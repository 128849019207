import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Orders from './Orders';
import { ORDER_STATUS } from '../../../../dto/enum';

function OrderListView() {
  const { status } = useParams();
  let orders = useSelector(state => state.firestore.ordered.adminOrden);
  switch (status) {
    case 'new':
      orders = orders?.filter(
        value =>
          value.status === ORDER_STATUS.IN_PROCESS ||
          value.status === ORDER_STATUS.CHANGES_ACCEPTED
      );
      break;
    case 'process':
      orders = orders?.filter(value => value.status === ORDER_STATUS.ACCEPTED);
      break;
    case 'ready':
      orders = orders?.filter(
        value =>
          value.status === ORDER_STATUS.READY_TO_PICK ||
          value.status === ORDER_STATUS.READY_TO_CASHIER
      );
      break;
    case 'delivering':
      orders = orders?.filter(
        value => value.status === ORDER_STATUS.DELIVERING
      );
      break;
    case 'complete':
      orders = orders?.filter(value => value.status === ORDER_STATUS.COMPLETED);
      break;
    case 'canceled':
      orders = orders?.filter(value => value.status === ORDER_STATUS.CANCELED);
      break;
    default:
      break;
  }
  if (!orders) {
    return null;
  }
  return (
    <>
      <Orders orders={orders} status={status} />
    </>
  );
}

export default OrderListView;
