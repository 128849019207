import React, { useState } from 'react';
import { Container, makeStyles, Divider, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Page from '../../../components/Page';
import Header from './Header';
import SendByRestaurants from './SendByRestaurants';
import SendByCity from './SendByCity';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const MarketingPage = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('byRestaurants');
  const translation = useTranslation();
  const tabs = [
    {
      value: 'byRestaurants',
      label: 'Por restaurants'
    },

    {
      value: 'byCity',
      label: 'Por ciudades'
    }
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page className={classes.root} title={translation('marketing.title')}>
      <Container maxWidth={false}>
        <Header />
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Divider style={{ marginTop: '10px' }} />
        {currentTab === 'byRestaurants' && <SendByRestaurants />}
        {currentTab === 'byCity' && <SendByCity />}
      </Container>
    </Page>
  );
};

export default MarketingPage;
