import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { Box, Typography, Container } from '@material-ui/core';

const Information = () => {
  const translation = useTranslation();
  return (
    <Container>
      <Box style={{ marginLeft: 60, marginBottom: 20 }}>
        <Box style={{ textAlign: 'center', marginBottom: 40 }}>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>
            {translation('subsidy_delivery.title')}
          </Typography>
        </Box>
        <Box style={{ marginBottom: 15 }}>
          <Typography variant="body1">
            {translation('subsidy_delivery.body.paragraph_one')}
          </Typography>
          <Typography variant="body1">
            {translation('subsidy_delivery.body.paragraph_two')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {translation('subsidy_delivery.body.listTitle')}
          </Typography>
          <ul style={{ marginLeft: 25 }}>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_one')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_two')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_three')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_four')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_five')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_six')}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                {translation('subsidy_delivery.body.list_seven')}
              </Typography>
            </li>
          </ul>
        </Box>
        <Box />
      </Box>
    </Container>
  );
};

export default Information;
