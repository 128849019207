import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import { useCity } from '../../../../hooks/AdminHooks/cities/useCities';
import CityPoligonEditForm from './CityPoligonEditForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function CityView(props) {
  const classes = useStyles();
  const { id } = useParams();
  const city = useCity(id);
  const translation = useTranslation();
  if (!city) {
    return null;
  }
  return (
    <Page className={classes.root} title={translation('orders.details.title')}>
      <Container maxWidth={false}>
        <Header city={city} />
        <CityPoligonEditForm city={{ id, ...city }} />
      </Container>
    </Page>
  );
}

export default CityView;
