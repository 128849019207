import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ModalAlertMerchant from 'src/components/Modal/ModalAlertMerchant';
import { useGetAlertsNative } from 'src/hooks/useAlerts';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useNewOrders } from '../../hooks/Order/useOrders';
import ReadyToPickTicketAlert from '../../components/Orders/ReadyToPickTicketAlert';
import XpressModal from '../../components/Modal';
import NewOrderModal from '../../views/orders/OrderDetailsView/modal/NewOrderModal';
import Sounds from '../../assets/sounds/sounds';
import useMembersConnect from '../../hooks/Members/useMembers';
import ChatViewPage from './xpressChat';
import { useAppOrders, useRestaurant } from '../../hooks/useRestaurant';
import { useSubsidiaryChat, useNewMessageChat } from '../../hooks/Chat/useChat';
import { useLastStory } from '../../hooks/useStories/useStories';
import { STATUS_STORY } from '../../constants';
import storyService from '../../services/storyService';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const alert = new Audio(Sounds.alert);
const alertChat = new Audio(Sounds.alertChat);
function DashboardLayout({ children }) {
  const classes = useStyles();
  const [merchantAlertOpened, setMerchantAlertOpened] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const subsidiary = useSelector(
    state => state.account.currentSubsidiary || ''
  );
  const activeChats = useSubsidiaryChat(subsidiary.id || '');
  const user = useSelector(state => state.account.user);
  const restaurant = useSelector(state => state.account.restaurant || '');
  useAppOrders(
    subsidiary.id || '',
    subsidiary.subsidiariesWithRestaurantsMerged || []
  );
  const [modal, setModal] = useState(null);
  const [orderInModal, setOrderInModal] = useState({ id: null });
  const [messageAmount, setMessageAmount] = useState(null);
  const newOrders = useNewOrders();
  useMembersConnect();
  const story = useLastStory(user.id);
  useRestaurant();
  const snackbar = useSnackbar();
  const newChat = useNewMessageChat();
  const [lastNewMessage, setLastNewMessage] = useState({ id: null });
  const alerts = useGetAlertsNative(user);
  const [lastStory, setLastStory] = useState(story);
  useEffect(() => {
    if (user && activeChats) {
      const newMessages = activeChats.filter(
        c => !c.last_message_viewers.includes(user.id)
      );
      setMessageAmount(newMessages.length);
    }
  }, [user, activeChats]);

  const showNotificationForElectron = (title, body) => {
    try {
      if (!('Notification' in window)) {
        console.log('No admite notificaciones de escritorio');
      } else if (Notification.permission === 'granted') {
        // eslint-disable-next-line
        const notification = new Notification(title, { body });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // eslint-disable-next-line
            const notification = new Notification(title, { body });
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showNotification = dataLastChat => {
    alertChat.play();
    setLastNewMessage(dataLastChat.message);
    const title = `Xpress- ${dataLastChat.message.sender_name}`;
    const body = dataLastChat.message.message;
    snackbar.enqueueSnackbar(`${title} - ${body} `, {
      variant: 'Success'
    });
    showNotificationForElectron(
      `Xpress-${restaurant.name}-${subsidiary.name}`,
      `${title} - ${body} `
    );
  };

  const showNotificationStory = async message => {
    alertChat.play();
    snackbar.enqueueSnackbar(message, {
      variant: 'Success'
    });
    showNotificationForElectron(`Xpress-${restaurant.name}`, message);
    await storyService.updateStory(story.id, { show_notification: true });
  };

  useEffect(() => {
    if (
      newChat &&
      !_.isEmpty(newChat.chat) &&
      newChat.message.id !== lastNewMessage.id
    ) {
      if (
        newChat.chat.merchantIdsToNotify &&
        newChat.chat.merchantIdsToNotify.includes(user.id)
      ) {
        if (!newChat.chat.last_message_viewers.includes(user.id)) {
          if (subsidiary.id === newChat.chat.subsidiary_id)
            showNotification(newChat);
        }
      }
    }
    // eslint-disable-next-line
  }, [snackbar, newChat]);

  useEffect(() => {
    if (newOrders && newOrders.length && newOrders[0].id !== orderInModal.id) {
      setShowModal(true);
      alert.play();
      alert.loop = true;
      setOrderInModal(newOrders[0]);
      setModal(
        <XpressModal>
          <NewOrderModal order={newOrders[0]} redirect />
        </XpressModal>
      );
      showNotificationForElectron(
        `Xpress-${restaurant.name}-${subsidiary.name}`,
        `Nueva Orden ${newOrders[0].order_code}`
      );
    }
    // eslint-disable-next-line
  }, [newOrders, orderInModal]);
  useEffect(() => {
    if (story && story.id !== lastStory.id) {
      if (
        story.status === STATUS_STORY.ACCEPTED &&
        story.show_notification === false
      ) {
        showNotificationStory(
          `Su Historia ${story.full_name} ha sido aceptada`
        );
        setLastStory(story);
      }
      if (
        story.status === STATUS_STORY.REJECTED &&
        story.show_notification === false
      ) {
        showNotificationStory(
          `Su Historia ${story.full_name} ha sido rechazada, motivo: ${story.reason_canceled}`
        );
        setLastStory(story);
      }
    }
    // eslint-disable-next-line
  }, [story]);

  useEffect(() => {
    if (alerts?.length > 0) {
      setMerchantAlertOpened(true);
    } else {
      setMerchantAlertOpened(false);
    }
  }, [alerts]);

  if (newOrders && !newOrders.length && showModal) {
    alert.pause();
    setShowModal(false);
  }

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        restaurantId={restaurant.id}
        chatNewMessagesAmount={messageAmount}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        chatNewMessagesAmount={messageAmount}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <ChatViewPage />
      {subsidiary && <ReadyToPickTicketAlert />}
      {showModal && modal}
      <ModalAlertMerchant
        data={alerts}
        isVisible={merchantAlertOpened}
        onClose={() => {
          setMerchantAlertOpened(false);
        }}
      />
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default React.memo(DashboardLayout);
