/* eslint-disable no-restricted-globals */
/* eslint-disable operator-assignment */
import React, { useState, useMemo } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { useTranslation } from 'react-multi-lang';
import StandardDashboard from './StandardDashboard';
import AdvancedDashboard from './AdvancedDashboard';
import { COLORS } from '../../../theme/colors';
import { useGetMonthlyMetricSubsidiary } from '../../../services/subsidiaryService';
import useCategories from '../../../hooks/useCategories';
import { useRestaurant } from '../../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  buttonContainedFirst: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    backgroundColor: COLORS.primary,
    color: theme.palette.expressColors.black
  },
  buttonContainedSecond: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: COLORS.primary,
    color: theme.palette.expressColors.black
  },
  buttonOutlinedFirst: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    backgroundColor: theme.palette.expressColors.white,
    color: theme.palette.expressColors.yellowLink
  },
  buttonOutlinedSecond: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: theme.palette.expressColors.white,
    color: theme.palette.expressColors.yellowLink
  },
  buttonText: {
    fontWeight: 'bold',
    height: '36px',
    width: '137px'
  },
  label: {
    backgroundColor: theme.palette.expressColors.primary,
    height: '36px',
    width: '164px',
    fontWeight: 'bold',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));

function DashboardView() {
  const classes = useStyles();
  const translation = useTranslation();
  const { enable_advanced_metrics } = useRestaurant();
  const subsidiary = useSelector(
    state => state.account.currentSubsidiary || ''
  );
  const [value, setValue] = useState(0);
  const [month, setMonth] = useState({
    value: createMomentOnBolivianTime()
      .subtract(1, 'month')
      .format('MMMM'),
    label: translation(
      `months.${createMomentOnBolivianTime()
        .subtract(1, 'month')
        .format('MMMM')}`
    )
  });
  const [year, setYear] = useState(2023);
  const monthlyMetric = useGetMonthlyMetricSubsidiary();
  const allCategories = useCategories();
  const categories = useMemo(() => {
    if (subsidiary.categories.length > 0) {
      const currentCategories = [];
      subsidiary.categories.forEach(categoryId => {
        allCategories.forEach(category => {
          if (category.id === categoryId) {
            currentCategories.push(category);
          }
        });
      });
      return currentCategories;
    }
    return [];
  }, [allCategories, subsidiary.categories]);
  const filterMetric = useMemo(() => {
    if (typeof month === 'object' && monthlyMetric) {
      const selectDate = `${month.value} - ${year}`;
      const selectMetric = monthlyMetric.find(
        metric => metric.date === selectDate
      );
      return selectMetric;
    }
    if (monthlyMetric) {
      const selectDate = `${month} - ${year}`;
      const selectMetric = monthlyMetric.find(
        metric => metric.date === selectDate
      );
      return selectMetric;
    }
    return null;
  }, [monthlyMetric, year, month]);

  const handleChange = (event, index) => {
    setValue(index);
  };

  const handleMonth = valueMonth => {
    setMonth(valueMonth);
  };

  const handleYear = valueYear => {
    setYear(valueYear);
  };

  const getTabContent = () => {
    switch (value) {
      case 0:
        return <StandardDashboard subsidiary={subsidiary} />;
      case 1:
        return (
          <AdvancedDashboard
            handleMonth={handleMonth}
            month={month}
            handleYear={handleYear}
            year={year}
            metrics={filterMetric}
            categories={categories}
            enable_advanced_metrics={enable_advanced_metrics}
          />
        );
      default:
        return <StandardDashboard subsidiary={subsidiary} />;
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary" align="center">
            {`${subsidiary?.restaurant_name || ''} - ${subsidiary?.name || ''}`}
          </Typography>
        </Box>
        <Box mt={3}>
          <Box display="flex" justifyContent="center">
            <Tabs
              value={value}
              indicatorColor="transparent"
              onChange={handleChange}
            >
              <Tab
                className={
                  value === 0
                    ? clsx(classes.buttonContainedFirst, classes.buttonText)
                    : clsx(classes.buttonOutlinedFirst, classes.buttonText)
                }
                label="ESTANDAR"
              />
              <Tab
                className={
                  value === 0
                    ? clsx(classes.buttonOutlinedSecond, classes.buttonText)
                    : clsx(classes.buttonContainedSecond, classes.buttonText)
                }
                label="AVANZADA"
              />
            </Tabs>
          </Box>
          <Box>{getTabContent()}</Box>
        </Box>
      </Container>
    </Page>
  );
}

export default DashboardView;
