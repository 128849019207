/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  makeStyles,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang/lib';
import WithLoading from '../../../components/WithLoading';
import ScheduleView from '../../../components/schedule';
import { createUpdateMenuSectionSchedule } from '../../../services/restaurantService';
import env from '../../../env';

const BoxLoading = WithLoading(Box);

const useStyles = makeStyles(theme => ({
  submit: {
    display: 'flex',
    justifyContent: 'center'
  },
  lastButton: {
    marginLeft: theme.spacing(2)
  }
}));

const EditMenuSection = ({
  show,
  onClose,
  closeModalSave,
  isEditable,
  section,
  currentMenuSectionSchedule,
  menuList,
  setName,
  saveName,
  restId,
  ...rest
}) => {
  const classes = useStyles();
  const today = moment();
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const allSectionId = menuList.map(element => {
    return element.id;
  });
  const [loading, setLoading] = useState(false);
  const [currentMenuSection, setCurrentMenuSection] = useState(
    currentMenuSectionSchedule
      ? currentMenuSectionSchedule.menu_section.find(
          item => item.id === section.id
        )
      : {}
  );
  const checkSchedule = schedule => {
    const newSchedule = [];
    let error = false;
    schedule.forEach(e => {
      let close = false;
      if (e.open) {
        let haveSchedule = false;
        for (let i = 0; i < e.schedule.length; i++) {
          const day = e.schedule[i];
          if (day.start_time && day.end_time) haveSchedule = true;
          if (
            day.start_time &&
            day.end_time &&
            day.start_time.seconds &&
            day.end_time.seconds &&
            day.start_time.seconds === day.end_time.seconds
          )
            error = true;
          if (
            day.start_time &&
            day.end_time &&
            !day.start_time.seconds &&
            !day.end_time.seconds &&
            day.start_time === day.end_time
          )
            error = true;
          if (!haveSchedule && !day.start_time && !day.end_time) close = true;
        }
      }
      if (close && !error) newSchedule.push({ ...e, open: !close });
      else newSchedule.push({ ...e });
    });
    if (!error) return newSchedule;
    return false;
  };
  const invalidLastDate = expireDate => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    if (expireDate < currentDate)
      return translation('admin_promos.expire_date.past');
    return false;
  };

  const saveMenuSection = async menuSectionForm => {
    setLoading(true);
    try {
      if (
        isEditable &&
        menuSectionForm.section.toUpperCase() !== section.name.toUpperCase()
      )
        await saveName();
      let menuSection = [];
      const parseSchedule = currentMenuSectionSchedule;
      if (parseSchedule) {
        menuSection = parseSchedule.menu_section;
        const index =
          parseSchedule.menu_section.length > 0 &&
          parseSchedule.menu_section.findIndex(item => item.id === section.id);
        if (menuSection[index]) {
          menuSection[index].all_days = menuSectionForm.all_days;
          menuSection[index].start_schedule = menuSectionForm.start_schedule;
          menuSection[index].end_schedule = menuSectionForm.end_schedule;
          menuSection[index].schedule = menuSectionForm.schedule;
        } else {
          menuSection.push({
            id: section.id,
            section_name: section.name,
            all_days: menuSectionForm.all_days,
            start_schedule: menuSectionForm.start_schedule,
            end_schedule: menuSectionForm.end_schedule,
            schedule: menuSectionForm.schedule
          });
        }
      } else {
        menuSection.push({
          id: section.id,
          section_name: section.name,
          all_days: menuSectionForm.all_days,
          start_schedule: menuSectionForm.start_schedule,
          end_schedule: menuSectionForm.end_schedule,
          schedule: menuSectionForm.all_days ? [] : menuSectionForm.schedule
        });
      }
      if (menuSection.length > 0) {
        const checkMenuSection = [];
        menuSection.forEach(s => {
          const find = allSectionId.includes(s.id || s.section_id);
          if (find) {
            if (section.id !== s.id) checkMenuSection.push(s);
            else if (!menuSectionForm.all_days) checkMenuSection.push(s);
          }
        });
        menuSection = checkMenuSection;
      }
      const data = {};
      data.id = parseSchedule ? parseSchedule.id : '';
      data.restaurant_id = isEditable ? restId : parseSchedule.restaurant_id;
      data.subsidiary_id = '';
      data.menu_section = menuSection;
      data.is_shared_menu = isEditable;
      setLoading(false);
      const result = await createUpdateMenuSectionSchedule(data);
      if (result.data.data.isSucceeded) {
        enqueueSnackbar(translation('menu.menu_section.correct_save'), {
          variant: 'success'
        });
        setCurrentMenuSection({});
        setLoading(false);
        closeModalSave();
      } else {
        enqueueSnackbar(translation('Error cloud functions'), {
          variant: 'error'
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(translation('ERROR'), {
        variant: 'error'
      });
      setLoading(false);
    }
  };
  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          section: section.name || '',
          all_days: !_.isEmpty(currentMenuSection)
            ? currentMenuSection.all_days
            : true,
          start_schedule: !_.isEmpty(currentMenuSection)
            ? currentMenuSection.start_schedule
            : moment().toDate(),
          end_schedule: !_.isEmpty(currentMenuSection)
            ? currentMenuSection.end_schedule
            : moment().toDate(),
          schedule: !_.isEmpty(currentMenuSection)
            ? currentMenuSection.schedule
            : []
        }}
        validationSchema={Yup.object().shape({
          section: Yup.string()
            .max(255)
            .required(translation('menu.menu_section.edit.section_required')),
          start_schedule: Yup.date()
            .typeError(translation('menu.menu_section.edit.format_date'))
            .when('all_days', {
              // eslint-disable-next-line consistent-return
              is: all_days => {
                if (!all_days) return true;
              },
              then: Yup.date().required(
                translation('menu.menu_section.edit.date_required')
              )
            }),
          end_schedule: Yup.date()
            .typeError(translation('menu.menu_section.edit.format_date'))
            .when('all_days', {
              // eslint-disable-next-line consistent-return
              is: all_days => {
                if (!all_days) return true;
              },
              then: Yup.date().required(
                translation('menu.menu_section.edit.date_required')
              )
            })
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const expiredDate = moment(values.end_schedule)
              .endOf('day')
              .toDate();
            const startSchedule = moment(values.start_schedule)
              .startOf('day')
              .toDate();
            const errorEndDate = invalidLastDate(values.end_schedule);
            const errorStartDate = invalidLastDate(values.start_schedule);
            if (!values.all_days) {
              if (errorEndDate || errorStartDate) {
                setErrors({
                  end_schedule: errorEndDate,
                  start_schedule: errorStartDate
                });
                setSubmitting(false);
                setLoading(false);
                return;
              }
              if (expiredDate < startSchedule) {
                setErrors({
                  end_schedule: translation(
                    'admin_promos.expire_date.diferenceStart'
                  ),
                  start_schedule: translation(
                    'admin_promos.start_date.diferenceExpire'
                  )
                });
                setSubmitting(false);
                setLoading(false);
                return;
              }
              const newSchedule = checkSchedule(values.schedule);
              if (!newSchedule) {
                setErrors({
                  schedule: translation(
                    'admin_promos.expire_date.diferenceStart'
                  )
                });
                setSubmitting(false);
                setLoading(false);
                enqueueSnackbar(translation('admin_promos.error_schedule'), {
                  variant: 'error'
                });
                return;
              }
              await saveMenuSection({
                ...values,
                end_schedule: expiredDate,
                start_schedule: startSchedule,
                schedule: newSchedule
              });
              setStatus({ success: true });
              setSubmitting(false);
            } else {
              await saveMenuSection({
                ...values
              });
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (e) {
            console.log(e);
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar(
              `${translation('complete_restaurant.error')} ${
                env.REACT_APP_SUPPORT_MAIL
              }`,
              {
                variant: 'error'
              }
            );
            setErrors({ submit: e.message });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <>
              <form noValidate onSubmit={handleSubmit} {...rest}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography>
                      <strong>
                        {translation('menu.menu_section.edit.title')}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.section && errors.section)}
                      fullWidth
                      helperText={touched.section && errors.section}
                      label={translation('menu.menu_section.edit.section')}
                      name="section"
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('section', e.target.value);
                        setName(e.target.value);
                      }}
                      value={values.section}
                      required
                      variant="outlined"
                      disabled={!isEditable}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.all_days}
                          onChange={handleChange}
                          value={values.all_days}
                          name="all_days"
                        />
                      }
                      label={translation('menu.menu_section.edit.all_days')}
                    />
                  </Grid>
                  {!values.all_days && (
                    <>
                      <Grid item sm={6} xs={12}>
                        <KeyboardDatePicker
                          variant="inline"
                          error={Boolean(
                            touched.start_schedule && errors.start_schedule
                          )}
                          fullWidth
                          autoOk
                          placeholder={today.format('DD/MM/YYYY')}
                          inputVariant="outlined"
                          helperText={
                            touched.start_schedule && errors.start_schedule
                          }
                          label={translation(
                            'menu.menu_section.edit.start_schedule'
                          )}
                          name="start_schedule"
                          onBlur={handleBlur}
                          format="DD/MM/YYYY"
                          minDate={moment()}
                          onChange={date => {
                            setFieldValue(
                              'start_schedule',
                              date ? date.toDate() : undefined
                            );
                          }}
                          value={
                            values.start_schedule ? values.start_schedule : null
                          }
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <KeyboardDatePicker
                          variant="inline"
                          error={Boolean(
                            touched.end_schedule && errors.end_schedule
                          )}
                          fullWidth
                          autoOk
                          placeholder={today.format('DD/MM/YYYY')}
                          inputVariant="outlined"
                          helperText={
                            touched.end_schedule && errors.end_schedule
                          }
                          label={translation(
                            'menu.menu_section.edit.end_schedule'
                          )}
                          name="end_schedule"
                          onBlur={handleBlur}
                          format="DD/MM/YYYY"
                          minDate={values.start_schedule || moment()}
                          onChange={date => {
                            setFieldValue(
                              'end_schedule',
                              date ? date.toDate() : undefined
                            );
                          }}
                          value={
                            values.end_schedule ? values.end_schedule : null
                          }
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <div style={{ padding: 10 }}>
                          <ScheduleView
                            currentSchedule={values.schedule}
                            onChange={data => setFieldValue('schedule', data)}
                            labelEnable="menu.menu_section.enable"
                            labelDisable="menu.menu_section.disable"
                          />
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box mt={2} className={classes.submit}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {translation('save')}
                  </Button>
                  <Button
                    className={classes.lastButton}
                    variant="contained"
                    color="secondary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={onClose}
                  >
                    {translation('cancel')}
                  </Button>
                </Box>
              </form>
            </>
          );
        }}
      </Formik>
    </BoxLoading>
  );
};

export default EditMenuSection;
