export default class CommissionCharges {
  constructor({ delivery = 0, xpress_motos = 0, pick_up = 0, iva = 0 } = {}) {
    this.delivery = delivery;
    this.xpress_motos = xpress_motos;
    this.pick_up = pick_up;
    this.iva = iva;
  }

  toPlainObject() {
    return { ...this };
  }
}
