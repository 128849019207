import React from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  makeStyles,
  SvgIcon,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(3)
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({
  section,
  className,
  sectionId,
  subsidiaryId,
  setNewSection,
  onAddClick,
  ...rest
}) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app/editor/menu-sections"
            component={RouterLink}
          >
            {translation('category_food.title')}
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid container item alignContent="center" justify="space-between">
        <Grid>
          <Typography variant="h3" color="textPrimary">
            {translation('category_food.title')}
          </Typography>
        </Grid>
        <Grid>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            onClick={onAddClick}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PlusCircleIcon />
            </SvgIcon>
            {translation('category_food.add')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
