import React from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const DeleteUser = ({ handleConfirm, handleClose, name, user }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('admin_workers.modal.title')}
        />
        <Divider />
        <CardContent>
          <Grid>
            <Grid item container justify="center">
              <Box mx={2} mb={3} fontWeight="fontWeightBold">
                <Typography>
                  {`${translation('admin_workers.modal.subtitle1')} "{name}"`}
                </Typography>
                <Typography>
                  {`${translation(
                    'admin_workers.modal.subtitle1_2'
                  )} "{user}?"`}
                </Typography>
              </Box>
              <Box mx={2} mb={3}>
                <Typography>
                  {translation('admin_workers.modal.subtitle2')}
                </Typography>
              </Box>
            </Grid>
            <Box mb={3} width="100%">
              <Grid item container justify="center">
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleConfirm}
                  >
                    {translation('admin_workers.modal.button.delete')}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                  >
                    {translation('admin_workers.modal.button.cancel')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DeleteUser;
