import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ type = '', className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  let orderLabel = translation('orders.details.header.order');
  let link = '';

  switch (type) {
    case 'orders':
      break;
    case 'new':
      orderLabel = translation('menu.new_order.label');
      link = '/admin/orders/new';
      break;
    case 'process':
      orderLabel = translation('menu.process_order.label');
      link = '/admin/orders/process';
      break;
    case 'ready':
      orderLabel = translation('menu.prepared_order');
      link = '/admin/orders/ready';
      break;
    case 'complete':
      orderLabel = translation('menu.completed_order');
      link = '/admin/orders/complete';
      break;
    default:
      orderLabel = translation('orders.details.header.order');
      break;
  }

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin"
            component={RouterLink}
          >
            {translation('orders.details.header.board')}
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/admin/orders"
            component={RouterLink}
          >
            {translation('orders.details.header.order')}
          </Link>
          {link && (
            <Link
              variant="body1"
              color="inherit"
              to={link}
              component={RouterLink}
            >
              {orderLabel}
            </Link>
          )}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          {orderLabel}
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
