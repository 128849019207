import { isBoolean, isDate, isNumber, isString } from 'lodash';

function isPrimitive(item) {
  return isNumber(item) || isDate(item) || isString(item) || isBoolean(item);
}
function getOnlyPrimitiveValues(object, includeItemValidation = () => true) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (isPrimitive(value) && includeItemValidation({ key, value })) {
      data[key] = value;
    }
  });
  return data;
}
export function getObjectChanges(prevValue, newValue) {
  const includeItemValidation = ({ key }) => {
    return (
      !key.includes('_id') &&
      !key.includes('created_at') &&
      !key.includes('updated_at') &&
      !key.includes('id')
    );
  };
  if (!prevValue) {
    return {
      after: getOnlyPrimitiveValues(newValue, includeItemValidation),
      before: null
    };
  }
  if (!newValue) {
    return {
      after: null,
      before: getOnlyPrimitiveValues(prevValue, includeItemValidation)
    };
  }
  const keys = {};
  const newData = getOnlyPrimitiveValues(newValue, includeItemValidation);
  Object.keys(newData).forEach(key => {
    keys[key] = key;
  });
  const before = {};
  const after = {};

  Object.keys(keys).forEach(key => {
    if (prevValue[key] !== newValue[key]) {
      before[key] = prevValue[key] === undefined ? '' : prevValue[key];
      after[key] = newValue[key] === undefined ? '' : newValue[key];
    }
  });
  return {
    before,
    after
  };
}

export const arrayToString = array => {
  const items = array.map(item => {
    const values = Object.values(item)
      .map(value => value)
      .join(',');
    return `(${values})`;
  });
  return items.join(',');
};
