/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, FormControlLabel } from '@material-ui/core';
import ScheduleTimePicker from 'src/components/ScheduleTimePicker';
import Schedule from 'src/dto/schedule';
import { DEFAULT_END_HOUR_DATE, DEFAULT_START_HOUR_DATE } from 'src/constants';
import { useTranslation } from 'react-multi-lang/lib';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '../../../components/Inputs/Switch';
import { COLORS } from '../../../theme/colors';
import { createMomentOnBolivianTime } from '../../../utils/date';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '100%'
  },
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  list: { width: '100%', padding: 0 },
  item: { display: 'flex', paddingLeft: 16 },
  week: {
    maxWidth: 120,
    minWidth: 80,
    paddingLeft: 10,
    fontWeight: 900,
    fontSize: 20
  },
  flex: { flex: 1 },
  center: { display: 'flex', justifyContent: 'center' },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  switch: {
    width: '100%',
    justifyContent: 'center'
  }
}));
const DEFAULT_NEW_DAY = journey =>
  new Schedule({
    schedule: [
      { start_time: DEFAULT_START_HOUR_DATE, end_time: DEFAULT_END_HOUR_DATE },
      {}
    ],
    open: true,
    journey: journey.toUpperCase(),
    enable: true
  });

function ScheduleTemplate({ onChange = () => {}, value = [] }) {
  const classes = useStyles();
  const translation = useTranslation();

  const WEEK_DAYS = [
    { value: 'monday', day: translation('schedule.result.days.monday') },
    { value: 'tuesday', day: translation('schedule.result.days.tuesday') },
    { value: 'wednesday', day: translation('schedule.result.days.wednesday') },
    { value: 'thursday', day: translation('schedule.result.days.thursday') },
    { value: 'friday', day: translation('schedule.result.days.friday') },
    { value: 'saturday', day: translation('schedule.result.days.saturday') },
    { value: 'sunday', day: translation('schedule.result.days.sunday') }
  ];

  const onChangeSchedule = (day, index, field) => ev => {
    const dayScheduleIndex = value.findIndex(
      item => item.journey === day.toUpperCase()
    );

    const newSchedule = [...value];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      ...newSchedule[dayScheduleIndex].schedule[index],
      [`${field}_time`]: createMomentOnBolivianTime(
        `2000 01 ${ev.target.value}`,
        'YYYY MM HH:mm'
      ).toDate()
    };
    onChange(newSchedule);
  };

  const handleOpenChange = day => () => {
    const dayExists = !!value.find(
      item => item.journey === day.value.toUpperCase()
    );
    if (dayExists) {
      const dayScheduleIndex = value.findIndex(
        item => item.journey === day.value.toUpperCase()
      );

      const newSchedule = [...value];
      newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
      newSchedule[dayScheduleIndex].open = !newSchedule[dayScheduleIndex].open;
      onChange(newSchedule);
    } else {
      onChange([...value, { ...DEFAULT_NEW_DAY(day.value) }]);
    }
  };

  function onTimeFrameAdd(day, index) {
    const dayScheduleIndex = value.findIndex(item => item.journey === day);

    const newSchedule = [...value];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      start_time: DEFAULT_START_HOUR_DATE,
      end_time: DEFAULT_END_HOUR_DATE
    };
    onChange(newSchedule);
  }

  function onTimeFrameRemove(day, index) {
    const dayScheduleIndex = value.findIndex(item => item.journey === day);

    const newSchedule = [...value];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {};
    onChange(newSchedule);
  }

  if (!value) return <></>;

  return (
    <div className={clsx(classes.root)}>
      <PerfectScrollbar options={{ suppressScrollY: true }}>
        <div style={{ borderRadius: 16, padding: 0, backgroundColor: 'white' }}>
          <Table className={classes.table}>
            <TableBody>
              {WEEK_DAYS.map((day, i) => {
                const daySchedule =
                  value.find(
                    item => item.journey === day.value.toUpperCase()
                  ) || '';

                return (
                  <TableRow key={i}>
                    <TableCell className={classes.tableCell}>
                      <Typography
                        className={classes.week}
                        variant="h5"
                        color="textPrimary"
                      >
                        {day.day}
                        {':'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Switch
                            checked={!!daySchedule.open}
                            onChange={handleOpenChange(day, !daySchedule.open)}
                            color="primary"
                          />
                        }
                        label={
                          daySchedule.open
                            ? translation('schedule.result.open')
                            : translation('schedule.result.close')
                        }
                        labelPlacement="start"
                      />
                    </TableCell>
                    {daySchedule.schedule ? (
                      daySchedule.schedule.map((time, index) => (
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          key={index}
                        >
                          <div className={clsx(classes.flex, classes.center)}>
                            {daySchedule && (
                              <ScheduleTimePicker
                                from={
                                  time.start_time
                                    ? createMomentOnBolivianTime(
                                        time.start_time
                                      )
                                    : ''
                                }
                                to={
                                  time.end_time
                                    ? createMomentOnBolivianTime(time.end_time)
                                    : ''
                                }
                                onChangeFrom={onChangeSchedule(
                                  day.value.toUpperCase(),
                                  index,
                                  'start'
                                )}
                                onChangeTo={onChangeSchedule(
                                  day.value.toUpperCase(),
                                  index,
                                  'end'
                                )}
                                onIconPress={() => {
                                  onTimeFrameRemove(
                                    day.value.toUpperCase(),
                                    index
                                  );
                                }}
                                onAddPress={() => {
                                  onTimeFrameAdd(
                                    day.value.toUpperCase(),
                                    index
                                  );
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                      ))
                    ) : (
                      <>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell} />
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

ScheduleTemplate.defaultProps = {
  schedule: []
};

export default ScheduleTemplate;
