import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS, PROVIDER_STORY } from '../../constants';
import { orderByDesc } from '../../utils/array';

export const useLastStory = userId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.STORIES,
      where: [
        ['user_id', '==', userId],
        ['provider', '==', PROVIDER_STORY.MERCHANT]
      ],
      storeAs: `current_last_story_${userId}`
    }
  ]);
  const currentLastStory = useSelector(
    state => state.firestore.ordered[`current_last_story_${userId}`]
  );
  if (currentLastStory) {
    const tmp = orderByDesc(currentLastStory.slice(), item => item.updated_at);
    return tmp[0];
  }
  return {};
};
