import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Breadcrumbs, Grid, Link, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Header({ className, promo, ...rest }) {
  const classes = useStyles();
  const translate = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/promos"
            component={RouterLink}
          >
            {translate('admin_promos.title')}
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/admin/promos"
            component={RouterLink}
          >
            {promo.title
              ? promo.title
              : translate('admin_promos.new_promotion')}
          </Link>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
