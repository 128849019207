/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
//  { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  InputAdornment,
  makeStyles,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-multi-lang';
import { USER_ROLES } from '../../../dto/enum';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import userService from '../../../services/usersService';
import SelectWithBorder from '../../../components/Inputs/SelectWithBorder';
import { getFormatDate, createMomentOnBolivianTime } from '../../../utils/date';
import { COLORS } from '../../../theme/colors';
// import IconButton from '../../../components/Inputs/IconButton';
import TabsHeader from '../../../components/Tab/Tabs';
// import Icons from '../../../assets/icons';
// import ModalCreateMember from './ModalCreateMember';
import getUserRoles from '../../../utils/helpers/userHelper';
import env from '../../../env';

function applyFilters(members, query, filters) {
  return members.filter(member => {
    let matches = true;

    if (query) {
      const properties = ['email', 'first_name', 'last_name'];
      let containsQuery = false;

      properties.forEach(property => {
        if (member[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value && member.roles && member.roles[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
}

function applyPagination(members, page, limit) {
  return members.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySort(members, sort) {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = members.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    width: 300,
    marginLeft: 30
  },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

function Results({ className, members, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  const sortOptions = [
    {
      value: 'created_at|desc',
      label: translation('sort_option.new_first')
    },
    {
      value: 'created_at|asc',
      label: translation('sort_option.old_first')
    }
  ];
  const [deletedUser, setDeletedUser] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  // const [openModal, setOpenModal] = useState(false);
  // const [userId, setUserId] = useState();
  const [filters, setFilters] = useState({
    OWNER: null,
    SUPERVISOR: null,
    CASHIER: null
  });

  const tabs = [
    {
      value: 'all',
      label: translation('customer_edit.all')
    },
    {
      value: USER_ROLES.SUPERVISOR,
      label: translation('customer_edit.supervisor')
    },
    {
      value: USER_ROLES.CASHIER,
      label: translation('customer_edit.cashier')
    }
  ];

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      OWNER: null,
      SUPERVISOR: null,
      CASHIER: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setCurrentTab(value);
  };

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = event => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const [filteredMembers, paginatedMembers] = useMemo(() => {
    if (members) {
      const filterMembers = applyFilters(members, query, filters);
      const sortMembers = applySort(filterMembers, sort);
      const pageMembers = applyPagination(sortMembers, page, limit);
      return [filterMembers, pageMembers];
    }
    return [[], []];
  }, [members, query, filters, sort, limit, page]);

  const deleteUser = async () => {
    const { id } = deletedUser;
    setDeletedUser({});
    try {
      await userService.removeFromRestaurant(id);
      enqueueSnackbar(translation('personal.delete_user'), {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar(
        `${translation('complete_restaurant.error')} ${
          env.REACT_APP_SUPPORT_MAIL
        }`,
        {
          variant: 'error'
        }
      );
    }
  };
  return (
    <>
      {/* <ModalCreateMember
        // userId={userId}
        show={openModal}
        onClose={() => setOpenModal(false)}
      /> */}
      <TabsHeader value={currentTab} onChange={handleTabsChange} tabs={tabs} />

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Box flexGrow={1} />
          <SelectWithBorder
            label={translation('personal.sort')}
            onChange={handleSortChange}
            options={sortOptions}
            value={sort}
          />
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder={translation('personal.search')}
            value={query}
            variant="outlined"
          />
        </Box>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <b>
                      {translation('basic_information.personal_info.username')}
                    </b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <b>
                      {translation('basic_information.personal_info.email')}
                    </b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <b>
                      {translation(
                        'basic_information.personal_info.registration_date'
                      )}
                    </b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <b>
                      {translation('basic_information.personal_info.roles')}
                    </b>
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableCell}>
                    <b>{translation('personal.action')}</b>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedMembers.map((member, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow hover key={member.id + i}>
                      <TableCell className={classes.tableCell}>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            className={classes.avatar}
                            src={
                              member.profile_photo && member.profile_photo.x80
                            }
                          >
                            {getInitials(
                              `${member.first_name} ${member.last_name}`
                            )}
                          </Avatar>
                          <div>
                            <Typography color="textPrimary" variant="h6">
                              {`${member.first_name} ${member.last_name}`}
                            </Typography>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography
                          style={{ opacity: 0.5 }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {member.email}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getFormatDate(
                          createMomentOnBolivianTime(member.created_at.toDate())
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getUserRoles(member, true)}
                      </TableCell>
                      {/* <TableCell align="center" className={classes.tableCell}>
                        {member.id &&
                          getUserRoles(member)[0] !== USER_ROLES.OWNER && (
                            <>
                              <IconButton
                                size={25}
                                src={Icons.edit}
                                component={RouterLink}
                                onClick={() => {
                                  setUserId(member.id);
                                  setOpenModal(true);
                                }}
                              />
                              <IconButton
                                size={25}
                                src={Icons.close}
                                onClick={() => setDeletedUser(member)}
                              />
                            </>
                          )}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={filteredMembers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          labelRowsPerPage={translation('personal.pagination.user')}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to === -1 ? count : to} ${translation(
              'personal.pagination.of'
            )} ${
              count !== -1
                ? count
                : `${translation('personal.pagination.more')} ${to}`
            }`;
          }}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <AlertDialog
        open={!!deletedUser.id}
        question={translation('personal.alert.quest')}
        maxWidth="sm"
        handleCancel={() => {
          setDeletedUser({});
        }}
        handleAccept={() => deleteUser()}
        acceptLabel={translation('personal.alert.delete')}
      >
        <Box m={1}>
          <Typography variant="h4">
            {`${getUserRoles(deletedUser, true)}: ${deletedUser.first_name} ${
              deletedUser.last_name
            }`}
          </Typography>
        </Box>
      </AlertDialog>
    </>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  members: PropTypes.array
};

Results.defaultProps = {
  members: []
};

export default Results;
