import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useCurrentUser } from 'src/hooks/useUser';
import AlertsServices from 'src/services/alertService';
import ModalTemplateCustomHeader from '../ModalTemplateCustomHeader';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    width: '100%',
    p: 2
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold'
  },
  descriptionContainer: {
    width: '100%',
    p: 2
  },
  descriptionText: {
    textAlign: 'center'
  },
  image: {
    height: 400,
    width: '100%',
    objectFit: 'cover',
    padding: 10
  }
}));

const ModalAlertMerchant = ({ isVisible, data = [], onClose = () => {} }) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = data?.length || 0;

  const handleClose = () => {
    onClose();
  };
  const handleNext = () => {
    if (activeStep !== data?.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      AlertsServices.setViewedAlerts(data, currentUser);
      handleClose();
    }
  };
  return (
    <ModalTemplateCustomHeader
      show={isVisible}
      onClose={handleClose}
      size="xs"
      label={' '}
    >
      <Box sx={{ flex: 1 }}>
        <Paper square elevation={0} className={classes.container}>
          <Typography className={classes.title}>
            <strong>{data[activeStep]?.alertData?.title}</strong>
          </Typography>
        </Paper>
        <Box className={classes.descriptionContainer}>
          <Typography className={classes.descriptionText}>
            {data[activeStep]?.alertData?.description}
          </Typography>
        </Box>
        {Boolean(data[activeStep]?.alertData?.image?.original) && (
          <Box
            sx={{ width: '100%', borderRadius: 10 }}
            display="flex"
            justifyContent="center"
          >
            <Box width="100%">
              <img
                alt="category"
                src={data[activeStep]?.alertData?.image?.original}
                className={classes.image}
              />
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          {data?.map((item, index) => (
            <Box
              width={10}
              height={10}
              bgcolor="#869791"
              m={0.2}
              borderRadius={10}
              style={activeStep !== index ? { opacity: 0.4 } : { opacity: 1 }}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="center" mt={1.2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === maxSteps}
          >
            {activeStep === data?.length - 1 ? 'Entendido' : 'Siguiente'}
          </Button>
        </Box>
      </Box>
    </ModalTemplateCustomHeader>
  );
};

export default React.memo(ModalAlertMerchant);
