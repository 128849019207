import firebase from 'firebase/app';
import 'firebase/database';

// eslint-disable-next-line import/prefer-default-export
export function generateRandomId() {
  return firebase
    .database()
    .ref('random')
    .push()
    .getKey();
}
