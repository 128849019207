import React, { useState } from 'react';
import {
  Container,
  makeStyles,
  Box,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useSelector } from 'react-redux';
import Header from './Header';
import Results from './Results';
import Reports from '../tabs/reports/Reports';
import Page from '../../../../components/Page';
import MapDealers from '../tabs/mapDealers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const AdminDealers = () => {
  const classes = useStyles();
  const translation = useTranslation();
  const [value, setValue] = useState(0);

  const orders = useSelector(state => state.firestore.ordered.adminOrden);

  const handleChange = (event, index) => {
    setValue(index);
  };

  const getTabContent = ordersData => {
    switch (value) {
      case 0:
        return <Results />;
      case 1:
        return <Reports orders={ordersData} />;
      case 2:
        return <MapDealers />;
      default:
        return <div> error tabe</div>;
    }
  };

  return (
    <Page
      className={classes.root}
      title={translation('admin_dealers.page_title')}
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab value={0} label={translation('admin_dealers.tabs.list')} />
              <Tab
                value={1}
                label={translation('admin_dealers.tabs.reports')}
              />
              <Tab value={2} label={translation('admin_dealers.tabs.map')} />
            </Tabs>
          </AppBar>
          {getTabContent(orders)}
        </Box>
      </Container>
    </Page>
  );
};

export default AdminDealers;
