import DevEnv from './dev';
import TestEnv from './test';
import StagingEnv from './staging';
import ProductionEnv from './production';
/**
 * @typedef {{
 * REACT_APP_GOOGLE_MAP_API: string,
 * REACT_APP_COMPANY_NAME: string,
 * REACT_APP_DYNAMIC_LINK_DOMAIN_URI: string,
 * REACT_APP_XPRESS_MERCHANT_URL: string,
 * REACT_APP_POS_CLOUD_FUNCTIONS_API: string,
 * REACT_APP_CLOUD_FUNCTIONS_API: string,
 * REACT_APP_FIREBASE_API_KEY: string,
 * REACT_APP_DYNAMIC_LINK_DRIVER_DOMAIN_URI: string,
 * REACT_APP_XPRESS_MOTOS_URL: string,
 * REACT_APP_XPRESS_ADMIN_URL: string,
 * REACT_APP_WHATSAPP: string,
 * firebaseConfig:Record<string,string>,
 * conbrixConfig: Record<string,string>,
 * REACT_APP_FCM_KEY: string,
 * REACT_APP_SUPPORT_MAIL:string,
 * BACKEND_URL: string
 * }} EnvironmentConfig
 */

/**
 *
 * @type {Record<string,EnvironmentConfig>}
 */
const Environments = {
  dev: DevEnv,
  test: TestEnv,
  staging: StagingEnv,
  production: ProductionEnv
};

export default Environments.production;
