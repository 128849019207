/* eslint-disable */

import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { GreenSwitch, RedSwitch } from './Inputs/Switch';

const useStyles = makeStyles(theme => ({
  root: {},
  box: {
    display: 'flex',
    alignItems: 'center'
  }
}));
const CustomSwitch = ({ avialability, setAvialability, onChange }) => {
  const translation = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography variant="body2" color="textSecondary">
        {avialability ? translation('available') : translation('not_available')}
      </Typography>
      {avialability ? (
        <GreenSwitch
          checked={avialability}
          onChange={e => {
            setAvialability(e.target.checked);
            onChange && onChange(e.target.checked);
          }}
          name="avialability"
          id="avialability"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ) : (
        <RedSwitch
          checked={avialability}
          onChange={e => {
            setAvialability(e.target.checked);
            onChange && onChange(e.target.checked);
          }}
          name="avialability"
          id="avialability"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )}
    </Box>
  );
};

export default CustomSwitch;
