import env from '../env';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import axios from '../utils/axios';
import firebaseService from './firebaseService';

const editProduct = async (id, fields) =>
  admin
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(id)
    .update({ ...fields });

export default editProduct;

export const editRestaurant = async (id, fields) => {
  return admin
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(id)
    .set({ ...fields }, { merge: true });
};

export const getOwner = async restId => {
  const userSnap = await admin
    .firestore()
    .collection(COLLECTIONS.USER)
    .where('restaurant_id', '==', restId)
    .get();
  const [owner] = userSnap.docs
    .map(doc => ({ ...doc.data() }))
    .filter(user => user.roles.OWNER);
  return owner;
};

export const updateMinimumPurchase = async (restaurantId, minimumPurchase) => {
  const updateMinimumPurchaseResult = admin
    .functions()
    .httpsCallable(`restaurant/minimumPurchaseRestaurant`);
  return updateMinimumPurchaseResult({
    restaurant_id: restaurantId,
    minimum_purchase: minimumPurchase
  });
};

export const createUpdateMenuSectionSchedule = async data => {
  const information = {
    id: data.id,
    restaurant_id: data.restaurant_id,
    subsidiary_id: data.subsidiary_id,
    menu_section: data.menu_section,
    is_shared_menu: data.is_shared_menu
  };
  const result = await axios.post('/restaurant/menuSectionSchedule', {
    data: information
  });
  return result;
};

export const setLogsRestaurant = async (data, idRest) => {
  const id = firebaseService.randomId(COLLECTIONS.LOGS);
  await admin
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(idRest)
    .collection(COLLECTIONS.LOGS)
    .doc(id)
    .set({ ...data, id });
};

export const getOrderDeliveryMap = async dataFilter => {
  let response = {
    error: true,
    message: 'invalid filter',
    payload: []
  };
  try {
    const result = await axios.get(
      `${env.BACKEND_URL}Restaurants/getOrderLocationsByFilters`,
      {
        params: { ...dataFilter }
      }
    );
    if (result.status === 200 && result.data.items.length > 0) {
      response = {
        error: false,
        payload: result.data.items
      };
    }
  } catch (error) {
    console.log('error', error);
  }
  return response;
};
