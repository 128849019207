import authService from 'src/services/authService';
import firebaseService from 'src/services/firebaseService';
import Restaurant from 'src/dto/restaurant';
import Subsidiary from 'src/dto/subsidiary';
import admin from 'src/config/firebaseConfig';
import {
  isAdmin,
  isCashier,
  isMerchant,
  isOwner,
  isSupervisor
} from '../utils/helpers/userHelper';
import { REGISTER_STATUS } from '../dto/enum';
import {
  getSubsidiariesIds,
  getRestaurantsIds,
  getSubsidiariesMerged,
  getRestaurantsMerged
} from '../services/subsidiariesRestaurantsMergedService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_SUBSIDIARY = '@account/update-subisdiary';
export const UPDATE_SCHEDULE = '@account/update-schedule';

export function getUserSubsidiaries(currentUser, subsidiaries) {
  let userSubsidiaries = subsidiaries;
  if (isCashier(currentUser)) {
    const [defaultSubsidiary] = currentUser.subsidiary_id;
    const currentSubsidiary = subsidiaries.find(
      subsidiary => subsidiary.id === defaultSubsidiary
    );
    userSubsidiaries = [currentSubsidiary];
  }
  if (isSupervisor(currentUser)) {
    userSubsidiaries = userSubsidiaries.filter(s =>
      currentUser.subsidiary_id.includes(s.id)
    );
  }
  if (isOwner(currentUser)) {
    userSubsidiaries = subsidiaries;
  }
  return userSubsidiaries;
}

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      let user = '';
      let accessToken = '';
      if (email && password) {
        user = await authService.loginWithEmailAndPassword(email, password);
        accessToken = user.refreshToken;
      }
      // if (!user.restaurant_id) {
      //   throw new Error("This user doesn't belong to any restaurant");
      // }
      if (email === 'google') user = await authService.loginWithGoogle();
      if (email === 'facebook') user = await authService.loginWithFacebook();

      // Token safeguard
      try {
        const token = await admin.messaging().getToken();
        await firebaseService.updateDoc(`users/${user.id || user.uid}`, {
          ...user,
          current_device_id_web: accessToken,
          device_id: admin.firestore.FieldValue.arrayUnion(token)
        });
        user.token = token;
      } catch (error) {
        await firebaseService.updateDoc(`users/${user.id || user.uid}`, {
          ...user,
          current_device_id_web: accessToken
        });
        user.token = null;
      }
      if (isAdmin(user)) {
        authService.logout();
        dispatch({
          type: LOGOUT
        });
        return 'admin';
      }
      const payload = { user };
      const rest = await firebaseService.getDbRestaurant(user.restaurant_id);
      if (rest?.denied_merch === true) {
        authService.logout();
        dispatch({
          type: LOGOUT
        });
        return 'xpress_motos';
      }
      if (isMerchant(user)) {
        let restaurant = await firebaseService.getDocumentWithJoin(
          'restaurants',
          user.restaurant_id,
          'subsidiary',
          'restaurant_id',
          user.restaurant_id
        );
        let subsidiaries = [];
        if (restaurant.register_status === REGISTER_STATUS.ACTIVE) {
          subsidiaries = restaurant.subsidiary.filter(
            s => s.register_status === REGISTER_STATUS.ACTIVE
          );
        }
        if (restaurant.register_status === REGISTER_STATUS.SUSPENDED) {
          subsidiaries = restaurant.subsidiary.filter(
            s => s.register_status === REGISTER_STATUS.SUSPENDED
          );
        }
        restaurant = new Restaurant(restaurant);
        subsidiaries = subsidiaries.map(
          subsidiary => new Subsidiary(subsidiary)
        );
        restaurant.subsidiary = subsidiaries;
        payload.restaurant = restaurant;
        const userSubsidiaries = getUserSubsidiaries(user, subsidiaries);
        const subsWithRestsMerged =
          userSubsidiaries[0].subsidiariesWithRestaurantsMerged;
        const subsidiariesIds = getSubsidiariesIds(subsWithRestsMerged);
        const restMerged = restaurant.restaurantsMerged;
        const restaurantsIds = getRestaurantsIds(restMerged);
        const subsidiariesMerged = await getSubsidiariesMerged(subsidiariesIds);
        const allRestaurantsMerged = await getRestaurantsMerged(restaurantsIds);
        payload.subsidiariesMerged = subsidiariesMerged;
        payload.allRestaurantsMerged = allRestaurantsMerged;
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload
      });
      return '';
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user, restaurant) {
  return async dispatch => {
    const payload = { user, restaurant };
    const userSubsidiaries = getUserSubsidiaries(user, restaurant.subsidiary);
    const subsWithRestsMerged =
      userSubsidiaries[0].subsidiariesWithRestaurantsMerged;
    const subsidiariesIds = getSubsidiariesIds(subsWithRestsMerged);
    const restMerged = restaurant.restaurantsMerged;
    const restaurantsIds = getRestaurantsIds(restMerged);
    const subsidiariesMerged = await getSubsidiariesMerged(subsidiariesIds);
    const allRestaurantsMerged = await getRestaurantsMerged(restaurantsIds);
    payload.subsidiariesMerged = subsidiariesMerged;
    payload.allRestaurantsMerged = allRestaurantsMerged;
    dispatch({
      type: SILENT_LOGIN,
      payload
    });
  };
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(user) {
  return dispatch =>
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user
      }
    });
}
