import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { Box, Typography } from '@material-ui/core';

const Information = () => {
  const translation = useTranslation();
  return (
    <Box style={{ marginLeft: 60, marginBottom: 20 }}>
      <Box style={{ textAlign: 'center', marginBottom: 40 }}>
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
          {translation('free_delivery.title')}
        </Typography>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <Typography variant="body1">
          {translation('free_delivery.body.paragraph_one')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          {translation('free_delivery.body.list_title')}
        </Typography>
        <ul style={{ marginLeft: 25 }}>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_one')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_two')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_three')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_four')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_five')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_six')}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {translation('free_delivery.body.list_seven')}
            </Typography>
          </li>
        </ul>
      </Box>
      <Box style={{ margin: '20px 0px' }}>
        <Typography variant="body1">
          {translation('free_delivery.body.footer')}
        </Typography>
      </Box>
      <Box />
    </Box>
  );
};

export default Information;
