import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  makeStyles,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import CardContent from '@material-ui/core/CardContent';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { GreenButton } from '../../../../components/Inputs/Buttons';
import { useAdditionsPerRestaurant } from '../../../../hooks/useAdditions';
import { useRestaurant } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import DragDropAdditions from './DragDropAdditions';
import { useAddition } from '../../../../hooks/Additions/useAddition';
import ModalPickAddition from '../../../aditions/AdditionEditView/ModalPickAddition';

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%'
  },
  noAdditions: {
    color: theme.palette.text.tertiary
  },
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));

const AdditionsPicker = ({
  product = {},
  restIdFromState = null,
  blockCashier
}) => {
  const translation = useTranslation();
  const classes = useStyles();
  const { prodId } = useParams();
  const { restId } = useParams();
  const isTemplate = useIsTemplateMenu();
  const additionsHook = useAddition(prodId);
  const restaurant = useRestaurant(restId);
  const restaurantAdditions = useAdditionsPerRestaurant(restId);
  const productAdditions = additionsHook;
  const [showList, setShowList] = useState(false);
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Card>
        <CardContent>
          <Box mt={1} mb={1} display="flex" justifyContent="space-between">
            <Typography variant="h4">
              <strong>
                {translation('edit.product_create.additions.header')}
              </strong>
            </Typography>
            <GreenButton
              size="small"
              variant="contained"
              onClick={() => setShowList(true)}
              disabled={!isTemplate}
            >
              {translation('additions.picker.button')}
            </GreenButton>
          </Box>
        </CardContent>
      </Card>
      <Box mt={3} className={classes.wrapper}>
        {isLoading && (
          <Box className={classes.loadingWrapper}>
            <CircularProgress />
          </Box>
        )}
        {productAdditions.length ? (
          <DragDropAdditions
            additions={productAdditions}
            restaurantAdditions={restaurantAdditions}
            prodId={prodId}
            restIdFromState={restaurant.id}
            blockCashier={blockCashier}
            setLoading={setLoading}
            isTemplate={isTemplate}
          />
        ) : (
          <Card>
            <Box m={3} display="flex" justifyContent="center">
              <Typography className={classes.noAdditions}>
                {translation('additions.picker.no_additions')}
              </Typography>
            </Box>
          </Card>
        )}
      </Box>
      <ModalPickAddition
        show={showList}
        onClose={() => setShowList(false)}
        product={product}
        restaurantAdditions={restaurantAdditions}
        productAdditions={productAdditions}
        restaurantId={restaurant.id}
      />
    </>
  );
};

export default AdditionsPicker;
