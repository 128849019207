import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// import 'src/mock';  // TODO: Just to see the examples
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import Store from 'src/store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import App from 'src/App';
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
import moment from 'moment';
import admin, { fbMessaging } from './config/firebaseConfig';

import en_us from './translation/en_us.json';
import es_lt from './translation/es_lt.json';
import 'moment/locale/es';
import Logger from './modules/logs';

enableES5();

// to set settings configuration
// const settings = restoreSettings();
const rrfProps = {
  firebase: admin,
  config: {},
  dispatch: Store.dispatch,
  createFirestoreInstance
};
setDefaultTranslations({ es_lt, en_us });
setDefaultLanguage('es_lt');
moment.locale('es');
moment().format('LLLL');

async function setUpNotifications() {
  try {
    if (fbMessaging) {
      await admin.messaging().requestPermission();
    }
  } catch (e) {
    console.log('Notifications disabled on icognito mode');
  }
}

window.onerror = (event, source, lineno, colno, error) => {
  const stack = {};
  Error.captureStackTrace(stack);
  Logger.error({ error, data: { loggerLine: stack.stack } });
};

setUpNotifications();
ReactDOM.render(
  <Provider store={Store}>
    <SettingsProvider>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </ReactReduxFirebaseProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
