import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import Results from './Results';
import SubsidiaryMerged from './SubsidiaryMerged';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  containerHeaderSubsidiaries: {
    width: '100%',
    backgroundColor: 'white',
    height: '80px',
    padding: '0px 24px'
  }
}));

function ScheduleManagementView() {
  const classes = useStyles();
  const translation = useTranslation();
  const [subsidiaryIdSelected, setSubsidiaryIdSelected] = useState(null);

  return (
    <Page
      className={classes.root}
      title={translation('admin_subsidiary.tabs.schedule')}
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Box mb={3} className={classes.containerHeaderSubsidiaries}>
            <SubsidiaryMerged onChange={setSubsidiaryIdSelected} />
          </Box>
          <Results
            key={`schedule_selector_${subsidiaryIdSelected}`}
            subsidiaryId={subsidiaryIdSelected}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default ScheduleManagementView;
