/* eslint-disable camelcase */
import firebase from 'firebase/app';
import { LOG_ACTION } from '../enum';
import { PLATFORM_NAME } from '../../config';

export default class OrderHistory {
  constructor({
    action = LOG_ACTION.NONE,
    canceled_reason = '',
    created_at = firebase.firestore.FieldValue.serverTimestamp(),
    delivery_status = '',
    driver = {},
    from = PLATFORM_NAME,
    id = '',
    role = '',
    status = '',
    user = {},
    verified = false
  }) {
    this.action = action;
    this.canceled_reason = canceled_reason;
    this.created_at = created_at;
    this.delivery_status = delivery_status;
    this.driver = driver;
    this.from = from;
    this.id = id;
    this.role = role;
    this.action = action;
    this.status = status;
    this.user = user;
    this.verified = verified;
  }
}
