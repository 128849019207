import { useEffect, useState } from 'react';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import { useRestaurant } from './useRestaurant';

export function useCurrentRestaurantSubsidiaries() {
  const [result, setResult] = useState([]);
  const currentRestaurant = useRestaurant();
  useEffect(() => {
    if (currentRestaurant) {
      return admin
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .where('restaurant_id', '==', currentRestaurant.id)
        .where('register_status', '==', 'ACTIVE')
        .onSnapshot(snap => {
          setResult(snap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        });
    }
    setResult([]);
  }, [currentRestaurant]);
  return result;
}
