import { ORDER_STATUS } from '../dto/enum';

export default function orderStatusTranslate(orderStatus, translation) {
  let status = '';
  let statusClass = '';
  switch (orderStatus) {
    case ORDER_STATUS.ON_CART:
      status = translation('orders.details.order_status.car');
      statusClass = '';
      break;
    case ORDER_STATUS.IN_PROCESS:
      status = translation('orders.details.order_status.process');
      statusClass = 'new';
      break;
    case ORDER_STATUS.NEED_CHANGES:
      status = translation('orders.details.order_status.need_changes');
      statusClass = 'needChanges';
      break;
    case ORDER_STATUS.CHANGES_ACCEPTED:
      status = translation('orders.details.order_status.change_accepted');
      statusClass = 'changesAccepted';
      break;
    case ORDER_STATUS.ACCEPTED:
      status = translation('orders.details.order_status.accepted');
      statusClass = 'accepted';
      break;
    case ORDER_STATUS.READY_TO_PICK:
      status = translation('orders.details.order_status.ready_to_pick');
      statusClass = 'readyToPick';
      break;
    case ORDER_STATUS.READY_TO_CASHIER:
      status = translation('orders.details.order_status.accepted');
      statusClass = 'readyToCashier';
      break;
    case ORDER_STATUS.DELIVERING:
      status = translation('orders.details.order_status.delivering');
      statusClass = 'delivering';
      break;
    case ORDER_STATUS.COMPLETED:
      status = translation('orders.details.order_status.completed');
      statusClass = 'completed';
      break;
    case ORDER_STATUS.CANCELED:
      status = translation('orders.details.order_status.canceled');
      statusClass = 'canceled';
      break;
    default:
      break;
  }
  return { status, statusClass };
}
