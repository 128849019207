import React from 'react';
import {
  Container,
  Box,
  makeStyles,
  Divider,
  Typography
} from '@material-ui/core';
import { COLORS } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  textTitle: {
    fontWeight: 'bold'
  },
  barSelected: {
    height: '8px'
  },
  containerSelected: {
    width: '242px',
    height: '68px'
  },
  typeDelivery: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}));

const SelectedDelivery = ({
  setFreeDelivery,
  freeDelivery,
  subsidyDelivery,
  setSubsidyDelivery
}) => {
  const classes = useStyles();

  const handleFreeDelivery = () => {
    setFreeDelivery(true);
    setSubsidyDelivery(false);
  };

  const handleSubsidyDelivery = () => {
    setFreeDelivery(false);
    setSubsidyDelivery(true);
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <Box className={classes.containerSelected}>
          <Box className={classes.typeDelivery} onClick={handleSubsidyDelivery}>
            <Typography
              style={subsidyDelivery ? { color: COLORS.ligthYellow } : null}
              variant="h3"
              className={classes.textTitle}
            >
              Precios de Delivery
            </Typography>
          </Box>
          <Divider
            style={
              !freeDelivery
                ? { backgroundColor: COLORS.ligthYellow }
                : { backgroundColor: COLORS.white }
            }
            className={classes.barSelected}
          />
        </Box>
        <Box className={classes.containerSelected}>
          <Box className={classes.typeDelivery} onClick={handleFreeDelivery}>
            <Typography
              style={freeDelivery ? { color: COLORS.ligthYellow } : null}
              variant="h3"
              className={classes.textTitle}
            >
              Delivery Gratis
            </Typography>
          </Box>
          <Divider
            style={
              freeDelivery
                ? { backgroundColor: COLORS.ligthYellow }
                : { backgroundColor: COLORS.white }
            }
            className={classes.barSelected}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default SelectedDelivery;
