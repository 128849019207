import React from 'react';
import { useParams } from 'react-router';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../../components/Page';
import { COLLECTIONS } from '../../../../constants';
import Header from './Header';
import AreasAssignForm from './AreasAssignForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
const AreasAssignView = props => {
  const classes = useStyles();
  const params = useParams();
  const translation = useTranslation();
  useFirestoreConnect({
    collection: COLLECTIONS.AREAS,
    doc: params.id
  });

  const areaStore = useSelector(state => {
    if (
      state.firestore.data[COLLECTIONS.AREAS] &&
      state.firestore.data[COLLECTIONS.AREAS][params.id]
    ) {
      return state.firestore.data[COLLECTIONS.AREAS][params.id];
    }
  });
  let area = {};
  let titleEdit = false;
  if (params.id) {
    if (!areaStore) return null;
    area = {
      id: params.id,
      ...areaStore
    };
    if (areaStore.location) {
      area.location = {
        lat: areaStore.location.latitude,
        lng: areaStore.location.longitude
      };
    }

    titleEdit = true;
  }

  return (
    <Page
      className={classes.root}
      title={
        titleEdit
          ? translation('areas.edit_area')
          : translation('areas.new_area')
      }
    >
      <Container maxWidth="lg">
        <Header area={area} />
        <Box mt={3}>{area.id && <AreasAssignForm area={area} />}</Box>
      </Container>
    </Page>
  );
};

export default AreasAssignView;
