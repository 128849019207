import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { COLLECTIONS, registerStatus } from '../../../constants';
import { orderByAsc } from '../../../utils/array';
import admin from '../../../config/firebaseConfig';

export const useAllRestaurantSubsidiaries = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      where: [['restaurant_id', '==', restId]],
      storeAs: `current_restaurant_subsidiaries`
    }
  ]);
  const allSubsidiaries = useSelector(
    state => state.firestore.ordered.current_restaurant_subsidiaries
  );
  return allSubsidiaries;
};

export const useAllActiveRestaurantSubsidiaries = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      where: [
        ['restaurant_id', '==', restId],
        ['register_status', '==', registerStatus.ACTIVE]
      ],
      storeAs: `all_active_restaurant_subsidiaries`
    }
  ]);
  const allSubsidiaries = useSelector(
    state => state.firestore.ordered.all_active_restaurant_subsidiaries
  );
  return allSubsidiaries;
};

export const useSubsidiariesPerCity = cityId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      where: [['city.id', '==', cityId]],
      storeAs: `subsidiariesPerCity`
    }
  ]);
  const allSubsidiaries = useSelector(
    state => state.firestore.ordered.subsidiariesPerCity
  );
  if (allSubsidiaries) {
    return allSubsidiaries;
  }
  return [];
};

export const useAllSubsidiaries = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      storeAs: 'all_subsidiaries'
    }
  ]);
  const allSubsidiaries = useSelector(
    state => state.firestore.ordered.all_subsidiaries
  );
  if (!allSubsidiaries) return [];
  return allSubsidiaries;
};

export function useAllActiveSubsidiaries() {
  const subsidiaries = useAllSubsidiaries();
  if (subsidiaries) {
    const result = subsidiaries.filter(
      item => item.register_status === registerStatus.ACTIVE
    );
    return orderByAsc(result, item => item.name);
  }
  return [];
}

export const useSubsidiary = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      doc: id,
      storeAs: id ? 'current_subsidiary' : 'all_subsidiaries'
    }
  ]);
  const currentSubsidiary = useSelector(state =>
    id
      ? state.firestore.data.current_subsidiary
      : state.firestore.ordered.all_subsidiaries
  );
  return currentSubsidiary;
};

export const useSubsidiaryById = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      doc: id,
      storeAs: 'subsidiary_id'
    }
  ]);
  const subsidiary = useSelector(state => state.firestore.data.subsidiary_id);
  return subsidiary;
};

export const useSubsidiaryByIdAsyncNative = subsidiaryId => {
  const [result, setResult] = useState(null);
  useEffect(() => {
    if (subsidiaryId) {
      admin
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(subsidiaryId)
        .get()
        .then(snap => {
          if (snap.exists) {
            setResult(snap.data());
          } else {
            setResult(null);
          }
        });
    } else {
      setResult(null);
    }
  }, [subsidiaryId]);
  return result;
};
export const useSubsidiaryId = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      doc: id,
      storeAs: `subsidiary_${id}`
    }
  ]);
  const subsidiary = useSelector(
    state => state.firestore.data[`subsidiary_${id}`]
  );
  return subsidiary;
};

export const useSubsidiaryProducts = (subId, restId) => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      where: [
        ['subsidiary_id', '==', subId || ''],
        ['restaurant_id', '==', restId || ''],

        ['enable', '==', true]
      ],
      storeAs: 'subsidiary_products'
    }
  ]);
  const subsidiaryProducts = useSelector(
    state => state.firestore.ordered.subsidiary_products
  );
  return subsidiaryProducts;
};
