import React from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Table,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-multi-lang/lib';
import { Plus as PlusIcon } from 'react-feather';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    minWidth: '100%'
  },
  plusIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'text-bottom'
  },
  card: {
    margin: '5% 5% 5% 0',
    padding: theme.spacing(4)
  },
  cardDetail: {
    width: '100%'
  },
  cardAddition: {
    maxWidth: '100%',
    margin: '5% 5% 5% 5%',
    padding: theme.spacing(4)
  }
}));

const OrderForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <>
      <Divider />
      <Grid container>
        <Grid md={6} sm={12}>
          <Card className={classes.card}>
            <Grid container>
              <Grid md={6} xl={6}>
                <Typography variant="body2" color="textPrimary">
                  {translation('dashboard_admin.order_status')}
                  {':'}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {translation('dashboard_admin.order_time')}
                  {':'}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {translation('dashboard_admin.order_date')}
                  {':'}
                </Typography>
              </Grid>
              <Grid md={6} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  111
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  111
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  111
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid md={6} sm={12}>
          <Card className={classes.card}>
            <Grid xs={12} md={12} xl={12}>
              <Typography variant="body2" color="textPrimary">
                {translation('orders.details.billing.title')}
                {':'}
              </Typography>
            </Grid>
            <Grid container>
              <Grid xs={6} md={6} xl={6}>
                <Typography variant="body2" color="textPrimary">
                  {translation('orders.details.billing.name_lastname')}
                  {':'}
                </Typography>
              </Grid>
              <Grid xs={6} md={6} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  111
                </Typography>
              </Grid>
              <Grid xs={6} md={6} xl={6}>
                <Typography variant="body2" color="textPrimary">
                  {translation('dashboard_admin.ci_nit')}
                  {':'}
                </Typography>
              </Grid>
              <Grid xs={6} md={6} xl={6}>
                <Typography variant="body2" color="textSecondary">
                  111
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Card
          className={clsx(classes.root, className, classes.table)}
          {...rest}
        >
          <Box p={5}>
            <Typography variant="body2" color="textPrimary">
              {translation('register_restaurant.name')}
            </Typography>
            <Typography ariant="body2" color="textSecondary">
              Pollos Copacabana
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {translation('dashboard_admin.kind_food')}
            </Typography>
            <Typography ariant="body2" color="textSecondary">
              Pollos
            </Typography>
          </Box>
          <Divider />
          <Box p={5}>
            <Table>
              <TableRow>
                <TableCell align="left">
                  <Typography>Hamburguesa Mexicana</Typography>
                </TableCell>
                <TableCell />
                <TableCell align="right">70</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell colSpan={1}>
                  <Typography>
                    <PlusIcon className={classes.plusIcon} />
                    Cebolla Caramelizada
                  </Typography>
                </TableCell>
                <TableCell align="right">5 Bs.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>
                  <Typography variant="h6" align="right">
                    {translation('orders.details.order_table.total')}
                    20
                  </Typography>
                  <Card className={classes.cardAddition} align="left">
                    <Box>
                      <Typography variant="body1" color="textPrimary">
                        {translation(
                          'orders.details.additional_reference.title'
                        )}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Ej.Utencilios incluidos
                      </Typography>
                    </Box>
                  </Card>
                </TableCell>
              </TableRow>
            </Table>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default OrderForm;
