const INITIAL_STATE = {
  subsidiaries: []
};

const ACTIONS = {
  SET_SUBSIDIARIES: 'SET_SUBSIDIARIES'
};

export function setSubsidiaries(subsidiaries) {
  return { type: ACTIONS.SET_SUBSIDIARIES, data: subsidiaries };
}

export default function restaurant(state = INITIAL_STATE, { type, data }) {
  switch (type) {
    case ACTIONS.SET_SUBSIDIARIES:
      return { ...state, subsidiaries: data };
    default:
      return state;
  }
}
