import React from 'react';
import { useParams } from 'react-router';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import firebaseService from '../../../../services/firebaseService';
import { addProduct } from '../../../../services/productsService';
import { addSharedProduct } from '../../../../services/sharedProductsService';
import { useRestaurantAndSubsidiaryforMenu } from '../../../../hooks/useRestaurant';
import { COLLECTIONS } from '../../../../constants';
import { logProduct } from '../../../../services/logServices';
import { LOG_ACTIONS } from '../../../../dto/enum';
import { getObjectChanges } from '../../../../utils/object';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = props => {
  const {
    sectionName,
    className,
    sectionId,
    isEditable,
    blockCashier,
    sizeProducts,
    ...rest
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const translation = useTranslation();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant, subsidiary] = useRestaurantAndSubsidiaryforMenu(
    restId,
    subId
  );
  const isTemplate = useIsTemplateMenu();
  const prefix = isTemplate
    ? `/app/management/shared_menu/restId/${restId}`
    : `/app/editor/menu-sections/restId/${restId}/subId/${subId}`;

  const createProduct = async () => {
    const restaurantId = restId;
    const productId = firebaseService.randomId(COLLECTIONS.PRODUCTS);
    if (isTemplate) {
      const currentProduct = await addSharedProduct(
        productId,
        sectionId,
        restaurantId,
        sizeProducts
      );
      const changes = getObjectChanges(null, { ...currentProduct, photo: '' });
      delete changes.after.subsidiary_id;
      const data = {
        product: {
          id: currentProduct.id,
          name: currentProduct.name
        },
        restaurant: {
          id: restaurant.id,
          name: restaurant.name
        }
      };
      await logProduct(changes, LOG_ACTIONS.CREATE, data);
    } else {
      addProduct(productId, sectionId, subId, restaurantId, sizeProducts);
    }
    history.push({
      pathname: `${prefix}/${sectionId}/${productId}`,
      state: { productId }
    });
  };

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              variant="body1"
              color="inherit"
              to={prefix}
              component={RouterLink}
            >
              {translation('menu.menu_section.label')}
            </Link>
            <Link
              variant="body1"
              color="inherit"
              to={{
                pathname: `${prefix}/${sectionId}`,
                state: { sectionId }
              }}
              component={RouterLink}
            >
              {sectionName}
            </Link>
          </Breadcrumbs>
          <Typography variant="body2" color="textPrimary">
            {translation('edit.header.advice')}
          </Typography>
        </Grid>
        <Grid>
          {isTemplate ? (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''}`}
            </Typography>
          ) : (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''} - ${subsidiary.name || ''}`}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            onClick={createProduct}
            disabled={!isEditable}
            style={{ cursor: 'pointer' }}
          >
            {translation('edit.product_create.head')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {blockCashier && (
          <Alert severity="warning">
            {translation('information.block_Cashier')}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
