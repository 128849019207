import React from 'react';
import { useParams } from 'react-router';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../../components/Page';
import { COLLECTIONS } from '../../../../constants';
import Header from './Header';
import PromosEditForm from '../PromosEditView/PromosEditForm';
import { useAreas } from '../../../../hooks/AdminHooks/areas/useAreas';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
const PromosDetailView = props => {
  const classes = useStyles();
  const params = useParams();
  const translation = useTranslation();
  const areas = useAreas();
  const cities = useCities();

  useFirestoreConnect({
    collection: COLLECTIONS.PROMOS,
    doc: params.id
  });

  const promoStore = useSelector(state => {
    if (
      state.firestore.data[COLLECTIONS.PROMOS] &&
      state.firestore.data[COLLECTIONS.PROMOS][params.id]
    ) {
      return state.firestore.data[COLLECTIONS.PROMOS][params.id];
    }
  });
  let promo = {};
  let titleEdit = false;
  if (params.id) {
    if (!promoStore) return null;
    promo = {
      id: params.id,
      ...promoStore
    };
    titleEdit = true;
  }
  if (!areas.length) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title={
        titleEdit
          ? translation('admin_promos.edit')
          : translation('admin_promos.new')
      }
    >
      <Container maxWidth="lg">
        <Header promo={promo} />
        <Box mt={3}>
          <PromosEditForm promo={promo} areas={areas} cities={cities} />
        </Box>
      </Container>
    </Page>
  );
};

export default PromosDetailView;
