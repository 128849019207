import React, { useMemo } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { CHARGE_TYPE } from 'src/constants';
import { SUBSCRIPTION_INITIAL_VALUES, TYPE_CHARGE } from 'src/dto/enum';
import { useTranslation } from 'react-multi-lang';
import { useChargesHistoryByRestaurant } from 'src/hooks/chargesHistory/useChargesHistory';
import CardsSection from './cardsSection';
import CardSectionSubscription from './cardSectionSubscription';
import HistorySection from './historySection';
import { useRestaurantInvoiceWithoutStatusNative } from '../../../../hooks/useInvoice';

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%'
  },
  title: {
    fontSize: '23px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
}));

const CashPayment = ({ restaurant }) => {
  const classes = useStyles();
  const translate = useTranslation();
  const invoices = useRestaurantInvoiceWithoutStatusNative(restaurant.id || '');
  const [currentChargesHistory] = useChargesHistoryByRestaurant(restaurant.id);
  const [chargesHist, chargesSubscription] = useMemo(() => {
    if (currentChargesHistory) {
      const subscriptionDetails =
        currentChargesHistory.chargeType === TYPE_CHARGE.COMMISSION
          ? SUBSCRIPTION_INITIAL_VALUES
          : currentChargesHistory.subscriptionDetails;
      return [currentChargesHistory.charges, subscriptionDetails];
    }
    if (restaurant.charge) {
      return [
        {
          pick_up: restaurant.charge.pick_up || 0,
          delivery: restaurant.charge.delivery,
          xpress_motos: restaurant.charge.xpress_motos || 0
        },
        restaurant?.subscriptionDetails || SUBSCRIPTION_INITIAL_VALUES
      ];
    }
    return [];
  }, [restaurant, currentChargesHistory]);

  const cashInvoices = useMemo(() => {
    if (invoices) {
      return invoices.filter(invoice => invoice.summary?.cash?.total_sales);
    }
    return [];
  }, [invoices]);
  return (
    <Box p={3} flexDirection="row">
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid item className={classes.row}>
          <Box className={classes.cardContainer}>
            <Box>
              {restaurant.charge_type === TYPE_CHARGE.COMMISSION && (
                <CardsSection
                  restaurant={restaurant}
                  title={CHARGE_TYPE[restaurant.charge_type]}
                  currentChargesHistory={currentChargesHistory}
                  charge={chargesHist}
                />
              )}
              {restaurant.charge_type === TYPE_CHARGE.SUBSCRIPTION && (
                <CardSectionSubscription
                  restaurant={restaurant}
                  title={CHARGE_TYPE[restaurant.charge_type]}
                  subscriptionDetails={chargesSubscription}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.row}>
          <Typography className={classes.title}>
            {translate('orders.history.history')}
          </Typography>
          <HistorySection invoices={cashInvoices} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CashPayment;
