import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  Link
} from '@material-ui/core';
import { login } from 'src/actions/accountActions';
import { useTranslation } from 'react-multi-lang/lib';
import { FaFacebookF, FaGoogle } from 'react-icons/fa';

const useStyles = makeStyles(() => ({
  root: {}
}));

function LoginNetworksForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();

  return (
    <Formik
      initialValues={{ isSecondButton: false }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.isSecondButton) {
            await dispatch(login('facebook'));
          } else {
            await dispatch(login('google'));
          }
        } catch (error) {
          const message =
            error.code && error.message
              ? error.message
              : translation('login.without_authorize');
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, setFieldValue, isSubmitting }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              className={className.button}
              startIcon={<FaGoogle className={className.google} />}
            >
              <FormHelperText className={className.google}>
                GOOGLE
              </FormHelperText>
            </Button>
          </Box>
          <Box mt={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              className={className.button}
              startIcon={<FaFacebookF className={className.facebook} />}
              onClick={e => {
                setFieldValue('isSecondButton', true);
                handleSubmit(e);
              }}
            >
              <FormHelperText className={className.facebook}>
                FACEBOOK
              </FormHelperText>
            </Button>
          </Box>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={3} display="flex">
            <FormHelperText variant="body2" className={classes.helper}>
              {translation('social_networks.note')}
              <Link
                component={RouterLink}
                to="/register"
                variant="body2"
                color="textSecondary"
              >
                {translation('login.sing_up')}
              </Link>
            </FormHelperText>
          </Box>
        </form>
      )}
    </Formik>
  );
}

LoginNetworksForm.propTypes = {
  className: PropTypes.string
};

export default LoginNetworksForm;
