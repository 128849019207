import React from 'react';
import { Typography, Box, List, ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  containerMain: {
    backgroundColor: 'rgba(255, 0, 0, 0.3)'
  },
  titleText: {
    fontWeight: 'bold'
  }
}));

const RedListSubsidiary = ({ listSubsidiaries }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.containerMain}
    >
      <Box mt={2} textAlign="center">
        <Typography variant="h4" className={classes.titleText}>
          Verifica las sucursales
        </Typography>
      </Box>
      <List>
        {listSubsidiaries?.map(subsidiary => (
          <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <Typography variant="h5">{subsidiary.name}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default RedListSubsidiary;
