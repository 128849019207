import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import WithLoading from '../../../../components/WithLoading';
import userService from '../../../../services/usersService';
import { USER_ROLES } from '../../../../dto/enum';
import { getUserRole } from '../../../../utils/helpers/userHelper';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  }
}));

function UserEditForm({ className, user = {}, def = {}, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const roles = [
    {
      value: USER_ROLES.SUPER_ADMIN,
      label: translation('admin_express_users.rol.admin')
    },
    {
      value: USER_ROLES.ACCOUNTANT,
      label: translation('admin_express_users.rol.accountant')
    },
    {
      value: USER_ROLES.CUSTOMER_SERVICE,
      label: translation('admin_express_users.rol.customer_service_single')
    },
    {
      value: USER_ROLES.MARKETING,
      label: translation('admin_express_users.rol.marketing')
    }
  ];

  const saveUser = async userForm => {
    setLoading(true);
    const userData = {
      ...userForm,
      roles: {
        [USER_ROLES.SUPER_ADMIN]: false,
        [USER_ROLES.ACCOUNTANT]: false,
        [USER_ROLES.CUSTOMER_SERVICE]: false,
        [USER_ROLES.MARKETING]: false,
        [userForm.roles]: true
      }
    };
    if (user.id) {
      delete userData.email;
      return userService.editUser(user.id, userData, true);
    }
    return userService.saveUser(userData, true);
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          phone_number: user.phone_number || '',
          email: user.email || '',
          roles: getUserRole(user) || ''
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string()
            .max(255)
            .required(
              translation('basic_information.personal_info.name.require')
            ),
          last_name: Yup.string()
            .max(255)
            .required(
              translation('basic_information.personal_info.lastname.require')
            ),
          // phone_number: Yup.string().max(255),
          email: Yup.string()
            .email(translation('login.email.valid'))
            .max(255)
            .required(translation('login.email.require')),
          phone_number: Yup.string().max(255),
          roles: Yup.mixed()
            .oneOf([
              USER_ROLES.SUPER_ADMIN,
              USER_ROLES.ACCOUNTANT,
              USER_ROLES.CUSTOMER_SERVICE,
              USER_ROLES.MARKETING
            ])
            .required(translation('customer_edit.profile_require'))
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await saveUser(values);

            setStatus({ success: true });
            setSubmitting(false);
            // resetForm();
            enqueueSnackbar(
              translation('admin_users.userEditView.correct_save'),
              {
                variant: 'success'
              }
            );
            setLoading(false);
            history.push('/admin/admin-users');
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            if (e.status === 409) {
              enqueueSnackbar(translation('customer_edit.used_email'), {
                variant: 'error'
              });
              setErrors({ email: translation('customer_edit.email') });
            } else {
              enqueueSnackbar(
                `${translation('complete_restaurant.error')} ${
                  env.REACT_APP_SUPPORT_MAIL
                }`,
                {
                  variant: 'error'
                }
              );
              setErrors({ submit: e.message });
            }
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        label={translation(
                          'basic_information.personal_info.name.name'
                        )}
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        label={translation(
                          'basic_information.personal_info.lastname.lastname'
                        )}
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={translation('login.email.label')}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                        disabled={!!user.id}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.phone_number && errors.phone_number
                        )}
                        fullWidth
                        helperText={touched.phone_number && errors.phone_number}
                        label={translation(
                          'basic_information.personal_info.phone.phone'
                        )}
                        name="phone_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone_number}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="roles"
                        onChange={handleChange}
                        select
                        label={translation('account.profile')}
                        SelectProps={{ native: true }}
                        value={values.roles}
                        variant="outlined"
                        error={Boolean(touched.roles && errors.roles)}
                        helperText={touched.roles && errors.roles}
                      >
                        <option />
                        {roles.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item />
                  </Grid>

                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation('save')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

UserEditForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default UserEditForm;
