import Base from './base';
import { registerStatuses } from './enum';
import { CHARGE_TYPE, RESTAURANT_DEFAULT_SETTINGS } from '../constants';
import SummaryInvoices from './restaurant/SummaryInvoices ';
/* eslint camelcase: 0 */
export default class Restaurant extends Base {
  constructor({
    id,
    enabled = true,
    block_cashier = false,
    created_at,
    updated_at,
    name,
    logo_photo,
    banner_photo,
    nit_photo,
    menu_file,
    cities_id,
    nit,
    enable_delivery_prices,
    accepted_terms = true,
    owner_id,
    register_status = registerStatuses.pending,
    register_basic_info = {
      computer_disponibility: false,
      internet_disponibility: false,
      personal_disponibility: false,
      experience_disponibility: false
    },
    menu_section = [],
    is_montly_paid = true,
    main_category,
    subsidiary_amount = 0,
    setting = RESTAURANT_DEFAULT_SETTINGS,
    hasSubsidiarySubsidy = false,
    deliveryFree = {},
    charges_online = {},
    show_user_phone = false,
    charge = {
      pickup: 0,
      delivery: 0
    },
    order_services = [],
    is_restaurant_invoiced = true,
    restaurantsMerged = [],
    summary_invoices = {},
    charge_type = CHARGE_TYPE.COMMISSION,
    denied_merch = false,
    xpress_motos = false,
    business_executive = {},
    invoice_name = '',
    invoice_contact_name = '',
    invoice_email = '',
    invoice_comments = '',
    restaurant_code = '',
    advertising_social_networks = {
      sign: false,
      stickers: false,
      url_facebook: '',
      url_instagram: '',
      url_other: ''
    },
    pickup_discount = 0,
    online_payment_activation_date = null,
    enable_advanced_metrics = false,
    enable_report_ratigns = false,
    show_past_orders = false
  } = {}) {
    super(id, enabled, created_at, updated_at);
    this.name = name;
    this.block_cashier = block_cashier;
    this.logo_photo = logo_photo;
    this.banner_photo = banner_photo;
    this.nit_photo = nit_photo;
    this.menu_file = menu_file;
    this.cities_id = cities_id;
    this.hasSubsidiarySubsidy = hasSubsidiarySubsidy;
    this.nit = nit;
    this.accepted_terms = accepted_terms;
    this.owner_id = owner_id;
    this.show_user_phone = show_user_phone;
    this.deliveryFree = deliveryFree;
    this.register_status = register_status;
    this.register_basic_info = register_basic_info;
    this.is_montly_paid = is_montly_paid;
    this.main_category = main_category;
    this.menu_section = menu_section;
    this.subsidiary_amount = subsidiary_amount;
    this.enable_delivery_prices = enable_delivery_prices;
    this.setting = setting;
    this.charge = charge;
    this.order_services = order_services;
    this.is_restaurant_invoiced = is_restaurant_invoiced;
    this.restaurantsMerged = restaurantsMerged;
    this.summary_invoices = new SummaryInvoices(summary_invoices);
    this.charge_type = charge_type;
    this.denied_merch = denied_merch;
    this.xpress_motos = xpress_motos;
    this.business_executive = business_executive;
    this.invoice_name = invoice_name;
    this.invoice_contact_name = invoice_contact_name;
    this.invoice_email = invoice_email;
    this.invoice_comments = invoice_comments;
    this.restaurant_code = restaurant_code;
    this.charges_online = charges_online;
    this.advertising_social_networks = advertising_social_networks;
    this.pickup_discount = pickup_discount;
    this.online_payment_activation_date = online_payment_activation_date;
    this.enable_advanced_metrics = enable_advanced_metrics;
    this.enable_report_ratigns = enable_report_ratigns;
    this.show_past_orders = show_past_orders;
  }

  toPlainObject() {
    return { ...this };
  }
}
