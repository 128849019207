import { makeStyles } from '@material-ui/core';

export const useOrderViewStyles = makeStyles(theme => ({
  commentBox: {
    marginTop: theme.spacing(2)
  }
}));

export const useCommentsStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  saveButton: {
    marginLeft: theme.spacing(4),
    fontWeight: 'bold',
    width: 150,
    height: 42
  }
}));

export const useHistoryStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  text: {
    fontSize: 18
  },
  mainText: {
    fontWeight: 'bold'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));
