import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Grid, Link, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({}));

const NavigationHistoryHeader = ({ history, translateName }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid
      className={clsx(classes.root)}
      container
      justify="space-between"
      spacing={3}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon style={{ fontSize: 24 }} />}
          aria-label="breadcrumb"
        >
          {history.map(item => (
            <Link
              style={{ fontSize: 24, fontWeight: 'bold' }}
              variant="body1"
              color="inherit"
              to={item.route}
              component={RouterLink}
            >
              {translateName && <>{translation(item.name)}</>}
              {!translateName && <>{item.name}</>}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default NavigationHistoryHeader;
