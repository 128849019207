import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/storage';
import AddIcon from '@material-ui/icons/Add';
import ButtonBase from '@material-ui/core/ButtonBase';
import { validateImageSizes } from '../../validations/image';
import { IMAGE_SIZES } from '../../constants';
import TranslateText from './TranslateText';
import { COLORS } from '../../theme/colors';
// import moment from 'moment'

interface StyleSheet {
  [key: string]: React.CSSProperties;
}

// eslint-disable-next-line no-unused-vars
const logoStyles: StyleSheet = {
  imageContainer: {
    width: 160,
    height: 160,
    padding: 13,
    marginBottom: 20
  }
};

const bannerStyles: StyleSheet = {
  imageContainer: {
    width: 368,
    height: 160,
    padding: 13
  }
};

const cardStyles: StyleSheet = {
  card: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 8,
    opacity: 1
  }
};

const commonStyles: StyleSheet = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8
  },
  container: {
    marginBottom: 16
  },
  addButtonContainer: {
    width: 0,
    height: 0,
    position: 'relative'
  }
};

class RestaurantPhotoForm extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef(null);
    this.state = {
      file: '',
      loadingImage: false,
      valid: {
        maxWidth: true,
        minWidth: true,
        maxHeight: true,
        minHeight: true,
        error: false,
        message: ''
      },
      banner: ''
    };
  }

  setImage = value => {
    this.setState({ banner: value });
  };

  setImageFile = value => {
    this.setState({ file: value });
  };

  setLoadingImage = value => {
    this.setState({ loadingImage: value });
  };

  setValid = value => {
    this.setState({ valid: value });
  };

  saveFile = event => {
    const { setImageFile, setImage } = this;
    setImageFile(URL.createObjectURL(event.target.files[0]));
    setImage(event.target.files[0]);
  };

  uploadFile = async () => {
    const { setLoadingImage } = this;
    const { restaurant, type } = this.props;
    const { banner } = this.state;
    if (banner) {
      setLoadingImage(true);
      const storageRef = firebase
        .storage()
        .ref(
          `restaurants/${type === 'banner' ? 'banner' : 'logo'}/${
            restaurant.id
          }`
        );
      await storageRef.put(banner);
      setLoadingImage(false);
    }
  };

  onButtonClick = () => {
    this.input.current.click();
  };

  getValidations = () => {
    const { type } = this.props;
    if (type === 'logo') {
      return {
        minHeight: IMAGE_SIZES.LOGO_MIN_HEIGHT,
        minWidth: IMAGE_SIZES.LOGO_MIN_WIDTH
      };
    }
    if (type === 'banner') {
      return {
        minHeight: IMAGE_SIZES.BANNER_MIN_HEIGHT,
        minWidth: IMAGE_SIZES.BANNER_MIN_WIDTH
      };
    }
    throw new Error('Unknown Type');
  };

  hasError = () => {
    const { valid } = this.state;
    return valid.error;
  };

  getTarget = () => {
    const { type, restaurant } = this.props;
    if (!restaurant) {
      return null;
    }
    if (type === 'logo') {
      return restaurant.logo_photo;
    }
    if (type === 'banner') {
      return restaurant.banner_photo;
    }
    throw new Error('Unknown Type');
  };

  getStyles = () => {
    const { type } = this.props;
    if (type === 'banner') {
      return bannerStyles;
    }
    if (type === 'logo') {
      return logoStyles;
    }
    return {};
  };

  render() {
    const {
      onButtonClick,
      setValid,
      saveFile,
      getValidations,
      getStyles,
      getTarget
    } = this;
    const { type } = this.props;
    const { loadingImage, file, valid } = this.state;
    const target = getTarget();
    const style = getStyles();
    return (
      <Grid>
        <Grid style={commonStyles.container}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: 'bold', fontSize: 16 }}
          >
            {type === 'banner' && (
              <TranslateText text="forms.commerce.banner_info" />
            )}
            {type === 'logo' && (
              <TranslateText text="forms.commerce.logo_info" />
            )}
          </Typography>
        </Grid>
        <Grid style={{ ...style.imageContainer, ...cardStyles.card }}>
          <Grid style={commonStyles.addButtonContainer}>
            <ButtonBase
              color="secondary"
              style={{
                width: 31,
                height: 31,
                backgroundColor: COLORS.primary,
                borderRadius: 6
              }}
              onChange={event => saveFile(event)}
              onClick={() => onButtonClick()}
            >
              <AddIcon style={{ marginRight: 3 }} />
            </ButtonBase>
          </Grid>
          <Grid
            container
            style={{ width: '100%', height: '100%' }}
            justify="center"
            alignItems="center"
          >
            {loadingImage && <CircularProgress />}
            {(file || (target && target.original)) && !loadingImage && (
              <img
                onLoad={() => {
                  validateImageSizes(file, getValidations(), setValid);
                }}
                style={commonStyles.image}
                src={file || (target.x400 ? target.x400 : target.original)}
                alt={`${type}_photo`}
              />
            )}
          </Grid>
        </Grid>
        {valid.error && (
          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            <TranslateText text={valid.message} />
          </Typography>
        )}
        <input
          type="file"
          accept="image/*"
          id={`${type}File`}
          ref={this.input}
          style={{ display: 'none' }}
          onChange={event => saveFile(event)}
        />
      </Grid>
    );
  }
}
export default RestaurantPhotoForm;
