import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Minimize, Maximize } from '@material-ui/icons';
import { useConversationStyles } from './chatStyles';
import { SUBJECT_OPTIONS } from '../constants';
import { GreenCrossButton } from '../../../components/Inputs/Buttons';
import { setChats } from '../../../actions/chatActions';

const Header = ({ chat, isCollapsed, setIsCollapsed }) => {
  const classes = useConversationStyles();
  const translation = useTranslation();
  const dispatch = useDispatch();
  let restaurantPhoto = '';
  if (chat) restaurantPhoto = chat.admin_photo || '';

  const getTitle = () => {
    const [option] = SUBJECT_OPTIONS.filter(so => so.value === chat.subject);
    switch (chat.subject) {
      case 'ORDER':
        return (
          <Typography>
            {`${translation(option.label)} - ${chat.order_summary.order_code}`}
          </Typography>
        );
      case 'OTHER':
        return (
          <Typography>
            {`${translation(option.label)} - ${chat.other_text}`}
          </Typography>
        );
      default:
        return <Typography>{`${translation(option.label)}`}</Typography>;
    }
  };

  return (
    <Box
      className={
        isCollapsed ? classes.minimizedContainer : classes.chatTitleContainer
      }
    >
      <Box className={classes.chatTitle}>
        <Avatar className={classes.avatar} alt="avatar" src={restaurantPhoto} />
        {chat && getTitle()}
      </Box>
      <Box className={isCollapsed ? classes.minimizedIconsContainer : ''}>
        {isCollapsed ? (
          <Maximize
            className={classes.icon}
            onClick={() => setIsCollapsed(false)}
          />
        ) : (
          <Minimize
            className={classes.icon}
            onClick={() => setIsCollapsed(true)}
          />
        )}
        <GreenCrossButton onClick={() => dispatch(setChats([]))} />
      </Box>
    </Box>
  );
};

export default Header;
