import React from 'react';
import { IconButton, Button, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Edit from '../../assets/icons/Edit.png';
import Cross from '../../assets/icons/Cross.png';
import Draft from '../../assets/icons/Draft.png';
import SixDots from '../../assets/icons/SixDots.png';

const useStyles = makeStyles(theme => ({
  commonButton: {
    cursor: 'pointer'
  }
}));

const GreenEditButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Edit} alt={alt || ''} />
    </IconButton>
  );
};

export const GreenDraftButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Draft} alt={alt || ''} />
    </IconButton>
  );
};

export const GreenCrossButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={Cross} alt={alt || ''} />
    </IconButton>
  );
};

export const SixDotsButton = props => {
  const localStyles = useStyles();
  const { classes, alt } = props;
  return (
    <IconButton
      className={localStyles.commonButton}
      {...props}
      classes={classes}
    >
      <img src={SixDots} alt={alt || ''} />
    </IconButton>
  );
};

export const YellowButton = withStyles(theme => ({
  root: {
    color: theme.palette.expressColors.strongYellow,
    backgroundColor: theme.palette.expressColors.lightYellow,
    boxShadow: 'none'
  }
}))(Button);

export const GreenButton = withStyles(theme => ({
  root: {
    color: theme.palette.expressColors.white,
    backgroundColor: theme.palette.expressColors.green,
    boxShadow: 'none'
  }
}))(Button);

export default GreenEditButton;
