import React, { useRef, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ReactCrop from 'react-image-crop';
import Box from '@material-ui/core/Box';
import 'react-image-crop/dist/ReactCrop.css';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-multi-lang/lib';
import { COLORS } from 'src/theme/colors';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 20,
    display: 'flex',
    paddingRight: 0
  },
  image: {
    width: '70%'
  },
  details: {
    width: '30%',
    alignItems: 'center',
    display: 'flex'
  },
  options: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex'
  },
  dangerButton: {
    backgroundColor: theme.palette.expressColors.lightRed,
    color: theme.palette.expressColors.white,
    '&:hover': {
      backgroundColor: COLORS.red
    }
  }
}));

const pixelRatio = 4;

const ModalCrop = ({ openDialog, setOpenDialog, upImg, saveBlob }) => {
  const translation = useTranslation();
  const classes = useStyles();
  const [crop, setCrop] = useState({ unit: '%', width: 60, aspect: 4 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [disableButton, setDisableButton] = useState(false);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const cropTmp = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = cropTmp.width * pixelRatio;
    canvas.height = cropTmp.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    if (canvas.width === 0 && canvas.height === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
    ctx.drawImage(
      image,
      cropTmp.x * scaleX,
      cropTmp.y * scaleY,
      cropTmp.width * scaleX,
      cropTmp.height * scaleY,
      0,
      0,
      cropTmp.width,
      cropTmp.height
    );
  }, [completedCrop]);

  const getResizedCanvas = (canvas, newWidth, newHeight) => {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext('2d');
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };

  const generateURL = (previewCanvas, cropTmp) => {
    if (!cropTmp || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(
      previewCanvas,
      cropTmp.width,
      cropTmp.height
    );

    canvas.toBlob(
      blob => {
        saveBlob(blob);
        setOpenDialog(false);
      },
      'image/png',
      1
    );
  };
  return (
    <Dialog open={openDialog} maxWidth="md" fullWidth>
      <Box className={classes.card}>
        <Box className={classes.image}>
          <ReactCrop
            src={upImg}
            crop={crop}
            onImageLoaded={onLoad}
            onChange={c => setCrop(c)}
            onComplete={c => {
              setCompletedCrop(c);
            }}
          />
        </Box>
        <Box className={classes.details}>
          <Box className={classes.options}>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: '70%',
                height: 'auto'
              }}
            />
            <ButtonGroup color="primary" style={{ marginTop: 20 }}>
              <Button
                onClick={() => setOpenDialog(false)}
                className={classes.dangerButton}
              >
                {translation(
                  'edit.product_create.basic_information.form.cancel'
                )}
              </Button>
              <Button
                disabled={disableButton}
                onClick={() => {
                  generateURL(previewCanvasRef.current, completedCrop);
                }}
              >
                {translation('edit.product_create.basic_information.form.save')}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalCrop;
