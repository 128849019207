import React, { useState } from 'react';
import { Box, Divider, Grid, Card, makeStyles } from '@material-ui/core';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import { createMomentOnBolivianTime } from 'src/utils/date';
import OrderProductsTable from './OrderProductsTable';
import Header from './Header';
import Facturation from './Facturation';
import ProgressLinearTimer from './ProgressLinearTimer';
import {
  ORDER_STATUS,
  TICKET_STATUS,
  ORDER_TYPE,
  LOG_ACTION
} from '../../../dto/enum';
import {
  ADDITIONAL_MINUTES,
  REJECT_ORDER_REASON,
  EXPIRATION_TICKET_TIME
} from '../../../constants';
import WithLoading from '../../../components/WithLoading';
import {
  updateOrder,
  updateOrdersOnBatch
} from '../../../services/orderServices';
import AlertDialog from '../../../components/Dialog/AlertDialog';
import { getDate } from '../../../utils/date';
import { getOrderNumber } from '../../../hooks/Order/useOrders';
import OneButtonDialog from '../../../components/Dialog/OneButtonDIalong';
import Description from './Description';
import {
  useCurrentSubsidiary,
  useRestaurant
} from '../../../hooks/useRestaurant';
import Accepted from './cockpit/Accepted';
import ReadyToPick from './cockpit/ReadyToPick';
import OrderHistoryServices from '../../../services/orderHistoryServices';
import { useCurrentUser } from '../../../hooks/useUser';
import { editSubsidiary } from '../../../services/subsidiaryService';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(theme => ({
  root: {},
  minuteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  minutesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  cardContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  tableContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important'
    }
  }
}));
const AcceptedOrder = ({ order }) => {
  const classes = useStyles();
  const history = useHistory();
  const restaurant = useRestaurant();
  const currentUser = useCurrentUser();
  const [isOrderCanceled, setIsOrderCanceled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aditionalMinutesOpen, setAdditionalMinutesOpen] = useState(false);
  const [aditionalMinutes, setAdditionalMinutes] = useState(0);
  const [timePassed, setTimePassed] = useState(false);
  const translation = useTranslation();
  const [confirmationOrder, setConfirmationOrder] = useState('');
  const [rejectOrderOpen, setRejectOrderOpen] = useState(false);
  const [rejectOrderReason, setRejectOrderReason] = useState('');
  const currentSubsidiary = useCurrentSubsidiary();

  // const [driver] = useUser(order.driver_id);

  const orderReadyForDelivery = async () => {
    setLoading(true);
    await updateOrder(
      order.id,
      {
        ready_for_driver_to_pick_up: true
      },
      LOG_ACTION.READY_FOR_DRIVER_TO_PICK_UP
    );
    setLoading(false);
  };

  const orderReady = async () => {
    if (
      order.status === ORDER_STATUS.ACCEPTED &&
      (order.order_type === ORDER_TYPE.PICK_UP ||
        order.order_type === ORDER_TYPE.IN_COMMERCE)
    ) {
      setLoading(true);
      const finishTime = createMomentOnBolivianTime().toDate();
      await updateOrder(
        order.id,
        {
          status: ORDER_STATUS.READY_TO_PICK,
          delivery_time: finishTime
        },
        LOG_ACTION.STATUS_CHANGED
      );
      setLoading(false);
    }
  };

  const rejectOrderConfim = () => {
    setConfirmationOrder(translation('orders.details.new_order.reject_quest'));
  };

  const delayOrder = async () => {
    setLoading(true);
    let deliveryTime = createMomentOnBolivianTime(getDate(order.delivery_time));
    const mTime = createMomentOnBolivianTime(new Date());
    if (mTime.isSameOrAfter(deliveryTime)) {
      deliveryTime = createMomentOnBolivianTime(getDate(mTime));
    }
    deliveryTime.add(aditionalMinutes, 'm');
    await updateOrder(order.id, {
      delivery_time: deliveryTime.toDate(),
      cooking_time: order.cooking_time + aditionalMinutes
    });
    setAdditionalMinutes(0);
    setAdditionalMinutesOpen(false);
    setLoading(false);
  };

  const rejectOrderReasonFunc = () => {
    setConfirmationOrder('');
    setRejectOrderOpen(true);
  };

  const rejectOrder = async () => {
    setRejectOrderOpen(false);
    setLoading(true);
    const today = createMomentOnBolivianTime().toDate();
    await updateOrdersOnBatch(
      order,
      {
        status: ORDER_STATUS.CANCELED,
        canceled_reason: rejectOrderReason,
        canceled_by: { id: currentUser.id, name: currentUser.first_name },
        canceled_from: 'MERCHANT'
      },
      LOG_ACTION.STATUS_CHANGED
    );
    await editSubsidiary(currentSubsidiary.id, {
      last_order_canceled_merchant: {
        canceled_date: today,
        order_code: order.order_code
      }
    });
    setRejectOrderReason('');
    history.push('/app/orders');
    setLoading(false);
  };

  const onNotifyClient = async () => {
    // Required to observe change of state history.
    const logAction =
      order.status !== ORDER_STATUS.READY_TO_CASHIER
        ? LOG_ACTION.STATUS_CHANGED
        : LOG_ACTION.NONE;
    if (!order.user_nearby) {
      setLoading(true);
      await updateOrder(
        order.id,
        {
          user_nearby: true,
          ticket_status: TICKET_STATUS.ON_GOING,
          status: ORDER_STATUS.READY_TO_CASHIER,
          notificationUser: true
        },
        logAction
      );
      setLoading(false);
    } else {
      setLoading(true);
      const newTicketExpirationTime = moment().add(EXPIRATION_TICKET_TIME, 'm');
      await updateOrder(
        order.id,
        {
          ticket_status: TICKET_STATUS.ON_GOING,
          status: ORDER_STATUS.READY_TO_CASHIER,
          expiration_ticket: new Date(newTicketExpirationTime),
          notificationUser: true
        },
        logAction
      );
      setLoading(false);
    }
    await OrderHistoryServices.addHistory(order.id, LOG_ACTION.NOTIFY_CLIENT);
  };

  const onOrderDelivered = async () => {
    setLoading(true);
    const newState =
      order.order_type === ORDER_TYPE.DELIVERY
        ? ORDER_STATUS.DELIVERING
        : ORDER_STATUS.COMPLETED;
    await updateOrder(
      order.id,
      {
        status: newState
      },
      LOG_ACTION.STATUS_CHANGED
    );
    setLoading(false);
  };

  const getCockpit = () => {
    switch (order.status) {
      case ORDER_STATUS.ACCEPTED:
        return (
          <Accepted
            orderType={order?.order_type}
            orderReady={orderReady}
            orderReadyForDelivery={orderReadyForDelivery}
            showDriverNotification={order?.ready_for_driver_to_pick_up}
            timePassed={timePassed}
            isDisabled={order?.ready_for_driver_to_pick_up === true}
            rejectOrderConfim={rejectOrderConfim}
            setAdditionalMinutesOpen={setAdditionalMinutesOpen}
            orderWithDestination={order?.order_with_destination}
          />
        );

      default:
        return (
          <ReadyToPick
            onNotifyClient={onNotifyClient}
            notificationUser={order.notificationUser}
            onOrderDelivered={onOrderDelivered}
            ticket={order.ticket}
            orderType={order.order_type}
            orderStatus={order.status}
          />
        );
    }
  };

  if (!restaurant) {
    return <></>;
  }

  return (
    <Card className={classes.cardContainer}>
      <Header order={order} />
      <BoxLoading isLoading={loading} mt={2}>
        <Grid container spacing={3}>
          <Grid item md={8} xl={8} xs={12} className={classes.tableContainer}>
            <ProgressLinearTimer
              startDate={order.accepted_time}
              finishDate={order.delivery_time}
              timeout={() => {
                if (order.status === ORDER_STATUS.ACCEPTED) orderReady();
              }}
            />
            <OrderProductsTable displayDescription={false} order={order} />
            {order.additional_description && (
              <Box mt={3}>
                <Description order={order} />
              </Box>
            )}
          </Grid>

          <Grid item container md={4} xl={4} xs={12} direction="column">
            <Box width="100%">
              <Facturation order={order} />
            </Box>
          </Grid>
          {!loading && order.status !== ORDER_STATUS.COMPLETED && (
            <Box width="100%">{getCockpit()}</Box>
          )}
        </Grid>
      </BoxLoading>

      <AlertDialog
        open={timePassed}
        question={translation('orders.details.alerts.add_quest')}
        maxWidth="sm"
        handleCancel={() => {
          setAdditionalMinutesOpen(true);
          setTimePassed(false);
        }}
        handleAccept={() => {
          setTimePassed(false);
          orderReady();
        }}
        acceptLabel={translation('orders.details.alerts.order_ready')}
        cancelLabel={translation('orders.details.alerts.add_minutes')}
      >
        <Box m={1}>
          <Typography variant="h4">
            {`${translation('orders.details.order')} "${getOrderNumber(
              order
            )}"`}
          </Typography>
        </Box>
      </AlertDialog>
      <AlertDialog
        open={aditionalMinutesOpen}
        question={translation('orders.details.alerts.add_complete')}
        handleCancel={() => setAdditionalMinutesOpen(false)}
        handleAccept={isOrderCanceled ? rejectOrderConfim : delayOrder}
        disabledButton={!isOrderCanceled && aditionalMinutes === 0}
      >
        <Box m={1}>
          <Box m={1} className={classes.minuteContainer}>
            <Chip label={`${order?.cooking_time || 0} min`} />
          </Box>
          <Divider />
          <Box m={1} className={classes.minutesContainer}>
            {ADDITIONAL_MINUTES.map(m => (
              <Chip
                icon={<PlusCircleIcon />}
                label={`${m} min ${translation('orders.list.more')}`}
                key={m}
                onClick={() => {
                  setAdditionalMinutes(m);
                  setIsOrderCanceled(false);
                }}
                color={
                  aditionalMinutes === m && !isOrderCanceled
                    ? 'primary'
                    : 'default'
                }
              />
            ))}
          </Box>
        </Box>
      </AlertDialog>
      <AlertDialog
        open={!!confirmationOrder}
        question={confirmationOrder}
        handleCancel={() => {
          setConfirmationOrder('');
        }}
        handleAccept={rejectOrderReasonFunc}
        acceptLabel={translation('orders.details.new_order.reject')}
        cancelLabel={translation('close')}
      >
        <Box m={1}>
          <Typography variant="h5">
            {`"${translation('orders.details.order')} ${getOrderNumber(
              order
            )}"`}
          </Typography>
        </Box>
      </AlertDialog>
      <OneButtonDialog
        open={rejectOrderOpen}
        handleAction={rejectOrder}
        handleClose={() => {
          setRejectOrderOpen(false);
        }}
        title={translation('orders.details.new_order.order_dialog.title')}
        buttonLabel={translation(
          'orders.details.new_order.order_dialog.reject'
        )}
        disableButton={loading || rejectOrderReason === ''}
      >
        <Box m={1}>
          {REJECT_ORDER_REASON.map(ro => (
            <Chip
              label={ro}
              key={ro}
              onClick={() => setRejectOrderReason(ro)}
              color={rejectOrderReason === ro ? 'primary' : 'default'}
            />
          ))}
        </Box>
      </OneButtonDialog>
    </Card>
  );
};

export default AcceptedOrder;
