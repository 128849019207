import Order from '../dto/order/order';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';
import OrderProduct from '../dto/order/orderProduct';
import axios from '../utils/axios';

export const sendNewOrder = async (
  restaurant,
  subsidiary,
  deliveryLocation = {},
  user = {},
  deliveryInformation = {},
  total = 0,
  orderWithDestination = false,
  driverRestaurantNearby = false,
  driverNearby = false
) => {
  try {
    const order_id = firebaseService.randomId(COLLECTIONS.ORDER);
    const totalOrder = total || 0;
    const driver_nearby = driverNearby || false;
    const order = new Order(
      order_id,
      restaurant.id,
      subsidiary.id,
      user.id,
      '',
      totalOrder,
      deliveryInformation.subsidy,
      restaurant.name,
      subsidiary.name,
      subsidiary.city,
      subsidiary.address,
      deliveryLocation || {},
      subsidiary.geohash,
      subsidiary.location,
      deliveryLocation || {},
      deliveryInformation?.price || 0,
      user?.phone_number || '',
      orderWithDestination,
      driverRestaurantNearby,
      driver_nearby
    );

    const orderProduct = new OrderProduct('generic_product', total || 0);
    const { data } = await axios.post('/orders/makeOrder', {
      data: {
        order,
        orderProducts: [orderProduct]
      }
    });
    return data.data;
  } catch (error) {
    console.error(error);
    return 'error';
  }
};
