/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import firebase from 'firebase/app';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Icons from '../../../assets/icons';
import Image from '../../Image';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  required: {
    color: 'red',
    marginTop: '15px',
    fontFamily: 'Nunito sans'
  },
  requiredDocument: {
    color: 'red',
    fontFamily: 'Nunito sans',
    marginLeft: '7px'
  },
  image: {
    minHeight: 100,
    maxHeight: 200,
    width: '100%',
    cursor: 'pointer'
  }
}));

const UploadFileRestaurant = ({ path, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const document = useRef(null);
  const [docDescription, setDocDescription] = useState('');
  const [docName, setDocName] = useState('');
  const [documentFile, setDocumentFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [urlObject, setUrlObject] = useState('');
  const [required, setRequired] = useState(false);
  const [requiredDoc, setRequiredDoc] = useState(false);
  const [nameExcel, setNameExcel] = useState('');

  const onButtonClick = () => {
    document.current.click();
  };

  const saveFile = event => {
    setDocumentFile(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setUrlObject(URL.createObjectURL(event.target.files[0]));
      const file = event.target.files[0];
      if (file.type.split('/')[0] !== 'image') {
        setNameExcel(file.name);
      }
    }
  };

  const upload = async () => {
    setUploading(true);
    const extension = documentFile.name.split('.').pop();
    const storageRef = firebase
      .storage()
      .ref(`documents/${path}/${docName}.${extension}`);
    const uploadTask = storageRef.put(documentFile);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        handleConfirm(url, { docName, docDescription });
      }
    );
  };

  const requiredForm = () => {
    if (docName && urlObject) {
      upload();
    } else {
      if (!docName) {
        setRequired(true);
      }
      if (!urlObject) {
        setRequiredDoc(true);
      }
    }
  };

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('modals.uploadFile.title')}
        />
        <Divider />
        <CardContent>
          <Grid item container>
            <Grid item container>
              <Grid item container xs={9} md={9}>
                <Box pb={3} pr={3} width="100%">
                  <TextField
                    style={{ marginTop: urlObject ? 0 : 10 }}
                    fullWidth
                    label={translation('modals.uploadFile.doc_title')}
                    name="title"
                    onChange={e => setDocName(e.target.value)}
                    value={docName}
                    variant="outlined"
                  />
                  {required && (
                    <p className={classes.required}>
                      {translation('document_uploader.title.required')}
                    </p>
                  )}
                </Box>
              </Grid>
              <Grid item container xs={9} md={9}>
                <Box pb={3} pr={3} width="100%">
                  <TextField
                    style={{ marginTop: urlObject ? 0 : 10 }}
                    fullWidth
                    label={translation('modals.uploadFile.doc_description')}
                    name="description"
                    onChange={e => setDocDescription(e.target.value)}
                    value={docDescription}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item container xs={3} md={3}>
                <input
                  type="file"
                  id="bannerFile"
                  accept="
                    image/png, 
                    image/jpeg, 
                    .csv, 
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                    application/vnd.ms-excel, 
                    application/pdf, 
                    application/msword
                  "
                  ref={document}
                  style={{ display: 'none' }}
                  onChange={event => saveFile(event)}
                />
                <Image
                  alt="Nit"
                  onClick={() => onButtonClick()}
                  style={classes.image}
                  src={urlObject || Icons.uploadImage}
                />
                {requiredDoc && (
                  <p className={classes.requiredDocument}>
                    {translation('document_uploader.document.required')}
                  </p>
                )}
                {nameExcel}
              </Grid>
              {uploading && (
                <Box width="100%" my={3}>
                  <LinearProgress />
                </Box>
              )}
            </Grid>
            {!uploading && (
              <Box mt={3} width="100%">
                <Grid item container justify="center">
                  <Box mr={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={requiredForm}
                    >
                      {translation('modals.uploadFile.btn_save')}
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleClose}
                    >
                      {translation('cancel')}
                    </Button>
                  </Box>
                </Grid>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UploadFileRestaurant;
