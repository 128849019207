/* eslint-disable global-require */
const Icons = {
  order: require('./order.png'),
  edit: require('./Edit.png'),
  close: require('./Cross.png'),
  usePicture: require('./userPicture.png'),
  uploadImage: require('./uploadImage.png'),
  back: require('./Back.png'),
  productDish: require('./ProductDish.png'),
  productImage: require('./ProductImage.jpg'),
  checkBoxGreenBorder: require('./checkBoxGreenBorder.png'),
  checkBoxGreen: require('./checkBoxGreen.png')
};
export default Icons;
