import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSubsidiariesRealTime } from '../../../hooks/useRestaurant';
import Information from './Information';
import Header from './Header';
import PriceForm from './PriceForm';
import RedListSubsidiary from './RedListSubsidiary';
import { isOwner, isSupervisor } from '../../../utils/helpers/userHelper';
import { registerStatus } from '../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  main: {
    alignContent: 'center'
  },
  form: {
    display: 'flex',
    backgroundColor: 'white',
    marginLeft: '5%',
    marginRight: '5%'
  }
}));

const SubsidyDelivery = ({ restaurantId, subsidiary, setSubsidiary }) => {
  const allSubsidiaries = useSubsidiariesRealTime(restaurantId);
  const { user } = useSelector(state => state.account);
  const classes = useStyles();
  const [listSubsidiaries, setListSubsidiaries] = useState([]);

  const listSubsidiariesForRol = () => {
    let userSubsidiaries = allSubsidiaries;
    const list = [];
    if (isSupervisor(user)) {
      userSubsidiaries = userSubsidiaries.filter(s =>
        user.subsidiary_id.includes(s.id)
      );
    }
    if (isOwner(user)) {
      userSubsidiaries = allSubsidiaries;
    }
    if (allSubsidiaries?.length > 0) {
      if (user.roles.OWNER || user.roles.SUPERVISOR) {
        userSubsidiaries.forEach(sub => {
          if (
            sub.price_area?.hasPriceChanges === true &&
            sub.register_status === registerStatus.ACTIVE
          ) {
            list.push(sub);
          }
        });
      }
    }
    setListSubsidiaries(list);
  };

  useEffect(() => {
    if (allSubsidiaries) {
      listSubsidiariesForRol();
    }
    // eslint-disable-next-line
  }, [allSubsidiaries]);

  return (
    <>
      <Box>
        <Box mb={2}>
          <Header
            currentSubsidiary={subsidiary}
            setSubsidiary={setSubsidiary}
          />
          {listSubsidiaries.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              marginRight="5%"
              marginTop="-4%"
            >
              <Card style={{ width: '20%' }}>
                <RedListSubsidiary listSubsidiaries={listSubsidiaries} />
              </Card>
            </Box>
          )}
        </Box>
        <Box className={classes.form}>
          <Box style={{ width: '100%' }} mt={8}>
            <Information />
          </Box>
          <Box style={{ width: '100%' }}>
            <PriceForm
              subsidiaryId={subsidiary.id}
              subsidiaryName={subsidiary.name}
              listSubsidiaries={listSubsidiaries}
              setListSubsidiaries={setListSubsidiaries}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SubsidyDelivery;
