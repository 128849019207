import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import { useOrderPerId } from '../../../../hooks/AdminHooks/orders/useOrders';
import OrderViewAdmin from './OrderViewAdmin';
import { usePromotion } from '../../../../hooks/Promotions/usePromotions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function OrderDetailsView(props) {
  const { orderIdFromProps } = props;
  const classes = useStyles();
  const { orderId } = useParams();
  const order = useOrderPerId(orderId || orderIdFromProps);
  const promotion = usePromotion(order?.promotionId);
  const translation = useTranslation();
  if (!order) {
    return null;
  }
  return (
    <Page className={classes.root} title={translation('orders.details.title')}>
      <Container maxWidth={false}>
        <OrderViewAdmin order={{ ...order, promotion }} adminView />
      </Container>
    </Page>
  );
}

export default OrderDetailsView;
