import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Badge, Box, Grid } from '@material-ui/core';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { FaFileImage } from 'react-icons/fa';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SelectImageMessage from './selectImageMessage';
import { useConversationStyles } from './chatStyles';
import YellowTextField from '../../../components/Inputs/TextField';
import {
  createImageMessage,
  createMessage
} from '../../../services/chatService';

const FooterChat = ({ chatId }) => {
  const classes = useConversationStyles();
  const user = useSelector(state => state.account.user);
  const [message, setMessage] = useState();

  const onPressEnter = event => {
    let messageSend = '';
    if (event.key === 'Enter') event.preventDefault();
    if (typeof message === 'string') messageSend = message.trim();
    if (event.key === 'Enter' && messageSend !== '') {
      createMessage(user, message, chatId);
      setMessage('');
    }
  };

  async function sendImageMessage(file) {
    await createImageMessage(user, file, chatId);
  }
  return (
    <Box className={classes.cockpitContainer}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={1}>
          <SelectImageMessage
            onAccept={sendImageMessage}
            compressed
            icon={
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                className={clsx(classes.cursorPointer, classes.badge)}
                badgeContent={
                  <AddCircleIcon className={classes.iconAddCircle} />
                }
              >
                <FaFileImage
                  fontSize="inherit"
                  style={{ fontSize: '32px' }}
                  className={classes.expressColorGreen}
                />
              </Badge>
            }
          />
        </Grid>
        <Grid item xs={10}>
          <YellowTextField
            fullWidth
            multiline
            name="message"
            onChange={e => setMessage(e.target.value)}
            onKeyDown={onPressEnter}
            value={message}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={1}>
          <RiSendPlane2Fill
            fontSize="inherit"
            style={{ fontSize: '38px' }}
            className={clsx(
              classes.expressColorGreen,
              classes.cursorPointer,
              classes.iconSend
            )}
            onClick={() => {
              let messageSend = '';
              if (typeof message === 'string') messageSend = message.trim();
              if (messageSend !== '') {
                createMessage(user, message, chatId);
                setMessage('');
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterChat;
