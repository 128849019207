/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useCockpitStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  card: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.expressColors.topbarButons,
    maxHeight: 48,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  },
  active: {
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  chip: {
    '&& .MuiBadge-colorPrimary': {
      backgroundColor: theme.palette.expressColors.green,
      fontWeight: 'bold',
      fontSize: 14,
      transform: 'scale(1) translate(20%, -20%)'
    }
  },
  containerEntregas: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textEntregas: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'none'
  },
  iconInfo: {
    color: theme.palette.expressColors.green,
    cursor: 'pointer',
    width: 28,
    height: 28
  }
}));

export const useChatLayoutStyles = makeStyles(theme => ({
  container: {
    width: 488,
    height: '75%',
    right: 0,

    top: 64,
    position: 'absolute'
  },
  cardContainer: {
    maxWidth: 488,
    maxHeight: '75%',
    borderLeft: `5px solid ${theme.palette.expressColors.green}`,
    paddingTop: 0,
    position: 'relative',
    overflowY: 'scroll'
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: 16
  },
  nativeSelect: {
    minHeight: 48,
    minWidth: 245,
    boxShadow: `0px 3px 6px #00000029`
  },
  button: {
    marginTop: 5,
    minHeight: 48,
    minWidth: 174,
    fontWeight: 'bold',
    color: theme.palette.expressColors.strongYellow,
    backgroundColor: theme.palette.expressColors.lightYellow,
    boxShadow: `0px 3px 3px #00000029`
  },
  cockpit: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  avatar: {
    margin: `0 ${theme.spacing(1)}px`
  },
  chatContainer: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  fontWeightBold: {
    '& .MuiTypography-body1': {
      fontWeight: 'bold'
    }
  },
  textRead: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 300
  },
  textUnread: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: theme.palette.tertiary.main
  },
  buttonGreen: {
    marginBottom: theme.spacing(-2),
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.expressColors.green,
    textTransform: 'none'
  },
  feedback: {
    fontSize: 12,
    marginLeft: 10
  }
}));
