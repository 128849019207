import { useEffect, useMemo, useState } from 'react';
import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import {
  getDeliveryDisabledByCity,
  getDeliveryDisabledByZone
} from '../utils/helpers/SubsidiaryDeliveryDisabledHelper';

export const useDeliveryDisabledByCity = cityId => {
  const [deliveryDisabledByCity, setDeliveryDisabledByCity] = useState([]);
  useEffect(() => {
    if (cityId) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.DELIVERY_DISABLED_PER_CITY)
        .where('city.id', '==', cityId)
        .onSnapshot(snap => {
          if (snap.docs.length > 0) {
            setDeliveryDisabledByCity(
              snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            );
          } else setDeliveryDisabledByCity([]);
        });
    }
  }, [cityId]);
  return deliveryDisabledByCity;
};
export const useZoneById = zoneId => {
  const [deliveryDisabledByZone, setDeliveryDisabledByZone] = useState(null);
  useEffect(() => {
    if (zoneId) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.ZONES)
        .doc(zoneId)
        .onSnapshot(snap => {
          if (snap.exists) {
            setDeliveryDisabledByZone({ ...snap.data(), id: snap.id });
          } else setDeliveryDisabledByZone(null);
        });
    }
  }, [zoneId]);
  return deliveryDisabledByZone;
};
export const useSubsidiaryDeliveryDisabled = subsidiary => {
  const deliveriesDisabledPerCity = useDeliveryDisabledByCity(
    subsidiary.city.id
  );
  const zone = useZoneById(subsidiary.zone.id);
  return useMemo(() => {
    let isDeliveryDisabled = false;
    let isDeliveryDisabledByCity = false;
    let isDeliveryDisabledByZone = false;
    if (deliveriesDisabledPerCity.length > 0) {
      isDeliveryDisabledByCity = false;
      isDeliveryDisabledByCity = getDeliveryDisabledByCity(
        subsidiary,
        deliveriesDisabledPerCity
      );
    }
    if (zone) {
      isDeliveryDisabledByZone = getDeliveryDisabledByZone(
        subsidiary,
        zone.demand
      );
    }
    if (isDeliveryDisabledByCity === true || isDeliveryDisabledByZone === true)
      isDeliveryDisabled = true;
    else isDeliveryDisabled = false;
    return isDeliveryDisabled;
  }, [subsidiary, deliveriesDisabledPerCity, zone]);
};
