// eslint-disable-next-line import/prefer-default-export
export function getNumberDecimalFixed(number, digits) {
  return parseFloat(parseFloat(number).toFixed(digits));
}

export const roundNumber = num => {
  const numRound = Math.round(num * 100) / 100;
  const decimal = numRound - Math.floor(numRound);
  switch (true) {
    case decimal < 0.25:
      return `${Math.floor(numRound)}.00`;
    case decimal >= 0.25 && decimal <= 0.75:
      return `${Math.floor(numRound)}.50`;
    default:
      return `${Math.ceil(numRound)}.00`;
  }
};
