/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';
import firebase from 'firebase/app';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  LinearProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Icons from '../../assets/icons';
import Image from '../Image';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  required: {
    color: 'red',
    marginTop: '15px',
    fontFamily: 'Nunito sans'
  },
  image: {
    minHeight: 100,
    maxHeight: 200,
    width: '100%',
    cursor: 'pointer'
  }
}));

const EditFileRestaurant = ({
  handleConfirm,
  handleClose,
  status,
  name,
  description,
  urlImage,
  path,
  acceptFormat
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const document = useRef(null);
  const [docDescription, setDocDescription] = useState(description || '');
  const [docName, setDocName] = useState(name || '');
  const [localStatus] = useState(status);
  const [documentFile, setDocumentFile] = useState();
  const [urlObject, setUrlObject] = useState(urlImage || '');
  const [uploading, setUploading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentImg] = useState(urlImage || '');
  const [required, setRequired] = useState(false);
  const [fileName, setFileName] = useState(name);

  const saveFile = event => {
    setDocumentFile(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setUrlObject(URL.createObjectURL(event.target.files[0]));
      setFileName(event.target.files[0].name);
    }
  };

  const onButtonClick = () => {
    document.current.click();
  };

  const statusDocument = state => {
    switch (state) {
      case 'ACEPTADO':
        return 'ACCEPTED';
      case 'PENDIENTE':
        return 'PENDING';
      default:
        return state;
    }
  };

  const editDocument = async (statusDoc, nameDoc) => {
    if (docName && urlObject) {
      setUploading(true);
      const newState = statusDocument(statusDoc);
      const updated_at = firebase.firestore.FieldValue.serverTimestamp();
      if (currentImg !== urlObject) {
        const extension = documentFile.name.split('.').pop();
        const storageRef = firebase
          .storage()
          .ref(`documents/${path}/${docName}.${extension}`);
        const uploadTask = storageRef.put(documentFile);
        uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
          },
          error => {
            console.log(error);
          },
          async () => {
            const url = await uploadTask.snapshot.ref.getDownloadURL();
            handleConfirm({
              status: newState,
              name: nameDoc.docName,
              name_file: docName,
              description: nameDoc.docDescription,
              updated_at,
              url
            });
          }
        );
      } else {
        handleConfirm({
          status: newState,
          name: nameDoc.docName,
          description: nameDoc.docDescription || '',
          updated_at,
          url: urlImage
        });
      }
    } else if (!docName) {
      setRequired(true);
    }
  };

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('modals.editFile.title')}
        />
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-around">
            <Grid
              style={{ display: 'flex', flexDirection: 'column', width: '50%' }}
            >
              <Box pb={3}>
                <TextField
                  fullWidth
                  label={translation('modals.uploadFile.doc_title')}
                  name="title"
                  onChange={e => setDocName(e.target.value)}
                  value={docName}
                  variant="outlined"
                />
                {required && (
                  <p className={classes.required}>
                    {translation('document_uploader.title.required')}
                  </p>
                )}
              </Box>
              <Box pb={3}>
                <TextField
                  fullWidth
                  label={translation('modals.uploadFile.doc_description')}
                  name="description"
                  onChange={e => setDocDescription(e.target.value)}
                  value={docDescription}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item container xs={3} md={3}>
              <input
                type="file"
                id="bannerFile"
                accept={acceptFormat()}
                ref={document}
                style={{ display: 'none' }}
                onChange={event => saveFile(event)}
              />
              <Image
                alt="Nit"
                onClick={() => onButtonClick()}
                style={classes.image}
                src={urlObject || Icons.uploadImage}
                filename={fileName}
              />
              {/* <RadioGroup
                aria-label='status'
                name='status'
                value={localStatus}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={documentsStatus.PENDING}
                  control={<Radio />}
                  label={translation('modal_common.edit_doc.pending')}
                />
                <FormControlLabel
                  value={documentsStatus.ACCEPTED}
                  control={<Radio />}
                  label={translation('modal_common.edit_doc.accepted')}
                />
                <FormControlLabel
                  value={documentsStatus.REJECTED}
                  control={<Radio />}
                  label={translation('modal_common.edit_doc.rejected')}
                />
              </RadioGroup> */}
            </Grid>
          </Box>

          {uploading && (
            <Box width="100%" my={3}>
              <LinearProgress />
            </Box>
          )}
          {!uploading && (
            <Box mt={3} width="100%">
              <Grid item container justify="center">
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      editDocument(localStatus, {
                        docName,
                        docDescription
                      })
                    }
                  >
                    {translation('modals.uploadFile.btn_save')}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => handleClose(false)}
                  >
                    {translation('cancel')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EditFileRestaurant;
