/* eslint-disable */ 
// TODO: fix eslint
import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';
import { getProductsPerRestaurant } from './productsService';
import axios from 'src/utils/axios';

const COLLECTION = COLLECTIONS.MENU_ADDITIONS;
const COLLECTION_ADDITIONS = COLLECTIONS.ADDITION;
const UPDATE_POSITION_END_POINT = '/menuAdition/updatePositions';

class AdditionsService {
  saveAddition = async (id, data) => {
    return firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .set({ ...data }, {merge: true});
  };

  menuAddition = async (id, data) => {
    const updateInfo = data
    if(updateInfo.created_at) delete updateInfo.created_at;
    if(updateInfo.updated_at) delete updateInfo.updated_at;

    return firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({ ...data });
  };


  updateAddition = async (id,productId, data) => {
    return firebase
      .firestore()
      .collection(`${COLLECTIONS.PRODUCT}/${productId}/${COLLECTION_ADDITIONS}`)
      .doc(id)
      .update({ ...data });
  };

  deleteAdditionFromProduct = async (productId, id) => {
    await firebase
      .firestore()
      .collection(`${COLLECTIONS.PRODUCT}/${productId}/${COLLECTION_ADDITIONS}`)
      .doc(id)
      .delete();
};

  saveAdditionToProduct = async (productId, data) => {
    const id = firebaseService.randomId(COLLECTIONS.ADDITION);
    return await firebase
      .firestore()
      .collection(`${COLLECTIONS.PRODUCT}/${productId}/${COLLECTION_ADDITIONS}`)
      .doc(id)
      .set({ ...data, id });
  };

  getAdditionsPerProduct = async productId => {
    // eslint-disable-next-line no-return-await
    return await firebase
      .firestore()
      .collection(`${COLLECTIONS.PRODUCT}/${productId}/${COLLECTION_ADDITIONS}`)
      .where('enable', '==', true)
      .get()
      .then(querySnapshot => {
        const additions = [];
        querySnapshot.forEach(doc => {
          additions.push({
            ...doc.data(),
            id: doc.id
          });
        });
        return additions;
      });
  };

  sharedWithOtherProducts = async (currentAddition, productId, restaurantId) => {
    let products = await getProductsPerRestaurant(restaurantId);
    products = products.filter(product => product.id !== productId);
    let counter = 0;
    await Promise.all(products.map(async product => {
      const additions = await this.getAdditionsPerProduct(product.id);
      additions.forEach(addition => {
        if (addition.menu_additions_id === currentAddition.menu_additions_id) {
          counter++;
        }
        if (addition.product_id ==='' || !_.has(addition,'product_id'))
          try {
            const arrayPath = addition.path.split('/')
            firebase.firestore()
              .collection(arrayPath[0]).doc(arrayPath[1])
              .collection(arrayPath[2]).doc(arrayPath[3])
              .delete();
          } catch (error) {
            console.error(`Error:${error} AditionPath:${addition.path}`);
          }
          
      });
    }))
    return counter;
  };

  getRestaurantAdditionFromProduct = async productAddition => {
    return firebase
      .firestore()
      .collection(`${COLLECTION}`)
      .doc(productAddition.menu_additions_id)
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data();
        }
        return null;
      });
  };

  getSharedWithOtherProductsName = async (menuAddition, restaurantId) => {
    let products = await getProductsPerRestaurant(restaurantId);
    let productsName = [];
    await Promise.all(products.map(async product => {
      const additions = await this.getAdditionsPerProduct(product.id);
      additions.forEach(addition => {
        if (addition.menu_additions_id === menuAddition.id) {
          productsName = [...productsName, product.name];
        }
      });
    }))
    return productsName;
  };

  updatePositions = async productId => {
    axios.post(UPDATE_POSITION_END_POINT, {data:{product_id:productId }});
  };
}

const additionService = new AdditionsService();
export default additionService;
