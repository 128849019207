import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../theme/colors';

export const useFacturationStyles = makeStyles(theme => ({
  root: {},
  right: {
    textAlign: 'right'
  },
  header: {
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`,
    height: 80,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      height: 45
    }
  },
  content: {
    width: '100%',
    padding: 20
  },
  text: {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: 14
    }
  },
  noMarging: {
    marging: 0
  },
  button: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.expressColors.green,
    color: theme.palette.expressColors.white
  },
  badgeText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14
  },
  iconInfo: {
    marginLeft: 10,
    color: COLORS.yellowLink,
    cursor: 'pointer',
    width: 26,
    height: 26
  },
  boxStyle: {
    marginTop: 12
  }
}));

export const useTimeSetterStyles = makeStyles(theme => ({
  root: {},
  timerBox: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  minutes: {
    fontSize: 24,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.expressColors.strongGreen,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  header: {
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`,
    height: 80,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      height: 45
    }
  },
  headerText: {
    fontSize: 20,
    fontWeight: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  button: {
    padding: 0
  },
  icon: {
    size: 22,
    strokeWidth: 4,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      size: 12
    }
  }
}));

export const useTableStyles = makeStyles(theme => ({
  root: {},
  table: {
    minWidth: 500,
    [theme.breakpoints.down('xs')]: {
      minWidth: 340
    }
  },
  unitWidth: {
    width: 165,
    [theme.breakpoints.down('xs')]: {
      width: 70
    }
  },
  totalWidth: {
    width: 165,
    [theme.breakpoints.down('xs')]: {
      width: 80
    }
  },
  quantityWidth: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      width: 25
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  plusIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'text-bottom'
  },
  tertiaryText: {
    fontSize: 20,
    color: theme.palette.tertiary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  textHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  discountRow: {
    backgroundColor: theme.palette.expressColors.cakeRed
  },
  grayText: {
    color: theme.palette.expressColors.lightGray
  },
  responsivePadding: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5)
    }
  },
  numericText: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  quantityText: {
    fontSize: 20,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  tableContentText: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  additionsText: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
}));

export const useOrderProductsTableStyles = makeStyles(theme => ({
  root: { position: 'relative' },
  table: {
    minWidth: 500
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  plusIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'text-bottom'
  },
  tertiaryText: {
    color: theme.palette.tertiary.main
  },
  payment: {
    display: 'flex',
    justifyContent: 'center',
    width: 340,
    height: 33,
    padding: '7px 16px',
    backgroundColor: theme.palette.expressColors.lightGreen,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      height: 23,
      width: 170,
      alignItems: 'center',
      padding: '8px 8px'
    }
  },
  paymentCard: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  pickup: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: theme.palette.expressColors.lightGreen
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  orderFromText: {
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      paddingLeft: theme.spacing(1)
    }
  },
  expansionHeader: {
    maxHeight: 80,
    [theme.breakpoints.down('sm')]: {
      height: 38,
      minHeight: '38px !important',
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 36,
      minHeight: '36px !important',
      padding: theme.spacing(1)
    }
  }
}));

export const useHeaderStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center'
  },
  modal: {
    marginTop: theme.spacing(2) * -1
  },
  yellowBackground: {
    backgroundColor: theme.palette.expressColors.yellowCake
  },
  greenBackground: {
    backgroundColor: theme.palette.expressColors.lightGreen
  },
  hourContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    height: 60,
    alignItems: 'center',
    padding: '25px 16px',
    [theme.breakpoints.only('sm')]: {
      height: 48,
      marginRight: theme.spacing(2),
      width: 230
    },
    [theme.breakpoints.down('xs')]: {
      height: 32,
      padding: theme.spacing(1),
      width: '48%'
    }
  },
  threeItemsMargin: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  button: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  buttonsWithWidth: {
    width: 155
  },
  infoMerged: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    alignItems: 'center',
    padding: 12
  },
  orderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    height: 60,
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      height: 48,
      maxWidth: 230
    },
    [theme.breakpoints.down('xs')]: {
      height: 32
    }
  },
  orderText: {
    fontWeight: 'bold',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },
  timeText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  deliveryText: {
    fontSize: 20,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  firstBlock: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  secondBlock: {
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    }
  }
}));

export const useDescriptionStyles = makeStyles(theme => ({
  header: {
    maxHeight: 80,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 36,
      minHeight: '36px !important',
      padding: theme.spacing(1)
    }
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  },
  text: {
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}));

export const userRefundCardStyles = makeStyles(theme => ({
  saveButton: {
    fontWeight: 'bold',
    width: 150,
    height: 42
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  box: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold'
  }
}));

export const useDriverQualificationStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`,
    height: 80,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      height: 45
    }
  },
  content: {
    width: '100%',
    padding: 20
  },
  text: {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
      fontSize: 20
    }
  },
  noMarging: {
    marging: 0
  },
  textbutton: {
    borderBottom: `1px solid ${theme.palette.expressColors.strongYellow}`,
    textTransform: 'none'
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: COLORS.rateQualifGray,
    backgroundColor: COLORS.rateQualifiGrayDark,
    cursor: 'pointer'
  },
  avatarLiked: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: COLORS.rateQualifGreenDark,
    backgroundColor: COLORS.rateQualifGreen,
    cursor: 'pointer',
    border: `2px solid white`,
    boxShadow: theme.shadows[3]
  },
  avatarDisliked: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: COLORS.rateQualifRedDark,
    backgroundColor: COLORS.rateQualifRed,
    cursor: 'pointer',
    border: `2px solid white`,
    boxShadow: theme.shadows[3]
  }
}));
