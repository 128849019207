import React from 'react';
import { Card, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
    minWidth: '15rem'
  },
  label: {
    alignItems: 'left',
    fontSize: '25px'
  },
  box: {
    padding: '10% 0 10% 0'
  }
}));

const Cards = ({ title, quantity }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box flexGrow={1} className={classes.box}>
        <Typography
          component="h3"
          gutterBottom
          variant="body1"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Typography
          className={classes.label}
          variant="body1"
          color="textSecondary"
        >
          {quantity}
        </Typography>
      </Box>
    </Card>
  );
};

export default Cards;
