/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../constants';
import firebase from '../../config/firebaseConfig';

export const useProduct = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      doc: id,
      storeAs: 'current_product'
    }
  ]);
  const currentProduct = useSelector(
    state => state.firestore.data.current_product
  );
  return currentProduct || {};
};

export const useProductNative = id => {
  const [product, setProduct] = useState(null);
  useEffect(() => {
    if (id) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.PRODUCT)
        .doc(id)
        .onSnapshot(snap => {
          setProduct(snap.data());
        });
    }
    // eslint-disable-next-line
  }, []);
  return product;
};
