/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import { PlusCircle } from 'react-feather';
import WithLoading from '../../../../components/WithLoading';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';
import { createAreaDb, saveAreaDB } from '../../../../services/areaServices';
import AreaPriceItemForm from '../AreasEditView/AreaPriceItemForm';
import { useNewPriceStyles } from '../../commerce/tabs/styles';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);

function NewPrice({
  className,
  area = {},
  def = {},
  onCancel,
  returnNewArea,
  ...rest
}) {
  const classes = useNewPriceStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const cities = useCities();

  const saveArea = async areaForm => {
    setLoading(true);
    const price = areaForm.price.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    const data = {
      ...areaForm,
      price,
      updated_at: moment().toDate()
    };
    if (area.id) {
      return saveAreaDB(area.id, data);
    }
    data.created_at = moment().toDate();
    const newArea = await createAreaDb(data);
    return newArea;
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          name: area.name || '',
          city_id: area.city_id || '',
          price: area.price || [
            {
              price: 0,
              distance: 0,
              subsidy: 0,
              bonus: 0
            }
          ]
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required(translation('admin_areas.title_field.required')),
          city_id: Yup.string()
            .max(255)
            .required(translation('admin_areas.city.required')),
          price: Yup.array()
            .of(
              Yup.object().shape({
                price: Yup.number()
                  .min(0)
                  .required('Precio requerido'),
                distance: Yup.number()
                  .min(0)
                  .required('Distancia requerida'),
                subsidy: Yup.number().min(0),
                bonus: Yup.number().min(0)
              })
            )
            .required(translation('admin_areas.price.required'))
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            const newArea = await saveArea(values);
            if (returnNewArea) returnNewArea(newArea);

            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(translation('admin_areas.correct_save'), {
              variant: 'success'
            });
            setLoading(false);
            onCancel();
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar(
              `${translation('complete_restaurant.error')} ${
                env.REACT_APP_SUPPORT_MAIL
              }`,
              {
                variant: 'error'
              }
            );
            setErrors({ submit: e.message });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <>
              <form noValidate onSubmit={handleSubmit} {...rest}>
                <Card className={clsx(classes.container)}>
                  <Box className={clsx(classes.titleContainer)}>
                    <Typography className={clsx(classes.titleText)}>
                      {translation(
                        area.id
                          ? 'admin_areas.edit_modal_title'
                          : 'admin_areas.modal_title'
                      )}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    {cities.length && (
                      <TextField
                        error={Boolean(touched.city_id && errors.city_id)}
                        fullWidth
                        label={`${translation('admin_areas.city.name')}:`}
                        name="city_id"
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('city_id', e.target.value);
                          setFieldValue('name', '');
                          setFieldValue('price', [
                            {
                              price: 0,
                              distance: 0,
                              subsidy: 0,
                              bonus: 0
                            }
                          ]);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.city_id}
                        variant="outlined"
                      >
                        <option value="" />
                        {cities.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </Box>
                  <Box mb={3}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={translation('admin_areas.title_field.name')}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      required
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={3}>
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={3}>
                        <Typography>
                          <strong>Distancia</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography>
                          <strong>Precio</strong>
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Typography>
                          <strong>
                            {translation('admin_areas.item_price.subsidy')}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item md={2} xs={2}>
                        <Typography>
                          <strong>
                            {translation('admin_areas.item_price.bonus')}
                          </strong>
                        </Typography>
                      </Grid>
                    </Grid>
                    <FieldArray
                      name="price"
                      render={arrayHelpers => (
                        <Box>
                          {values.price &&
                            values.price.length > 0 &&
                            values.price.map((option, index) => (
                              <AreaPriceItemForm
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                price={option}
                                index={index}
                                errors={
                                  errors.price &&
                                  typeof errors.price !== 'string'
                                    ? errors.price[index]
                                    : {}
                                }
                                // eslint-disable-next-line no-shadow
                                onSave={(i, option) =>
                                  arrayHelpers.replace(i, option)
                                }
                                onDelete={i => arrayHelpers.remove(i)}
                                initialyEditing={option.initialyEditing}
                              />
                            ))}
                          <Grid container justify="flex-end" spacing={3}>
                            <Grid item md={2} xs={2}>
                              <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      distance: '',
                                      price: '',
                                      initialyEditing: true
                                    });
                                  }}
                                  className={classes.icon}
                                >
                                  <PlusCircle />
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    />
                    {errors.price && typeof errors.price === 'string' && (
                      <Box ml={3} mr={3} mb={3}>
                        <FormHelperText error>{errors.price}</FormHelperText>
                      </Box>
                    )}
                  </Box>
                  <Box mt={2} className={clsx(classes.submit)}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation('save')}
                    </Button>
                    <Button
                      className={clsx(classes.lastButton)}
                      variant="contained"
                      color="secondary"
                      type="button"
                      disabled={isSubmitting}
                      onClick={onCancel}
                    >
                      {translation('cancel')}
                    </Button>
                  </Box>
                </Card>
              </form>
            </>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

export default NewPrice;
