/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AdminLayout from 'src/layouts/AdminLayout';
// import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ProductCreateView from 'src/views/editor/ProductsView/ProductCreateEditVIew/CreateEditProduct';
import Delivery from 'src/views/delivery';
import Configurations from 'src/views/configurations/ConfigurationsView';
import Order from 'src/views/admin/dashboard/OrderView';
import OrderDetailsView from 'src/views/admin/order/OrderDetailsView';
import OrderListView from 'src/views/admin/order/OrderListView';
import AccountView from './views/pages/AccountView';
import PricingView from './views/pages/PricingView';
import CentralPayments from './views/centralPayments';
import { USER_ROLES } from './dto/enum';
import RolGuard from './components/RolGuard';
import AdminGuard from './components/AdminGuard';
import ProductListMenuSubsidiary from './views/editor/ProductsView/ProductListView/ProductListMenuSubsidiary';
import MainLayout from './layouts/MainLayout';
import DashboardView from './views/reports/DashboardView';
import Edit from './views/editor/Edit';
import MembersListView from './views/members/MemberListView';
import MenuGallery from './views/menuGallery';
import ScheduleManagementView from './views/management/ScheduleManagementView';
import ProductList from './views/editor/ProductsView/ProductListView/ProductList';
import PerformanceReport from './views/performance/PerformanceReport/PerformanceReport';
import OrdersCockpit from './views/orders';
import Error404View from './views/pages/Error404View';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import CompleteRestaurant from './views/auth/CompleteRestaurant';
import ResetView from './views/auth/ResetView';
import UserSuspended from './views/auth/UserSuspended';
import SocialNetworks from './views/auth/SocialNetworks';
import Subsidiary from './views/admin/commerce/Subsidiary';
import AdditionListView from './views/aditions/AditionsListView';
import Dashboard from './views/admin/dashboard';
import UsersListView from './views/admin/users/UsersListView';
import UserDetailView from './views/admin/adminUsers/AdminUserDetailsView';
import Commerce from './views/admin/commerce';
import CommerceTable from './views/admin/commerce/ComercesTable';
import CityView from './views/admin/areas/CityEditView';
import DealersDetailView from './views/admin/adminDealers/DealersDetailsView';
import AdminDealers from './views/admin/adminDealers/DealersListView';
import AreasDetailView from './views/admin/areas/AreasDetailsView';
import AreasAssignView from './views/admin/areas/AreasAssignView';
import Areas from './views/admin/areas/AreasListView';
import FoodCategories from './views/admin/foodCategories';
import MarketingPage from './views/admin/marketing';
import PromosBannerDetailView from './views/admin/promos/PromosBannerDetailsView';
import PromosListView from './views/admin/promos/PromosListView';
import PromosDetailView from './views/admin/promos/PromosDetailsView';
import ExpressAccounting from './views/admin/accounting/xpressManagment';
import TermsAndConditions from './views/docs/TermAndConditions/TermsAndConditions';
import PoliciyAndPrivay from './views/docs/PolicyAndPrivacy/PolicyAndPrivacy';
import Restaurant from './views/admin/commerce/Restaurant';
import CommerceData from './views/admin/commerce/Commerce';
import CommerceAdmin from './views/admin/principal';
import SalesReport from './views/performance/salesReport';
import DeliveryMap from './views/delivery_map';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: Error404View
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    component: TermsAndConditions
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/policy-and-privacy',
    component: PoliciyAndPrivay
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: LoginView
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: LoginView
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: RegisterView
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/completeRegister/:id',
    component: CompleteRestaurant
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetView
  },
  {
    exact: true,
    path: '/user-suspended',
    component: UserSuspended
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/social-networks',
    component: SocialNetworks
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/orders" />
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path: '/app/editor/menu-sections',
        component: Edit
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path: '/app/editor/menu-sections/restId/:restId/subId/:subId',
        component: Edit
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path:
          '/app/editor/menu-sections/restId/:restId/subId/:subId/:sectionId',
        component: ProductListMenuSubsidiary
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path:
          '/app/editor/menu-sections/restId/:restId/subId/:subId/:sectionId/:prodId',
        component: ProductCreateView
      },
      {
        exact: true,
        path: '/app/account',
        component: AccountView
      },

      {
        exact: true,
        path: '/app/orders',
        component: OrdersCockpit
      },
      {
        exact: true,
        path: '/app/orders/:orderId',
        component: OrdersCockpit
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/members',
        component: MembersListView
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        // deprecated
        path: '/app/dealers',
        component: lazy(() => import('src/views/dealers/DealerListView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        // deprecated
        path: '/app/members/create',
        component: lazy(() => import('src/views/members/MemberCreateView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        // deprecated
        path: '/app/members/:memberId',
        component: lazy(() => import('src/views/members/MemberDetailsView'))
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        // deprecated
        path: '/app/members/:memberId/edit',
        component: lazy(() => import('src/views/members/MemberEditView'))
      },

      {
        exact: true,
        path: '/app/management/dashboard',
        component: DashboardView
      },

      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/management/dashboard" />
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path: '/app/management/schedule',
        component: ScheduleManagementView
      },
      {
        exact: true,
        path: '/app/subsidyDelivery',
        guard: RolGuard,
        blockProfiles: [],
        component: Delivery
      },
      {
        exact: true,
        path: '/app/configuration',
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        component: Configurations
      },
      {
        exact: true,
        path: '/app/configuration/:restId',
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        component: Configurations
      },
      {
        exact: true,
        path: '/app/configuration/subsidiary/:id',
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        component: Subsidiary
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/management/shared_menu/restId/:restId/:sectionId',
        component: ProductList
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/management/shared_menu',
        component: Edit
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/management/shared_menu/restId/:restId',
        component: Edit
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/management/shared_menu/restId/:restId/:sectionId/:prodId',
        component: ProductCreateView
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/management/additions',
        component: AdditionListView
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path: '/app/management/menu_gallery',
        component: MenuGallery
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [],
        path: '/app/management/menu_gallery/restId/:restId/',
        component: MenuGallery
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        // deprecated
        path: '/app/performance/earnings',
        component: lazy(() =>
          import('src/views/performance/EarningsReport/EarningsReport')
        )
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        path: '/app/performance/performance',
        component: PerformanceReport
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER],
        path: '/app/performance/salesReport',
        component: SalesReport
      },
      {
        exact: true,
        guard: RolGuard,
        blockProfiles: [USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR],
        path: '/app/delivery_map',
        component: DeliveryMap
      },
      // {
      //   exact: true,
      //   guard: RolGuard,
      //   blockProfiles: [],
      //   path: '/app/chat',
      //   component: lazy(() => import('src/views/xpressChat'))
      // },
      // {
      //   exact: true,
      //   guard: RolGuard,
      //   blockProfiles: [],
      //   path: '/app/chat/:chatId',
      //   component: lazy(() => import('src/views/xpressChat'))
      // },
      {
        exact: true,
        path: '/app/centralPayments',
        component: CentralPayments
      },
      {
        exact: false,
        path: '/app/centralPayments/:tabId',
        component: CentralPayments
      },
      /* {
        exact: true,
        path: '/app/rewards/restaurant',
        component: lazy(() => import('./views/rewards'))
      }, */
      // {
      //   exact: true,
      //   path: '/app/logs',
      //   component: lazy(() => import('./views/logs'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/admin',
        component: Dashboard
      },
      {
        exact: true,
        path: '/admin/order',
        component: Order
      },
      {
        exact: true,
        path: '/admin/users',
        component: UsersListView
      },
      {
        exact: true,
        path: '/admin/users/:status',
        component: UsersListView
      },
      {
        exact: true,
        path: '/admin/user/:userId',
        component: UserDetailView
      },
      {
        exact: true,
        path: '/admin/commerce',
        component: Commerce
      },
      {
        exact: true,
        path: '/admin/add_commerce',
        component: RegisterView
      },
      {
        exact: true,
        path: '/admin/commerce/:status',
        component: CommerceTable
      },
      {
        exact: true,
        path: '/admin/commerce/:status/:id',
        component: CommerceData
      },
      {
        exact: true,
        path: '/admin/commerce/:status/subsidiary/:id',
        component: Subsidiary
      },
      {
        exact: true,
        path: '/admin/commerce/:status/restaurant/:id',
        component: Restaurant
      },
      {
        exact: true,
        path: '/admin/editor/menu-sections',
        component: Edit
      },
      {
        exact: true,
        path: '/admin/editor/menu-sections/:sectionId',
        component: ProductListMenuSubsidiary
      },
      {
        exact: true,
        path: '/admin/editor/shared_menu/:sectionId/:prodId',
        component: ProductCreateView
      },
      {
        exact: true,
        path: '/admin/editor/menu-sections/:sectionId/:prodId',
        component: ProductCreateView
      },
      {
        exact: true,
        path: '/admin/orders',
        component: OrderListView
      },
      {
        exact: true,
        path: '/admin/orders/:status',
        component: OrderListView
      },
      {
        exact: true,
        path: '/admin/order/:orderId',
        component: OrderDetailsView
      },
      {
        exact: true,
        path: '/admin/accounting',
        component: ExpressAccounting
      },
      {
        exact: true,
        path: '/admin/promos',
        component: PromosListView
      },
      {
        exact: true,
        path: '/admin/promos/new',
        component: PromosDetailView
      },
      {
        exact: true,
        path: '/admin/promos/:id',
        component: PromosDetailView
      },
      {
        exact: true,
        path: '/admin/promos_banner',
        component: PromosListView
      },
      {
        exact: true,
        path: '/admin/promos_banner/new',
        component: PromosBannerDetailView
      },
      {
        exact: true,
        path: '/admin/promos_banner/:id',
        component: PromosBannerDetailView
      },
      {
        exact: true,
        path: '/admin/marketing',
        component: MarketingPage
      },
      {
        exact: true,
        path: '/admin/chat',
        component: CommerceAdmin
      },
      {
        exact: true,
        path: '/admin/admin-users',
        component: UsersListView
      },
      {
        exact: true,
        path: '/admin/admin-users/:status',
        component: UsersListView
      },
      {
        exact: true,
        path: '/admin/admin-user/new',
        component: UserDetailView
      },
      {
        exact: true,
        path: '/admin/admin-user/:userId',
        component: UserDetailView
      },
      {
        exact: true,
        path: '/admin/food-categories',
        component: FoodCategories
      },
      {
        exact: true,
        path: '/admin/areas',
        component: Areas
      },
      {
        exact: true,
        path: '/admin/areas/new',
        component: AreasDetailView
      },
      {
        exact: true,
        path: '/admin/areas/:id/assign',
        component: AreasAssignView
      },
      {
        exact: true,
        path: '/admin/areas/:id',
        component: AreasDetailView
      },
      {
        exact: true,
        path: '/admin/account',
        component: AccountView
      },
      {
        exact: true,
        path: '/admin/dealers',
        component: AdminDealers
      },
      {
        exact: true,
        path: '/admin/dealers/:id',
        component: DealersDetailView
      },
      {
        exact: true,
        path: '/admin/city/:id/poligon',
        component: CityView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: PricingView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const blockProfiles = route.blockProfiles
            ? { blockProfiles: route.blockProfiles }
            : {};

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard {...blockProfiles}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
