/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert/Alert';
import WithLoading from '../../../../components/WithLoading';
import MapPoligon from '../../../../components/Map/MapPoligon';
import FilesDropzonePicker from '../../../../components/Dropzone/FilesDropzonePicker';
import { saveCityDB } from '../../../../services/cityServices';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  },
  mapWrapper: {
    height: 850
  }
}));

// eslint-disable-next-line no-unused-vars
function CityPoligonEditForm({ className, city = {}, def = {}, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const translation = useTranslation();
  const [paths, setPaths] = useState();

  useEffect(() => {
    if (city.coverage_polygon) {
      const pathsDB = city.coverage_polygon.map(p => ({
        lat: p.latitude,
        lng: p.longitude
      }));
      setPaths(pathsDB);
    }
    // eslint-disable-next-line
  }, []);

  const setPoligon = xml => {
    setError('');
    // const data = xml2json(xml);
    try {
      const parser = new DOMParser();
      let xmlDoc = parser.parseFromString(xml, 'text/xml');
      [xmlDoc] = xmlDoc.getElementsByTagName('Polygon');
      xmlDoc = xmlDoc.getElementsByTagName('coordinates')[0].innerHTML;
      const coordinates = xmlDoc
        .trim()
        .split(' ')
        .map(i => {
          const chords = i.split(',');
          return {
            lng: +chords[0],
            lat: +chords[1]
          };
        });
      setPaths(coordinates);
    } catch (e) {
      setError('No se puede leer el archivo.');
    }
  };

  const savePoligon = async () => {
    setLoading(true);
    await saveCityDB(city.id, {
      coverage_polygon: [...paths]
    });
    setLoading(false);
    enqueueSnackbar(translation('admin_areas.correct_save'), {
      variant: 'success'
    });
    history.push('/admin/areas');
  };

  const optionalMapProps = {};
  if (city && city.location) {
    optionalMapProps.center = {
      lat: city.location.latitude,
      lng: city.location.longitude
    };
  }
  return (
    <BoxLoading isLoading={loading}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h5">
                Pasos para obtener un archivo .kml
              </Typography>
              <List component="nav" aria-label="main mailbox folders">
                <ListItemText>
                  {' '}
                  Dirigirse a la pagina:{' '}
                  <a
                    href="https://earth.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://earth.google.com/
                  </a>
                </ListItemText>
                <ListItemText primary="Buscar la ciudad" />
                <ListItemText primary="Crear un nuevo proyecto en Google Earth" />
                <ListItemText primary="Crear un poligono usando las herramientas de Google Earth" />
                <ListItemText primary="Exportar el archivo .kml de Google Earth" />
                <ListItemText primary="Subir el archivo .kml a la plataforma Xpress" />
              </List>
            </Grid>
            <Grid item md={6} xs={12}>
              <FilesDropzonePicker
                type="file"
                labels={{
                  selectFiles: translation(
                    'edit.product_create.basic_information.form.choose_file'
                  ),
                  instructions: translation('dropzone.image.instructions_kml'),
                  removeAll: translation('remove'),
                  uploadAll: translation('save')
                }}
                previewImage={src => setPoligon(src)}
              />
              {error && (
                <Box m={1}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
            </Grid>
            <Grid itme md={12} xs={12}>
              <Box className={classes.mapWrapper}>
                <MapPoligon {...optionalMapProps} coords={paths} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={savePoligon}
            >
              {translation('save')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </BoxLoading>
  );
}

CityPoligonEditForm.propTypes = {
  className: PropTypes.string,
  area: PropTypes.object
};

export default CityPoligonEditForm;
