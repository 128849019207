import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { createMomentOnBolivianTime } from '../../utils/date';
import { useListOrderCardStyles } from './styles';

const TextTime = ({ finish_times, isActive }) => {
  const classes = useListOrderCardStyles();
  const translation = useTranslation();
  const getTimeLabel = (finishTimes = {}) => {
    if (finish_times.completed_at) {
      return `${translation(
        'orders_view.card.delivery_time'
      )}  ${createMomentOnBolivianTime(
        finishTimes.completed_at.toDate()
      ).format('HH:mm')}`;
    }
  };
  return (
    <Box width="100%" className={clsx(classes.textContainer)}>
      <Typography
        className={clsx({
          [classes.timeText]: !isActive,
          [classes.activeTextInfo]: isActive
        })}
      >
        {getTimeLabel(finish_times)}
      </Typography>
    </Box>
  );
};
export default TextTime;
