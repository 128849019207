/* eslint camelcase: 0 */
import Base from './base';

export default class PaymentHistory extends Base {
  constructor({
    id,
    enable = true,
    restaurant_id = '',
    month_charge = '',
    invoice_number = '',
    observations = '',
    date_payment = '',
    amount_paid = 0,
    invoice_id,
    created_at = '',
    month = '',
    billing_data = {},
    period = {},
    name_owner = '',
    bank_account = '',
    bank_name = '',
    ci_nit = '',
    isRefund = false
  } = {}) {
    super(id, enable, created_at);
    this.restaurant_id = restaurant_id;
    this.month_charge = month_charge;
    this.invoice_number = invoice_number;
    this.observations = observations;
    this.date_payment = date_payment;
    this.amount_paid = amount_paid;
    this.billing_data = billing_data;
    this.invoice_id = invoice_id;
    this.created_at = created_at;
    this.month = month;
    this.period = period;
    this.name_owner = name_owner;
    this.bank_account = bank_account;
    this.bank_name = bank_name;
    this.ci_nit = ci_nit;
    this.isRefund = isRefund;
  }

  toPlainObject() {
    return { ...this };
  }
}
