import Base from './base';
import { INVOICE_STATUS } from './enum';
/* eslint camelcase: 0 */
export default class Invoice extends Base {
  constructor({
    id = '',
    enable = true,
    created_at,
    updated_at,
    restaurant_id = '',
    owner_id = '',
    representant_id = '',
    nit = '',
    status = INVOICE_STATUS.PENDING,
    amount = 0,
    amount_paid = 0,
    representant_name = '',
    detail_amount = '',
    description = '',
    limit_date = '',
    subsidiary_name = '',
    restaurant_name = '',
    reportId = '',
    reportUrl = '',
    payment_number = 0,
    cities_id = [],
    orders_charged = [],
    orders_not_charged = [],
    subsidiaries = [],
    is_restaurant_charge = true,
    period = '',
    amount_pickup = 0,
    amount_delivery = 0,
    amount_entregas = 0,
    amount_iva = 0,
    amount_xpress_motos = 0,
    month_charge = {},
    status_paid = INVOICE_STATUS.NO_PAID,
    month_created = false,
    amount_comission_refund = 0,
    amount_online_payment = 0,
    amount_refund = 0,
    amount_wallet = 0,
    amount_restaurant_reward = 0,
    amount_delivery_free_Xpress = 0,
    amount_delivery_free_Restaurant = 0,
    amount_promo = 0,
    amount_refund_online_payment = 0,
    total_orders_cash = 0,
    total_orders_online = 0,
    total_amount_online = 0,
    total_amount_cash = 0,
    total_commission = 0,
    total_iva = 0,
    total_discount = 0,
    total_orders = 0,
    summary = {},
    charge_type = 'COMMISSION',
    amount_xpress = 0
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.restaurant_id = restaurant_id;
    this.owner_id = owner_id;
    this.representant_id = representant_id;
    this.nit = nit;
    this.status = status;
    this.amount = amount;
    this.amount_paid = amount_paid;
    this.representant_name = representant_name;
    this.detail_amount = detail_amount;
    this.description = description;
    this.limit_date = limit_date;
    this.subsidiary_name = subsidiary_name;
    this.restaurant_name = restaurant_name;
    this.reportId = reportId;
    this.reportUrl = reportUrl;
    this.payment_number = payment_number;
    this.orders_charged = orders_charged;
    this.cities_id = cities_id;
    this.orders_not_charged = orders_not_charged;
    this.subsidiaries = subsidiaries;
    this.is_restaurant_charge = is_restaurant_charge;
    this.period = period;
    this.amount_paid = amount_paid;
    this.amount_pickup = amount_pickup;
    this.amount_delivery = amount_delivery;
    this.amount_entregas = amount_entregas;
    this.amount_iva = amount_iva;
    this.amount_xpress_motos = amount_xpress_motos;
    this.month_charge = month_charge;
    this.status_paid = status_paid;
    this.month_created = month_created;
    this.amount_comission_refund = amount_comission_refund;
    this.amount_online_payment = amount_online_payment;
    this.amount_refund = amount_refund;
    this.amount_wallet = amount_wallet;
    this.amount_restaurant_reward = amount_restaurant_reward;
    this.amount_delivery_free_Xpress = amount_delivery_free_Xpress;
    this.amount_delivery_free_Restaurant = amount_delivery_free_Restaurant;
    this.amount_promo = amount_promo;
    this.amount_refund_online_payment = amount_refund_online_payment;
    this.total_orders_cash = total_orders_cash;
    this.total_orders_online = total_orders_online;
    this.total_amount_online = total_amount_online;
    this.total_amount_cash = total_amount_cash;
    this.total_commission = total_commission;
    this.total_iva = total_iva;
    this.total_discount = total_discount;
    this.summary = summary;
    this.total_orders = total_orders;
    this.charge_type = charge_type;
    this.amount_xpress = amount_xpress;
  }

  toPlainObject() {
    return { ...this, summary: this.summary.toPlainObject() };
  }

  getCharges() {
    return this.amount === 0 ? -this.amount_comission_refund : this.amount;
  }

  getPendingBalance() {
    return this.getCharges() - this.amount_paid;
  }
}
