import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import useUser from '../../../../hooks/useUser';
import RegisterBankData from './registerBankData';
import TableRefundHistory from './tableRefundHistory';

const BankData = ({ restaurant }) => {
  const [billingData, setBillingData] = useState(null);
  const [user] = useUser(restaurant?.owner_id);
  const isEmpty = obj => {
    return Object.keys(obj).length === 0;
  };
  useEffect(() => {
    if (!billingData && restaurant && user)
      setBillingData({
        id: restaurant.id,
        business_name: restaurant.invoice_name || restaurant.name || '',
        nit: restaurant.nit ? restaurant.nit : '',
        contact_name:
          restaurant.invoice_contact_name ||
          `${user.first_name} ${user.last_name}` ||
          '',
        phone_number: user ? user.phone_number : '',
        email: restaurant.invoice_email
          ? restaurant.invoice_email
          : user.email || '',
        additional_comments: restaurant.invoice_comments || ''
      });
    // eslint-disable-next-line
  }, [user, restaurant]);

  return (
    <Box p={3}>
      <Grid container direction="row" spacing={2} justify="center">
        {!isEmpty(restaurant) && (
          <Grid item xs={12} sm={12}>
            <RegisterBankData idRest={restaurant?.id} restaurant={restaurant} />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <TableRefundHistory idRes={restaurant?.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankData;
