import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import Results from './Results';
import CityResults from '../CityListView/Results';
import Page from '../../../../components/Page';
import NewPrice from '../modal/NewPrice';
import XpressModal from '../../../../components/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  addPriceModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`
  }
}));

const Areas = () => {
  const classes = useStyles();
  const translation = useTranslation();
  const [currentTab, setCurrentTab] = useState('areas');
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const tabs = [
    { value: 'areas', label: 'Areas' },
    { value: 'cities', label: translation('areas.cities.nameTab') }
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const createArea = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid className={classes.addPriceModal}>
          <NewPrice onCancel={handleClose} />
        </Grid>
      </XpressModal>
    );
  };

  return (
    <Page className={classes.root} title={translation('areas.title')}>
      <Container maxWidth={false}>
        <Header currentTab={currentTab} onAddClick={createArea} />
        <Box mt={3}>
          <AppBar position="static" color="default">
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              indicatorColor="secondary"
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </AppBar>
          {currentTab === 'areas' && <Results />}
          {currentTab === 'cities' && <CityResults />}
        </Box>
      </Container>
      {showModal && modal}
    </Page>
  );
};

export default Areas;
