import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import { Edit2, PlusCircle, XCircle } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import moment from 'moment';
import ModalCreatePromoBannerItem from './ModalCreatePromoBannerItem';
import WithLoading from '../../../../components/WithLoading';
import promoBannerService from '../../../../services/promoBannerService';
import fileService from '../../../../services/fileService';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    width: '100%'
  },
  media: {
    height: 100,
    backgroundSize: 'contain'
  },
  item: {
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`,
    marginBottom: theme.spacing(3)
  },
  button: {
    marginRight: theme.spacing(3)
  }
}));

function PromosBannersEditForm({
  className,
  promoBanner = {},
  itemsBanner = [],
  areas,
  cities,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const [promoBannerItem, setPromoBannerItem] = useState({});
  const [items, setItems] = useState([]);

  const renderItems = items.filter(i => !i.deleteItem);
  // eslint-disable-next-line
  useEffect(() => {
    setItems(itemsBanner);
    // eslint-disable-next-line
  }, [itemsBanner]);

  const savePromoBanner = async promoBannerForm => {
    setLoading(true);
    const data = {
      ...promoBannerForm,
      price_areas: promoBannerForm.price_areas.map(p => p.id),
      created_at: moment().toDate(),
      enable: true
    };
    let promoBannerId = '';
    if (promoBanner.id) {
      promoBannerId = promoBanner.id;
      await promoBannerService.savePromoBannerDB(promoBanner.id, data);
    } else {
      promoBannerId = await promoBannerService.createPromoBannerDb(data);
    }

    const itemsData = items.map(i => {
      const itemSaved = {
        id: i.id,
        item_id: i.item_id,
        type: i.type
      };
      if (i.product_restaurant_id) {
        itemSaved.product_restaurant_id = i.product_restaurant_id;
      }
      return itemSaved;
    });

    for (let i = 0; i < items.length; i++) {
      if (items[i].newItem) {
        // eslint-disable-next-line no-continue
        if (items[i].deleteItem) continue;
        // eslint-disable-next-line no-await-in-loop
        const itemID = await promoBannerService.createPromoBannerItem(
          promoBannerId,
          itemsData[i]
        );
        if (items[i].file) {
          // eslint-disable-next-line no-await-in-loop
          await promoBannerService.saveFile(
            promoBannerId,
            itemID,
            'image',
            items[i].file
          );
        }
        if (items[i].fullscreen_file) {
          // eslint-disable-next-line no-await-in-loop
          await promoBannerService.saveFile(
            promoBannerId,
            itemID,
            'fullscreen_image',
            items[i].fullscreen_file
          );
        }
      } else if (items[i].deleteItem) {
        // delete files
        // console.log('delete item', itemsData[i]);
        // eslint-disable-next-line no-await-in-loop
        await promoBannerService.deletePromoBannerItem(
          promoBannerId,
          itemsData[i].id
        );

        fileService.deleteFolderContents(
          `promo_banner/${promoBannerId}/${itemsData[i].id}`
        );
      } else {
        // console.log('replace item', promoBannerId, itemsData[i]);
        // eslint-disable-next-line no-await-in-loop
        await promoBannerService.savePromoBannerItem(
          promoBannerId,
          itemsData[i].id,
          itemsData[i]
        );

        if (items[i].file) {
          // eslint-disable-next-line no-await-in-loop
          await promoBannerService.saveFile(
            promoBannerId,
            itemsData[i].id,
            'image',
            items[i].file
          );
        }
        if (items[i].fullscreen_file) {
          // eslint-disable-next-line no-await-in-loop
          await promoBannerService.saveFile(
            promoBannerId,
            itemsData[i].id,
            'fullscreen_image',
            items[i].fullscreen_file
          );
        }
      }
    }
  };
  const saveItem = item => {
    if (item.newItem) {
      const itemsCopy = [...items];
      const itemCopy = { ...item };
      itemsCopy.push(itemCopy);
      setItems(itemsCopy);
    } else {
      const index = items.findIndex(i => i.id === item.id);
      if (index > -1) {
        const itemsCopy = [...items];
        itemsCopy[index] = item;
        setItems(itemsCopy);
      }
    }
  };

  const deleteItem = item => {
    const index = items.findIndex(i => i.id === item.id);
    if (index > -1) {
      const itemsCopy = [...items];
      const itemCopy = { ...item };
      itemCopy.deleteItem = true;
      itemsCopy[index] = itemCopy;
      setItems(itemsCopy);
    }
  };

  let price_areas = [];
  if (promoBanner.id && promoBanner.price_areas && areas.length) {
    price_areas = promoBanner.price_areas.map(p => areas.find(a => a.id === p));
  }

  return (
    <>
      <BoxLoading isLoading={loading}>
        <Formik
          initialValues={{
            name: promoBanner.name || '',
            color: promoBanner.color || '#000000',
            font_color: promoBanner.font_color || '#000000',
            available: promoBanner.available || false,
            full_view: promoBanner.full_view || false,
            price_areas
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required('Nombre es requerido'),
            color: Yup.string()
              .max(255)
              .required('Color es requerido'),
            font_color: Yup.string()
              .max(255)
              .required('Color de fuente es requerido'),
            price_areas: Yup.array().required('Areas son requeridas')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (renderItems.length === 0) return;
              setLoading(true);
              await savePromoBanner(values);

              setStatus({ success: true });
              setSubmitting(false);
              // resetForm();
              enqueueSnackbar(translation('admin_promos.correct_save'), {
                variant: 'success'
              });
              setLoading(false);
              history.push('/admin/promos_banner');
            } catch (e) {
              console.log(e);
              setStatus({ success: false });
              setSubmitting(false);
              enqueueSnackbar(
                `${translation('complete_restaurant.error')} ${
                  env.REACT_APP_SUPPORT_MAIL
                }`,
                {
                  variant: 'error'
                }
              );
              setErrors({ submit: e.message });
              setLoading(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => {
            return (
              <form
                noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Nombre"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          error={Boolean(touched.color && errors.color)}
                          fullWidth
                          helperText={touched.color && errors.color}
                          type="color"
                          label="Color"
                          name="color"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.color}
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          error={Boolean(
                            touched.font_color && errors.font_color
                          )}
                          fullWidth
                          helperText={touched.font_color && errors.font_color}
                          type="color"
                          label="Color de fuente"
                          name="font_color"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.font_color}
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          id="combo-box-areas"
                          options={areas}
                          multiple
                          getOptionLabel={area => {
                            if (!area) return;
                            const city = cities.find(
                              c => c.id === area.city_id
                            );
                            return `${area.name} (${city ? city.name : ''})`;
                          }}
                          className={classes.selector}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Areas"
                              variant="outlined"
                              error={Boolean(
                                touched.price_areas && errors.price_areas
                              )}
                              helperText={
                                touched.price_areas && errors.price_areas
                              }
                            />
                          )}
                          value={values.price_areas}
                          onChange={(e, d) => {
                            setFieldValue('price_areas', d);
                          }}
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.available}
                              onChange={handleChange}
                              value={values.available}
                              name="available"
                            />
                          }
                          label="Habilitado"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.full_view}
                              onChange={handleChange}
                              value={values.full_view}
                              name="full_view"
                            />
                          }
                          label="Vista completa"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Box mb={3}>
                          <Grid container spacing={3}>
                            <Grid item md={5} xs={12}>
                              <Typography>
                                <strong>Item</strong>
                              </Typography>
                            </Grid>
                            <Grid item md={5} xs={12}>
                              <Typography>
                                <strong>Tipo</strong>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Box>
                            {renderItems &&
                              renderItems.map((item, index) => (
                                <Grid
                                  container
                                  spacing={3}
                                  key={index}
                                  className={classes.item}
                                >
                                  <Grid item md={5} xs={12}>
                                    <CardMedia
                                      className={classes.media}
                                      image={
                                        item.image ||
                                        '/static/home/image-placeholder.png'
                                      }
                                    />
                                  </Grid>
                                  <Grid item md={5} xs={12}>
                                    <Typography>{item.type}</Typography>
                                  </Grid>
                                  <Grid item md={2} xs={12}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <IconButton
                                        className={classes.icon}
                                        type="button"
                                        onClick={() => {
                                          setPromoBannerItem(item);
                                        }}
                                      >
                                        <Edit2 />
                                      </IconButton>
                                      <IconButton
                                        className={classes.icon}
                                        type="button"
                                        onClick={() => {
                                          deleteItem(item);
                                        }}
                                      >
                                        <XCircle />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              ))}
                            <Grid container spacing={3}>
                              <Grid item md={9} xs={12} />
                              <Grid item md={3} xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                  <IconButton
                                    type="button"
                                    onClick={() => {
                                      setPromoBannerItem(
                                        promoBannerService.getNewItem()
                                      );
                                    }}
                                    className={classes.icon}
                                  >
                                    <PlusCircle />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                            {renderItems.length === 0 && (
                              <Box mt={3}>
                                <FormHelperText error>
                                  Introducir al menos un item
                                </FormHelperText>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item />
                    </Grid>

                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                        className={classes.button}
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        {translation('save')}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            );
          }}
        </Formik>
      </BoxLoading>
      <ModalCreatePromoBannerItem
        show={!!promoBannerItem.id}
        onClose={() => setPromoBannerItem({})}
        item={promoBannerItem}
        onSuccess={saveItem}
      />
    </>
  );
}

PromosBannersEditForm.propTypes = {
  className: PropTypes.string,
  promoBanner: PropTypes.object
};

export default PromosBannersEditForm;
