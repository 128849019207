/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Card, Button, Grid, LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import firebase from 'firebase';
import { sendInvoiceToUser, updateOrder } from 'src/services/orderServices';
import { Alert } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useUser from '../../../hooks/useUser';
import { useFacturationStyles } from './styles';
import env from '../../../env';
import ModalInfo from '../../../components/Modal/ModalInfo';

const Facturation = ({
  order,
  className,
  onlyContent = false,
  showWhatapp = false
}) => {
  const classes = useFacturationStyles();
  const [user] = useUser(order.user_id);
  const translation = useTranslation();
  const [errorFile, setErrorFile] = useState('');
  const [documentData, setDocumentData] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const clearData = () => {
    setDocumentData('');
    setLoadingImage(false);
    setErrorFile(null);
  };

  useEffect(() => {
    clearData();
    setResponseData(null);
  }, [order]);

  useEffect(() => {
    if (documentData) {
      const extension = documentData.name.split('.').pop();
      if (extension.toLowerCase() !== 'pdf') {
        setErrorFile(
          'El formato del documento no es correcto, solo se admiten archivos PDF'
        );
        setDocumentData(null);
      } else {
        setErrorFile(null);
      }
    }
  }, [documentData]);

  if (!user) {
    return null;
  }
  const sendWhatsApp = () => {
    window.open(
      `${env.REACT_APP_WHATSAPP}${order.user_phone}?text=Gracias por utilizar XPRESS`,
      '_blank'
    );
  };

  const getDocument = e => {
    setResponseData(null);
    if (e.target.files) {
      setDocumentData(e.target.files[0]);
    }
  };
  const saveDocument = () => {
    setLoadingImage(true);
    try {
      const extension = documentData.name.split('.').pop();
      const storageRef = firebase
        .storage()
        .ref(`orders/${order.id}/invoices/${order.id}.${extension}`);
      const uploadTask = storageRef.put(documentData);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('progress', progress);
        },
        error => {
          console.log('error', error);
          clearData();
        },
        async () => {
          let response = { isSucceeded: false, message: '' };
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          if (url) {
            await updateOrder(order.id, {
              invoice_url: url
            });
            response = await sendInvoiceToUser(order.id, order.user_id);
          }
          setResponseData(response);
          clearData();
        }
      );
    } catch (e) {
      console.log('error', e.message);
      clearData();
    }
  };
  const points = [
    {
      text:
        '• Al subir un documento puedes enviar facturas digitales directamente al correo electrónico de un cliente.',
      bold: false
    },
    {
      text:
        '• El formato permitido para el envío de documentos es únicamente .pdf',
      bold: false
    },
    {
      text:
        '• Al enviar un documento se mostrará un texto mencionando si el envío fue exitoso o fallido.',
      bold: false
    },
    {
      text: '• Solo puedes enviar un documento a un cliente a la vez.',
      bold: false
    }
  ];

  const getContent = () => (
    <Box className={classes.content}>
      <ModalInfo
        title="¿Para qué sirve el botón de SUBIR DOCUMENTO?"
        isVisible={showModalInfo}
        description=""
        confirm={() => setShowModalInfo(false)}
        onClose={() => setShowModalInfo(false)}
        component={
          <Box className={classes.boxStyle}>
            {points.map((point, index) => (
              <Typography
                key={index}
                style={{
                  fontWeight: point.bold ? 'bold' : 'normal',
                  fontSize: 14
                }}
              >
                {point.text}
              </Typography>
            ))}
          </Box>
        }
      />
      <Typography className={classes.text}>
        <strong>{translation('orders.details.billing.reason')}</strong>
        <span>&nbsp;&nbsp;</span>
        {order.invoice_name}
      </Typography>

      <Typography className={classes.text}>
        <strong>{translation('orders.details.billing.nit')}</strong>
        <span>&nbsp;&nbsp;</span>
        {order.invoice_nit}
      </Typography>
      <Grid container xs={12}>
        {!documentData ? (
          <Box display="flex">
            <input
              accept="application/pdf"
              style={{ display: 'none' }}
              id="contained-button-file"
              multiple={false}
              type="file"
              onChange={getDocument}
            />
            <label htmlFor="contained-button-file">
              <Box className={classes.boxStyle}>
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  disabled={!order?.user_id}
                >
                  Subir Documento
                </Button>
              </Box>
            </label>
            <Box style={{ alignSelf: 'end' }}>
              <InfoOutlinedIcon
                className={classes.iconInfo}
                onClick={() => setShowModalInfo(true)}
              />
            </Box>
          </Box>
        ) : (
          <Grid item xs={12}>
            <Typography>{documentData.name}</Typography>
            <Button
              size="large"
              disabled={loadingImage || errorFile}
              className={classes.button}
              onClick={saveDocument}
              variant="contained"
              type="submit"
            >
              Enviar al cliente
            </Button>
          </Grid>
        )}
      </Grid>
      {errorFile && (
        <Box width="100%" my={1}>
          <Alert severity="error">{errorFile}</Alert>
        </Box>
      )}
      {loadingImage && (
        <Box width="100%" my={3}>
          <LinearProgress />
        </Box>
      )}
      {responseData && (
        <Box width="100%" my={3}>
          <Alert severity={responseData?.isSucceeded ? 'success' : 'error'}>
            {responseData?.isSucceeded
              ? 'Fue enviado correctamente'
              : 'Hubo un error'}
          </Alert>
        </Box>
      )}
      <Grid container>
        {showWhatapp && (
          <Button
            style={{ left: '125px' }}
            onClick={() => sendWhatsApp()}
            variant="contained"
            color="secondary"
            type="submit"
          >
            <WhatsAppIcon />
          </Button>
        )}
      </Grid>
    </Box>
  );

  if (onlyContent) {
    return getContent();
  }

  return (
    <Card className={clsx(classes.root)}>
      <Box className={classes.header}>
        <Typography className={clsx(classes.text, classes.noMarging)}>
          <strong>{translation('orders.details.billing.title')}</strong>
        </Typography>
      </Box>
      {getContent()}
    </Card>
  );
};

export default Facturation;
