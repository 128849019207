import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { useTranslation } from 'react-multi-lang/lib';
import getInitials from '../../../../utils/getInitials';
import { useDealers } from '../../../../hooks/AdminHooks/dealers/useDealers';
import { getTodayOrders } from '../../../../services/orderServices';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';
import MapDealersOrders from '../../../../components/Map/MapDealersOrders';

const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  },
  mapWrapper: {},
  checkbox: {},
  media: {
    width: 35,
    height: 35,
    display: 'inline-block'
  }
}));

const MapDealers = props => {
  // const dealers = useActiveDealers();
  const dealers = useDealers();
  const cities = useCities();
  const translate = useTranslation();
  const [cityId, setCityId] = useState('');
  const [dealer, setDealer] = useState();
  const [showOrders, setShowOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const classes = useStyles();

  const todayOrders = async () => {
    const ordersDB = await getTodayOrders();
    setOrders(ordersDB);
  };

  useEffect(() => {
    todayOrders();
    const cId = localStorage.getItem('driverMapcity');
    if (cId) {
      setCityId(cId);
    }
  }, []);

  const cityStore = cities.find(c => c.id === cityId);
  let city = {};
  if (cityStore) {
    city = {
      ...cityStore
    };
    city.location = {
      lat: city.location.latitude,
      lng: city.location.longitude
    };
  }

  let dealersMarker = [];
  if (dealer) {
    const itemDealer = { ...dealers.find(d => d.id === dealer.id) };
    if (
      itemDealer.location &&
      itemDealer.location.latitude &&
      itemDealer.location.longitude
    ) {
      itemDealer.location = {
        lat: itemDealer.location.latitude,
        lng: itemDealer.location.longitude
      };
    }
    dealersMarker = [itemDealer];
  } else {
    dealersMarker = dealers
      .filter(d => d.location && d.location.latitude && d.location.longitude)
      .map(d => ({
        ...d,
        location: {
          lat: d.location.latitude,
          lng: d.location.longitude
        }
      }));
  }

  let ordersMarker = [];
  if (orders.length && showOrders) {
    ordersMarker = orders
      .filter(
        o =>
          o.delivery_location &&
          o.delivery_location?.location &&
          o.delivery_location?.location?.latitude &&
          o.delivery_location?.location?.longitude
      )
      .map(o => ({
        ...o,
        location: {
          lat: o?.delivery_location?.location?.latitude,
          lng: o?.delivery_location?.location?.longitude
        }
      }));
  }
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={2} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="city-select-label">Ciudad</InputLabel>
              <Select
                fullWidth
                labelId="city-select-label"
                label="Ciudad"
                name="city"
                id="city-select"
                value={cityId}
                onChange={event => {
                  const id = event.target.value;
                  setCityId(id);
                  if (id) {
                    localStorage.setItem('driverMapcity', id);
                  } else {
                    localStorage.removeItem('driverMapcity');
                  }
                }}
              >
                {cities?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <Autocomplete
              id="combo-box-dealers"
              options={dealersMarker}
              freeSolo
              getOptionLabel={d => `${d.first_name} ${d.last_name}`}
              className={classes.selector}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Repartidores"
                  variant="outlined"
                />
              )}
              value={dealer}
              onChange={(e, d) => {
                setDealer(d && typeof d !== 'string' ? d : undefined);
              }}
              renderOption={d => (
                <Box display="flex" alignItems="center">
                  <Avatar
                    className={classes.avatar}
                    src={
                      d.profile_photo &&
                      (d.profile_photo.x40 ? d.profile_photo.x40 : '')
                    }
                  >
                    {getInitials(`${d.first_name} ${d.last_name}`)}
                  </Avatar>
                  <Box m={1}>
                    <Typography color="textPrimary" variant="h6">
                      <strong>{`${d.first_name} ${d.last_name}`}</strong>
                    </Typography>
                    <Typography color="textPrimary" variant="h6">
                      {`${d.phone_number}`}
                    </Typography>
                  </Box>
                </Box>
              )}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={showOrders}
                  onChange={event => {
                    setShowOrders(event.target.checked);
                  }}
                />
              }
              label="Mostrar ordenes"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <CardMedia
              className={classes.media}
              image="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
            />
            <Typography display="inline">
              {translate('dealers.dealers_status.unavailable')}
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <CardMedia
              className={classes.media}
              image="http://maps.google.com/mapfiles/ms/icons/orange-dot.png"
            />
            <Typography display="inline">
              {translate('dealers.dealers_status.delivery')} /{' '}
              {translate('dealers.dealers_status.going_to_pick')}
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <CardMedia
              className={classes.media}
              image="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
            />
            <Typography display="inline">
              {translate('dealers.dealers_status.waiting_for_orders')}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box className={classes.mapWrapper}>
              {city && city.location && (
                <MapDealersOrders
                  center={city?.location}
                  dealers={dealersMarker}
                  orders={ordersMarker}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MapDealers;
