import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { Grid, Box, CircularProgress, Button } from '@material-ui/core';
import moment from 'moment';
import useFilter from '../../../../../hooks/filter/useFilter';
import ExpressTable from '../../../../../components/Table/ExpressTable';
import filteredInvoices from '../../../../../services/invoiceService';
import InvoiceCard from '../../../commerce/tabs/invoice/Invoice';

const History = props => {
  const filter = useFilter();
  const translation = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState([]);
  const [invoice, setInvoice] = useState();
  const [rawList, setRawList] = useState([]);

  const tableColumns = [
    {
      id: 'counter',
      label: translation('accounting.table.counter'),
      minWidth: 50,
      align: 'center'
    },
    {
      id: 'restaurant',
      label: translation('accounting.table.rest'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'subsidiary',
      label: translation('accounting.table.sub'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'num_pay',
      label: translation('accounting.table.num_pay'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'period',
      label: translation('accounting.table.period'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'limit_date',
      label: translation('accounting.table.date'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'status',
      label: translation('accounting.table.status'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'amount',
      label: translation('accounting.table.sale'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'city',
      label: translation('accounting.table.city'),
      minWidth: 100,
      align: 'center'
    }
  ];

  useEffect(() => {
    setLoading(true);
    filteredInvoices(filter).then(r => {
      const tc = r.payload.map((currentInvoice, index) => ({
        counter: index + 1,
        restaurant: currentInvoice.restaurant_name,
        subsidiary: currentInvoice.subsidiary_name,
        num_pay: currentInvoice.payment_number
          ? currentInvoice.payment_number
          : '-',
        period: currentInvoice.created_at
          ? moment(currentInvoice.created_at.toDate()).format('LL')
          : '-',
        limit_date: currentInvoice.limit_date
          ? moment(currentInvoice.limit_date.toDate()).format('LL')
          : '-',
        status: currentInvoice.status ? currentInvoice.status : '-',
        amount: currentInvoice.amount ? currentInvoice.amount : '-',
        city: currentInvoice.subsidiary_city?.name || '-',
        id: currentInvoice.id
      }));
      setRawList(r.payload);
      setTableContent(tc);
      setLoading(false);
    });
  }, [filter]);

  const onSetInvoice = row => {
    const [currentInvoice] = rawList.filter(invo => invo.id === row.id);
    setInvoice(currentInvoice);
  };

  return (
    <Grid>
      {loading && !invoice && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !invoice && (
        <ExpressTable
          columns={tableColumns}
          rows={tableContent}
          clickRow={onSetInvoice}
        />
      )}
      {invoice && (
        <>
          <Button onClick={() => setInvoice(null)}>
            {`< ${translation('admin_subsidiary.common_table.back')}`}
          </Button>
          <InvoiceCard invoice={invoice} />
        </>
      )}
    </Grid>
  );
};

export default History;
