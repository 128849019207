import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ImagePicker from './ImagePicker';
import { useCampaingsImages } from '../../hooks/useCampaingsImages';

const useStyles = makeStyles(theme => ({
  image: {
    height: 250,
    backgroundSize: 'contain'
  }
}));

const NotificationImagePicker = ({ image, setImage }) => {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const images = useCampaingsImages();

  const onPick = src => {
    setImage(src);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <CardMedia
            className={classes.image}
            image={image || '/static/home/image-placeholder.png'}
            onClick={() => setShow(true)}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5">
            Escoja una imagen para la notificación
          </Typography>
        </Grid>
      </Grid>
      <ImagePicker
        show={show}
        onClose={() => setShow(false)}
        onPick={onPick}
        images={images}
        label="Escoja una imagen"
      />
    </>
  );
};

export default NotificationImagePicker;
