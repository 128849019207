import React from 'react';
import { Box, Grid, Card, makeStyles } from '@material-ui/core';
import OrderProductsTable from './OrderProductsTable';
import Header from './Header';
import Facturation from './Facturation';
import AdditionalReferences from './AdditionalReferences';
import TimeDetails from './TimeDetails';
import RateQualificationDriver from './RateQualificationDriver';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    padding: theme.spacing(3)
  }
}));

const OrderView = ({ order, adminView }) => {
  const classes = useStyles();

  const isDriverQualification = () => {
    let qualify = false;
    if (order.order_type === 'DELIVERY') {
      if (!order.driver_qualification) {
        qualify = true;
      } else if (!order.driver_qualification.merchant) qualify = true;
    }
    return qualify;
  };

  return (
    <>
      {adminView && (
        <>
          <Box mt={2}>
            <Grid item container>
              <Grid item md={6} xl={6} xs={6}>
                <Box mb={3} pr={3} width="100%">
                  <TimeDetails order={order} />
                </Box>
              </Grid>
              <Grid item md={6} xl={6} xs={6}>
                <Box mb={3} pl={3} width="100%">
                  <Facturation order={order} />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} xl={12} xs={12}>
                <OrderProductsTable adminView order={order} />
              </Grid>
              {order.additional_description && (
                <Grid item md={12} xl={12} xs={12}>
                  <AdditionalReferences order={order} />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}

      {!adminView && (
        <Card className={classes.cardContainer}>
          <Header order={order} />
          <Box mt={2}>
            <Grid container spacing={3}>
              <Grid item md={8} xl={8} xs={12}>
                <OrderProductsTable order={order} />
                <Box width="100%" mt={2}>
                  {isDriverQualification() && (
                    <RateQualificationDriver order={order} />
                  )}
                </Box>
              </Grid>
              <Grid item md={4} xl={4} xs={12}>
                <Facturation order={order} />
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
    </>
  );
};

export default OrderView;
