import React from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { COLORS } from 'src/theme/colors';

const useStyles = makeStyles(() => ({
  informationContainer: {
    borderRadius: '5px',
    border: `1px solid ${COLORS.yellowLink}`,
    padding: '28px 27px 20px 18px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    height: '100%'
  },
  total: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: COLORS.green,
    alignSelf: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'start',
    width: '100%'
  },
  snack: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '0px 10px'
  },
  description: {
    fontWeight: 'light',
    fontSize: '16px',
    color: COLORS.green,
    textAlign: 'center'
  },
  divider: {
    width: '100%'
  },
  box: {
    maxWidth: '60%'
  }
}));

const DebtInformation = ({ restaurant }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const debt = restaurant?.summary_invoices?.getTotalPending() || 0;
  return (
    <Box className={classes.informationContainer}>
      <Typography className={classes.title}>
        <strong>
          {translation('centralPayment.global_summary.pending_amount')}
        </strong>
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.total}>
        <strong>
          {translation('centralPayment.global_summary.currency')}
          {debt.toFixed(2)}
        </strong>
      </Typography>
      <Box className={classes.box}>
        <Typography className={classes.description}>
          {translation('centralPayment.global_summary.copy')}
        </Typography>
      </Box>
    </Box>
  );
};

export default DebtInformation;
