import { useState } from 'react';
import User from '../../../../dto/user';

const useRegisterUser = () => {
  const [user, setUser] = useState(new User());
  const updateUser = updatedUser => setUser({ ...user, ...updatedUser });

  return { user, updateUser };
};
export default useRegisterUser;
