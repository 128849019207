import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Box,
  Button,
  Grid,
  makeStyles,
  CardMedia,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useMenuGalleryDocs } from 'src/hooks/useMenuGallery';
import { COLORS } from 'src/theme/colors';
import CardHeader from './Card/CardHeader';
import XpressModal from './Modal';

const useStyles = makeStyles(theme => ({
  card: { maxWidth: 200, maxHeight: 160 },
  cardSelected: {
    maxWidth: 200,
    maxHeight: 160,
    border: '4px solid #B2E7D8 !important'
  },
  cardMedia: { height: 112 },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '6%',
    outline: 'none'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1px'
  },
  Header: {
    textAlign: 'center'
  },
  photosContaines: {
    overflow: 'scroll',
    overflowX: 'hidden !important',
    maxHeight: 380
  },
  dangerButton: {
    backgroundColor: theme.palette.expressColors.lightRed,
    color: theme.palette.expressColors.white,
    '&:hover': {
      backgroundColor: COLORS.red
    }
  }
}));

const ImageSelector = ({ handleClose, restaurantId, handleConfirm }) => {
  const classes = useStyles();
  const images = useMenuGalleryDocs(restaurantId);
  const [docsGallery, setDocsGallery] = useState([]);
  const [selected, setSelected] = useState(null);
  const [addNewModal, setAddNewModal] = useState(false);
  const [modal, setModal] = useState(null);
  const translation = useTranslation();
  useEffect(() => {
    if (images) {
      setDocsGallery(
        images.map(img => {
          return { selected: false, ...img };
        })
      );
    }
  }, [images]);
  const onSelect = row => {
    const tempImages = [];

    docsGallery.forEach((element, index) => {
      if (index === row) {
        tempImages.push({ ...element, selected: true });
      } else {
        tempImages.push({ ...element, selected: false });
      }
    });

    setDocsGallery(tempImages);
    setSelected(tempImages[row]);
  };

  const handleNewImage = () => {
    setAddNewModal(true);
    setModal(
      <XpressModal
        type="addNewImage"
        id={restaurantId}
        handleConfirm={async () => {
          setAddNewModal(false);
        }}
        handleClose={() => {
          setAddNewModal(false);
        }}
      />
    );
  };

  return (
    <div>
      <Card className={classes.content}>
        <Grid container direction="row">
          <Grid
            sm={5}
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px'
            }}
          >
            <Button
              style={{ paddingLeft: '16px' }}
              color="secondary"
              variant="contained"
              onClick={handleNewImage}
            >
              {translation(
                'edit.product_create.basic_information.add_new_image'
              )}
            </Button>
          </Grid>
          <Grid>
            <CardHeader
              containerStyle={{ paddingLeft: 0 }}
              className={classes.Header}
              title={translation(
                'edit.product_create.basic_information.selectImagesTitle'
              )}
            />
          </Grid>
        </Grid>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={2}
            className={docsGallery.length > 8 ? classes.photosContaines : ''}
          >
            {docsGallery.map((menu, index) => {
              return menu.image.original !== '' ? (
                <Grid sm={3} item key={index}>
                  <Card
                    className={
                      menu.selected ? classes.cardSelected : classes.card
                    }
                    onClick={() => onSelect(index)}
                  >
                    <CardMedia
                      className={classes.cardMedia}
                      component="img"
                      alt={menu.title}
                      height="140"
                      image={menu?.image?.x400 || menu?.image?.original}
                      title={menu.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {menu.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null;
            })}
          </Grid>
          <Box mt={3} width="100%">
            <Grid item container justify="center">
              <Box mr={2}>
                <Button
                  className={classes.dangerButton}
                  variant="contained"
                  onClick={() => handleClose(false)}
                >
                  {translation('cancel')}
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={!selected}
                  onClick={() => handleConfirm(selected)}
                >
                  {translation('edit.product_create.basic_information.select')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      {addNewModal && modal}
    </div>
  );
};

export default ImageSelector;
