/* eslint-disable */
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Icons from '../../../assets/icons';
import {useTranslation} from 'react-multi-lang/lib';
import env from "../../../env";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '1hv',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 20,
    paddingTop: 20
  },
  back: {
    padding: 4,
    borderWidth: 1,
    borderRadius: 100,
    borderStyle: 'solid'
  },
  doc: {
    color: theme.palette.text,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5)
    },
    '& a': {
      color: theme.palette.text.primary
    },
    '& ul': {
      paddingInlineStart: '40px'
    }
  }
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const translation = useTranslation();
  return (
    <Page className={classes.root} title={translation('terms_and_conditions.title')}>
      <Container maxWidth="md" maxHeight="md">
        <Card>
          <CardContent>
            <img
              className={classes.back}
              src={Icons.back}
              onClick={() => window.history.back()}
            />
            <Grid>
              <Box className={classes.doc}>
                <div dir="ltr">
                  <span>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        textAlign: 'center',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Términos de Uso y Condiciones
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt',
                        padding: '0pt 108.75pt 0pt 0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Legal
                      </span>
                    </h4>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESSDELIVERY, S.R.L.
                      </span>
                    </h4>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        TÉRMINOS Y CONDICIONES
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Última actualización: 1 de Mayo 2020
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        1. Relación contractual
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Las presentes Condiciones de uso (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Condiciones
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”) regulan el acceso o uso que usted haga, como persona,
                        desde cualquier país del mundo incluyendo Bolivia
                        (excluidos los Estados Unidos y sus territorios y
                        posesiones) de aplicaciones, páginas web, contenido,
                        productos y servicios (los “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Servicios
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”) puestos a disposición por XPRESSDELIVERY, S.R.L., una
                        Sociedad de Responsabilidad Limitada constituida en
                        Bolivia, con domicilio social en Calle La Plata (8 Este)
                        #19, Barrio Equipetrol, Santa Cruz de la Sierra,
                        Bolivia, inscrita en FundEmpresa (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”).
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O
                        USAR LOS SERVICIOS.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Mediante su acceso y uso de los Servicios usted acuerda
                        vincularse jurídicamente por estas Condiciones, que
                        establecen una relación contractual entre usted y
                        XPRESS. Si usted no acepta estas Condiciones, no podrá
                        acceder o usar los Servicios. Estas Condiciones
                        sustituyen expresamente los acuerdos o compromisos
                        previos con usted. XPRESS podrá poner fin de inmediato a
                        estas Condiciones o cualquiera de los Servicios respecto
                        de usted o, en general, dejar de ofrecer o denegar el
                        acceso a los Servicios o cualquier parte de ellos, en
                        cualquier momento y por cualquier motivo.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Se podrán aplicar condiciones suplementarias a
                        determinados Servicios, como políticas para un evento,
                        una actividad o una promoción particular, y dichas
                        condiciones suplementarias se le comunicarán en relación
                        con los Servicios aplicables. Las condiciones
                        suplementarias se establecen además de las Condiciones,
                        y se considerarán una parte de estas, para los fines de
                        los Servicios aplicables. Las condiciones suplementarias
                        prevalecerán sobre las Condiciones en el caso de
                        conflicto con respecto a los Servicios aplicables.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS podrá modificar las Condiciones relativas a los
                        Servicios cuando lo considere oportuno. Las
                        modificaciones serán efectivas después de la publicación
                        por parte de XPRESS de dichas Condiciones actualizadas
                        en esta ubicación o las políticas modificadas o
                        condiciones suplementarias sobre el Servicio aplicable.
                        Su acceso o uso continuado de los Servicios después de
                        dicha publicación constituye su consentimiento a
                        vincularse por las Condiciones y sus modificaciones.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        La recopilación y el uso que hacemos de la información
                        personal en relación con los Servicios es conforme y se
                        dispone en la Política de Privacidad de XPRESS,
                        disponible en{' '}
                      </span>
                      <a
                        href="https://www.uber.com/legal/usa/privacy"
                        style={{ textDecorationLine: 'none' }}
                        target="_blank"
                        data-saferedirecturl="https://www.google.com/url?q=https://www.uber.com/legal/usa/privacy&source=gmail&ust=1589482068531000&usg=AFQjCNG7T_GWtq3CQsI1kdzQPVMcQqmBww"
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',

                            backgroundColor: 'transparent',
                            fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal',
                            textDecorationLine: 'underline',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          https://www.xpressbolivia.com
                        </span>
                      </a>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        . XPRESS podrá facilitar a un procesador de
                        reclamaciones o a una aseguradora cualquier información
                        necesaria (incluida su información de contacto) si
                        hubiera quejas, disputas o conflictos, que pudieran
                        incluir un accidente, implicándole a usted y a un
                        tercero (incluido el Socio Repartidor o el Socio
                        Comercial) y dicha información o dichos datos fueran
                        necesarios para resolver la queja, la disputa o el
                        conflicto.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        2. Los Servicios
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Los Servicios constituyen una plataforma de tecnología
                        que permite a los usuarios de aplicaciones móviles de
                        XPRESS o páginas web proporcionadas como parte de los
                        Servicios (cada una, una “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Aplicación
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”) organizar y planear el transporte, mensajería, y/o
                        servicios de logística con terceros proveedores
                        independientes de dichos servicios, incluidos terceros
                        Socios Repartidores independientes y terceros
                        proveedores logísticos independientes, conforme a un
                        acuerdo con XPRESS o algunos afiliados de XPRESS (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Terceros proveedores
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). A no ser que XPRESS lo acepte mediante un contrato
                        separado por escrito con usted, los Servicios se ponen a
                        disposición sólo para su uso personal, no comercial.
                        USTED RECONOCE QUE XPRESS NO PRESTA SERVICIOS DE
                        TRANSPORTE, MENSAJERÍA, O DE LOGÍSTICA O FUNCIONA COMO
                        UNA EMPRESA DE TRANSPORTES Y QUE DICHOS SERVICIOS DE
                        TRANSPORTE O LOGÍSTICA SE PRESTAN POR TERCEROS
                        CONTRATISTAS INDEPENDIENTES, QUE NO ESTÁN EMPLEADOS POR
                        XPRESS NI POR NINGUNA DE SUS AFILIADAS, EMPRESAS
                        MATRICES O SUBSIDIARIAS.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Licencia.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Sujeto al cumplimiento de estas Condiciones, XPRESS le
                        otorga una licencia limitada, no exclusiva, no
                        sublicenciable, revocable, no transferible para: (i) el
                        acceso y uso de las Aplicaciones en su dispositivo
                        personal sólo en relación con su uso de los Servicios; y
                        (ii) el acceso y uso de cualquier contenido, información
                        y material relacionado que pueda ponerse a disposición a
                        través de los Servicios, en cada caso sólo para su uso
                        personal, no comercial. XPRESS y sus licenciantes se
                        reservan cualquier derecho que no haya sido expresamente
                        otorgado por el presente.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Restricciones.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted no podrá: (i) retirar cualquier nota de derechos
                        de autor, marca registrada u otra nota de propiedad de
                        cualquier parte de los Servicios; (ii) reproducir,
                        modificar, preparar obras derivadas sobre los Servicios,
                        distribuir, licenciar, arrendar, revender, transferir,
                        exhibir públicamente, presentar públicamente,
                        transmitir, retransmitir o explotar de otra forma los
                        Servicios, excepto como se permita expresamente por
                        XPRESS; (iii) descompilar, realizar ingeniería inversa o
                        desmontar los Servicios, excepto como se permita por la
                        ley aplicable; (iv) enlazar, reflejar o enmarcar
                        cualquier parte de los Servicios; (v) causar o lanzar
                        cualquier programa o script con el objeto de extraer,
                        indexar, analizar o de otro modo realizar prospección de
                        datos de cualquier parte de los Servicios o sobrecargar
                        o bloquear indebidamente la operación y/o funcionalidad
                        de cualquier aspecto de los Servicios; o (vi) intentar
                        obtener un acceso no autorizado o dañar cualquier
                        aspecto de los Servicios o sus sistemas o redes
                        relacionados.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Prestación de los Servicios.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted reconoce que partes de los Servicios podrán
                        ponerse a disposición bajo varias marcas u opciones de
                        pedidos de XPRESS asociadas con el transporte,
                        mensajería, servicios logísticos y marcas de pedidos,
                        incluyendo “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, “XPRESS Pa’Recoger”, “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {`${env.REACT_APP_COMPANY_NAME}`}
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ” “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Restaurantes
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Farmacias
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Supermercados
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Florerías
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Licorerías
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”, y “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS Courier
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”. Asimismo usted reconoce que los Servicios podrán
                        ponerse a disposición bajo dichas marcas u opciones de
                        pedidos por o en relación con: (i) ciertas subsidiarias
                        o afiliados de XPRESS; o (ii) Terceros Socios
                        Comerciales que vendan productos relacionado a una de
                        dichas variaciones en específico.&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Servicios y contenido de Terceros.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Los Servicios podrán ponerse a disposición o ser
                        accesibles en relación con servicios y contenido de
                        terceros (incluida la publicidad) que XPRESS no
                        controle. Usted reconoce que podrán ser de aplicación
                        diferentes condiciones y políticas de privacidad al uso
                        que haga de dichos servicios y contenido de terceros.
                        XPRESS no respalda dichos servicios y contenido de
                        terceros y XPRESS en ningún caso será responsable de
                        cualquier producto o servicio de dichos terceros
                        proveedores. Adicionalmente, Apple Inc., Google, Inc.,
                        Microsoft Corporation o BlackBerry Limited y/o sus
                        correspondientes subsidiarias o afiliados
                        internacionales serán terceros beneficiarios en este
                        contrato si usted accede a los Servicios utilizando
                        Aplicaciones desarrolladas para dispositivos móviles con
                        sistema iOS, Android, Microsoft Windows,
                        respectivamente. Estos terceros beneficiarios no son
                        parte de este contrato y no son responsables de la
                        prestación o apoyo de los Servicios de ninguna manera.
                        Su acceso a los Servicios utilizando estos dispositivos
                        se sujeta a las condiciones establecidas en las
                        condiciones de servicio de terceros beneficiarios
                        aplicables.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Titularidad.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Los Servicios y todos los derechos relativos a estos son
                        y permanecerán de la propiedad de XPRESS o de sus
                        licenciantes. Ninguna de estas Condiciones ni su uso de
                        los Servicios le transfieren u otorgan ningún derecho:
                        (i) sobre o en relación con los Servicios, excepto en
                        cuanto a la licencia limitada otorgada anteriormente; o
                        bien (ii) a utilizar o mencionar en cualquier modo a los
                        nombres de empresa, logotipos, nombres de producto y
                        servicio, marcas comerciales o marcas de servicio de
                        XPRESS o de sus licenciantes.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        3. Su uso de los Servicios
                      </span>
                    </h4>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Cuentas de usuario.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Con el fin de usar la mayor parte de los aspectos de los
                        Servicios, usted debe registrarse y mantener activa una
                        cuenta personal de usuario de los Servicios (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Cuenta
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Para obtener una Cuenta debe tener como mínimo 18
                        años, o tener la mayoría de edad legal en su
                        jurisdicción (si es distinta a los 18 años). El registro
                        de la cuenta le requiere que comunique a XPRESS
                        determinada información personal, como su nombre,
                        dirección, correo electrónico, número de teléfono móvil,
                        así como por lo menos un método de pago válido (bien una
                        tarjeta de crédito, débito, Tigo Money, efectivo, cuenta
                        con un procesador de pagos efectivo en las ciudades en
                        que este método esté disponible). Usted se compromete a
                        mantener la información en su Cuenta de forma exacta,
                        completa y actualizada. Si no mantiene la información de
                        Cuenta de forma exacta, completa y actualizada, incluso
                        el tener un método de pago inválido o que haya vencido,
                        podrá resultar en su imposibilidad para acceder y
                        utilizar los Servicios o en la resolución por parte de
                        XPRESS de estas Condiciones celebrado con usted. Usted
                        es responsable de toda la actividad que ocurre en su
                        Cuenta y se compromete a mantener en todo momento de
                        forma segura y secreta el nombre de usuario y la
                        contraseña de su Cuenta. A menos que XPRESS permita otra
                        cosa por escrito, usted solo puede poseer una Cuenta.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Requisitos y conducta del usuario.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        El Servicio no está disponible para el uso de personas
                        menores de 18 años. Usted no podrá autorizar a terceros
                        a utilizar su Cuenta, asimismo no podrá permitir a
                        personas menores de 18 años que reciban servicios de
                        entregas de Terceros proveedores, a menos que aquellos
                        sean acompañados por usted. No podrá ceder o transferir
                        de otro modo su Cuenta a cualquier otra persona o
                        entidad. Usted acuerda cumplir con todas las leyes
                        aplicables al utilizar los Servicios y solo podrá
                        utilizar los Servicios con fines legítimos (
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        por ejemplo
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        , no para el transporte de materiales ilegales o
                        peligrosos en caso de usar nuestro servicio de courier
                        ). En el uso de los Servicios, no causará estorbos,
                        molestias, incomodidades, daños, órdenes falsas, u otras
                        inconveniencias, tanto al Socio Repartidor, Socio
                        Comercial, o a cualquier otro Proveedor tercero. En
                        algunos casos, se le podrá requerir que facilite un
                        documento de identidad u otro elemento de verificación
                        de identidad para el acceso o uso de los Servicios, y
                        usted acepta que se le podrá denegar el acceso o uso de
                        los Servicios si se niega a facilitar el documento de
                        identidad o el elemento de verificación de identidad.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '14pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Condiciones especiales del servicio de encargo de compra
                        de productos a través de XPRESS.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        El Usuario tiene la opción de solicitar a través de la
                        Plataforma la adquisición presencial por parte del Socio
                        Repartidor de una serie de productos y/o servicios que
                        son ofrecidos por Socios Comerciales con los que XPRESS
                        mantiene un acuerdo comercial. El Usuario puede
                        seleccionar mediante un menú desplegable dentro de la
                        Plataforma, una serie de opciones predeterminadas por
                        los Comercios en donde se indica las características, el
                        precio, así como en ocasiones, una fotografía del
                        producto o servicio.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        El Usuario manifiesta su voluntad de encargar la compra
                        de determinados productos, resultando que el Socio
                        Repartidor es un mero mandatario por cuenta de este.
                        Como consecuencia de ello, sería el Usuario el único
                        responsable y quién, llegado el caso, asumiría cualquier
                        incidencia derivada de la naturaleza de dichos productos
                        (i.e. sanción, responsabilidad civil, responsabilidad
                        patrimonial, etc).
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        El Usuario es consciente y acepta que las descripciones
                        y, en su caso, los precios o las fotografías de los
                        productos y/o servicios ofertados en la Plataforma se
                        realizan en base a la información y documentación
                        proporcionada por los Socios Comerciales por lo que
                        XPRESS no puede ofrecer garantías frente a posibles
                        inexactitudes en dichas descripciones, precios y/o
                        fotografías.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Asimismo, el Usuario acepta que todos los productos
                        reflejados en la plataforma de XPRESS están sujetos a la
                        disponibilidad de los mismos y en este sentido asume la
                        posibilidad de que durante la realización del pedido se
                        encuentre con la no disponibilidad del producto y/o
                        servicio en el Socio Comercial. Adicionalmente, el
                        precio del producto puede variar ligeramente a causa de
                        modificaciones en el punto de venta. XPRESS se reserva
                        el derecho de proceder con la compra ante variaciones en
                        el precio de hasta un 30%, si superar el citado 30% el
                        Socio Repartidor se pondrá en contacto con el Usuario
                        para transmitirle la situación.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS se reserva el derecho a retirar cualquier
                        producto de su plataforma, así como de modificar el
                        contenido de su ficha en cualquier momento, sin que le
                        pueda ser atribuido ningún tipo de responsabilidad.
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Entregas en el mismo día.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '0pt',
                        marginBottom: '0pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        La entrega se podrá realizar siempre que se cumplan las
                        siguientes condiciones en el momento de realizar el
                        pedido:
                      </span>
                    </p>
                    <ul style={{ marginTop: 0, marginBottom: 0 }}>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: 'disc',
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: '2.4',
                            marginTop: '11pt',
                            marginBottom: '0pt'
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12pt',
                              backgroundColor: 'transparent',
                              fontVariantNumeric: 'normal',
                              fontVariantEastAsian: 'normal',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            El servicio se encuentre operativo para la franja
                            horaria escogida.
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: 'disc',
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: '2.4',
                            marginTop: '0pt',
                            marginBottom: '0pt'
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12pt',
                              backgroundColor: 'transparent',
                              fontVariantNumeric: 'normal',
                              fontVariantEastAsian: 'normal',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            La mercancía a entregar debe encontrarse dentro de
                            alguna de las ciudades y dentro del espacio
                            habilitado en las que opere XPRESS.
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: 'disc',
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: '2.4',
                            marginTop: '0pt',
                            marginBottom: '0pt'
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12pt',
                              backgroundColor: 'transparent',
                              fontVariantNumeric: 'normal',
                              fontVariantEastAsian: 'normal',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            El lugar de destino debe estar situado en la misma
                            ciudad en la que se encuentre el producto.
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: 'disc',
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: '2.4',
                            marginTop: '0pt',
                            marginBottom: '0pt'
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12pt',
                              backgroundColor: 'transparent',
                              fontVariantNumeric: 'normal',
                              fontVariantEastAsian: 'normal',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            El tamaño del pedido no puede superar las medidas:
                            40cm x 40cm x 30cm.
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: 'disc',
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: '2.4',
                            marginTop: '0pt',
                            marginBottom: '11pt'
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12pt',
                              backgroundColor: 'transparent',
                              fontVariantNumeric: 'normal',
                              fontVariantEastAsian: 'normal',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap'
                            }}
                          >
                            El peso máximo a cargar en un pedido será,
                            aproximadamente, de 9 Kg
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Compra de bebidas alcohólicas.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Los Usuarios que realicen un pedido que incluya la
                        adquisición y/o entrega de bebidas alcohólicas a través
                        de la Plataforma deben ser mayores de edad, habiendo
                        cumplido los 18 años. Al realizar un pedido que incluya
                        bebidas alcohólicas, el Usuario confirma que tiene al
                        menos 18 años de edad. XPRESS se reserva el derecho de
                        negarse a permitir el pedido de compra y/o entrega de
                        alcohol a cualquier persona que no pueda probar tener al
                        menos 18 años de edad.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        La presente cláusula será de idéntica aplicación a
                        cualquier otro producto y/o servicio análogo reservado a
                        la mayoría de edad según legislación vigente y que sea
                        solicitado por un Usuario a través de la Plataforma.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Asimismo, en los casos y en las ciudades en que se
                        encuentre restringida la venta y/o entrega de bebidas
                        alcohólicas en determinada franja horaria, el Usuario es
                        responsable de realizar pedidos en los horarios
                        permitidos según la normativa de ley. XPRESS se reserva
                        el derecho a negarse a permitir el pedido de compra y/o
                        entrega de alcohol fuera de los horarios permitidos.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Productos en la categoría de Farmacias.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Los Socios Repartidores actúan como mandatarios de
                        aquellos Usuarios que solicitan medicamentos de uso
                        humano no sujetos a prescripción médica a través de la
                        Plataforma para su recogida. En cualquier caso, XPRESS
                        no se hace responsable del uso que hagan los Usuarios de
                        los productos solicitados en la categoría de Farmacias
                        así como tampoco responderá por las cantidades y/o
                        condiciones de los productos que se dispensen en las
                        oficinas de farmacia.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontWeight: 700,
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Política de envío de productos ilegales, controlados, y
                        otros.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.4',
                        marginTop: '11pt',
                        marginBottom: '11pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Está prohibido{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        y/o controlado
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {' '}
                        el uso y XPRESS se reserva el derecho de cancelar{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        y/o negar cualquier orden que incluyan productos en la
                        lista de abajo.{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        La lista de ejemplos incluye pero no es limitada a los
                        siguientes:
                      </span>
                    </p>
                    <div dir="ltr" style={{ marginLeft: '0pt' }} align="left">
                      <table
                        style={{ border: 'none', borderCollapse: 'collapse' }}
                      >
                        <colgroup>
                          <col width={144} />
                          <col width={480} />
                        </colgroup>
                        <tbody>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Animales y Especies Reguladas
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Las partes de animales, o fluidos; semillas
                                  prohibidas, plantas nocivas; plantas u otros
                                  organismos regulados (incluyendo sus
                                  derivados) en peligro de extinción o cuyo
                                  comercio es en cualquier caso según lo que
                                  prevea la ley.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Pornografía infantil
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Material pornográfico involucrando a menores o
                                  contenido que puede ser percibido como
                                  pedofilia erótica.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Copyright sobre Software y Media
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Copias no autorizadas de libros, música,
                                  películas y otros materiales protegidos o por
                                  licencia incluyendo copias sin atribución
                                  apropiada; y copias no autorizadas de
                                  software, videojuegos y otros materiales
                                  protegidos o con licencia, incluyendo OEM u
                                  otros productos activando mensajes no
                                  solicitados.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Falsificaciones y productos desautorizados
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Réplicas o imitaciones de diseñadores u otros
                                  bienes; objetos de celebridades que
                                  normalmente requerirían autenticación;
                                  autógrafos falsos; divisas; sellos; tiquets; u
                                  otros bienes no autorizados.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Dispositivos o herramientas para desbloquear
                                  medidas de seguridad
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Módems, chips u otros dispositivos para
                                  desmantelar medidas técnicas de protección así
                                  como en dispositivos digitales, incluyendo
                                  para desbloquear iPhones.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '47pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Drogas
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Sustancias controladas, narcóticos, drogas
                                  ilegales, y drogas accesorias, incluyendo
                                  psicoactivas y drogas vegetales como setas
                                  alucinógenas así como material promoviendo su
                                  uso; o sustancias legales como plantas y
                                  hierbas, en una forma que sugiera su ingesta,
                                  inhalación, extracción, u otro uso que pueda
                                  provocar el mismo uso que un componente, droga
                                  o sustancia ilegal o que produzca beneficios
                                  no probados para la salud.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Juego y Apuestas
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Billetes de lotería, apuestas,
                                  membresías/inscripciones en sitios online de
                                  apuestas, y contenido relativo.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '47pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Productos de Venta en Farmacias
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Las entregas de productos de farmacia pueden
                                  estar limitadas o restringidas en determinados
                                  países y/o ciudades donde opera la
                                  Plataforma.&nbsp; No está permitido el
                                  encargo/envío de medicamentos sujetos a
                                  prescripción médica. Los medicamentos OTC/de
                                  venta libre, productos sanitarios
                                  complementarios, así como cualquier otro
                                  producto de higiene, nutrición o análogo, de
                                  venta en farmacia y de uso humano se
                                  encuentran sujetos al mandato que el Usuario
                                  confiera al Socio Repartidor y a lo que estime
                                  conveniente el farmacéutico.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Materiales para hacking y cracking
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Manuales, guías, información o equipamiento
                                  que infrinja la ley dañando o facilitando
                                  fraudulentamente el acceso a software,
                                  servidores, sitios web u otra propiedad
                                  protegida.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Partes humanas
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Órganos u otras partes del cuerpo; fluidos
                                  corporales; células madre; embriones.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '47pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Bienes robados o ilegales
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Materiales, productos o información que
                                  promueva bienes ilegales o facilite actos
                                  ilegales; bienes sobre los que no se tiene la
                                  propiedad o no se tiene el derecho a la venta;
                                  bienes producidos con violación de los
                                  derechos de terceros; bienes en violación de
                                  restricciones sobre importación, exportación o
                                  etiquetado; vehículos a motor sujetos a
                                  restricciones de transferencia; Usted (Usuario
                                  de XPRESS) es única y completamente
                                  responsable de verificar que todos los objetos
                                  son auténticos y legales.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Equipamiento de telecomunicaciones ilegal
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Dispositivos que pretenden obtener señal
                                  satélite gratis, productos ilegales para
                                  modificar teléfonos móviles y otros
                                  equipamientos.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '158pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Bienes ofensivos
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Bienes, literatura, productos, u otro material
                                  que:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Difame alguna persona o grupo de personas
                                  basándose en su raza, etnia, origen nacional,
                                  religión, sexo, u otro factor.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Difame a alguna persona o grupo de personas
                                  protegidas según la ley aplicable en supuestos
                                  de difamaciones (como por ejemplo la familia
                                  real en algunas jurisdicciones).
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Enaltecimiento o incitación de actos
                                  violentos.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Promover intolerancia u odio.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Promover o apoyar membresía en grupos
                                  terroristas u otras organizaciones prohibidas
                                  por ley.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  • &nbsp;{' '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  <span style={{ whiteSpace: 'pre-wrap' }}>
                                    {' '}
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Contravenir la moralidad pública.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Bienes ofensivos relacionados con un crimen
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Fotos u objetos de la escena de un crimen,
                                  como pertenencias personales, asociadas con
                                  criminales o con actos criminales.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Materiales preciosos
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Ventas a granel de piedras o materiales raros,
                                  escasos o valiosos.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Artefactos culturalmente protegidos
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Material cubierto por la Convención de la
                                  UNESCO de 1970 sobre Medidas que Deben
                                  Adoptarse para Prohibir e Impedir la
                                  Importación, la Exportación y la Transferencia
                                  de Propiedad Ilícitas de Bienes Culturales u
                                  otros bienes restringidos por ley a su venta,
                                  exportación o transferencia; Artefactos,
                                  formaciones de cuevas (estalactitas y
                                  estalagmitas);
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Dispositivos pirotécnicos y sustancias
                                  peligrosas que requieran permiso especial
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Artículos pirotécnicos y bienes relacionados
                                  en mercados en que su entrega está regulada
                                  así como sustancias como la gasolina o el
                                  propano.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Armas
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Armas de fuego, munición, y otros objetos
                                  incluyendo pero no limitando a armas de fuego,
                                  cuchillos camuflables, o indetectables, armas
                                  de artes marciales, silenciadores, munición o
                                  revistas de munición.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Divisas y Dinero
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Cambio de divisas o divisas aseguradas con
                                  metales preciosos, así como billetes, monedas,
                                  o cualquier otro&nbsp; título de valor
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Uso por menores
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Se reserva el derecho a rechazar pedidos de
                                  todos aquellos Usuarios menores de edad.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '23pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Mal uso de la plataforma/Abuso
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  No toleraremos el uso de lenguaje abusivo así
                                  como de actitudes abusivas hacia nuestra
                                  compañía o hacia nuestros Socios Repartidores
                                  y Socios Comerciales.
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ height: '35pt' }}>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Bienes, objetos, productos y/o artículos hasta
                                  determinado valor monetario
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: 'top',
                                padding: '5pt',
                                overflow: 'hidden'
                              }}
                            >
                              <p
                                dir="ltr"
                                style={{
                                  lineHeight: '1.38',
                                  marginTop: '11pt',
                                  marginBottom: '11pt'
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '12pt',
                                    fontFamily: 'Arial',

                                    backgroundColor: 'transparent',
                                    fontVariantNumeric: 'normal',
                                    fontVariantEastAsian: 'normal',
                                    verticalAlign: 'baseline',
                                    whiteSpace: 'pre-wrap'
                                  }}
                                >
                                  Cualquiera sea su naturaleza, no podrán
                                  superar el importe de USD 100 (Cien Dólares
                                  estadounidenses)
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Mensajes de texto.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Al crear una Cuenta, usted acepta que los Servicios le
                        puedan enviar mensajes de texto informativos (SMS) como
                        parte de la actividad comercial normal de su uso de los
                        Servicios. Usted podrá solicitar la eliminación de su
                        suscripción de mensajes de texto informativos (SMS) de
                        XPRESS en cualquier momento. Para más información por
                        favor siga las instrucciones que están en está página:{' '}
                      </span>
                      <a
                        href="https://www.uber.com/es-PE/unsubscribe/"
                        style={{ textDecorationLine: 'none' }}
                        target="_blank"
                        data-saferedirecturl="https://www.google.com/url?q=https://www.uber.com/es-PE/unsubscribe/&source=gmail&ust=1589482068531000&usg=AFQjCNFokOJyw9xrXLahETtQgg6r3U_yBw"
                      >
                        <span
                          style={{
                            fontSize: '12pt',
                            fontFamily: 'Arial',
                            color: 'rgb(26,26,26)',
                            backgroundColor: 'transparent',
                            fontVariantNumeric: 'normal',
                            fontVariantEastAsian: 'normal',
                            textDecorationLine: 'underline',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          https://www.xpressbolivia.com
                        </span>
                      </a>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {' '}
                        Usted reconoce que solicitar la no recepción de mensajes
                        de texto informativos (SMS) podrá afectar al uso que
                        usted haga de los Servicios. Al crear una Cuenta y como
                        parte del proceso de verificación de identidad podremos
                        enviar un mensaje de texto (SMS) para confirmar que su
                        Cuenta está afiliado a un número de teléfono móvil
                        válido.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Códigos promocionales.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS podrá, a su sola discreción, crear códigos
                        promocionales que podrán ser canjeados por crédito de
                        Cuenta u otros elementos o beneficios relacionados con
                        los Servicios y/o los servicios de un Tercero proveedor,
                        con sujeción a cualquier condición adicional que XPRESS
                        establezca sobre la base de cada código promocional (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Códigos promocionales
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Usted acuerda que los Códigos promocionales: (i)
                        deben usarse para la audiencia y el propósito deseado, y
                        de manera lícita; (ii) no podrán duplicarse, venderse o
                        transferirse de ninguna manera, o ponerse a disposición
                        del público general (tanto si se publican en un foro
                        público como de otra forma), a menos que sea con el
                        permiso de XPRESS; (iii) podrán ser invalidados por
                        XPRESS en cualquier momento por cualquier motivo sin
                        responsabilidad para XPRESS; (iv) podrán usarse solo
                        conforme a las condiciones específicas que XPRESS
                        establezca para dicho Código promocional; (v) no son
                        válidos como efectivo; y (vi) podrán caducar antes de
                        que usted los utilice. XPRESS se reserva el derecho de
                        retener o deducir el crédito u otros elementos o
                        beneficios obtenidos a través de la utilización de los
                        Códigos promocionales por usted o cualquier otro usuario
                        en el caso de que XPRESS determine o crea que el uso o
                        el canje de los Códigos promocionales fue de modo
                        erróneo, fraudulento, ilegal o infringiendo las
                        condiciones del Código promocional o las presentes
                        Condiciones.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Contenido proporcionado por el Usuario.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS podrá, a su sola discreción, permitirle cuando
                        considere oportuno, que envíe, cargue, publique o de
                        otro modo ponga a disposición de XPRESS a través de los
                        Servicios contenido e información de texto, audio y/o
                        visual, incluidos comentarios y opiniones relativos a
                        los Servicios, iniciación de peticiones de apoyo, así
                        como presentación de admisiones para competiciones y
                        promociones (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Contenido de usuario
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Todo Contenido de usuario facilitado por usted
                        seguirá siendo de su propiedad. No obstante, al
                        proporcionar Contenido de usuario a XPRESS, usted otorga
                        una licencia mundial, perpetua, irrevocable,
                        transferible, libre de regalías, con derecho a
                        sublicenciar, usar, copiar, modificar, crear obras
                        derivadas, distribuir, exhibir públicamente, presentar
                        públicamente o de otro modo explotar de cualquier manera
                        dicho Contenido de usuario en todos los formatos y
                        canales de distribución, conocidos ahora o ideados en un
                        futuro (incluidos en relación con los Servicios y el
                        negocio de XPRESS y en sitios y servicios de terceros),
                        sin más aviso o consentimiento de usted y sin requerirse
                        el pago a usted o a cualquier otra persona o entidad.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted declara y garantiza que: (i) es el único y
                        exclusivo propietario de todo el Contenido de usuario o
                        que tiene todos los derechos, licencias, consentimientos
                        y permisos necesarios para otorgar a XPRESS la licencia
                        al Contenido de usuario como establecido anteriormente;
                        y (ii) ni el Contenido de usuario ni su presentación,
                        carga, publicación o puesta a disposición de otro modo
                        de dicho Contenido de usuario, ni el uso por parte de
                        XPRESS del Contenido de usuario como está aquí
                        permitido, infringirán, malversarán o violarán la
                        propiedad intelectual o los derechos de propiedad de un
                        tercero o los derechos de publicidad o privacidad o
                        resultarán en la violación de cualquier ley o reglamento
                        aplicable.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted acuerda no proporcionar Contenido de usuario que
                        sea difamatorio, calumnioso, odioso, violento, obsceno,
                        pornográfico, ilícito o de otro modo ofensivo, como
                        determine XPRESS, a su sola discreción, tanto si dicho
                        material pueda estar protegido o no por la ley. XPRESS
                        podrá, a su sola discreción y en cualquier momento y por
                        cualquier motivo, sin avisarle previamente, revisar,
                        controlar o eliminar Contenido de usuario, pero sin
                        estar obligada a ello.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Acceso a la red y dispositivos.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted es responsable de obtener el acceso a la red de
                        datos necesario para utilizar los Servicios. Podrán
                        aplicarse las tarifas y tasas de datos y mensajes de su
                        red móvil si usted accede o utiliza los Servicios desde
                        un dispositivo inalámbrico y usted será responsable de
                        dichas tarifas y tasas. Usted es responsable de adquirir
                        y actualizar el hardware compatible o los dispositivos
                        necesarios para acceder y utilizar los Servicios y
                        Aplicaciones y cualquier actualización de estos. XPRESS
                        no garantiza que los Servicios, o cualquier parte de
                        estos, funcionen en cualquier hardware o dispositivo
                        particular. Además, los Servicios podrán ser objeto de
                        disfunciones o retrasos inherentes al uso de Internet y
                        de las comunicaciones electrónicas.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        4. Pago
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted entiende que el uso de los Servicios puede derivar
                        en cargos por los servicios o bienes que reciba de un
                        Tercer proveedor incluyendo cargos por alta demanda,
                        tasa especial, recargos adicionales, cargos por órdenes
                        pequeñas (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Cargos
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Después de que haya recibido los servicios u
                        obtenido los bienes mediante el uso de los Servicios,
                        XPRESS facilitará su pago de los Cargos aplicables en
                        nombre del Tercero proveedor como agente de cobro
                        limitado del Tercero proveedor. XPRESS está establecido
                        en Bolivia, por lo que es posible que los bancos del
                        país puedan aplicar tarifas adicionales de transacción.
                        Si usa métodos de pagos de otros países, es posible que
                        los bancos del país puedan aplicar cargos por la
                        conversión de monedas y de uso internacional que no se
                        reflejarán en la Aplicación o el correo electrónico
                        enviado por XPRESS. El pago de los Cargos de dicha
                        manera se considerará como el pago efectuado
                        directamente por usted al Tercero proveedor. Los Cargos
                        incluirán los impuestos aplicables cuando se requiera
                        por ley. Los Cargos pagados por usted son definitivos y
                        no reembolsables, a menos que XPRESS determine lo
                        contrario. Usted conserva el derecho de solicitar Cargos
                        más bajos de un Tercero proveedor para los servicios o
                        bienes recibidos por usted de dicho Tercero proveedor en
                        el momento en que reciba dichos servicios o bienes.
                        XPRESS responderá en consecuencia a cualquier solicitud
                        de un Tercero proveedor para modificar los Cargos por un
                        servicio o bien en particular. Los cargos pueden incluir
                        recargos aplicables, incluyendo cuotas por solicitud,
                        cuotas o cargos municipales, estatales o
                        nacionales/federales, cargos por tasa especial, cargos
                        por órdenes pequeñas, recargos o tarifas de
                        procesamiento por pagos fraccionados, entre otros que
                        sean aplicables.&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Todos los Cargos son pagaderos inmediatamente y el pago
                        se facilitará por XPRESS utilizando el método de pago
                        preferido indicado en su Cuenta, y después de ello el
                        Socio Comercial o XPRESS le enviará un recibo impreso o
                        por correo electrónico. Si se determina que el método de
                        pago de su Cuenta principal ha caducado, es inválido o
                        de otro modo no sirve para cobrarle, usted acepta que
                        XPRESS, como agente de cobro limitado del Tercero
                        proveedor, utilice un método de pago secundario en su
                        Cuenta, si estuviera disponible.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS, en cualquier momento y a su sola discreción, se
                        reserva el derecho de establecer, eliminar y/o revisar
                        los Cargos para alguno o todos los servicios o bienes
                        obtenidos a través del uso de los Servicios. Además,
                        usted reconoce y acepta que los Cargos aplicables en
                        determinadas zonas geográficas podrán incrementar
                        sustancialmente durante los periodos de alta demanda.
                        XPRESS usará los esfuerzos razonables para informarle de
                        los Cargos que podrán aplicarse, siempre y cuando usted
                        sea responsable de los Cargos incurridos en su Cuenta,
                        independientemente de que usted conozca dichos Cargos o
                        los importes de estos. XPRESS podrá, cuando lo considere
                        oportuno, proporcionar a determinados usuarios ofertas
                        promocionales y descuentos que pueden resultar en el
                        cobro de diferentes importes por estos o similares
                        servicios o bienes obtenidos a través del uso de los
                        Servicios, y usted acepta que dichas ofertas
                        promocionales y descuentos, a menos que también se
                        pongan a su disposición, no se tendrán en cuenta en el
                        uso por su parte de los Servicios o los Cargos aplicados
                        a usted. Usted podrá optar por cancelar su solicitud
                        para los servicios o bienes de un Tercero proveedor en
                        cualquier momento antes de que el Socio Comercial
                        comience a preparar la orden, en cuyo caso se le podrá
                        cobrar el total de la órden.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Esta estructura de pago está destinada para compensar
                        plenamente al Tercero proveedor por los servicios o
                        bienes proporcionados. XPRESS no designa ninguna parte
                        de su pago como propina o gratificación al Tercero
                        proveedor. Cualquier manifestación por parte de XPRESS
                        (en la página web de XPRESS o en los materiales de
                        marketing de XPRESS) en el sentido de que dar una
                        propina es “voluntario”, “no requerido”, y/o “incluido”
                        en los pagos que realiza para los servicios o bienes
                        proporcionados no pretende sugerir que XPRESS
                        proporciona importes adicionales, aparte de los
                        descritos anteriormente, al Tercero proveedor. Usted
                        entiende y acepta que, mientras es libre de proporcionar
                        un pago adicional como gratificación a cualquier Tercero
                        proveedor que le proporcione servicios o bienes
                        obtenidos mediante el Servicio, no tiene obligación de
                        ello. Las gratificaciones son voluntarias. Después de
                        que haya recibido los bienes o servicios obtenidos a
                        través del Servicio, tendrá la oportunidad de calificar
                        su experiencia y dejar comentarios adicionales sobre el
                        Tercero proveedor.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        5. Renuncias; Limitación de responsabilidad; Indemnidad.
                      </span>
                    </h4>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        RENUNCIA.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        LOS SERVICIOS SE PROPORCIONAN “TAL CUAL” Y “COMO
                        DISPONIBLES”. XPRESS RENUNCIA A TODA DECLARACIÓN Y
                        GARANTÍA, EXPRESA, IMPLÍCITA O ESTATUTARIA, NO
                        EXPRESAMENTE ESTABLECIDA EN ESTAS CONDICIONES, INCLUIDAS
                        LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD
                        PARA UN FIN PARTICULAR Y NO VIOLACIÓN. ADEMÁS, XPRESS NO
                        HACE DECLARACIÓN NI PRESTA GARANTÍA ALGUNA RELATIVA A LA
                        FIABILIDAD, PUNTUALIDAD, CALIDAD, IDONEIDAD O
                        DISPONIBILIDAD DE LOS SERVICIOS O CUALQUIERA DE LOS
                        SERVICIOS O BIENES SOLICITADOS A TRAVÉS DEL USO DE LOS
                        SERVICIOS, O QUE LOS SERVICIOS NO SERÁN INTERRUMPIDOS O
                        ESTARÁN LIBRES DE ERRORES. XPRESS NO GARANTIZA LA
                        CALIDAD, IDONEIDAD, SEGURIDAD O HABILIDAD DE LOS
                        TERCEROS PROVEEDORES. USTED ACUERDA QUE TODO RIESGO
                        DERIVADO DE SU USO DE LOS SERVICIOS Y CUALQUIER SERVICIO
                        O BIEN SOLICITADO EN RELACIÓN CON AQUELLOS SERÁ
                        ÚNICAMENTE SUYO, EN LA MÁXIMA MEDIDA PERMITIDA POR LA
                        LEY APLICABLE.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        LIMITACIÓN DE RESPONSABILIDAD.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS NO SERÁ RESPONSABLE DE DAÑOS INDIRECTOS,
                        INCIDENTALES, ESPECIALES, EJEMPLARES, PUNITIVOS O
                        EMERGENTES, INCLUIDOS EL LUCRO CESANTE, LA PÉRDIDA DE
                        DATOS, LA LESIÓN PERSONAL O EL DAÑO A LA PROPIEDAD, NI
                        DE PERJUICIOS RELATIVOS, O EN RELACIÓN CON, O DE OTRO
                        MODO DERIVADOS DE CUALQUIER USO DE LOS SERVICIOS,
                        INCLUSO AUNQUE XPRESS HAYA SIDO ADVERTIDO DE LA
                        POSIBILIDAD DE DICHOS DAÑOS. XPRESS NO SERÁ RESPONSABLE
                        DE CUALQUIER DAÑO, RESPONSABILIDAD O PÉRDIDA QUE DERIVEN
                        DE: (I) SU USO O DEPENDENCIA DE LOS SERVICIOS O SU
                        INCAPACIDAD PARA ACCEDER O UTILIZAR LOS SERVICIOS; O
                        (ii) CUALQUIER TRANSACCIÓN O RELACIÓN ENTRE USTED Y
                        CUALQUIER TERCERO PROVEEDOR, AUNQUE XPRESS HUBIERA SIDO
                        ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS. XPRESS NO
                        SERÁ RESPONSABLE DEL RETRASO O DE LA FALTA DE EJECUCIÓN
                        RESULTANTE DE CAUSAS QUE VAYAN MÁS ALLÁ DEL CONTROL
                        RAZONABLE DE XPRESS. EN NINGÚN CASO LA RESPONSABILIDAD
                        TOTAL DE XPRESS HACIA USTED EN RELACIÓN CON LOS
                        SERVICIOS POR TODOS LOS DAÑOS, LAS PÉRDIDAS Y LOS
                        JUICIOS PODRÁ EXCEDER DE CIEN DÓLARES AMERICANOS ($us.
                        100).
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        USTED PODRÁ UTILIZAR LOS SERVICIOS DE XPRESS PARA
                        SOLICITAR Y PLANIFICAR SERVICIOS DE TRANSPORTE,
                        MENSAJERÍA, BIENES O LOGÍSTICOS CON TERCEROS
                        PROVEEDORES, PERO ACEPTA QUE XPRESS NO TIENE
                        RESPONSABILIDAD ALGUNA HACIA USTED EN RELACIÓN CON
                        CUALQUIER SERVICIO DE TRANSPORTE, MENSAJERÍA, BIENES O
                        LOGÍSTICO PRESTADO A USTED POR TERCEROS PROVEEDORES QUE
                        NO SEA COMO SE HA ESTABLECIDO EXPRESAMENTE EN ESTAS
                        CONDICIONES.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        LAS LIMITACIONES Y LA RENUNCIA EN ESTE APARTADO 5 NO
                        PRETENDEN LIMITAR LA RESPONSABILIDAD O ALTERAR SUS
                        DERECHOS COMO CONSUMIDOR QUE NO PUEDAN EXCLUIRSE SEGÚN
                        LA LEY APLICABLE.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Indemnidad.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Usted acuerda mantener indemnes y responder frente a
                        XPRESS y sus consejeros, directores, empleados y agentes
                        por cualquier reclamación, demanda, pérdida,
                        responsabilidad y gasto (incluidos los honorarios de
                        abogados) que deriven de: (i) su uso de los Servicios o
                        servicios o bienes obtenidos a través de su uso de los
                        Servicios; (ii) su incumplimiento o violación de
                        cualquiera de estas Condiciones; (iii) el uso por parte
                        de XPRESS de su Contenido de usuario; o (iv) su
                        infracción de los derechos de cualquier tercero,
                        incluidos Terceros proveedores.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        6. Legislación aplicable; Arbitraje.
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Salvo que aquí se especifique lo contrario, las
                        presentes Condiciones se regirán e interpretarán
                        exclusivamente en virtud de la legislación de Bolivia,
                        con exclusión de sus normas sobre conflicto de leyes. La
                        Convención de Viena sobre los Contratos de Compraventa
                        Internacional de Mercaderías de 1980 (CISG) no se
                        aplicará. Cualquier disputa, conflicto, reclamación o
                        controversia, del tipo que sea, que resulte de las
                        presentes Condiciones o que se relacione en gran parte
                        con ellas, incluyendo las relativas a su validez,
                        interpretación y exigibilidad (cualquier “
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Disputa
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”), deberán someterse forzosamente a procedimientos de
                        mediación en virtud del Reglamento de Mediación de la
                        Cámara de Comercio Internacional (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Reglamento de Mediación de la CCI
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Si dicha disputa no fuese solucionada en un plazo de
                        sesenta (60) días desde la fecha en la que se formalice
                        la solicitud de mediación en virtud del Reglamento de
                        Mediación de la CCI, se hará referencia a dicha disputa
                        y se solucionará exclusiva y definitivamente mediante
                        arbitraje en virtud del Reglamento de Arbitraje de la
                        Cámara de Comercio Internacional (“
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontStyle: 'italic',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Reglamento de Arbitraje de la CCI
                      </span>
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        ”). Las disposiciones sobre Proceso Expedito y del
                        Árbitro de Emergencia del Reglamento de la CCI no se
                        aplicarán. La disputa será resuelta por un (1) árbitro
                        nombrado a tal fin en virtud del Reglamento de la CCI.
                        El lugar tanto para la mediación como para el arbitraje
                        será Santa Cruz de la Sierra, Bolivia. El idioma de
                        mediación y/o arbitraje será el Español. La existencia y
                        el contenido de los procedimientos de mediación y
                        arbitraje, incluidos los documentos e informes
                        presentados por las partes, la correspondencia de la
                        CCI, la correspondencia del mediador y la
                        correspondencia, los pedidos y los laudos emitidos por
                        el único árbitro deberán permanecer en estricta
                        confidencialidad y no deberán ser revelados a ningún
                        tercero sin el consentimiento expreso por escrito de la
                        otra parte, a menos que: (i) la revelación al tercero
                        sea razonablemente necesaria para llevar a cabo el
                        procedimiento de mediación o arbitraje; y (ii) el
                        tercero acepte incondicionalmente por escrito estar
                        sujeto a la obligación de confidencialidad estipulada en
                        el presente documento.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '12pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        7. Otras disposiciones
                      </span>
                    </h4>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Reclamaciones por infracción de derechos de autor.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Las reclamaciones por infracción de derechos de autor
                        deberán enviarse al agente designado de XPRESS. Puede
                        contactarnos por email a{' '}
                        <a href="mailto:info@xpressbolivia.com" target="_blank">
                          info@xpressbolivia.com
                        </a>
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Notificaciones.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS podrá notificar por medio de una notificación
                        general en los Servicios, mediante un correo electrónico
                        enviado a su dirección electrónica en su Cuenta o por
                        comunicación escrita enviada a su dirección, según lo
                        dispuesto en su Cuenta. Usted podrá notificar a XPRESS
                        por comunicación escrita a la dirección de XPRESS en
                        Avenida Banzer #1234, Santa Cruz de la Sierra, Bolivia.
                      </span>
                    </p>
                    <h5
                      dir="ltr"
                      style={{
                        lineHeight: '1.38',
                        marginTop: '11pt',
                        marginBottom: '2pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Disposiciones generales.
                      </span>
                    </h5>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        No podrá ceder ni transferir estas Condiciones, en todo
                        o en parte, sin el consentimiento previo por escrito de
                        XPRESS. Usted da su aprobación a XPRESS para ceder o
                        transferir estas Condiciones, en todo o en parte,
                        incluido a: (i) una subsidiaria o un afiliado; (ii) un
                        adquirente del capital, del negocio o de los activos de
                        XPRESS; o (iii) un sucesor por fusión. No existe entre
                        usted, XPRESS o cualquier Tercer proveedor una empresa
                        conjunta o relación de socios, empleo o agencia como
                        resultado del contrato entre usted y XPRESS o del uso de
                        los Servicios.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        Si cualquier disposición de estas Condiciones se
                        considerara ilegal, nula o inexigible, ya sea en su
                        totalidad o en parte, de conformidad con cualquier
                        legislación, dicha disposición o parte de esta se
                        considerará que no forma parte de estas Condiciones,
                        aunque la legalidad, validez y exigibilidad del resto de
                        las disposiciones de estas Condiciones no se verá
                        afectada. En ese caso, las partes deberán reemplazar
                        dicha disposición ilegal, nula o inexigible, en todo o
                        en parte por una disposición legal, válida y exigible
                        que tenga, en la medida de lo posible, un efecto similar
                        al que tenía la disposición ilegal, nula o inexigible,
                        dados los contenidos y el propósito de estas
                        Condiciones. Estas Condiciones constituyen el contrato
                        íntegro y el entendimiento entre las partes en relación
                        con el objeto y sustituye y reemplaza a todos los
                        contratos o acuerdos anteriores o contemporáneos en
                        relación con dicho objeto. En estas Condiciones, las
                        palabras “incluido/a/os/as” e “incluye/n” significan
                        “incluido, de forma meramente enunciativa”.
                      </span>
                    </p>
                    <h4
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '14pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          backgroundColor: 'transparent',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        8. Política de no discriminación
                      </span>
                    </h4>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: '2.05715',
                        marginTop: '0pt',
                        marginBottom: '12pt'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',

                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        XPRESS busca garantizar la disponibilidad a todos de los
                        servicios de coordinación de entregas fiables y de alta
                        calidad. Por este motivo, XPRESS, empresas matrices,
                        subsidiarias, y afiliados prohíben la discriminación
                        contra Usuarios o Socios Repartidores por motivos de
                        raza, religión, nacionalidad, discapacidad, orientación
                        sexual, sexo, estado civil, identidad de género, edad o
                        cualquier otra característica protegida por la
                        legislación gubernamental pertinente. Dentro de dicha
                        discriminación, se incluye, entre otras cosas, negarse a
                        proporcionar o recibir servicios por motivo de
                        cualquiera de las características mencionadas. Cualquier
                        Usuario o Socio Repartidor que haya infringido esta
                        prohibición perderá el acceso a la plataforma XPRESS.
                      </span>
                    </p>
                  </span>
                  <div>
                    <br />
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default TermsAndConditions;
