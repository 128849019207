import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function MembersListView() {
  const classes = useStyles();
  const members = useSelector(state => state.firestore.ordered.members);
  const translation = useTranslation();

  if (!members) {
    return null;
  }
  return (
    <Page className={classes.root} title={translation('members.page_title')}>
      <Container maxWidth={false}>
        <Header />
        {members && (
          <Box mt={3}>
            <Results members={members} />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default MembersListView;
