import axios from 'axios';
import {
  dynamicLinkConfiguration,
  expressDriverLinkConfiguration
} from '../constants';

export default function createDynamicLink(
  title,
  description,
  id = '',
  type = 'topics',
  isDriver = false
) {
  const config = isDriver
    ? expressDriverLinkConfiguration
    : dynamicLinkConfiguration;
  const data = { ...config.template };
  data.dynamicLinkInfo.socialMetaTagInfo.socialTitle = title;
  data.dynamicLinkInfo.socialMetaTagInfo.socialDescription = description;
  data.dynamicLinkInfo.link = `${config.template.dynamicLinkInfo.link}/${type}/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        dynamicLinkConfiguration.firebaseDynamicLinkUrl +
          dynamicLinkConfiguration.firebaseApi,
        data
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}
