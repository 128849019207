import React from 'react';
import {
  FileMinus as FileMinusIcon,
  Watch as WatchIcon,
  TrendingUp as TrendingUpIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  BarChart
} from 'react-feather';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import MapIcon from '@material-ui/icons/MapOutlined';
import ImageIcon from '../../../components/ImageIcon';

export const owner = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        title: 'Dashboard',
        icon: TrendingUpIcon,
        href: '/app/management/dashboard'
      },
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
      // {
      //   title: 'menu.sections.chat',
      //   icon: MessageIcon,
      //   href: '/app/chat',
      //   chatAlert: ErrorIcon
      // }
    ]
  },
  {
    subheader: 'menu.sections.admin',
    id: 'administration',
    items: [
      {
        title: 'menu.sections.schedule',
        icon: WatchIcon,
        href: '/app/management/schedule'
      },
      {
        title: 'personal.personal',
        icon: UsersIcon,
        href: '/app/members'
      },
      {
        id: 'configuration',
        title: 'menu.sections.restaurantProfile',
        icon: HomeIcon,
        href: '/app/configuration'
      },
      {
        id: 'subsidiary_menu',
        title: 'menu.menu_section.menu',
        icon: FileMinusIcon,
        href: '/app/editor/menu-sections', // TODO: Subitmes arrive here from line 360, Improve this into the future
        isLink: false
      },
      {
        id: 'shared_menu',
        title: 'menu.sections.shared_menu',
        icon: FileMinusIcon,
        href: '/app/management/shared_menu',
        isLink: false
      },
      {
        id: 'menu_gallery',
        title: 'menu.sections.menu_gallery',
        icon: PhotoLibraryIcon,
        href: '/app/management/menu_gallery',
        isLink: false
      },
      {
        id: 'delivery_map',
        title: 'menu.sections.delivery_map',
        icon: MapIcon,
        href: '/app/delivery_map',
        isLink: false
      }
      // {
      //   id: 'subsidyDelivery',
      //   title: 'menu.sections.subsidyDelivery',
      //   icon: FaMotorcycle,
      //   href: '/app/subsidyDelivery'
      // }
      // {
      //   id: 'reward',
      //   title: 'rewards.title',
      //   icon: DollarSign,
      //   href: '/app/rewards/restaurant'
      // },
      // {
      //   id: 'logs',
      //   title: 'logs.title',
      //   icon: DehazeIcon,
      //   href: '/app/logs'
      // }
    ]
  },
  {
    subheader: 'earning.header.performance',
    id: 'performance',
    items: [
      /*       {
        title: 'earning.header.reports',
        icon: DollarSign,
        href: '/app/performance/earnings'
      }, */
      {
        title: 'earning.header.performance_report',
        icon: BarChart,
        href: '/app/performance/performance'
      },
      {
        title: 'earning.header.sales_report',
        icon: BarChart,
        href: '/app/performance/salesReport'
      }
    ]
  },
  {
    subheader: 'payment.header.payment_history',
    id: 'payment',
    items: [
      {
        title: 'payment.header.payment_information',
        icon: FaFileInvoiceDollar,
        href: '/app/centralPayments'
      }
    ]
  }
];

export const cashier = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        id: 'menu_gallery',
        title: 'menu.sections.menu_gallery',
        icon: PhotoLibraryIcon,
        href: '/app/management/menu_gallery',
        isLink: false
      },
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
      // {
      //   title: 'menu.sections.chat',
      //   icon: MessageIcon,
      //   href: '/app/chat',
      //   chatAlert: ErrorIcon
      // }
    ]
  },
  {
    subheader: 'menu.sections.admin',
    items: [
      {
        title: 'menu.sections.schedule',
        icon: WatchIcon,
        href: '/app/management/schedule'
      },
      {
        id: 'subsidiary_menu',
        title: 'menu.menu_section.menu',
        icon: FileMinusIcon,
        href: '/app/editor/menu-sections', // TODO: Subitmes arrive here from line 360, Improve this into the future
        isLink: false
      }
      // {
      //   id: 'subsidyDelivery',
      //   title: 'menu.sections.subsidyDelivery',
      //   icon: FaMotorcycle,
      //   href: '/app/subsidyDelivery'
      // }
    ]
  }
];

export const supervisor = [
  {
    subheader: 'menu.sections.overview',
    id: 'editor',
    href: '/app/editor',
    items: [
      {
        title: 'menu.sections.orders',
        icon: () => <ImageIcon src="/static/merchant/orders/bag.png" />,
        href: '/app/orders'
      }
      // {
      //   title: 'menu.sections.chat',
      //   icon: MessageIcon,
      //   href: '/app/chat',
      //   chatAlert: ErrorIcon
      // }
    ]
  },
  {
    subheader: 'menu.sections.admin',
    id: 'administration',
    items: [
      {
        title: 'menu.sections.schedule',
        icon: WatchIcon,
        href: '/app/management/schedule'
      },
      {
        title: 'personal.personal',
        icon: UsersIcon,
        href: '/app/members'
      },
      {
        id: 'configuration',
        title: 'menu.sections.restaurantProfile',
        icon: HomeIcon,
        href: '/app/configuration'
      },
      {
        id: 'subsidiary_menu',
        title: 'menu.menu_section.menu',
        icon: FileMinusIcon,
        href: '/app/editor/menu-sections', // TODO: Subitmes arrive here from line 360, Improve this into the future
        isLink: false
      },
      {
        id: 'shared_menu',
        title: 'menu.sections.shared_menu',
        icon: FileMinusIcon,
        href: '/app/management/shared_menu',
        isLink: false
      },
      {
        id: 'menu_gallery',
        title: 'menu.sections.menu_gallery',
        icon: PhotoLibraryIcon,
        href: '/app/management/menu_gallery',
        isLink: false
      },
      {
        title: 'earning.header.sales_report',
        icon: BarChart,
        href: '/app/performance/salesReport'
      }
    ]
  }
];
