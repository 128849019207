import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import firebase from 'firebase/app';
import { useFirestore } from 'react-redux-firebase';
import { IoMdAddCircle } from 'react-icons/io';
import { useWebView } from 'src/hooks/window/windowHooks';
import { COLORS } from 'src/theme/colors';
import { COLLECTIONS, BORDER_DECORATION } from '../../constants';
import firebaseService from '../../services/firebaseService';
import MenuGallery from '../../dto/menuGallery';
import ModalCrop from '../Document/ModalCrop';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '15%',
    outline: 'none'
  },
  contentSmall: {
    padding: '16px 32px 24px',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5%',
    outline: 'none'
  },
  header: {
    textAlign: 'center'
  },
  required: {
    color: 'red'
  },
  image: {
    minHeight: 100,
    maxHeight: 200,
    width: '100%',
    cursor: 'pointer'
  },
  input: {
    marginTop: 15
  },
  cardDocument: {
    height: '183px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
    backgroundPosition: 'center',
    maxWidth: '100%',
    width: '250px'
  },
  cursor: {
    cursor: 'pointer'
  },
  box: {
    height: 60,
    marginBottom: '8px'
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dangerButton: {
    backgroundColor: theme.palette.expressColors.lightRed,
    color: theme.palette.expressColors.white,
    '&:hover': {
      backgroundColor: COLORS.red
    }
  }
}));

const AddNewImage = ({ handleClose, handleConfirm, id }) => {
  const webView = useWebView();
  const classes = useStyles();
  const firestore = useFirestore();
  const translation = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [docTitle, setDocTitle] = useState('');
  const [required, setRequired] = useState(false);
  const [requiredImg, setRequiredImg] = useState(false);
  const [openDialogCrop, setOpenDialogCrop] = useState(false);
  const [uploadImg, setUploadImg] = useState('');
  const [upImg, setUpImg] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const pathMenuGallery = `${COLLECTIONS.MENU_GALLERY}/${id}`;

  const addDocument = flag => {
    setUploading(flag);
    handleConfirm();
  };

  const deleteImage = file => {
    return firebase
      .storage()
      .ref(file)
      .delete()
      .catch(error => console.log(error));
  };

  const handleUploadOriginal = docId => {
    const storageRef = firebase
      .storage()
      .ref(`restaurant_gallery/${pathMenuGallery}/original/${docId}`);
    const uploadTask = storageRef.put(originalFile);
    return uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload original', progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        firestore
          .collection(
            `${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.MENU_GALLERY}`
          )
          .doc(docId)
          .set({ original_image: url }, { merge: true });
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${docId}_400x400`
        );
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${docId}_40x40`
        );
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${docId}_80x80`
        );
      }
    );
  };

  const uploadImage = async file => {
    const docId = firebaseService.randomId(COLLECTIONS.MENU_GALLERY);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        setUploading(true);
        const extension = file.type.split('/').pop();
        const gallery = new MenuGallery({ docId, extension });
        gallery.title = docTitle;
        gallery.id = id;
        gallery.restaurant_id = id;
        gallery.type = 'image';
        await firebase
          .firestore()
          .collection(
            `${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.MENU_GALLERY}`
          )
          .doc(docId)
          .set({ ...gallery }, { merge: true });
        const storageRef = firebase
          .storage()
          .ref(`restaurant_gallery/${pathMenuGallery}/${docId}`);
        const uploadTask = storageRef.put(file);
        handleUploadOriginal(docId);
        return uploadTask.on(
          'state_changed',
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
          },
          error => {
            console.log(error);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            const image = {
              original: url
            };
            await firebase
              .firestore()
              .collection(
                `${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.MENU_GALLERY}`
              )
              .doc(docId)
              .set({ image }, { merge: true });
            firebase
              .firestore()
              .collection(
                `${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.MENU_GALLERY}`
              )
              .doc(docId)
              .onSnapshot(doc => {
                const data = doc.data();
                if (data?.image.x40 && data?.image.x400 && data?.image.x80) {
                  resolve(true);
                }
              });
          }
        );
      } catch (error) {
        reject(false);
      }
    });
  };

  const requiredForm = async () => {
    if (docTitle && uploadFile) {
      const result = await uploadImage(uploadFile);
      if (result) {
        addDocument();
      }
    }
    if (!docTitle) {
      setRequired(true);
    }
    if (!uploadFile) {
      setRequiredImg(true);
    }
  };

  const handleUploadFile = async event => {
    const file = event.target.files[0];
    const img = URL.createObjectURL(file);
    setOpenDialogCrop(true);
    setUpImg(img);
    setOriginalFile(file);
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const handleCropImage = file => {
    const img = URL.createObjectURL(file);
    setUploadImg(img);
    setUploadFile(file);
  };

  const handleUploadImage = () => {
    return document.getElementById('upload-image').click();
  };

  return (
    <Grid>
      <Card className={webView ? classes.content : classes.contentSmall}>
        <CardHeader
          className={classes.header}
          title="Agregar nueva imagen en la galeria de menú"
        />
        <Divider />
        <CardContent>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={12} sm={9}>
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_title')}
                name="title"
                onChange={e => setDocTitle(e.target.value)}
                value={docTitle}
                variant="outlined"
                disabled={uploading}
              />
              {required && !docTitle && (
                <Typography className={classes.required}>
                  {translation('document_uploader.title.required')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={9} className={classes.containerImage}>
              {!uploadImg && (
                <Box
                  className={classes.cardDocument}
                  style={{
                    backgroundImage: `url("${BORDER_DECORATION}")`,
                    cursor: 'pointer'
                  }}
                  onClick={handleUploadImage}
                >
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="upload-image"
                    hidden
                    onChange={handleUploadFile}
                  />
                  <Box mb={3} textAlign="center">
                    <Box className={classes.box}>
                      <IoMdAddCircle className={classes.styleIcon} />
                    </Box>
                    <Typography>
                      {translation('central_payments.documents.add_field')}
                    </Typography>
                    <Typography>
                      {translation('central_payments.documents.new_document')}
                    </Typography>
                  </Box>
                </Box>
              )}
              {uploadImg && (
                <Box
                  onClick={handleUploadImage}
                  style={{
                    backgroundImage: `url(${uploadImg})`,
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '183px',
                    width: '250px',
                    cursor: 'pointer'
                  }}
                >
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="upload-image"
                    hidden
                    onChange={handleUploadFile}
                  />
                </Box>
              )}
              {requiredImg && !uploadImg && (
                <Typography className={classes.required}>
                  {translation('document_uploader.image.required')}
                </Typography>
              )}
            </Grid>
          </Grid>
          {uploading && (
            <Box width="100%" my={3}>
              <LinearProgress />
            </Box>
          )}
          {!uploading && (
            <Box mt={3} width="100%">
              <Grid item container justify="center" spacing={4}>
                <Box mr={2}>
                  <Button
                    className={classes.dangerButton}
                    variant="contained"
                    onClick={handleClose}
                  >
                    {translation('cancel')}
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={requiredForm}
                  >
                    {translation('modals.uploadFile.btn_save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}
        </CardContent>
        <ModalCrop
          openDialog={openDialogCrop}
          setOpenDialog={() => {
            setOpenDialogCrop(false);
          }}
          upImg={upImg}
          saveBlob={handleCropImage}
        />
      </Card>
    </Grid>
  );
};

export default AddNewImage;
