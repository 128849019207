import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import { PlusCircle } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import additionService from '../../../services/additionsService';
import ModalTemplateCustomHeader from '../../../components/Modal/ModalTemplateCustomHeader';
import ModalCreateAddition from './ModalCreateAddition';
import { roundTwoDecimals } from '../../../services/orderServices';
import { logAddition } from '../../../services/logServices';
import WithLoading from '../../../components/WithLoading';
import { LOG_ACTIONS } from '../../../dto/enum';
import { getObjectChanges } from '../../../utils/object';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  button: {
    color: `${theme.palette.tertiary.main} !important`
  }
}));

function ModalPickAddition({
  show,
  onClose,
  restaurantAdditions,
  productAdditions,
  product,
  restaurantId
}) {
  const classes = useStyles();
  const translation = useTranslation();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant] = useRestaurantAndSubsidiaryforMenu(restId, subId);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteAdditionItem, setDeleteAdditionItem] = useState({});
  const [sharedWithProductsName, setSharedWithProductsName] = useState([]);

  const deleteAddition = async addition => {
    const changes = getObjectChanges(addition, null);
    const data = {
      addition: {
        id: addition.id,
        name: addition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      },
      product: {
        id: product.id,
        name: product?.name
      }
    };
    setLoading(true);
    await additionService.deleteAdditionFromProduct(product.id, addition.id);
    await logAddition(changes, LOG_ACTIONS.DELETE, data);
    const productsName = await additionService.getSharedWithOtherProductsName(
      addition,
      restaurant.id
    );
    setSharedWithProductsName(productsName);
    setDeleteAdditionItem(addition);
    setLoading(false);
  };

  const addAddition = async addition => {
    let newAddition = { ...addition };
    // eslint-disable-next-line camelcase
    const menu_additions_id = addition.id;
    newAddition = {
      ...newAddition,
      menu_additions_id,
      order: productAdditions.length,
      product_id: product.id
    };
    const changes = getObjectChanges(null, newAddition);
    const data = {
      addition: {
        id: newAddition.id,
        name: newAddition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      },
      product: {
        id: product.id,
        name: product?.name
      }
    };
    setLoading(true);
    await additionService.saveAdditionToProduct(product.id, newAddition);
    await logAddition(changes, LOG_ACTIONS.CREATE, data);
    setLoading(false);
  };

  const deleteAdditionFromRestaurant = async addition => {
    setLoading(true);
    const changes = getObjectChanges(addition, null);
    const data = {
      addition: {
        id: addition.id,
        name: addition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      }
    };
    await additionService.saveAddition(addition.id, {
      enable: false
    });
    await logAddition(changes, LOG_ACTIONS.DELETE, data);
    setLoading(false);
    setDeleteAdditionItem({});
  };

  const handleChange = (event, addition, productAddition) => {
    const { checked } = event.target;
    if (checked) {
      addAddition(addition);
    } else {
      deleteAddition(productAddition);
    }
  };

  return (
    <>
      <ModalTemplateCustomHeader
        show={show}
        onClose={onClose}
        className={classes.root}
        size="md"
        label={translation('additions.picker.button')}
      >
        <BoxLoading isLoading={loading}>
          <Button
            className={classes.button}
            onClick={() => setShowModal(true)}
            startIcon={<PlusCircle />}
          >
            {translation('additions.selector.create')}
          </Button>
          <Box mt={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>
                      <Typography variant="h5">
                        <strong>
                          {translation('additions.selector.type')}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">
                        <strong>
                          {translation('additions.selector.obligatory')}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">
                        <strong>
                          {translation('additions.selector.addition')}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">
                        <strong>
                          {translation('additions.selector.max_number')}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">
                        <strong>
                          {translation('additions.selector.actions')}
                        </strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {restaurantAdditions.map(addition => {
                    const productAddition = productAdditions.find(
                      a => a.menu_additions_id === addition.id
                    );
                    return (
                      <TableRow key={addition.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={!!productAddition}
                            className={classes.button}
                            onChange={e => {
                              handleChange(e, addition, productAddition);
                            }}
                          />
                        </TableCell>
                        <TableCell>{addition.name}</TableCell>
                        <TableCell>
                          {addition.mandatory
                            ? translation('additions.selector.yes')
                            : translation('additions.selector.no')}
                        </TableCell>
                        <TableCell>
                          {addition.options.map(
                            (o, i) =>
                              `${o.name} (Bs. ${roundTwoDecimals(o.price)})${
                                i === addition.options.length - 1 ? '' : ', '
                              }`
                          )}
                        </TableCell>
                        <TableCell>{addition.max_selection}</TableCell>
                        <TableCell>
                          <Button onClick={() => deleteAddition(addition)}>
                            {translation(
                              'additions.selector.delete_from_restaurant'
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </BoxLoading>
      </ModalTemplateCustomHeader>
      <ModalCreateAddition
        show={showModal}
        onClose={() => setShowModal(false)}
        restaurantId={restaurantId}
      />
      <ModalTemplateCustomHeader
        show={!!deleteAdditionItem.id}
        onClose={() => setDeleteAdditionItem({})}
        size="sm"
        label={translation('additions.delete.question_restaurant')}
      >
        <Grid container>
          <Grid item xs={4} md={4} />
          <Grid item xs={4} md={4}>
            {sharedWithProductsName.length > 0 && (
              <Box m={2}>
                <Typography>
                  <strong>
                    {translation('additions.delete.subtitle_products')}
                  </strong>
                </Typography>
                <Box ml={2}>
                  {sharedWithProductsName.map(productName => (
                    <Typography>
                      <strong>- </strong>
                      {productName}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={4} md={4} />
        </Grid>
        <Box display="flex" justifyContent="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteAdditionFromRestaurant(deleteAdditionItem)}
          >
            {translation('additions.delete.button')}
          </Button>
        </Box>
      </ModalTemplateCustomHeader>
    </>
  );
}

export default ModalPickAddition;
