import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  Box,
  Typography,
  Button,
  CardContent,
  Avatar,
  Grid,
  Divider
} from '@material-ui/core';
import { Circle } from 'react-feather';
import { SUBJECT_OPTIONS } from '../../constants';
import { useChatLayoutStyles } from '../styles';
import { lastMessageViewrs } from '../../../../services/chatService';
import { setChats } from '../../../../actions/chatActions';
import { useSubsidiaryActiveChats } from '../../../../hooks/Chat/useChat';

const Conversations = ({ handleClose }) => {
  const classes = useChatLayoutStyles();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const [limitDay, setLimitDay] = useState(1);
  const chats = useSubsidiaryActiveChats(limitDay);
  const [renderChats, setRenderChats] = useState(chats);
  const user = useSelector(state => state.account.user);

  useEffect(() => {
    const sameValue =
      renderChats.length === chats.length &&
      renderChats.every((value, index) => value === chats[index]);
    if (chats && !sameValue) setRenderChats(chats);
    // eslint-disable-next-line
  }, [chats, limitDay]);

  const getLapseTime = c => {
    let lapseTime = '';
    if (c.last_message_created) {
      const diferenceMinutes = moment().diff(
        moment(c.last_message_created.toDate()),
        'minutes'
      );
      if (diferenceMinutes < 60) {
        lapseTime = `${diferenceMinutes} min`;
      } else if (diferenceMinutes >= 60 && diferenceMinutes < 24 * 60) {
        const diferenceHours = moment().diff(
          moment(c.last_message_created.toDate()),
          'hours'
        );
        lapseTime = `${diferenceHours} h`;
      } else if (
        diferenceMinutes >= 24 * 60 &&
        diferenceMinutes < 24 * 60 * 7
      ) {
        const diferenceDays = moment().diff(
          moment(c.last_message_created.toDate()),
          'days'
        );
        lapseTime = `${diferenceDays} d`;
      } else {
        const diferenceWeeks = moment().diff(
          moment(c.last_message_created.toDate()),
          'weeks'
        );
        lapseTime = `${diferenceWeeks} sem`;
      }
    }
    return lapseTime;
  };

  const getTitle = ({ sub, label, orderCode, otherTextLabel }) => {
    switch (sub) {
      case 'ORDER':
        return (
          <Typography>{`${translation(label)} - ${orderCode}`}</Typography>
        );
      case 'OTHER':
        return (
          <Typography>{`${translation(label)} - ${otherTextLabel}`}</Typography>
        );
      default:
        return <Typography>{`${translation(label)}`}</Typography>;
    }
  };

  const openChat = async chat => {
    if (!chat.last_message_viewers.includes(user.id)) {
      const lasMessageViewrs = [...chat.last_message_viewers];
      lasMessageViewrs.push(user.id);
      await lastMessageViewrs(chat.id, {
        last_message_viewers: [...lasMessageViewrs]
      });
    }
    dispatch(setChats([chat]));
    handleClose();
  };

  const chatCards = () => {
    if (!renderChats.length === 0) return <></>;
    return renderChats.map(c => {
      const [option] = SUBJECT_OPTIONS.filter(so => so.value === c.subject);
      const restaurantPhoto = c.admin_photo || '';
      let isRead = true;
      if (!c.last_message_viewers.includes(user.id)) isRead = false;
      const lapseTime = getLapseTime(c);
      return (
        <Box
          key={c.id}
          className={classes.chatContainer}
          onClick={() => openChat(c)}
        >
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={9}>
              <Box display="flex" alignItems="center">
                <Avatar
                  className={classes.avatar}
                  alt="avatar"
                  src={restaurantPhoto}
                />
                <div>
                  <Typography className={classes.fontWeightBold}>
                    {getTitle({
                      sub: option.value,
                      label: option.label,
                      orderCode: c.order_summary.order_code,
                      otherTextLabel: c.other_text
                    })}
                  </Typography>
                  <Typography
                    className={isRead ? classes.textRead : classes.textUnread}
                  >
                    {_.truncate(c.last_message_send, {
                      length: 40,
                      separator: ' '
                    })}
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  style={{
                    paddingRight: 7,
                    paddingTop: 10,
                    fontWeight: 300,
                    fontSize: 14
                  }}
                >
                  {lapseTime}
                </Typography>
                {!isRead && (
                  <div style={{ paddingTop: 12 }}>
                    <Circle fill="#56B19E" color="#56B19E" size={12} />
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Box>
      );
    });
  };

  return (
    <CardContent>
      {chats && chatCards(chats)}
      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          className={classes.buttonGreen}
          onClick={() => setLimitDay(limitDay + 7)}
        >
          {translation('chat.chat_layout.see_more')}
        </Button>
      </Box>
    </CardContent>
  );
};

export default Conversations;
