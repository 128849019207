import React, { useState } from 'react';
import {
  Container,
  Card,
  Typography,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { COLORS } from '../../../theme/colors';
import { useSubsidiaryId } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';

const useStyles = makeStyles(theme => ({
  TableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid',
    textAlign: 'left'
  },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));

const PriceTable = ({ subsidiary }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const dataSubsidiary = useSubsidiaryId(subsidiary.id);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Card>
        <TableContainer>
          <Table aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell className={classes.TableCell}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translation('free_delivery.free_delivery_form.distance')}
                  </Typography>
                </TableCell>
                {false && (
                  <TableCell className={classes.TableCell}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {translation('free_delivery.free_delivery_form.price')}
                    </Typography>
                  </TableCell>
                )}
                {false && (
                  <TableCell className={classes.TableCell}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {translation(
                        'free_delivery.free_delivery_form.subsidySubsidiary'
                      )}
                    </Typography>
                  </TableCell>
                )}
                <TableCell className={classes.TableCell}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translation(
                      'free_delivery.free_delivery_form.subsidy_free_delivery'
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSubsidiary?.price_area?.price.map((price, index) => {
                return (
                  <TableRow key={index + 1}>
                    <TableCell className={classes.TableCell}>
                      <Typography>{price.distance}</Typography>
                    </TableCell>
                    {false && (
                      <TableCell className={classes.TableCell}>
                        <Typography>{price.price}</Typography>
                      </TableCell>
                    )}
                    {false && (
                      <TableCell className={classes.TableCell}>
                        <Typography>{price.subsidiarySubsidy}</Typography>
                      </TableCell>
                    )}
                    <TableCell className={classes.TableCell}>
                      <Typography>
                        {price.price - price.subsidiarySubsidy}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[4]}
                  colSpan={3}
                  count={0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default PriceTable;
