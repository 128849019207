import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const useStyles = makeStyles(theme => ({
  center: {
    justifyContent: 'center'
  }
}));

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ width: '90%' }} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function OneButtonDialog({
  open,
  handleAction = () => {},
  handleClose = () => {},
  title = '',
  disableButton = false,
  children,
  size = 'xs',
  buttonLabel,
  subtitle,
  centerButton = false
}) {
  const translation = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth={size}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h4" component="span">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="h6" component="p">
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions
        className={clsx({
          [classes.center]: centerButton
        })}
      >
        <Button
          autoFocus
          onClick={handleAction}
          disabled={disableButton}
          color="primary"
        >
          {buttonLabel || translation('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
