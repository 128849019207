import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { useSelector } from 'react-redux';
import { useCurrentSubsidiary } from 'src/hooks/useRestaurant';
import admin from '../config/firebaseConfig';
import FirebaseService from './firebaseService';
import { COLLECTIONS } from '../constants';
import Subsidiary from '../dto/subsidiary';
import MonthlyMetric from '../dto/monthlyMetric';

export const editSubsidiary = async (id, fields) => {
  return admin
    .firestore()
    .collection(COLLECTIONS.SUBSIDIARY)
    .doc(id)
    .set({ ...fields }, { merge: true });
};

export const createSubsidiary = async (fields, restId) => {
  const subId = FirebaseService.randomId();
  let newSubsidiary = new Subsidiary();
  newSubsidiary.id = subId;
  newSubsidiary.restaurant_id = restId;
  newSubsidiary = {
    ...newSubsidiary,
    ...fields,
    enable: true
  };
  admin
    .firestore()
    .collection(COLLECTIONS.SUBSIDIARY)
    .doc(subId)
    .set({ ...newSubsidiary });
};

export const updateSchedule = async ({ subId, schedule }) => {
  const WEEK_DAYS = [
    { value: 'MONDAY', day: 0 },
    { value: 'TUESDAY', day: 1 },
    { value: 'WEDNESDAY', day: 2 },
    { value: 'THURSDAY', day: 3 },
    { value: 'FRIDAY', day: 4 },
    { value: 'SATURDAY', day: 5 },
    { value: 'SUNDAY', day: 6 }
  ];

  const orderSchedule = schedule
    .map(d => ({
      ...d,
      position: WEEK_DAYS.filter(p => p.value === d.journey)[0]
    }))
    .sort((a, b) => (a.position.day > b.position.day ? 1 : -1));
  orderSchedule.forEach(s => {
    // eslint-disable-next-line no-param-reassign
    delete s.position;
  });
  return editSubsidiary(subId, { schedule: orderSchedule });
};

export const useSubsidiary = () => {
  const [subsidiarySnap, setSubsidiarySnap] = useState({});
  const subsidiary = useCurrentSubsidiary();
  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .doc(subsidiary.id)
      .onSnapshot(snapshot => setSubsidiarySnap(snapshot.data()));
  }, [subsidiary]);

  return subsidiarySnap;
};

export function useGetMonthlyMetricSubsidiary() {
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  const [monthlyMetric, setMonthlyMetric] = useState(null);
  useEffect(() => {
    firebase
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .doc(subsidiary.id)
      .collection(COLLECTIONS.MONTHLY_METRIC)
      .get()
      .then(collection => {
        const metrics = [];
        collection.docs.map(doc =>
          metrics.push(new MonthlyMetric({ ...doc.data() }))
        );
        setMonthlyMetric(metrics);
      });
  }, [subsidiary]);
  return monthlyMetric;
}

export default editSubsidiary;
