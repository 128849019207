/**
 *
 * @type {EnvironmentConfig}
 */
const ProductionEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyBlhBl_GNqU14UTZmfAllGLjLAXOQhJ1y8',
    authDomain: 'xpress-production.firebaseapp.com',
    databaseURL: 'https://xpress-production.firebaseio.com',
    projectId: 'xpress-production',
    storageBucket: 'xpress-production.appspot.com',
    messagingSenderId: '455344764199',
    appId: '1:455344764199:web:ced4eb56b08738a739e502',
    measurementId: 'G-W8S4JCTMDP'
  },
  REACT_APP_GOOGLE_MAP_API: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
  REACT_APP_CLOUD_FUNCTIONS_API:
    'https://us-central1-xpress-production.cloudfunctions.net',
  REACT_APP_SUPPORT_MAIL: 'info@xpressbolivia.com',
  REACT_APP_FCM_KEY:
    'BCn0r41uuAc3mQzeUklZE9kTT8mSm9GMXOWyatoWCAUmo971rSgn6GqR1hmTQqLoBoswlgIFGLoIcsViYhKvXdM',
  REACT_APP_COMPANY_NAME: 'Xpress Technologies',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyBlhBl_GNqU14UTZmfAllGLjLAXOQhJ1y8',
  REACT_APP_DYNAMIC_LINK_DOMAIN_URI: 'https://xpresssocial.page.link',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_MOTOS_URL: 'https://entregas.com/login',
  REACT_APP_XPRESS_SUPPORT: '+59162093356',
  REACT_APP_GA_MEASUREMENT_ID: '',
  REACT_APP_XPRESS_BOLIVIA_COOKIES:
    'https://xpressbolivia.com/cookies-declaration',
  BACKEND_URL: 'https://xpress-api-nnzydfbpnq-uc.a.run.app/'
};

export default ProductionEnv;
