import { createMomentOnBolivianTime } from '../utils/date';
import env from '../env';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  EXPRESS: 'EXPRESS'
};

export const COLLECTIONS = {
  ORDER: 'order',
  ORDER_INVITE: 'order_invite',
  ORDER_PRODUCT: 'order_product',
  USER: 'users',
  SUBSIDIARY: 'subsidiary',
  PRODUCT: 'product',
  CATEGORIES: 'categories',
  MERCHANT_MAIN_CATEGORIES: 'merchant_main_categories',
  MENU_ADDITIONS: 'menu_additions',
  ADDITION: 'additions',
  RESTAURANT: 'restaurants',
  CITY: 'city',
  DOCUMENT: 'document',
  INVOICE: 'invoice',
  NOTIFICATIONS: 'notifications',
  PROMOS: 'promo',
  PROMOS_BANNER: 'promo_banner',
  BANNER_ITEM: 'banner_item',
  AREAS: 'price_area',
  CAMPAINGS_IMAGE: 'campaings_image',
  HISTORY: 'history',
  CHAT: 'chat',
  MESSAGE: 'message',
  SUMMARY: 'summary',
  MENU_SECTION_SCHEDULE: 'menu_section_schedule',
  RATE_QUALIFICATON_OPTIONS: 'rate_qualification_options',
  PAYMENT_HISTORY: 'payment_history',
  SETTINGS: 'settings',
  REWARDS: 'rewards',
  LOGS: 'logs',
  MENU_LOGS: 'menu_logs',
  CHARGES_HISTORY: 'charges_history',
  BANK_DATA: 'bank_data',
  MENU_GALLERY: 'menu_gallery',
  ALERTS: 'alerts',
  STORIES: 'story',
  MONTHLY_METRIC: 'monthly_metric',
  DELIVERY_DISABLED_PER_CITY: 'delivery_disabled_per_city',
  ZONES: 'zones'
};

export const DEFAULT_HOUR = '2000 01 00:00';
export const DEFAULT_DATE_FORMAT = 'YYYY MM HH:mm';
export const DEFAULT_START_HOUR_DATE = createMomentOnBolivianTime(
  DEFAULT_HOUR,
  DEFAULT_DATE_FORMAT
);
export const DEFAULT_END_HOUR_DATE = createMomentOnBolivianTime(
  DEFAULT_HOUR,
  DEFAULT_DATE_FORMAT
).endOf('day');

export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const WEEK_DAYS_SPANISH = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo'
];

export const REJECT_PRODUCT_REASON = [
  'Producto agotado',
  'Artículo con alta demanda',
  'Ingredientes incompletos',
  'No podemos acomodarnos a su requerimiento especial o de alergias',
  'Otro'
];
export const REJECT_ORDER_REASON = [
  'Alta demanda de órdenes',
  'Falta de personal de atención',
  'Sin Servicios básicos',
  'Establecimiento cerrado',
  'Problemas con computadora/Internet/App',
  'No podemos acomodarnos a su requerimiento especial o de alergias',
  'Comercio no cuenta con unos de los productos',
  'Tipo de servicio elegido no es el correcto',
  'Su repartidor ha tenido un percance',
  'Dirección elegida no es la correcta',
  'Otro'
];
export const ADDITIONAL_MINUTES = [10, 20, 30];
export const READY_TO_PICK_WAITING_MINUTES = 5;
export const EXPIRATION_TICKET_TIME = 3;

export const commerceType = {
  SUBSIDIARY: 'subsidiary',
  RESTAURANT: 'restaurant'
};

export const DOCUMENT_TYPE = {
  SUBSIDIARY: 'subsidiary',
  RESTAURANT: 'restaurant',
  INVOICE: 'invoice'
};

export const registerStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  SUSPENDED: 'SUSPENDED'
};

export const documentsStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const bannerColors = {
  suspColor: '#fefbd8',
  archColor: '#eca1a6'
};

export const IMAGE_SIZES = {
  BANNER_MIN_WIDTH: '800',
  BANNER_MIN_HEIGHT: '400',
  LOGO_MIN_WIDTH: '400',
  LOGO_MIN_HEIGHT: '400',
  PRODUCT_MIN_WIDTH: '400',
  PRODUCT_MIN_HEIGHT: '400'
};

export const COLORS = {
  red: '#C23F25',
  green: '#56B19E',
  disabledGray: '#F2F2F2',
  ligthRed: '#F75B3B',
  ligthYellow: '#FFEA85',
  ligthGreen: '#EAF8F4',
  white: '#FFFFFF'
};

export const INACTIVITY_TIME = 60000;

export const INACTIVITY_TIME_TEN_MIN = 1000 * 60 * 10;

export const RESTAURANT_DEFAULT_SETTINGS = {
  askBeforeDelivery: true,
  sharedMenuEditBySupervisor: true
};

export const dynamicLinkConfiguration = {
  firebaseApi: `${env.REACT_APP_FIREBASE_API_KEY}`,
  firebaseDynamicLinkUrl:
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=',
  template: {
    dynamicLinkInfo: {
      domainUriPrefix: `${env.REACT_APP_DYNAMIC_LINK_DOMAIN_URI}`,
      link: 'https://xpressbolivia.com',
      androidInfo: {
        androidPackageName: 'com.believesol.xpress'
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      iosInfo: {
        iosBundleId: 'com.believesol.xpress' // ,
        // iosAppStoreId: '1507805959'
      },
      socialMetaTagInfo: {
        socialTitle: '',
        socialDescription: '',
        socialImageLink:
          'https://firebasestorage.googleapis.com/v0/b/xpress-production.appspot.com/o/app%2Flogo.png?alt=media&token=300ecd9f-1ee2-4049-a450-09c7dfea3f20'
      }
    },
    suffix: {
      option: 'SHORT'
    }
  }
};

export const expressDriverLinkConfiguration = {
  firebaseApi: `${env.REACT_APP_FIREBASE_API_KEY}`,
  firebaseDynamicLinkUrl:
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=',
  template: {
    dynamicLinkInfo: {
      domainUriPrefix: `${env.REACT_APP_DYNAMIC_LINK_DOMAIN_URI}`,
      link: 'https://xpressdriverbolivia.com',
      androidInfo: {
        androidPackageName: 'com.believesol.xpressdriver'
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      iosInfo: {
        iosBundleId: 'com.believesol.xpressdriver'
        // TODO: uncomment when in production
        // iosAppStoreId: '1507805959'
      },
      socialMetaTagInfo: {
        socialTitle: '',
        socialDescription: '',
        socialImageLink:
          'https://firebasestorage.googleapis.com/v0/b/xpress-production.appspot.com/o/app%2Flogo.png?alt=media&token=300ecd9f-1ee2-4049-a450-09c7dfea3f20'
      }
    },
    suffix: {
      option: 'SHORT'
    }
  }
};

export const START_YEAR = 2020;

export const DATE_FILTERS = [
  { value: '-', label: 'admin_dealers.tabs.filters.select' },
  { value: '0', label: 'admin_dealers.tabs.filters.today' },
  { value: '1', label: 'admin_dealers.tabs.filters.yesterday' },
  { value: '7', label: 'admin_dealers.tabs.filters.past7' }
];

export const MONTHS = [
  { value: '-', label: 'months.select' },
  { value: '01', label: 'months.january' },
  { value: '02', label: 'months.february' },
  { value: '03', label: 'months.march' },
  { value: '04', label: 'months.april' },
  { value: '05', label: 'months.may' },
  { value: '06', label: 'months.june' },
  { value: '07', label: 'months.july' },
  { value: '08', label: 'months.august' },
  { value: '09', label: 'months.september' },
  { value: '10', label: 'months.october' },
  { value: '11', label: 'months.november' },
  { value: '12', label: 'months.december' }
];

export const PAID_FILTER = [
  { value: '-', label: 'admin_dealers.tabs.filters.all' },
  { value: '1', label: 'admin_dealers.tabs.filters.paidOut' },
  { value: '0', label: 'admin_dealers.tabs.filters.notPayed' }
];

export const FILTER_DEFAULT = {
  city: '',
  restaurant: '',
  subsidiary: '',
  year: '',
  month: '',
  status: '',
  promo: '',
  statusOrder: '',
  complexDate: '',
  driver: '',
  fromDate: '',
  toDate: '',
  paid: '',
  typePaid: '',
  payment_method: ''
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
};

export const DEFAULT_SCHEDULE_VALUE = [
  {
    open: false,
    journey: 'MONDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'TUESDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'WEDNESDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'THURSDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'FRIDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'SATURDAY',
    schedule: [{}, {}]
  },
  {
    open: false,
    journey: 'SUNDAY',
    schedule: [{}, {}]
  }
];

export const ORDER_TYPE_SUBSIDIARY = [
  {
    type: 'VERY_FAST',
    min: 0,
    max: 10
  },
  {
    type: 'FAST',
    min: 11,
    max: 20
  },
  {
    type: 'NORMAL',
    min: 21,
    max: 30
  },
  {
    type: 'SLOW',
    min: 31,
    max: 40
  },
  {
    type: 'VERY_SLOW',
    min: 41,
    max: 50
  }
];

export const CHARGE_TYPE = {
  COMMISSION: 'Comisión',
  SUBSCRIPTION: 'Subscripción'
};

export const INVOICES = {
  REPORT_NO_GENERATED: 'REPORT_NO_GENERATED'
};

export const ACCOUNT_TYPE = {
  CURRENT_ACCOUNT: 'current_account',
  SAVING_ACCOUNT: 'saving_account'
};

export const BORDER_DECORATION =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%2356B19EFF' stroke-width='1' stroke-dasharray='7%2c 7' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e";

export const MEDIA_TYPE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO'
};

export const STATUS_STORY = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

export const PROVIDER_STORY = { MERCHANT: 'MERCHANT' };
