import React, { useState } from 'react';
import {
  Card,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { userRefundCardStyles } from './styles';
import { updateOrder } from '../../../services/orderServices';

const RefundCard = ({ orderId, orderRefund, orderDescription }) => {
  const translation = useTranslation();
  const [refund, setRefund] = useState(orderRefund || 0);
  const [description, setDescription] = useState(orderDescription || '');
  const classes = userRefundCardStyles();
  const [loading, setLoading] = useState(false);

  const saveRefund = async () => {
    setLoading(true);
    await updateOrder(orderId, {
      refund,
      refund_description: description
    });
    setLoading(false);
  };

  if (loading)
    return (
      <Card className={classes.card}>
        <Box display="flex" justifyContent="center" my={1}>
          <CircularProgress />
        </Box>
      </Card>
    );

  return (
    <Card className={classes.card}>
      <Box>
        <Box className={classes.box}>
          <Typography className={classes.header}>
            {translation('admin_orders.refund_card.header')}
          </Typography>
        </Box>
        <Box className={classes.box}>
          <TextField
            fullWidth
            type="number"
            label={translation('admin_orders.refund_card.refund_label')}
            name="refund"
            value={refund}
            variant="outlined"
            onChange={e => setRefund(e.target.value)}
            inputProps={{
              min: 0,
              step: 1.0
            }}
          />
        </Box>

        <Box className={classes.box}>
          <TextField
            fullWidth
            type="text"
            multiline
            label={translation('admin_orders.refund_card.description_label')}
            name="description"
            value={description}
            variant="outlined"
            onChange={e => setDescription(e.target.value)}
          />
        </Box>
      </Box>

      <Box className={classes.box}>
        <Button
          className={classes.saveButton}
          variant="contained"
          color="secondary"
          onClick={saveRefund}
        >
          {translation(`${orderRefund || orderDescription ? 'edit' : 'save'}`)}
        </Button>
      </Box>
    </Card>
  );
};

export default RefundCard;
