/* eslint-disable*/
import firebase from 'firebase';
import Log from '../dto/log';
import firebaseService from './firebaseService';
import admin from '../config/firebaseConfig';
import { LOG_PLATFORM, TARGET_LOGS } from '../dto/enum';
import { COLLECTIONS } from '../constants';
import Store from '../store';
import Logger from '../modules/logs';

export const getModifierUser = () => {
  const userAccount = Store.getState().account.user;
  const { email, first_name, last_name } = userAccount;
  return {
    id: userAccount.id,
    email,
    first_name,
    last_name
  };
};

const log = async (changes, action, data, target) => {
  try {
    if (
      Object.keys(changes.after || {}).length === 0 &&
      Object.keys(changes.before || {}).length === 0
    ) {
      return Promise.resolve();
    }
    const modifier_user = getModifierUser();
    const logId = firebaseService.randomId();
    const restaurantId = data?.restaurant?.id;
    const pathCollection = `${COLLECTIONS.RESTAURANT}/${restaurantId}/${COLLECTIONS.MENU_LOGS}`;
    const newLog = new Log({
      id: logId,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      target,
      action,
      platform: LOG_PLATFORM.MERCHANT_WEB,
      data,
      modifier_user,
      changes
    });
    return admin
      .firestore()
      .collection(pathCollection)
      .doc(logId)
      .set({ ...newLog });
  } catch (error) {
    console.log('ERROR', error);
    Logger.error({
      error,
      data: { changes, action, data, target }
    });
  }
};

export const logMenuSection = (changes, action, data) => {
  return log(changes, action, data, TARGET_LOGS.MENU_SECTION);
};

export const logProduct = (changes, action, data) => {
  return log(changes, action, data, TARGET_LOGS.PRODUCT);
};

export const logAddition = (changes, action, data) => {
  return log(changes, action, data, TARGET_LOGS.ADDITION);
};
