import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Container,
  makeStyles,
  Box,
  CircularProgress
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { useSelector } from 'react-redux';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import Header from './Header';
import Page from '../../../../components/Page';
import Results from './Results';
import { useRestaurant } from '../../../../hooks/useRestaurant';
import useCanEditablePage from '../../../../hooks/navigation/useCanEditablePage';
import { blockCashierEdit } from '../../../../utils/helpers/userHelper';
import { useMenuProductsNativeAsync } from '../../../../hooks/useMenu';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));

const ProductList = ({ dontOpenEdit }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const match = useRouteMatch();
  const { sectionId } = match.params;
  const { restId, subId } = useParams();
  const isEditable = useCanEditablePage();
  const currentRestaurant = useRestaurant();
  const [restaurant, subsidiary] = useRestaurantAndSubsidiaryforMenu(
    restId,
    subId
  );

  const isTemplate = useIsTemplateMenu(subId);
  const products = useMenuProductsNativeAsync(subId, sectionId, isTemplate);
  const [menuSection, setMenuSection] = useState(null);
  const { user } = useSelector(state => state.account);
  const blockCashier = blockCashierEdit(user, currentRestaurant);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isTemplate) {
      setMenuSection(
        // eslint-disable-next-line camelcase
        restaurant?.menu_section?.filter(item => item.id === sectionId)[0]
      );
    } else {
      setMenuSection(
        // eslint-disable-next-line camelcase
        subsidiary?.menu_section?.find(item => item.id === sectionId)
      );
    }
    // eslint-disable-next-line
  }, [isTemplate, sectionId, restaurant, subsidiary]);

  return (
    <Box className={classes.wrapper}>
      {isLoading && (
        <Box className={classes.loadingWrapper}>
          <CircularProgress />
        </Box>
      )}
      <Page
        className={classes.root}
        title={translation('edit.product_create.create_edit.product')}
      >
        <Container maxWidth={false}>
          {menuSection && (
            <>
              <Header
                section={menuSection}
                sectionId={menuSection.id}
                sectionName={menuSection.name}
                isEditable={isEditable}
                blockCashier={blockCashier}
                sizeProducts={products.length}
              />
              <Results
                dontOpenEdit={dontOpenEdit}
                section={menuSection}
                sectionName={menuSection.name}
                sectionId={menuSection.id}
                isEditable={isEditable}
                blockCashier={blockCashier}
                setLoading={setLoading}
                restaurant={restaurant}
              />
            </>
          )}
        </Container>
      </Page>
    </Box>
  );
};

export default ProductList;
