/* eslint-disable no-nested-ternary,no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CardMedia,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { PROMO_BANNER_TYPE } from '../../../../dto/enum';
import WithLoading from '../../../../components/WithLoading';
import YellowTextField from '../../../../components/Inputs/TextField';
import { useAllActiveRestaurants } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import useCategories from '../../../../hooks/useCategories';
import FilesDropzonePicker from '../../../../components/Dropzone/FilesDropzonePicker';
import { getProductsPerRestaurant } from '../../../../services/productsService';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 250,
    marginBottom: theme.spacing(1),
    backgroundSize: 'contain'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function PromoBannerItemForm({
  className,
  item = {},
  onClose,
  onSuccess,
  ...rest
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const restaurants = useAllActiveRestaurants();
  const categories = useCategories();
  const [products, setProducts] = useState([]);

  const updateProducts = async id => {
    const productsDB = await getProductsPerRestaurant(id);
    setProducts(productsDB);
  };
  const saveItem = async currentItem => {
    onSuccess(currentItem);
  };

  useEffect(() => {
    if (item.product_restaurant_id) {
      updateProducts(item.product_restaurant_id);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          image: item.image || '',
          fullscreen_image: item.fullscreen_image || '',
          item_id: item.item_id || '',
          type: item.type || PROMO_BANNER_TYPE.RESTAURANT,
          file: '',
          fullscreen_file: '',
          product_restaurant_id: item.product_restaurant_id || '',
          newItem: item.newItem || false,
          id: item.id
        }}
        validationSchema={Yup.object().shape({
          image: Yup.string().required('El Banner es requerido'),
          fullscreen_image: Yup.string().when('type', {
            is: PROMO_BANNER_TYPE.INFO,
            then: Yup.string().required(
              'La imagen de pantalla completa es requerida'
            ),
            otherwise: Yup.string()
          }),
          item_id: Yup.string().when('type', {
            is: PROMO_BANNER_TYPE.INFO,
            otherwise: Yup.string()
              .max(255)
              .required('El Item es Requerido')
          }),
          product_restaurant_id: Yup.string().when('type', {
            is: PROMO_BANNER_TYPE.PRODUCT,
            then: Yup.string()
              .max(255)
              .required('Restaurant requerido')
          }),
          type: Yup.string()
            .max(255)
            .required('El tipo es requerido')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await saveItem(values);

            setStatus({ success: true });
            setSubmitting(false);
            setLoading(false);
            if (onClose) {
              onClose();
            }
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => {
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    value={values.type}
                    onChange={(e, v) => {
                      setFieldValue('item_id', '');
                      setFieldValue('fullscreen_image', '');
                      setFieldValue('fullscreen_file', '');
                      setFieldValue('type', v);
                    }}
                  >
                    <FormControlLabel
                      value={PROMO_BANNER_TYPE.RESTAURANT}
                      control={<Radio />}
                      label="RESTAURANT"
                    />
                    <FormControlLabel
                      value={PROMO_BANNER_TYPE.CATEGORY}
                      control={<Radio />}
                      label="CATEGORIA"
                    />
                    <FormControlLabel
                      value={PROMO_BANNER_TYPE.PRODUCT}
                      control={<Radio />}
                      label="PRODUCTO"
                    />
                    <FormControlLabel
                      value={PROMO_BANNER_TYPE.INFO}
                      control={<Radio />}
                      label="INFO"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CardMedia
                    className={classes.media}
                    image={values.image || '/static/home/image-placeholder.png'}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FilesDropzonePicker
                    labels={{
                      selectFiles: translation(
                        'edit.product_create.basic_information.form.choose_image'
                      ),
                      instructions: translation('dropzone.image.instructions'),
                      removeAll: translation('remove'),
                      uploadAll: translation('save')
                    }}
                    setImage={file => setFieldValue('file', file)}
                    previewImage={src => {
                      setFieldValue('image', src);
                    }}
                    maxDimensions={{
                      height: 450,
                      width: 1280
                    }}
                    maxSize={350}
                  />
                  {errors.image && (
                    <Box mt={3} mb={3}>
                      <FormHelperText error>{errors.image}</FormHelperText>
                    </Box>
                  )}
                </Grid>
                {values.type === PROMO_BANNER_TYPE.INFO && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Typography variant="h5">
                        Imagen para pantalla completa
                      </Typography>
                      <CardMedia
                        className={classes.media}
                        image={
                          values.fullscreen_image ||
                          '/static/home/image-placeholder.png'
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FilesDropzonePicker
                        labels={{
                          selectFiles: translation(
                            'edit.product_create.basic_information.form.choose_image'
                          ),
                          instructions: translation(
                            'dropzone.image.instructions'
                          ),
                          removeAll: translation('remove'),
                          uploadAll: translation('save')
                        }}
                        setImage={file => {
                          setFieldValue('fullscreen_file', file);
                        }}
                        previewImage={src => {
                          setFieldValue('fullscreen_image', src);
                        }}
                        maxDimensions={{
                          height: 1624,
                          width: 750
                        }}
                        maxSize={350}
                      />
                      {errors.fullscreen_image && (
                        <Box mt={3} mb={3}>
                          <FormHelperText error>
                            {errors.fullscreen_image}
                          </FormHelperText>
                        </Box>
                      )}
                    </Grid>
                  </>
                )}

                {values.type === PROMO_BANNER_TYPE.RESTAURANT && (
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="tags-outlined"
                      options={restaurants || []}
                      getOptionLabel={s => {
                        if (!s) return;
                        return s.name;
                      }}
                      filterSelectedOptions
                      value={
                        restaurants.find(r => r.id === values.item_id) || null
                      }
                      onChange={(e, res) => {
                        if (!res || !res.id) {
                          setFieldValue('item_id', '');
                          return;
                        }
                        setFieldValue('item_id', res.id);
                      }}
                      renderInput={params => (
                        <YellowTextField
                          {...params}
                          variant="outlined"
                          label={translation('admin_promos.restaurant.name')}
                          error={Boolean(touched.item_id && errors.item_id)}
                          helperText={touched.item_id && errors.item_id}
                          placeholder="Restaurant"
                        />
                      )}
                    />
                  </Grid>
                )}
                {values.type === PROMO_BANNER_TYPE.CATEGORY && (
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      id="tags-outlined"
                      options={categories || []}
                      getOptionLabel={c => {
                        if (!c) return;
                        return c.name;
                      }}
                      filterSelectedOptions
                      value={
                        categories.find(c => c.id === values.item_id) || null
                      }
                      onChange={(e, res) => {
                        if (!res || !res.id) {
                          setFieldValue('item_id', '');
                          return;
                        }
                        setFieldValue('item_id', res.id);
                      }}
                      renderInput={params => (
                        <YellowTextField
                          {...params}
                          variant="outlined"
                          label="Categoria"
                          error={Boolean(touched.item_id && errors.item_id)}
                          helperText={touched.item_id && errors.item_id}
                          placeholder="Categoria"
                        />
                      )}
                    />
                  </Grid>
                )}
                {values.type === PROMO_BANNER_TYPE.PRODUCT && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        id="tags-outlined"
                        options={restaurants || []}
                        getOptionLabel={s => {
                          if (!s) return;
                          return s.name;
                        }}
                        filterSelectedOptions
                        value={
                          restaurants.find(
                            r => r.id === values.product_restaurant_id
                          ) || null
                        }
                        onChange={(e, res) => {
                          if (!res || !res.id) {
                            setFieldValue('product_restaurant_id', '');
                            return;
                          }
                          if (res.id) {
                            updateProducts(res.id);
                          }
                          setFieldValue('product_restaurant_id', res.id);
                        }}
                        renderInput={params => (
                          <YellowTextField
                            {...params}
                            variant="outlined"
                            error={Boolean(
                              touched.product_restaurant_id &&
                                errors.product_restaurant_id
                            )}
                            helperText={
                              touched.product_restaurant_id &&
                              errors.product_restaurant_id
                            }
                            placeholder="Restaurant"
                          />
                        )}
                      />
                    </Grid>
                    {products.length > 0 && (
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          id="tags-outlined"
                          options={products || []}
                          getOptionLabel={s => {
                            if (!s) return;
                            return s.name;
                          }}
                          filterSelectedOptions
                          value={
                            products.find(r => r.id === values.item_id) || null
                          }
                          onChange={(e, res) => {
                            if (!res || !res.id) {
                              setFieldValue('item_id', '');
                              return;
                            }
                            setFieldValue('item_id', res.id);
                          }}
                          renderInput={params => (
                            <YellowTextField
                              {...params}
                              variant="outlined"
                              label="Producto"
                              error={Boolean(touched.item_id && errors.item_id)}
                              helperText={touched.item_id && errors.item_id}
                              placeholder="Producto"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              <Box m={3} display="flex" justifyContent="center">
                <Button
                  // variant="contained"

                  type="submit"
                  disabled={isSubmitting}
                >
                  Asignar
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

PromoBannerItemForm.propTypes = {
  className: PropTypes.string
};

export default PromoBannerItemForm;
