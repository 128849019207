import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import ExpressTable from '../../../../components/Table/ExpressTable';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import promoBannerService from '../../../../services/promoBannerService';

const ResultsBanners = ({ promoBanners }) => {
  // const classes = useStyles();
  const translation = useTranslation();
  const [deletedPromoBanner, setDeletedPromoBanner] = useState({});

  const tableColumns = [
    {
      id: 'name',
      label: 'Nombre',
      minWidth: 100,
      align: 'center',
      search: true
    },
    {
      id: 'available',
      label: 'Habilitado',
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'full_view',
      label: 'Pantalla completa',
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'actions',
      label: translation('admin_promos.table.actions'),
      minWidth: 50,
      align: 'center',
      search: true
    }
  ];
  let tableContent = [];

  const linkToSub = item => {
    return (
      <Box>
        <Link
          style={{ color: 'black', textDecorationLine: 'underline' }}
          component={RouterLink}
          to={`/admin/promos_banner/${item.id}`}
        >
          {translation('admin_promos.table.link').toUpperCase()}
        </Link>

        <Button
          style={{ color: 'black', textDecorationLine: 'underline' }}
          onClick={() => setDeletedPromoBanner(item)}
        >
          {translation('admin_promos.table.delete_link').toUpperCase()}
        </Button>
      </Box>
    );
  };

  if (promoBanners) {
    tableContent = promoBanners.map(item => ({
      id: item.id,
      name: item.name,
      available: item.available ? 'Si' : 'No',
      full_view: item.available ? 'Si' : 'No',
      actions: linkToSub(item)
    }));
  }

  const deletePromoB = async () => {
    await promoBannerService.savePromoBannerDB(deletedPromoBanner.id, {
      enable: false,
      available: false,
      updated_at: moment().toDate()
    });
    setDeletedPromoBanner({});
  };

  return (
    <>
      <Grid>
        <ExpressTable
          columns={[...tableColumns]}
          rows={[...tableContent]}
          search
        />
      </Grid>
      <AlertDialog
        open={!!deletedPromoBanner.id}
        question={translation('admin_promos.table.delete')}
        maxWidth="sm"
        handleCancel={() => {
          setDeletedPromoBanner({});
        }}
        handleAccept={() => deletePromoB()}
      >
        <Box m={1}>
          <Typography variant="h4">{`${deletedPromoBanner.name}`}</Typography>
        </Box>
      </AlertDialog>
    </>
  );
};

export default ResultsBanners;
