import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { AiOutlineFileText } from 'react-icons/ai';
import firebase from 'firebase/app';
import Dropzone from 'react-dropzone';
import { useFirestore } from 'react-redux-firebase';
import { IoMdAddCircle } from 'react-icons/io';
import { COLORS } from '../../theme/colors';
import { BORDER_DECORATION, COLLECTIONS } from '../../constants';
import XpressModal from '../Modal';
import OptionsDocument from './OptionsDocument';

const useStyles = makeStyles(() => ({
  containerDoc: {
    border: `1px solid ${COLORS.yellowLink}`,
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  containerNull: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  containerCard: {},
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginTop: '5%',
    marginLeft: '5%'
  },
  cardDescription: {
    margin: '13px 0px',
    fontSize: '12px',
    marginLeft: '5%'
  },
  cardDocument: {
    height: '183px',
    borderRadius: '6px',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
    margin: '5%',
    backgroundPosition: 'center',
    maxWidth: '100%'
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  styleText: {
    fontSize: '10px',
    textDecoration: 'underline'
  },
  hoverCard: {
    backgroundColor: 'black'
  },
  containerIcons: {
    backgroundColor: 'rgb(0, 23, 20, 0.7)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '6px'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '6vh',
    height: '6vh',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: 'white'
  },
  smallBox: {
    height: 34
  },
  opacity: {
    opacity: '0.5'
  },
  cursor: {
    cursor: 'pointer'
  },
  box: {
    height: 60,
    marginBottom: '8px'
  },
  dropzone: {
    borderRadius: '6px',
    height: '183px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const PreviewDocument = ({
  type = '',
  title = '',
  photoUrl = '',
  editDocument,
  index = false,
  deletedDocument,
  addNewDocument,
  flag = false,
  id = '',
  docExtension = '',
  idProgress = '',
  gallery = false,
  restId = ''
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [hoverIconOptions, setHoverIconOptions] = useState(false);
  const firestore = useFirestore();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [typeDoc, setTypeDoc] = useState(type);
  const [typeDocument, setTypeDocument] = useState(false);
  const [fileName, setFileName] = useState('');
  const pathMenuGallery = `${COLLECTIONS.MENU_GALLERY}/${restId}`;

  const showImageDocument = () => {
    const docImage = document.createElement('a');
    docImage.href = photoUrl;
    docImage.setAttribute('target', '_blank');
    document.body.appendChild(docImage);
    docImage.click();
    document.body.removeChild(docImage);
  };

  const handleDownloadDocument = () => {
    if (type !== 'image') {
      const fileNamedoc = `${title}.${docExtension}`;
      const link = document.createElement('a');
      link.download = fileNamedoc;
      link.href = photoUrl;
      link.click();
    } else if (photoUrl) {
      return fetch(photoUrl, {
        method: 'GET',
        headers: {}
      })
        .then(response => {
          response.arrayBuffer().then(buffer => {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title}.${docExtension}`);
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleEdit = () => {
    return document.getElementById(id).click();
  };

  const editDocumentImage = file => {
    if (file && file.type.split('/').pop() !== 'pdf') {
      const [currentType] = file.type.split('/');
      editDocument({
        type: currentType,
        document: file,
        index,
        extension: file.name.split('.').pop(),
        id
      });
      if (currentType !== 'image') {
        setFileName(file.name);
        setTypeDocument(true);
      }
      setHoverIconOptions(false);
    }
  };

  const handleDrop = files => {
    const file = files[0];
    if (file && file.type.split('/').pop() !== 'pdf') {
      const [currentType] = file.type.split('/');
      setTypeDoc(currentType);
      editDocument({
        type: currentType,
        document: file,
        index,
        extension: file.name.split('.').pop(),
        id
      });
      if (currentType !== 'image') {
        setFileName(file.name);
        setTypeDocument(true);
      }
      setHoverIconOptions(false);
    }
  };

  const handleDropGallery = files => {
    const file = files[0];
    const [currentType] = file.type.split('/');
    if (file && currentType === 'image') {
      setTypeDoc(currentType);
      editDocument({
        type: currentType,
        document: file,
        index,
        extension: file.name.split('.').pop(),
        id
      });
      setHoverIconOptions(false);
    }
  };

  const acceptFormat = () => {
    if (typeDoc === 'image' || gallery) {
      return 'image/png, image/jpeg';
    }
    if (typeDoc === 'application') {
      return '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    }
    return 'image/png, image/jpeg, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  };

  const deleteDocument = () => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="deleteConfirmation"
        handleConfirm={async () => {
          if (gallery) {
            firebase
              .storage()
              .ref(`restaurant_gallery/${pathMenuGallery}/${id}`)
              .delete()
              .catch(error => console.log(error));
            await firestore
              .collection(
                `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
              )
              .doc(id)
              .set(
                { image: { original: '', x40: '', x400: '', x80: '' } },
                { merge: true }
              );
            setShowModal(false);
          } else {
            deletedDocument({ id });
          }
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  return (
    <Box
      className={classes.cardDocument}
      style={{
        backgroundImage: `url("${BORDER_DECORATION}")`
      }}
      onMouseEnter={() => setHoverIconOptions(true)}
      onMouseLeave={() => setHoverIconOptions(false)}
    >
      <input
        type="file"
        id={id}
        hidden
        accept={acceptFormat()}
        onChange={e => editDocumentImage(e.target.files[0])}
      />
      {/** Drop image or document */}
      <Box textAlign="center" style={{ cursor: 'pointer' }}>
        {idProgress !== id && !typeDocument && !photoUrl && id && (
          <Dropzone onDrop={!gallery ? handleDrop : handleDropGallery}>
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} accept={acceptFormat()} />
                <Box mb={1.6} className={classes.smallBox}>
                  <AiOutlineFileText className={classes.styleIcon} />
                </Box>
                <Box mb={3}>
                  <Typography>
                    {translation('central_payments.documents.drag_document')}
                  </Typography>
                  <Typography>
                    {translation('central_payments.documents.here')}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.styleText}>
                    {translation(
                      'central_payments.documents.upload_from_device'
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
          </Dropzone>
        )}
      </Box>
      {/** Preview image */}
      <Box textAlign="center" style={{ cursor: 'pointer' }}>
        {idProgress !== id &&
          photoUrl &&
          type !== 'application' &&
          type !== 'text' && (
            <Box
              style={{
                backgroundImage: `url(${photoUrl})`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '183px'
              }}
            />
          )}
      </Box>
      {/** Preview document */}
      {idProgress !== id &&
        (typeDocument || type === 'application' || type === 'text') && (
          <Box textAlign="center" className={classes.opacity}>
            <Box mb={1.6} className={classes.smallBox}>
              <AiOutlineFileText className={classes.styleIcon} />
            </Box>
            <Box mb={3}>
              <Typography>{fileName || `${title}.${docExtension}`}</Typography>
            </Box>
          </Box>
        )}
      {/** Add new document */}
      {!flag && (
        <Box
          mb={3}
          textAlign="center"
          className={classes.cursor}
          onClick={addNewDocument}
        >
          <Box className={classes.box}>
            <IoMdAddCircle className={classes.styleIcon} />
          </Box>
          <Typography>
            {translation('central_payments.documents.add_field')}
          </Typography>
          <Typography>
            {translation('central_payments.documents.new_document')}
          </Typography>
        </Box>
      )}
      {/** loadingUpload */}
      <Box textAlign="center" style={{ cursor: 'pointer' }}>
        {idProgress === id && flag && <CircularProgress />}
      </Box>
      {(typeDocument || photoUrl) && idProgress !== id && hoverIconOptions && (
        <OptionsDocument
          classes={classes}
          handleEdit={handleEdit}
          typeDoc={type}
          showImageDocument={showImageDocument}
          handleDownloadDocument={handleDownloadDocument}
          deleteDocument={deleteDocument}
        />
      )}
      {showModal && modal}
    </Box>
  );
};

export default PreviewDocument;
