/* eslint-disable no-shadow */
import React, { memo, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Link,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Search as SearchIcon
} from 'react-feather';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTranslation } from 'react-multi-lang/lib';
import { COLORS } from '../../theme/colors';
import exportToCsv from '../../utils/csv';
import ExpressTableHead from './ExpressTableHead';
import ExpressTableToolbar from './ExpressTableToolbar';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    // maxHeight: 500,
  },
  queryField: {
    width: 500
  },
  actionButtons: {
    cursor: 'pointer'
  },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  tableRowMarked: {
    backgroundColor: COLORS.lightGreen
  },
  searchStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.greenLight,
    borderRadius: 100,
    height: 35,
    width: 35,
    fontSize: 10
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
});

const ExpressTable = memo(
  ({
    columns,
    rows,
    search,
    searchPH,
    filterOptions,
    filterText,
    filterSettings,
    actions,
    additional,
    nameToExportCsv = null,
    clickRow,
    enhanced,
    handlePayment,
    markPaidRow
  }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [filteredRows, setFilteredRows] = useState(rows);
    const translation = useTranslation();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [selected, setSelected] = useState([]);

    if (actions) {
      const added = columns.filter(item => item.id === 'expressTableActions');
      if (!added.length) {
        columns.push({
          id: 'expressTableActions',
          label: translation('express_table.actions'),
          minWidth: 170,
          align: 'center'
        });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleQueryChange = event => {
      event.persist();
      setQuery(event.target.value);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = event => {
      event.stopPropagation();
      if (event.target.checked) {
        const newSelecteds = [];
        rows.forEach(n => {
          if (!n.orderPaid) newSelecteds.push(n.id);
        });
        setSelected(newSelecteds);
      } else {
        setSelected([]);
      }
    };

    const handleClick = (event, id) => {
      event.stopPropagation();
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };

    // eslint-disable-next-line consistent-return
    const filterBydate = () => {
      if (filter === 'all') {
        setFilteredRows(rows);
        return null;
      }
      const from = moment().subtract(filter * 1, 'days');
      const newRows = rows.filter(rowItem =>
        moment(rowItem[filterSettings.property]).isSameOrAfter(from)
      );
      setFilteredRows(newRows);
    };

    // eslint-disable-next-line no-shadow
    const handleFilter = filter => {
      setFilter(filter);
    };

    const sortHandler = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    useEffect(() => {
      setQuery('');
      setFilter('all');
    }, [rows]);

    useEffect(() => {
      if (filterSettings) {
        switch (filterSettings.filterType) {
          case 'dates':
            filterBydate();
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('unhandled option!!');
            break;
        }
      } else {
        const filterBy = columns.filter(item => item.filterBy);
        if (filter !== 'all' && filterBy.length) {
          const newRows = rows.filter(rowItem =>
            rowItem[filterBy[0].id].toLowerCase().includes(filter.toLowerCase())
          );
          setFilteredRows(newRows);
        } else setFilteredRows(rows);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, rows, columns]);

    useEffect(() => {
      const searchParams = columns
        .filter(item => item.search)
        .map(item => item.id);
      if (query) {
        const newRows = rows.filter(rowItem => {
          let containQuery = false;
          let someMatch = false;
          searchParams.forEach(property => {
            if (rowItem[property]) {
              containQuery = rowItem[property]
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase());
            }
            if (containQuery) someMatch = true;
          });
          if (someMatch) return rowItem;
          return false;
        });
        setFilteredRows(newRows);
      } else setFilteredRows(rows);
    }, [query, rows, columns]);

    // eslint-disable-next-line no-shadow
    const descendingComparator = (a, b, orderBy) => {
      let A = '';
      let B = '';
      if (
        orderBy === 'restaurant_name' ||
        orderBy === 'name' ||
        orderBy === 'email' ||
        orderBy === 'restaurant' ||
        orderBy === 'title' ||
        orderBy === 'promotionTitle'
      ) {
        if (a[orderBy]) A = a[orderBy].toUpperCase();
        if (b[orderBy]) B = b[orderBy].toUpperCase();
      } else {
        A = a[orderBy];
        B = b[orderBy];
      }
      if (B < A) {
        return -1;
      }
      if (B > A) {
        return 1;
      }
      return 0;
    };

    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    };

    function getData() {
      return stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }

    async function exportData() {
      const data = filteredRows;
      const columnsWithoutActions = columns.filter(
        item => item.id !== 'actions'
      );
      const headers = columnsWithoutActions.map(item => item.label);
      const dataToExport = data.map(item =>
        columnsWithoutActions.map(column => item[column.id])
      );
      exportToCsv(
        [headers, ...dataToExport],
        `${nameToExportCsv}${moment().format('YYYY_MM_DD_HH_mm')}`
      );
      return Promise.resolve();
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    return (
      <Paper className={classes.root}>
        <Grid container alignItems="center" justify="space-between">
          {additional && <Box m={3}>{additional}</Box>}
          {search && (
            <>
              <Box m={3}>
                <TextField
                  className={classes.queryField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div className={classes.searchStyle}>
                          <SvgIcon fontSize="small">
                            <SearchIcon color={COLORS.greenDark} />
                          </SvgIcon>
                        </div>
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={searchPH || 'Buscar'}
                  value={query}
                  variant="outlined"
                />
              </Box>
              {markPaidRow && (
                <Box m={3} p={2} className={classes.tableRowMarked}>
                  <Typography>Ordenes pagadas</Typography>
                </Box>
              )}
              {nameToExportCsv && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={exportData}
                >
                  {translation('earning.export')}
                </Button>
              )}
            </>
          )}
          {filterOptions && (
            <Box m={3}>
              <Typography variant="body1" color="primary">
                {filterText}
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                label={translation(
                  'admin_subsidiary.common_table.filter_label'
                )}
                name="filter"
                id="filter"
                value={filter}
                onChange={event => handleFilter(event.target.value)}
              >
                {filterOptions.map(item => (
                  <MenuItem key={item.value + Math.random()} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Grid>
        {enhanced && (
          <ExpressTableToolbar
            numSelected={selected.length}
            onPayment={() => handlePayment(selected)}
          />
        )}
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            {enhanced ? (
              <ExpressTableHead
                classes={classes}
                headCells={columns}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            ) : (
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      className={classes.tableCell}
                      style={{ minWidth: column.minWidth }}
                      sortDirection={
                        orderBy === (column.filterName || column.id)
                          ? order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={
                          orderBy === (column.filterName || column.id)
                            ? order
                            : 'asc'
                        }
                        onClick={event => {
                          sortHandler(event, column.filterName || column.id);
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {getData().map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={event => {
                      return enhanced && markPaidRow && !row[markPaidRow]
                        ? handleClick(event, row.id)
                        : {};
                    }}
                    selected={isItemSelected}
                    className={
                      markPaidRow && row[markPaidRow]
                        ? classes.tableRowMarked
                        : false
                    }
                  >
                    {enhanced && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={markPaidRow && row[markPaidRow]}
                        />
                      </TableCell>
                    )}
                    {columns.map(column => {
                      const value = row[column.id];
                      if (column.id === 'expressTableActions') {
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.tableCell}
                          >
                            <EditIcon
                              style={{ marginRight: '10px' }}
                              className={classes.actionButtons}
                              onClick={() => actions.edit(row)}
                            />
                            <DeleteIcon
                              className={classes.actionButtons}
                              onClick={() => actions.delete(row)}
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          className={classes.tableCell}
                          style={{ cursor: clickRow ? 'pointer' : 'default' }}
                        >
                          {column.link && row.linkTo ? (
                            <Link component={RouterLink} to={row.linkTo}>
                              {value}
                            </Link>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
);

export default ExpressTable;
