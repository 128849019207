import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  colors,
  Container,
  makeStyles
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-multi-lang/lib';
import Suspended from './Suspended';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    backgroundColor: colors.red[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  },
  facebook: {
    color: '#3B5998',
    fontSize: 13
  },
  google: {
    color: '#F34A38',
    fontSize: 13
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 138,
    backgroundColor: '#56B19E12',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));

function UserSuspended() {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Page className={classes.root} title={translation('social_networks.title')}>
      <Container maxWidth="xs">
        <Card
          className={classes.card}
          style={{ width: 558, marginLeft: '-50px' }}
        >
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Box display="flex" alignItems="center">
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
            <Box>
              <Suspended />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default UserSuspended;
