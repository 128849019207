/* eslint-disable */
// TODO: fix eslint
import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import subsidiary from '../dto/subsidiary';
import { getDistance } from 'geolib';

const COLLECTION = COLLECTIONS.USER;
export const MAX_LENGTH = 9999999999999999999999;
export const ONE_KM = 1000;

class DriverService {
  saveDriverOnSubsidiary = async (id, subsidiaryId) => {
    return firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({
        subsidiary_id: firebase.firestore.FieldValue.arrayUnion(subsidiaryId)
      });
  };

  removeDriverFromSubsidiary = async (id, subsidiaryId) => {
    return firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({
        subsidiary_id: firebase.firestore.FieldValue.arrayRemove(subsidiaryId)
      });
  };

  sortDealersBySubsidiaryDistance = (subsidiary, dealers) => {
    if (!subsidiary.location) return dealers;

    const dealersDistance = dealers.map(d => {
      let distanceToSubsidiary = MAX_LENGTH;
      if (d.location) {
        distanceToSubsidiary = getDistance(
          {
            latitude: subsidiary.location.latitude,
            longitude: subsidiary.location.longitude
          },
          {
            latitude: d.location.latitude,
            longitude: d.location.longitude
          }
        );
      }
      return {
        ...d,
        distanceToSubsidiary
      };
    });

    return dealersDistance.sort((a, b) => {
      // return b.distanceToSubsidiary - a.distanceToSubsidiary;
      return a.distanceToSubsidiary - b.distanceToSubsidiary;
    });
  };

  saveDealerDB = async (id, data) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({ ...data });

  editDealer = async (id, dealerData) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update(dealerData);
}

const driverService = new DriverService();
export default driverService;
