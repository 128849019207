import {
  Award as AwardIcon,
  Home as HomeIcon,
  List as ListIcon,
  MessageSquare as MessageIcon,
  ShoppingBag as BagIcon,
  ShoppingCart as ShoppingIcon,
  Users as UsersIcon
} from 'react-feather';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import FastfoodIcon from '@material-ui/icons/FastfoodOutlined';
import MapIcon from '@material-ui/icons/Map';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

export const customer_service = [
  {
    title: 'orders.details.header.order',
    icon: ReceiptIcon,
    href: '/admin/orders',
    items: [
      {
        title: 'menu.new_order.label',
        href: '/admin/orders/new'
      },
      {
        title: 'menu.process_order.label',
        href: '/admin/orders/process'
      },
      {
        title: 'menu.prepared_order',
        href: '/admin/orders/ready'
      },
      {
        title: 'menu.completed_order',
        href: '/admin/orders/complete'
      },
      {
        title: 'menu.canceled_order',
        href: '/admin/orders/canceled'
      }
    ]
  },
  {
    title: 'admin_navBar.dealers',
    icon: MotorcycleIcon,
    href: '/admin/dealers'
  }
];

export const navItemsGeneral = [
  {
    title: 'admin_navBar.principal_dashboard',
    icon: HomeIcon,
    href: '/admin'
  },
  {
    title: 'admin_navBar.user',
    icon: UsersIcon,
    href: '/admin/users',
    items: [
      {
        title: 'admin_navBar.active',
        href: '/admin/users/active'
      },
      {
        title: 'admin_navBar.suspended',
        href: '/admin/users/suspended'
      },
      {
        title: 'admin_navBar.archived',
        href: '/admin/users/archived'
      }
    ]
  },
  {
    title: 'admin_navBar.commerces',
    icon: BagIcon,
    href: '/admin/commerce',
    items: [
      {
        title: 'admin_subsidiary.pendingTable.pending',
        href: '/admin/commerce/pending'
      },
      {
        title: 'admin_navBar.active',
        href: '/admin/commerce/active'
      },
      {
        title: 'admin_navBar.rejected',
        href: '/admin/commerce/rejected'
      },
      {
        title: 'admin_navBar.suspended',
        href: '/admin/commerce/suspended'
      },
      {
        title: 'admin_navBar.archived',
        href: '/admin/commerce/archived'
      }
    ]
  },
  {
    title: 'orders.details.header.order',
    icon: ReceiptIcon,
    href: '/admin/orders',
    items: [
      {
        title: 'menu.new_order.label',
        href: '/admin/orders/new'
      },
      {
        title: 'menu.process_order.label',
        href: '/admin/orders/process'
      },
      {
        title: 'menu.prepared_order',
        href: '/admin/orders/ready'
      },
      {
        title: 'menu.completed_order',
        href: '/admin/orders/complete'
      },
      {
        title: 'menu.canceled_order',
        href: '/admin/orders/canceled'
      }
    ]
  },
  {
    title: 'admin_navBar.account',
    icon: ListIcon,
    href: '/admin/accounting'
  },
  {
    title: 'admin_navBar.promo',
    icon: AwardIcon,
    href: '/admin/promos'
  },
  {
    title: 'marketing.title',
    icon: ShoppingIcon,
    href: '/admin/marketing'
  },
  {
    title: 'Chat',
    icon: MessageIcon,
    href: '/admin/chat'
  },
  {
    title: 'admin_navBar.admin_accounts',
    icon: UsersIcon,
    href: '/admin/admin-users'
  },
  {
    title: 'admin_navBar.food_category',
    icon: FastfoodIcon,
    href: '/admin/food-categories'
  },
  {
    title: 'admin_navBar.area',
    icon: MapIcon,
    href: '/admin/areas'
  },
  {
    title: 'admin_navBar.dealers',
    icon: MotorcycleIcon,
    href: '/admin/dealers'
  }
];

export const maketingNavBar = [
  {
    title: 'admin_navBar.commerces',
    icon: BagIcon,
    href: '/admin/commerce',
    items: [
      {
        title: 'admin_subsidiary.pendingTable.pending',
        href: '/admin/commerce/pending'
      },
      {
        title: 'admin_navBar.active',
        href: '/admin/commerce/active'
      },
      {
        title: 'admin_navBar.rejected',
        href: '/admin/commerce/rejected'
      },
      {
        title: 'admin_navBar.suspended',
        href: '/admin/commerce/suspended'
      },
      {
        title: 'admin_navBar.archived',
        href: '/admin/commerce/archived'
      }
    ]
  }
];
