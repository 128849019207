import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useRestaurantFormStyles = makeStyles(theme => ({
  formCard: {
    width: '100%',
    boxShadow: 'unset'
  },

  chargeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: 500
  },

  chargeOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },

  isMontlySelect: {
    marginLeft: theme.spacing(1)
  }
}));
