import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Select,
  Box,
  Button,
  makeStyles,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import _ from 'lodash';
import { setFilter } from '../../../../../actions/filterActions';
import { useDealers } from '../../../../../hooks/AdminHooks/dealers/useDealers';
import { useAllRestaurantsToSelect } from '../../../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllSubsidiaries } from '../../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { useAllCities } from '../../../../../hooks/AdminHooks/cities/useCities';
import useFilter from '../../../../../hooks/filter/useFilter';
import { PAID_FILTER } from '../../../../../constants/index';
import ExportToExcel from '../../../../../components/ExportToExcel';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  selectContainer: {
    display: 'flex'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  }
}));

const Header = ({ orders }) => {
  const translation = useTranslation();
  const dealers = useDealers();
  const allRestaurants = useAllRestaurantsToSelect();
  const allSubsidiaries = useAllSubsidiaries();
  const cities = useAllCities();
  const filter = useFilter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [defaultCity, setDefaultCity] = useState(null);

  const getFromDate = () => {
    const fromDate = moment().subtract(7, 'days');
    return fromDate.format('YYYY-MM-DD');
  };

  const getToDate = () => {
    const toDate = moment();
    return toDate.format('YYYY-MM-DD');
  };

  const [fromDatePicker, setFromDatePicker] = useState(
    filter.fromDate || getFromDate()
  );
  const [toDatePicker, setToDatePicker] = useState(
    filter.toDate || getToDate()
  );

  if (filter && !filter.city && cities && !defaultCity) {
    const [defCity] = cities.filter(city => city.is_default);
    setDefaultCity(defCity ? defCity.id : cities[0].id);
    dispatch(setFilter({ city: defCity ? defCity.id : cities[0].id }));
  }
  if (filter && !filter.year) {
    dispatch(setFilter({ year: moment().format('YYYY') }));
  }
  if (filter && !filter.month) {
    dispatch(setFilter({ month: '00' }));
  }
  if (filter && !filter.driver) {
    dispatch(setFilter({ driver: 'all' }));
  }
  if (filter && !filter.restaurant) {
    dispatch(setFilter({ restaurant: 'all' }));
  }
  if (filter && filter.restaurant === 'all') {
    dispatch(setFilter({ subsidiary: 'all' }));
  }
  if (filter && !filter.fromDate && !filter.toDate) {
    dispatch(
      setFilter({
        fromDate: getFromDate(),
        toDate: getToDate()
      })
    );
  }
  if (filter && !filter.paid) {
    dispatch(setFilter({ paid: '-' }));
  }

  const getRestaurants = () => {
    if (!allSubsidiaries.length || !allRestaurants.length) return [];
    const subsidiaries = allSubsidiaries.filter(
      s => s.city?.id === filter.city
    );
    const restaurants = allRestaurants
      .filter(r => {
        let isInList = false;
        for (let i = 0; i < subsidiaries.length; i++) {
          if (r.value === subsidiaries[i].restaurant_id) {
            isInList = true;
            i = subsidiaries.length;
          }
        }
        return isInList;
      })
      .map(r => (
        <option key={r.value} value={r.value}>
          {r.label}
        </option>
      ));
    restaurants.unshift(
      <option key="all" value="all">
        {translation('admin_dealers.tabs.filters.all_rest')}
      </option>
    );
    return restaurants;
  };

  const getSubsidiaries = () => {
    const subsidiaries = allSubsidiaries
      .filter(s => s.restaurant_id === filter.restaurant)
      .map(s => (
        <option key={s.id} value={s.id}>
          {s.name}
        </option>
      ));
    subsidiaries.unshift(
      <option key="all" value="all">
        {translation('admin_dealers.tabs.filters.all_subs')}
      </option>
    );
    return subsidiaries;
  };

  const getDealers = () => {
    const drivers = dealers.map(item => (
      <option key={item.id} value={item.id}>
        {`${item.first_name} ${item.last_name}`}
      </option>
    ));
    drivers.unshift(
      <option key="all" value="all">
        {translation('admin_dealers.tabs.filters.all_drivers')}
      </option>
    );
    return drivers;
  };

  const onReset = () => {
    dispatch(
      setFilter({
        city: defaultCity,
        restaurant: 'all',
        subsidiary: 'all',
        driver: 'all',
        fromDate: getFromDate(),
        toDate: getToDate(),
        paid: '0'
      })
    );
  };

  const onFilterByDatePicker = useCallback(
    (from, to) => {
      return dispatch(
        setFilter({
          fromDate: from,
          toDate: to
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    onFilterByDatePicker(fromDatePicker, toDatePicker);
  }, [onFilterByDatePicker, fromDatePicker, toDatePicker]);

  const getDataSet = () => {
    const dataSet = [];
    const contentByDrivers = _.chain(orders)
      .groupBy('driverId')
      .map((value, key) => ({
        driverId: key,
        orders: value
      }))
      .value();
    const contentByDriversWithDriver = contentByDrivers.map(item => {
      const [driver] = dealers.filter(d => d.id === item.driverId);
      return {
        ...item,
        driver
      };
    });

    let totalDeliveryFee = 0;
    let totalSubsidy = 0;
    let totalBonus = 0;

    contentByDriversWithDriver.forEach(item => {
      const data = item.orders.map(o => [
        o.counter,
        o.orderCode,
        o.driverName,
        o.subsiRestName,
        o.date,
        o.deliveryFee,
        o.subsidy,
        o.bonus,
        o.subsidy + o.bonus,
        '-'
      ]);

      const total = data.reduce((currentTotal, cv) => currentTotal + cv[8], 0);
      const tDeliveryFee = data.reduce(
        (currentTotal, cv) => currentTotal + cv[5],
        0
      );
      const tSubsidy = data.reduce(
        (currentTotal, cv) => currentTotal + cv[6],
        0
      );
      const tBonus = data.reduce((currentTotal, cv) => currentTotal + cv[7], 0);

      totalDeliveryFee += tDeliveryFee;
      totalSubsidy += tSubsidy;
      totalBonus += tBonus;

      const info = [
        [translation('admin_dealers.tabs.csv.total'), total],
        [translation('admin_dealers.tabs.csv.bank'), item.driver?.bank_name],
        [
          translation('admin_dealers.tabs.csv.account'),
          item.driver?.bank_account
        ],
        [
          translation('admin_dealers.tabs.csv.account_type'),
          item.driver?.bank_account_type
        ],
        [
          translation('admin_dealers.tabs.csv.full_name'),
          item.orders[0]?.driverName
        ],
        [
          translation('admin_dealers.tabs.csv.ci'),
          item.driver?.driver_information.ci_number
        ]
      ];

      const table = {
        xSteps: 1,
        ySteps: 1,
        columns: [
          translation('admin_dealers.tabs.csv.number'),
          translation('admin_dealers.tabs.csv.order'),
          translation('admin_dealers.tabs.csv.name'),
          translation('admin_dealers.tabs.csv.suc_rest'),
          translation('admin_dealers.tabs.csv.date'),
          translation('admin_dealers.tabs.csv.charge'),
          translation('admin_dealers.tabs.csv.subsidy'),
          translation('admin_dealers.tabs.csv.bonus'),
          translation('admin_dealers.tabs.csv.addition'),
          translation('admin_dealers.tabs.csv.paid')
        ],
        data
      };

      const driverInfoTable = {
        xSteps: 9,
        columns: [
          translation('admin_dealers.tabs.csv.total'),
          translation('admin_dealers.tabs.csv.value')
        ],
        data: info
      };

      dataSet.push(table);
      dataSet.push(driverInfoTable);
    });

    const totalsTable = {
      ySteps: 3,
      xSteps: 6,
      columns: [
        translation('admin_dealers.tabs.csv.total_delivery_fee'),
        translation('admin_dealers.tabs.csv.total_subsidy'),
        translation('admin_dealers.tabs.csv.total_bonus')
      ],
      data: [[totalDeliveryFee, totalSubsidy, totalBonus]]
    };

    dataSet.push(totalsTable);
    return dataSet;
  };

  if (!cities)
    return (
      <Box display="flex" justifyContent="center" my={1}>
        <CircularProgress />
      </Box>
    );

  return (
    <Grid className={classes.container}>
      <Box>
        {dealers && (
          <ExportToExcel
            data={getDataSet}
            sheetName={`drivers_report_${moment().format('YYYY_MM_DD_HH_mm')}`}
            buttonLabel={translation('earning.export')}
          />
        )}
      </Box>

      <Box className={classes.selectContainer}>
        {cities && (
          <Box mx={1.5}>
            <Select
              native
              value={filter.city}
              onChange={e => {
                return dispatch(
                  setFilter({
                    city: e.target.value,
                    restaurant: 'all',
                    subsidiary: 'all',
                    driver: 'all'
                  })
                );
              }}
            >
              {cities.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </Box>
        )}

        <Box mx={1.5}>
          <DatePicker
            format="YYYY-MM-DD"
            variant="dialog"
            value={fromDatePicker}
            onChange={setFromDatePicker}
          />
          <FormHelperText>Desde</FormHelperText>
        </Box>

        <Box mx={1.5}>
          <DatePicker
            format="YYYY-MM-DD"
            variant="dialog"
            value={toDatePicker}
            onChange={setToDatePicker}
          />
          <FormHelperText>Hasta</FormHelperText>
        </Box>

        {dealers && (
          <Box mx={1.5}>
            <Select
              native
              value={filter.driver}
              onChange={e => dispatch(setFilter({ driver: e.target.value }))}
            >
              {getDealers()}
            </Select>
          </Box>
        )}

        {allRestaurants && (
          <Box mx={1.5}>
            <Select
              native
              value={filter.restaurant}
              onChange={e => {
                return dispatch(
                  setFilter({
                    restaurant: e.target.value,
                    subsidiary: 'all'
                  })
                );
              }}
            >
              {getRestaurants()}
            </Select>
          </Box>
        )}
        {allSubsidiaries && (
          <Box mx={1.5}>
            <Select
              native
              value={filter.subsidiary}
              disabled={!filter.restaurant || filter.restaurant === 'all'}
              onChange={e => {
                return dispatch(setFilter({ subsidiary: e.target.value }));
              }}
            >
              {getSubsidiaries()}
            </Select>
          </Box>
        )}
        <Box mx={1.5}>
          <Select
            native
            value={filter.paid}
            onChange={e => {
              return dispatch(setFilter({ paid: e.target.value }));
            }}
          >
            {PAID_FILTER.map(option => (
              <option value={option.value}>{translation(option.label)}</option>
            ))}
          </Select>
          <FormHelperText>
            {translation('admin_dealers.tabs.filters.paidOutNotPayed')}
          </FormHelperText>
        </Box>
        <Box mx={1.5}>
          <Button variant="contained" onClick={onReset}>
            {translation('accounting.reset_button')}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default Header;
