import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import DropzoneModal from '../../../../components/Dropzone/DropzoneModal';
import { getDate } from '../../../../utils/date';

const useStyles = makeStyles(theme => ({
  root: {},
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

function ProfileDetails({
  user,
  viewYear = false,
  updateImage = () => {},
  handleCredentialModal = () => {},
  className,
  viewSeeCredentials = true,
  viewUpdateImage = true,
  ...rest
}) {
  const classes = useStyles();
  const translation = useTranslation();
  const [open, setOpen] = useState(false);
  const image = user.profile_photo ? user.profile_photo.x400 : '';
  const userYear =
    user.created_at !== null ? moment(getDate(user.created_at)).year() : false;

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
          >
            <Avatar className={classes.avatar} src={image} />
            <Typography
              className={classes.name}
              gutterBottom
              variant="h3"
              color="textPrimary"
            >
              {`${user.first_name ? user.first_name : ''} 
              ${user.last_name ? user.last_name : ''}`}
            </Typography>
            {viewYear && userYear && (
              <Typography
                className={classes.name}
                gutterBottom
                variant="h6"
                color="textPrimary"
              >
                {translation('admin_users.user_since')}
                {userYear}
              </Typography>
            )}
          </Box>
        </CardContent>
        <CardActions>
          {viewUpdateImage && (
            <Button fullWidth variant="text" onClick={() => setOpen(true)}>
              {translation('restaurant.button_title')}
            </Button>
          )}
          {viewSeeCredentials && (
            <Button
              fullWidth
              variant="text"
              onClick={() => handleCredentialModal()}
            >
              {translation('restaurant.credentials')}
            </Button>
          )}
        </CardActions>
      </Card>
      <DropzoneModal
        onClose={() => setOpen(false)}
        open={open}
        setImage={updateImage}
        onFinish={() => setOpen(false)}
        path={`profile/${user.id}`}
      />
    </>
  );
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default ProfileDetails;
