import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  Typography,
  Card
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import useFilter from '../../../../../hooks/filter/useFilter';
import ExpressTable from '../../../../../components/Table/ExpressTable';
import { filteredOrders } from '../../../../../services/orderServices';
import OrderDetailsView from '../../../order/OrderDetailsView';
import orderStatusTranslate from '../../../../../utils/translateUtil';
import Orderstatus from '../../../../orders/OrderDetailsView/Orderstatus';
import { getNumberDecimalFixed } from '../../../../../utils/numberUtils';
import { useAllRestaurantsToSelect } from '../../../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useSalesStyles } from './styles';

const Sales = props => {
  const { handleOrders, getTotals } = props;
  const filter = useFilter();
  const translation = useTranslation();
  const [tableContent, setTableContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const allRestaurants = useAllRestaurantsToSelect();
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalRefund, setTotalRefund] = useState(0);
  const classes = useSalesStyles();

  const tableColumns = [
    {
      id: 'counter',
      label: translation('accounting.table.counter'),
      align: 'center'
    },
    {
      id: 'orderNumber',
      label: translation('accounting.table.order_number'),
      align: 'center',
      search: true
    },
    {
      id: 'restaurant',
      label: translation('accounting.table.rest'),
      align: 'center',
      search: true
    },
    {
      id: 'date',
      filterName: 'dateValue',
      label: translation('accounting.table.date'),
      align: 'center',
      search: true
    },
    {
      id: 'status',
      filterName: 'statusValue',
      label: translation('accounting.table.status'),
      align: 'center',
      search: true
    },
    {
      id: 'city',
      label: translation('accounting.table.city'),
      align: 'center',
      search: true
    },
    {
      id: 'sale',
      filterName: 'saleValue',
      label: translation('accounting.table.sale'),
      align: 'center',
      search: true
    },
    {
      id: 'service',
      label: translation('accounting.table.service'),
      align: 'center',
      search: true
    },
    {
      id: 'commissionPercentage',
      label: translation('accounting.table.commission_percentage'),
      align: 'center',
      search: true
    },
    {
      id: 'commission',
      label: translation('accounting.table.commission'),
      align: 'center',
      search: true
    },
    {
      id: 'promotionTitle',
      label: translation('accounting.table.promo'),
      align: 'center',
      search: true
    },
    {
      id: 'discount',
      label: translation('accounting.table.discount'),
      align: 'center',
      search: true
    },
    {
      id: 'refund',
      label: translation('accounting.table.refund'),
      align: 'center',
      search: true
    }
  ];

  const getCommission = (restId, orderType, total) => {
    const [rest] = allRestaurants.filter(r => r.value === restId);
    if (rest) {
      const price = (
        (rest.charge[orderType.toLowerCase()] * total) /
        100
      ).toFixed(2);
      return isNaN(price) ? 0 : price;
    }
    return 0;
  };

  const getCommissionPercentageUsed = (restId, orderType) => {
    const [rest] = allRestaurants.filter(r => r.value === restId);
    if (rest) {
      const percentage = rest.charge[orderType.toLowerCase()];
      return percentage || 0;
    }
    return 0;
  };

  useEffect(() => {
    setLoading(true);
    filteredOrders(filter).then(r => {
      const tc = r.payload.map((item, index) => ({
        counter: index + 1,
        orderNumber: item.order_code,
        restaurant: `${item.restaurant_name} - ${item.subsidiary_name}`,
        date: moment(item.created_at.toDate()).format('LLL'),
        dateValue: item.created_at,
        status: <Orderstatus orderStatus={item.status} />,
        statusValue: orderStatusTranslate(item.status, translation).status,
        statusFilter: item.status,
        city: item.subsidiary_city?.name || '-',
        sale: `${getNumberDecimalFixed(item.total, 2).toFixed(2)}`,
        saleValue: isNaN(getNumberDecimalFixed(item.total, 2))
          ? 0
          : getNumberDecimalFixed(item.total, 2),
        service: item.order_type.toLowerCase().replace('_', ' ') || '-',
        commissionPercentage: `${getCommissionPercentageUsed(
          item.restaurant_id,
          item.order_type
        ) || 0}`,
        commission: `${getCommission(
          item.restaurant_id,
          item.order_type,
          item.total
        ) || 0}`,
        commissionValue: getCommission(
          item.restaurant_id,
          item.order_type,
          item.total
        ),
        id: item.id,
        promotionTitle: item.promotionTitle || '-',
        discount: `${item.discount || '0'}`,
        refund: `${item.refund || 0}`,
        refund_description: item.refund_description || ''
      }));

      setTableContent(tc);
      handleOrders(tc);
      setLoading(false);
      if (tc.length) {
        setTotalSales(
          tc
            .reduce((total, currentValue) => total + currentValue.saleValue, 0)
            .toFixed(2)
        );
        setTotalCommission(
          tc
            .reduce(
              (total, currentValue) => total + currentValue.commissionValue * 1,
              0
            )
            .toFixed(2)
        );
        setTotalDiscount(
          tc
            .reduce(
              (total, currentValue) => total + currentValue.discount * 1,
              0
            )
            .toFixed(2)
        );
        setTotalRefund(
          tc
            .reduce((total, currentValue) => total + currentValue.refund * 1, 0)
            .toFixed(2)
        );
      } else {
        setTotalSales(0);
        setTotalCommission(0);
        setTotalDiscount(0);
        setTotalRefund(0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, allRestaurants.length]);

  useEffect(() => {
    const totalToCharge = totalCommission - totalDiscount - totalRefund;
    getTotals(
      totalSales,
      totalCommission,
      totalDiscount,
      totalRefund,
      totalToCharge
    );
  }, [totalSales, totalCommission, totalDiscount, totalRefund, getTotals]);

  const onSetOrder = row => {
    setOrderId(row.id);
  };

  return (
    <Grid>
      {loading && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !orderId && (
        <Box>
          <ExpressTable
            columns={tableColumns}
            rows={tableContent}
            clickRow={onSetOrder}
            search
          />
          <Card className={classes.card}>
            <Box className={classes.container}>
              <Box className={classes.container}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('accounting.summary.total_sale')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {totalSales}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.total_commission')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {totalCommission}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.promo')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {totalDiscount}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.refund')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {totalRefund}
                </Typography>
              </Box>
              <Box className={clsx(classes.container, classes.lastContainer)}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('accounting.summary.toal_charge')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs.{' '}
                  {(totalCommission - totalDiscount - totalRefund).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
      {orderId && (
        <>
          <Button onClick={() => setOrderId(null)}>
            {`< ${translation('admin_subsidiary.common_table.back')}`}
          </Button>
          <OrderDetailsView orderIdFromProps={orderId} />
        </>
      )}
    </Grid>
  );
};

export default Sales;
