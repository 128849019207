import Base from '../base';
import Media from './media';
// eslint-disable-next-line import/named
import { PROVIDER_STORY, STATUS_STORY } from '../../constants';

export default class Story extends Base {
  constructor({
    id = '',
    user_id = '',
    media = new Media({}),
    phone_number = '',
    reason_canceled = '',
    status = STATUS_STORY.PENDING,
    review_date = null,
    full_name = '',
    profile_photo = {},
    city_name = '',
    enable = true,
    provider = PROVIDER_STORY.MERCHANT,
    show_notification = false
  }) {
    super({ id, enable });
    this.id = id;
    this.user_id = user_id;
    this.media = media;
    this.phone_number = phone_number;
    this.reason_canceled = reason_canceled;
    this.status = status;
    this.review_date = review_date;
    this.full_name = full_name;
    this.profile_photo = profile_photo;
    this.city_name = city_name;
    this.provider = provider;
    this.show_notification = show_notification;
  }

  toPlainObject() {
    return { ...this, media: this.media.toPlainObject() };
  }
}
