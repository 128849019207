import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { registerStatus } from '../../../../../constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const Suspend = ({ handleConfirm, handleClose }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [reason, setReason] = useState('');

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('admin_invoice.modal.suspend')}
        />
        <Divider />
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Box my={3} width="100%">
              <Grid item container justify="center">
                <TextField
                  type="text"
                  label={translation('admin_invoice.modal.suspend_reason')}
                  placeholder={translation('admin_invoice.modal.suspend_ph')}
                  onChange={e => setReason(e.target.value)}
                  name="reason"
                  value={reason}
                  variant="outlined"
                />
              </Grid>
            </Box>
            <Box mb={3} width="100%">
              <Grid item container justify="center">
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      handleConfirm(reason, registerStatus.SUSPENDED);
                    }}
                  >
                    {translation('admin_invoice.modal.button.suspend')}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                  >
                    {translation('admin_invoice.modal.button.cancel')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Suspend;
