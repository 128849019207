import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import { DRIVER_APPLICATION_STATUS } from '../../../../dto/enum';
import Label from '../../../../components/Label';

const Dealerstatus = ({ dealerStatus }) => {
  const translation = useTranslation();
  let status = '';
  let statusColor = '';
  switch (dealerStatus) {
    case DRIVER_APPLICATION_STATUS.ACCEPTED:
      status = translation('admin_dealers.driver_aplication_status.accepted');
      statusColor = 'success';
      break;
    case DRIVER_APPLICATION_STATUS.REJECTED:
      status = translation('admin_dealers.driver_aplication_status.rejected');
      statusColor = 'error';
      break;
    case DRIVER_APPLICATION_STATUS.SUSPENDED:
      status = translation('admin_dealers.driver_aplication_status.suspended');
      statusColor = 'warning';
      break;
    case DRIVER_APPLICATION_STATUS.APPLIED:
      status = translation('admin_dealers.driver_aplication_status.applied');
      statusColor = 'primary';
      break;
    case DRIVER_APPLICATION_STATUS.EXPIRED_LICENSE:
      status = translation(
        'admin_dealers.driver_aplication_status.expired_licence'
      );
      statusColor = 'primary';
      break;
    case DRIVER_APPLICATION_STATUS.EXPIRED_SOAT:
      status = translation(
        'admin_dealers.driver_aplication_status.expired_soat'
      );
      statusColor = 'primary';
      break;
    default:
      break;
  }

  return (
    <Label color={statusColor}>
      <Typography variant="h4">
        {translation('admin_dealers.dealer')} {status}
      </Typography>
    </Label>
  );
};

export default Dealerstatus;
