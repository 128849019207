import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  TextField
} from '@material-ui/core';
import firebase from 'firebase/app';
import { useTranslation } from 'react-multi-lang';
import { BsPencil } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { GoCheck } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { useFirestore } from 'react-redux-firebase';
import { COLORS } from '../../theme/colors';
import { COLLECTIONS } from '../../constants';
import PreviewDocument from '../../components/Document/PreviewDocument';
import XpressModal from '../../components/Modal';
import firebaseService from '../../services/firebaseService';
import MenuGallery from '../../dto/menuGallery';
import ModalCrop from '../../components/Document/ModalCrop';
import { getProductsByGallery } from '../../services/productsService';

const useStyles = makeStyles(theme => ({
  textTitle: {
    fontFamily: 'Nunito Sans',
    fontSize: '30px'
  },
  textDescription: {
    fontFamily: 'Nunito Sans',
    fontSize: '22px'
  },
  containerDoc: {
    border: `1px solid ${COLORS.darkGolden}`,
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: `${COLORS.green}`,
    cursor: 'pointer'
  },
  styleIconCheck: {
    width: '15px',
    height: '15px',
    color: 'white'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '2vh',
    height: '2vh',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  styleIconDelete: {
    width: '24px',
    height: '24px',
    color: `${COLORS.green}`,
    cursor: 'pointer'
  }
}));

const Gallery = ({ menuDocs = [], restId }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const translation = useTranslation();
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [menu, setMenu] = useState([...menuDocs]);
  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [selectMenu, setSelectMenu] = useState('');
  const pathMenuGallery = `${COLLECTIONS.MENU_GALLERY}/${restId}`;
  const [openDialogCrop, setOpenDialogCrop] = useState(false);
  const [upImg, setUpImg] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [prevTitle, setPrevTitle] = useState('');
  const [prevIndex, setPrevIndex] = useState(null);
  const [idProgress, setIdProgress] = useState('');
  const [originalFile, setOriginalFile] = useState(null);
  const [fileState, setFileState] = useState({
    extension: null,
    id: null,
    type: null
  });

  const updateProductImage = async (id, newPhoto, isNull = true) => {
    const products = await getProductsByGallery({ galleryId: id });

    if (products.length) {
      const batch = firebase.firestore().batch();
      products.forEach(product => {
        const productRef = firebase
          .firestore()
          .collection(COLLECTIONS.PRODUCT)
          .doc(product.id);
        if (isNull) {
          batch.update(productRef, {
            photo: {
              original: '',
              x40: '',
              x80: '',
              x400: ''
            }
          });
        } else {
          batch.update(productRef, {
            photo: { ...newPhoto }
          });
        }
      });
      await batch.commit();
    }
  };

  const imageToUrl = (file, extension, id, type) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result));
    reader.readAsDataURL(file);
    setFileState({ extension, id, type });
    setOpenDialogCrop(true);
  };

  const deleteImage = file => {
    return firebase
      .storage()
      .ref(file)
      .delete()
      .catch(error => console.log(error));
  };

  const handleUploadOriginal = () => {
    const storageRef = firebase
      .storage()
      .ref(`restaurant_gallery/${pathMenuGallery}/original/${fileState.id}`);
    const uploadTask = storageRef.put(originalFile);
    return uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload original', progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        firestore
          .collection(
            `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
          )
          .doc(fileState.id)
          .set({ original_image: url }, { merge: true });
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${fileState.id}_400x400`
        );
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${fileState.id}_40x40`
        );
        deleteImage(
          `restaurant_gallery/${pathMenuGallery}/original/resize/${fileState.id}_80x80`
        );
      }
    );
  };

  const uploadDocument = async file => {
    const urlPreview = URL.createObjectURL(file);
    setPreviewImage({ imageUrl: urlPreview, id: fileState.id });
    const storageRef = firebase
      .storage()
      .ref(`restaurant_gallery/${pathMenuGallery}/${fileState.id}`);
    const uploadTask = storageRef.put(file);
    handleUploadOriginal();
    return uploadTask.on(
      'state_changed',
      snapshot => {
        setIdProgress(fileState.id);
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        const image = {
          original: url
        };
        await firestore
          .collection(
            `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
          )
          .doc(fileState.id)
          .set(
            { image, extension: fileState.extension, type: fileState.type },
            { merge: true }
          );
        firebase
          .firestore()
          .collection(
            `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
          )
          .doc(fileState.id)
          .onSnapshot(doc => {
            const data = doc.data();
            if (data?.image.x40 && data?.image.x400 && data?.image.x80) {
              updateProductImage(
                fileState.id,
                {
                  original: url,
                  x40: data?.image.x40,
                  x80: data?.image.x80,
                  x400: data?.image.x400
                },
                false
              );
            }
          });
        setIdProgress('');
      }
    );
  };

  const editDocument = ({
    type = '',
    document = null,
    index,
    extension,
    id
  }) => {
    menu[index] = {
      ...menu[index],
      type,
      document
    };
    imageToUrl(document, extension, id, type);
    setOriginalFile(document);
  };

  const deletedDocument = async ({ id }) => {
    const newDocuments = menu.filter(item => item.id !== id);
    deleteImage(`restaurant_gallery/${pathMenuGallery}/${id}`);
    deleteImage(`restaurant_gallery/${pathMenuGallery}/original/${id}`);
    deleteImage(`restaurant_gallery/${pathMenuGallery}/resize/${id}_400x400`);
    deleteImage(`restaurant_gallery/${pathMenuGallery}/resize/${id}_40x40`);
    deleteImage(`restaurant_gallery/${pathMenuGallery}/resize/${id}_80x80`);

    await firestore
      .collection(
        `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
      )
      .doc(id)
      .delete();
    setMenu(newDocuments);
    updateProductImage(id);
  };

  const handleCancel = index => {
    if (index || index === 0) {
      menu[index].title = prevTitle;
      setEditTitle(false);
      setSelectMenu('');
      setNewTitle('');
    }
  };

  const deleteDocumentModal = ({ id }) => {
    setShowModal(true);
    handleCancel(prevIndex);
    setModal(
      <XpressModal
        type="deleteConfirmation"
        handleConfirm={async () => {
          deletedDocument({ id });
          setShowModal(false);
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  const createDocument = async ({ id, title }) => {
    const menuGallery = new MenuGallery();
    menuGallery.id = id;
    menuGallery.title = title;
    menuGallery.restaurant_id = restId;
    await firestore
      .collection(
        `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
      )
      .doc(id)
      .set({ ...menuGallery }, { merge: true });
  };

  const addNewDocument = () => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="addMenu"
        handleConfirm={({ title = '' }) => {
          const newDocs = [...menu];
          const newId = firebaseService.randomId(COLLECTIONS.MENU_GALLERY);
          newDocs.push({
            title,
            type: 'image',
            document: null,
            id: newId
          });
          createDocument({ id: newId, title });
          setMenu(newDocs);
          setShowModal(false);
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  const handleEditTitle = (value, index) => {
    menu[index].title = value;
    setNewTitle(value);
  };

  const handleConfirm = async index => {
    if (newTitle) {
      await firestore
        .collection(
          `${COLLECTIONS.RESTAURANT}/${restId}/${COLLECTIONS.MENU_GALLERY}`
        )
        .doc(menu[index].id)
        .set({ title: newTitle }, { merge: true });
      setSelectMenu('');
      setEditTitle(false);
      setPrevTitle(menu[index].title);
      setPrevIndex(index);
    }
  };

  const showEditTitle = (id, index) => {
    if (prevTitle) {
      menu[prevIndex].title = prevTitle;
    }
    setPrevTitle(menu[index].title);
    setPrevIndex(index);
    setNewTitle(menu[index].title);
    setSelectMenu(id);
    setEditTitle(true);
  };

  useEffect(() => {
    setMenu([...menuDocs]);
  }, [menuDocs]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={4}>
        {menu.map((doc, index) => (
          <Grid sm={4} item key={index} xs={12}>
            <Box className={classes.containerDoc}>
              <Box
                display="flex"
                justifyContent="space-between"
                margin="5% 5% 0% 5%"
                alignItems="center"
              >
                {selectMenu !== doc.id && (
                  <>
                    <Typography className={classes.cardTitle}>
                      {translation(doc.title)}
                    </Typography>
                    <Box>
                      <BsPencil
                        className={classes.styleIconOption}
                        onClick={() => showEditTitle(doc.id, index)}
                      />
                      <MdCancel
                        className={classes.styleIconDelete}
                        onClick={() => deleteDocumentModal({ id: doc.id })}
                      />
                    </Box>
                  </>
                )}
                {editTitle && selectMenu === doc.id && (
                  <Box display="flex">
                    <TextField
                      label="Título"
                      value={newTitle || menu[index].title}
                      variant="outlined"
                      onChange={e => handleEditTitle(e.target.value, index)}
                      size="small"
                    />
                    <Box ml={1}>
                      <Box
                        className={classes.iconOption}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => handleConfirm(index)}
                      >
                        <GoCheck className={classes.styleIconCheck} />
                      </Box>
                      <Box
                        className={classes.iconOption}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => handleCancel(index)}
                      >
                        <IoMdClose className={classes.styleIconCheck} />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box width="100%" height="100%">
                <PreviewDocument
                  type={doc?.type}
                  title={doc?.title}
                  photoUrl={doc?.image?.original}
                  editDocument={editDocument}
                  deletedDocument={deletedDocument}
                  id={doc?.id}
                  index={index}
                  docExtension={doc?.extension}
                  flag
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  idProgress={idProgress}
                  gallery
                  restId={restId}
                />
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid sm={4} item xs={12}>
          <Box className={classes.containerDoc}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <PreviewDocument
                addNewDocument={addNewDocument}
                idProgress={idProgress}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showModal && modal}
      <ModalCrop
        openDialog={openDialogCrop}
        setOpenDialog={() => {
          setOpenDialogCrop(false);
        }}
        upImg={upImg}
        saveBlob={uploadDocument}
      />
    </div>
  );
};

export default Gallery;
