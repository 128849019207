import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import RegisterView from 'src/views/auth/RegisterView';

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <RegisterView />
    </Page>
  );
}

export default HomeView;
