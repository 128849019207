import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { registerStatus } from '../../../../../constants';
import FirebaseService from '../../../../../services/firebaseService';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const Archived = ({ handleConfirm, handleClose, name }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const admin = useSelector(state => state.account.user.email);

  if (admin && admin !== user) {
    setUser(admin);
    setLoading(false);
  }

  const verifyUser = async (currentUser, status) => {
    FirebaseService.loginWithEmailAndPassword({
      email: currentUser,
      password
    })
      .then(() => handleConfirm(currentUser, status))
      .catch(() => setError(true));
  };

  return (
    <>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Grid>
          <Card className={classes.content}>
            <CardHeader
              className={classes.Header}
              title={translation('admin_invoice.modal.archive')}
            />
            <Divider />
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Grid item container justify="center">
                  <Box mx={2} mb={3} fontWeight="fontWeightBold">
                    <Typography>
                      {`${translation(
                        'admin_invoice.modal.are_u_sure'
                      )} "{name}"?`}
                    </Typography>
                  </Box>
                  <Box mx={2}>
                    <Typography>
                      {translation('admin_invoice.modal.are_u_sure_leyend')}
                    </Typography>
                  </Box>
                </Grid>
                <Box my={3} width="100%">
                  <Grid
                    item
                    container
                    justify="center"
                    direction="column"
                    alignItems="center"
                  >
                    <Box width="100%" mb={2}>
                      <TextField
                        fullWidth
                        disabled
                        type="text"
                        label={translation('admin_invoice.modal.user')}
                        onChange={e => setUser(e.target.value)}
                        name="reason"
                        value={user}
                        variant="outlined"
                      />
                    </Box>
                    <Box width="100%">
                      <Grid item container direction="column" justify="center">
                        <TextField
                          type="password"
                          label={translation('admin_invoice.modal.password')}
                          onChange={e => setPassword(e.target.value)}
                          name="reason"
                          value={password}
                          variant="outlined"
                        />
                        {error && (
                          <Box>
                            <Typography
                              style={{ color: 'red' }}
                              variant="body2"
                            >
                              {translation('admin_invoice.modal.error_pass')}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <Box mb={3} width="100%">
                  <Grid item container justify="center">
                    <Box mr={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          verifyUser(user, registerStatus.ARCHIVED);
                        }}
                      >
                        {translation('admin_invoice.modal.button.archived')}
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleClose}
                      >
                        {translation('admin_invoice.modal.button.cancel')}
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default Archived;
