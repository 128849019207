import { createRef, useEffect, useState } from 'react';
import Subsidiary from '../../../../dto/subsidiary';

const useRegisterSubsidiary = () => {
  const [subsidiaryNumber, setSubsidiaryNumber] = useState(1);
  const [subsidiaries, setSubsidiaries] = useState([]);
  useEffect(() => {
    const items = [...subsidiaries];
    if (subsidiaryNumber > subsidiaries.length) {
      let iterations = subsidiaryNumber - subsidiaries.length;
      while (iterations) {
        items.push({
          id: items.length + 1,
          formRef: createRef(),
          subsidiary: new Subsidiary()
        });
        iterations--;
      }
      setSubsidiaries(items);
    } else if (subsidiaries.length > subsidiaryNumber) {
      let iterations = subsidiaries.length - subsidiaryNumber;
      while (iterations) {
        items.pop();
        iterations--;
      }
      setSubsidiaries(items);
    }
    // eslint-disable-next-line
  }, [subsidiaryNumber]);
  const setSubsidiary = (id, subs) => {
    const items = [...subsidiaries];
    const index = items.findIndex(s => s.id === id);
    if (index > -1) {
      items[index].subsidiary = subs;
    }
    setSubsidiaries(items);
  };

  return {
    subsidiaryNumber,
    setSubsidiaryNumber,
    subsidiaries,
    setSubsidiary
  };
};
export default useRegisterSubsidiary;
