import { useState, useEffect, useMemo } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';
import firebase from '../config/firebaseConfig';

export default function useMenu(id, collection) {
  useFirestoreConnect([
    {
      collection,
      doc: id,
      storeAs: 'current_menu'
    }
  ]);
  const currentMenu = useSelector(state => state.firestore.data.current_menu);
  return currentMenu;
}

export const useMenuNative = (id, collection) => {
  const [currentMenu, setCurrentMenu] = useState(null);
  useEffect(() => {
    if (id && collection) {
      return firebase
        .firestore()
        .collection(collection)
        .doc(id)
        .onSnapshot(snap => {
          setCurrentMenu(snap.data());
        });
    }
    // eslint-disable-next-line
  }, [id]);
  return currentMenu;
};

export const useMenuProducts = (subId, sectionId, isTemplate) => {
  let query = [];
  if (isTemplate) {
    query = [
      ['menu_section_id', '==', sectionId],
      ['template', '==', true],
      ['enable', '==', true],
      ['subsidiary_id', '==', '']
    ];
  } else
    query = [
      ['subsidiary_id', '==', subId],
      ['enable', '==', true],
      ['menu_section_id', '==', sectionId]
    ];
  const storeAs = `menu_products_${sectionId}`;
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      where: query,
      storeAs
    }
  ]);

  const rawMenuProducts = useSelector(
    state => state.firestore.ordered[storeAs] || []
  );

  const menuProducts = rawMenuProducts
    ? rawMenuProducts
        .map(item => ({
          ...item,
          menu_orer: item.menu_order || null
        }))
        .sort((a, b) => (a.menu_order > b.menu_order ? 1 : -1))
    : [];
  return menuProducts;
};

export const useMenuProductsNativeAsync = (subId, sectionId, isTemplate) => {
  const [menuProducts, setMenuProducts] = useState([]);
  useEffect(() => {
    let productsRef = firebase.firestore().collection(COLLECTIONS.PRODUCT);
    if (isTemplate) {
      productsRef = productsRef
        .where('menu_section_id', '==', sectionId)
        .where('template', '==', true)
        .where('enable', '==', true)
        .where('subsidiary_id', '==', '');
    } else {
      productsRef = productsRef
        .where('subsidiary_id', '==', subId)
        .where('enable', '==', true)
        .where('menu_section_id', '==', sectionId);
    }
    productsRef.onSnapshot(snap => {
      if (snap.empty) {
        return [];
      }
      const rawMenuProducts = snap.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
      setMenuProducts(rawMenuProducts);
    });
  }, [subId, sectionId, isTemplate]);

  return useMemo(() => {
    return menuProducts
      ? menuProducts
          .map(item => ({
            ...item,
            menu_order: item.menu_order || null
          }))
          .sort((a, b) => (a.menu_order > b.menu_order ? 1 : -1))
      : [];
  }, [menuProducts]);
};
