/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import firebase from 'firebase/app';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useWebView } from 'src/hooks/window/windowHooks';
import Icons from '../../assets/icons';
import Image from '../Image';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '15%',
    outline: 'none'
  },
  contentSmall: {
    padding: '16px 32px 24px',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5%',
    outline: 'none'
  },
  header: {
    textAlign: 'center'
  },
  required: {
    color: 'red'
  },
  image: {
    minHeight: 100,
    maxHeight: 200,
    width: '100%',
    cursor: 'pointer'
  },
  input: {
    marginTop: 15
  }
}));

const UploadFileRestaurant = ({ path, handleClose, handleConfirm }) => {
  const webView = useWebView();
  const classes = useStyles();
  const translation = useTranslation();
  const document = useRef(null);
  const [docTitle, setDocTitle] = useState('');
  const [docDescription, setDocDescription] = useState('');
  const [docName, setDocName] = useState('');
  const [documentFile, setDocumentFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [urlObject, setUrlObject] = useState('');
  const [required, setRequired] = useState(false);
  const [requiredDoc, setRequiredDoc] = useState(false);

  const onButtonClick = () => {
    document.current.click();
  };

  const saveFile = event => {
    setDocumentFile(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setUrlObject(URL.createObjectURL(event.target.files[0]));
    }
  };

  const upload = async () => {
    setUploading(true);
    const extension = documentFile.name.split('.').pop();
    const storageRef = firebase
      .storage()
      .ref(`documents/${path}/${docName}.${extension}`);
    const uploadTask = storageRef.put(documentFile);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      error => {
        console.log(error);
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        handleConfirm(url, { docName, docTitle, docDescription });
      }
    );
  };

  const requiredForm = () => {
    if (docName && urlObject && docTitle && docDescription) {
      upload();
    } else {
      if (!docName) {
        setRequired(true);
      }
      if (!urlObject) {
        setRequiredDoc(true);
      }
      if (!docTitle) {
        setRequired(true);
      }
      if (!docDescription) {
        setRequired(true);
      }
    }
  };

  return (
    <Grid>
      <Card className={webView ? classes.content : classes.contentSmall}>
        <CardHeader
          className={classes.header}
          title={translation('modals.uploadFile.title')}
        />
        <Divider />
        <CardContent>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={12} sm={9}>
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_title')}
                name="title"
                onChange={e => setDocTitle(e.target.value)}
                value={docTitle}
                variant="outlined"
              />
              {required && !docTitle && (
                <Typography className={classes.required}>
                  {translation('document_uploader.title.required')}
                </Typography>
              )}
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_description')}
                name="description"
                onChange={e => setDocDescription(e.target.value)}
                value={docDescription}
                variant="outlined"
              />
              {required && !docDescription && (
                <Typography className={classes.required}>
                  {translation('document_uploader.description.required')}
                </Typography>
              )}
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_name')}
                name="name"
                onChange={e => setDocName(e.target.value)}
                value={docName}
                variant="outlined"
              />
              {required && !docName && (
                <Typography className={classes.required}>
                  {translation('document_uploader.name.required')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <input
                type="file"
                id="bannerFile"
                accept="image/*"
                ref={document}
                style={{ display: 'none' }}
                onChange={event => saveFile(event)}
              />
              <Image
                alt="Nit"
                onClick={() => onButtonClick()}
                style={classes.image}
                src={urlObject || Icons.uploadImage}
              />
              {requiredDoc && (
                <Typography className={classes.required}>
                  {translation('document_uploader.document.required')}
                </Typography>
              )}
            </Grid>
          </Grid>
          {uploading && (
            <Box width="100%" my={3}>
              <LinearProgress />
            </Box>
          )}
          {!uploading && (
            <Box mt={3} width="100%">
              <Grid item container justify="center" spacing={4}>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={requiredForm}
                  >
                    {translation('modals.uploadFile.btn_save')}
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                  >
                    {translation('cancel')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UploadFileRestaurant;
