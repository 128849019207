import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import Icons from '../../../assets/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  containerTitle: {
    padding: theme.spacing(3)
  }
}));

const CardCustomerImg = ({ className, title, img, subTitle }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <Box
        style={{
          backgroundImage: `url("${img || Icons.productDish}")`,
          width: '30%',
          height: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px'
        }}
      />
      <Box flexGrow={1} className={classes.containerTitle}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h3" color="textPrimary">
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

CardCustomerImg.propTypes = {
  className: PropTypes.string
};

export default CardCustomerImg;
