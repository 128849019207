import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'firebase/storage';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useCurrentOwner } from '../../../../hooks/AdminHooks/users/useUsers';

const useStyles = makeStyles(theme => ({
  root: {},
  formCard: {
    width: '100%',
    margin: '20px 0'
  }
}));

const LegalRepresentant = ({ ownerId }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const owner = useCurrentOwner(ownerId);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid>
      {!owner && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {owner && ownerId === owner.id && (
        <Formik
          initialValues={{
            email: owner.email ? owner.email : '',
            first_name: owner.first_name ? owner.first_name : '',
            last_name: owner.last_name ? owner.last_name : '',
            phone: owner.phone_number ? owner.phone_number : '',
            ci_dni: owner.ci ? owner.ci : ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Requerido'),
            full_name: Yup.string().required('Requerido'),
            last_name: Yup.string().required('Requerido'),
            phone: Yup.string().required('Requerido'),
            ci_dni: Yup.string().required('Requerido')
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(translation('notification.correctly_save'), {
                variant: 'success'
              });
            } catch (error) {
              resetForm();
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
              enqueueSnackbar(translation('notification.something_go_wrong'), {
                variant: 'error'
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            // isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item container>
                <Card className={classes.formCard}>
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                  >
                    <CardHeader
                      title={translation(
                        'forms.commerce.legal_representant.title'
                      )}
                    />
                    <Box width={200} mr={2}>
                      <Button
                        disabled
                        color="secondary"
                        // disabled={isSubmitting}
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                      >
                        {translation('forms.save')}
                      </Button>
                    </Box>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Grid item container>
                      <Grid item container direction="column" xs={6} md={6}>
                        <Box mb={3}>
                          <TextField
                            disabled
                            type="text"
                            error={Boolean(
                              touched.first_name && errors.first_name
                            )}
                            fullWidth
                            helperText={touched.first_name && errors.first_name}
                            label={translation(
                              'forms.commerce.legal_representant.name'
                            )}
                            name="first_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.first_name}
                            variant="outlined"
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            disabled
                            type="text"
                            error={Boolean(
                              touched.last_name && errors.last_name
                            )}
                            fullWidth
                            helperText={touched.last_name && errors.last_name}
                            label={translation(
                              'forms.commerce.legal_representant.last_name'
                            )}
                            name="last_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            variant="outlined"
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            disabled
                            type="text"
                            error={Boolean(touched.ci_dni && errors.ci_dni)}
                            fullWidth
                            helperText={touched.ci_dni && errors.ci_dni}
                            label={translation(
                              'forms.commerce.legal_representant.ci'
                            )}
                            name="ci_dni"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.ci_dni}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                      <Grid item container direction="column" xs={6} md={6}>
                        <Box ml={3}>
                          <Box mb={3}>
                            <TextField
                              disabled
                              type="text"
                              error={Boolean(touched.phone && errors.phone)}
                              fullWidth
                              helperText={touched.phone && errors.phone}
                              label={translation(
                                'forms.commerce.legal_representant.phone'
                              )}
                              name="phone"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phone}
                              variant="outlined"
                            />
                          </Box>
                          <Box mb={3}>
                            <TextField
                              disabled
                              type="text"
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email}
                              label={translation(
                                'forms.commerce.legal_representant.email'
                              )}
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              variant="outlined"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default LegalRepresentant;
