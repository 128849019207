import React from 'react';
import { Container, Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import Page from '../../../components/Page';
import DashboardCard from './DashboardCardView/DashboardCard';
import RecentOrdersList from './DashboardOrderList/RecentOrdersList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Page
      className={classes.root}
      title={translation('admin_navBar.principal_dashboard')}
    >
      <Container maxWidth={false}>
        <Header />
        <Divider style={{ marginTop: '10px' }} />
      </Container>
      <DashboardCard />
      <RecentOrdersList />
    </Page>
  );
};

export default Dashboard;
