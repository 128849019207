import React, { useRef, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ReactCrop from 'react-image-crop';
import Box from '@material-ui/core/Box';
import 'react-image-crop/dist/ReactCrop.css';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-multi-lang/lib';
import Icons from '../../assets/icons';
import { COLORS } from '../../constants';
import HoverButtons from './HoverButtons';
import HoverButtonsEmpty from './HoverButtonsEmpty';

const useStyles = makeStyles(() => ({
  card: {
    padding: 20,
    display: 'flex',
    paddingRight: 0
  },
  image: {
    width: '70%'
  },
  details: {
    width: '30%',
    alignItems: 'center',
    display: 'flex'
  },
  options: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex'
  },
  imageButton: {
    width: '100%',
    cursor: 'pointer'
  },
  cardDocument: {
    height: '197px',

    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',

    backgroundPosition: 'center',
    maxWidth: '100%'
  },
  iconDeleteImage: {
    position: 'absolute',
    color: COLORS.red,
    backgroundColor: COLORS.white,
    right: 0,
    zIndex: 1,
    marginTop: '30%',
    margin: 5,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.red
    }
  },
  imageContainer: {
    position: 'relative',
    width: '100%'
  },
  imageContainerHover: {
    position: 'relative',
    width: '100%',
    background: '#000000'
  },
  imageView: {
    width: '100%',
    height: 'auto'
  },
  imageViewHover: {
    width: '100%',
    height: 'auto',

    opacity: 0.6
  },
  iconContainer: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'center'
  },
  otherIcon: {
    backgroundColor: '#56B19E',
    color: 'white',
    padding: 5,
    height: '30px',
    width: '30px',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'black'
    }
  },
  reactIcons: {
    backgroundColor: '#56B19E',
    color: 'white',
    height: '30px',
    width: '30px',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'black'
    }
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  styleText: {
    fontSize: '10px',
    textDecoration: 'underline'
  },
  hoverCard: {
    backgroundColor: 'black'
  },
  containerIcons: {
    backgroundColor: 'rgb(0, 23, 20, 0.7)',
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '6vh',
    height: '6vh',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: 'white'
  },
  smallBox: {
    height: 34
  },
  opacity: {
    opacity: '0.5'
  },
  cursor: {
    cursor: 'pointer'
  },
  box: {
    height: 60,
    marginBottom: '8px'
  }
}));
const pixelRatio = 4;
const ImageInputCrop = ({
  saveImage = () => {},
  defaultImage,
  section,
  onDelete = () => {},
  onDownloadImage = () => {},
  fullImage,
  onOpen = () => {},
  isTemplate,
  enableMenuCashier
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [upImg, setUpImg] = useState();
  const inputFile = useRef(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 60, aspect: 4 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imag, setImag] = useState('');
  const [fullImag, setFullImage] = useState('');
  const [hoverIconOptions, setHoverIconOptions] = useState(false);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const updateImage = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setOpenDialog(true);
    }
  };
  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    setImag(defaultImage);
  }, [defaultImage]);
  useEffect(() => {
    setFullImage(fullImage);
  }, [fullImage]);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const cropTmp = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = cropTmp.width * pixelRatio;
    canvas.height = cropTmp.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      cropTmp.x * scaleX,
      cropTmp.y * scaleY,
      cropTmp.width * scaleX,
      cropTmp.height * scaleY,
      0,
      0,
      cropTmp.width,
      cropTmp.height
    );
  }, [completedCrop]);
  const getResizedCanvas = (canvas, newWidth, newHeight) => {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext('2d');
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };
  const generateURL = (previewCanvas, cropTmp) => {
    if (!cropTmp || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(
      previewCanvas,
      cropTmp.width,
      cropTmp.height
    );

    canvas.toBlob(
      blob => {
        const previewUrl = window.URL.createObjectURL(blob);
        saveImage(previewUrl, blob);
        setImag(previewUrl);
        setOpenDialog(false);
      },
      'image/png',
      1
    );
  };
  return (
    <div>
      <input
        type="file"
        id="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={event => {
          updateImage(event);
          // eslint-disable-next-line no-param-reassign
          event.target.value = '';
        }}
        value={null}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <Box
        className={classes.cardDocument}
        onMouseEnter={() => setHoverIconOptions(true)}
        onMouseLeave={() => setHoverIconOptions(false)}
      >
        <Box textAlign="center" style={{ cursor: 'pointer' }}>
          <Box
            style={{
              backgroundImage: `url(${imag ||
                fullImag ||
                Icons.productDish}),url(${Icons.productDish})`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '197px'
            }}
          />
        </Box>
        {enableMenuCashier && hoverIconOptions && Boolean(imag) && (
          <HoverButtons
            section={section}
            classes={classes}
            isEmpty={!imag}
            onImageSelect={onButtonClick}
            onDownloadImage={() => onDownloadImage(fullImage)}
            onOpen={onOpen}
            onDelete={() => {
              onDelete();
              setFullImage('');
              saveImage(false, false);
              setImag(false);
            }}
            isTemplate={isTemplate}
          />
        )}
        {hoverIconOptions && isTemplate && !imag && !section && (
          <HoverButtonsEmpty
            section={section}
            classes={classes}
            isEmpty={!imag}
            onImageSelect={onButtonClick}
            onOpen={onOpen}
          />
        )}
      </Box>

      <Dialog open={openDialog} maxWidth="md" fullWidth>
        <Box className={classes.card}>
          <Box className={classes.image}>
            <ReactCrop
              src={upImg}
              crop={crop}
              onImageLoaded={onLoad}
              onChange={c => setCrop(c)}
              onComplete={c => {
                setCompletedCrop(c);
              }}
            />
          </Box>
          <Box className={classes.details}>
            <Box className={classes.options}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: '70%',
                  height: 'auto'
                }}
              />
              <ButtonGroup color="primary" style={{ marginTop: 20 }}>
                <Button onClick={() => setOpenDialog(false)}>
                  {translation(
                    'edit.product_create.basic_information.form.cancel'
                  )}
                </Button>
                <Button
                  onClick={() => {
                    generateURL(previewCanvasRef.current, completedCrop);
                  }}
                >
                  {translation(
                    'edit.product_create.basic_information.form.save'
                  )}
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
export default ImageInputCrop;
