import Base from './base';
import { docStatus } from './enum';
/* eslint camelcase: 0 */
export default class Document extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    name = '',
    status = docStatus.PENDING,
    url = '',
    name_file = '',
    title = '',
    description = '',
    type = '',
    extension = ''
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.name = name;
    this.name_file = name_file;
    this.status = status;
    this.url = url;
    this.title = title;
    this.description = description;
    this.type = type;
    this.extension = extension;
  }
}
