import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from '../../../../components/Page';
import Header from './Header';
import PromosBannersEditForm from '../PromosBannersEditView/PromosBannersEditForm';
import promoBannerService from '../../../../services/promoBannerService';
import { useAreas } from '../../../../hooks/AdminHooks/areas/useAreas';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
const PromosBannerDetailView = props => {
  const classes = useStyles();
  const params = useParams();
  const [promoBanner, setPromoBanner] = useState({});
  const [promoBannerItems, setPromoBannerItems] = useState([]);
  const areas = useAreas();
  const cities = useCities();

  const loadBanner = async () => {
    const pBanner = await promoBannerService.getPromoBanner(params.id);
    const pBannerItems = await promoBannerService.getPromoBannerItems(
      params.id
    );

    setPromoBanner(pBanner);
    setPromoBannerItems(
      pBannerItems.map(i => {
        let image = '';
        if (i.image) {
          if (i.image.x400) image = i.image.x400;
          if (i.image.original) image = i.image.original;
        }
        let fullscreen_image = '';
        if (i.fullscreen_image) {
          if (i.fullscreen_image.x400)
            fullscreen_image = i.fullscreen_image.x400;
          if (i.fullscreen_image.original)
            fullscreen_image = i.fullscreen_image.original;
        }
        return {
          ...i,
          image,
          fullscreen_image
        };
      })
    );
  };
  useEffect(() => {
    if (params.id) {
      loadBanner();
    }
    // eslint-disable-next-line
  }, []);

  let titleEdit = false;
  if (params.id) {
    if (!promoBanner.id) return null;
    titleEdit = true;
  }
  if (!areas.length || !cities.length) {
    return <Typography> Cargando... </Typography>;
  }
  return (
    <Page
      className={classes.root}
      title={titleEdit ? 'EDITAR PROMO BANNER' : 'NUEVA PROMO BANNER'}
    >
      <Container maxWidth="lg">
        <Header promoBanner={promoBanner} />
        <Box mt={3}>
          <PromosBannersEditForm
            promoBanner={promoBanner}
            itemsBanner={promoBannerItems}
            areas={areas}
            cities={cities}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PromosBannerDetailView;
