import React from 'react';
import { Grid, Card, CardContent, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import _ from 'lodash';
import NewOrder from '../NewOrder';
import useWindowDimensions from '../../../../hooks/useWindowDimension';
import { useOrderBeenUse } from '../../../../hooks/Order/useOrders';
import ImageIcon from '../../../../components/ImageIcon';
import { useBrowserModal } from './modalStyles';
import { useCurrentUser } from '../../../../hooks/useUser';

const bottomSizeConst = 64;

const NewOrderModal = props => {
  const { order } = props;
  const classes = useBrowserModal();
  const currentUser = useCurrentUser();
  const translation = useTranslation();
  const dimensions = useWindowDimensions();
  const orderBeenUsed = useOrderBeenUse(order.id);

  if (_.isEmpty(orderBeenUsed)) return <></>;

  return (
    <Grid className={classes.content}>
      <Card
        className={classes.cardContainer}
        style={{ maxHeight: dimensions.height - bottomSizeConst }}
      >
        <Box className={classes.annoyCashier}>
          <Grid item container justify="space-between" wrap="nowrap">
            <Grid item container justify="center" alignItems="center">
              <Typography className={classes.headerText}>
                {`${translation('orders_view.modal.exclamation')}`}
              </Typography>
              <ImageIcon
                styles={classes.icon}
                src="/static/merchant/orders/newOrdersWhite.png"
              />
              <Typography className={classes.headerText}>
                {`${translation('orders_view.modal.title')}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <CardContent className={classes.cardContent}>
          <Grid
            item
            container
            className={clsx(classes.orderContainer, classes.threeItemsMargin)}
          >
            <Box
              flex="display"
              className={clsx(classes.orderContainer, classes.yellowBackground)}
            >
              <Box mr={2}>
                <Typography className={classes.deliveryText}>
                  Pedido de:
                </Typography>
              </Box>
              <Box
                mr={2}
                style={{
                  height: '50px',
                  width: '50px'
                }}
              >
                {order?.subisidiary_logo_photo && (
                  <img
                    alt="restaurant logo"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50px',
                      objectFit: 'cover'
                    }}
                    src={order.subisidiary_logo_photo}
                  />
                )}
              </Box>
              <Box>
                <Typography className={classes.deliveryText}>
                  {orderBeenUsed.restaurant_name}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  {`Sucursal: ${orderBeenUsed.subsidiary_name}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <NewOrder order={orderBeenUsed} modal currentUser={currentUser} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NewOrderModal;
