import React from 'react';
import SubsidiaryMergueOptions from './SubsidiaryMergueOptions';
import { useSubsidiariesMergedAndCurrentOrdered } from '../../../../hooks/useRestaurant';

const SubsidiariesMergue = () => {
  const subsidiaries = useSubsidiariesMergedAndCurrentOrdered();

  if (!subsidiaries) return null;

  return (
    <>
      {subsidiaries.map((subsWithRestsMerged, index) => (
        <div key={index}>
          <SubsidiaryMergueOptions
            subsidiaryId={subsWithRestsMerged.subsidiaryId}
            restaurantId={subsWithRestsMerged.restaurantId}
          />
        </div>
      ))}
    </>
  );
};
export default SubsidiariesMergue;
