import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import RestaurantInfo from './restaurantInfo';
import DebtInformation from './debtInformation';
import useUser from '../../../../hooks/useUser';
import PaymentHistory from './paymentHistory';
import SummaryTable from './summaryTable';
import {
  useRestaurantInvoiceWithoutStatusNative,
  usePaymentHistoryNative
} from '../../../../hooks/useInvoice';

const GlobalSummary = ({ restaurant }) => {
  const invoices = useRestaurantInvoiceWithoutStatusNative(restaurant.id || '');
  const paymentHistory = usePaymentHistoryNative(restaurant.id || '');
  const [billingData, setBillingData] = useState(null);
  const [user] = useUser(restaurant?.owner_id);

  useEffect(() => {
    if (!billingData && restaurant && user)
      setBillingData({
        id: restaurant.id,
        business_name: restaurant.invoice_name || restaurant.name || '',
        nit: restaurant.nit ? restaurant.nit : '',
        contact_name:
          restaurant.invoice_contact_name ||
          `${user.first_name} ${user.last_name}` ||
          '',
        phone_number: user ? user.phone_number : '',
        email: restaurant.invoice_email
          ? restaurant.invoice_email
          : user.email || '',
        additional_comments: restaurant.invoice_comments || ''
      });
    // eslint-disable-next-line
  }, [user, restaurant]);

  return (
    <Box p={3} flexDirection="row">
      <Grid container direction="row" spacing={2} justify="center">
        <Grid item xs={12} sm={9}>
          <RestaurantInfo
            restaurant={restaurant}
            billingData={billingData}
            setBillingData={setBillingData}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DebtInformation restaurant={restaurant} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SummaryTable invoices={invoices} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PaymentHistory
            lastPaymentHistory={paymentHistory}
            restaurant={restaurant}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlobalSummary;
