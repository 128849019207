import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/theme/colors';

export const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: 440,
    '&::-webkit-scrollbar': {
      width: 7,
      height: 7
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: COLORS.success
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLORS.successLight,
      borderRadius: '8px'
    }
  }
}));
