import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles,
  InputAdornment
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { login } from 'src/actions/accountActions';
import { useTranslation } from 'react-multi-lang/lib';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Logger from '../../../modules/logs';

const useStyles = makeStyles(() => ({
  root: {},
  eyeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    localStorage.setItem('isNewSession', new Date().getTime());
  }, []);
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(translation('login.email.valid'))
          .max(255)
          .required(translation('login.email.require')),
        password: Yup.string()
          .max(255)
          .required(translation('login.password.require'))
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        localStorage.setItem('isNewSession', new Date().getTime());
        try {
          const response = await login(values.email, values.password)(dispatch);
          if (response) {
            if (response === 'admin')
              setErrors({ submit: translation('login.error_only_merchants') });
            else setErrors({ submit: translation('login.error_access') });
          }
          onSubmitSuccess();
        } catch (error) {
          Logger.error({ error, data: { ...error } });
          const message =
            error.code && error.message
              ? error.message
              : translation('login.without_authorize');
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label={`${translation('login.email.label')}:`}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={`${translation('login.password.label')}:`}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  onClick={() => setShowPassword(!showPassword)}
                  className={classes.eyeIcon}
                  position="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              )
            }}
            type={showPassword ? 'text' : 'password'}
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {translation('login.enter')}
            </Button>
            {errors.submit ===
            'The user account has been disabled by an administrator.' ? (
              <Redirect to="/user-suspended" />
            ) : (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default LoginForm;
