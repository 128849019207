import React from 'react';
import { Box } from '@material-ui/core';
import { AiOutlineEye, AiOutlineDownload } from 'react-icons/ai';
// import { IoMdClose } from 'react-icons/io';
import { BsPencil } from 'react-icons/bs';

const OptionsDocument = ({
  classes,
  handleEdit,
  typeDoc,
  showImageDocument,
  handleDownloadDocument
  // logo,
  // deleteDocument
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      className={classes.containerIcons}
    >
      {/** Edit */}
      <Box
        className={classes.iconOption}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={handleEdit}
      >
        <BsPencil className={classes.styleIconOption} />
      </Box>
      {/** Show image */}
      {typeDoc === 'image' && (
        <Box
          className={classes.iconOption}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={showImageDocument}
        >
          <AiOutlineEye className={classes.styleIconOption} />
        </Box>
      )}
      {/* Download document */}
      <Box
        className={classes.iconOption}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={handleDownloadDocument}
      >
        <AiOutlineDownload className={classes.styleIconOption} />
      </Box>
      {/* Deleted document */}
      {/* {!logo && (
        <Box
          className={classes.iconOption}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={deleteDocument}
        >
          <IoMdClose className={classes.styleIconOption} />
        </Box>
      )} */}
    </Box>
  );
};

export default OptionsDocument;
