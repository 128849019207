import { USER_ROLES } from '../../dto/enum';

const getUserRoles = (user, text = false) => {
  let roles = [];
  const r = user.roles;
  if (r) {
    if (r[USER_ROLES.OWNER]) {
      roles.push({
        value: USER_ROLES.OWNER,
        label: 'Propietario'
      });
    }
    if (r[USER_ROLES.CASHIER]) {
      roles.push({
        value: USER_ROLES.CASHIER,
        label: 'Cajero'
      });
    }
    if (r[USER_ROLES.SUPERVISOR])
      roles.push({
        value: USER_ROLES.SUPERVISOR,
        label: 'Supervisor'
      });
    if (r[USER_ROLES.SUPER_ADMIN])
      roles.push({
        value: USER_ROLES.SUPER_ADMIN,
        label: 'Super Admin'
      });
    if (r[USER_ROLES.ACCOUNTANT]) {
      roles.push({
        value: USER_ROLES.ACCOUNTANT,
        label: 'Contador'
      });
    }

    if (r[USER_ROLES.CUSTOMER_SERVICE]) {
      roles.push({
        value: USER_ROLES.CUSTOMER_SERVICE,
        label: 'Servicio al cliente'
      });
    }
    if (r[USER_ROLES.MARKETING]) {
      roles.push({
        value: USER_ROLES.MARKETING,
        label: 'Marketing'
      });
    }
  }

  if (text) {
    roles = roles
      .map(currentRoles => currentRoles.label)
      .join(', ')
      .trim(' ')
      .trim(',');
    return roles;
  }

  return roles.map(currentRoles => currentRoles.value);
};
export const getUserRole = user => {
  const r = getUserRoles(user);
  if (r.includes(USER_ROLES.SUPER_ADMIN)) return USER_ROLES.SUPER_ADMIN;
  if (r.includes(USER_ROLES.ACCOUNTANT)) return USER_ROLES.ACCOUNTANT;
  if (r.includes(USER_ROLES.CUSTOMER_SERVICE))
    return USER_ROLES.CUSTOMER_SERVICE;
  if (r.includes(USER_ROLES.OWNER)) return USER_ROLES.OWNER;
  if (r.includes(USER_ROLES.CASHIER)) return USER_ROLES.CASHIER;
  if (r.includes(USER_ROLES.SUPERVISOR)) return USER_ROLES.SUPERVISOR;
  if (r.includes(USER_ROLES.MARKETING)) return USER_ROLES.MARKETING;
};

export const isSuperAdmin = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.SUPER_ADMIN);
};
export const isAccountant = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.ACCOUNTANT);
};
export const isCustomerService = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.CUSTOMER_SERVICE);
};
export const isMarketingUser = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.MARKETING);
};

export const isAdmin = user => {
  if (!user) return;
  return (
    isSuperAdmin(user) ||
    isAccountant(user) ||
    isCustomerService(user) ||
    isMarketingUser(user)
  );
};

export const isCashier = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.CASHIER);
};

export const isSupervisor = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  return profiles.includes(USER_ROLES.SUPERVISOR);
};

export const isOwner = user => {
  if (!user) return;
  const profiles = getUserRoles(user);
  // eslint-disable-next-line consistent-return
  return profiles.includes(USER_ROLES.OWNER);
};
export const isMerchant = user => {
  if (!user) return false;
  return isCashier(user) || isSupervisor(user) || isOwner(user);
};
export const blockCashierEdit = (user, restaurant) => {
  const userRole = isCashier(user);
  let blockUser = false;
  if (restaurant) blockUser = restaurant.block_cashier || false;
  return userRole && blockUser;
};

export const getResumeUser = user => {
  return {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    roles: user.roles,
    profile_photo: user.profile_photo ? user.profile_photo : {}
  };
};

export default getUserRoles;
