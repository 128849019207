/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import {
  Grid,
  Select,
  Box,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px'
  },
  minWidth: {
    minWidth: '250px'
  }
}));

const selectDays = [
  {
    id: 'last_day',
    name: 'Último día'
  },
  {
    id: 'last_week',
    name: 'Última semana'
  },
  {
    id: 'last_month',
    name: 'Mes pasado'
  },
  {
    id: 'last_three_months',
    name: 'Últimos 3 meses'
  }
];

const Header = ({
  allSubsidiariesRest,
  handleFilter,
  filter,
  handleSearch
}) => {
  const classes = useStyles();

  if (!allSubsidiariesRest) return null;
  return (
    <>
      <Box className={classes.container} width="100%" my={3}>
        <Grid
          item
          container
          style={{ display: 'flex' }}
          justifyContent="flex-start"
          wrap="nowrap"
          xs={12}
          md={12}
        >
          <Box mx={1.5} className={classes.minWidth}>
            <Typography>Sucursal:</Typography>
            {allSubsidiariesRest && (
              <Select
                native
                value={filter.subsidiary}
                onChange={e =>
                  handleFilter({ id: 'subsidiary_id', value: e.target.value })
                }
                fullWidth
              >
                <option key="" value="">
                  Todas las sucursales
                </option>
                {allSubsidiariesRest.map(sub => (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                ))}
              </Select>
            )}
          </Box>
          <Box mx={1.5} className={classes.minWidth}>
            <Typography>Fecha:</Typography>
            {selectDays && (
              <Select
                native
                value={filter.date_range}
                onChange={e =>
                  handleFilter({ id: 'date_range', value: e.target.value })
                }
                fullWidth
              >
                {selectDays.map(days => (
                  <option key={days.id} value={days.id}>
                    {days.name}
                  </option>
                ))}
              </Select>
            )}
          </Box>
          <Box display="flex" alignItems="center" ml={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Header;
