import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../constants';

export const usePromos = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PROMOS,
      where: [['enable', '==', true]],
      storeAs: COLLECTIONS.PROMOS
    }
  ]);
  const promos = useSelector(
    state => state.firestore.ordered[COLLECTIONS.PROMOS]
  );

  if (!promos) return [];
  return promos;
};
export const usePromoBanners = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PROMOS_BANNER,
      where: [['enable', '==', true]],
      storeAs: `${COLLECTIONS.PROMOS_BANNER}_list`
    }
  ]);
  const promosBanner = useSelector(
    state => state.firestore.ordered[`${COLLECTIONS.PROMOS_BANNER}_list`]
  );

  if (!promosBanner) return [];
  return promosBanner;
};
export const usePromoBanner = id => {
  useFirestoreConnect({
    collection: COLLECTIONS.PROMOS_BANNER,
    doc: id
  });
  const promoBannerStore = useSelector(state => {
    if (
      state.firestore.data[COLLECTIONS.PROMOS_BANNER] &&
      state.firestore.data[COLLECTIONS.PROMOS_BANNER][id]
    ) {
      return state.firestore.data[COLLECTIONS.PROMOS_BANNER][id];
    }
  });
  return promoBannerStore;
};

export const usePromoBannerItems = id => {
  const query = {
    collection: COLLECTIONS.PROMOS_BANNER,
    doc: id
  };
  if (id) {
    query.subcollections = [{ collection: COLLECTIONS.BANNER_ITEM }];
    query.storeAs = 'bannerItems';
  }
  useFirestoreConnect(query);
  const promoBannerItemsStore = useSelector(state => {
    if (state.firestore.ordered.bannerItems) {
      return state.firestore.ordered.bannerItems;
    }
  });
  if (!id) return [];
  if (promoBannerItemsStore) return promoBannerItemsStore;
  return [];
};

// useFirestoreConnect({
//   collection: COLLECTIONS.PROMOS_BANNER,
//   doc: params.id,
//   subcollections: [{ collection: COLLECTIONS.BANNER_ITEM }],
//   storeAs: 'bannerItems'
// });
