import { roundNumber } from '../numberUtils';

export const calculatePricePickup = (
  priceDelivery,
  percentageDiscountPickup
) => {
  const pricePickup =
    priceDelivery - priceDelivery * (percentageDiscountPickup / 100);
  return roundNumber(pricePickup);
};
