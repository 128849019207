import axios from 'src/utils/axios';

class RestaurantService {
  registerRestaurant = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/restaurant/register', data)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            reject(data.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  completeRegisterRestaurant = data =>
    new Promise((resolve, reject) => {
      axios
        .post('/restaurant/completeRegister', data)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => {
          // TODO: Refactor to support eslint 'data' variable
          if (data.success) {
            resolve(data.message);
          } else {
            reject(data.message);
          }
        })
        .catch(error => {
          reject(error.message);
        });
    });

  restaruantAlreadyRegistered = id =>
    new Promise((resolve, reject) => {
      axios
        .get(`/restaurant/hasRegistered/${id}`)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => resolve(data))
        .catch(error => {
          reject(error.message);
        });
    });
}

const restaurantService = new RestaurantService();

export default restaurantService;
