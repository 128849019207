import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-multi-lang/lib';
import { withStyles } from '@material-ui/core/styles';
import useUser from '../../../hooks/useUser';
import OrderExtraInfo from './OrderExtraInfo';
import ProductTable from './Table/ProductTable';
import { usePromotion } from '../../../hooks/Promotions/usePromotions';
import { ORDER_STATUS, PAYMENT_METHOD } from '../../../dto/enum';
import { useOrderProductsTableStyles } from './styles';
import { useRestaurant } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import env from '../../../env';

const ExpansionPanel = withStyles({
  root: {
    marginTop: -5,
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiExpansionPanel);
function OrderProductsTable({
  order,
  showExtraInfo = false,
  canEdit = false,
  headerCaption,
  extraHeader,
  rejectProducts = () => {},
  rejectOrder = () => {},
  children,
  className,
  adminView,
  showExtraHeaderInfo = false,
  displayDescription = true,
  ...rest
}) {
  const classes = useOrderProductsTableStyles();
  const translations = useTranslation();
  const [user] = useUser(order.user_id);
  const promotion = usePromotion(order?.promotionId);
  const currentRestaurant = useRestaurant(order.restaurant_id);
  const isPaid =
    order.status === ORDER_STATUS.COMPLETED ||
    order.status === ORDER_STATUS.DELIVERING;
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  let userPhone = order.user_phone || '-';
  userPhone = userPhone.includes('+')
    ? userPhone.substr(4)
    : userPhone.substr(3);

  const sendWhatsApp = () => {
    window.open(
      `${env.REACT_APP_WHATSAPP}${order.user_phone}?text=Gracias por utilizar XPRESS`,
      '_blank'
    );
  };
  const isOnlinePayment = useMemo(() => {
    if (order) {
      if (
        order.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
        order.payment_method === PAYMENT_METHOD.ONLINE_QR
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [order]);

  if (!user) {
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={2} />
      </Card>
    );
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={isXS ? null : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.expansionHeader}
        >
          <Box width="100%">
            <Grid className={classes.headerContainer}>
              <Grid item container>
                <Typography className={clsx(classes.orderFromText)}>
                  {translations('orders.details.order_table.order_for')}
                  <span>&nbsp;&nbsp;</span>
                  <strong>
                    {user.first_name || '-'} {user.last_name || ''}
                  </strong>
                </Typography>
              </Grid>
              {!isOnlinePayment && (
                <Box className={classes.payment}>
                  <Typography className={clsx(classes.paymentCard)}>
                    {translations(
                      `orders_view.card.${
                        isPaid ? 'payment_done' : 'payment_pending'
                      }`
                    )}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Box width="100%">{headerCaption}</Box>
            {extraHeader}
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
        >
          {currentRestaurant?.show_user_phone && (
            <>
              <Box style={{ padding: '5px 0px 10px 16px', display: 'flex' }}>
                <Typography className={clsx(classes.orderFromText)}>
                  {translations('orders.details.order_table.user_phone')}
                  <span>&nbsp;&nbsp;</span>
                  <strong>{userPhone || '-'}</strong>
                </Typography>
                {userPhone && (
                  <Button
                    style={{ height: 30, marginLeft: '20px' }}
                    onClick={() => sendWhatsApp()}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    <WhatsAppIcon />
                  </Button>
                )}
              </Box>
              <Divider />
            </>
          )}
          <Box width="100%">
            {showExtraHeaderInfo && (
              <Box ml={2}>
                <OrderExtraInfo order={order} />
              </Box>
            )}
            <ProductTable
              order={{ ...order, promotion }}
              canEdit={canEdit}
              rejectOrder={rejectOrder}
              rejectProducts={rejectProducts}
            />
            {displayDescription && order.additional_description && !adminView && (
              <>
                <Box mt={2} mb={2}>
                  <Typography variant="h6" component="h6">
                    {translations('orders.details.additional_reference.title')}
                  </Typography>
                  <Typography variant="body1">
                    {order.additional_description}
                  </Typography>
                </Box>
              </>
            )}
            {children}
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
}

OrderProductsTable.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderProductsTable;
