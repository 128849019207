import React from 'react';
import { Card } from '@material-ui/core';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-multi-lang/lib';
import useUser from '../../../hooks/useUser';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const AdditionalReferences = ({ order }) => {
  const classes = useStyles();
  const [user] = useUser(order.user_id);
  const translations = useTranslation();

  if (!user) {
    return null;
  }
  return (
    <Card className={clsx(classes.root)}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="h4" variant="h4">
            {translations('orders.details.additional_reference.title')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box width="100%">
            <Grid container direction="row">
              <Grid item xs={12} md={6} xl={6}>
                <Typography variant="h6" color="textPrimary">
                  {order.additional_description
                    ? order.additional_description
                    : translations(
                        'orders.details.additional_reference.references'
                      )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
};

export default AdditionalReferences;
