import Resizer from 'react-image-file-resizer';

export const FILE_TYPES = {
  IMAGE: 'image/*',
  ANY: ''
};

const FileUtils = {
  /**
   *
   * @param {FILE_TYPES | string} fileTypes
   * @returns {Promise<File>}
   */
  async selectSingleFile(fileTypes = FILE_TYPES.ANY) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', fileTypes);
    input.click();
    return new Promise(resolve => {
      input.onchange = event => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        resolve(file);
      };
      input.onabort = () => resolve(null);
    });
  },
  /**
   *
   * @param {File} imageFile
   * @returns {Promise<File>}
   */
  async resizeImage(imageFile) {
    const compressedBlob = new Promise(resolve => {
      Resizer.imageFileResizer(
        imageFile,
        2048,
        2048,
        'JPEG',
        70,
        0,
        uri => {
          resolve(uri);
        },
        'blob'
      );
    });
    return this.blobToFile(compressedBlob, imageFile.name);
  },
  /**
   *
   * @param {Blob | any} blob
   * @param {string} fileName
   * @returns {File}
   */
  blobToFile(blob, fileName) {
    const newBlob = blob;
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return blob;
  },
  /**
   *
   * @param {File} file
   * @returns {string}
   */
  getFileUrl(file) {
    if (file) {
      return URL.createObjectURL(file);
    }
    return '';
  }
};

export default FileUtils;
