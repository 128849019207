/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from 'src/utils/bytesToSize';
import firebase from 'firebase/app';
import 'firebase/storage';
import Alert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dropZoneDisabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    cursor: 'pointer'
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  listItem: {
    flexWrap: 'wrap'
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function FilesDropzone({
  className,
  labels,
  maxImages = null,
  setImage,
  setDoc = () => {},
  onFinish,
  type = 'image',
  path,
  icon = '/static/images/undraw_add_file2_gvbb.svg',
  withName = false,
  saveDocumentDB = () => {},
  maxDimensions,
  maxSize,
  defaultName,
  disableTextField = false,
  isDocument = false,
  setDocumentUrl,
  documentId,
  ...rest
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [status, setLocalStatus] = useState('');
  const [message, setMessage] = useState('');
  const [localError, setLocalError] = useState('');
  const [dimensionError, setDimensionError] = useState([]);
  const translation = useTranslation();
  const formRef = useRef();
  const validImageExtensions = ['image/jpg', 'image/jpeg'];

  useEffect(() => {
    if (maxImages === 1 && files.length === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (files.length) {
      const complete = files.every(f => f.complete);
      if (complete) {
        setLocalStatus('completed');
        setMessage('Se guardo correctamente');
      }
    }
    // eslint-disable-next-line
  }, [files]);

  const validateImageSizeAndFormat = image => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      setDimensionError('');
      const URL = window.URL || window.webkitURL;
      if (image) {
        const img = new Image();
        const objectUrl = URL.createObjectURL(image);
        // eslint-disable-next-line func-names
        img.onload = function() {
          const messageDimensions = [];
          if (maxDimensions) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (maxDimensions.minWidth && maxDimensions.minWidth > this.width) {
              messageDimensions.push(
                `Ancho mínimo de ${maxDimensions.minWidth} px`
              );
            }
            // eslint-disable-next-line react/no-this-in-sfc
            if (maxDimensions.maxWidth && maxDimensions.maxWidth < this.width) {
              messageDimensions.push(
                `Ancho maximo de ${maxDimensions.maxWidth} px`
              );
            }
            if (
              maxDimensions.minHeight &&
              // eslint-disable-next-line react/no-this-in-sfc
              maxDimensions.minHeight > this.height
            ) {
              messageDimensions.push(
                `Alto mínimo de ${maxDimensions.minHeight} px`
              );
            }
            if (
              maxDimensions.maxHeight &&
              // eslint-disable-next-line react/no-this-in-sfc
              maxDimensions.maxHeight < this.height
            ) {
              messageDimensions.push(
                `Alto maximo de ${maxDimensions.maxHeight} px`
              );
            }
          }
          if (!validImageExtensions.includes(image.type)) {
            const format = image.type.split('/');
            messageDimensions.push(
              `El formato ${format[1] ? format[1] : image.type} no es aceptado.`
            );
          }
          if (maxSize) {
            if (image.size / 1024 > maxSize) {
              messageDimensions.push(
                `El tamaño ${Math.round(
                  image.size / 1024
                )}KB es mayor a ${maxSize}KB  no es aceptado.`
              );
            }
          }
          if (messageDimensions.length) {
            setDimensionError(messageDimensions);
            resolve(false);
          } else {
            resolve(true);
          }
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
    });
  };

  const handleDrop = useCallback(async acceptedFiles => {
    const fileStructure = acceptedFiles.map(file => ({
      file,
      progress: 0,
      complete: false
    }));
    if (type === 'image') {
      const valid = await validateImageSizeAndFormat(fileStructure[0].file);
      if (!valid) return;
    }

    setFiles(prevFiles => [...prevFiles].concat(fileStructure));
    // eslint-disable-next-line
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop
  });

  const uploadFile = ({ file }, index, subPath = '') => {
    const withOutExtension = path.includes('logo') || path.includes('banner');
    const storageRef = firebase.storage().ref();
    const extension = file.name.split('.').pop();
    const referenceImg = `${path}${subPath || ''}${index === 0 ? '' : index}`;
    const reference = withOutExtension
      ? referenceImg
      : `${referenceImg}.${extension}`;
    const imageRef = storageRef.child(reference);
    const uploadTask = imageRef.put(file);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const items = [...files];
        items[index].progress = progress;
        setFiles(items);
      },
      // eslint-disable-next-line no-shadow
      error => {
        // eslint-disable-next-line default-case
        switch (error.code) {
          case 'storage/unauthorized':
            setLocalError('No tiene permiso autorizado');
            break;
          case 'storage/canceled':
            setLocalError('Se cancelo');
            break;
          case 'storage/unknown':
            setLocalError('Tuvimos un problema al subir');
            break;
        }
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        if (isDocument) setDocumentUrl(url);
        if (type === 'image') setImage(url);
        else setDoc(files[index].file);
        const items = [...files];
        items[index].complete = true;
        setFiles(items);
      }
    );
  };
  const handleSave = async () => {
    let subPath = '';
    setLocalStatus('uploading');
    if (withName) {
      await formRef.current.submitForm();
      const { isValid } = formRef.current;
      if (isValid) {
        const id = await saveDocumentDB(
          formRef.current.values.name,
          formRef.current.values.description,
          files[0].file.name,
          type
        );
        subPath = `/${id}`;
      } else {
        setLocalStatus('');
        return;
      }
    }

    files.forEach((file, index) => uploadFile(file, index, subPath));
  };

  const inputProps = {
    disabled,
    multiple: maxImages !== 1
  };

  if (type === 'image') {
    inputProps.accept = validImageExtensions;
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dropZoneDisabled]: disabled,
          [classes.dragActive]: false
        })}
        {...getRootProps()}
      >
        <input {...getInputProps(inputProps)} />
        <div>
          <img alt="Select file" className={classes.image} src={icon} />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            {labels.selectFiles ? labels.selectFiles : 'Select files'}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {labels.instructions ? (
                labels.instructions
              ) : (
                <>
                  {'Drop files here or click '}
                  <Link underline="always">browse</Link>
                  {' thorough your machine'}
                </>
              )}
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        <>
          {withName && (
            <Box mt={2}>
              <Formik
                innerRef={formRef}
                initialValues={{
                  description: '',
                  name: defaultName || ''
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(
                    translation('document_uploader.title.required')
                  )
                })}
                onSubmit={async (
                  values,
                  // eslint-disable-next-line no-shadow
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    // console.log('set values', values);
                  } catch (error) {
                    setStatus({ success: false });
                    setErrors({ submit: error.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      label={translation('document_uploader.title.title')}
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableTextField}
                      variant="outlined"
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      name="name"
                      type="name"
                      value={values.name}
                    />
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label={translation('document_uploader.description.title')}
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="description"
                      disabled={disableTextField}
                      value={values.description}
                      variant="outlined"
                    />
                  </form>
                )}
              </Formik>
            </Box>
          )}

          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.listItem}>
              {files.map((file, i) => (
                <ListItem
                  className={classes.listItem}
                  divider={i < files.length - 1}
                  key={i}
                >
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.file.size)}
                  />
                  <Box width="100%" mb={1}>
                    <LinearProgress
                      variant="determinate"
                      value={file.progress}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          {!!message && (
            <Box m={1}>
              <Alert severity="success">{message}</Alert>
            </Box>
          )}
          {!!localError && (
            <Box m={1}>
              <Alert severity="error">{localError}</Alert>
            </Box>
          )}
          <div className={classes.actions}>
            {status === 'completed' && (
              <Button onClick={onFinish} size="small">
                Aceptar
              </Button>
            )}
            {status === '' && (
              <>
                <Button onClick={handleRemoveAll} size="small">
                  {labels.removeAll ? labels.removeAll : 'Remove all'}
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={handleSave}
                >
                  {labels.uploadAll ? labels.uploadAll : 'Upload files'}
                </Button>
              </>
            )}
          </div>
        </>
      )}
      {dimensionError.length > 0 && (
        <Box m={1}>
          <Alert severity="error">
            Por favor verificar :
            <ul>
              {dimensionError.map(e => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.object,
  maxImages: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.string,
  setImage: PropTypes.func,
  onFinish: PropTypes.func,
  path: PropTypes.string.isRequired
};

export default FilesDropzone;
