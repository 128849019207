import Base from './base';
import { categoryOptions } from './enum';
/* eslint camelcase: 0 */
export default class Product extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    subsidiary_id = '',
    restaurant_id = '',
    name = '',
    cooking_time = 10,
    category = categoryOptions[0],
    unit_price = 0,
    complementary_products = [],
    menu_section_id = '',
    pre_packed = false,
    availability = false,
    description = '',
    photo = {
      original: '',
      x40: '',
      x80: '',
      x160: '',
      x200: '',
      x400: '',
      x800: ''
    },
    menu_order = 0,
    gallery_id = ''
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.subsidiary_id = subsidiary_id;
    this.restaurant_id = restaurant_id;
    this.name = name;
    this.cooking_time = cooking_time;
    this.category = category;
    this.unit_price = unit_price;
    this.complementary_products = complementary_products;
    this.menu_section_id = menu_section_id;
    this.pre_packed = pre_packed;
    this.availability = availability;
    this.description = description;
    this.photo = photo;
    this.menu_order = menu_order;
    this.gallery_id = gallery_id;
  }
}
