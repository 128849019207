import { useState } from 'react';

export default function useToggle(initialState = false) {
  const [toggleState, setToggleState] = useState(initialState);

  function toggleOn() {
    setToggleState(true);
  }
  function toggleOff() {
    setToggleState(false);
  }
  function toggle() {
    setToggleState(!toggleState);
  }

  return [toggleState, toggle, { toggleOn, toggleOff }];
}
