import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  Avatar,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpressTable from '../../../../components/Table/ExpressTable';
import { useDealers } from '../../../../hooks/AdminHooks/dealers/useDealers';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

const Results = () => {
  const translation = useTranslation();
  const classes = useStyles();
  const dealers = useDealers();

  const tableColumns = [
    {
      id: 'dealersName',
      filterName: 'name',
      label: translation('admin_dealers.table.name'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'delivering_status',
      label: translation('admin_dealers.delivery_status'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'status',
      label: translation('admin_dealers.table.status'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'actions',
      label: translation('admin_dealers.table.actions'),
      maxWidth: 100,
      align: 'right'
    }
  ];
  let tableContent = [];

  const getLabel = status => {
    switch (status) {
      case 'APPLIED':
        return translation('admin_dealers.driver_aplication_status.applied');
      case 'ACCEPTED':
        return translation('admin_dealers.driver_aplication_status.accepted');
      case 'REJECTED':
        return translation('admin_dealers.driver_aplication_status.rejected');
      case 'SUSPENDED':
        return translation('admin_dealers.driver_aplication_status.suspended');
      case 'EXPIRED_LICENSE':
        return translation(
          'admin_dealers.driver_aplication_status.expired_licence'
        );
      case 'EXPIRED_SOAT':
        return translation(
          'admin_dealers.driver_aplication_status.expired_soat'
        );
      default:
        break;
    }
  };

  const getDeliveryLabel = status => {
    switch (status) {
      case 'UNAVAILABLE':
        return translation(
          'admin_dealers.delivery_aplication_status.unavailable'
        );
      case 'WAITING_FOR_ORDERS':
        return translation(
          'admin_dealers.delivery_aplication_status.waiting_for_orders'
        );
      case 'GOING_TO_PICK':
        return translation(
          'admin_dealers.delivery_aplication_status.going_to_pick'
        );
      case 'DELIVERING':
        return translation(
          'admin_dealers.delivery_aplication_status.delivering'
        );
      default:
        return translation(
          'admin_dealers.delivery_aplication_status.delivering'
        );
    }
  };

  const dealersName = (photo, firstName, lastName) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar alt="Ted talk" className={classes.avatar} src={photo} />
        </ListItemAvatar>
        <Typography variant="body2" color="textSecondary">
          {firstName} {lastName}
        </Typography>
      </ListItem>
    );
  };

  const linkToSub = item => {
    return (
      <Box>
        <Link
          style={{
            color: 'black',
            textDecorationLine: 'underline',
            marginRight: '8px'
          }}
          component={RouterLink}
          to={`/admin/dealers/${item.id}`}
        >
          {translation('admin_dealers.table.link').toUpperCase()}
        </Link>
      </Box>
    );
  };

  if (dealers) {
    tableContent = dealers.map(item => ({
      id: item.id,
      name: `${item.first_name} ${item.last_name}`,
      dealersName: dealersName(
        item.profile_photo ? item.profile_photo.original : '',
        item.first_name || '',
        item.last_name || ''
      ),
      delivering_status: item.delivering_status
        ? getDeliveryLabel(item.delivering_status)
        : '',
      status: item.driver_information
        ? getLabel(item.driver_information.status)
        : '',
      actions: linkToSub(item)
    }));
  }

  return (
    <>
      <Grid>
        <ExpressTable columns={[...tableColumns]} rows={[...tableContent]} />
      </Grid>
    </>
  );
};

export default Results;
