import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { Container, Box, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import Header from './Header';
import TabsContainer from './tabsContainer';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const CentralPayments = () => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Page
      className={classes.root}
      title={translation('central_payments.title')}
    >
      <Container>
        <Header />
        <Box width="100%">
          <TabsContainer />
        </Box>
        {/* <Box mt={3}>
          <DetailsView
            restaurant={restaurant}
            invoices={invoices}
            paymentHistory={PaymentHistory}
          />
        </Box> */}
      </Container>
    </Page>
  );
};

export default CentralPayments;
