import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { useAllRestaurants } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllSubsidiaries } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';

const Commerces = () => {
  const history = useHistory();
  const restaurants = useAllRestaurants();
  const subsidiaries = useAllSubsidiaries();
  const translation = useTranslation();
  let pendingAmount = 0;
  let activeAmount = 0;
  let rejectedAmount = 0;
  let suspendedAmount = 0;
  let archivedAmount = 0;

  const goTo = dir => {
    history.push(`/admin/commerce/${dir}`);
  };

  if (restaurants) {
    pendingAmount = restaurants.filter(
      item =>
        item.pendings && (item.pendings.docs || item.pendings.subsidiaries)
    ).length;
  }

  if (subsidiaries) {
    subsidiaries.forEach(sub => {
      switch (sub.register_status) {
        case 'ACTIVE':
          activeAmount++;
          break;
        case 'SUSPENDED':
          suspendedAmount++;
          break;
        case 'ARCHIVED':
          archivedAmount++;
          break;
        case 'REJECTED':
          rejectedAmount++;
          break;
        default:
          break;
      }
    });
  }

  return (
    <Grid container>
      <Box m={3}>
        <Card>
          <CardHeader title={translation('admin_commerces.cards.pendings')} />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {pendingAmount}
                </Typography>
              </Grid>
              <Grid>
                <Button color="secondary" onClick={() => goTo('pending')}>
                  {translation('admin_commerces.cards.see_rest')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title={translation('admin_commerces.cards.actives')} />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {activeAmount}
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('active')}>
                  {translation('admin_commerces.cards.see_rest')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title={translation('admin_commerces.cards.rejected')} />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {rejectedAmount}
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('rejected')}>
                  {translation('admin_commerces.cards.see_rest')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title={translation('admin_commerces.cards.suspended')} />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {suspendedAmount}
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('suspended')}>
                  {translation('admin_commerces.cards.see_rest')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title={translation('admin_commerces.cards.archived')} />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  {archivedAmount}
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('archived')}>
                  {translation('admin_commerces.cards.see_rest')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default Commerces;
