import { makeStyles } from '@material-ui/core';

export const useBrowserModal = makeStyles(theme => ({
  root: {},
  content: {
    marginLeft: '15%',
    marginRight: '15%',
    marginTop: '2%',
    outline: 'none',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 32,
      marginRight: 32
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2)
    }
  },
  cardContainer: {
    overflow: 'scroll'
  },
  Header: {
    fontSize: 32,
    textAlign: 'center'
  },
  deliveryText: {
    fontSize: 20,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  yellowBackground: {
    backgroundColor: theme.palette.expressColors.yellowCake
  },
  threeItemsMargin: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  orderContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8,
    alignItems: 'center',
    padding: 12
  },
  annoyCashier: {
    padding: '10px 0',
    animation: '$blink 1s infinite',
    borderRadius: 8,
    marginBottom: 10,
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      height: 60,
      margin: theme.spacing(2),
      marginBottom: 0
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderRadius: 0,
      height: 48
    }
  },
  '@keyframes blink': {
    '0%': {
      backgroundColor: theme.palette.background.secondary,
      opacity: 1
    },
    '50%': {
      backgroundColor: theme.palette.background.secondary,
      opacity: 1
    },
    '100%': {
      backgroundColor: theme.palette.background.primary,
      opacity: 1
    }
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  icon: {
    width: 20,
    height: 25,
    [theme.breakpoints.down('sm')]: {
      width: 15,
      height: 21
    },
    [theme.breakpoints.down('xs')]: {
      width: 15,
      height: 23
    }
  },
  cardContent: {
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(2),
      padding: 0
    }
  }
}));
