import React, { useState, useEffect, useMemo } from 'react';
import { Select, MenuItem, Box, Button } from '@material-ui/core';
import moment from 'moment';
import { getDate, generateMonths, generateYears } from 'src/utils/date';
import { useTranslation } from 'react-multi-lang/lib';
import exportToCsv from 'src/utils/csv';
import sortBy, { getBoundaries } from 'src/utils/array';
import ReportCard from './ReportCard';
import { appFilterReport } from '../../../hooks/Order/useOrders';
import WithLoading from '../../../components/WithLoading';

const BoxLoading = WithLoading(Box);

function ReportContent({
  subsidiaryId,
  restaurantId,
  completedCanceledOrders
}) {
  const translation = useTranslation();
  const [totalOrders, setTotalOrder] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('day');
  const [currentDate] = useState(moment().toDate());
  const [monthSelected, setMonthSelected] = useState(currentDate);
  const [yearSelected, setYearSelected] = useState(currentDate.getFullYear());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filterOptionSelected === 'day') {
      setLoading(true);
      setTotalOrder(completedCanceledOrders);
      setLoading(false);
    } else {
      setLoading(true);
      appFilterReport(
        false,
        filterOptionSelected,
        monthSelected,
        yearSelected,
        subsidiaryId,
        restaurantId
      ).then(r => {
        setTotalOrder(r);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [filterOptionSelected, monthSelected, yearSelected, completedCanceledOrders]);

  function getMomentFilters() {
    let functionMomentFilter;
    switch (filterOptionSelected) {
      case 'day':
        functionMomentFilter = order => {
          const orderDate = moment(
            getDate(order.accepted_time || order.delivery_time)
          );
          return moment(currentDate).isSame(orderDate, 'day');
        };
        break;
      case 'week':
        functionMomentFilter = order => {
          const orderDate = moment(
            getDate(order.accepted_time || order.delivery_time)
          );
          const actualDate = moment(currentDate);
          const initialDate = moment(
            new Date(actualDate.year(), actualDate.month(), actualDate.date())
          ).subtract(7, 'd');
          return orderDate.isBetween(initialDate, actualDate);
        };
        break;
      case 'month':
        functionMomentFilter = order => {
          const orderDate = moment(
            getDate(order.accepted_time || order.delivery_time)
          );
          return moment(monthSelected).isSame(orderDate, 'month');
        };
        break;
      case 'year':
        functionMomentFilter = order => {
          const orderDate = moment(
            getDate(order.accepted_time || order.delivery_time)
          );
          return orderDate.year() === yearSelected;
        };
        break;
      default:
        functionMomentFilter = () => true;
        break;
    }
    return functionMomentFilter;
  }

  const filteredOrders = useMemo(() => {
    const momentFilter = getMomentFilters();
    return totalOrders
      .filter(order => momentFilter(order))
      .sort(sortBy('created_at'));
    // eslint-disable-next-line
  }, [filterOptionSelected, totalOrders]);

  const yearsResult = useMemo(() => {
    const boundaries = getBoundaries(totalOrders, order =>
      getDate(order.accepted_time || order.delivery_time).getFullYear()
    );
    if (!boundaries) {
      return [currentDate.getFullYear()];
    }
    return generateYears({
      min: boundaries.min,
      max: currentDate.getFullYear()
    });
    // eslint-disable-next-line
  }, [totalOrders]);

  async function exportData() {
    const data = filteredOrders;
    if (!data) return null;

    const formattedData = data
      .filter(order => !!order.qualification)
      .map(order => {
        const formattedValue = {};
        formattedValue.username = order.invoice_name;
        formattedValue.notes =
          order.qualification && order.qualification.options
            ? order.qualification.options.join('-')
            : '-';
        formattedValue.anotations = order.qualification.comment
          ? order.qualification.comment
          : '-';
        formattedValue.liked = order.qualification.liked
          ? translation('notification.yes')
          : translation('admin_subsidiary.table.header.no');

        return Object.values(formattedValue);
      });

    const headers = [
      translation('earning.table.user'),
      translation('earning.table.notes'),
      translation('earning.table.comments'),
      translation('earning.satifactory_experience')
    ];

    exportToCsv(
      [headers, ...formattedData],
      `qualifications_report_${moment().format('YYYY_MM_DD_HH_mm')}`
    );
    return Promise.resolve();
  }

  if (!subsidiaryId && !restaurantId) return null;
  return (
    <>
      <Box
        marginTop="12px"
        width={450}
        display="flex"
        justifyContent="space-between"
      >
        <Select
          value={filterOptionSelected}
          onChange={ev => setFilterOptionSelected(ev.target.value)}
        >
          <MenuItem value="day">{translation('earning.report.day')}</MenuItem>
          <MenuItem value="week">{translation('earning.report.week')}</MenuItem>
          <MenuItem value="month">
            {`${translation('earning.report.month')} `}
          </MenuItem>
          <MenuItem value="year">{translation('earning.report.year')}</MenuItem>
        </Select>
        {filterOptionSelected === 'month' && (
          <Select
            value={moment(monthSelected).format('YYYY MM')}
            onChange={ev => {
              setMonthSelected(moment(ev.target.value, 'YYYY MM').toDate());
            }}
          >
            {filterOptionSelected === 'month' &&
              generateMonths().map(week => (
                <MenuItem
                  key={Math.random()}
                  value={moment(week).format('YYYY MM')}
                >
                  {moment(week).format('MMMM')}
                </MenuItem>
              ))}
          </Select>
        )}
        {filterOptionSelected === 'year' && (
          <Select
            value={yearSelected}
            onChange={ev => {
              setYearSelected(ev.target.value);
            }}
          >
            {filterOptionSelected === 'year' &&
              yearsResult.map(year => (
                <MenuItem key={Math.random()} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        )}
        <Button color="primary" variant="contained" onClick={exportData}>
          {translation('earning.export')}
        </Button>
      </Box>
      <BoxLoading isLoading={loading}>
        <ReportCard orders={filteredOrders} date={currentDate} />
      </BoxLoading>
    </>
  );
}

export default ReportContent;
