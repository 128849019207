/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Divider,
  InputAdornment,
  Link,
  makeStyles,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { useTranslation } from 'react-multi-lang';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { USER_STATUS } from '../../../../dto/enum';

function applyFilters(users, query, filters) {
  return users.filter(user => {
    let matches = true;

    if (query) {
      const properties = ['email', 'first_name', 'last_name'];
      let containsQuery = false;

      properties.forEach(property => {
        if (
          user[property] &&
          user[property].toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
}

function applyPagination(users, page, limit) {
  return users.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySort(users, sort) {
  const { orderBy, order } = sort;
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = users.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

function Results({ className, users, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  const history = useHistory();
  const { status } = useParams();
  const sortOptions = [
    {
      orderBy: 'first_name',
      order: 'asc'
    }
  ];
  const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0]);
  const [filters, setFilters] = useState({
    status: null
  });

  useEffect(() => {
    const value = status || 'all';
    const updatedFilters = {
      ...filters,
      status: null
    };

    delete updatedFilters.status;
    if (value === 'active') {
      updatedFilters.status = USER_STATUS.ACTIVE;
    }
    if (value === 'suspended') {
      updatedFilters.status = USER_STATUS.SUSPENDED;
    }
    if (value === 'archived') {
      updatedFilters.status = USER_STATUS.ARCHIVED;
    }
    setFilters(updatedFilters);
    setQuery('');
    setCurrentTab(value);
    // eslint-disable-next-line
  }, [status]);

  const tabs = [
    {
      value: 'all',
      label: translation('customer_edit.all')
    },

    {
      value: 'active',
      label: translation('admin_navBar.active')
    },
    {
      value: 'suspended',
      label: translation('admin_navBar.suspended')
    },
    {
      value: 'archived',
      label: translation('admin_navBar.archived')
    }
  ];

  const handleTabsChange = (event, value) => {
    if (value !== 'all') {
      history.push(`/admin/users/${value}`);
    } else {
      history.push(`/admin/users`);
    }
  };

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = value => {
    const order = {
      orderBy: value,
      order: sort.order === 'asc' ? 'desc' : 'asc'
    };
    if (value !== sort.orderBy) {
      order.order = sort.order;
    }
    setSort(order);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const filteredUsers = applyFilters(users, query, filters);
  const sortedUsers = applySort(filteredUsers, sort);
  const paginatedUsers = applyPagination(sortedUsers, page, limit);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Divider />
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder={translation('personal.search')}
            value={query}
            variant="outlined"
          />
          <Box flexGrow={1} />
        </Box>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sort.orderBy === 'first_name'}
                      direction={sort.order}
                      onClick={() => handleSortChange('first_name')}
                    >
                      {translation('basic_information.personal_info.name.name')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.orderBy === 'orders_completed'}
                      direction={sort.order}
                      onClick={() => handleSortChange('orders_completed')}
                    >
                      {translation('admin_users.table.orders')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sort.orderBy === 'total_purchases'}
                      direction={sort.order}
                      onClick={() => handleSortChange('total_purchases')}
                    >
                      {translation('admin_users.table.total')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    {translation('personal.action')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow hover key={user.id + i}>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            className={classes.avatar}
                            src={user.profile_photo && user.profile_photo.x80}
                          >
                            {getInitials(
                              `${user.first_name} ${user.last_name}`
                            )}
                          </Avatar>
                          <div>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/admin/user/${user.id}`}
                              variant="h6"
                            >
                              {user.first_name} {user.last_name}
                            </Link>
                            <Typography variant="body2" color="textSecondary">
                              {user.email}
                            </Typography>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        {user.orders_completed}
                      </TableCell>
                      <TableCell align="left">
                        Bs. {user.total_purchases}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/admin/user/${user.id}`}
                          component={RouterLink}
                        >
                          {translation('admin_users.table.view_profile')}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          labelRowsPerPage={translation('personal.pagination.user')}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to === -1 ? count : to} ${translation(
              'personal.pagination.of'
            )} ${
              count !== -1
                ? count
                : `${translation('personal.pagination.more')} ${to}`
            }`;
          }}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array
};

Results.defaultProps = {
  users: []
};

export default Results;
