/* eslint-disable react/jsx-curly-newline */
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Select,
  Box,
  makeStyles,
  Button,
  FormHelperText
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { setFilter } from '../../../actions/filterActions';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import useFilter from '../../../hooks/filter/useFilter';
import { ORDER_STATUS, PAYMENT_METHOD } from '../../../dto/enum';
import SelectWithOption from '../../../components/selectWithOption';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  }
}));

const Header = props => {
  const { setSubsidiary, restaurant, allSubsidiariesRest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const cities = useAllCities();
  const filter = useFilter();
  // const allPromos = usePromos();
  const [defaultCity, setDefaultCity] = useState(null);

  if (filter && !filter.city && cities && !defaultCity) {
    const [defCity] = cities.filter(city => city.is_default);
    setDefaultCity(defCity ? defCity.id : cities[0].id);
    dispatch(setFilter({ city: defCity ? defCity.id : cities[0].id }));
  }
  if (filter && !filter.fromDate) {
    dispatch(
      setFilter({ fromDate: createMomentOnBolivianTime().format('YYYY-MM-DD') })
    );
  }
  if (filter && !filter.toDate) {
    dispatch(
      setFilter({ toDate: createMomentOnBolivianTime().format('YYYY-MM-DD') })
    );
  }
  if (filter && !filter.restaurant) {
    dispatch(setFilter({ restaurant: restaurant.id }));
  }
  if (filter && filter.restaurant === 'all') {
    dispatch(setFilter({ subsidiary: 'all' }));
  }
  if (filter && !filter.subsidiary) {
    dispatch(setFilter({ subsidiary: 'all' }));
  }
  if (filter && !filter.status) {
    dispatch(setFilter({ status: 'all' }));
  }
  if (filter && !filter.promo) {
    dispatch(setFilter({ promo: 'all' }));
  }
  if (filter && !filter.statusOrder) {
    dispatch(setFilter({ statusOrder: ORDER_STATUS.COMPLETED }));
  }
  if (filter && !filter.payment_method) {
    dispatch(setFilter({ payment_method: 'all' }));
  }

  const handleSelectPaymentType = value => {
    dispatch(
      setFilter({
        payment_method:
          value !== 'all' && value.length !== 4
            ? value.map(option => option.value)
            : 'all'
      })
    );
  };

  const allSelected = [{ value: 'all', label: 'TODO TIPO DE PAGO' }];

  const payment_method = [
    { value: PAYMENT_METHOD.CASH, label: 'EFECTIVO' },
    { value: PAYMENT_METHOD.ONLINE_CARD, label: 'TARJETA DE CRÉDITO' },
    { value: PAYMENT_METHOD.ONLINE_QR, label: 'ONLINE QR' },
    { value: PAYMENT_METHOD.QR, label: 'QR' }
  ];

  const statusOrder = [
    {
      value: ORDER_STATUS.COMPLETED,
      label: translation('orders.details.order_status.completed')
    },
    {
      value: ORDER_STATUS.CANCELED,
      label: translation('orders.details.order_status.canceled')
    }
  ];

  const subdidiariesData = useMemo(() => {
    if (filter.restaurant && allSubsidiariesRest) {
      const subsidiaries = allSubsidiariesRest
        .filter(
          s =>
            s.city?.id === filter.city && s.restaurant_id === filter.restaurant
        )
        .map(s => (
          <option key={s.id} value={s.id} name={s.name}>
            {s.name}
          </option>
        ));
      subsidiaries.unshift(
        <option key="all" value="all">
          {translation('accounting.all_subsidiaries')}
        </option>
      );
      return subsidiaries;
    }
    return [];
    // eslint-disable-next-line
  }, [filter, allSubsidiariesRest]);

  const onReset = () => {
    dispatch(
      setFilter({
        city: defaultCity,
        restaurant: restaurant.id,
        subsidiary: 'all',
        promo: 'all',
        fromDate: createMomentOnBolivianTime().format('YYYY-MM-DD'),
        toDate: createMomentOnBolivianTime().format('YYYY-MM-DD'),
        paymentMethod: 'all'
      })
    );
  };
  if (!allSubsidiariesRest) return null;
  return (
    <>
      <Box className={classes.container} width="100%" my={3}>
        <Grid item container justify="flex-end" wrap="nowrap" xs={12} md={12}>
          <Button variant="contained" onClick={onReset}>
            {translation('accounting.reset_button')}
          </Button>
          {cities && (
            <Box mx={1.5}>
              <Select
                native
                value={filter.city}
                onChange={e =>
                  dispatch(
                    setFilter({
                      city: e.target.value,
                      restaurant: restaurant.id,
                      subsidiary: 'all',
                      promo: 'all',
                      statusOrder: ORDER_STATUS.COMPLETED,
                      payment_method: 'all'
                    })
                  )
                }
              >
                <option key="all" value="all">
                  Todas las ciudades
                </option>
                {cities.map(c => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Select>
            </Box>
          )}

          <Box mx={1.5}>
            {filter.restaurant && (
              <Select
                native
                value={filter.subsidiary}
                onChange={e => {
                  dispatch(setFilter({ subsidiary: e.target.value }));
                  const [s] = allSubsidiariesRest.filter(
                    sub => sub.id === e.target.value
                  );
                  setSubsidiary(s);
                }}
              >
                {subdidiariesData}
              </Select>
            )}
          </Box>
          <Box mx={1.5}>
            {allSubsidiariesRest && (
              <Select
                native
                value={filter.statusOrder}
                onChange={e =>
                  dispatch(setFilter({ statusOrder: e.target.value }))
                }
              >
                {statusOrder.map(s => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </Select>
            )}
          </Box>

          <Box mx={1.5}>
            <DatePicker
              disableFuture
              format="YYYY-MM-DD"
              variant="dialog"
              value={filter.fromDate}
              onChange={date => {
                dispatch(
                  setFilter({
                    fromDate: date
                      ? createMomentOnBolivianTime(date.toDate()).format(
                          'YYYY-MM-DD'
                        )
                      : undefined
                  })
                );
              }}
            />
            <FormHelperText>Desde</FormHelperText>
          </Box>
          <Box mx={1.5}>
            <DatePicker
              disableFuture
              format="YYYY-MM-DD"
              variant="dialog"
              value={filter.toDate}
              onChange={date => {
                dispatch(
                  setFilter({
                    toDate: date
                      ? createMomentOnBolivianTime(date.toDate()).format(
                          'YYYY-MM-DD'
                        )
                      : undefined
                  })
                );
              }}
            />
            <FormHelperText>Hasta</FormHelperText>
          </Box>
          <Box mx={1.5}>
            <SelectWithOption
              options={payment_method}
              allLabel={allSelected}
              handleSelectOptions={handleSelectPaymentType}
            />
            <FormHelperText>Tipo de Pago</FormHelperText>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Header;
