import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import {
  AiOutlineFileText,
  AiOutlineEye,
  AiOutlineDownload
} from 'react-icons/ai';
import { IoMdAddCircle, IoMdClose } from 'react-icons/io';
import { BsPencil } from 'react-icons/bs';
import { useFirestore } from 'react-redux-firebase';
import { COLORS } from '../../../theme/colors';
import { COLLECTIONS } from '../../../constants';
import firebaseService from '../../../services/firebaseService';
import Document from '../../../dto/document';
import XpressModal from '../../Modal';

const useStyles = makeStyles(() => ({
  containerDoc: {
    border: '1px solid #BF8D20',
    borderRadius: '8px',
    width: '100%',
    height: '100%'
  },
  containerNull: {
    height: '183px',
    borderRadius: '6px',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
    margin: '5%',
    backgroundPosition: 'center',
    maxWidth: '100%',
    width: '100%'
  },
  containerCard: {
    margin: '23px 25px'
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  cardDescription: {
    margin: '13px 0px',
    fontSize: '12px'
  },
  cardDocument: {
    width: '100%',
    height: '165px',
    borderRadius: '6px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative'
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  styleText: {
    fontSize: '10px',
    textDecoration: 'underline'
  },
  hoverCard: {
    backgroundColor: 'black'
  },
  containerIcons: {
    backgroundColor: 'rgb(0, 23, 20, 0.7)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '6px'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: 'white'
  }
}));

const Documents = ({
  description = '',
  urlDocument = '',
  nameDocument = '',
  type = 'document',
  index = false,
  idDocument = '',
  status
}) => {
  const borderDecoration =
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%2356B19EFF' stroke-width='1' stroke-dasharray='7%2c 7' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e";
  const [hoverIconOptions, setHoverIconOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [logoBanner, setLogoBanner] = useState(urlDocument);
  const [extension, setExtension] = useState('');
  const [newType, setNewType] = useState(type);
  const classes = useStyles();
  const firestore = useFirestore();
  const { id } = useParams();

  const getTypeDocumentByUrl = async () => {
    const data = await fetch(urlDocument)
      .then(response => response.blob())
      .catch(error => console.log(error));
    const currentType = data.type.split('/')[0];
    const extensionDoc = data.type.split('/')[1];
    if (currentType === 'image') {
      setNewType(currentType);
      setExtension(extensionDoc);
    }
  };

  const createDocument = async (url, { docName, docDescription }) => {
    const docId = firebaseService.randomId('document');
    const doc = new Document();
    doc.id = docId;
    doc.url = url;
    doc.name = docName;
    doc.name_file = docName;
    doc.description = docDescription;
    await firestore
      .collection(`${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.DOCUMENT}`)
      .doc(docId)
      .set({ ...doc });
  };

  const editDocument = async (docId, editedField) => {
    await firestore
      .collection(`${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.DOCUMENT}`)
      .doc(docId)
      .update(editedField);
  };

  const addDocument = () => {
    setShowModal(true);
    const path = `${COLLECTIONS.RESTAURANT}/${id}`;
    setModal(
      <XpressModal
        type="uploadFileRest"
        path={path}
        handleConfirm={async (url, name) => {
          createDocument(url, name);
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
      />
    );
  };

  const showImageDocument = () => {
    const doc = document.createElement('a');
    doc.href = `${urlDocument}.${extension}`;
    doc.setAttribute('target', '_blank');
    document.body.appendChild(doc);
    doc.click();
    document.body.removeChild(doc);
  };

  const acceptFormat = () => {
    if (newType === 'image') {
      return 'image/png, image/jpeg';
    }
    return '.csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/msword';
  };

  const editLogoBanner = () => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="updateLogo"
        typeImage={status}
        id={idDocument}
        acceptFormat={acceptFormat}
        handleConfirm={async values => {
          setLogoBanner(values);
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
      />
    );
  };

  const editModal = () => {
    setShowModal(true);
    const path = `${COLLECTIONS.RESTAURANT}/${id}`;
    setModal(
      <XpressModal
        type="editFileRestaurant"
        path={path}
        status={status}
        name={nameDocument}
        title={nameDocument}
        acceptFormat={acceptFormat}
        description={description}
        urlImage={urlDocument}
        handleConfirm={async values => {
          editDocument(idDocument, values);
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
        typeDocument={newType}
      />
    );
  };

  const deleteDocument = () => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="deleteConfirmation"
        handleConfirm={async () => {
          await firestore
            .collection(
              `${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.DOCUMENT}`
            )
            .doc(idDocument)
            .update({ enable: false });
          setShowModal(false);
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  const handleDownloadDocument = () => {
    if (newType === 'image') {
      return fetch(urlDocument, {
        method: 'GET',
        headers: {}
      })
        .then(response => {
          response.arrayBuffer().then(buffer => {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nameDocument}.${extension}`);
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
    return showImageDocument();
  };

  useEffect(() => {
    if (urlDocument) {
      getTypeDocumentByUrl();
    }
    // eslint-disable-next-line
  }, [urlDocument]);

  return (
    <Box width="100%" height="100%" display="flex">
      <Grid className={index ? classes.containerDoc : classes.containerNull}>
        <Box className={classes.containerCard}>
          <Typography className={classes.cardTitle}>{nameDocument}</Typography>
          <Typography variant="h5" className={classes.cardDescription}>
            {description || <br />}
          </Typography>
          <Box
            className={classes.cardDocument}
            style={{
              backgroundImage: `url("${
                // eslint-disable-next-line no-nested-ternary
                newType === 'image'
                  ? status === 'logo' || status === 'banner'
                    ? logoBanner
                    : urlDocument
                  : borderDecoration
              }")`
            }}
            onMouseEnter={() => setHoverIconOptions(true)}
            onMouseLeave={() => setHoverIconOptions(false)}
          >
            {urlDocument && newType !== 'image' && (
              <Box textAlign="center" style={{ opacity: '0.5' }}>
                {index && (
                  <Box>
                    <Box mb={1.6} style={{ height: 34 }}>
                      <AiOutlineFileText className={classes.styleIcon} />
                    </Box>
                    <Box mb={3}>
                      <Typography>{nameDocument}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {!urlDocument && (
              <Box
                textAlign="center"
                onClick={
                  status === 'logo' || status === 'banner'
                    ? editLogoBanner
                    : null
                }
                style={{ cursor: 'pointer' }}
              >
                {index && (
                  <>
                    <Box mb={1.6} style={{ height: 34 }}>
                      <AiOutlineFileText className={classes.styleIcon} />
                    </Box>
                    <Box mb={3}>
                      <Typography>Agregar imagen</Typography>
                      <Typography>aquí</Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.styleText}>
                        Sube documentos desde tu dispositivo
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            )}
            {!index && (
              <Box
                mb={3}
                textAlign="center"
                style={{ cursor: 'pointer' }}
                onClick={addDocument}
              >
                <Box style={{ height: 60, marginBottom: '8px' }}>
                  <IoMdAddCircle className={classes.styleIcon} />
                </Box>
                <Typography>Agregar espacio</Typography>
                <Typography>para nuevo documento</Typography>
              </Box>
            )}
            {hoverIconOptions && urlDocument && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                className={classes.containerIcons}
              >
                <Box
                  className={classes.iconOption}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={
                    status === 'logo' || status === 'banner'
                      ? editLogoBanner
                      : editModal
                  }
                >
                  <BsPencil className={classes.styleIconOption} />
                </Box>
                {newType === 'image' && (
                  <Box
                    className={classes.iconOption}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={showImageDocument}
                  >
                    <AiOutlineEye className={classes.styleIconOption} />
                  </Box>
                )}
                <Box
                  className={classes.iconOption}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleDownloadDocument}
                >
                  <AiOutlineDownload className={classes.styleIconOption} />
                </Box>
                {status !== 'banner' && status !== 'logo' && (
                  <Box
                    className={classes.iconOption}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={deleteDocument}
                  >
                    <IoMdClose className={classes.styleIconOption} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {showModal && modal}
        </Box>
      </Grid>
    </Box>
  );
};

export default Documents;
