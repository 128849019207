import React, { useState } from 'react';
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { useParams } from 'react-router';
import Page from '../../../components/Page';
import Documents from '../../../components/Document/documents';
import ImageDocuments from '../../../components/Document/ImageDocuments';
import AdvertisingSocialNetworks from '../RegisterView/AdvertisingSocialNetworks';
import { useRestaurantIdNative } from '../../../hooks/useRestaurant';
import { editRestaurant } from '../../../services/restaurantService';
import RegisterThanks from '../RegisterView/RegisterThanks';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  },
  photosContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contractBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '30px'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}));

function CompleteRestaurant() {
  const { id } = useParams();
  const classes = useStyles();
  const translation = useTranslation();
  const restaurant = useRestaurantIdNative(id);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep !== 5) setActiveStep(activeStep + 1);
  };

  const saveAdvertisingSocialNetworks = async values => {
    await editRestaurant(id, { ...values });
    setActiveStep(4);
  };

  return (
    <Page
      className={classes.root}
      title={translation('complete_restaurant.title_page')}
    >
      {activeStep === 0 && (
        <Documents restaurant={restaurant} handleNext={handleNext} />
      )}
      {activeStep === 1 && (
        <ImageDocuments
          handleNext={handleNext}
          handleBack={() => setActiveStep(0)}
          restId={id}
        />
      )}
      {activeStep === 2 && (
        <AdvertisingSocialNetworks
          isRegister
          handleBack={() => setActiveStep(1)}
          saveRestaurantData={saveAdvertisingSocialNetworks}
          handleNext={handleNext}
        />
      )}
      {activeStep === 3 && (
        <Box display="flex" justifyContent="center" my={15}>
          <CircularProgress />
        </Box>
      )}
      {activeStep === 4 && <RegisterThanks />}
    </Page>
  );
}

export default CompleteRestaurant;
