import React from 'react';
import { Box } from '@material-ui/core';
import { GrSelect } from 'react-icons/gr';

const HoverButtonsEmpty = ({ classes, onImageSelect, onOpen }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      className={classes.containerIcons}
    >
      <Box
        className={classes.iconOption}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={onOpen}
      >
        <style>
          {`
                .gricon path {
                  stroke: white;
                }
               
                `}
        </style>
        <GrSelect
          style={{
            width: '24px',
            height: '24px',
            color: 'white',
            paddingTop: 2
          }}
          className="gricon"
        />
      </Box>
    </Box>
  );
};

export default HoverButtonsEmpty;
