import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Button,
  Radio,
  FormControlLabel,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';
import { useAllRestaurantSubsidiaries } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { useAreas } from '../../../../hooks/AdminHooks/areas/useAreas';
import { usePricesStyles } from './styles';
import ExpressTable from '../../../../components/Table/ExpressTable';
import XpressModal from '../../../../components/Modal';
import AddPrice from './modals/AddPrice';
import { editSubsidiary } from '../../../../services/subsidiaryService';
import NewPrice from '../../areas/modal/NewPrice';

const Prices = ({ restId }) => {
  const classes = usePricesStyles();
  const allSubsidiaries = useAllRestaurantSubsidiaries(restId);
  const areaPrices = useAreas();
  const translation = useTranslation();
  const [selectedSubsidiary, setSelectedSubsidiary] = useState({ id: null });
  const [areaId, setAreaId] = useState(null);
  const [areaToShow, setAreaToShow] = useState([]);
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [areaName, setAreaName] = useState('');

  useEffect(() => {
    if (areaId) {
      const [area] = areaPrices.filter(a => a.id === areaId);
      setAreaToShow(area.price);
    }
    if (!areaId && areaToShow.length) setAreaToShow([]);
  }, [areaId, areaPrices, areaToShow]);

  const tableHeaders = [
    {
      id: 'distance',
      label: translation('admin_rest.prices.distance')
    },
    {
      id: 'price',
      label: translation('admin_rest.prices.price')
    },
    {
      id: 'subsidy',
      label: translation('admin_rest.prices.subsidy')
    },
    {
      id: 'bonus',
      label: translation('admin_rest.prices.bonus')
    }
  ];

  const handleClose = () => {
    setShowModal(false);
  };

  const onAreaEdited = (currentAreaId, currentAreaName) => {
    setAreaId(currentAreaId);
    setAreaName(currentAreaName);
  };

  const createArea = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid className={classes.addPriceModal}>
          <NewPrice onCancel={handleClose} />
        </Grid>
      </XpressModal>
    );
  };

  const assigneAreaPrice = (priceId, subid) => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid item container className={classes.modal}>
          <AddPrice
            priceId={priceId}
            subId={subid}
            onClose={handleClose}
            onAreaEdited={onAreaEdited}
          />
        </Grid>
      </XpressModal>
    );
  };

  const removePrice = subId => {
    editSubsidiary(subId, {
      price_area_id: '',
      area_price_name: ''
    });
    setAreaName('');
  };

  return (
    <Box>
      <Grid item container xs={12} md={12} justify="flex-end">
        <Button
          className={clsx(classes.button, classes.newButton)}
          variant="contained"
          onClick={createArea}
          disabled={!allSubsidiaries}
        >
          {translation('admin_rest.prices.add_prices')}
        </Button>
      </Grid>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          md={7}
          className={clsx(classes.subsidiaryContainer)}
        >
          {!allSubsidiaries && (
            <Box width="100%" display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          )}
          {allSubsidiaries && (
            <Box>
              <Typography className={clsx(classes.helperText)}>
                {translation('admin_rest.prices.help_text')}
              </Typography>
            </Box>
          )}
          {allSubsidiaries &&
            allSubsidiaries.map(s => (
              <Box
                key={s.id}
                className={clsx(classes.rowContainer, {
                  [classes.activeRowContainer]: selectedSubsidiary.id === s.id
                })}
              >
                <Box className={clsx(classes.areaContainer)}>
                  <FormControlLabel
                    checked={selectedSubsidiary.id === s.id}
                    control={<Radio color="primary" />}
                    label={
                      <Typography className={classes.label}>
                        {s.name}
                      </Typography>
                    }
                    labelPlacement="end"
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedSubsidiary(s);
                        setAreaId(s.price_area_id);
                        setAreaName(s.area_price_name || '');
                      }
                    }}
                  />
                  <Typography className={clsx(classes.area)}>
                    {s.area_price_name ||
                      translation('admin_rest.prices.assigne_area')}
                  </Typography>
                </Box>
                <Box className={clsx(classes.cockpitContainer)}>
                  <Button
                    className={clsx(classes.button)}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setSelectedSubsidiary(s);
                      setAreaId(s.price_area_id);
                      assigneAreaPrice(s.price_area_id, s.id);
                      setAreaName(s.area_price_name || '');
                    }}
                  >
                    {translation(
                      s.price_area_id
                        ? 'admin_rest.prices.re_assigne'
                        : 'admin_rest.prices.assigne'
                    )}
                  </Button>
                  {s.price_area_id && (
                    <Button
                      className={clsx(classes.button, classes.removeButton)}
                      variant="contained"
                      onClick={() => {
                        setSelectedSubsidiary(s);
                        setAreaId(s.price_area_id);
                        removePrice(s.id);
                      }}
                    >
                      {translation('admin_rest.prices.remove')}
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
        </Grid>
        <Grid item container xs={12} md={5}>
          {selectedSubsidiary.id && (
            <Box className={clsx(classes.tableHelperContainer)}>
              <Typography className={clsx(classes.tableHelper)}>
                {selectedSubsidiary.name} -
              </Typography>
              <Typography className={clsx(classes.tableHelperArea)}>
                {areaName}
              </Typography>
            </Box>
          )}
          <ExpressTable columns={[...tableHeaders]} rows={[...areaToShow]} />
        </Grid>
      </Grid>
      {showModal && modal}
    </Box>
  );
};

export default Prices;
