import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { COLLECTIONS } from '../constants';
import firebase from '../config/firebaseConfig';
import User from '../dto/user';

const useUser = userId => {
  let query = [];
  if (userId) {
    query = [
      {
        collection: COLLECTIONS.USER,
        doc: userId,
        storeAs: `user_${userId}`
      }
    ];
  }
  useFirestoreConnect(query);
  const user = useSelector(state => state.firestore.ordered[`user_${userId}`]);

  if (user && userId) {
    return user;
  }
  return [new User({ first_name: '', last_name: '' })];
};

export function useCurrentUser() {
  const user = useSelector(state => state.account.user);
  return user;
}

export default useUser;
function useUserFirebase() {
  const [userAuth, setUserAuth] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUserAuth(user);
    });
  }, []);
  return userAuth;
}

export function useUserFirestore() {
  const [user, setUser] = useState(null);
  const currentUser = useUserFirebase();
  useEffect(() => {
    if (currentUser) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.USER)
        .doc(currentUser.uid)
        .onSnapshot(snap => {
          if (snap.exists) {
            // eslint-disable-next-line no-shadow
            const user = new User({ ...snap.data(), id: snap.id });
            setUser(user);
          }
        });
    }
    return () => {};
  }, [currentUser]);
  return user;
}
