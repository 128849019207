import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

const COLLECTION = COLLECTIONS.CAMPAINGS_IMAGE;

class CampaingsImage {
  createCampaingImageDB = async (id, data) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .set({ ...data });

  getNewCampaingsImage = () => {
    const id = firebaseService.randomId(COLLECTION);
    return id;
  };
}
const campaingsImageService = new CampaingsImage();
export default campaingsImageService;
