import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

const COLLECTION = COLLECTIONS.CITY;

// eslint-disable-next-line import/prefer-default-export
export const saveCityDB = async (id, data) => {
  const value = {
    ...data
  };

  if (data.coverage_polygon) {
    value.coverage_polygon = data.coverage_polygon.map(p => {
      return new admin.firestore.GeoPoint(p.lat, p.lng);
    });
  }

  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update(value);
};
