import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const useDealers = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['is_driver', '==', true]],
      orderBy: ['created_at', 'desc'],
      storeAs: 'dealers'
    }
  ]);
  const dealers = useSelector(state => state.firestore.ordered.dealers);

  if (dealers) {
    return dealers;
  }
  return [];
};
