import React from 'react';
import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ path, className, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslation();
  const { status } = useParams();

  let statusLabel = '';
  switch (status) {
    case 'active':
      statusLabel = translate('admin_navBar.active');
      break;
    case 'suspended':
      statusLabel = translate('admin_navBar.suspended');
      break;
    case 'archived':
      statusLabel = translate('admin_navBar.archived');
      break;
    default:
      break;
  }

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/users"
            component={RouterLink}
          >
            {translate('admin_users.link')}
          </Link>
          {statusLabel && (
            <Link
              variant="body1"
              color="inherit"
              to={`/admin/users${status ? `/${status}` : ''}`}
              component={RouterLink}
            >
              {statusLabel}
            </Link>
          )}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          {translate('admin_users.title')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
