/* eslint camelcase: 0 */
export default class AdditionItem {
  constructor({
    id,
    enable = true,
    name = 'Nuevo',
    price = 0,
    availability = true
  } = {}) {
    this.id = id;
    this.enable = enable;
    this.name = name;
    this.price = price;
    this.availability = availability;
  }
}
