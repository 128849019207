import { firestore } from 'firebase';
import { LOG_ACTION, ORDER_STATUS } from '../dto/enum';
import authService from './authService';
import { COLLECTIONS } from '../constants';
import OrderHistory from '../dto/order/orderHistory';
import { getResumeUser, getUserRole } from '../utils/helpers/userHelper';

/**
 * @param orderId
 * @returns {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
 */
const orderHistoryCollectionRef = orderId =>
  firestore()
    .collection(COLLECTIONS.ORDER)
    .doc(orderId)
    .collection(COLLECTIONS.HISTORY);

const OrderHistoryServices = {
  /**
   * @param {string} orderId
   * @param {LOG_ACTION|string} logAction
   * @param {{}} dataChanges
   * @returns {Promise<void>}
   */
  async addHistory(orderId, logAction = LOG_ACTION.NONE, dataChanges = {}) {
    if (logAction === LOG_ACTION.NONE) {
      return Promise.resolve();
    }
    const currentUserResume = authService.getCurrentUserResume();
    const currentUserRole = getUserRole(currentUserResume);
    const orderHistoryCollection = orderHistoryCollectionRef(orderId);
    const newHistoryDoc = orderHistoryCollection.doc();
    const history = new OrderHistory({
      id: newHistoryDoc.id,
      role: currentUserRole,
      user: currentUserResume,
      action: logAction
    });
    switch (logAction) {
      case LOG_ACTION.VERIFIED:
        history.verified = dataChanges.verified;
        break;
      case LOG_ACTION.STATUS_CHANGED:
        history.status = dataChanges.status;
        if (dataChanges.status === ORDER_STATUS.CANCELED) {
          history.canceled_reason = dataChanges.canceled_reason;
        }
        break;
      case LOG_ACTION.DRIVER_CHANGED:
        history.driver = getResumeUser(dataChanges.driver);
        break;
      case LOG_ACTION.NOTIFY_CLIENT:
        break;
      case LOG_ACTION.READY_FOR_DRIVER_TO_PICK_UP:
        break;
      default:
        break;
    }
    return newHistoryDoc.set({ ...history });
  }
};

export default OrderHistoryServices;
