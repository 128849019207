import React, { useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { ORDER_STATUS, PAYMENT_METHOD } from '../../../dto/enum';
import { getDate, createMomentOnBolivianTime } from '../../../utils/date';
import OrderPrinting from '../OrderPrinting/OrderPrinting';
import PrintButton from './PrintButton';
import { usePromotion } from '../../../hooks/Promotions/usePromotions';
import { useHeaderStyles } from './styles';
import IntervalTime from '../../../components/IntervalTime';
import { getOrderType } from '../../../utils/helpers/orderHelper';

// import XpressModal from '../../../components/Modal';
// import { createChat, updateChat } from '../../../services/chatService';

function Header({ order, orderInProcess, modal }) {
  // const history = useHistory();
  const classes = useHeaderStyles();
  const translation = useTranslation();
  const dataOrder = { ...order };
  const promotion = usePromotion(order?.promotionId);
  // const [showModal, setShowModal] = useState(false);
  // const [modalDisplayed, setModalDisplayed] = useState(null);
  // const user = useSelector(state => state.account.user);

  const orderType = getOrderType(order.order_type);

  const getOrder = () => {
    if (!order.order_code) return translation('orders_view.card.processing');
    const word = translation('orders_view.card.order');
    return `${word} #${order.order_code.split('-')[0]}-${
      order.order_code.split('-')[1]
    }`;
  };

  if (dataOrder.delivery_time) {
    dataOrder.delivery_time = moment(getDate(dataOrder.delivery_time));
  }

  const isOnlinePayment = useMemo(() => {
    if (order) {
      if (
        order.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
        order.payment_method === PAYMENT_METHOD.ONLINE_QR
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [order]);

  return (
    <>
      {order.status !== 'IN_PROCESS' && (
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20
          }}
        >
          <Box
            flex="display"
            className={clsx(classes.infoMerged, classes.yellowBackground)}
          >
            <Box mr={2}>
              <Typography className={classes.deliveryText}>
                Pedido de:
              </Typography>
            </Box>
            <Box
              mr={2}
              style={{
                height: '50px',
                width: '50px'
              }}
            >
              {order?.subisidiary_logo_photo && (
                <img
                  alt="restaurant logo"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50px',
                    objectFit: 'cover'
                  }}
                  src={order.subisidiary_logo_photo}
                />
              )}
            </Box>
            <Box>
              <Typography className={classes.deliveryText}>
                {order.restaurant_name}
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Sucursal: ${order.subsidiary_name}`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        container
        className={clsx(classes.root, { [classes.modal]: modal })}
      >
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={9}
          wrap="nowrap"
          className={classes.firstBlock}
        >
          <Grid item container className={classes.orderContainer}>
            <Box
              className={clsx(classes.orderContainer, classes.greenBackground)}
            >
              <Typography className={classes.orderText}>
                {getOrder()}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            container
            className={clsx(classes.orderContainer, classes.threeItemsMargin)}
          >
            <Box
              className={clsx(classes.orderContainer, classes.yellowBackground)}
            >
              <Typography className={classes.deliveryText}>
                {translation(`${orderType}`)}
              </Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item container>
              {order.status !== ORDER_STATUS.IN_PROCESS ? (
                <>
                  {order.status === ORDER_STATUS.COMPLETED &&
                  order.finish_times.completed_at ? (
                    <Box
                      className={clsx(
                        classes.hourContainer,
                        classes.yellowBackground
                      )}
                    >
                      <Typography
                        variant="h4"
                        color="textPrimary"
                        className={classes.timeText}
                      >
                        {`${translation(
                          'orders_view.card.delivery_time'
                        )} ${createMomentOnBolivianTime(
                          order.finish_times.completed_at.toDate()
                        ).format('HH:mm')}`}
                      </Typography>
                    </Box>
                  ) : (
                    <IntervalTime order={dataOrder} modal={false} />
                  )}
                </>
              ) : (
                <IntervalTime order={dataOrder} modal />
              )}
            </Grid>
          </Hidden>
          <Grid
            item
            container
            className={clsx(classes.orderContainer, classes.threeItemsMargin)}
          >
            {isOnlinePayment && (
              <Box
                className={clsx(
                  classes.orderContainer,
                  classes.yellowBackground
                )}
              >
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={classes.timeText}
                >
                  PAGADO
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          className={classes.secondBlock}
        >
          <Hidden mdUp>
            {order.status === ORDER_STATUS.COMPLETED &&
            order.finish_times.completed_at ? (
              <Box
                className={clsx(
                  classes.hourContainer,
                  classes.yellowBackground
                )}
              >
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={classes.timeText}
                >
                  {`${translation(
                    'orders_view.card.delivery_time'
                  )} ${createMomentOnBolivianTime(
                    order.finish_times.completed_at.toDate()
                  ).format('HH:mm')}`}
                </Typography>
              </Box>
            ) : (
              <IntervalTime order={dataOrder} modal />
            )}
          </Hidden>

          <Box onClick={orderInProcess}>
            <OrderPrinting order={{ ...order, promotion }} />
            <PrintButton
              text={
                order.status === ORDER_STATUS.IN_PROCESS
                  ? translation('orders_view.cockpit.buttons.accept_print')
                  : translation('orders_view.cockpit.buttons.print')
              }
              propsClasses={clsx(classes.button, {
                [classes.buttonsWithWidth]:
                  order.status !== ORDER_STATUS.IN_PROCESS
              })}
            />
          </Box>
        </Grid>
        {/* {showModal && modalDisplayed} */}
      </Grid>
    </>
  );
}

Header.propTypes = {
  order: PropTypes.object.isRequired
};

export default Header;
