import React from 'react';
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { IoMdArrowBack } from 'react-icons/io';
import { COLORS } from '../../../theme/colors';
import Gallery from '../../../views/menuGallery/Gallery';
import { useMenuGalleryDocs } from '../../../hooks/useMenuGallery';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.background.default
  },
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    }
  },
  transparentBox: {
    backgroundColor: '#FFFFFF80',
    borderRadius: 10,
    padding: 41,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  textTitle: {
    fontFamily: 'Nunito Sans',
    fontSize: '30px'
  },
  textDescription: {
    fontFamily: 'Nunito Sans',
    fontSize: '22px'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: `${COLORS.green}`,
    cursor: 'pointer'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '2vh',
    height: '2vh',
    borderRadius: '50%',
    cursor: 'pointer'
  }
}));

const ImageDocuments = ({ handleNext, restId, handleBack }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const menuGallery = useMenuGalleryDocs(restId);

  return (
    <div className={classes.root}>
      <div className={classes.basicInfo}>
        <Box className={classes.transparentBox}>
          <IoMdArrowBack
            className={classes.styleIconOption}
            onClick={handleBack}
          />
          <Box textAlign="center" mb={3}>
            <Typography className={classes.textTitle}>
              {translation('restaurant.images_menu.title')}
            </Typography>
          </Box>
          <Box textAlign="center" mb={5}>
            <Typography className={classes.textDescription}>
              {translation('restaurant.images_menu.description')}
            </Typography>
          </Box>
          <Box>
            <Gallery menuDocs={menuGallery} restId={restId} />
          </Box>
          <Box mt={5}>
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              mt={2}
              ml={-1}
            >
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={handleNext}
              >
                {translation('next')}
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ImageDocuments;
