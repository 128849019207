import React from 'react';
import clsx from 'clsx';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { useIntervalOrder } from '../hooks/useIntervalOrder';
import { useHeaderStyles } from '../views/orders/OrderDetailsView/styles';

const IntervalTime = ({ order, modal }) => {
  const interval = useIntervalOrder(order);
  const translation = useTranslation();
  const classes = useHeaderStyles();
  if (!interval) return null;
  return (
    <Box className={clsx(classes.hourContainer, classes.yellowBackground)}>
      <Typography variant="h4" color="textPrimary" className={classes.timeText}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {modal
          ? `${translation('orders.details.header.order_time')}
          ${createMomentOnBolivianTime(order.created_at.toDate()).format(
            'HH:mm'
          )}`
          : order.finish_times.completed_at
          ? `${translation('orders_view.card.delivery_time')}
            ${createMomentOnBolivianTime(
              order.finish_times.completed_at.toDate()
            ).format('HH:mm')}`
          : `${translation('orders.details.header.time_delay')}
          ${createMomentOnBolivianTime(interval.low).format(
            'HH:mm'
          )} - ${createMomentOnBolivianTime(interval.high).format('HH:mm')}`}
      </Typography>
    </Box>
  );
};

function getOrderResumeTimes(order) {
  return {
    id: order.id,
    accepted_time: order.accepted_time,
    created_at: order.created_at,
    delivery_distance: order.delivery_distance,
    cooking_time: order.cooking_time
  };
}

export default React.memo(IntervalTime, (prev, current) => {
  return (
    JSON.stringify(getOrderResumeTimes(prev.order)) ===
    JSON.stringify(getOrderResumeTimes(current.order))
  );
});
