import React, { useMemo, useState } from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory, useLocation, useParams } from 'react-router';
import { IconButton } from '@material-ui/core';
import Img from 'react-cool-img';
import { Delete } from '@material-ui/icons';
import useIsTemplateMenu from '../../hooks/AdminHooks/restaurant/useIsTemplateMenu';
import XpressSwitch from '../XpressSwitch';
import { editSharedProduct } from '../../services/sharedProductsService';
import { editProduct } from '../../services/productsService';
import Icons from '../../assets/icons';
import { logProduct } from '../../services/logServices';
import { LOG_ACTIONS, ORDER_TYPE } from '../../dto/enum';
import XpressModal from '../Modal';
import DeleteProduct from '../Modal/DeleteProduct';
import { COLORS } from '../../theme/colors';
import { calculatePricePickup } from '../../utils/helpers/productHelper';

export default function ProductListItem({
  product,
  blockCashier,
  section,
  restaurant,
  enableMenuCashier,
  SUPER_ADMIN
}) {
  const { restId } = useParams();
  const { subId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const isTemplate = useIsTemplateMenu();
  const translation = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const isSharedMenu = pathname
    .split('/')
    .some(route => route === 'shared_menu');
  const adminUrl = SUPER_ADMIN ? '/admin/editor/menu-sections' : null;
  const prefix = isTemplate
    ? `/app/management/shared_menu/restId/${restId}`
    : `/app/editor/menu-sections/restId/${restId}/subId/${subId}`;

  const getPriceWithDiscountTitle = () => {
    let title;
    switch (true) {
      case restaurant.order_services?.includes(ORDER_TYPE.IN_COMMERCE) &&
        restaurant.order_services?.includes(ORDER_TYPE.PICK_UP):
        title = 'Precio Pickup - En Local';
        break;
      case restaurant?.order_services?.includes(ORDER_TYPE.PICK_UP):
        title = 'Precio Pickup';
        break;
      case restaurant?.order_services?.includes(ORDER_TYPE.IN_COMMERCE):
        title = 'Precio En Local';
        break;
      default:
        title = 'Precio Pickup';
    }
    return title;
  };

  const titleDiscount = useMemo(() => {
    return getPriceWithDiscountTitle();
    // eslint-disable-next-line
  }, [restaurant]);

  const onEditProduct = () => {
    // if (!dontOpenEdit) {
    history.push(
      `${adminUrl || prefix}/${product.menu_section_id}/${product.id}`
    );
    // }
  };
  const handleEnabledChange = async checked => {
    if (isTemplate) {
      await editSharedProduct({ availability: checked }, product.id);
      const data = {
        restaurant: {
          id: restaurant.id,
          name: restaurant.name
        },
        menu_section: {
          id: section.id,
          name: section.name
        }
      };
      const changes = {
        after: {
          availability: checked
        },
        before: {
          availability: !checked
        }
      };
      await logProduct(changes, LOG_ACTIONS.UPDATE, data);
    } else await editProduct({ availability: checked }, product.id);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleEnabledProduct = async (productId, productName) => {
    setShowModal(true);
    setModal(
      <XpressModal>
        <DeleteProduct
          productId={productId}
          productName={productName}
          handleClose={handleClose}
        />
      </XpressModal>
    );
  };

  const pricePickup = calculatePricePickup(
    product.unit_price,
    restaurant.pickup_discount
  );
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <ListItem
        key={product.id}
        style={{ width: '67vw' }}
        onClick={onEditProduct}
      >
        <ListItemAvatar>
          <Img
            style={{ width: 40, height: 40, borderRadius: 4 }}
            src={product.photo.x40 || product.photo.x80 || Icons.productImage}
          />
        </ListItemAvatar>
        <div style={{ width: '30%', paddingRigth: 5 }}>
          <Typography style={{ fontSize: 10 }}>Producto</Typography>
          <Typography
            style={{
              width: '13vw',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {product.name || 'Nuevo Producto'}
          </Typography>
        </div>
        <div
          style={{
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          <Typography style={{ fontSize: 10 }}>Descripción</Typography>
          <Typography
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {product.description === ''
              ? 'Sin descripción'
              : product.description}
          </Typography>
        </div>
        <div style={{ width: '20%', marginLeft: 5 }}>
          <Typography style={{ fontSize: 10 }}>Precio Delivery</Typography>
          <Typography>
            {`Bs.${parseFloat(product.unit_price).toFixed(2)}`}
          </Typography>
        </div>
        {(restaurant?.order_services?.includes(ORDER_TYPE.PICK_UP) ||
          restaurant?.order_services?.includes(ORDER_TYPE.IN_COMMERCE)) && (
          <div style={{ width: '15%', marginLeft: 5 }}>
            <Typography style={{ fontSize: 10 }}>{titleDiscount}</Typography>
            <Typography>
              {`Bs.${parseFloat(pricePickup).toFixed(2)}`}
            </Typography>
          </div>
        )}
      </ListItem>
      <div>
        <Typography
          style={{
            fontSize: 10,
            position: 'relative',
            top: 5,
            textAlign: 'center'
          }}
        >
          {product.availability
            ? translation(
                `edit.section_item.${blockCashier ? 'blockEnable' : 'disable'}`
              )
            : translation(
                `edit.section_item.${blockCashier ? 'blockDisable' : 'enable'}`
              )}
        </Typography>
        <XpressSwitch
          disabled={!product.unit_price || blockCashier || !enableMenuCashier}
          value={product.availability}
          onClick={e => handleEnabledChange(e.target.checked)}
        />
      </div>
      {isSharedMenu && (
        <div
          style={{
            marginLeft: 30,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography
            style={{
              fontSize: 10,
              position: 'relative',
              top: -2,
              textAlign: 'center'
            }}
          >
            Eliminar
          </Typography>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => handleEnabledProduct(product.id, product.name)}
          >
            <Delete style={{ color: COLORS.red }} />
          </IconButton>
          {showModal && modal}
        </div>
      )}
    </div>
  );
}
