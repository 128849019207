import React from 'react';
import { Paper, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '../Inputs/IconButton';
import Icons from '../../assets/icons';

const styles = {
  backDrop: {
    background:
      'transparent linear-gradient(180deg, #4E9788 0%, #7CFFE4 100%) 0% 0% no-repeat padding-box',
    opacity: 0.8
  }
};
const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1190,
    margin: 'auto'
  },
  cardHeader: {
    marginTop: 12,
    padding: 20,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const ModalTemplate = ({
  show,
  children = <div />,
  onClose = () => {},
  label,
  component
}) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      BackdropProps={{
        style: styles.backDrop
      }}
      open={show}
      className={classes.modal}
    >
      <Paper style={{ paddingBottom: 20 }} elevation={3}>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton
            src={Icons.close}
            onClick={() => onClose()}
            component={component}
          />
        </div>
        <Typography className={classes.cardHeader}>
          <b>{label}</b>
        </Typography>
        {children}
      </Paper>
    </Dialog>
  );
};
export default ModalTemplate;
