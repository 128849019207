import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import {
  useDocumentsRestaurantNative,
  useGetBanner
} from 'src/hooks/Documents/useDocument';
import AdvertisingSocialNetworksForm from 'src/components/AdvertisingSocialNetworksForm';
import Document from './Document';
import { editRestaurant } from '../../../../services/restaurantService';

const useStyles = makeStyles(theme => ({
  containerTitle: {
    margin: '20px 0px'
  },
  textTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    paddingBottom: 20
  },
  row: {
    width: '100%'
  },
  padding: {
    padding: '0px 10%'
  },
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));
const Documents = ({ restaurant }) => {
  const classes = useStyles();
  const { logo, banner } = useGetBanner(restaurant.id);
  const documentsRestaurant = useDocumentsRestaurantNative(restaurant.id);
  const [documents, setDocuments] = useState([]);
  const [initial, setInitial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (banner && logo) {
      const initialArray = [
        {
          title: 'Imagen de LOGO',
          description: 'Imagen del logo corporativo.',
          urlDocument: logo.original,
          nameDocument: 'logo',
          idDocument: restaurant.id,
          status: 'logo'
        },
        {
          title: 'Banner de restaurant',
          description: 'Imagen del banner del restaurant.',
          urlDocument: banner.original,
          nameDocument: 'banner',
          idDocument: restaurant.id,
          status: 'banner'
        }
      ];
      setInitial(initialArray);
    }
    // eslint-disable-next-line
  }, [banner, logo]);

  useEffect(() => {
    if (documentsRestaurant.length > 0) {
      const arrayDoc = [...initial];
      documentsRestaurant.forEach(doc => {
        arrayDoc.push({
          title: doc.title,
          description: doc.description,
          urlDocument: doc.url,
          nameDocument: doc.name,
          idDocument: doc.id,
          status: doc.status
        });
      });
      setDocuments(arrayDoc);
    } else {
      const arrayDoc = [...initial];
      setDocuments(arrayDoc);
    }
    // eslint-disable-next-line
  }, [documentsRestaurant]);

  const saveAdvertisingSocialNetworks = async values => {
    setIsLoading(true);
    await editRestaurant(restaurant.id, { ...values });
    setIsLoading(false);
  };

  return (
    <Box p={3} flexDirection="row">
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid item className={classes.row}>
          <Box className={classes.cardContainer}>
            <Grid
              container
              direction="row"
              spacing={6}
              className={classes.padding}
            >
              {documents.length > 0 &&
                documents.map((doc, index) => (
                  <Grid sm={4} xs={12} item key={index}>
                    <Document
                      title={doc.title}
                      description={doc.description}
                      urlDocument={doc.urlDocument}
                      index={index >= 0}
                      nameDocument={doc.nameDocument}
                      idDocument={doc.idDocument}
                      status={doc.status}
                      id={restaurant.id}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {documents.length > 0 && (
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
      )}
      {restaurant && (
        <Box className={clsx(classes.padding, classes.wrapper)}>
          {isLoading && (
            <Box className={classes.loadingWrapper}>
              <CircularProgress />
            </Box>
          )}
          <AdvertisingSocialNetworksForm
            restaurant={restaurant}
            saveRestaurantData={saveAdvertisingSocialNetworks}
          />
        </Box>
      )}
    </Box>
  );
};

export default Documents;
