import React from 'react';
import {
  Box,
  Typography,
  ListItemAvatar,
  ListItem,
  Avatar,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { getOrderNumber, useAllOrders } from './OrdersList';
import { getDate } from '../../../../utils/date';
import ExpressTable from '../../../../components/Table/ExpressTable';
import Orderstatus from '../../../orders/OrderDetailsView/Orderstatus';
import orderStatusTranslate from '../../../../utils/translateUtil';

function Results() {
  const translation = useTranslation();
  const orders = useAllOrders();

  const tableColumns = [
    {
      id: 'order',
      label: translation('orders.list.table.number_order'),
      minWidth: 50,
      align: 'center',
      search: true
    },
    {
      id: 'restaurantName',
      filterName: 'restaurant_name',
      label: translation('dashboard_admin.restaurant'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'dateDelivery',
      label: translation('dashboard_admin.order_time'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'totalPay',
      filterName: 'totalPayValue',
      label: translation('dashboard_admin.bill'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'status',
      filterName: 'statusValue',
      label: translation('orders.list.table.state'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'link',
      label: translation('dashboard_admin.see_order'),
      minWidth: 170,
      align: 'center',
      search: true
    }
  ];

  let tableContent = [];

  const restaurantName = (image, retaurantName) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar alt="Ted talk" src={image} />
        </ListItemAvatar>
        <Typography variant="body2" color="textSecondary">
          {retaurantName}
        </Typography>
      </ListItem>
    );
  };

  const linkToSeeOrder = id => {
    return (
      <Box>
        <Link component={RouterLink} to={`/admin/order/${id}`}>
          {translation('dashboard_admin.see_order')}
        </Link>
      </Box>
    );
  };

  const total = totalOrder => {
    return `Bs. ${totalOrder}`;
  };

  if (orders) {
    tableContent = orders.map(order => ({
      id: order.id,
      order: getOrderNumber(order),
      restaurant_name: order.restaurant_name,
      restaurantName: restaurantName('image', order.restaurant_name || ''),
      dateDelivery: moment(
        getDate(order.accepted_time || order.delivery_time)
      ).format('HH:mm'),
      totalPay: total(order.total),
      totalPayValue: order.total,
      status: <Orderstatus orderStatus={order.status} />,
      statusValue: orderStatusTranslate(order.status, translation).status,
      link: linkToSeeOrder(order.id)
    }));
  }

  return (
    <ExpressTable columns={[...tableColumns]} rows={[...tableContent]} search />
  );
}

export default Results;
