import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from '../../../components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '1hv',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  }
}));

const PoliciyAndPrivay = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Policy and Privacy">
      <Container maxWidth="md" maxHeight="md">
        <PerfectScrollbar>
          <Card>
            <CardContent>
              <Grid>
                <Typography align="center" variant="h1" color="textPrimary">
                  Policy and Privacy
                </Typography>
              </Grid>
              <Grid>
                <Box m={5}>
                  <Typography
                    align="justify"
                    variant="body1"
                    color="textPrimary"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus luctus metus vitae dui ullamcorper dapibus.
                    Integer bibendum molestie erat id iaculis. Quisque aliquam
                    risus non varius ornare. Aliquam justo elit, rhoncus at
                    dolor quis, interdum accumsan ligula. Cras id pharetra
                    libero, eu luctus lectus. Donec ac nulla nec leo commodo
                    gravida sit amet non eros. Morbi quis varius dui. Donec
                    commodo leo eget lorem facilisis varius. Sed euismod lacus
                    vitae venenatis tempus. Phasellus laoreet mi vitae libero
                    vulputate, in maximus magna tristique. Integer cursus
                    suscipit eros, quis bibendum libero venenatis quis.
                    Curabitur faucibus et elit ut cursus. Nunc lacinia, purus
                    quis lacinia feugiat, mauris lacus porta nulla, sit amet
                    laoreet quam tortor ultrices nunc. In eu consectetur nisi.
                    Proin fermentum leo ac augue dapibus vestibulum.
                    <br />
                    <br />
                    Cras id semper odio, sed ultrices ex. Integer rutrum risus
                    vitae nisi pulvinar, a venenatis purus cursus. Aenean a arcu
                    nibh. Donec ut tellus ut nisi tincidunt commodo quis at
                    nisl. Donec maximus eros vel mi ultricies suscipit.
                    Vestibulum eu tincidunt velit. Proin posuere nulla felis, ac
                    ultricies nulla sagittis eget. Pellentesque volutpat
                    bibendum ultrices. Sed vitae ullamcorper velit. Cras
                    placerat tempor tristique. Pellentesque gravida augue nunc,
                    vel gravida tellus porta et. Praesent ultricies consequat
                    eros non imperdiet. Nunc vehicula, libero pulvinar pretium
                    imperdiet, lorem mi egestas urna, vel iaculis dui urna
                    feugiat neque. Phasellus mattis viverra sem vel
                    pellentesque. Curabitur nec luctus ligula. Proin a sem enim.
                    Integer tempor massa vitae nunc auctor tristique. <br />
                    <br />
                    Fusce feugiat condimentum nulla, ut ultrices nisi. Maecenas
                    eleifend lorem nec tristique ultricies.
                    <br /> <br />
                    Nulla dictum, libero non varius sollicitudin, neque lectus
                    suscipit justo, eu imperdiet risus eros vel ligula. Morbi
                    sed mattis dolor. Aenean tristique purus ligula, et semper
                    sapien auctor vel. Morbi id odio dolor. Nulla id purus
                    purus. Duis sit amet tellus et nisl tempor sollicitudin at
                    sed tellus. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Mauris et nisi enim. Phasellus sit amet
                    hendrerit odio. Aenean quis congue leo. Fusce aliquet
                    convallis euismod. Curabitur tincidunt vehicula ligula, id
                    ultrices purus efficitur pretium. Fusce eu augue congue,
                    convallis nulla eget, accumsan leo. Praesent quis massa
                    sollicitudin, aliquam ligula ut, ultrices magna. Fusce
                    hendrerit nibh odio. Cras maximus nisl ac turpis
                    scelerisque, id egestas mi fermentum. Sed mattis porta nibh
                    a facilisis. Curabitur at tortor congue, posuere neque quis,
                    mollis nunc. Integer aliquet enim lacus, sed facilisis est
                    ultrices porta. Duis eu dui turpis. Maecenas tempor bibendum
                    eros ut varius. Integer a tincidunt orci. Integer auctor
                    sapien vitae nisi ornare tincidunt. Nunc justo arcu,
                    ultrices eu vehicula eu, gravida nec metus. Sed sit amet
                    enim vel metus elementum porta. Duis dolor massa, venenatis
                    in arcu nec, cursus rutrum mi. <br />
                    <br />
                    Vestibulum tempor a ex a sagittis. Fusce euismod malesuada
                    sem sed faucibus. Pellentesque vulputate dui ut nibh
                    maximus, et imperdiet metus auctor. Etiam ultricies
                    condimentum nisl, eu efficitur mauris semper sit amet.
                    Maecenas aliquet rutrum mattis. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Fusce tincidunt vel nibh eget
                    porta. Ut felis dui, vulputate in elementum vitae, vehicula
                    eu leo. Donec vitae rhoncus nisl. Vivamus ut nisi nulla.
                    Curabitur id laoreet purus. Cras dolor felis, rutrum eget
                    nunc sed, pretium semper turpis. Nulla vel mollis dui,
                    mattis maximus leo. In ultrices ante a mauris vehicula, id
                    placerat sem ultrices.
                  </Typography>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </PerfectScrollbar>
      </Container>
    </Page>
  );
};

export default PoliciyAndPrivay;
