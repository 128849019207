import Base from './base';

export default class MenuGallery extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    title = '',
    extension = '',
    restaurant_id = '',
    type = '',
    original_image = '',
    image = {
      original: '',
      x40: '',
      x400: '',
      x80: ''
    }
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.title = title;
    this.extension = extension;
    this.restaurant_id = restaurant_id;
    this.type = type;
    this.image = image;
    this.original_image = original_image;
  }
}
