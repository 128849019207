import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  makeStyles,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Edit as EditIcon } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from 'src/constants';
import Modal from '../../../components/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '12px 0'
  },
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

const CategoriesFoodList = ({
  className,
  categories,
  sectionName,
  sectionId,
  onEdit,
  ...rest
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState('');

  const firestore = useFirestore();

  function editCategory(category) {
    onEdit(category);
  }

  function toggleCategoryVisibility(categoryId, oldEnabledValue) {
    firestore.update(`${COLLECTIONS.CATEGORIES}/${categoryId}`, {
      enable: !oldEnabledValue
    });
  }
  function closeDeleteConfirmation() {
    setIsConfirmationModalOpen(false);
    setCategoryIdToDelete('');
  }
  function deleteCategory(categoryId) {
    firestore.delete(`${COLLECTIONS.CATEGORIES}/${categoryId}`);
    closeDeleteConfirmation();
  }
  // function askForDeleteConfirmation(categoryId){
  //   setIsConfirmationModalOpen(true)
  //   setCategoryIdToDelete(categoryId)
  // }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {isConfirmationModalOpen && (
        <Modal
          type="deleteConfirmation"
          handleConfirm={() => deleteCategory(categoryIdToDelete)}
          handleClose={closeDeleteConfirmation}
        />
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {translation('edit.product_list.table.head.number')}
                </TableCell>
                <TableCell>{translation('category_food.photo')}</TableCell>
                <TableCell>{translation('category_food.category')}</TableCell>
                <TableCell>{translation('category_food.show_app')}</TableCell>
                <TableCell align="center">
                  {translation('personal.action')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category, index) => (
                <TableRow hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Avatar
                      src={
                        category.img ||
                        category.img.x80 ||
                        category.img.original ||
                        ''
                      }
                      variant="rounded"
                      className={classes.rounded}
                    />
                  </TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={category.enable}
                      onChange={() => {
                        toggleCategoryVisibility(category.id, category.enable);
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => editCategory(category)}>
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CategoriesFoodList;
