import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'firebase/storage';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { editRestaurant } from '../../../../services/restaurantService';
import RestaurantPhotoForm from '../../../../components/Restaurant/RestaurantPhotoForm';
import { COLORS } from '../../../../theme/colors';
import CardHeader from '../../../../components/Card/CardHeader';
import { useRestaurantFormStyles } from './styles';
import { ORDER_SERVICES } from '../../../../dto/enum';
import { useRestaurantSnapNativeAsync } from '../../../../hooks/useRestaurant';

const RestaurantFormOnConfig = ({
  restaurantId,
  isMerchant = false,
  shouldUpdate
}) => {
  const classes = useRestaurantFormStyles();
  const currentRestaurant = useRestaurantSnapNativeAsync(restaurantId);
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const bannerRef = useRef(null);
  const logoRef = useRef(null);
  const formikRef = useRef(null);
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [restaurantId, formikRef]);
  const chargeOptions = [
    { value: true, label: translation('forms.commerce.montly') },
    { value: false, label: translation('forms.commerce.biweekly') }
  ];

  return (
    <Grid>
      {currentRestaurant ? (
        <Formik
          innnerRef={ref => {
            formikRef.current = ref;
          }}
          enableReinitialize
          initialValues={{
            name: currentRestaurant.name ? currentRestaurant.name : '',
            subsidiary_amount: currentRestaurant?.subsidiary_amount
              ? currentRestaurant?.subsidiary_amount
              : 0,
            charge: currentRestaurant?.charge
              ? {
                  pick_up: currentRestaurant?.charge.pick_up,
                  delivery: currentRestaurant?.charge.delivery
                }
              : {
                  pick_up: 0.0,
                  delivery: 0.0
                },
            usePickup:
              currentRestaurant?.order_services.findIndex(
                e => e === ORDER_SERVICES.PICKUP
              ) > -1,
            useDelivery:
              currentRestaurant?.order_services.findIndex(
                e => e === ORDER_SERVICES.DELIVERY
              ) > -1,
            is_montly_paid: currentRestaurant?.is_montly_paid
              ? currentRestaurant.is_montly_paid
              : false
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Requerido'),
            subsidiary_amount: Yup.number()
              .positive('Necesita ser mayor o igual a uno')
              .required('Requerido'),
            is_montly_paid: Yup.boolean().required('Requerido')
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            const hasBannerError = bannerRef.current.hasError();
            const hasLogoError = logoRef.current.hasError();
            if (!hasBannerError && !hasLogoError)
              try {
                const orderServices = [];
                if (values.usePickup) orderServices.push(ORDER_SERVICES.PICKUP);
                if (values.useDelivery)
                  orderServices.push(ORDER_SERVICES.DELIVERY);
                const restInfo = {
                  name: values.name,
                  charge: values.charge,
                  is_montly_paid: values.is_montly_paid,
                  order_services: orderServices
                };
                await logoRef.current.uploadFile();
                await bannerRef.current.uploadFile();
                editRestaurant(currentRestaurant.id, restInfo);
                setStatus({ success: true });
                setSubmitting(false);
                if (shouldUpdate) shouldUpdate();
                enqueueSnackbar(translation('notification.correctly_save'), {
                  variant: 'success'
                });
              } catch (error) {
                resetForm();
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                enqueueSnackbar(
                  translation('notification.something_go_wrong'),
                  {
                    variant: 'error'
                  }
                );
              }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Grid item container>
                <Grid item container>
                  <Card className={classes.formCard}>
                    <CardHeader title="Datos sobre comercio:" />
                    <Divider />
                    <CardContent>
                      <Grid item container md={12} justify="space-around">
                        <Grid
                          item
                          container
                          direction="column"
                          md={3}
                          justify="flex-end"
                        >
                          <Box mb={3}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              label="Nombre del Restaurante"
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.name}
                              variant="outlined"
                            />
                          </Box>
                          <Box mb={3}>
                            <TextField
                              disabled
                              type="number"
                              min="1"
                              error={Boolean(
                                touched.subsidiary_amount &&
                                  errors.subsidiary_amount
                              )}
                              fullWidth
                              helperText={
                                touched.subsidiary_amount &&
                                errors.subsidiary_amount
                              }
                              label="No de Sucursales"
                              name="subsidiary_amount"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.subsidiary_amount}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item container md={2}>
                          <RestaurantPhotoForm
                            key={`restaurant_logo_${currentRestaurant.id}`}
                            ref={logoRef}
                            restaurant={currentRestaurant}
                            type="logo"
                          />
                        </Grid>
                        <Grid item container md={5}>
                          <RestaurantPhotoForm
                            key={`restaurant_banner_${currentRestaurant.id}`}
                            ref={bannerRef}
                            restaurant={currentRestaurant}
                            type="banner"
                          />
                        </Grid>
                      </Grid>
                      {!isMerchant && (
                        <Grid item container>
                          <Card className={classes.formCard}>
                            <CardHeader
                              title={translation(
                                'forms.commerce.charge_section'
                              )}
                            />
                            <Divider />
                            <CardContent>
                              <Box className={classes.chargeContainer}>
                                <Box className={classes.chargeOptionContainer}>
                                  <FormControlLabel
                                    checked={values.usePickup}
                                    control={
                                      <Checkbox
                                        value={values.usePickup}
                                        color="primary"
                                      />
                                    }
                                    label={translation(
                                      'forms.commerce.pickup_charge'
                                    )}
                                    labelPlacement="end"
                                    onChange={e => {
                                      setFieldValue(
                                        'usePickup',
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <TextField
                                    type="number"
                                    label={translation(
                                      'forms.commerce.pickup_charge'
                                    )}
                                    name="pickup"
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'charge.pick_up',
                                        e.target.value * 1
                                      );
                                    }}
                                    value={values.charge.pick_up * 1}
                                    variant="outlined"
                                    inputProps={{
                                      min: 0,
                                      step: 1.0
                                    }}
                                  />
                                </Box>
                                <Box className={classes.chargeOptionContainer}>
                                  <FormControlLabel
                                    checked={values.useDelivery}
                                    control={<Checkbox color="primary" />}
                                    label={translation(
                                      'forms.commerce.delivery_charge'
                                    )}
                                    labelPlacement="end"
                                    onChange={e => {
                                      setFieldValue(
                                        'useDelivery',
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <TextField
                                    type="number"
                                    label={translation(
                                      'forms.commerce.delivery_charge'
                                    )}
                                    name="delivery"
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'charge.delivery',
                                        e.target.value * 1
                                      );
                                    }}
                                    value={values.charge.delivery}
                                    variant="outlined"
                                    inputProps={{
                                      min: 0,
                                      step: 1.0
                                    }}
                                  />
                                </Box>
                                <Box className={classes.chargeOptionContainer}>
                                  <FormControlLabel
                                    control={
                                      <Select
                                        className={classes.isMontlySelect}
                                        variant="outlined"
                                        label={translation(
                                          'forms.commerce.charge_type'
                                        )}
                                        name="is_montly_paid"
                                        id="is_montly_paid"
                                        value={values.is_montly_paid}
                                        onChange={event => {
                                          setFieldValue(
                                            'is_montly_paid',
                                            event.target.value
                                          );
                                        }}
                                      >
                                        {chargeOptions.map(item => (
                                          <MenuItem
                                            key={Math.random()}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    }
                                    label={translation(
                                      'forms.commerce.charge_type'
                                    )}
                                    labelPlacement="start"
                                  />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      <Grid
                        container
                        style={{ marginTop: 32 }}
                        justify="center"
                      >
                        <ButtonBase
                          style={{
                            backgroundColor: COLORS.primary,
                            width: 193,
                            height: 48,
                            borderRadius: 7
                          }}
                          disabled={isSubmitting}
                          size="small"
                          type="submit"
                          variant="contained"
                        >
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            style={{ fontWeight: 'bold', fontSize: 16 }}
                          >
                            {translation('forms.save_changes').toUpperCase()}
                          </Typography>
                        </ButtonBase>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      ) : (
        <Box m="auto" pt={2}>
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};

export default RestaurantFormOnConfig;
