import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Bell as BellIcon, ShoppingBag, User } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';
import useAdminNotifications from 'src/hooks/useAdminNotifications';
import { useCurrentUser } from 'src/hooks/useUser';
import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from 'src/constants';

const iconsMap = {
  user: User,
  merchant: ShoppingBag
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  listTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    cursor: 'pointer'
  },
  listSubTitle: {
    fontSize: 12,
    cursor: 'pointer'
  }
}));

const restaurantToLinkMap = {
  ACTIVE: 'actives',
  SUSPENDED: 'suspended',
  PENDING: 'pending',
  REJECTED: 'rejected',
  ARCHIVED: 'archived'
};

function getType(notification) {
  if (notification.type) {
    return notification.type;
  }
  return notification.metadata.userId ? 'user' : 'merchant';
}

function Notifications() {
  const classes = useStyles();
  const translation = useTranslation();
  const notifications = useAdminNotifications();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const history = useHistory();

  const user = useCurrentUser();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function readNotification(notificationId) {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .doc(user.id)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .doc(notificationId)
      .update({ read: true });
  }

  function readAllNotifications() {
    notifications.forEach(({ id }) => readNotification(id));
  }

  function actOnNotificationPress(notification) {
    switch (getType(notification)) {
      case 'merchant':
        history.push(
          `/admin/commerce/${
            restaurantToLinkMap[notification.metadata?.restauratStatus]
          }/${notification.metadata.restaurantId}`
        );
        break;
      case 'user':
        history.push(`/admin/user/${notification.metadata.userId}`);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Tooltip title={translation('account.notification')}>
        <IconButton color="textPrimary" ref={ref} onClick={handleOpen}>
          <SvgIcon>
            <BellIcon
              fill={notifications.length > 0 ? 'green' : 'none'}
              color={notifications.length > 0 ? 'green' : 'currentColor'}
            />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            {translation('account.notification')}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {translation('account.no_notification')}
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.map(notification => {
                return (
                  <ListItem
                    className={classes.listItem}
                    divider
                    key={notification.id}
                    onClick={() => {
                      actOnNotificationPress(notification);
                      readNotification(notification.id);
                      handleClose();
                    }}
                    hover
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          {iconsMap[getType(notification)]}
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <Box>
                      <Typography className={classes.listTitle}>
                        {notification.title}
                      </Typography>
                      <Typography className={classes.listSubTitle}>
                        {notification.description}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button size="small" onClick={readAllNotifications}>
                {translation('account.mark_all')}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
