import React from 'react';
import { Breadcrumbs, Grid, Link, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(3)
  }
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/dealers"
            component={RouterLink}
          >
            {translation('admin_dealers.dealers')}
          </Link>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default Header;
