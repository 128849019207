import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from 'src/constants';

const alertCollectionRef = admin.firestore().collection(COLLECTIONS.ALERTS);

const AlertsServices = {
  async setViewedAlerts(alerts = [], currentUser) {
    const batch = admin.firestore().batch();
    alerts.forEach(alert => {
      if (alert.alertData.id && alert.merchantsData.id) {
        batch.update(
          alertCollectionRef
            .doc('platform_alerts')
            .collection('merchant')
            .doc(alert.alertData.id)
            .collection('platform_alerts_merchant_users')
            .doc(alert.merchantsData.id),
          {
            [`users.${currentUser.id}.merchant_web`]: true
          }
        );
      }
    });
    await batch.commit();
  },
  async getAlertMerchant(alerts = [], currentUser) {
    const alertsData = [];
    await Promise.all(
      alerts.map(async alert => {
        await admin
          .firestore()
          .collection(COLLECTIONS.ALERTS)
          .doc('platform_alerts')
          .collection('merchant')
          .doc(alert.id)
          .collection('platform_alerts_merchant_users')
          .where(`users.${currentUser.id}.merchant_web`, '==', false)
          .get()
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              const dataMerchantAlert = querySnapshot.docs.map(item =>
                item.data()
              );
              alertsData.push({
                alertData: alert,
                merchantsData: dataMerchantAlert[0]
              });
            }
          });
      })
    );
    return alertsData;
  }
};
export default AlertsServices;
