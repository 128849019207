import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Tabs,
  Tab
} from '@material-ui/core';
import BANKS from 'src/assets/files/banks';
import { ACCOUNT_TYPE } from 'src/constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createBankAccount, editBankData } from 'src/services/bankDataService';
import { COLORS } from '../../../../theme/colors';
import { useBankDataPerRestaurantId } from '../../../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  informationContainer: {
    borderRadius: '5px',
    border: '1px solid #BF8D20',
    padding: '28px 27px 20px 18px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  textTitle: {
    color: COLORS.activeGreen,
    fontSize: '20px',
    fontWeight: 'bold'
  },
  button: {
    background: '#FDED87',
    'border-radius': '8px'
  },
  actionButton: {
    background: '#BDE5D9',
    'border-radius': '8px'
  },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  fontWeight: {
    fontWeight: 'bold'
  },
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '5%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  feedback: {
    fontSize: 12,
    marginLeft: 10
  },
  inputStyle: {
    background: COLORS.white,
    'box-shadow': '0px 4px 5px 2px rgba(0, 0, 0, 0.25)',
    'border-radius': '4px'
  }
}));

const RegisterBankData = ({ idRest, restaurant }) => {
  const translation = useTranslation();

  const validationSchema = yup.object({
    ownerName: yup
      .string()
      .min(
        3,
        translation(
          'central_payments.details.register_bank_data.validation.chart_minimum_owner'
        )
      )
      .required(
        translation(
          'central_payments.details.register_bank_data.validation.required_owner'
        )
      ),
    ciNit: yup
      .string(
        translation(
          'central_payments.details.register_bank_data.validation.ci_nit'
        )
      )
      .min(
        7,
        translation(
          'central_payments.details.register_bank_data.validation.chart_minimum_ci_nit'
        )
      )
      .required(
        translation(
          'central_payments.details.register_bank_data.validation.required_ci_nit'
        )
      ),
    accountType: yup
      .string()
      .required(
        translation(
          'central_payments.details.register_bank_data.validation.type_account'
        )
      ),
    accountNumber: yup
      .number()
      .typeError(
        translation(
          'central_payments.details.register_bank_data.validation.must_be_numeric'
        )
      )
      .required(
        translation(
          'central_payments.details.register_bank_data.validation.required_account'
        )
      ),
    bank: yup
      .string()
      .required(
        translation(
          'central_payments.details.register_bank_data.validation.required_bank'
        )
      )
  });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const accounts = useBankDataPerRestaurantId(idRest);
  const [isLoading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const [banks, setBanks] = useState([]);
  const [currentAccountId, setCurrentAccountId] = useState('');

  const [states, setStates] = useState({
    ownerName: '',
    ciNit: '',
    accountType: '',
    accountNumber: null,
    bank: ''
  });

  useEffect(() => {
    if (accounts.length) {
      setCurrentAccountId(accounts[value]?.id || '');
      setStates({
        ownerName: accounts[value]?.owner_name || '',
        ciNit: accounts[value]?.ci_nit || '',
        accountType: accounts[value]?.account_type || '',
        accountNumber: accounts[value]?.account_number || '',
        bank: accounts[value]?.bank || ''
      });
      setBanks(oldValues => [...accounts, ...oldValues]);

      if (!accounts[value]) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [accounts, value]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const newAccountHandler = () => {
    if (accounts.length) {
      setBanks([...banks, {}]);
      setValue(1);
    }
  };

  const saveInvoicePayment = async values => {
    setLoading(true);

    try {
      if (!currentAccountId) {
        await createBankAccount({
          commerce_id: restaurant?.id,
          ...values
        });
        setBanks([]);
      } else {
        await editBankData(currentAccountId, {
          commerce_id: restaurant?.id,
          ...values
        });
      }

      enqueueSnackbar(translation('notification.correctly_save'), {
        variant: 'success'
      });

      setLoading(false);
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar(translation('notification.error_on_save'), {
        variant: 'error'
      });
      setLoading(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: states,
    validationSchema,
    onSubmit: values => {
      saveInvoicePayment(values);
    }
  });

  return (
    <Box>
      <Box
        mt={1}
        mb={1}
        mr={6}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        <Box mr={2}>
          {banks.length === 1 && (
            <Button
              size="medium"
              variant="contained"
              className={classes.actionButton}
              /* onClick={() => saveInvoicePayment()} */
              onClick={() => newAccountHandler()}
            >
              {translation('central_payments.details.register_bank_data.title')}
            </Button>
          )}
        </Box>
        {banks.length > 0 && (
          <Button
            size="medium"
            variant="contained"
            className={classes.actionButton}
            onClick={() => setDisabled(!disabled)}
          >
            {translation(
              'central_payments.details.register_bank_data.edit_account'
            )}
          </Button>
        )}
      </Box>
      {banks.length > 1 && (
        <Box className={classes.root} pb={2}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            centered
          >
            <Tab
              label={translation(
                'central_payments.details.register_bank_data.first_account'
              )}
            />
            <Tab
              label={translation(
                'central_payments.details.register_bank_data.second_account'
              )}
            />
          </Tabs>
        </Box>
      )}
      <Box className={classes.informationContainer} ml={8} mr={8}>
        {isLoading && (
          <Box className={classes.loadingWrapper}>
            <CircularProgress />
          </Box>
        )}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography className={classes.textTitle}>
            {currentAccountId
              ? null
              : translation(
                  'central_payments.details.register_bank_data.title'
                )}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            mt={1}
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width="45%" mr={2}>
              <TextField
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                label={translation(
                  'central_payments.details.register_bank_data.account_holder_name'
                )}
                value={formik.values.ownerName}
                onBlur={formik.handleBlur}
                name="ownerName"
                disabled={disabled}
                onChange={formik.handleChange}
                error={
                  formik.touched.ownerName && Boolean(formik.errors.ownerName)
                }
                helperText={formik.touched.ownerName && formik.errors.ownerName}
                fullWidth
              />
            </Box>

            <Box width="45%">
              <TextField
                className={classes.inputStyle}
                fullWidth
                label={translation(
                  'central_payments.details.register_bank_data.ci_nit'
                )}
                type="text"
                size="small"
                name="ciNit"
                disabled={disabled}
                variant="outlined"
                value={formik.values.ciNit}
                onChange={formik.handleChange}
                error={formik.touched.ciNit && Boolean(formik.errors.ciNit)}
                helperText={formik.touched.ciNit && formik.errors.ciNit}
              />
            </Box>
          </Box>
          <Box mt={2} mb={2} display="flex" justifyContent="space-between">
            <Box width="45%" mr={2}>
              <TextField
                size="small"
                fullWidth
                className={classes.inputStyle}
                select
                disabled={disabled}
                name="bank"
                variant="outlined"
                label={translation(
                  'central_payments.details.register_bank_data.bank'
                )}
                value={formik.values.bank}
                onChange={e => {
                  formik.setFieldValue('bank', e.target.value);
                }}
                error={formik.touched.bank && Boolean(formik.errors.bank)}
                helperText={formik.touched.bank && formik.errors.bank}
              >
                {BANKS.map(b => (
                  <MenuItem key={b.name} value={b.name}>
                    {b.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box width="45%">
              <TextField
                size="small"
                fullWidth
                className={classes.inputStyle}
                select
                disabled={disabled}
                name="accountType"
                variant="outlined"
                label={translation(
                  'central_payments.details.register_bank_data.account_type'
                )}
                value={formik.values.accountType}
                error={
                  formik.touched.accountType &&
                  Boolean(formik.errors.accountType)
                }
                helperText={
                  formik.touched.accountType && formik.errors.accountType
                }
                onChange={e => {
                  formik.setFieldValue('accountType', e.target.value);
                }}
              >
                {Object.keys(ACCOUNT_TYPE).map((key, i) => (
                  <MenuItem key={i} value={key}>
                    {translation(
                      `central_payments.details.register_bank_data.${ACCOUNT_TYPE[key]}`
                    )}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box mt={2} mb={2} display="flex" justifyContent="space-between">
            <Box width="45%" mr={2}>
              <TextField
                size="small"
                className={classes.inputStyle}
                fullWidth
                label={translation(
                  'central_payments.details.register_bank_data.account_number'
                )}
                type="number"
                name="accountNumber"
                variant="outlined"
                disabled={disabled}
                InputLabelProps={
                  formik.values.accountNumber
                    ? {
                        shrink: true
                      }
                    : {}
                }
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.accountNumber &&
                  Boolean(formik.errors.accountNumber)
                }
                helperText={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
              />
            </Box>
          </Box>

          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              size="medium"
              type="submit"
              variant="contained"
              disabled={disabled}
              className={classes.button}
            >
              {translation('central_payments.details.register_payment.save')}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default RegisterBankData;
