import React from 'react';
import { Box, Typography } from '@material-ui/core';

const TagSimpleTextOval = ({ title, color, stylesText }) => {
  return (
    <Box
      style={{
        backgroundColor: color,
        marginLeft: 6,
        borderRadius: 12
      }}
    >
      <Typography style={stylesText}>{title}</Typography>
    </Box>
  );
};
export default TagSimpleTextOval;
