import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useSelector } from 'react-redux';

const Header = () => {
  const translation = useTranslation();
  const restaurant = useSelector(state => state.account.restaurant);
  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app/centralPayments"
            component={RouterLink}
          >
            {translation('central_payments.title')}
          </Link>
          {restaurant.name && (
            <Link
              variant="body1"
              color="inherit"
              to="/app/centralPayments"
              component={RouterLink}
            >
              {restaurant.name}
            </Link>
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default Header;
