import React from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useParams } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import Page from 'src/components/Page';
import Header from './Header';
import { useMenuGalleryDocs } from '../../hooks/useMenuGallery';
import Gallery from './Gallery';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  containerHeaderSubsidiaries: {
    width: '100%',
    backgroundColor: 'white',
    height: '80px',
    padding: '0px 24px'
  },
  textTitle: {
    fontFamily: 'Nunito Sans',
    fontSize: '30px'
  }
}));

const MenuGallery = () => {
  const classes = useStyles();
  const translation = useTranslation();
  const { restId } = useParams();
  const menuGallery = useMenuGalleryDocs(restId);

  return (
    <Page
      className={classes.root}
      title={translation('menu.menu_section.gallery')}
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Card>
            <Box textAlign="center" mt={4}>
              <Typography className={classes.textTitle}>
                {translation('menu.menu_section.gallery')}
              </Typography>
            </Box>
            <Box p={6}>
              <Gallery menuDocs={menuGallery} restId={restId} />
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default MenuGallery;
