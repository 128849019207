/* eslint-disable no-nested-ternary,no-unneeded-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import FormHelperText from '@material-ui/core/FormHelperText';
import { PlusCircle } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import WithLoading from '../../../components/WithLoading';
import { allowOnlyNumbers } from '../../../utils/helpers/fieldFormat';
import AdditionItem from '../../../dto/additionItem';
import AdditionItemForm from './AdditionItemForm';
import { COLLECTIONS } from '../../../constants';
import firebaseService from '../../../services/firebaseService';
import additionService from '../../../services/additionsService';
import { logAddition } from '../../../services/logServices';
import { LOG_ACTIONS } from '../../../dto/enum';
import { getObjectChanges } from '../../../utils/object';
import env from '../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {}
}));

function AdditionEditForm({
  className,
  addition = {},
  onClose,
  availability = false,
  onSuccess,
  prodId,
  restaurantId,
  ...rest
}) {
  const classes = useStyles();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant] = useRestaurantAndSubsidiaryforMenu(restId, subId);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const saveAddition = async additionValues => {
    const id = firebaseService.randomId(COLLECTIONS.MENU_ADDITIONS);
    const newAddition = {
      ...additionValues,
      availability,
      id,
      restaurant_id: restaurantId,
      order: additionValues.options.length,
      created_at: moment().toDate(),
      enable: true
    };
    const changes = getObjectChanges(null, newAddition);
    const data = {
      addition: {
        id: newAddition.id,
        name: newAddition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      }
    };
    await logAddition(changes, LOG_ACTIONS.CREATE, data);
    await additionService.saveAddition(id, newAddition);
    if (onSuccess) onSuccess();
  };
  const editAddition = async newAddition => {
    await additionService.menuAddition(addition.menu_additions_id, {
      ...newAddition,
      availability
    });
    await additionService.updateAddition(addition.id, prodId, {
      ...newAddition,
      availability
    });
    const arrayToString = array => {
      const items = array.map(item => {
        const values = Object.values(item)
          .map(value => value)
          .join(',');
        return `(${values})`;
      });
      return items.join(',');
    };
    const changes = getObjectChanges(
      {
        ...addition,
        options: arrayToString(
          addition.options.map(({ name, price }) => ({ name, price }))
        )
      },
      {
        ...newAddition,
        options: arrayToString(
          newAddition.options.map(({ name, price }) => ({ name, price }))
        )
      }
    );
    const data = {
      addition: {
        id: addition.id,
        name: addition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      }
    };
    await logAddition(changes, LOG_ACTIONS.UPDATE, data);
    if (onSuccess) onSuccess();
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          name: addition.name || '',
          mandatory: addition.mandatory || false,
          multi_selection: addition.multi_selection || false,
          max_selection: addition.max_selection || '',
          options: addition.options || [],
          is_quantifiable: addition.is_quantifiable || false,
          mandatory_amount: addition.mandatory_amount || false,
          has_max_amount_addition: addition.has_max_amount_addition || false,
          single_addition: addition.single_addition || false
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required(translation('additions.form.addition_types.required')),
          max_selection: Yup.number()
            .min(
              1,
              translation(
                'additions.form.options_list.additions_max_number.required'
              )
            )
            .required(
              translation(
                'additions.form.options_list.additions_max_number.required'
              )
            ),
          options: Yup.array()
            .of(
              Yup.object().shape({
                price: Yup.number().min(0),
                name: Yup.string().required()
              })
            )
            .required(translation('additions.form.options_list.required'))
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            const formData = {
              ...values,
              options: values.options.map(currentOption => {
                const o = currentOption;
                delete o.initialyEditing;
                let { price } = o;
                price = parseFloat(price);
                return {
                  ...o,
                  price:
                    price === 0 || price === ''
                      ? 0.0
                      : parseFloat(price.toFixed(2) * 1)
                };
              })
            };
            if (addition.id) {
              await editAddition(formData);
            } else {
              await saveAddition(formData);
            }

            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(translation('customer_edit.correct_save'), {
              variant: 'success'
            });
            setLoading(false);
            if (onClose) {
              onClose();
            }
          } catch (e) {
            console.log(e);
            setStatus({ success: false });
            setSubmitting(false);
            if (e.status === 409) {
              enqueueSnackbar(translation('customer_edit.used_email'), {
                variant: 'error'
              });
              setErrors({ email: translation('customer_edit.email') });
            } else {
              enqueueSnackbar(
                `${translation('complete_restaurant.error')} ${
                  env.REACT_APP_SUPPORT_MAIL
                }`,
                {
                  variant: 'error'
                }
              );
              setErrors({ submit: e.message });
            }
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => {
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Box ml={3} mr={3}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={translation('additions.form.addition_types.name')}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>
                      <strong>
                        {translation('additions.form.mandatory.label')}
                      </strong>
                    </Typography>
                    <Box>
                      <FormControlLabel
                        label={translation('additions.form.mandatory.required')}
                        control={
                          <Radio
                            checked={values.mandatory}
                            onChange={e => {
                              if (e.target.value === 'true') {
                                setFieldValue('mandatory', true);
                              }
                            }}
                            value="true"
                            color="primary"
                            name="mandatory"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        label={translation('additions.form.mandatory.optional')}
                        control={
                          <Radio
                            checked={!values.mandatory}
                            onChange={e => {
                              if (e.target.value === 'false') {
                                setFieldValue('mandatory', false);
                                setFieldValue('mandatory_amount', false);
                                setFieldValue('single_addition', false);
                                setFieldValue('has_max_amount_addition', false);
                              }
                            }}
                            value="false"
                            color="primary"
                            name="mandatory"
                          />
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Typography>
                      <strong>
                        {translation('additions.form.multi_selection.title')}
                      </strong>
                    </Typography>
                    <Box>
                      <FormControlLabel
                        label={translation(
                          'additions.form.multi_selection.label'
                        )}
                        control={
                          <Checkbox
                            onChange={(e, checked) => {
                              setFieldValue('multi_selection', checked);
                              if (!checked)
                                setFieldValue('is_quantifiable', checked);
                            }}
                            nane="multi_selection"
                            color="primary"
                            checked={values.multi_selection}
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <TextField
                        error={Boolean(
                          touched.max_selection && errors.max_selection
                        )}
                        fullWidth
                        type="number"
                        inputProps={{
                          min: 0,
                          step: 1,
                          onKeyDown: allowOnlyNumbers
                        }}
                        helperText={
                          touched.max_selection && errors.max_selection
                        }
                        label={translation(
                          'additions.form.options_list.additions_max_number.name'
                        )}
                        name="max_selection"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.max_selection}
                        required
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} mb={3}>
                <Typography>
                  <strong>
                    {translation('additions.form.options_list.title')}
                  </strong>
                </Typography>
              </Box>
              <Box ml={3} mr={3} mb={3}>
                <FieldArray
                  name="options"
                  render={arrayHelpers => (
                    <Box>
                      {values.options &&
                        values.options.length > 0 &&
                        values.options.map((option, index) => (
                          <AdditionItemForm
                            max={values.max_selection}
                            showMax={values.has_max_amount_addition}
                            key={index}
                            addition={option}
                            index={index}
                            errors={
                              errors.options &&
                              typeof errors.options !== 'string'
                                ? errors.options[index]
                                : {}
                            }
                            onSave={(i, additionToSave) => {
                              arrayHelpers.replace(i, additionToSave);
                            }}
                            onDelete={i => arrayHelpers.remove(i)}
                            initialyEditing={option.initialyEditing}
                          />
                        ))}
                      <Grid container spacing={3}>
                        <Grid item md={9} xs={12} />
                        <Grid item md={3} xs={12}>
                          <Box display="flex" justifyContent="flex-end">
                            <IconButton
                              type="button"
                              onClick={() => {
                                arrayHelpers.push({
                                  ...new AdditionItem({
                                    id: firebaseService.randomId('option')
                                  }),
                                  initialyEditing: true
                                });
                              }}
                              className={classes.icon}
                            >
                              <PlusCircle />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                />
              </Box>
              {errors.options && typeof errors.options === 'string' && (
                <Box ml={3} mr={3} mb={3}>
                  <FormHelperText error>{errors.options}</FormHelperText>
                </Box>
              )}
              <Box ml={3} mr={3} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {translation(
                    addition.id
                      ? 'additions.form.submit_edit'
                      : 'additions.form.submit'
                  )}
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

AdditionEditForm.propTypes = {
  className: PropTypes.string
};

export default AdditionEditForm;
