import { Button } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../theme/colors';

const styledBy = (property, mapping) => props => mapping[props[property]];
const styles = {
  root: {
    height: 48,
    background: styledBy('check', {
      likedUncheck: COLORS.rateLikedUncheck,
      dislikedUncheck: COLORS.redCake,
      likedCheck: COLORS.successLight,
      dislikedCheck: COLORS.rateDislikedCheck
    }),
    border: styledBy('check', {
      likedUncheck: 0,
      dislikedUncheck: 0,
      likedCheck: '5px solid #E6E3E8',
      dislikedCheck: '5px solid #E6E3E8'
    }),
    borderRadius: 8,
    color: styledBy('check', {
      likedUncheck: COLORS.green,
      dislikedUncheck: COLORS.black,
      likedCheck: COLORS.success,
      dislikedCheck: COLORS.rateTextDislikedCheck
    }),
    margin: '4px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '5px 10px'
  }
};

const StyledButton = withStyles(styles)(({ classes, check, ...other }) => (
  <Button className={classes.root} {...other} />
));

const ButtonCheckRate = ({ danger, check, title, onClick }) => {
  return check ? (
    <StyledButton
      onClick={onClick}
      check={danger ? 'dislikedCheck' : 'likedCheck'}
    >
      {title}
    </StyledButton>
  ) : (
    <StyledButton
      onClick={onClick}
      check={danger ? 'dislikedUncheck' : 'likedUncheck'}
    >
      {title}
    </StyledButton>
  );
};

export default ButtonCheckRate;
