import Base from './base';

/* eslint camelcase: 0 */
export default class User extends Base {
  constructor({
    id,
    enabled = true,
    created_at,
    updated_at,
    first_name,
    last_name,
    phone_number,
    email,
    ci,
    location,
    profile_photo,
    roles,
    restaurant_id,
    subsidiary_id,
    addresses = [],
    current_device_id,
    current_device_id_web
  } = {}) {
    super(id, enabled, created_at, updated_at);
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone_number = phone_number;
    this.email = email;
    if (ci) this.ci = ci;
    if (location) this.location = location;
    if (profile_photo) this.profile_photo = profile_photo;
    if (roles) this.roles = roles;
    if (restaurant_id) this.restaurant_id = restaurant_id;
    if (subsidiary_id) this.subsidiary_id = subsidiary_id;
    if (addresses.length) this.addresses = addresses;
    if (current_device_id) this.current_device_id = current_device_id;
    this.current_device_id_web = current_device_id_web;
  }
}
