import { makeStyles } from '@material-ui/core';

export const useResultsStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    minWidth: 400
  },
  specialCard: {
    boxShadow: 'none',
    borderRadius: 16
  },
  tableExpress: {
    '& tbody td': {
      cursor: 'pointer'
    }
  },
  ordersTable: {
    '& th, & td': {
      fontSize: '1.1rem'
    },
    '& tbody td': {
      borderBottom: 'none',
      padding: 'unset',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '& > div': {
        padding: theme.spacing(3)
      },
      '&:first-child': {
        paddingLeft: theme.spacing(2)
      },
      '&:last-child': {
        paddingRight: theme.spacing(2)
      },
      '&:first-child > div': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
      },
      '&:last-child > div': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
      }
    },
    '& thead th': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '&:first-child': {
        paddingLeft: theme.spacing(4)
      },
      '&:last-child': {
        paddingRight: theme.spacing(4)
      }
    }
  },
  rowSelected: {
    '& td > div': {
      background: theme.palette.background.secondary,
      height: 88,
      alignItems: 'center'
    },
    '& td:first-child > div': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& td:last-child > div': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  rowDanger: {
    '& td > div': {
      background: theme.palette.background.red,
      height: 88,
      display: 'flex',
      alignItems: 'center'
    },
    '& td:first-child > div': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& td:last-child > div': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  gray: {
    color: theme.palette.text.tertiary
  },
  whatappIcon: {
    color: '#25d366'
  },
  phoneText: {
    marginRight: theme.spacing(1)
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
