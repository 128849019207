import React, { useMemo } from 'react';
import { Avatar, Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import SubsidiarySelector from '../TopBar/SubsidiarySelector';
import RecievingOrdersSwitch from '../TopBar/RecievingOrdersSwitch';
import TypeVehicleSelector from '../TopBar/TypeVehicleSelector';
import {
  useCurrentSubsidiary,
  useRestaurant
} from '../../../hooks/useRestaurant';
import { isOwner, isSupervisor } from '../../../utils/helpers/userHelper';
import Story from '../TopBar/story';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    borderRadius: 10
  },
  restaurantName: {
    margin: theme.spacing(1)
  }
}));

const RestaurantOptions = () => {
  const classes = useStyles();
  const restaurant = useRestaurant();
  const user = useSelector(state => state.account.user);
  const subsidiary = useCurrentSubsidiary();
  const canSelectVehicle = useMemo(() => {
    return (
      restaurant?.enable_vehicle_selector &&
      (isSupervisor(user) || isOwner(user))
    );
  }, [user, restaurant]);
  return (
    <Box m={2}>
      <Box display="flex" alignItems="center">
        <RouterLink to={`/app/configuration/${restaurant.id}`}>
          <Avatar
            alt="Restaurant"
            variant="rounded"
            className={classes.avatar}
            src={
              restaurant.logo_photo.x400
                ? restaurant.logo_photo.x400
                : restaurant.logo_photo.original
            }
          />
        </RouterLink>
        <Link
          component={RouterLink}
          to={`/app/configuration/${restaurant.id}`}
          variant="h5"
          color="textPrimary"
          underline="none"
          className={classes.restaurantName}
        >
          {restaurant.name}
        </Link>
      </Box>
      <SubsidiarySelector />
      {canSelectVehicle && <TypeVehicleSelector />}
      <RecievingOrdersSwitch />
      <Story
        name={`${restaurant?.name} - ${subsidiary?.name}` || ''}
        photo={restaurant?.logo_photo}
        city={subsidiary?.city?.name || ''}
        phoneNumber={
          subsidiary?.legal_representant?.phone ||
          subsidiary?.manager_phone ||
          subsidiary?.phone_number
        }
      />
    </Box>
  );
};

export default RestaurantOptions;
