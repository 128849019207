import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  // Box,
  Breadcrumbs,
  // Button,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';
import ModalCreateMember from './ModalCreateMember';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <ModalCreateMember show={showModal} onClose={() => setShowModal(false)} />
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app"
            component={RouterLink}
          >
            {translation('orders.details.header.board')}
          </Link>
          <Typography variant="body1" color="textPrimary">
            {translation('members.details.board_administration')}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {translation('personal.personal')}
          </Typography>
        </Breadcrumbs>
        <Typography style={{ marginTop: 20 }} variant="h3" color="textPrimary">
          {translation('personal.all')}
        </Typography>
      </Grid>
      {/* <Grid item>
        <Box
          style={{ height: '100%' }}
          display="flex"
          flexWrap="wrap"
          alignContent="flex-end"
        >
          <Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setShowModal(true)}
            >
              {translation('personal.new')}
            </Button>
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
