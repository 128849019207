import { useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import AdminNotification from 'src/dto/adminNotification';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ADMIN_NOTIFICATIONS } from 'src/actions/notificationsActions';
import { COLLECTIONS } from 'src/constants';
import { useCurrentUser } from './useUser';
import getDeviceLanguage from '../utils/languaje';

export const selectAdminNotifications = state =>
  state.notifications.adminNotifications;

export default function useAdminNotifications() {
  const notifications = useSelector(selectAdminNotifications);

  const dispatch = useDispatch();

  const user = useCurrentUser();

  const firestore = useFirestore();

  useEffect(() => {
    let unsubscribe = () => {};
    if (user.roles?.SUPER_ADMIN) {
      const language = getDeviceLanguage(true);
      unsubscribe = firestore
        .collection(COLLECTIONS.USER)
        .doc(user.id)
        .collection(COLLECTIONS.NOTIFICATIONS)
        .where('read', '==', false)
        .orderBy('created_at', 'desc')
        .limit(20)
        .onSnapshot(snap => {
          const mappedSnap = snap.docs.map(doc => {
            const data = doc.data();
            const title = data.titleTranslated
              ? data.titleTranslated[language]
              : data.title;
            return new AdminNotification({
              ...doc.data(),
              title,
              id: doc.id
            });
          });
          dispatch({
            type: GET_ADMIN_NOTIFICATIONS,
            payload: { notifications: mappedSnap }
          });
        });
    }
    return unsubscribe;
    // eslint-disable-next-line
    },[user.id])

  return notifications;
}
