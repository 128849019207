import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Button,
  Card,
  Typography,
  Box,
  Badge,
  Menu,
  MenuItem,
  Popover,
  Link as Link2
} from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon
} from 'react-feather';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import env from '../../../env';
import { useCockpitStyles } from './styles';
import XpressModal from '../../../components/Modal';
import ChatLayout from './modalChat';
import { useSubsidiariesMergedAndCurrentOrdered } from '../../../hooks/useRestaurant';
import ModalRequestDriver from './modalRequestDriver';

const menuUrl = '/app/editor/menu-sections';

const Cockpit = ({ chatNewMessagesAmount, isEnableChat }) => {
  const translation = useTranslation();
  const classes = useCockpitStyles();
  const ref = useRef(null);
  const history = useHistory();
  const isActive = history.location.pathname.includes(menuUrl);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [flagIcon, setFlagIcon] = useState(false);
  const subsidiaries = useSubsidiariesMergedAndCurrentOrdered();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setShowModal(false);
  };

  const setLayoutModal = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <ChatLayout handleClose={handleClose} />
      </XpressModal>
    );
  };
  const setLayoutModalForDriver = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <ModalRequestDriver
          handleClose={handleClose}
          subsidiaries={subsidiaries}
        />
      </XpressModal>
    );
  };
  const handleOpen = () => {
    if (subsidiaries.length > 1) {
      setOpen(true);
      setFlagIcon(true);
    } else {
      history.push(
        `/app/editor/menu-sections/restId/${subsidiaries[0].restaurantId}/subId/${subsidiaries[0].subsidiaryId}`
      );
    }
  };

  const handleCloseMenu = () => {
    setOpen(false);
    setFlagIcon(false);
  };

  return (
    <Box className={clsx(classes.container)}>
      <Card className={clsx(classes.card)}>
        <Button onClick={() => setLayoutModalForDriver()}>
          <Typography className={classes.text}>Pedir Moto</Typography>
        </Button>
      </Card>
      <Card className={clsx(classes.card, { [classes.active]: isActive })}>
        <Button onClick={handleOpen} ref={ref}>
          <Typography className={classes.text}>
            {translation('top_bar.menu')}
          </Typography>
          {!flagIcon && subsidiaries.length > 1 && <ChevronDownIcon />}
          {flagIcon && subsidiaries.length > 1 && <ChevronUpIcon />}
        </Button>
        {subsidiaries.length > 1 && (
          <Menu
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            PaperProps={{ className: classes.popover }}
            keepMounted
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
            {subsidiaries.map(sub => (
              <MenuItem
                component={Link}
                to={`/app/editor/menu-sections/restId/${sub.restaurantId}/subId/${sub.subsidiaryId}`}
                onClick={handleCloseMenu}
              >
                {sub.subsidiary_name}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Card>
      {isEnableChat && (
        <Badge
          color="primary"
          className={clsx(classes.chip)}
          badgeContent={chatNewMessagesAmount}
        >
          <Card className={clsx(classes.card)}>
            <Button onClick={() => setLayoutModal()}>
              <Typography className={classes.text}>Chat</Typography>
            </Button>
          </Card>
        </Badge>
      )}
      <Card className={clsx(classes.card)}>
        <Button
          size="large"
          className={classes.containerEntregas}
          endIcon={
            <InfoOutlinedIcon
              aria-describedby="popover-entregas"
              className={classes.iconInfo}
              onClick={handleClickPopover}
            />
          }
        >
          <Link2
            variant="body1"
            color="inherit"
            target="_blank"
            href={env.REACT_APP_XPRESS_MOTOS_URL}
          >
            <Typography className={classes.textEntregas}>
              Entregas.com
            </Typography>
          </Link2>
        </Button>
        <Popover
          id="popover-entregas"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Box p={2}>
            <Typography variant="body2">
              Redirección a la página de Entregas.com
            </Typography>
          </Box>
        </Popover>
      </Card>
      {showModal && modal}
    </Box>
  );
};

export default Cockpit;
