import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { COLLECTIONS } from '../../../constants';

export const useAllCities = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.CITY,
      storeAs: `all_cities`
    }
  ]);
  const allCities = useSelector(state => state.firestore.ordered.all_cities);

  return allCities;
};

export const useAllCitiesNative = () => {
  const [cities, setCities] = useState([]);
  useEffect(() => {
    return firebase
      .firestore()
      .collection(COLLECTIONS.CITY)
      .onSnapshot(snap => {
        setCities(snap.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      });
  }, []);
  return cities;
};

export const useCitiesNames = cities_id => {
  const [cities, setCities] = useState([]);
  useEffect(() => {
    if (cities_id.length > 0) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.CITY)
        .where('id', 'in', cities_id)
        .onSnapshot(snap => {
          setCities(snap.docs.map(doc => doc.data().name));
        });
    }
  }, [cities_id]);
  return cities;
};

export const useCities = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.CITY,
      storeAs: `cities`
    }
  ]);
  const cities = useSelector(state => state.firestore.ordered.cities);

  if (cities) {
    return cities;
  }

  return [];
};

export const useCity = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.CITY,
      doc: id,
      storeAs: `city_${id}`
    }
  ]);
  const city = useSelector(state => state.firestore.data[`city_${id}`]);
  return city;
};
