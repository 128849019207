/* eslint-disable */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-multi-lang';
import moment from 'moment';
import Switch from '../components/Inputs/Switch';
import ScheduleTimePicker from '../components/ScheduleTimePicker';
import { getDate } from '../utils/date';
import { COLORS } from '../theme/colors';

import { DEFAULT_START_HOUR_DATE } from '../constants';
import Schedule from '../dto/schedule';
import { updateSchedule } from '../actions/scheduleActions';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '100%'
  },
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  list: { width: '100%', padding: 0 },
  item: { display: 'flex', paddingLeft: 16 },
  week: {
    maxWidth: 120,
    minWidth: 80,
    paddingLeft: 10,
    fontWeight: 900,
    fontSize: 20
  },
  flex: { flex: 1 },
  center: { display: 'flex', justifyContent: 'center' },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  switch: {
    width: '100%',
    justifyContent: 'center'
  }
}));
const DEFAULT_NEW_DAY = journey =>
  new Schedule({
    schedule: [
      { start_time: DEFAULT_START_HOUR_DATE, end_time: DEFAULT_START_HOUR_DATE },
      {}
    ],
    open: true,
    journey: journey.toUpperCase(),
    enable: true
  });
const ScheduleView = ({
  currentSchedule = [],
  onChange = () => {},
  labelEnable = 'schedule.result.open',
  labelDisable = 'schedule.result.close'
}) => {
  const translation = useTranslation();
  const classes = useStyles();
  const WEEK_DAYS = [
    { value: 'monday', day: translation('schedule.result.days.monday') },
    { value: 'tuesday', day: translation('schedule.result.days.tuesday') },
    { value: 'wednesday', day: translation('schedule.result.days.wednesday') },
    { value: 'thursday', day: translation('schedule.result.days.thursday') },
    { value: 'friday', day: translation('schedule.result.days.friday') },
    { value: 'saturday', day: translation('schedule.result.days.saturday') },
    { value: 'sunday', day: translation('schedule.result.days.sunday') }
  ];
  const onChangeSchedule = (day, index, field) => ev => {
    const { value } = ev.target;

    const dayScheduleIndex = currentSchedule.findIndex(
      item => item.journey === day.toUpperCase()
    );

    const newSchedule = [...currentSchedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      ...newSchedule[dayScheduleIndex].schedule[index],
      [`${field}_time`]: moment(`2000 01 ${value}`, 'YYYY MM HH:mm').toDate()
    };
    onChange(newSchedule);
  };
  const handleOpenChange = day => () => {
    const dayExists = !!currentSchedule?.find(
      item => item.journey === day.value.toUpperCase()
    );
    if (dayExists) {
      const dayScheduleIndex = currentSchedule?.findIndex(
        item => item.journey === day.value.toUpperCase()
      );
      const newSchedule = [...currentSchedule];
      newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
      newSchedule[dayScheduleIndex].open = !newSchedule[dayScheduleIndex].open;
      onChange(newSchedule);
    } else {
      onChange([...currentSchedule, { ...DEFAULT_NEW_DAY(day.value) }]);
    }
  };

  function onTimeFrameAdd(day, index) {
    const dayScheduleIndex = currentSchedule?.findIndex(
      item => item.journey === day.toUpperCase()
    );

    const newSchedule = [...currentSchedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      start_time: DEFAULT_START_HOUR_DATE,
      end_time: DEFAULT_START_HOUR_DATE
    };
    onChange(newSchedule);
  }

  function onTimeFrameRemove(day, index) {
    const dayScheduleIndex = currentSchedule?.findIndex(
      item => item.journey === day.toUpperCase()
    );
    const newSchedule = [...currentSchedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {};
    onChange(newSchedule);
  }

  return (
    <PerfectScrollbar options={{ suppressScrollY: true }}>
      <div style={{ borderRadius: 16, padding: 0, backgroundColor: 'white' }}>
        <Table className={classes.table}>
          <TableBody>
            {WEEK_DAYS.map((day, i) => {
              const daySchedule =
                currentSchedule?.find(
                  item => item.journey === day.value.toUpperCase()
                ) || '';

              return (
                <TableRow key={i}>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      className={classes.week}
                      variant="h5"
                      color="textPrimary"
                    >
                      {`${day.day}:`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          onChange={handleOpenChange(day)}
                          checked={!!daySchedule.open}
                          color="primary"
                        />
                      }
                      label={
                        daySchedule.open
                          ? translation(labelEnable)
                          : translation(labelDisable)
                      }
                      labelPlacement="start"
                    />
                  </TableCell>
                  {daySchedule.schedule ? (
                    daySchedule.schedule.map((time, index) => (
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        key={index}
                      >
                        <div className={clsx(classes.flex, classes.center)}>
                          {daySchedule && (
                            <ScheduleTimePicker
                              onChangeFrom={onChangeSchedule(
                                day.value.toUpperCase(),
                                index,
                                'start'
                              )}
                              onChangeTo={onChangeSchedule(
                                day.value.toUpperCase(),
                                index,
                                'end'
                              )}
                              from={
                                time.start_time ? getDate(time.start_time) : ''
                              }
                              to={time.end_time ? getDate(time.end_time) : ''}
                              onIconPress={() =>
                                onTimeFrameRemove(
                                  day.value.toUpperCase(),
                                  index
                                )
                              }
                              onAddPress={() =>
                                onTimeFrameAdd(day.value.toUpperCase(), index)
                              }
                            />
                          )}
                        </div>
                      </TableCell>
                    ))
                  ) : (
                    <>
                      <TableCell className={classes.tableCell} />
                      <TableCell className={classes.tableCell} />
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </PerfectScrollbar>
  );
};

export default ScheduleView;
