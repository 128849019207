import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

const COLLECTION = COLLECTIONS.PROMOS;

class PromoService {
  savePromoDB = async (id, data) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({ ...data });

  createPromoDb = async data => {
    const id = firebaseService.randomId(COLLECTION);
    return firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .set({ id, ...data });
  };

  isUniquePromo = async (promo, id) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .get()
      .then(querySnapshot => {
        let repeated = false;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.promo_code === promo && data.id !== id) {
            repeated = true;
          }
        });
        return repeated;
      });
}
const promoService = new PromoService();
export default promoService;
