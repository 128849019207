// eslint-disable-next-line no-unused-vars
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles, TextField } from '@material-ui/core';
import { ChevronDown } from 'react-feather';
import { COLORS } from '../../theme/colors';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: COLORS.primary,
      borderWidth: 2
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.primary,
      borderWidth: 2
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 2
      },
      '&:hover fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 2
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.primary,
        borderWidth: 2
      }
    }
  }
})(TextField);
const useStyles = makeStyles(() => ({
  labelStyle: {
    color: COLORS.primary,
    fontWeight: 900
  },
  icon: {
    color: COLORS.primary,
    marginRight: 4
  },
  option: {
    '& hover': {
      backgroundColor: 'red'
    }
  }
}));
const SelectWithBorder = ({
  label,
  value,
  options = [],
  onChange = () => {},
  itemValueName = 'value',
  itemLabelName = 'label'
}) => {
  const classes = useStyles();
  return (
    <CssTextField
      label={label}
      name="sort"
      onChange={onChange}
      select
      SelectProps={{
        native: true,
        IconComponent: () => <ChevronDown size={35} className={classes.icon} />
      }}
      value={value}
      variant="outlined"
      InputLabelProps={{
        className: classes.labelStyle
      }}
    >
      {options.map(option => (
        <option key={option[itemValueName]} value={option[itemValueName]}>
          {option[itemLabelName]}
        </option>
      ))}
    </CssTextField>
  );
};
export default SelectWithBorder;
