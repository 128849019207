import React, { useState, useEffect } from 'react';
import {
  Typography,
  TableRow,
  TableCell,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { USER_ROLES } from '../../../dto/enum';

const RowTable = ({ price, key, index, classes, priceArray, rol }) => {
  const [price_area] = useState(price);
  const [render, setRender] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const translation = useTranslation();

  const validationRow = (result, priceRow) => {
    let flagButton = false;
    if (result > priceRow || isNaN(result) || result < 0) {
      flagButton = true;
      priceArray({ result, index, flagButton });
    } else {
      flagButton = false;
      priceArray({ result, index, flagButton });
    }
  };

  const dataChange = (event, row, field) => {
    let result = event.target.value;
    if (typeof row[field] === 'number') result = parseFloat(result);
    validationRow(result, row.price);
    setRender(!render);
  };

  useEffect(() => {
    if (rol === USER_ROLES.OWNER) {
      setDisabledButton(false);
    }
  }, [rol]);

  return (
    <TableRow key={index + 1}>
      <TableCell className={classes.TableCell}>
        <Typography>{price_area.distance}</Typography>
      </TableCell>
      <TableCell className={classes.TableCell}>
        <Typography>
          {isNaN(price_area.subsidiarySubsidy)
            ? price_area.price
            : price_area.price - price_area.subsidiarySubsidy}
        </Typography>
      </TableCell>
      <TableCell className={classes.TableCell}>
        <TextField
          key={key}
          type="number"
          variant="outlined"
          error={Boolean(isNaN(price_area.subsidiarySubsidy))}
          style={{ width: '100%' }}
          defaultValue={price_area.subsidiarySubsidy}
          InputProps={{ inputProps: { min: 0, max: price_area.price } }}
          onChange={
            event =>
              dataChange(
                event,
                price_area,
                'subsidiarySubsidy'
              ) /* onChange(DOM, row) */
          }
          disabled={disabledButton}
        />
        {price_area.subsidiarySubsidy > price_area.price && (
          <FormHelperText
            error
            style={{
              color: 'red',
              marginRight: '65px'
            }}
          >
            <Typography>
              {translation('subsidy_delivery.subsidy_form.limit_subsidy')}
            </Typography>
          </FormHelperText>
        )}
        {price_area.subsidiarySubsidy < 0 && (
          <FormHelperText
            error
            style={{
              color: 'red',
              marginRight: '65px'
            }}
          >
            <Typography>
              {translation('subsidy_delivery.subsidy_form.lower_limit')}
            </Typography>
          </FormHelperText>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RowTable;
