import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = prod => {
  const {
    sectionName,
    className,
    sectionId,
    prodId,
    blockCashier,
    ...rest
  } = prod;
  const classes = useStyles();
  const history = useHistory();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant, subsidiary] = useRestaurantAndSubsidiaryforMenu(
    restId,
    subId
  );
  const isTemplate = useIsTemplateMenu();
  const translation = useTranslation();
  const prefix = isTemplate
    ? `/app/management/shared_menu/restId/${restId}`
    : `/app/editor/menu-sections/restId/${restId}/subId/${subId}`;
  const url = history.location ? history.location.state?.url : null;
  const tab = history.location ? history.location.state?.tab : null;

  const { SUPER_ADMIN } = useSelector(state => state.account.user.roles);

  const getURL = () => {
    if (SUPER_ADMIN && tab)
      history.push({
        pathname: url,
        state: {
          tab
        }
      });
    if (SUPER_ADMIN && !tab) history.goBack();
    if (!SUPER_ADMIN) {
      history.push({
        pathname: `${prefix}/${sectionId}`,
        state: {
          sectionId,
          prodId
        }
      });
    }
  };

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          {!SUPER_ADMIN && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              mb={2}
            >
              <Link
                variant="body1"
                color="inherit"
                to={prefix}
                component={RouterLink}
              >
                {translation('menu.menu_section.label')}
              </Link>
              <Link
                variant="body1"
                color="inherit"
                to={{
                  pathname: `${prefix}/${sectionId}`,
                  state: { sectionId }
                }}
                component={RouterLink}
              >
                {sectionName}
              </Link>
            </Breadcrumbs>
          )}
          <Typography color="textPrimary">
            {translation('edit.header.advice')}
          </Typography>
        </Grid>
        <Grid item>
          {isTemplate ? (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''}`}
            </Typography>
          ) : (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''} - ${subsidiary.name || ''}`}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button onClick={getURL} style={{ cursor: 'pointer' }}>
            {translation('back')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {blockCashier && (
          <Alert severity="warning">
            {translation('information.block_Cashier')}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
