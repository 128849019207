import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { COLORS } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 23,
    fontWeight: 500
  },
  titleTable: {
    fontSize: 16,
    fontWeight: 700,
    borderColor: COLORS.green,
    borderWidth: 0.5
  },
  titleContainer: {
    cursor: 'pointer',
    flexDirection: 'row',
    userSelect: 'none'
  }
}));
const ExpressTableGenerate = ({
  needTranslation = true,
  listHeader = [],
  data = []
}) => {
  const translation = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid>
      {data?.length > 0 && (
        <Grid>
          <TableContainer>
            <Table>
              {listHeader && (
                <TableHead>
                  <TableRow>
                    {listHeader.map(item => (
                      <TableCell className={classes.titleTable}>
                        {needTranslation ? translation(item.title) : item.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {paginatedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {listHeader?.map(item => (
                      <TableCell
                        style={{ borderColor: COLORS.green, borderWidth: 0.5 }}
                      >
                        {row[item.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Historial por páginas"
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default ExpressTableGenerate;
