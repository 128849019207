import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../constants';

// eslint-disable-next-line import/prefer-default-export
export const useMasterSchedule = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SETTINGS,
      doc: 'master_schedule',
      storeAs: `master_schedule`
    }
  ]);
  const masterSchedule = useSelector(
    state => state.firestore.ordered.master_schedule
  );
  if (masterSchedule) return masterSchedule[0];
  return masterSchedule;
};
