/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  Checkbox,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import WithLoading from '../../../../components/WithLoading';
import dealerService from '../../../../services/dealerService';
import BANKS from '../../../../assets/files/banks';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  }
}));

function DealersEditForm({ className, dealer = {}, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const saveDealer = async dealerForm => {
    setLoading(true);
    const dealerData = {
      ...dealer,
      first_name: dealerForm.first_name,
      last_name: dealerForm.last_name,
      driver_information: {
        ...dealer.driver_information,
        ci_number: dealerForm.ci_number,
        license_number: dealerForm.license_number,
        plate_number: dealerForm.plate_number,
        transportation_type: dealerForm.transportation_type,
        license_movement: dealerForm.license_movement,
        vehicle_manufacturer: dealerForm.vehicle_manufacturer,
        vehicle_model: dealerForm.vehicle_model,
        vehicle_year: dealerForm.vehicle_year
      },
      bank_name: dealerForm.bank_name,
      bank_account: dealerForm.bank_account,
      bank_account_type: dealerForm.bank_account_type,
      updated_at: moment().toDate()
    };
    if (dealer.id) {
      return dealerService.editDealer(dealer.id, dealerData);
    }
  };

  const getType = option => {
    let type = '';
    switch (option) {
      case 'MOTO':
        type = translation('admin_dealers.type_transportation.motorcycle');
        break;
      case 'BICYCLE':
        type = translation('admin_dealers.type_transportation.bicycle');
        break;
      default:
        type = translation('admin_dealers.type_transportation.car');
        break;
    }
    return type;
  };

  const getAccountOption = option => {
    let type = '';
    switch (option) {
      case 'SAVINGS_ACCOUNT':
        type = translation('admin_dealers.bank_account_type.savings_account');
        break;
      case 'CURRENT_ACCOUNT':
        type = translation('admin_dealers.bank_account_type.current_account');
        break;
      default:
        break;
    }
    return type;
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          first_name: dealer.first_name || '',
          last_name: dealer.last_name || '',
          ci_number: dealer.driver_information.ci_number || '',
          license_number: dealer.driver_information.license_number || '',
          plate_number: dealer.driver_information.plate_number
            ? dealer.driver_information.plate_number.toUpperCase()
            : '',
          transportation_type: dealer.driver_information
            ? dealer.driver_information.transportation_type
            : '',
          license_movement: dealer.driver_information
            ? dealer.driver_information.license_movement
            : false,
          bank_name: dealer.bank_name || '',
          bank_account: dealer.bank_account || '',
          bank_account_type: dealer.bank_account_type || '',
          vehicle_manufacturer:
            dealer.driver_information.vehicle_manufacturer || '',
          vehicle_model: dealer.driver_information.vehicle_model || '',
          vehicle_year: dealer.driver_information.vehicle_year || ''
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string()
            .max(255)
            .required(
              translation('admin_dealers.information.first_name.require')
            ),
          last_name: Yup.string()
            .max(255)
            .required(
              translation('admin_dealers.information.last_name.require')
            ),
          ci_number: Yup.string()
            .max(255)
            .required(
              translation('admin_dealers.information.ci_number.require')
            ),
          license_number: Yup.string()
            .max(255)
            .required(
              translation('admin_dealers.information.license_number.require')
            ),
          plate_number: Yup.string()
            .max(255)
            .when('transportation_type', {
              // eslint-disable-next-line consistent-return
              is: transportation_type => {
                if (
                  transportation_type === 'CAR' ||
                  transportation_type === 'MOTO'
                )
                  return true;
              },
              then: Yup.string().required(
                translation('admin_dealers.information.plate_number.require')
              )
            }),
          transportation_type: Yup.string()
            .max(255)
            .required(
              translation(
                'admin_dealers.information.transportation_type.require'
              )
            ),
          license_movement: Yup.boolean(),
          bank_name: Yup.string().max(255),
          bank_account: Yup.number().min(0),
          bank_account_type: Yup.string().max(255),
          vehicle_manufacturer: Yup.string().max(255),
          vehicle_model: Yup.string().max(255),
          vehicle_year: Yup.number().min(1900)
        })}
        onSubmit={async (
          currentValues,
          { setErrors, setStatus, setSubmitting }
        ) => {
          const values = currentValues;
          try {
            setLoading(true);
            if (values.vehicle_manufacturer)
              values.vehicle_manufacturer = values.vehicle_manufacturer.toUpperCase();
            if (values.vehicle_model)
              values.vehicle_model = values.vehicle_model.toUpperCase();
            await saveDealer(values);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(
              translation('admin_dealers.dealerEditView.correct_save'),
              {
                variant: 'success'
              }
            );
            setLoading(false);
            history.push('/admin/dealers');
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar(
              `${translation('admin_dealers.dealerEditView.error')} ${
                env.REACT_APP_SUPPORT_MAIL
              }`,
              {
                variant: 'error'
              }
            );
            setErrors({ submit: e.message });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
          const typeTranspotation = ['CAR', 'MOTO', 'BICYCLE'];
          const bankAccountType = ['SAVINGS_ACCOUNT', 'CURRENT_ACCOUNT'];
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        label={translation(
                          'admin_dealers.information.first_name.first_name'
                        )}
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        label={translation(
                          'admin_dealers.information.last_name.last_name'
                        )}
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.ci_number && errors.ci_number)}
                        fullWidth
                        helperText={touched.ci_number && errors.ci_number}
                        label={translation(
                          'admin_dealers.information.ci_number.ci_number'
                        )}
                        name="ci_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ci_number}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.license_number && errors.license_number
                        )}
                        fullWidth
                        helperText={
                          touched.license_number && errors.license_number
                        }
                        label={translation(
                          'admin_dealers.information.license_number.license_number'
                        )}
                        name="license_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.license_number}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    {(values.transportation_type === 'CAR' ||
                      values.transportation_type === 'MOTO') && (
                      <>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.plate_number && errors.plate_number
                            )}
                            fullWidth
                            helperText={
                              touched.plate_number && errors.plate_number
                            }
                            label={translation(
                              'admin_dealers.information.plate_number.plate_number'
                            )}
                            name="plate_number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.plate_number}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.transportation_type &&
                            errors.transportation_type
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.transportation_type.transportation_type'
                        )}
                        name="transportation_type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.transportation_type}
                        variant="outlined"
                      >
                        <option value="" />
                        {typeTranspotation.map(option => (
                          <option key={option} value={option}>
                            {getType(option)}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.bank_name && errors.bank_name)}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.bank_name'
                        )}
                        name="bank_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.bank_name}
                        variant="outlined"
                      >
                        <option value="" />
                        {BANKS.map(bank => (
                          <option key={bank.id} value={bank.name}>
                            {bank.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.bank_account && errors.bank_account
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.bank_account'
                        )}
                        name="bank_account"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bank_account}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.bank_account_type && errors.bank_account_type
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.bank_account_type'
                        )}
                        name="bank_account_type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.bank_account_type}
                        variant="outlined"
                      >
                        <option value="" />
                        {bankAccountType.map(option => (
                          <option key={option} value={option}>
                            {getAccountOption(option)}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.vehicle_manufacturer &&
                            errors.vehicle_manufacturer
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.vehicle_manufacturer'
                        )}
                        name="vehicle_manufacturer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vehicle_manufacturer}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.vehicle_model && errors.vehicle_model
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.vehicle_model'
                        )}
                        name="vehicle_model"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vehicle_model}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.vehicle_year && errors.vehicle_year
                        )}
                        fullWidth
                        label={translation(
                          'admin_dealers.information.vehicle_year'
                        )}
                        placeholder="yyyy"
                        name="vehicle_year"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vehicle_year}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                          checked={values.license_movement}
                          name="license_movement"
                          onChange={handleChange}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {translation(
                            'admin_dealers.information.license_movement'
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation('save')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

DealersEditForm.propTypes = {
  className: PropTypes.string,
  dealer: PropTypes.object
};

export default DealersEditForm;
