import React from 'react';
import { useParams } from 'react-router';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../../components/Page';
import { COLLECTIONS } from '../../../../constants';
import Header from './Header';
import DealersView from '../DealersEditView/DealersView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
const DealersDetailView = props => {
  const classes = useStyles();
  const translation = useTranslation();
  const params = useParams();
  useFirestoreConnect({
    collection: COLLECTIONS.USER,
    doc: params.id
  });

  const dealerStore = useSelector(state => {
    if (state.firestore.data.users && state.firestore.data.users[params.id]) {
      return state.firestore.data.users[params.id];
    }
  });
  let dealer = {};
  if (params.id) {
    if (!dealerStore) return null;
    dealer = {
      id: params.id,
      ...dealerStore
    };
  }

  return (
    <Page
      className={classes.root}
      title={translation('admin_dealers.title_detail')}
    >
      <Container maxWidth="lg">
        <Header dealer={dealer} />
        <Box mt={3}>
          <DealersView dealer={dealer} />
        </Box>
      </Container>
    </Page>
  );
};

export default DealersDetailView;
