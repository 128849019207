/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import _ from 'lodash';
import { useAllActiveRestaurants } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import YellowTextField from '../../../../components/Inputs/TextField';
import { allowOnlyNumbers } from '../../../../utils/helpers/fieldFormat';
import promoService from '../../../../services/promoService';
import { TYPE_OF_DISCOUNT } from '../../../../dto/enum';
import WithLoading from '../../../../components/WithLoading';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  }
}));

// eslint-disable-next-line no-unused-vars
function PromosEditForm({
  className,
  promo = {},
  areas = [],
  cities = [],
  def = {},
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const today = moment();
  const discountsTypes = [
    {
      value: TYPE_OF_DISCOUNT.PERCENTAGE,
      label: translation('admin_promos.percentage')
    },
    {
      value: TYPE_OF_DISCOUNT.CASH,
      label: translation('admin_promos.cash')
    }
  ];
  const restaurants = useAllActiveRestaurants();
  const savePromo = async promoForm => {
    setLoading(true);
    const data = {
      ...promoForm,
      price_areas: promoForm.price_areas.map(p => p.id),
      updated_at: moment().toDate()
    };
    if (promo.id) {
      return promoService.savePromoDB(promo.id, data);
    }
    data.enable = true;
    data.created_at = moment().toDate();
    return promoService.createPromoDb(data);
  };

  const invalidPromo = async promo_code => {
    const repeated = await promoService.isUniquePromo(promo_code, promo.id);
    if (repeated) return translation('admin_promos.promo_code.repeated');
  };

  const invalidLastDate = expireDate => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const expireDatePromo = promo.expire_date ? promo.expire_date.toDate() : '';
    if (expireDatePromo === expireDate) return;
    if (expireDate < currentDate)
      return translation('admin_promos.expire_date.past');
  };

  let price_areas = [];
  if (promo.id && promo.price_areas && areas.length) {
    price_areas = promo.price_areas.map(p => areas.find(a => a.id === p));
  }

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          promo_code: promo.promo_code || '',
          title: promo.title || '',
          description: promo.description || '',
          type_of_discount: promo.type_of_discount || TYPE_OF_DISCOUNT.CASH,
          amount: promo.amount || '',
          minimum_amount: promo.minimum_amount || '',
          expire_date: promo.expire_date ? promo.expire_date.toDate() : '',
          available: _.isEmpty(promo) ? true : promo.available,
          limited: _.isEmpty(promo) ? true : promo.limited,
          restaurants: promo.restaurants || [],
          price_areas
        }}
        validationSchema={Yup.object().shape({
          promo_code: Yup.string()
            .max(255)
            .required(translation('admin_promos.promo_code.required')),
          title: Yup.string()
            .max(255)
            .required(translation('admin_promos.title_field.required')),
          description: Yup.string()
            .max(255)
            .required(translation('admin_promos.description.required')),
          type_of_discount: Yup.mixed()
            .oneOf([TYPE_OF_DISCOUNT.PERCENTAGE, TYPE_OF_DISCOUNT.CASH])
            .required(translation('admin_promos.type_of_discount.required')),
          amount: Yup.number()
            .when('type_of_discount', {
              is: TYPE_OF_DISCOUNT.PERCENTAGE,
              then: Yup.number().max(
                100,
                translation('admin_promos.amount.max')
              )
            })
            .min(1, translation('admin_promos.amount.required'))
            .required(translation('admin_promos.amount.required')),
          minimum_amount: Yup.number()
            .when('type_of_discount', {
              is: TYPE_OF_DISCOUNT.CASH, // alternatively: (val) => val == true
              then: Yup.number().test(
                'customTest',
                translation('admin_promos.minimum_amount.required_amount'),
                function testPrev(value) {
                  // eslint-disable-next-line react/no-this-in-sfc
                  return this.parent.amount < value;
                }
              ),
              otherwise: Yup.number().min(
                1,
                translation('admin_promos.minimum_amount.required')
              )
            })
            .required(translation('admin_promos.minimum_amount.required')),
          expire_date: Yup.date()
            .typeError(translation('admin_promos.expire_date.format'))
            .required(translation('admin_promos.expire_date.required')),
          available: Yup.bool(),
          limited: Yup.bool(),
          restaurants: Yup.array().required(
            translation('admin_promos.restaurant.required')
          ),
          price_areas: Yup.array().required('Areas son requeridas')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            const errorCode = await invalidPromo(values.promo_code);
            if (errorCode) {
              setErrors({ promo_code: errorCode });
              setSubmitting(false);
              setLoading(false);
              return;
            }
            const errorLastDate = invalidLastDate(values.expire_date);
            if (errorLastDate) {
              setErrors({ expire_date: errorLastDate });
              setSubmitting(false);
              setLoading(false);
              return;
            }

            await savePromo(values);

            setStatus({ success: true });
            setSubmitting(false);
            // resetForm();
            enqueueSnackbar(translation('admin_promos.correct_save'), {
              variant: 'success'
            });
            setLoading(false);
            history.push('/admin/promos');
          } catch (e) {
            console.log(e);
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar(
              `${translation('complete_restaurant.error')} ${
                env.REACT_APP_SUPPORT_MAIL
              }`,
              {
                variant: 'error'
              }
            );
            setErrors({ submit: e.message });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.promo_code && errors.promo_code)}
                        fullWidth
                        helperText={touched.promo_code && errors.promo_code}
                        label={translation('admin_promos.promo_code.name')}
                        inputProps={{
                          maxLength: 8
                        }}
                        name="promo_code"
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue(
                            'promo_code',
                            e.target.value
                              .trim()
                              .replace(/[^\w\s]|_/g, '')
                              .toUpperCase()
                          );
                        }}
                        value={values.promo_code}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        label={translation('admin_promos.title_field.name')}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        multiline
                        rows={2}
                        helperText={touched.description && errors.description}
                        label={translation('admin_promos.description.name')}
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name="type_of_discount"
                        onChange={handleChange}
                        select
                        label={translation(
                          'admin_promos.type_of_discount.name'
                        )}
                        SelectProps={{ native: true }}
                        value={values.type_of_discount}
                        variant="outlined"
                        error={Boolean(
                          touched.type_of_discount && errors.type_of_discount
                        )}
                        helperText={
                          touched.type_of_discount && errors.type_of_discount
                        }
                      >
                        {discountsTypes.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.margin}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {translation('admin_promos.amount.name')}
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(touched.amount && errors.amount)}
                          id="outlined-adornment-amount"
                          value={values.amount}
                          onBlur={() => {
                            let value = '';
                            // eslint-disable-next-line no-restricted-globals
                            if (!isNaN(values.amount)) {
                              value = Number.isInteger(values.amount)
                                ? values.amount
                                : parseFloat(values.amount).toFixed(2);
                            }
                            setFieldValue('amount', value);
                          }}
                          onChange={handleChange('amount')}
                          startAdornment={
                            <InputAdornment position="start">
                              {values.type_of_discount === TYPE_OF_DISCOUNT.CASH
                                ? 'Bs.'
                                : '%'}
                            </InputAdornment>
                          }
                          label={translation('admin_promos.amount.name')}
                          type="number"
                          inputProps={{
                            min: 0,
                            step: 1,
                            onKeyDown: allowOnlyNumbers
                          }}
                          name="amount"
                        />
                        {Boolean(touched.amount && errors.amount) && (
                          <FormHelperText error>
                            {touched.amount && errors.amount}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <KeyboardDatePicker
                        variant="inline"
                        error={Boolean(
                          touched.expire_date && errors.expire_date
                        )}
                        fullWidth
                        autoOk
                        placeholder={today.format('DD/MM/YYYY')}
                        inputVariant="outlined"
                        helperText={touched.expire_date && errors.expire_date}
                        label={translation('admin_promos.expire_date.name')}
                        name="expire_date"
                        onBlur={handleBlur}
                        format="DD/MM/YYYY"
                        minDate={moment()}
                        onChange={date => {
                          setFieldValue(
                            'expire_date',
                            date ? date.toDate() : undefined
                          );
                        }}
                        value={values.expire_date ? values.expire_date : null}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.margin}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {translation('admin_promos.minimum_amount.name')}
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(
                            touched.minimum_amount && errors.minimum_amount
                          )}
                          id="outlined-adornment-minimum_amount"
                          value={values.minimum_amount}
                          onChange={handleChange('minimum_amount')}
                          startAdornment={
                            <InputAdornment position="start">
                              Bs.
                            </InputAdornment>
                          }
                          label={translation(
                            'admin_promos.minimum_amount.name'
                          )}
                          type="number"
                          inputProps={{
                            min: 0,
                            step: 1,
                            onKeyDown: allowOnlyNumbers
                          }}
                          name="minimum_amount"
                        />
                        {Boolean(
                          touched.minimum_amount && errors.minimum_amount
                        ) && (
                          <FormHelperText error>
                            {touched.minimum_amount && errors.minimum_amount}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        id="combo-box-areas"
                        options={areas}
                        multiple
                        filterSelectedOptions
                        getOptionLabel={area => {
                          if (!area) return;
                          const city = cities.find(c => c.id === area.city_id);
                          return `${area.name} (${city ? city.name : ''})`;
                        }}
                        className={classes.selector}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Areas"
                            variant="outlined"
                            error={Boolean(
                              touched.price_areas && errors.price_areas
                            )}
                            helperText={
                              touched.price_areas && errors.price_areas
                            }
                          />
                        )}
                        value={values.price_areas}
                        onChange={(e, d) => {
                          setFieldValue('price_areas', d);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.available}
                            onChange={handleChange}
                            value={values.available}
                            name="available"
                          />
                        }
                        label={translation('admin_promos.available')}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.limited}
                            onChange={handleChange}
                            value={values.limited}
                            name="limited"
                          />
                        }
                        label={translation('admin_promos.limited')}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={restaurants || []}
                        getOptionLabel={s => {
                          if (!s) return;
                          return s.name;
                        }}
                        filterSelectedOptions
                        value={values.restaurants.map(v =>
                          restaurants.find(r => v === r.id)
                        )}
                        onChange={(e, res) => {
                          setFieldValue(
                            'restaurants',
                            res.map(s => s.id)
                          );
                        }}
                        renderInput={params => (
                          <YellowTextField
                            {...params}
                            variant="outlined"
                            label={translation('admin_promos.restaurant.name')}
                            error={Boolean(
                              touched.restaurants && errors.restaurants
                            )}
                            helperText={
                              touched.restaurants && errors.restaurants
                            }
                            placeholder={translation(
                              'admin_promos.restaurant.name'
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item />
                  </Grid>

                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation('save')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

PromosEditForm.propTypes = {
  className: PropTypes.string,
  promo: PropTypes.object
};

export default PromosEditForm;
