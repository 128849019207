import { makeStyles } from '@material-ui/core';

export const usePricesStyles = makeStyles(theme => ({
  subsidiaryContainer: {
    alignContent: 'flex-start'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: `0 ${theme.spacing(2)}px`,
    marginRight: theme.spacing(2)
  },
  activeRowContainer: {
    backgroundColor: theme.palette.expressColors.greenCake
  },
  areaContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cockpitContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  area: {
    fontSize: 14,
    fontStyle: 'italic'
  },
  button: {
    fontWeight: 'bold',
    minWidth: 120
  },
  removeButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.expressColors.cakeRed
  },
  newButton: {
    backgroundColor: theme.palette.expressColors.green,
    marginBottom: theme.spacing(2),
    fontColor: theme.palette.expressColors.white
  },
  modal: {
    marginTop: '5%',
    marginLeft: '5%',
    width: '90%',
    justifyContent: 'center',
    maxHeight: 600,
    overflowY: 'scroll'
  },
  addPriceModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`
  },
  helperText: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tableHelper: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  tableHelperArea: {
    fontSize: 14,
    fontWeight: 'regular',
    fontStyle: 'italic',
    marginLeft: theme.spacing(1)
  },
  tableHelperContainer: {
    display: 'flex',
    alignItems: 'baseline'
  }
}));

export const useAddPriceStyles = makeStyles(theme => ({
  label: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  areasContainer: {
    alignContent: 'flex-start'
  },
  areaActiveRowContainer: {
    backgroundColor: theme.palette.expressColors.greenCake
  },
  setAreaRowContainer: {
    backgroundColor: theme.palette.expressColors.yellowCake
  },
  areaRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: `0 ${theme.spacing(2)}px`,
    marginRight: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(4),
    paddingTop: 0
  },
  button: {
    width: 100,
    fontWeight: 'bold'
  },
  setAreaButton: {
    backgroundColor: theme.palette.expressColors.green
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  tableHelper: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  newButton: {
    minWidth: 120,
    backgroundColor: theme.palette.expressColors.green,
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontColor: theme.palette.expressColors.white
  },
  tableTitleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  newPriceModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`
  }
}));

export const useNewPriceStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'center',
    flexDirection: 'column',
    minWidth: 600
  },
  submit: {
    display: 'flex',
    justifyContent: 'center'
  },
  lastButton: {
    marginLeft: theme.spacing(2)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold'
  }
}));
