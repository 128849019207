import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-multi-lang/lib';

const TermsAndConditions = ({ showTerms, setShowTerms, agreeTerms }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const translation = useTranslation();

  return (
    <Dialog
      open={showTerms}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={() => setShowTerms(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography variant="h2" color="textPrimary">
          {translation('terms_conditions.title')}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          id="alert-dialog-slide-description"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          nisl lectus, scelerisque vel magna at, commodo pulvinar risus. Aliquam
          erat volutpat. Proin semper euismod felis, ut semper justo sagittis
          quis. Duis vehicula enim ut erat lobortis elementum. Ut dolor metus,
          ornare a ipsum eu, semper luctus diam. Suspendisse a pretium dui. Sed
          vehicula dolor turpis, a consectetur magna ornare sit amet. Cras
          luctus eget ipsum id vulputate. Nam luctus sem est, a sollicitudin
          nibh tincidunt quis. Pellentesque tincidunt purus enim, non luctus
          ipsum vulputate sit amet. Duis condimentum libero vel ligula efficitur
          auctor. Duis eleifend urna in eleifend tristique. Vestibulum rutrum,
          odio id cursus volutpat, nibh diam vehicula ante, id semper augue nisi
          mattis massa. In dignissim, massa eget sollicitudin malesuada, felis
          ipsum tristique dui, vitae malesuada mi sem in mauris. Integer semper
          lacus mattis, scelerisque arcu vel, interdum tortor. Donec ut sodales
          magna, eget dignissim nulla. In lacinia rhoncus nunc vitae semper. Nam
          eget dignissim elit, sit amet pharetra nulla. Maecenas libero justo,
          condimentum ut sollicitudin vitae, maximus a massa. Ut pretium, turpis
          vel tristique mollis, nibh velit scelerisque sem, ut hendrerit augue
          nisl et lacus. Duis erat magna, malesuada non pretium a, suscipit quis
          tortor. Praesent vitae elementum nulla, nec scelerisque metus. Nulla
          felis ante, interdum ut magna sed, commodo pretium sem. Fusce vitae
          interdum urna. Quisque auctor, felis eget condimentum consectetur,
          turpis risus pulvinar massa, bibendum semper arcu risus sit amet
          purus. Proin molestie vehicula nulla. Maecenas sed ultrices elit.
          Mauris mollis mauris vel commodo fringilla. Etiam volutpat pharetra
          maximus. Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos. Vivamus ut sapien at arcu fermentum
          fringilla. Maecenas in commodo lacus, quis semper orci. Nullam lectus
          lacus, facilisis in turpis id, hendrerit porta dolor. In nunc est,
          ultricies vitae efficitur eget, bibendum at diam. Duis sagittis porta
          erat vitae finibus. In quam arcu, iaculis ac arcu vitae, molestie
          efficitur ante. Proin quis lorem ut dui lacinia lacinia. Morbi et
          tellus scelerisque libero fringilla elementum. Fusce ut odio quis
          lectus commodo viverra. Nam eu ipsum quis velit volutpat maximus.
          Nullam non imperdiet nisl. Nullam porttitor ornare bibendum. Proin
          gravida tellus et mi varius aliquam. Duis efficitur pretium mollis.
          Curabitur imperdiet, turpis eu malesuada mattis, nulla diam elementum
          diam, feugiat malesuada sem nibh lobortis enim. Vestibulum vel laoreet
          ante. Phasellus sed mi justo. Aenean at metus vel quam malesuada
          vulputate vel quis velit. Donec nec risus sit amet ipsum gravida
          convallis eu ac mi. Curabitur facilisis enim tempus hendrerit
          imperdiet. Mauris blandit elit nec ipsum sodales vestibulum. Vivamus
          id purus sollicitudin tortor efficitur scelerisque nec ac libero.
          Nulla faucibus enim sapien, nec malesuada nulla vulputate vitae.
          Pellentesque sodales in dolor vitae lacinia. Suspendisse pulvinar
          congue gravida. Ut iaculis mauris eu nisi dapibus ullamcorper. Fusce
          tempor justo ante, quis euismod lacus scelerisque non. Ut id imperdiet
          libero. In laoreet lacus at aliquam semper. Praesent vulputate
          eleifend ante tincidunt pulvinar. Ut mollis ante orci, a sollicitudin
          arcu egestas a. Nullam justo elit, malesuada sit amet elementum id,
          tempor ut orci. Nulla facilisi. Proin accumsan arcu sit amet faucibus
          interdum. In quis commodo est, fringilla convallis mauris. Praesent ut
          arcu a turpis dapibus imperdiet. Morbi facilisis quam erat, vel auctor
          nisi egestas a. Cras sagittis scelerisque nisi, eget volutpat tellus
          tempor vel. In hac habitasse platea dictumst. Donec vel odio vitae
          nibh rhoncus volutpat vel posuere lacus. Nullam placerat sapien neque,
          sit amet dignissim urna scelerisque in. Nunc imperdiet, mauris non
          congue eleifend, leo ligula condimentum diam, non tincidunt lorem nisl
          et massa. Praesent malesuada neque quam, vitae laoreet enim
          condimentum a. Cras mollis mi vestibulum eros viverra lobortis pretium
          in nulla. Sed justo dui, malesuada vel efficitur laoreet, posuere ac
          diam. Suspendisse finibus ante sit amet nibh scelerisque laoreet. Cras
          quis interdum quam. Etiam ut bibendum enim, id pellentesque metus.
          Duis ornare ligula congue, tincidunt nunc sit amet, sagittis lectus.
          Morbi et sagittis leo. Pellentesque habitant morbi tristique senectus
          et netus et malesuada fames ac turpis egestas. Nunc eget arcu
          porttitor, lacinia metus sit amet, auctor justo. Vivamus convallis
          condimentum nisl at interdum. Aliquam semper quis enim id cursus.
          Suspendisse eu tempor libero, id placerat ex. Morbi venenatis libero
          et ante sodales rhoncus. Phasellus convallis porttitor facilisis. Sed
          feugiat quam quis neque tempor, ac hendrerit lorem sollicitudin.
          Phasellus fringilla nisi lorem, nec lobortis lorem vulputate a. Mauris
          purus dolor, varius id ultricies sed, tincidunt sed turpis. Nunc ut
          ipsum sed quam lacinia feugiat a vitae nunc. Suspendisse potenti.
          Integer vestibulum bibendum nulla vitae placerat. Quisque ex quam,
          porta hendrerit nisi non, molestie gravida diam. Curabitur non
          tincidunt metus. Cras aliquam dui urna, in interdum orci maximus non.
          Vestibulum nisl lectus, rhoncus et ipsum ac, maximus hendrerit quam.
          Donec rhoncus ligula tincidunt blandit imperdiet. Duis ex metus,
          euismod ac tortor at, mollis scelerisque metus. Nam ante mauris,
          laoreet vel ullamcorper at, pulvinar sed tellus. Morbi ex nisl, congue
          vel vulputate vitae, feugiat nec odio. Donec quis urna ex. Morbi
          elementum finibus nisl vitae pretium. Nullam mollis eu velit sed
          eleifend. Praesent maximus, enim ut placerat venenatis, ante ante
          dignissim tortor, ut ultrices eros magna quis lacus. Sed imperdiet
          massa velit, vel tempor nulla feugiat ut. Curabitur sed enim urna.
          Mauris magna sapien, ultricies quis diam eu, semper fermentum magna.
          Nulla finibus dui et augue luctus sodales. Nulla tempus volutpat
          sollicitudin. Aenean iaculis eget metus non tempor. Mauris rutrum
          velit turpis, quis pharetra neque lacinia quis. Nulla posuere bibendum
          risus a sollicitudin. Class aptent taciti sociosqu ad litora torquent
          per conubia nostra, per inceptos himenaeos. In venenatis hendrerit
          aliquet. Vivamus laoreet iaculis neque. Vivamus eu gravida lorem.
          Morbi convallis fringilla felis quis feugiat. Vestibulum in orci non
          justo accumsan condimentum. Integer magna enim, pulvinar porta diam
          vel, posuere lacinia nibh. In nec mauris nec augue egestas convallis
          ut eu velit. Vivamus consequat, arcu id varius lacinia, urna dolor
          ultricies mi, vitae tincidunt libero ligula consequat felis. Nam quis
          varius felis, sed vestibulum odio. Etiam euismod turpis lacus, eu
          vestibulum erat rhoncus ut. Maecenas orci eros, pretium sed laoreet
          eu, laoreet quis enim. Mauris non odio vel elit aliquam mollis.
          Quisque vitae efficitur felis, ut accumsan sapien. Aenean id rutrum
          turpis, vitae mollis orci. Integer id dolor odio. Duis non suscipit
          dui, a interdum elit. Pellentesque id dolor nec urna fringilla
          eleifend ac ut diam. Interdum et malesuada fames ac ante ipsum primis
          in faucibus. Sed non interdum magna, et suscipit diam. Donec iaculis
          tortor id placerat facilisis. Nunc faucibus faucibus diam, at
          condimentum ipsum cursus vel. Pellentesque dapibus ligula et venenatis
          luctus. Maecenas id sapien lectus. Morbi ultrices vehicula lectus sit
          amet accumsan. Mauris pharetra vestibulum magna ac vehicula. Vivamus
          pulvinar vulputate sem non gravida. Quisque elementum ligula in
          pulvinar scelerisque. Mauris lectus ipsum, accumsan id mauris sed,
          fermentum commodo nunc. Vestibulum quam urna, pharetra ac est at,
          dictum lacinia metus. Proin ullamcorper tellus mauris, in rhoncus
          tortor tincidunt eu. Sed eu pulvinar erat, eget pretium diam.
          Phasellus consectetur risus vitae vehicula consectetur. Donec
          scelerisque felis vitae enim malesuada tincidunt. Ut rhoncus hendrerit
          sem, ac sagittis neque porttitor eu. Quisque in lacus enim. Cras arcu
          nunc, porta vitae aliquet et, mattis nec tellus. Phasellus eleifend
          sapien ac ex volutpat malesuada. Integer nisi quam, dictum ut maximus
          sit amet, vulputate non ex. Quisque tincidunt consequat est nec
          vehicula. Maecenas suscipit nec ex non commodo. Vestibulum iaculis
          orci nec tortor sodales, eu vestibulum turpis congue. Vestibulum
          sollicitudin ante ut tortor scelerisque fringilla. Vivamus vel magna
          in lectus condimentum sagittis at at eros. Vivamus tempus mi neque,
          quis imperdiet augue ornare nec. Duis vitae tincidunt justo, dignissim
          pharetra ante. Suspendisse potenti. Morbi convallis mattis aliquam.
          Donec id nisi sit amet augue scelerisque hendrerit. Duis euismod enim
          eu magna laoreet pellentesque. Etiam viverra magna lectus, eu
          condimentum ipsum faucibus non. Vestibulum bibendum faucibus euismod.
          Etiam aliquet est mi, ut commodo enim aliquam ac. Ut felis ipsum,
          dapibus ac ligula quis, cursus rhoncus felis. Integer tincidunt
          maximus leo, eu vehicula elit consectetur a. Sed in vehicula mauris.
          Phasellus malesuada erat sapien, a pulvinar libero consequat non.
          Phasellus aliquet ex nec nibh dapibus finibus. Sed varius est augue,
          ac euismod dolor iaculis id. Praesent nec sapien velit. Etiam
          vulputate pharetra nisl. Nunc interdum dictum gravida. Cras sit amet
          aliquam ipsum. Ut dictum quis felis eu ultricies. Quisque euismod
          tellus non enim facilisis, non malesuada nibh interdum. Morbi cursus
          dictum massa ut vehicula. Aliquam erat volutpat. Interdum et malesuada
          fames ac ante ipsum primis in faucibus. Vivamus et scelerisque ante.
          Maecenas mattis risus quis faucibus tempor. Donec non sapien non odio
          scelerisque lobortis in euismod mi. Nullam in sodales sapien, eget
          elementum lorem. In diam eros, mollis congue nisl vel, ultricies
          faucibus nulla. Quisque blandit commodo lorem, ac consectetur magna
          dictum quis. Morbi vitae convallis ante, dapibus varius arcu. Nam quis
          lectus viverra, ornare neque id, convallis nibh. Morbi id tellus quam.
          Aliquam eu blandit ex, non egestas magna. Suspendisse quis finibus
          velit, et tempor sem. Maecenas velit nisi, sollicitudin vel feugiat
          sit amet, convallis tempor neque. Nam eget libero arcu. Aliquam
          maximus aliquet nibh, non fringilla tellus vestibulum eget. Integer
          sit amet urna a risus mattis dapibus nec in neque. Etiam at ligula
          nunc. Cras condimentum pellentesque pharetra.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={agreeTerms} color="primary">
          {translation('cookies.agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditions.propTypes = {
  showTerms: PropTypes.bool,
  setShowTerms: PropTypes.func,
  agreeTerms: PropTypes.func
};

export default TermsAndConditions;
