import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MenuProps, useStyles } from './utils';

const SelectWithOption = ({ options, allLabel, handleSelectOptions }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(
    options.map(({ label: optionValue }) => optionValue)
  );
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = event => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelected(
        selected.length === options.length
          ? []
          : options.map(({ label: optionValue }) => optionValue)
      );
      handleSelectOptions(selected.length === options.length ? [] : 'all');
      return;
    }
    setSelected(value);
    handleSelectOptions(
      value.map(option => {
        return options.filter(
          ({ label: optionValue }) => optionValue === option
        )[0];
      })
    );
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={value => value.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value={allLabel[0].value}
          classes={{
            root: isAllSelected ? classes.selectedAll : ''
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary={allLabel[0].label}
          />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.value} value={option.label}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.label) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWithOption;
