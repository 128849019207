import React from 'react';
import clsx from 'clsx';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'redux-firestore';
import { useSnackbar } from 'notistack';
import { logout } from 'src/actions/accountActions';
import { useHistory } from 'react-router';
import { COLLECTIONS } from '../../../constants';
import { REGISTER_STATUS } from '../../../dto/enum';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Suspended() {
  const classes = useStyles();
  const translation = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const subsidiary = useSelector(
    state => state.account.currentSubsidiary || false
  );
  const restaurant = useSelector(
    state => state.firestore.ordered.userRestaurant || false
  );
  const handleLogout = async () => {
    try {
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.SUBSIDIARY }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.RESTAURANT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.PRODUCT }
      });
      dispatch({
        type: actionTypes.CLEAR_DATA,
        meta: { collection: COLLECTIONS.ORDER }
      });
      await dispatch(logout());
      if (
        !subsidiary ||
        restaurant[0]?.register_status === REGISTER_STATUS.SUSPENDED
      ) {
        history.push('/home');
      } else {
        history.push('/login');
      }
    } catch (error) {
      enqueueSnackbar(translation('account.unable_logout'), {
        variant: 'error'
      });
    }
  };

  const getTypeSuspended = () => {
    if (subsidiary) {
      if (restaurant[0]?.register_status === REGISTER_STATUS.SUSPENDED) {
        return translation('login.commerce_suspended');
      }
      if (
        restaurant[0]?.register_status === REGISTER_STATUS.SUBSCRIPTION_EXPIRED
      ) {
        return translation('login.subscription_expired');
      }
      return translation('login.login.suspended');
    }
    return translation('login.login.suspended');
  };
  return (
    <div className={clsx(classes.root)}>
      <Box mt={2}>
        <Typography variant="h2" color="textPrimary">
          {getTypeSuspended()}
        </Typography>
      </Box>
      <Box mt={2}>
        <Button onClick={() => handleLogout()} className={classes.backButton}>
          {!subsidiary ||
          restaurant[0]?.register_status === REGISTER_STATUS.SUSPENDED
            ? translation('login.go_home')
            : translation('login.login_other_account')}
        </Button>
      </Box>
    </div>
  );
}

export default Suspended;
