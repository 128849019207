import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import 'firebase/firestore';
import ConfirmationProvider from './context/DialogContext';
import Providers from './providers';
import Logger from './modules/logs';
// import { useUserIsInactive } from './hooks/window/windowHooks';
// import { INACTIVITY_TIME_TEN_MIN } from './constants';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function App() {
  useStyles();

  const { settings } = useSettings();
  /*   const isUserInactive = useUserIsInactive(INACTIVITY_TIME_TEN_MIN);

  const onReload = () => {
    if (isUserInactive) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (isUserInactive) {
      onReload();
    }
    // eslint-disable-next-line
  }, [isUserInactive]); */

  window.onerror = (event, source, lineno, colno, error) => {
    const stack = {};
    Error.captureStackTrace(stack);
    Logger.error({ error, data: { loggerLine: stack.stack } });
  };

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <ConfirmationProvider>
              <Router history={history}>
                <Auth>
                  <Providers />
                  <ScrollReset />
                  <GoogleAnalytics />
                  <CookiesNotification />
                  <Routes />
                </Auth>
              </Router>
            </ConfirmationProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
