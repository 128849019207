import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ProfileDetails from '../../../pages/AccountView/General/ProfileDetails';

import UserEditForm from './UserEditForm';
import { updateProfile } from '../../../../actions/accountActions';

const useStyles = makeStyles(theme => ({
  root: {},
  actionButtons: {
    textAlign: 'end',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const UserView = ({ user }) => {
  const classes = useStyles();
  const currentUser = useSelector(state => state.account.user);
  const dispatch = useDispatch();
  const updateImage = async img => {
    if (user.id === currentUser.id) {
      const userUpdated = {
        ...user,
        profile_photo: { ...user.profile_photo, x80: img, x40: img }
      };
      await dispatch(updateProfile(userUpdated));
    }
  };
  return (
    <>
      <Grid className={clsx(classes.root)} container spacing={3}>
        {user.id && (
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <ProfileDetails user={user} updateImage={updateImage} />
          </Grid>
        )}
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <UserEditForm user={user} />
        </Grid>
      </Grid>
      {/* <AlertDialog */}
      {/*  open={!!statusToChange} */}
      {/*  question={translate( */}
      {/*    `admin_users.userEditView.question.${statusToChange}` */}
      {/*  )} */}
      {/*  handleCancel={() => { */}
      {/*    setStatusToChange(''); */}
      {/*  }} */}
      {/*  handleAccept={() => setStatusDB()} */}
      {/*  acceptLabel={translate('admin_users.userEditView.continue')} */}
      {/*  cancelLabel={translate('close')} */}
      {/* > */}
      {/*  <Typography as="body"> */}
      {/*    {translate( */}
      {/*      `admin_users.userEditView.question_confirmation.${statusToChange}` */}
      {/*    )} */}
      {/*  </Typography> */}
      {/* </AlertDialog> */}
    </>
  );
};

export default UserView;
