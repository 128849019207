import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import axios from 'axios';
import {
  AiOutlineFileText,
  AiOutlineEye,
  AiOutlineDownload
} from 'react-icons/ai';
import { IoMdAddCircle } from 'react-icons/io';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from 'src/constants';
import firebaseService from 'src/services/firebaseService';
import XpressModal from 'src/components/Modal';
import { COLORS } from 'src/theme/colors';
import { useTranslation } from 'react-multi-lang';
import Document from 'src/dto/document';

const useStyles = makeStyles(() => ({
  containerDoc: {
    border: `1px solid ${COLORS.yellowLink}`,
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  containerNull: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  containerCard: {},
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    marginTop: '5%',
    marginLeft: '5%'
  },
  cardDescription: {
    margin: '13px 0px',
    fontSize: '12px',
    marginLeft: '5%'
  },
  cardDocument: {
    height: '183px',
    borderRadius: '6px',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
    margin: '5%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxWidth: '100%'
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  styleText: {
    fontSize: '10px',
    textDecoration: 'underline'
  },
  hoverCard: {
    backgroundColor: 'black'
  },
  containerIcons: {
    backgroundColor: 'rgb(0, 23, 20, 0.7)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '6px'
  },
  iconOption: {
    backgroundColor: COLORS.green,
    width: '6vh',
    height: '6vh',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: 'white'
  },
  smallBox: {
    height: 34
  },
  opacity: {
    opacity: '0.5'
  },
  cursor: {
    cursor: 'pointer'
  },
  box: {
    height: 60,
    marginBottom: '8px'
  }
}));

const Documents = ({
  title = '',
  description = '',
  urlDocument = '',
  nameDocument = '',
  type = 'document',
  index = false,
  status,
  id
}) => {
  const translation = useTranslation();
  const borderDecoration =
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%2356B19EFF' stroke-width='1' stroke-dasharray='7%2c 7' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e";
  const [hoverIconOptions, setHoverIconOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  // eslint-disable-next-line
  const [logoBanner, setLogoBanner] = useState(urlDocument);
  const [extension, setExtension] = useState('');
  const [newType, setNewType] = useState(type);
  const classes = useStyles();
  const firestore = useFirestore();

  const getTypeDocumentByUrl = async () => {
    const data = axios.get(urlDocument, {
      responseType: 'arraybuffer'
    });
    const doc = await Promise.resolve(data);
    const currentType = doc.headers['content-type'].split('/')[0];
    const extensionDoc = doc.headers['content-type'].split('/')[1];
    if (currentType === 'image') {
      setNewType(currentType);
      setExtension(extensionDoc);
    }
  };

  const createDocument = async (url, { docName, docTitle, docDescription }) => {
    const docId = firebaseService.randomId('document');
    const doc = new Document();
    doc.id = docId;
    doc.url = url;
    doc.name = docName;
    doc.name_file = docName;
    doc.title = docTitle;
    doc.description = docDescription;
    await firestore
      .collection(`${COLLECTIONS.RESTAURANT}/${id}/${COLLECTIONS.DOCUMENT}`)
      .doc(docId)
      .set({ ...doc });
  };

  const addDocument = () => {
    setShowModal(true);
    const path = `${COLLECTIONS.RESTAURANT}/${id}`;
    setModal(
      <XpressModal
        type="uploadFileRestaurant"
        path={path}
        handleConfirm={async (url, name) => {
          createDocument(url, name);
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
      />
    );
  };

  const showImageDocument = () => {
    const doc = document.createElement('a');
    doc.href = `${urlDocument}.${extension}`;
    doc.setAttribute('target', '_blank');
    document.body.appendChild(doc);
    doc.click();
    document.body.removeChild(doc);
  };

  const handleDownloadDocument = () => {
    if (newType === 'image') {
      return fetch(urlDocument, {
        method: 'GET',
        headers: {}
      })
        .then(response => {
          response.arrayBuffer().then(buffer => {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nameDocument}.${extension}`);
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
    return showImageDocument();
  };

  useEffect(() => {
    if (urlDocument) {
      getTypeDocumentByUrl();
    }
    // eslint-disable-next-line
  }, [urlDocument]);

  return (
    <Grid className={index ? classes.containerDoc : classes.containerNull}>
      <Box className={classes.containerCard}>
        <Typography className={classes.cardTitle}>{title}</Typography>
        <Typography variant="h5" className={classes.cardDescription}>
          {description}
        </Typography>
        <Box
          className={classes.cardDocument}
          style={{
            backgroundImage: `url("${
              // eslint-disable-next-line no-nested-ternary
              newType === 'image'
                ? status === 'logo' || status === 'banner'
                  ? logoBanner
                  : urlDocument
                : borderDecoration
            }")`
          }}
          onMouseEnter={() => setHoverIconOptions(true)}
          onMouseLeave={() => setHoverIconOptions(false)}
        >
          {urlDocument && newType !== 'image' && (
            <Box textAlign="center" className={classes.opacity}>
              {index && (
                <Box>
                  <Box mb={1.6} className={classes.smallBox}>
                    <AiOutlineFileText className={classes.styleIcon} />
                  </Box>
                  <Box mb={3}>
                    <Typography>{nameDocument}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {!urlDocument && (
            <Box textAlign="center">
              {index && (
                <>
                  <Box mb={1.6} className={classes.smallBox}>
                    <AiOutlineFileText className={classes.styleIcon} />
                  </Box>
                  <Box mb={3}>
                    <Typography>
                      {translation('central_payments.documents.drag_document')}
                    </Typography>
                    <Typography>
                      {translation('central_payments.documents.here')}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.styleText}>
                      {translation(
                        'central_payments.documents.upload_from_device'
                      )}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
          {!index && (
            <Box
              mb={3}
              textAlign="center"
              className={classes.cursor}
              onClick={addDocument}
            >
              <Box className={classes.box}>
                <IoMdAddCircle className={classes.styleIcon} />
              </Box>
              <Typography>
                {translation('central_payments.documents.add_field')}
              </Typography>
              <Typography>
                {translation('central_payments.documents.new_document')}
              </Typography>
            </Box>
          )}
          {hoverIconOptions && urlDocument && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              className={classes.containerIcons}
            >
              {newType === 'image' && (
                <Box
                  className={classes.iconOption}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={showImageDocument}
                >
                  <AiOutlineEye className={classes.styleIconOption} />
                </Box>
              )}
              <Box
                className={classes.iconOption}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={handleDownloadDocument}
              >
                <AiOutlineDownload className={classes.styleIconOption} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {showModal && modal}
    </Grid>
  );
};

export default Documents;
