import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import CustomerEditForm from '../MemberEditView/CustomerEditForm';
import { useCurrentSubsidiary } from '../../../hooks/useRestaurant';
import ModalTemplate from '../../../components/Modal/ModalTemplate';
import { isSupervisor } from '../../../utils/helpers/userHelper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function ModalCreateMember({ show, onClose, userId }) {
  const classes = useStyles();
  const defaultValues = {};
  const currentUser = useSelector(state => state.account.user);
  const translation = useTranslation();
  const subsidiary = useCurrentSubsidiary();

  if (isSupervisor(currentUser)) {
    if (subsidiary && subsidiary.id) {
      defaultValues.subsidiary_id = subsidiary.id;
    }
  }

  // eslint-disable-next-line consistent-return
  const user = useSelector(state => {
    if (userId) {
      if (
        state.firestore.data.members &&
        state.firestore.data.members[userId]
      ) {
        return state.firestore.data.members[userId];
      }
    }
  });

  return (
    <ModalTemplate
      show={show}
      onClose={onClose}
      className={classes.root}
      label={
        userId
          ? translation('members.edit_member')
          : translation('members.new_member')
      }
    >
      <CustomerEditForm user={user} onClose={onClose} def={defaultValues} />
    </ModalTemplate>
  );
}

export default ModalCreateMember;
