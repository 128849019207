export const allowOnlyNumbers = e => {
  const invalidChars = ['-', '+', 'e', '.'];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};
export const allowOnlyNumbersAndDecimals = e => {
  const invalidChars = ['-', '+', 'e'];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};
