import React, { useState, useEffect } from 'react';
import { Hexagon } from 'react-feather';
import moment from 'moment';

const Timer = ({ orderCreated }) => {
  const [colorTimer, setColorTimer] = useState('white');

  useEffect(() => {
    const createdOrder = moment(orderCreated.toDate());
    const interval = setInterval(() => {
      const difTime = moment().diff(createdOrder, 'minutes');
      if (difTime <= 5) {
        setColorTimer('green');
      }
      if (difTime > 5 && difTime <= 10) {
        setColorTimer('orange');
      }
      if (difTime > 10) {
        setColorTimer('red');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [orderCreated]);

  return <Hexagon fill={colorTimer} color={colorTimer} size={20} />;
};
export default Timer;
