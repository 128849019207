import { useMemo } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ORDER_STATUS, ORDER_TYPE } from '../../dto/enum';
import { useCurrentSubsidiary, useRestaurant } from '../useRestaurant';
import { COLLECTIONS } from '../../constants';
import { orderApp } from '../../utils/helpers/constRoutes';
import admin from '../../config/firebaseConfig';

export default function useOrdersConnect() {
  const subsidiary = useCurrentSubsidiary();
  // TODO: restrict per subsidiary
  const path = window.location.pathname;
  const query = [
    {
      collection: COLLECTIONS.ORDER,
      where: [['subsidiary_id', '==', subsidiary.id]]
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['status', '==', ORDER_STATUS.IN_PROCESS],
        ['subsidiary_id', '==', subsidiary.id],
        ['verified', '==', true]
      ],
      orderBy: ['created_at', 'desc'],
      storeAs: 'orders_new'
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        [
          'status',
          'in',
          [
            ORDER_STATUS.ACCEPTED,
            ORDER_STATUS.READY_TO_PICK,
            ORDER_STATUS.READY_TO_CASHIER
          ]
        ],
        ['subsidiary_id', '==', subsidiary.id]
      ],
      orderBy: ['created_at', 'desc'],
      storeAs: 'orders_in_process'
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['status', '==', ORDER_STATUS.DELIVERING],
        ['subsidiary_id', '==', subsidiary.id]
      ],
      orderBy: ['created_at', 'desc'],
      storeAs: 'orders_been_delivery'
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['status', '==', ORDER_STATUS.COMPLETED],
        ['subsidiary_id', '==', subsidiary.id]
        // [
        //   'updated_at',
        //   '>=',
        //   moment()
        //     .startOf('day')
        //     .toDate()
        // ]
      ],
      // orderBy: ['updated_at', 'desc'],
      storeAs: 'orders_completed_today'
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['status', '==', ORDER_STATUS.CANCELED],
        ['subsidiary_id', '==', subsidiary.id]
      ],
      orderBy: ['created_at', 'desc'],
      storeAs: 'orders_canceled'
    },
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['status', '==', ORDER_STATUS.COMPLETED],
        ['subsidiary_id', '==', subsidiary.id]
      ],
      // orderBy: ['updated_at', 'desc'],
      storeAs: 'orders_completed'
    }
  ];
  // Have a diferent function behavior according to the path

  if (path.includes(orderApp)) {
    const today = moment()
      .startOf('day')
      .subtract(1, 'days')
      .valueOf();
    const limitToday = ['created_at', '>=', new Date(today)];
    for (let i = 0; i < query.length; i++) {
      const item = query[i];
      if (item.where) query[i].where.push(limitToday);
      else query[i].where = [limitToday];
    }
  }
  useFirestoreConnect(query);
  return [];
}

export function useOrdersStatus(limitDay = 0) {
  const query = {
    collection: COLLECTIONS.ORDER,
    orderBy: ['created_at', 'desc'],
    storeAs: 'adminOrden'
  };
  // This data is to limit the data obtainned on certain days
  if (limitDay) {
    const beforeYesterday = moment()
      .startOf('day')
      .subtract(limitDay, 'days')
      .valueOf();
    if (!query.where)
      query.where = [['created_at', '>=', new Date(beforeYesterday)]];
    else query.where.push(['created_at', '>=', new Date(beforeYesterday)]);
  }
  useFirestoreConnect(query);
  const orders = useSelector(state => state.firestore.ordered.adminOrden);
  if (!orders) return false;
  return orders;
}

export const getOrderNumber = order => {
  if (order.order_code) return order.order_code;
  return false;
};

export function useSubsidiaryOrders() {
  const subsidiary = useRestaurant();
  useFirestoreConnect({
    collection: COLLECTIONS.ORDER,
    where: ['restaurant_id', '==', subsidiary.id],
    storeAs: 'all_orders'
  });
  const all_orders = useSelector(
    state => state.firestore.ordered.all_orders || []
  );
  return all_orders;
}

export const useOrderBeenUse = orderId => {
  useFirestoreConnect({
    collection: COLLECTIONS.ORDER,
    where: ['id', '==', orderId],
    storeAs: 'order_been_use'
  });
  const orderBeenUse = useSelector(
    state => state.firestore.ordered.order_been_use
  );
  if (orderBeenUse) return orderBeenUse[0];
  return {};
};

export const useNewOrders = (limit = 0, lastOne = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    const newOrders = Orders?.filter(
      value =>
        value.status === ORDER_STATUS.IN_PROCESS && value.verified === true
    );
    if (newOrders && newOrders.length > 0) {
      const newDelivery = newOrders.filter(
        o => o.driver_id && o.order_type === ORDER_TYPE.DELIVERY
      );
      const newPickup = newOrders.filter(
        o =>
          o.order_type === ORDER_TYPE.PICK_UP ||
          o.order_type === ORDER_TYPE.IN_COMMERCE
      );
      let concatOrders = newDelivery.concat(newPickup);
      if (limit) {
        const showDay = moment()
          .startOf('day')
          .subtract(limit, 'days')
          .toDate();
        concatOrders = concatOrders.filter(
          value => showDay <= new Date(value.created_at.seconds * 1000)
        );
      }
      if (lastOne) return concatOrders[0];

      return concatOrders;
    }
    return [];
  }, [Orders, limit, lastOne]);
};

export const useOrdersInProcess = limit => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    let orderProcess = Orders?.filter(
      value =>
        value.status === ORDER_STATUS.ACCEPTED ||
        value.status === ORDER_STATUS.READY_TO_PICK ||
        value.status === ORDER_STATUS.READY_TO_CASHIER
    );
    if (orderProcess) {
      if (limit) {
        const showDay = moment()
          .startOf('day')
          .subtract(limit, 'days')
          .toDate();
        orderProcess = orderProcess.filter(
          value => showDay <= new Date(value.created_at.seconds * 1000)
        );
      }
      return orderProcess;
    }
    return [];
  }, [Orders, limit]);
};

export const useOrdersBeenDelivery = limit => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    let orderProcess = Orders?.filter(
      value => value.status === ORDER_STATUS.DELIVERING
    );
    if (orderProcess) {
      if (limit) {
        const showDay = moment()
          .startOf('day')
          .subtract(limit, 'days')
          .toDate();
        orderProcess = orderProcess.filter(
          value => showDay <= new Date(value.created_at.seconds * 1000)
        );
      }
      return orderProcess;
    }
    return [];
  }, [Orders, limit]);
};

export const useOrdersCompleted = (limit, sort = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    let orderProcess = Orders?.filter(
      value => value.status === ORDER_STATUS.COMPLETED
    );
    if (orderProcess) {
      if (limit || limit === 0) {
        const showDay = moment()
          .startOf('day')
          .subtract(limit, 'days')
          .toDate();
        orderProcess = orderProcess.filter(
          value => showDay <= new Date(value.created_at.seconds * 1000)
        );
      }
      if (sort && orderProcess.length > 1)
        orderProcess = orderProcess
          .slice()
          .sort((a, b) => b.updated_at - a.updated_at);
      return orderProcess;
    }
    return [];
  }, [Orders, limit, sort]);
};

export const useOrdersCanceled = (limit, sort = false) => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    let orderProcess = Orders?.filter(
      value => value.status === ORDER_STATUS.CANCELED
    );
    if (orderProcess) {
      if (limit || limit === 0) {
        const showDay = moment()
          .startOf('day')
          .subtract(limit, 'days')
          .toDate();
        orderProcess = orderProcess.filter(
          value => showDay <= new Date(value.created_at.seconds * 1000)
        );
      }
      if (sort && orderProcess.length > 1)
        orderProcess = orderProcess
          .slice()
          .sort((a, b) => b.updated_at - a.updated_at);
      return orderProcess;
    }
    return [];
  }, [Orders, limit, sort]);
};

export const appFilterReport = async (
  status,
  time,
  month,
  year,
  subsidiaryId,
  restaurantId
) => {
  let startDate;
  let endDate;
  const getMoth = new Date(month).getMonth() + 1;
  switch (time) {
    case 'week':
      startDate = moment()
        .startOf('day')
        .subtract(6, 'days')
        .toDate();
      endDate = moment()
        .endOf('day')
        .toDate();
      break;
    case 'month':
      startDate = moment(`${year}-${getMoth}`)
        .startOf('months')
        .toDate();
      endDate = moment(startDate)
        .endOf('months')
        .toDate();
      break;
    default:
      startDate = moment(`${year + 1}`)
        .startOf('year')
        .toDate();
      endDate = moment(startDate)
        .endOf('year')
        .toDate();
  }
  let query = admin.firestore().collection(COLLECTIONS.ORDER);
  if (status === 'completed')
    query = query.where('status', '==', ORDER_STATUS.COMPLETED);
  if (status === 'canceled')
    query = query.where('status', '==', ORDER_STATUS.CANCELED);
  if (subsidiaryId && restaurantId) {
    query = query.where('subsidiary_id', '==', subsidiaryId);
    query = query.where('restaurant_id', '==', restaurantId);
  }
  query = query
    .orderBy('created_at')
    .startAt(new Date(startDate))
    .endAt(new Date(endDate));
  let orders = await query.get();
  orders = orders.docs.map(doc => ({ ...doc.data() }));
  orders = orders.filter(
    item =>
      item.status === ORDER_STATUS.CANCELED ||
      item.status === ORDER_STATUS.COMPLETED
  );
  orders = orders.filter(item => !item.xpressMotos);
  if (status === 'completed')
    orders = orders.filter(item => item.status === ORDER_STATUS.COMPLETED);
  if (status === 'canceled')
    query = orders.filter(item => item.status === ORDER_STATUS.CANCELED);
  return orders || [];
};

export const useOrderShortInfo = () => {
  const orders = useSelector(state => state.firestore.ordered.order);
  if (!orders) return [];
  return orders.map(o => ({
    id: o.id,
    order_code: o.order_code
  }));
};

export const useOrdersCompletedCanceled = subId => {
  const Orders = useSelector(state => state.firestore.ordered.order);
  return useMemo(() => {
    if (subId) {
      let orderProcess = Orders?.filter(
        value =>
          (value.status === ORDER_STATUS.COMPLETED ||
            value.status === ORDER_STATUS.CANCELED) &&
          value.subsidiary_id === subId
      );
      if (orderProcess) {
        if (orderProcess.length > 1)
          orderProcess = orderProcess
            .slice()
            .sort((a, b) => b.updated_at - a.updated_at);
        return orderProcess;
      }
    }
    return [];
  }, [Orders, subId]);
};
