import { useState, useEffect, useMemo } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import lodash from 'lodash';
import { COLLECTIONS } from '../constants';
import { REGISTER_STATUS } from '../dto/enum';
import { useAllRestaurantSubsidiaries } from './AdminHooks/subsidiaries/useSubsidiaries';
import { isOwner } from '../utils/helpers/userHelper';
// eslint-disable-next-line import/no-duplicates
import firebase from '../config/firebaseConfig';
import Restaurant from '../dto/restaurant';
import { BankDataRef } from '../config/references';
import BankData from '../dto/bankData';
import Invoice from '../dto/invoice';
// eslint-disable-next-line import/no-duplicates
import admin from '../config/firebaseConfig';

export function useRestaurant(restFromState = null) {
  const restaurant = useSelector(state => state.account.restaurant);
  const userRestaurant = useSelector(
    state => state.firestore.data.userRestaurant
  );
  const getId = () => {
    if (restFromState) return restFromState;
    if (restaurant) return restaurant.id;
    return '';
  };
  const id = getId();

  useFirestoreConnect({
    collection: COLLECTIONS.RESTAURANT,
    doc: id,
    storeAs: restaurant ? 'userRestaurant' : 'restaurant'
  });

  const rest = useSelector(state => state.firestore.data.restaurant);
  if (restFromState) return rest || {};

  return userRestaurant || restaurant;
}
export const useRestaurantPerIdNative = id => {
  const [restaurant, setRestaurant] = useState(null);
  let unsubscribe = () => {};
  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      unsubscribe = firebase
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(id)
        .onSnapshot(snap => {
          const data = new Restaurant({ ...snap.data(), id: snap.id });
          setRestaurant(data);
        });
    }
    return () => {
      unsubscribe();
    };
  }, [id]);
  return restaurant;
};
export function useSubsidiaries() {
  const subsidiaries = useSelector(state => state.account.subsidiaries);
  return subsidiaries;
}

export async function useUpdateFreeDelivery(restId, data) {
  const result = await firebase
    .firestore()
    .collection(COLLECTIONS.RESTAURANT)
    .doc(restId)
    .update({ deliveryFree: data });
  return result;
}

export function useSubsidiariesRealTime(restaurantId) {
  useFirestoreConnect({
    collection: COLLECTIONS.SUBSIDIARY,
    where: [['restaurant_id', '==', restaurantId]],
    storeAs: 'subsidiariesRealTime'
  });
  const subsidiaries = useSelector(
    state => state.firestore.ordered.subsidiariesRealTime
  );
  return subsidiaries;
}

export function useSubsidiariesUser(restId) {
  const subsidiaries = useSubsidiaries();
  const currentUser = useSelector(state => state.account.user);
  const ownerSubsidiariesForAdmin = useAllRestaurantSubsidiaries(restId);
  if (restId && ownerSubsidiariesForAdmin) {
    return ownerSubsidiariesForAdmin;
  }
  if (isOwner(currentUser)) {
    return subsidiaries;
  }
  return subsidiaries.filter(s => currentUser.subsidiary_id.includes(s.id));
}

export function useCurrentSubsidiary(isAdmin = null) {
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  const subsidiaryFS = useSelector(
    state => state.firestore.data.current_subsidiary
  );
  const sub = isAdmin ? subsidiaryFS : subsidiary;
  return sub;
}

export function useCurrentListenSubsidiary() {
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  useFirestoreConnect({
    collection: COLLECTIONS.SUBSIDIARY,
    doc: subsidiary.id,
    storeAs: 'current_subsidiary_schedule'
  });
  let subsidiaryFS = useSelector(
    state => state.firestore.ordered.current_subsidiary_schedule
  );
  if (subsidiaryFS && subsidiaryFS.length !== 0) [subsidiaryFS] = subsidiaryFS;
  return subsidiaryFS || subsidiary;
}

export default function useRestaurantAndSubsidiaries() {
  const restaurant = useRestaurant();
  const subsidiaries = useSubsidiaries();

  return [restaurant, subsidiaries];
}

export const useRestaurantSnap = restaurantId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      storeAs: `restaurant`
    }
  ]);
  const restaurantSnap = useSelector(state => state.firestore.data.restaurant);

  return restaurantSnap;
};

export const useRestaurantSnapNativeAsync = restaurantId => {
  const [restaurant, setRestaurant] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .doc(restaurantId)
      .get()
      .then(rest => {
        if (rest.exists) {
          setRestaurant(rest.data());
        } else {
          setRestaurant({});
        }
      });
  }, [restaurantId]);

  return restaurant;
};

export async function useAppOrders(subsidiaryId, subsWithRestsMerged) {
  const limitDay = moment()
    .startOf('day')
    .subtract(1, 'days');
  const subsidiariesId = useMemo(() => {
    return [
      subsidiaryId,
      ...subsWithRestsMerged.map(item => item.subsidiaryId)
    ];
  }, [subsidiaryId, subsWithRestsMerged]);
  const query = {
    collection: COLLECTIONS.ORDER,
    where: [
      ['subsidiary_id', 'in', subsidiariesId],
      ['created_at', '>=', limitDay.toDate()],
      ['xpressMotos', '==', false]
    ]
  };
  useFirestoreConnect(query);
}

export function useAppSummary(restaurantId, subsidiaryId) {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUMMARY,
      where: [
        ['restaurant_id', '==', restaurantId],
        ['subsidiary', 'array-contains', subsidiaryId],
        ['summary.size_completed', '>=', 1]
      ]
    }
  ]);
  return true;
}

export function useAppSub(restaurantId, subsidiaryId) {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      where: [
        ['subsidiary_id', '==', subsidiaryId || ''],
        ['restaurant_id', '==', restaurantId || '']
      ]
    }
  ]);
  return true;
}

export function useRestaurantStatus(restaurant, subsidiary) {
  if (restaurant && restaurant.length > 0 && subsidiary) {
    const restActive = restaurant[0];
    if (
      restActive.register_status === REGISTER_STATUS.ACTIVE ||
      restActive.register_status === REGISTER_STATUS.SUSPENDED
    ) {
      return true;
    }
  }
  return false;
}

export const useRestaurantInvoices = (restId, limit = 3, limitDate) => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    const dataInvoice = async () => {
      if (restId && restId !== 'all' && limit) {
        try {
          const querySnapshot = await admin
            .firestore()
            .collection(COLLECTIONS.INVOICE)
            .orderBy('created_at', 'desc')
            .where('restaurant_id', '==', restId)
            .where('created_at', '<=', new Date(limitDate))
            .limit(limit)
            .get();
          const invoicesData = querySnapshot.docs.map(snap => {
            const invoice = new Invoice({ ...snap.data(), id: snap.id });
            return invoice;
          });
          setResult(invoicesData || []);
        } catch (error) {
          console.error('ERROR', error);
          setResult([]);
        }
      } else {
        setResult([]);
      }
    };
    dataInvoice();
  }, [restId, limit, limitDate]);
  return result;
};

// export const useRestaurantInvoices = restId => {
//   useFirestoreConnect([
//     {
//       collection: COLLECTIONS.INVOICE,
//       where: [['restaurant_id', '==', restId]],
//       storeAs: `restaurant_invoices`
//     }
//   ]);
//   const restaurantInvoices = useSelector(
//     state => state.firestore.ordered.restaurant_invoices
//   );
//
//   return restaurantInvoices || [];
// };

export const useLogsRestaurant = (restId, limitDay = 0) => {
  const previousDate = moment()
    .startOf('day')
    .subtract(limitDay, 'days')
    .valueOf();
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restId,
      subcollections: [{ collection: COLLECTIONS.LOGS }],
      where: ['created_at', '>', new Date(previousDate)],
      orderBy: ['created_at', 'desc'],
      storeAs: `logs_${restId}`
    }
  ]);
  const logs = useSelector(state => state.firestore.ordered[`logs_${restId}`]);
  return logs || [];
};

export const useRestaurantId = restaurantId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restaurantId,
      storeAs: `restaurant_${restaurantId}`
    }
  ]);
  const restaurant = useSelector(
    state => state.firestore.ordered[`restaurant_${restaurantId}`]
  );
  return restaurant ? new Restaurant(restaurant[0]) : false;
};

export const useRestaurantAndSubsidiaryforMenu = (restId, subId) => {
  const [restaurant, setRestaurant] = useState({});
  const [subsidiary, setSubsidiary] = useState({});

  useEffect(() => {
    if (restId && subId) {
      const unsubscribeRest = firebase
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .onSnapshot(snap => {
          setRestaurant(snap.data());
        });

      const unsubscribeSub = firebase
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(subId)
        .onSnapshot(snap => {
          setSubsidiary({ ...snap.data() });
        });
      return () => {
        unsubscribeRest();
        unsubscribeSub();
      };
    }
    if (restId && !subId) {
      const unsubscribeRest = firebase
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .onSnapshot(snap => {
          setRestaurant(snap.data());
        });
      return () => {
        unsubscribeRest();
      };
    }
  }, [subId, restId]);
  return [restaurant, subsidiary];
};

export const useSubsidiariesMergedAndCurrentOrdered = () => {
  const currentSubsidiary = useSelector(
    state => state.account.currentSubsidiary
  );
  const [result, setResult] = useState([]);
  useEffect(() => {
    const ordered = lodash.orderBy(
      currentSubsidiary.subsidiariesWithRestaurantsMerged || [],
      ['restaurant_name']
    );
    setResult([
      {
        restaurantId: currentSubsidiary.restaurant_id,
        restaurant_name: currentSubsidiary.restaurant_name,
        subsidiaryId: currentSubsidiary.id,
        subsidiary_name: currentSubsidiary.name
      },
      ...ordered
    ]);
    // eslint-disable-next-line
  }, [currentSubsidiary?.subsidiariesWithRestaurantsMerged]);
  return result;
};

export const useRestaurantMergedAndCurrentOrdered = () => {
  const currentRestaurant = useSelector(state => state.account.restaurant);
  const [result, setResult] = useState([]);
  useEffect(() => {
    const ordered = lodash.orderBy(currentRestaurant.restaurantsMerged || [], [
      'restaurant_name'
    ]);
    setResult([
      {
        restaurantId: currentRestaurant.id,
        restaurant_name: currentRestaurant.name
      },
      ...ordered
    ]);
    // eslint-disable-next-line
  }, [currentRestaurant?.restaurantsMerged]);
  return result;
};

export const useBankDataPerRestaurantId = id => {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    if (id) {
      return BankDataRef(id)
        .orderBy('created_at', 'asc')
        .onSnapshot(snap => {
          if (snap?.docs) {
            setBanks(
              snap.docs.map(doc => new BankData({ ...doc.data(), id: doc.id }))
            );
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  return banks;
};

export const useRestaurantIdNative = id => {
  const [restaurant, setRestaurant] = useState(null);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .doc(id)
      .onSnapshot(snap => {
        const data = new Restaurant({ ...snap.data(), id: snap.id });
        setRestaurant(data);
      });
  }, [id]);

  return restaurant;
};
