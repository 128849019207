/**
 *
 * @type {EnvironmentConfig}
 */
const TestEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyBARdYUMYOlEf3thKPHNvjL8KTxeiBTDxk',
    authDomain: 'xpress-test-env.firebaseapp.com',
    databaseURL: 'https://xpress-test-env.firebaseio.com',
    projectId: 'xpress-test-env',
    storageBucket: 'xpress-test-env.appspot.com',
    messagingSenderId: '67978209754',
    appId: '1:67978209754:web:214e9d599d660ec6d7f8e3',
    measurementId: 'G-P9G88B552D'
  },
  REACT_APP_GOOGLE_MAP_API: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
  REACT_APP_CLOUD_FUNCTIONS_API:
    'https://us-central1-xpress-test-env.cloudfunctions.net',
  REACT_APP_SUPPORT_MAIL: 'info@xpressbolivia.com',
  REACT_APP_FCM_KEY:
    'BJIDyG59F-ybmvZ9k8xXry_F-HU0fAis_o7QvC74rF36odClBH3fTNp3Tlz1JA46H9pJn-CeQtAJ9vdeVa_T4qY',
  REACT_APP_COMPANY_NAME: 'Xpress Technologies',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyBARdYUMYOlEf3thKPHNvjL8KTxeiBTDxk',
  REACT_APP_DYNAMIC_LINK_DOMAIN_URI: 'https://xpresstesting.page.link',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_MOTOS_URL: 'https://xpress-test-motos-admin.web.app/login',
  REACT_APP_XPRESS_SUPPORT: '+59162093356',
  REACT_APP_GA_MEASUREMENT_ID: '',
  REACT_APP_XPRESS_BOLIVIA_COOKIES:
    'https://xpress-dev.web.app/cookies-declaration',
  BACKEND_URL: 'https://xpress-api-ucccw7ir7q-uc.a.run.app/'
};

export default TestEnv;
