import React from 'react';
import { makeStyles } from '@material-ui/core';
import NavigationHistoryHeader from '../../../components/Navigation/NavigationHistoryHeader';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({}));

const Header = ({ restId }) => {
  const history = [
    { route: '/app', name: 'orders.details.header.board' },
    { name: 'members.details.board_administration' },
    {
      route: `/app/configuration/${restId}`,
      name: 'menu.sections.configuration'
    }
  ];
  return <NavigationHistoryHeader translateName history={history} />;
};

export default Header;
