/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  FormControl,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  OutlinedInput,
  InputAdornment,
  InputLabel
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-multi-lang';
import XpressSwitch from '../../../components/XpressSwitch';
import { useUpdateFreeDelivery } from '../../../hooks/useRestaurant';
import XpressModal from '../../../components/Modal';

const useStyles = makeStyles(() => ({
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const EnableDeliveryFree = ({ freeDelivery, restaurantId }) => {
  const translation = useTranslation();
  const [enableFreeDelivery, setEnableFreeDelivery] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [minimumAmount, setMinimumAmount] = useState(0);
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [enableButton, setEnableButton] = useState(false);

  useEffect(() => {
    setEnableFreeDelivery(freeDelivery?.enable);
    setMinimumAmount(freeDelivery?.minimumAmount);
    // eslint-disable-next-line
  }, []);

  const handleChangeMinimum = event => {
    const amount = parseFloat(event.target.value);
    if (amount >= 0) {
      setEnableButton(true);
      setMinimumAmount(parseFloat(event.target.value));
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleClickUpdate = async () => {
    setEnableButton(false);
    try {
      const deliveryFree = {
        enable: enableFreeDelivery,
        minimumAmount,
        chargeByXpress: freeDelivery.chargeByXpress
      };
      await useUpdateFreeDelivery(restaurantId, deliveryFree);
      handleClose();
      enqueueSnackbar(translation('notification.correctly_save'), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(translation('account.unable_logout'), {
        variant: 'error'
      });
    }
  };

  const handleSaveChanges = async () => {
    setShowModal(true);
    setModal(
      <XpressModal>
        <Grid>
          <Card className={classes.content}>
            <CardHeader
              className={classes.Header}
              title={
                <Typography style={{ fontWeight: 'bold' }}>
                  ¿GUARDAR CAMBIOS?
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClickUpdate}
                  disabled={!enableButton}
                >
                  Guardar
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClose}
                  disabled={!enableButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </XpressModal>
    );
  };

  return (
    <Container>
      <Box display="flex" flexDirection="column" mb={3}>
        <Box display="flex" alignItems="center">
          <Typography style={{ fontSize: 23, marginRight: 15 }}>
            Habilitado
          </Typography>
          <XpressSwitch
            value={enableFreeDelivery}
            onClick={() => {
              setEnableFreeDelivery(!enableFreeDelivery);
              setEnableButton(true);
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ marginRight: 10 }}
          >
            {translation('free_delivery.free_delivery_form.amounth_equal')}
          </Typography>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Costo</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              variant="outlined"
              type="number"
              size="small"
              label="Costo"
              value={minimumAmount}
              onChange={event => handleChangeMinimum(event)}
              startAdornment={
                <InputAdornment position="start">Bs.</InputAdornment>
              }
              disabled={!enableFreeDelivery}
            />
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="contained"
            size="medium"
            style={{ backgroundColor: '#fbdd39' }}
            onClick={handleSaveChanges}
            disabled={!enableButton}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      {showModal && modal}
    </Container>
  );
};

export default EnableDeliveryFree;
