import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useFirestore } from 'react-redux-firebase';
import ExpressTable from '../../../components/Table/ExpressTable';
import { useDocuments } from '../../../hooks/AdminHooks/document/useDocument';
import firebaseService from '../../../services/firebaseService';
import { COLLECTIONS, DOCUMENT_TYPE } from '../../../constants';
import XpressModal from '../../../components/Modal';
import Document from '../../../dto/document';

const getCollection = type => {
  switch (type) {
    case DOCUMENT_TYPE.SUBSIDIARY:
      return DOCUMENT_TYPE.SUBSIDIARY;
    case DOCUMENT_TYPE.RESTAURANT:
      return DOCUMENT_TYPE.RESTAURANT;
    case DOCUMENT_TYPE.INVOICE:
      return DOCUMENT_TYPE.INVOICE;
    default:
      console.log('unhandled document type!!');
      break;
  }
};

const DocumentsTable = ({ id, type }) => {
  const translation = useTranslation();
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const firestore = useFirestore();
  const documents = useDocuments(id, type);
  const documentCollection = getCollection(type);
  let tableContent = [];
  const tableColumns = [
    {
      id: 'id',
      label: translation('admin_subsidiary.table.no'),
      minWidth: 50,
      align: 'center'
    },
    {
      id: 'name',
      label: translation('admin_subsidiary.table.doc_name'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'status',
      label: translation('admin_subsidiary.table.status'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'download',
      label: translation('admin_subsidiary.table.download'),
      minWidth: 170,
      align: 'center'
    }
  ];

  if (documents) {
    tableContent = documents
      .filter(item => item.enable)
      .map((item, index) => ({
        id: index + 1,
        name: item.name,
        status: item.status,
        download: (
          <a
            target="_blank"
            href={item.url.original ? item.url.original : item.url}
            download
            rel="noopener noreferrer"
            style={{ color: 'yellow' }}
          >
            {translation('admin_subsidiary.table.download')}
          </a>
        ),
        docId: item.id,
        linkTo: item.url
      }));
  }

  const createDocument = async (url, nameFile) => {
    const docId = firebaseService.randomId('document');
    const doc = new Document();
    doc.id = docId;
    doc.url = url;
    doc.name = nameFile;
    doc.name_file = nameFile;
    await firestore
      .collection(`${documentCollection}/${id}/${COLLECTIONS.DOCUMENT}`)
      .doc(docId)
      .set({ ...doc });
  };

  const addDocument = () => {
    setShowModal(true);
    const path = `${documentCollection}/${id}`;
    setModal(
      <XpressModal
        type="uploadFile"
        path={path}
        handleConfirm={async (url, name) => {
          createDocument(url, name);
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
      />
    );
  };
  const editDocument = async (rowId, editedField) => {
    await firestore
      .collection(`${documentCollection}/${id}/${COLLECTIONS.DOCUMENT}`)
      .doc(rowId)
      .update(editedField);
  };
  const editModal = row => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="editDocument"
        status={row.status}
        name={row.name}
        handleConfirm={async (status, name) => {
          editDocument(row.docId, { status, name });
          setShowModal(false);
        }}
        handleClose={async () => setShowModal(false)}
      />
    );
  };

  const disableDocument = row => {
    editDocument(row.docId, { enable: false });
  };

  return (
    <Card>
      <CardHeader title={translation('admin_subsidiary.table.header')} />
      <Divider />
      <CardContent>
        <ExpressTable
          columns={[...tableColumns]}
          rows={[...tableContent]}
          actions={{
            edit: row => editModal(row),
            delete: row => disableDocument(row)
          }}
        />
        <Box mt={2}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="button"
            variant="contained"
            onClick={addDocument}
          >
            {translation('admin_subsidiary.table.buttons.add_doc')}
          </Button>
        </Box>
        {showModal && modal}
      </CardContent>
    </Card>
  );
};

export default DocumentsTable;
