export default class DeliveryDisabledReasons {
  constructor({
    delivery_disabled_per_city_id = [],
    delivery_closed_by_zone_demand = []
  } = {}) {
    this.delivery_disabled_per_city_id = delivery_disabled_per_city_id;
    this.delivery_closed_by_zone_demand = delivery_closed_by_zone_demand;
  }

  toPlainObject() {
    return {
      ...this
    };
  }
}
