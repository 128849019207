export default class TransitionOrderServices {
  constructor({ DELIVERY = false, PICK_UP = false, ENTREGAS = false } = {}) {
    this.DELIVERY = DELIVERY;
    this.PICK_UP = PICK_UP;
    this.ENTREGAS = ENTREGAS;
  }

  toPlainObject() {
    return {
      ...this
    };
  }
}
