import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useParams } from 'react-router';
import Page from '../../../components/Page';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import SubsidiariesList from '../../admin/commerce/tabs/SubsidiariesList';
import { useRestaurant, useRestaurantSnap } from '../../../hooks/useRestaurant';
import Header from './Header';
import RestaurantForm from '../../admin/commerce/forms/RestaurantFormOnConfig';
import TabsHeader from '../../../components/Tab/Tabs';
// import RestaurantSettingsView from '../settingsView/RestaurantSettingsView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const Configurations = () => {
  const classes = useStyles();
  const { restId } = useParams();
  const useRest = restId ? useRestaurantSnap : useRestaurant;
  const restaurant = useRest(restId || '');
  const [value, setValue] = useState(0);
  const [restaurantMerged, setRestaurantMerged] = useState(0);
  const [selectRestaurant, setSelectRestaurant] = useState(restaurant?.id);
  const translation = useTranslation();
  const allCities = useAllCities();

  const resMergedTab = [
    {
      id: restaurant?.id,
      value: 0,
      label: restaurant?.name
    }
  ];
  if (restaurant?.restaurantsMerged?.length > 0) {
    restaurant.restaurantsMerged.forEach((rest, index) => {
      resMergedTab.push({
        id: rest.restaurantId,
        value: index + 1,
        label: rest.restaurant_name
      });
    });
  }
  console.log('res', restId);
  if (!restaurant) {
    return (
      <Box display="flex" justifyContent="center" my={5}>
        <CircularProgress />
      </Box>
    );
  }

  const handleChange = (event, index) => {
    setValue(index);
  };
  const handleTab = (event, index) => {
    setRestaurantMerged(index);
    setSelectRestaurant(resMergedTab[index].id);
  };
  const getTabContent = () => {
    switch (value) {
      case 0:
        if (!allCities) return null;
        return (
          <RestaurantForm
            restaurantId={selectRestaurant || restaurant.id}
            allCities={allCities}
            isMerchant
          />
        );
      case 1:
        return (
          <SubsidiariesList
            restId={selectRestaurant || restaurant.id}
            isMerchant
          />
        );
      /*  case 2:
        if (!allCities) return null;
        return <RestaurantSettingsView currentRestaurant={restaurant} />; */
      default:
        return null;
    }
  };
  const tabs = [
    {
      value: 0,
      label: translation('admin_rest.tabs.gen_data')
    },
    {
      value: 1,
      label: translation('admin_rest.tabs.res_sub_list')
    }
    /*     {
      value: 2,
      label: translation('admin_rest.tabs.settings')
    } */
  ];

  return (
    <Page className={classes.root} title={translation('admin_commerces.title')}>
      <Container maxWidth={false}>
        <Box mb={2}>
          <Header restId={restaurant.id} />
        </Box>
        {restaurant?.restaurantsMerged && (
          <TabsHeader
            value={restaurantMerged}
            onChange={handleTab}
            tabs={resMergedTab}
          />
        )}
        {restaurant && (
          <>
            <TabsHeader value={value} onChange={handleChange} tabs={tabs} />
            {getTabContent()}
          </>
        )}
      </Container>
    </Page>
  );
};

export default Configurations;
