import axios from 'src/utils/axios';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

const COLLECTION = COLLECTIONS.SUBSIDIARY;
const popagateNameEndPoint = '/menu/propagateMenuName';
const disableMenuProductsEndPoint = 'menu/disableProducts';

export const addMenuSection = async (newSection, subsidiaryId) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(subsidiaryId)
    .get();
  const existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections.push(newSection);
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(subsidiaryId)
    .set({ menu_section: existingSections }, { merge: true });
};

export const removeMenuSection = async (id, index) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  const existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections.splice(index, 1);
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update({ menu_section: existingSections });
};

export const editMenuSection = async (editedFields, id, index) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  const existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections[index] = editedFields;
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update({ menu_section: existingSections });
};

export const swapArrayPositions = async (
  section,
  index,
  direction,
  subsidiaryId
) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(subsidiaryId)
    .get();
  const existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  if (direction === 'up') {
    const swapableSection = [...existingSections].splice(index - 1, 1)[0];
    existingSections[index] = swapableSection;
    existingSections[index - 1] = section;
  } else {
    const swapableSection = [...existingSections].splice(index + 1, 1)[0];
    existingSections[index] = swapableSection;
    existingSections[index + 1] = section;
  }
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(subsidiaryId)
    .update({ menu_section: existingSections });
};

export const saveMenuSections = (subsidiaryId, menuSections, collection) =>
  admin
    .firestore()
    .collection(collection)
    .doc(subsidiaryId)
    .update({ menu_section: menuSections });

export const saveMenuSectionsInSubsidiaries = async (
  restaurantId,
  menuSection
) => {
  const batch = admin.firestore().batch();
  const subsidiaries = (
    await admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('restaurant_id', '==', restaurantId)
      .get()
  ).docs.map(doc => ({ ...doc.data(), id: doc.id }));
  subsidiaries.forEach(sub => {
    const selfSections = (sub.menu_section || []).filter(
      section => !section.template
    );
    batch.update(
      admin
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(sub.id),
      { menu_section: [...menuSection, ...selfSections] }
    );
  });
  return batch.commit();
};

export const saveMenuProductsOrder = async productsList => {
  const batch = admin.firestore().batch();
  const prodRef = admin.firestore().collection(COLLECTIONS.PRODUCT);
  productsList.forEach((product, index) => {
    const document = prodRef.doc(product.id);
    batch.set(document, { menu_order: index }, { merge: true });
  });
  return batch.commit();
};

export const propagateNewSection = (section, name, restId, isTemplate) => {
  const newSection = { ...section };
  newSection.name = name;
  axios.post(popagateNameEndPoint, {
    section: newSection,
    restId,
    isTemplate
  });
};

export const disableMenuProducts = async section => {
  axios.post(disableMenuProductsEndPoint, { ...section });
};
