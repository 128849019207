import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import _ from 'lodash';
import Header from './Header';
import ExpressTable from '../../../../../components/Table/ExpressTable';
import useFilter from '../../../../../hooks/filter/useFilter';
import { setDefaultFields } from '../../../../../actions/filterActions';
import {
  filteredOrders,
  updateOrder
} from '../../../../../services/orderServices';
import { ORDER_TYPE, ORDER_STATUS } from '../../../../../dto/enum';
import { useReportsStyles } from './styles';

const Reports = orders => {
  const [loading, setLoading] = useState(false);
  const filter = useFilter();
  const translation = useTranslation();
  const [tableContent, setTableContent] = useState([]);
  const dispatch = useDispatch();
  const [totalCost, setTotalCost] = useState('0.00');
  const [totalSubsidy, setTotalSubsidy] = useState('0.00');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const classes = useReportsStyles();

  const tableColumns = [
    {
      id: 'counter',
      label: translation('admin_dealers.tabs.columns.counter'),
      minWidth: 50,
      align: 'center'
    },
    {
      id: 'orderCode',
      label: translation('admin_dealers.tabs.columns.order_code'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'driverName',
      label: translation('admin_dealers.tabs.columns.driver_name'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'subsiRestName',
      label: translation('admin_dealers.tabs.columns.subsi_rest_name'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'date',
      label: translation('admin_dealers.tabs.columns.date'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'deliveryFee',
      label: translation('admin_dealers.tabs.columns.delivery_fee'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'subsidy',
      label: translation('admin_dealers.tabs.columns.subsidy'),
      minWidth: 150,
      align: 'center'
    },
    {
      id: 'bonus',
      label: translation('admin_dealers.tabs.columns.bonus'),
      minWidth: 150,
      align: 'center'
    }
  ];

  useEffect(() => {
    return () => dispatch(setDefaultFields());
  }, [dispatch, selectedOrders]);

  useEffect(() => {
    setLoading(true);
    filteredOrders(filter, orders).then(r => {
      const tc = r.payload
        .map(item => ({
          id: item.id,
          orderCode: item.order_code,
          driverName: !_.isEmpty(item.driver)
            ? `${item.driver.first_name} ${item.driver.last_name}`
            : '-',
          driverId: item.driver_id ? item.driver_id : '',
          subsiRestName: `${item.restaurant_name} - ${item.subsidiary_name}`,
          date: moment(item.created_at.toDate()).format('DD/MM/YYYY HH:mm'),
          deliveryFee: item.delivery_fee,
          subsidy: item.subsidy || 0,
          bonus: item.bonus || 0,
          orderType: item.order_type,
          orderDeliveryStatus: item.delivery_status,
          orderPaid: item.order_paid
        }))
        .filter(
          item =>
            item.orderType === ORDER_TYPE.DELIVERY &&
            item.orderDeliveryStatus === ORDER_STATUS.COMPLETED
        )
        .map((item, index) => ({ ...item, counter: index + 1 }));
      setTableContent(tc);
      setLoading(false);
      if (tc.length) {
        setTotalCost(
          tc
            .reduce(
              (total, currentValue) => total + currentValue.deliveryFee,
              0
            )
            .toFixed(2)
        );
        setTotalSubsidy(
          tc
            .reduce((total, currentValue) => total + currentValue.subsidy, 0)
            .toFixed(2)
        );
      }
    });
  }, [filter, orders]);

  useEffect(() => {
    if (selectedOrders.length) {
      setLoading(true);
      selectedOrders.map(async order => {
        await updateOrder(order, {
          order_paid: true
        });
      });
      setLoading(false);
      setSelectedOrders([]);
    }
  }, [selectedOrders]);

  return (
    <Grid>
      {loading && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box>
          <Header orders={tableContent} />
          <ExpressTable
            columns={tableColumns}
            rows={tableContent}
            search
            enhanced
            handlePayment={selected => setSelectedOrders(selected)}
            markPaidRow="orderPaid"
          />
          <Card className={classes.card}>
            <Box className={classes.container}>
              <Box className={classes.container}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('admin_dealers.reports.summary.total_cost')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  {`Bs. ${totalCost}`}
                </Typography>
              </Box>
              <Box className={classes.container}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('admin_dealers.reports.summary.total_subsidy')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  {`Bs. ${totalSubsidy}`}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
    </Grid>
  );
};

export default Reports;
