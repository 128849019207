import { useEffect, useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS, DOCUMENT_TYPE } from '../../../constants';
import admin from '../../../config/firebaseConfig';
import Document from '../../../dto/document';

const getCollection = type => {
  switch (type) {
    case DOCUMENT_TYPE.SUBSIDIARY:
      return DOCUMENT_TYPE.SUBSIDIARY;
    case DOCUMENT_TYPE.RESTAURANT:
      return DOCUMENT_TYPE.RESTAURANT;
    case DOCUMENT_TYPE.INVOICE:
      return DOCUMENT_TYPE.INVOICE;
    default:
      console.log('unhandled document type!!');
      break;
  }
};

export const useDocuments = (id, type) => {
  const collection = getCollection(type);

  useFirestoreConnect([
    {
      collection,
      doc: id,
      subcollections: [{ collection: COLLECTIONS.DOCUMENT }],
      storeAs: `documents`
    }
  ]);
  const documents = useSelector(state => state.firestore.ordered.documents);

  return documents;
};

export const useDocumentsRestaurantNative = restId => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .collection(COLLECTIONS.DOCUMENT)
        .where('enable', '==', true)
        .orderBy('created_at', 'asc')
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => new Document({ ...doc.data() }));
          setDocuments(data);
        });
    }
    // eslint-disable-next-line
  }, []);

  return documents;
};

export const useGetBanner = restId => {
  const [logoBanner, setLogoBanner] = useState({});

  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .onSnapshot(snap => {
          const banner = snap.data().banner_photo;
          const logo = snap.data().logo_photo;
          setLogoBanner({ logo, banner });
        });
    }
    // eslint-disable-next-line
  }, []);

  return logoBanner;
};
