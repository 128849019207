import React from 'react';
import { Card, CardContent, Dialog, CardMedia } from '@material-ui/core';

function ModalDealerImg({
  showImg = { show: false, img: '' },
  setShowImg = () => {}
}) {
  return (
    <Dialog
      open={showImg.show}
      fullWidth
      maxWidth="xl"
      onClose={() => setShowImg({ show: false, img: '' })}
    >
      <Card>
        <CardContent>
          <CardMedia
            component="img"
            alt="Image"
            image={showImg.img}
            title="Image"
          />
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default ModalDealerImg;
