/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  CircularProgress,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import MapPicker from '../../../../components/Register/MapPicker';
import { editSubsidiary } from '../../../../services/subsidiaryService';
import { useSubsidiaryById } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import admin from '../../../../config/firebaseConfig';
import useCategories from '../../../../hooks/useCategories';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {},
  formCard: {
    width: '100%',
    margin: '20px 0'
  }
}));

const SubsidiaryForm = ({ id, allCities }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [location, setLocation] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const subsidiary = useSubsidiaryById(id);
  const { enqueueSnackbar } = useSnackbar();
  const categories = useCategories();

  useEffect(() => {
    if (subsidiary)
      setLocation({
        lat: subsidiary.location.latitude,
        lng: subsidiary.location.longitude
      });
  }, [subsidiary]);

  return (
    <>
      {!subsidiary && (
        <>
          <Card>
            <CardHeader title={translation('edit.product_create.load')} />
            <Divider />
            <CardContent>
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            </CardContent>
          </Card>
        </>
      )}
      {subsidiary && subsidiary.id === id && (
        <Formik
          initialValues={{
            name: subsidiary.name ? subsidiary.name : '',
            manager: subsidiary.legal_representant
              ? subsidiary.legal_representant.name
              : '',
            manager_phone: subsidiary.legal_representant
              ? subsidiary.legal_representant.phone
              : '',
            address: subsidiary.address ? subsidiary.address : '',
            nit: subsidiary.nit ? subsidiary.nit : '',
            address_2: subsidiary.address_2 ? subsidiary.address_2 : '',
            city:
              subsidiary.city && subsidiary.city.id
                ? subsidiary.city.id
                : allCities[0].id,
            categories:
              subsidiary.categories && subsidiary.categories.length
                ? subsidiary.categories
                : []
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(translation('subsidiary.required')),
            manager: Yup.string().required(translation('subsidiary.required')),
            manager_phone: Yup.string().required(
              translation('subsidiary.required')
            ),
            address: Yup.string().required(translation('subsidiary.required')),
            nit: Yup.number()
              .positive(translation('subsidiary.number'))
              .required(translation('subsidiary.required')),
            address_2: Yup.string(),
            city: Yup.string().required(translation('subsidiary.required')),
            categories: Yup.array()
              .max(10)
              .required(translation(translation('subsidiary.required')))
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setSubmitting(true);
              if (location)
                values.location = new admin.firestore.GeoPoint(
                  location.lat,
                  location.lng
                );
              if (subsidiary.city.id !== values.city) {
                const newCity = values.city;
                const cityName = allCities.find(item => item.id === values.city)
                  .name;
                values.city = {
                  ...subsidiary.city,
                  id: newCity,
                  location: new admin.firestore.GeoPoint(
                    location.lat,
                    location.lng
                  ),
                  name: cityName
                };
              }
              if (subsidiary.city.id === values.city) {
                values.city = {
                  ...subsidiary.city,
                  location: new admin.firestore.GeoPoint(
                    location.lat,
                    location.lng
                  )
                };
              }
              values.legal_representant = {
                ...subsidiary.legal_representant,
                name: values.manager,
                phone: values.manager_phone
              };
              await editSubsidiary(subsidiary.id, values);
              values.city =
                subsidiary.city && subsidiary.city.id
                  ? values.city.id
                  : allCities[0].id;
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(translation('notification.correctly_save'), {
                variant: 'success'
              });
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              // resetForm();
              setSubmitting(false);
              if (error.message.includes("Cannot read property 'lat'")) {
                enqueueSnackbar(translation('notification.error_map'), {
                  variant: 'error'
                });
              } else {
                enqueueSnackbar(
                  translation('notification.something_go_wrong'),
                  {
                    variant: 'error'
                  }
                );
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            // isSubmitting,
            touched,
            values,
            setFieldValue
          }) => {
            const city = allCities.find(c => c.id === values.city);
            const optionalMapProps = {};
            if (city && city.location) {
              optionalMapProps.center = {
                lat: city.location.latitude,
                lng: city.location.longitude
              };
            }
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid item container>
                    <Grid item container>
                      <Card className={classes.formCard}>
                        <CardHeader
                          title={translation(
                            'subsidiary.basic_information.title'
                          )}
                        />
                        <Divider />
                        <CardContent>
                          <Grid item container>
                            <Grid item container xs={6} md={6}>
                              <Box mr={1.5} width="100%">
                                <Grid item container>
                                  <Box mb={3} width="50%" pr={1.5}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.name && errors.name
                                      )}
                                      helperText={touched.name && errors.name}
                                      label={translation(
                                        'subsidiary.basic_information.name'
                                      )}
                                      name="name"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.name}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                  <Box mb={3} width="50%" pl={1.5}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.manager && errors.manager
                                      )}
                                      helperText={
                                        touched.manager && errors.manager
                                      }
                                      label={translation(
                                        'subsidiary.basic_information.name_manager'
                                      )}
                                      name="manager"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.manager}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item container>
                                  <Box mb={3} width="50%" pr={1.5}>
                                    <TextField
                                      error={Boolean(
                                        touched.address && errors.address
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.address && errors.address
                                      }
                                      label={translation(
                                        'subsidiary.basic_information.address'
                                      )}
                                      name="address"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.address}
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            disablePointerEvents
                                          >
                                            <IconButton
                                              onClick={() => setShowMap(true)}
                                            >
                                              <LocationOn />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                  <Box mb={3} width="50%" pl={1.5}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.manager_phone &&
                                          errors.manager_phone
                                      )}
                                      helperText={
                                        touched.manager_phone &&
                                        errors.manager_phone
                                      }
                                      label={translation(
                                        'subsidiary.basic_information.phone_manager'
                                      )}
                                      name="manager_phone"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.manager_phone}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Box>
                            </Grid>

                            <Grid item container xs={6} md={6}>
                              <Box ml={1.5} width="100%">
                                <Grid item container>
                                  <Box mb={3} width="100%">
                                    <TextField
                                      type="number"
                                      fullWidth
                                      error={Boolean(touched.nit && errors.nit)}
                                      helperText={touched.nit && errors.nit}
                                      label={translation(
                                        'subsidiary.basic_information.nit'
                                      )}
                                      name="nit"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.nit}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item container justify="space-between">
                                  <Box mb={3} width="50%" pr={1.5}>
                                    <TextField
                                      fullWidth
                                      error={Boolean(
                                        touched.address_2 && errors.address_2
                                      )}
                                      helperText={
                                        touched.address_2 && errors.address_2
                                      }
                                      label={translation(
                                        'subsidiary.basic_information.local_address'
                                      )}
                                      name="address_2"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.address_2}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true
                                      }}
                                    />
                                  </Box>
                                  <Box mb={3} width="50%" pl={1.5}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formControl}
                                    >
                                      <InputLabel>
                                        {translation(
                                          'subsidiary.basic_information.city'
                                        )}
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        variant="outlined"
                                        label="Ciudad"
                                        name="city"
                                        id="city"
                                        value={values.city}
                                        onChange={event => {
                                          setFieldValue(
                                            'city',
                                            event.target.value
                                          );
                                          setFieldValue('address', '');
                                          setLocation(null);
                                        }}
                                        inputProps={{ readOnly: true }}
                                      >
                                        {allCities.map(item => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={categories}
                                getOptionLabel={s => {
                                  if (!s) return;
                                  // eslint-disable-next-line consistent-return
                                  return s.name;
                                }}
                                filterSelectedOptions
                                value={values.categories.map(v =>
                                  categories.find(s => v === s.id)
                                )}
                                onChange={(e, cat) => {
                                  setFieldValue(
                                    'categories',
                                    cat.map(s => s.id)
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={translation(
                                      'subsidiary.basic_information.category'
                                    )}
                                    error={Boolean(
                                      touched.categories && errors.categories
                                    )}
                                    helperText={
                                      touched.categories && errors.categories
                                    }
                                    placeholder={translation(
                                      'subsidiary.basic_information.category'
                                    )}
                                  />
                                )}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          {/* <Box mt={3} mb={3}>
                            <Button
                              color="secondary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {translation('subsidiary.save')}
                            </Button>
                          </Box> */}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </form>
                <MapPicker
                  open={showMap}
                  setShowMap={setShowMap}
                  setPicker={setLocation}
                  location={location}
                  {...optionalMapProps}
                />
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default SubsidiaryForm;
