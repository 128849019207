import { useSelector } from 'react-redux';
import { COLLECTIONS } from 'src/constants';
import ChargesHistory from 'src/dto/restaurant/chargesHistory';
import { useFirestoreConnect } from 'react-redux-firebase';
import { createMomentOnBolivianTime as moment } from '../../utils/date';

/* eslint-disable import/prefer-default-export */
export const useChargesHistoryByRestaurant = restId => {
  const today = moment().format('YYYY/MM/DD hh:mm:ss');
  const dateNow = new Date(today);
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      doc: restId,
      subcollections: [{ collection: COLLECTIONS.CHARGES_HISTORY }],
      where: [['startsOn', '<=', dateNow]],
      limit: 1,
      orderBy: ['startsOn', 'desc'],
      storeAs: `charges_history_rest_${restId}`
    }
  ]);
  const chargesHitoryRest = useSelector(
    state => state.firestore.ordered[`charges_history_rest_${restId}`]
  );

  return (
    chargesHitoryRest?.map(data => new ChargesHistory(data).toPlainObject()) ||
    []
  );
};
