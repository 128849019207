import React from 'react';
import { Card, Box, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useChatLayoutStyles } from '../styles';
import { GreenCrossButton } from '../../../../components/Inputs/Buttons';
import CreateNewChat from './CreateNewChat';
import Conversations from './Conversations';

const ChatLayout = ({ handleClose }) => {
  const classes = useChatLayoutStyles();
  const translation = useTranslation();

  return (
    <Box className={classes.container}>
      <Card className={classes.cardContainer}>
        <Box className={classes.header}>
          <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
            {translation('chat.chat_layout.title')}
          </Typography>
          <GreenCrossButton onClick={handleClose} />
        </Box>
        <CreateNewChat handleClose={handleClose} />
        <Typography style={{ fontSize: 20, marginBottom: 5, paddingLeft: 16 }}>
          {translation('chat.chat_layout.recent_conversations')}
        </Typography>
        <Divider />
        <Conversations handleClose={handleClose} />
      </Card>
    </Box>
  );
};

export default ChatLayout;
