import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import orderStatusTranslate from '../../../utils/translateUtil';
import { ORDER_STATUS } from '../../../dto/enum';

const useStyles = makeStyles(theme => ({
  successStatus: {
    backgroundColor: theme.palette.secondary.main
  },
  dangerStatus: {
    backgroundColor: theme.palette.expressColors.lightRed
  }
}));

const Orderstatus = ({ orderStatus }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const { status } = orderStatusTranslate(orderStatus, translation);
  return (
    <Chip
      label={status}
      className={clsx(classes.successStatus, {
        [classes.dangerStatus]: orderStatus === ORDER_STATUS.CANCELED
      })}
    />
  );
};

export default Orderstatus;
