/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import 'firebase/storage';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dropZoneDisabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    cursor: 'pointer'
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  listItem: {
    flexWrap: 'wrap'
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function FilesDropzone({
  className,
  labels,
  setImage,
  previewImage = () => {},
  onFinish,
  maxDimensions,
  maxSize,
  icon = '/static/images/undraw_add_file2_gvbb.svg',
  type = 'image',
  validImageExtensions = ['image/jpg', 'image/jpeg'],
  ...rest
}) {
  const classes = useStyles();
  const [dimensionError, setDimensionError] = useState([]);
  const translation = useTranslation();

  const validateFileFormat = file => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      setDimensionError('');
      if (file) {
        const messageDimensions = [];
        // if (!validImageExtensions.includes(image.type)) {
        //   const format = image.type.split('/');
        //
        //   messageDimensions.push(
        //     `El formato ${format[1] ? format[1] : image.type} no es aceptado.`
        //   );
        // }
        if (messageDimensions.length) {
          setDimensionError(messageDimensions);
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  };

  const validateImageSizeAndFormat = image => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      setDimensionError('');
      const URL = window.URL || window.webkitURL;
      if (image) {
        const img = new Image();
        const objectUrl = URL.createObjectURL(image);
        // eslint-disable-next-line func-names
        img.onload = function() {
          const messageDimensions = [];
          if (maxDimensions) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (maxDimensions.width && maxDimensions.width !== this.width) {
              messageDimensions.push(
                `Ancho de ${maxDimensions.width} px es requerido.`
              );
            }

            if (maxDimensions.height && maxDimensions.height !== this.height) {
              messageDimensions.push(
                `Alto de ${maxDimensions.height} px es requerido.`
              );
            }

            // eslint-disable-next-line react/no-this-in-sfc
            if (maxDimensions.minWidth && maxDimensions.minWidth > this.width) {
              messageDimensions.push(
                `Ancho mínimo de ${maxDimensions.minWidth} px`
              );
            }
            // eslint-disable-next-line react/no-this-in-sfc
            if (maxDimensions.maxWidth && maxDimensions.maxWidth < this.width) {
              messageDimensions.push(
                `Ancho maximo de ${maxDimensions.maxWidth} px`
              );
            }
            if (
              maxDimensions.minHeight &&
              // eslint-disable-next-line react/no-this-in-sfc
              maxDimensions.minHeight > this.height
            ) {
              messageDimensions.push(
                `Alto mínimo de ${maxDimensions.minHeight} px`
              );
            }
            if (
              maxDimensions.maxHeight &&
              // eslint-disable-next-line react/no-this-in-sfc
              maxDimensions.maxHeight < this.height
            ) {
              messageDimensions.push(
                `Alto maximo de ${maxDimensions.maxHeight} px`
              );
            }
          }
          if (!validImageExtensions.includes(image.type)) {
            const format = image.type.split('/');
            messageDimensions.push(
              `El formato ${format[1] ? format[1] : image.type} no es aceptado.`
            );
          }
          if (maxSize) {
            if (image.size / 1024 > maxSize) {
              messageDimensions.push(
                `El tamaño ${Math.round(
                  image.size / 1024
                )}KB es mayor a ${maxSize}KB  no es aceptado.`
              );
            }
          }

          if (messageDimensions.length) {
            setDimensionError(messageDimensions);
            resolve(false);
          } else {
            resolve(true);
          }
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
    });
  };

  const handleDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0];
    let valid = true;
    if (type === 'image') {
      valid = await validateImageSizeAndFormat(file);
      if (!valid) return;
      setImage(file);
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          previewImage(reader.result);
        },
        false
      );
      if (file && valid) {
        reader.readAsDataURL(file);
      }
    }
    if (valid && type === 'file') {
      valid = await validateFileFormat(file);
      if (!valid) return;
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = function(evt) {
        previewImage(evt.target.result);
      };
      reader.onerror = function(evt) {
        console.log('error reading file');
      };
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: false
        })}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            multiple: false
          })}
        />
        <div>
          <img alt="Select file" className={classes.image} src={icon} />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            {labels.selectFiles ? labels.selectFiles : 'Select file'}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {labels.instructions ? (
                labels.instructions
              ) : (
                <>
                  {translation('dropzone.files.instructions.line1')}{' '}
                  <Link underline="always">browse</Link>{' '}
                  {translation('dropzone.files.instructions.line2')}
                </>
              )}
            </Typography>
          </Box>
        </div>
      </div>
      {dimensionError.length > 0 && (
        <Box m={1}>
          <Alert severity="error">
            Por favor verificar :
            <ul>
              {dimensionError.map(e => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.object,
  maxImages: PropTypes.number,
  type: PropTypes.string,
  icon: PropTypes.string,
  setImage: PropTypes.func,
  onFinish: PropTypes.func
};

export default FilesDropzone;
