export default class SummaryInvoices {
  constructor({
    total_debt_balance = 0,
    paid_debt_balance = 0,
    total_refund_balance = 0,
    paid_refund_balance = 0
  } = {}) {
    this.total_debt_balance = total_debt_balance; // este es el total bruto positivo adeudado
    this.paid_debt_balance = paid_debt_balance; // este es el monto pagado por el restaurante a amortizar de debt_balance
    this.total_refund_balance = total_refund_balance; //
    this.paid_refund_balance = paid_refund_balance;
  }

  toPlainObject() {
    return { ...this };
  }

  getPendingDebt() {
    return this.total_debt_balance - this.paid_debt_balance;
  }

  getPendingRefund() {
    return this.total_refund_balance - this.paid_refund_balance;
  }

  getTotalPending() {
    return this.getPendingDebt() - this.getPendingRefund();
  }
}
