/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polygon
} from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import demoFancyMapStyles from './demoFancyMapStyles';
import env from '../../env';

function Map({ center, coords = [], ...props }) {
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={center}
      defaultOptions={{ styles: demoFancyMapStyles }}
      center={center}
    >
      {coords.length && (
        <Polygon
          path={coords}
          key={1}
          options={{
            // fillColor: '#000',
            fillOpacity: 0,
            strokeColor: '#F00',
            strokeOpacity: 1,
            strokeWeight: 2
          }}
        />
      )}
    </GoogleMap>
  );
}

export const MapWrapped = withScriptjs(withGoogleMap(Map));

const useStyles = makeStyles(theme => ({
  mapResponsiveContainer: {
    height: 850,
    [theme.breakpoints.down('md')]: {
      height: 400
    }
  }
}));

const MapPoligon = ({ center, coords }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.mapResponsiveContainer)}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        center={center}
        coords={coords}
      />
    </div>
  );
};

MapPoligon.propTypes = {
  center: PropTypes.object,
  coords: PropTypes.array
};

export default MapPoligon;
