/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@material-ui/core';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createMomentOnBolivianTime } from 'src/utils/date';
import TagSimpleTextOval from 'src/components/TagSimpleTextOval';
import { INVOICES } from '../../../../constants';
import { COLORS } from '../../../../theme/colors';
import { styles } from '../styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  accordion: {
    flexDirection: 'row-reverse',
    gap: '50px'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '18px'
  },
  expandIcon: {
    color: COLORS.success
  },
  tableCell: {
    borderColor: COLORS.green,
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingBlock: '30px'
  },
  firstTableCell: {
    orderBlockStart: 'none',
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    paddingBlock: '30px'
  },
  tableCellHeader: {
    borderBlockStart: 'none',
    borderBlockEnd: `1px solid ${COLORS.green}`
  },
  fontWeight: {
    fontWeight: 'bold'
  },
  deleteIcon: {
    color: COLORS.red,
    cursor: 'pointer'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '10px'
  },
  button: {
    cursor: 'pointer',
    color: COLORS.success
  },
  link: {
    textDecoration: 'none'
  }
}));

const COLUMNS = [
  'Mes',
  '# de ordenes totales',
  'Comisiones por servicios con pago en efectivo',
  'Comisión por servicios con pago en linea',
  'Total comisiones',
  'Reembolso',
  'Saldo pendiente',
  'Total deuda',
  'Estado',
  'Reporte mensual'
];

const parseFloat = number => {
  return number === 0 ? 0 : Number.parseFloat(number).toFixed(2);
};

const STATUS = {
  PAID: 'Pagado',
  PENDING: 'Pendiente'
};

const SummaryTable = ({ invoices }) => {
  const classes = useStyles();
  const mainStyle = styles();
  const translation = useTranslation();

  return (
    <Box className={classes.container}>
      <Accordion>
        <AccordionSummary
          className={classes.accordion}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Resumen global</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer className={mainStyle.root}>
            <Table stickyHeader size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {COLUMNS.map(col => (
                    <TableCell
                      align="center"
                      className={clsx(
                        classes.tableCell,
                        classes.tableCellHeader,
                        classes.fontWeight
                      )}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {invoices?.length > 0 ? (
                <TableBody>
                  {invoices.map((invoice, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {`${translation(
                              `month.${invoice?.month_created}`
                            )}/${createMomentOnBolivianTime(
                              invoice?.period?.from.toDate()
                            ).format('YYYY')}`}
                            {STATUS[invoice?.status] === 'Pendiente' &&
                              parseFloat(invoice?.getPendingBalance() || 0) !==
                                Number((0).toFixed(2)) && (
                                <Box style={{ marginLeft: 4 }}>
                                  <TagSimpleTextOval
                                    title="PENDIENTE"
                                    color="#E66447"
                                    stylesTag={{
                                      maxWidth: 72,
                                      marginLeft: 0,
                                      height: 15
                                    }}
                                    stylesText={{
                                      fontSize: 10,
                                      marginRight: 4,
                                      color: COLORS.white,
                                      padding: '1px 5px'
                                    }}
                                  />
                                </Box>
                              )}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {invoice?.total_orders}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(
                            invoice?.summary.cash.total_comission || 0
                          )}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(
                            invoice?.summary.online.total_comission || 0
                          )}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.tableCellFirst
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(
                            invoice?.summary.online.total_comission +
                              invoice.summary.cash.total_comission || 0
                          )}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(
                            invoice?.amount_comission_refund || 0
                          )}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(
                            invoice?.getPendingBalance() || 0
                          )}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {`Bs. ${parseFloat(invoice?.getCharges() || 0)}`}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {STATUS[invoice?.status] || '-'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={
                            index === 0
                              ? classes.firstTableCell
                              : classes.tableCell
                          }
                        >
                          {invoice.reportUrl === INVOICES.REPORT_NO_GENERATED &&
                            'Sin Reporte'}
                          {invoice.reportUrl !==
                            INVOICES.REPORT_NO_GENERATED && (
                            <a
                              href={invoice.reportUrl}
                              className={classes.link}
                              rel="noreferrer"
                            >
                              <SystemUpdateAltOutlinedIcon
                                className={classes.button}
                              />
                            </a>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} />
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SummaryTable;
