import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isAdmin } from '../utils/helpers/userHelper';

function AdminGuard({ children }) {
  const { user } = useSelector(state => state.account);
  if (user) {
    if (isAdmin(user)) {
      return children;
    }
  }
  return <Redirect to="/login" />;
}

AdminGuard.propTypes = {
  children: PropTypes.any
};

export default AdminGuard;
