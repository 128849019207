import { useState } from 'react';
import Restaurant from '../../../../dto/restaurant';

const useRegisterRestaurant = () => {
  const [restaurant, setRestaurant] = useState(
    new Restaurant({ accepted_terms: false })
  );
  const setTerms = acceptedTerms =>
    setRestaurant({ ...restaurant, accepted_terms: acceptedTerms });
  const setBasicInfo = (type, value) => {
    setRestaurant({
      ...restaurant,
      register_basic_info: {
        ...restaurant.register_basic_info,
        [type]: value
      }
    });
  };
  const setRestaurantData = data => {
    setRestaurant({
      ...restaurant,
      ...data
    });
  };

  return {
    restaurant,
    setTerms,
    setBasicInfo,
    setRestaurantData
  };
};
export default useRegisterRestaurant;
