import { useContext } from 'react';
import { useSelector } from 'react-redux';
import SettingsContext from 'src/context/SettingsContext';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';

export default function useSettings() {
  const context = useContext(SettingsContext);

  return context;
}

export function useSettingsAppInfo() {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SETTINGS,
      doc: 'app_info',
      storeAs: 'settings_app_info'
    }
  ]);

  const appInfo = useSelector(state => state.firestore.data.settings_app_info);
  if (!appInfo) return null;

  return appInfo;
}
