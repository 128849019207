import React, { useEffect, useState } from 'react';
import { makeStyles, CircularProgress, Box } from '@material-ui/core';
import Page from 'src/components/Page';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from 'react-multi-lang/lib';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import Register from './Register';
import BasicInfo from './BasicInfo';
import ExecutiveRepresentative from './ExecutiveRepresentative';
// import RegisterThanks from './RegisterThanks';
import TermsAndConditions from './TermsAndConditions';
import useRegisterRestaurant from './hooks/RegisterRestaurant';
import useRegisterUser from './hooks/RegisterUser';
import RestaurantForm from './RestaurantForm';
import useRegisterSubsidiary from './hooks/RegisterSubsidiary';
import restauranService from '../../../services/registerRestauranService';
import NewPassword from './NewPassword';

const useStyles = makeStyles(theme => ({
  root: {
    // justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: 'calc(100% - 64px)',
    // minHeight: '100%',
    flexDirection: 'column'
    // paddingBottom: 80,
    // paddingTop: 80,
  }
}));

function RegisterView() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const translation = useTranslation();
  const [showTerms, setShowTerms] = useState(false);
  const [activeStep, setActiveStep] = useState(pathname === '/home' ? 0 : 1);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { restaurant, setTerms, setRestaurantData } = useRegisterRestaurant();
  const { user, updateUser } = useRegisterUser();
  const {
    subsidiaryNumber,
    setSubsidiaryNumber,
    subsidiaries,
    setSubsidiary
  } = useRegisterSubsidiary();

  const handleNext = () => {
    if (activeStep !== 5) setActiveStep(activeStep + 1);
  };

  const registerRestaurant = async () => {
    const subs = subsidiaries.map(s => s.subsidiary);
    try {
      const data = {
        user,
        restaurant,
        subsidiaries: subs
      };
      const response = await restauranService.registerRestaurant(data);
      if (response && response.restaurant_id)
        history.push(`/completeRegister/${response.restaurant_id}`);
      else {
        const message = translation('register_restaurant.messageConfirmation');
        enqueueSnackbar(message, {
          variant: 'success'
        });
        setActiveStep(0);
      }
    } catch (e) {
      let message = translation('register_restaurant.trouble');
      if (e) message = e;
      if (e === translation('register_restaurant.owner')) {
        message = translation('register_restaurant.user');
      }
      setActiveStep(0);
      setError(message);
    }
  };
  useEffect(() => {
    if (activeStep === 5) registerRestaurant();
    // eslint-disable-next-line
  }, [activeStep]);

  return (
    <>
      <Page
        className={classes.root}
        title={translation('register_restaurant.title')}
      >
        {activeStep === 0 && (
          <Register
            openTerms={() => {
              history.push('/terms-and-conditions');
            }}
            handleNext={handleNext}
            terms={restaurant.accepted_terms}
            setTerms={term => setTerms(term)}
          />
        )}
        {activeStep === 1 && (
          <ExecutiveRepresentative
            handleNext={handleNext}
            updateRestaurant={setRestaurantData}
            handleBack={() => history.push('/home')}
          />
        )}
        {activeStep === 2 && (
          <BasicInfo
            handleNext={handleNext}
            updateUser={updateUser}
            setRestaurantData={setRestaurantData}
            handleBack={() => setActiveStep(1)}
          />
        )}
        {activeStep === 3 && (
          <NewPassword
            email={user.email}
            handleNext={handleNext}
            updateUser={updateUser}
            handleBack={() => setActiveStep(2)}
          />
        )}
        {activeStep === 4 && (
          <RestaurantForm
            subsidiaryNumber={subsidiaryNumber}
            setSubsidiaryNumber={setSubsidiaryNumber}
            subsidiaries={subsidiaries}
            setSubsidiary={setSubsidiary}
            setRestaurantData={setRestaurantData}
            handleNext={handleNext}
            handleBack={() => setActiveStep(3)}
          />
        )}
        {activeStep === 5 && (
          <Box display="flex" justifyContent="center" my={15}>
            <CircularProgress />
          </Box>
          // <RegisterThanks handleNext={handleNext}/>
        )}
      </Page>
      <TermsAndConditions
        showTerms={showTerms}
        setShowTerms={setShowTerms}
        agreeTerms={() => {
          setShowTerms(false);
          setTerms(true);
        }}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

export default RegisterView;
