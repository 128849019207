import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  MenuItem,
  makeStyles,
  Select,
  useMediaQuery
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import moment from 'moment';
import { useTranslation } from 'react-multi-lang';

const timeRanges = [
  {
    value: 'today',
    text: 'Today'
  },
  {
    value: 'yesterday',
    text: 'Yesterday'
  },
  {
    value: 'last_7_days',
    text: 'Last 7 days'
  },
  {
    value: 'last_30_days',
    text: 'Last 30 days'
  },
  {
    value: 'last_year',
    text: 'Last year'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 10,
    marginTop: 20
  },
  container: {
    marginBottom: 10
  },
  select: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function getDateRanges(timerange) {
  const today = moment();
  switch (timerange) {
    case 'Last 30 days':
      return {
        start: moment(today)
          .subtract(30, 'days')
          .toDate(),
        compare: moment(today)
          .subtract(60, 'days')
          .toDate(),
        end: today.toDate()
      };
    case 'Last 7 days':
      return {
        start: moment(today)
          .subtract(7, 'days')
          .toDate(),
        compare: moment(today)
          .subtract(14, 'days')
          .toDate(),
        end: today.toDate()
      };
    case 'Today':
      return {
        start: moment(today)
          .subtract(1, 'days')
          .endOf('day')
          .toDate(),
        compare: moment(today)
          .subtract(2, 'days')
          .toDate(),
        end: today.toDate()
      };
    case 'Yesterday':
      return {
        start: moment(today)
          .subtract(2, 'days')
          .endOf('day')
          .toDate(),
        compare: moment(today)
          .subtract(3, 'days')
          .toDate(),
        end: moment(today)
          .subtract(1, 'days')
          .endOf('day')
          .toDate()
      };
    case 'Last year':
      return {
        start: moment(today)
          .subtract(1, 'year')
          .toDate(),
        compare: moment(today)
          .subtract(2, 'years')
          .toDate(),
        end: moment(today).toDate()
      };
    default:
      return {
        start: moment(today)
          .subtract(7, 'days')
          .toDate(),
        compare: moment(today)
          .subtract(14, 'days')
          .toDate(),
        end: today.toDate()
      };
  }
}

function Header({ className, setTimeRange = () => {}, ...rest }) {
  const classes = useStyles();
  // const translation = useTranslation();

  const isMobile = useMediaQuery('(max-width:480px)');

  const dates = getDateRanges(rest.timerange);

  const translation = useTranslation();

  return (
    <Grid
      container
      spacing={1}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid
        container
        alignItems="center"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={isMobile ? 12 : 6} sm={3}>
          <Select
            value={rest.timerange}
            variant="outlined"
            onChange={ev => setTimeRange(ev.target.value)}
            className={classes.select}
          >
            {timeRanges.map(t => (
              <MenuItem value={t.text} key={t.value}>
                {translation(`dashboard.${t.text}`)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={3}
        className={classes.container}
      >
        <Grid item xs={isMobile ? 12 : 6} sm={3}>
          <TextField
            name="datestart"
            variant="outlined"
            value={moment(dates.start).format('DD/MM/YYYY')}
            disabled
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6} sm={3}>
          <TextField
            name="dateend"
            variant="outlined"
            value={moment(dates.end).format('DD/MM/YYYY')}
            disabled
            className={classes.textField}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  setTimeRange: PropTypes.func
};

export default Header;
