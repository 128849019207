import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import SectionCard from './SectionCard';
import {
  saveMenuSections,
  saveMenuSectionsInSubsidiaries
} from '../../services/menuSectionService';
import { useMenuNative } from '../../hooks/useMenu';
import DndList from '../../components/DragAndDrop/DndList';
import { logMenuSection } from '../../services/logServices';
import { LOG_ACTIONS } from '../../dto/enum';

const useStyles = makeStyles(theme => ({
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));

const AddSecction = props => {
  const {
    newSectionId,
    setNoLongerNew,
    id,
    collection,
    isEditable,
    setLoading,
    blockCashier = false,
    restaurant
  } = props;
  const classes = useStyles();
  const subsidiaryMenu = useMenuNative(id, collection);
  const { SUPER_ADMIN, CASHIER, OWNER, SUPERVISOR } = useSelector(
    state => state.account.user.roles
  );
  const [menuList, setMenuList] = useState([]);
  const [shouldReload, setShouldReload] = useState(false);
  const [isLoadingMenu, setLoadingMenu] = useState(false);

  const enableCashier = useMemo(() => {
    return OWNER || SUPERVISOR || (CASHIER && restaurant?.enable_menu_cashier);
  }, [CASHIER, restaurant, OWNER, SUPERVISOR]);

  if (
    subsidiaryMenu &&
    !menuList?.length &&
    subsidiaryMenu.menu_section &&
    subsidiaryMenu.menu_section?.length
  ) {
    setMenuList(subsidiaryMenu.menu_section);
  }

  useEffect(() => {
    setLoadingMenu(true);
    if (subsidiaryMenu && subsidiaryMenu.menu_section)
      setMenuList(subsidiaryMenu.menu_section);
    setLoadingMenu(false);
  }, [id, subsidiaryMenu]);

  useEffect(() => {
    if (
      subsidiaryMenu &&
      menuList &&
      subsidiaryMenu.menu_section?.length !== menuList?.length
    )
      setMenuList(subsidiaryMenu.menu_section);
    // eslint-disable-next-line
  }, [subsidiaryMenu, menuList?.length])

  useEffect(() => {
    if (shouldReload) {
      const newMenuList = subsidiaryMenu.menu_section;
      setMenuList(newMenuList);
      setShouldReload(false);
    }
  }, [shouldReload, subsidiaryMenu]);

  const arrayToObject = array => {
    const newObject = {};
    array.forEach((a, index) => {
      newObject[index] = array[index].name;
    });
    return newObject;
  };

  const onDrop = async nextState => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const [restaurantResult, subsidiariesResult] = await Promise.all([
      saveMenuSections(subsidiaryMenu.id, nextState, collection),
      saveMenuSectionsInSubsidiaries(subsidiaryMenu.id, nextState)
    ]);

    const data = {
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      }
    };
    const [edit] = nextState.filter(
      (section, index) => section !== menuList[index]
    );
    if (edit && isEditable) {
      logMenuSection(
        { before: arrayToObject(menuList), after: arrayToObject(nextState) },
        LOG_ACTIONS.ORDER,
        data
      );
    }
    setMenuList(nextState);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <Box className={classes.wrapper}>
      {isLoadingMenu && (
        <Box className={classes.loadingWrapper}>
          <CircularProgress />
        </Box>
      )}
      <DndList
        items={menuList}
        renderItem={(item, index) => (
          <SectionCard
            section={item}
            position={index}
            menuList={menuList}
            sectionAmount={subsidiaryMenu.menu_section?.length}
            isNew={newSectionId === item.id}
            setNoLongerNew={setNoLongerNew}
            isAdmin={SUPER_ADMIN}
            setShouldReload={setShouldReload}
            isEditable={isEditable}
            blockCashier={blockCashier}
            enableMenuCashier={enableCashier}
          />
        )}
        dndType="PRODUCT_ITEM"
        keyGenerator={item => item.id}
        onDrop={onDrop}
        menu
      />
    </Box>
  );
};

export default AddSecction;
