/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  Divider,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Search as SearchIcon, CheckCircle } from 'react-feather';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { FcCustomerSupport } from 'react-icons/fc';
import { getOrderNumber } from '../../../hooks/Order/useOrders';
import { getDate } from '../../../utils/date';
import Orderstatus from '../OrderDetailsView/Orderstatus';
import ProgressCircleTimer from '../OrderDetailsView/ProgressCircleTimer';
import { roundTwoDecimals } from '../../../services/orderServices';
import { ORDER_STATUS, ORDER_TYPE } from '../../../dto/enum';
import { useResultsStyles } from './styles';
import Timer from '../../admin/order/OrderListView/Timer';
import env from '../../../env';

function applyPagination(orders, page, limit) {
  return orders.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
  let A = '';
  let B = '';
  if (
    orderBy === 'restaurant_name' ||
    orderBy === 'subsidiary_address' ||
    orderBy === 'invoice_name' ||
    orderBy === 'canceled_reason'
  ) {
    if (a[orderBy]) A = a[orderBy].toUpperCase();
    if (b[orderBy]) B = b[orderBy].toUpperCase();
  } else {
    A = a[orderBy];
    B = b[orderBy];
  }

  if (B < A) {
    return -1;
  }

  if (B > A) {
    return 1;
  }

  return 0;
}
function applyFilters(orders, query, filters) {
  return orders.filter(order => {
    let matches = true;

    if (query) {
      const properties = [
        'order_code',
        'ticket',
        'invoice_name',
        'restaurant_name'
      ];
      let containsQuery = false;

      properties.forEach(property => {
        if (
          order[property] &&
          typeof order[property] === 'string' &&
          order[property].toLowerCase().includes(query.toLowerCase())
        ) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value && order[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySort(orders, sort) {
  const { orderBy, order } = sort;
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = orders.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function Results({
  className,
  orders,
  showDetails = false,
  showTitle = false,
  showType = false,
  search = false,
  showMethod = false,
  showName = false,
  showRestaurant = false,
  showDate = false,
  showHour = false,
  showProductsNumber = false,
  showCreatedHour = false,
  showStatus = false,
  showTotal = false,
  showProgress = false,
  showTicket = false,
  showCanceledReason = false,
  showSubsidiaryAddress = false,
  showUserPhone = false,
  showVerified = false,
  showDriver = false,
  showTimer = false,
  showSupport = false,
  link = '/app/order',
  params = {},
  ...rest
}) {
  const classes = useResultsStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const theme = useTheme();
  const sortOptions = [
    {
      orderBy: 'created_at',
      order: 'desc'
    }
  ];
  const [sort, setSort] = useState(sortOptions[0]);
  const [filters] = useState({
    status: null
  });
  const translation = useTranslation();
  const [dangerProgress, setDangerProgress] = useState({});

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };
  const handleSortChange = value => {
    const order = {
      orderBy: value,
      order: sort.order === 'asc' ? 'desc' : 'asc'
    };
    if (value !== sort.orderBy) {
      order.order = sort.order;
    }
    setSort(order);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const filteredOrders = applyFilters(orders, query, filters);
  const sortedOrders = applySort(filteredOrders, sort);
  const paginatedOrders = applyPagination(sortedOrders, page, limit);

  useEffect(() => {
    setQuery('');
  }, [params.status]);

  const sendWhatsApp = phone => {
    window.open(
      `${env.REACT_APP_WHATSAPP}${phone}?text=XPRESS atención al cliente`,
      '_blank'
    );
  };

  const getPhone = phone => {
    if (phone[0] === '+' && phone[1] === '5') return phone.substr(4);
    if (phone[0] === '+' && phone[1] === '1') return phone.substr(2);
    return phone;
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {showDetails && (
        <Typography color="textSecondary" gutterBottom variant="body2">
          {`${orders.length} ${translation('orders.list.result.orders')}
        ${page + 1} ${translation('orders.list.result.of')} 
        ${Math.ceil(orders.length / limit)}`}
        </Typography>
      )}
      <Card>
        {showTitle && (
          <>
            <CardHeader title={translation('orders.details.header.order')} />
            <Divider />
          </>
        )}

        {search && (
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder={translation('personal.search')}
              value={query}
              variant="outlined"
            />
            <Box flexGrow={1} />
          </Box>
        )}

        <PerfectScrollbar>
          <Box minWidth={1150}>
            <Table
              className={clsx({
                [classes.ordersTable]: true,
                [classes.tableExpress]: true
              })}
            >
              <TableHead>
                <TableRow>
                  {showTimer && <TableCell align="center" />}
                  {showSupport && <TableCell align="center" />}
                  <TableCell size="small" align="center">
                    <TableSortLabel
                      active={sort.orderBy === 'order_code'}
                      direction={sort.order}
                      onClick={() => handleSortChange('order_code')}
                    >
                      <div style={{ minWidth: 78 }}>
                        <b>{translation('orders.list.table.number_order')}</b>
                      </div>
                    </TableSortLabel>
                  </TableCell>
                  {showTicket && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'ticket'}
                        direction={sort.order}
                        onClick={() => handleSortChange('ticket')}
                      >
                        <b>{translation('orders.list.table.ticket')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showType && (
                    <TableCell size="small" align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'order_type'}
                        direction={sort.order}
                        onClick={() => handleSortChange('order_type')}
                      >
                        <b>{translation('orders.list.table.type')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showName && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'invoice_name'}
                        direction={sort.order}
                        onClick={() => handleSortChange('invoice_name')}
                      >
                        <b>
                          {translation(
                            'basic_information.personal_info.name.name'
                          )}
                        </b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showRestaurant && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'restaurant_name'}
                        direction={sort.order}
                        onClick={() => handleSortChange('restaurant_name')}
                      >
                        <b>{translation('orders.list.table.restaurant')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showSubsidiaryAddress && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'subsidiary_address'}
                        direction={sort.order}
                        onClick={() => handleSortChange('subsidiary_address')}
                      >
                        <b>
                          {translation('orders.list.table.subsidiary_address')}
                        </b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showUserPhone && (
                    <TableCell size="small" align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'user_phone'}
                        direction={sort.order}
                        onClick={() => handleSortChange('user_phone')}
                      >
                        <b>{translation('orders.list.table.user_phone')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showDate && (
                    <TableCell size="small" align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'delivery_time'}
                        direction={sort.order}
                        onClick={() => handleSortChange('delivery_time')}
                      >
                        <div style={{ minWidth: 78 }}>
                          <b>
                            {translation('admin_subsidiary.common_table.date')}
                          </b>
                        </div>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showCreatedHour && (
                    <TableCell size="small" align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'created_at'}
                        direction={sort.order}
                        onClick={() => handleSortChange('created_at')}
                      >
                        <b>{translation('orders.list.table.time_created')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}

                  {showHour && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'delivery_time'}
                        direction={sort.order}
                        onClick={() => handleSortChange('delivery_time')}
                      >
                        <b>{translation('orders.list.table.time')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showProductsNumber && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'products_available_number'}
                        direction={sort.order}
                        onClick={() =>
                          handleSortChange('products_available_number')
                        }
                      >
                        <b>
                          {translation('orders.list.table.products_number')}
                        </b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showStatus && (
                    <TableCell size="small" align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'status'}
                        direction={sort.order}
                        onClick={() => handleSortChange('status')}
                      >
                        <b>{translation('orders.list.table.state')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}

                  {showCanceledReason && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'canceled_reason'}
                        direction={sort.order}
                        onClick={() => handleSortChange('canceled_reason')}
                      >
                        <b>
                          {translation('orders.list.table.canceled_reason')}
                        </b>
                      </TableSortLabel>
                    </TableCell>
                  )}

                  {showMethod && (
                    <TableCell align="center">
                      <b>{translation('orders.list.table.method')}</b>
                    </TableCell>
                  )}

                  {showTotal && (
                    <TableCell align="center">
                      <TableSortLabel
                        active={sort.orderBy === 'total'}
                        direction={sort.order}
                        onClick={() => handleSortChange('total')}
                      >
                        <b>{translation('orders.list.table.sales')}</b>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {showProgress && (
                    <TableCell align="center">
                      <b>
                        {translation('orders.list.process_orders.time_remain')}
                      </b>
                    </TableCell>
                  )}
                  {showDriver && (
                    <TableCell align="center">
                      <b>{translation('orders.list.driver')}</b>
                    </TableCell>
                  )}
                  {showVerified && (
                    <TableCell size="small" align="center">
                      <b>{translation('orders.list.verified')}</b>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedOrders.map(order => {
                  return (
                    <TableRow
                      onClick={e => {
                        if (e.target.id !== 'whatsappIcon')
                          history.push(`${link}/${order.id}`);
                      }}
                      key={order.id}
                      className={clsx({
                        [classes.rowSelected]:
                          order.status === ORDER_STATUS.IN_PROCESS &&
                          !order.verified,
                        [classes.rowDanger]: dangerProgress[order.id]
                      })}
                    >
                      {showTimer && (
                        <TableCell size="small" align="center">
                          <Grid container justify="center">
                            {order.status !== ORDER_STATUS.COMPLETED &&
                              order.status !== ORDER_STATUS.CANCELED && (
                                <Timer orderCreated={order.created_at} />
                              )}
                          </Grid>
                        </TableCell>
                      )}
                      {showSupport && (
                        <TableCell size="small" align="center">
                          <Grid container justify="center">
                            {Object.prototype.hasOwnProperty.call(
                              order,
                              'assigned'
                            ) === true &&
                              order.status !== ORDER_STATUS.COMPLETED &&
                              order.status !== ORDER_STATUS.CANCELED && (
                                <FcCustomerSupport size={22} />
                              )}
                          </Grid>
                        </TableCell>
                      )}
                      <TableCell align="center">
                        <div className={classes.tableCell}>
                          <Typography variant="button" gutterBottom>
                            {getOrderNumber(order)
                              ? getOrderNumber(order)
                              : translation(
                                  'orders.list.process_orders.processing'
                                )}
                          </Typography>
                        </div>
                      </TableCell>
                      {showTicket && (
                        <TableCell className={classes.gray} align="center">
                          <div className={classes.tableCell}>
                            <Typography variant="button" gutterBottom>
                              {order.ticket}
                            </Typography>
                          </div>
                        </TableCell>
                      )}
                      {showType && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            <Typography variant="button" gutterBottom>
                              {order.order_type === ORDER_TYPE.DELIVERY
                                ? 'DELIVERY'
                                : 'PICK UP'}
                            </Typography>
                          </div>
                        </TableCell>
                      )}
                      {showName && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {order.invoice_name}
                          </div>
                        </TableCell>
                      )}
                      {showRestaurant && (
                        <TableCell size="small" align="center">
                          <div className={classes.tableCell}>
                            {order.restaurant_name && order.subsidiary_name ? (
                              <div>
                                <Typography variant="button" gutterBottom>
                                  {order.restaurant_name}
                                </Typography>
                                <Typography variant="button" gutterBottom>
                                  {' '}
                                </Typography>
                                <Typography variant="caption">
                                  {`(${order.subsidiary_name})`}
                                </Typography>
                              </div>
                            ) : (
                              ' '
                            )}
                          </div>
                        </TableCell>
                      )}
                      {showSubsidiaryAddress && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {order.subsidiary_address
                              ? order.subsidiary_address
                              : ' '}
                          </div>
                        </TableCell>
                      )}
                      {showUserPhone && (
                        <TableCell size="small" align="center">
                          <div className={classes.tableCell}>
                            <Typography
                              variant="button"
                              className={classes.phoneText}
                            >
                              {order.user_phone
                                ? getPhone(order.user_phone)
                                : 0}
                            </Typography>
                            <WhatsAppIcon
                              onClick={() => sendWhatsApp(order.user_phone)}
                              className={classes.whatappIcon}
                              id="whatsappIcon"
                            />
                          </div>
                        </TableCell>
                      )}
                      {showDate && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            <Typography variant="button" gutterBottom>
                              {moment(order.delivery_time.toDate()).format(
                                'DD/MM/YY HH:mm'
                              )}
                            </Typography>
                          </div>
                        </TableCell>
                      )}
                      {showCreatedHour && (
                        <TableCell className={classes.gray} align="center">
                          <div className={classes.tableCell}>
                            {moment(getDate(order.created_at)).format(
                              'DD/MM/YY HH:mm'
                            )}
                          </div>
                        </TableCell>
                      )}
                      {showHour && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {moment(getDate(order.delivery_time)).format(
                              'DD/MM/YY HH:mm'
                            )}
                          </div>
                        </TableCell>
                      )}
                      {showProductsNumber && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {order.products_available_number
                              ? order.products_available_number
                              : 0}
                          </div>
                        </TableCell>
                      )}
                      {showStatus && (
                        <TableCell
                          size="small"
                          align="center"
                          style={{ maxWidth: 150 }}
                        >
                          <div className={classes.tableCell}>
                            <Orderstatus orderStatus={order.status} />
                          </div>
                        </TableCell>
                      )}
                      {showCanceledReason && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {order.canceled_reason
                              ? order.canceled_reason
                              : ' '}
                          </div>
                        </TableCell>
                      )}
                      {showMethod && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {order.payment_method}
                          </div>
                        </TableCell>
                      )}
                      {showTotal && (
                        <TableCell className={classes.gray} align="center">
                          <div className={classes.tableCell}>
                            Bs.
                            {order.total && roundTwoDecimals(order.total)}
                          </div>
                        </TableCell>
                      )}
                      {showProgress && (
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {!order?.order_with_destination && (
                              <ProgressCircleTimer
                                startDate={order?.accepted_time}
                                finishDate={order?.delivery_time}
                                setDangerToParent={danger => {
                                  setDangerProgress({
                                    ...dangerProgress,
                                    [order.id]: danger
                                  });
                                }}
                                showMinutes
                              />
                            )}
                          </div>
                        </TableCell>
                      )}
                      {showDriver && (
                        <TableCell align="center">
                          {order.order_type === ORDER_TYPE.DELIVERY && (
                            <div className={classes.tableCell}>
                              <Typography variant="button" gutterBottom>
                                {order.driver &&
                                order.driver.first_name &&
                                order.driver.last_name
                                  ? `${order.driver.first_name} ${
                                      order.driver.last_name.length > 0
                                        ? order.driver.last_name[0]
                                        : '-'
                                    }.`
                                  : ' '}
                              </Typography>
                            </div>
                          )}
                          {order.order_type === ORDER_TYPE.PICK_UP && (
                            <div className={classes.tableCell}>-</div>
                          )}
                        </TableCell>
                      )}

                      {showVerified && (
                        <TableCell size="small" align="center">
                          <Grid container justify="center">
                            {order.verified && (
                              <CheckCircle
                                style={{
                                  strokeWidth: 2.5,
                                  strokeLinecap: 'round',
                                  color: theme.palette.expressColors.lightBlue
                                }}
                                size={24}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {orders.length > 5 && (
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            labelRowsPerPage={translation('orders.list.table.orders_page')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${
                count !== -1 ? count : `mas de ${to}`
              }`
            }
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </Card>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array
};

Results.defaultProps = {
  orders: []
};

export default Results;
