import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Commerces = () => {
  const history = useHistory();

  const goTo = dir => {
    history.push(`/admin/commerce/${dir}`);
  };

  return (
    <Grid container>
      <Box m={3}>
        <Card>
          <CardHeader title="Comercios Pendientes" />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  cantidad: 4
                </Typography>
              </Grid>
              <Grid>
                <Button color="secondary" onClick={() => goTo('pending')}>
                  Ver lista de restaurantes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title="Comercios Activos" />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  cantidad: 4
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('active')}>
                  Ver lista de restaurantes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title="Comercios Rechazados" />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  cantidad: 4
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('rejected')}>
                  Ver lista de restaurantes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title="Comercios Suspendidos" />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  cantidad: 4
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('suspended')}>
                  Ver lista de restaurantes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box m={3}>
        <Card>
          <CardHeader title="Comercios Archivados" />
          <Divider />
          <CardContent>
            <Grid item container direction="column">
              <Grid>
                <Typography variant="body1" style={{ paddingLeft: '8px' }}>
                  cantidad: 4
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={() => goTo('archives')}>
                  Ver lista de restaurantes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default Commerces;
