import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { COLLECTIONS } from '../../constants';
import { useCurrentSubsidiary } from '../useRestaurant';
import Subsidiary from '../../dto/subsidiary';
import firebase from '../../config/firebaseConfig';

export const useCurrentSubsidiaryFS = subsidiaryId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      doc: subsidiaryId,
      storeAs: subsidiaryId
        ? `current_subsidiary_${subsidiaryId}`
        : 'all_subsidiaries'
    }
  ]);
  const currentSubsidiary = useSelector(
    state => state.firestore.ordered[`current_subsidiary_${subsidiaryId}`]
  );

  const temporarySubsidiary = useCurrentSubsidiary();
  if (currentSubsidiary && currentSubsidiary) return currentSubsidiary[0];

  return temporarySubsidiary;
};

export const useSubsidiaryIdRealTime = id => {
  const [subsidiary, setSubsidiary] = useState(null);
  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
     firebase
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(id)
        .onSnapshot(snap => {
          const data = new Subsidiary({ ...snap.data(), id: snap.id });
          setSubsidiary(data);
        });
    }
  }, [id]);
  return subsidiary;
};

export const useAllSubsidiaries = restaurantId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      where: ['restaurant_id', '==', restaurantId],
      storeAs: `all_subsidiaries`
    }
  ]);
  const allSubsidiaries = useSelector(
    state => state.firestore.ordered.all_subsidiaries
  );

  return allSubsidiaries;
};

export const useSubsidiaryId = subsidiaryId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.SUBSIDIARY,
      doc: subsidiaryId,
      storeAs: `subsidiary_${subsidiaryId}`
    }
  ]);
  const subsidiary = useSelector(
    state => state.firestore.ordered[`subsidiary_${subsidiaryId}`]
  );

  return subsidiary ? new Subsidiary(subsidiary[0]) : false;
};
