import React, { useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import { useTranslation } from 'react-multi-lang/lib';
import SubsidiaryForm from './forms/SubsidiaryForm';
import DocumentsTable from './DocumentsTable';
import { editSubsidiary } from '../../../services/subsidiaryService';
import { COLLECTIONS, registerStatus } from '../../../constants';

const Subsidiaries = ({
  restaurantSubsidiaries,
  allCities,
  clearView,
  setClearView
}) => {
  const [currentSubsidiary, setCurrentSubsidiary] = useState(null);
  const translation = useTranslation();
  const firestore = useFirestore();

  const approvedSubsidiaries = async pending => {
    await firestore
      .collection(COLLECTIONS.RESTAURANT)
      .doc(currentSubsidiary.restaurant_id)
      .set({ pendings: { subsidiaries: pending } }, { merge: true });
  };

  const approveSub = async () => {
    await editSubsidiary(currentSubsidiary.id, {
      register_status: registerStatus.ACTIVE
    });
    setCurrentSubsidiary({
      ...currentSubsidiary,
      register_status: registerStatus.ACTIVE
    });

    const subsidiaryPending = restaurantSubsidiaries.filter(
      item => item.register_status === registerStatus.PENDING
    ).length;
    if (subsidiaryPending - 1 === 0) {
      approvedSubsidiaries(false);
    }
  };

  const pendingSub = async () => {
    await editSubsidiary(currentSubsidiary.id, {
      register_status: registerStatus.PENDING
    });
    setCurrentSubsidiary({
      ...currentSubsidiary,
      register_status: registerStatus.PENDING
    });
    approvedSubsidiaries(true);
  };

  useEffect(() => {
    if (clearView) {
      setCurrentSubsidiary(null);
      setClearView(false);
    }
  }, [clearView, setClearView]);

  const subsidiaryCard = subsidiary => {
    return (
      <Grid key={subsidiary.id} item container xs={6} md={3}>
        <Box width="100%" my={3} mx={3}>
          <Card>
            <CardHeader
              title={`Estado: ${
                subsidiary.register_status
                  ? subsidiary.register_status
                  : 'Pending'
              }`}
            />
            <Divider />
            <CardContent>
              <Typography variant="h3" color="textPrimary">
                {subsidiary.name}
              </Typography>
              <Typography variant="h1" color="textPrimary">
                {subsidiary.address}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCurrentSubsidiary(subsidiary);
                }}
              >
                Ver Sucursal
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid item container>
      {!currentSubsidiary && (
        <Box width="100%">
          <Grid item container xs={12} md={12}>
            {restaurantSubsidiaries.map(subsidiary =>
              subsidiaryCard(subsidiary)
            )}
          </Grid>
        </Box>
      )}
      {currentSubsidiary && (
        <>
          <Box m={3} style={{ width: '100%' }}>
            <Card>
              <Grid item container display="row" justify="space-between">
                <CardHeader title={currentSubsidiary.name} />
                <Grid
                  md={6}
                  xs={6}
                  item
                  container
                  display="row"
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box mr={3}>
                    <Button
                      onClick={approveSub}
                      color="secondary"
                      variant={
                        currentSubsidiary.register_status ===
                        registerStatus.ACTIVE
                          ? 'contained'
                          : 'outlined'
                      }
                    >
                      {translation('admin_common.approve')}
                    </Button>
                  </Box>
                  <Box mr={3}>
                    <Button
                      onClick={pendingSub}
                      color="secondary"
                      variant={
                        currentSubsidiary.register_status ===
                        registerStatus.PENDING
                          ? 'contained'
                          : 'outlined'
                      }
                    >
                      {translation('admin_common.pending')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <CardContent>
                <SubsidiaryForm
                  id={currentSubsidiary.id}
                  allCities={allCities}
                />
                <DocumentsTable id={currentSubsidiary.id} type="subsidiary" />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default Subsidiaries;
