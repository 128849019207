import React from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import OrderPrinting from '../../OrderPrinting/OrderPrinting';
import PrintButton from '../PrintButton';
import { usePromotion } from '../../../../hooks/Promotions/usePromotions';
import { useNewOrderStyles } from './styles';

const NewOrderCockpit = props => {
  const { rejectOrderConfim, orderInProcess, order } = props;
  const classes = useNewOrderStyles();
  const translation = useTranslation();
  const promotion = usePromotion(order?.promotionId);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.buttonContainer}>
      <Button
        variant="contained"
        className={clsx(classes.button, classes.rejectButton)}
        style={{ marginRight: '15px' }}
        onClick={rejectOrderConfim}
      >
        <Typography className={classes.btnText}>
          {translation('orders.details.new_order.reject')}
        </Typography>
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={orderInProcess}
        className={clsx(classes.button, classes.acceptButton)}
      >
        <Typography className={classes.btnText}>
          {translation('orders.details.new_order.accept')}
        </Typography>
      </Button>

      {!isXS && (
        <Box>
          <OrderPrinting order={{ ...order, promotion }} />
          <Box onClick={orderInProcess}>
            <PrintButton
              text={translation('orders_view.cockpit.buttons.accept_print')}
              propsClasses={classes.button}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NewOrderCockpit;
