import { updateSchedule as updateScheduleService } from '../services/subsidiaryService';
/* eslint camelcase: 0 */
export const createPeriod = async (subsidiary_id, updatedSubsidiary) => {
  await updateScheduleService({
    subId: subsidiary_id,
    schedule: updatedSubsidiary.schedule
  });
};

export const updateSchedule = async (subsidiary_id, subsidiary) => {
  await updateScheduleService({
    subId: subsidiary_id,
    schedule: subsidiary.schedule
  });
};
