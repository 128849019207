import React from 'react';
import { Typography } from '@material-ui/core';

export default function CardHeader({
  title,
  containerStyle = {},
  titleStyle = {}
}) {
  return (
    <div
      style={{
        paddingTop: 27,
        paddingBottom: 27,
        paddingLeft: 32,
        ...containerStyle
      }}
    >
      <Typography
        variant="body2"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: 20, ...titleStyle }}
      >
        {title}
      </Typography>
    </div>
  );
}
