import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardHeader, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import Chart from './Chart';

const useStyles = makeStyles(theme => ({
  root: {},
  current: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  navigateNextIcon: {
    marginLeft: theme.spacing(1)
  }
}));

function OrdersRealTime({
  className,
  labels,
  orders = [],
  data = [],
  ...date
}) {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Card className={clsx(classes.root, className)} {...date}>
      <CardHeader
        classes={{ action: classes.current }}
        title={translation('dashboard.orders')}
        subheader={orders.length}
        titleTypographyProps={{ color: 'textPrimary' }}
        subheaderTypographyProps={{ color: 'textPrimary' }}
      />
      <Chart data={data} labels={labels} />

      {/* <Box p={2} display="flex" justifyContent="flex-end">
        <Button component={RouterLink} size="small" to="#">
          See all
          <NavigateNextIcon className={classes.navigateNextIcon} />
        </Button>
      </Box> */}
    </Card>
  );
}

OrdersRealTime.propTypes = {
  className: PropTypes.string
};

export default OrdersRealTime;
