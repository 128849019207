import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const useCampaingsImages = () => {
  useFirestoreConnect({
    collection: COLLECTIONS.CAMPAINGS_IMAGE,
    storeAs: COLLECTIONS.CAMPAINGS_IMAGE
  });
  const images = useSelector(
    state => state.firestore.ordered[COLLECTIONS.CAMPAINGS_IMAGE]
  );
  if (images) return images;
  return [];
};
