import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  divider: {
    height: 4,
    width: '5%'
  },
  progressBar: {
    width: '100%',
    height: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    overflow: 'hidden'
  },
  level: {
    height: '100%',
    float: 'left'
  },
  defaultColor: {
    color: '#f0f0f0'
  },
  selectLevel: {
    fontWeight: 'bold'
  }
}));

const CardLevelBar = ({ className, title, data, styleLevel }) => {
  const classes = useStyles();
  const { value } = data;
  const levels = ['VERY_SLOW', 'SLOW', 'NORMAL', 'FAST', 'VERY_FAST'];
  const levelsEsp = ['Muy lento', 'Lento', 'Normal', 'Rápido', 'Muy rápido'];
  const levelStyle = levels.indexOf(value);

  const getActiveLevels = () => {
    const index = levels.indexOf(value);
    return levels.slice(0, index + 1);
  };

  return (
    <Card className={clsx(classes.root, className)} {...data}>
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box width="100%">
            <Box display="flex">
              {levelsEsp.map((levelEsp, index) => (
                <Box
                  width="20%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    className={
                      levelStyle === index
                        ? classes.selectLevel
                        : classes.defaultColor
                    }
                  >
                    {levelEsp}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.progressBar}>
              {levels.map((levelName, index) => (
                <Box
                  key={index}
                  className={`${classes.level} ${
                    getActiveLevels().includes(levelName) ? styleLevel : ''
                  }`}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

CardLevelBar.propTypes = {
  className: PropTypes.string
};

export default CardLevelBar;
