import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-multi-lang/lib';
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icons from '../assets/icons';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 30,
    fontWeight: 500,
    marginBottom: 32
  },
  iconCheck: {
    width: 30
  },
  textSubtitle: {
    fontSize: 26,
    fontWeight: 300,
    marginBottom: 16
  },
  textOption: {
    fontSize: 24,
    fontWeight: 300
  },
  iconDot: {
    width: 12,
    color: theme.palette.expressColors.strongYellow,
    marginRight: 21,
    paddingTop: 12
  },
  marginCheck: {
    marginRight: 100
  },
  divider: {
    marginTop: 48,
    marginBottom: 24
  },
  socialNetworks: {
    color: theme.palette.expressColors.green
  }
}));

const AdvertisingSocialNetworksForm = React.memo(
  ({
    isRegister = false,
    handleNext = () => {},
    saveRestaurantData = () => {},
    restaurant = false
  }) => {
    const classes = useStyles();
    const translation = useTranslation();
    return (
      <Formik
        initialValues={{
          sign: restaurant
            ? restaurant.advertising_social_networks.sign
            : false,
          stickers: restaurant
            ? restaurant.advertising_social_networks.stickers
            : false,
          url_facebook: restaurant
            ? restaurant.advertising_social_networks.url_facebook
            : '',
          url_instagram: restaurant
            ? restaurant.advertising_social_networks.url_instagram
            : '',
          url_other: restaurant
            ? restaurant.advertising_social_networks.url_other
            : ''
        }}
        validationSchema={Yup.object().shape({
          sign: Yup.boolean(),
          stickers: Yup.boolean(),
          url_facebook: Yup.string(),
          url_instagram: Yup.string(),
          url_other: Yup.string()
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const newValues = {
              advertising_social_networks: {
                ...values
              }
            };
            saveRestaurantData(newValues);
            if (isRegister) {
              handleNext();
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box pt={2} display="flex" justifyContent="center">
              <Typography className={classes.title}>
                {translation('advertising_social_networks.title')}
              </Typography>
            </Box>
            <Typography className={classes.textSubtitle}>
              {translation('advertising_social_networks.have')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box mb={1} ml={3} display="flex" alignItems="baseline">
                  <FiberManualRecordIcon className={classes.iconDot} />
                  <Typography className={classes.textOption}>
                    {translation('advertising_social_networks.sign')}
                  </Typography>
                </Box>
                <Box ml={8}>
                  <FormControlLabel
                    className={classes.marginCheck}
                    control={
                      <Checkbox
                        icon={
                          <img
                            className={classes.iconCheck}
                            src={Icons.checkBoxGreenBorder}
                            alt="icon"
                          />
                        }
                        checkedIcon={
                          <img
                            className={classes.iconCheck}
                            src={Icons.checkBoxGreen}
                            alt="icon"
                          />
                        }
                        checked={values.sign}
                        onChange={() => setFieldValue('sign', true)}
                        name="signTrue"
                      />
                    }
                    label={translation('advertising_social_networks.yes')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <img
                            className={classes.iconCheck}
                            src={Icons.checkBoxGreenBorder}
                            alt="icon"
                          />
                        }
                        checkedIcon={
                          <img
                            className={classes.iconCheck}
                            src={Icons.checkBoxGreen}
                            alt="icon"
                          />
                        }
                        checked={!values.sign}
                        onChange={() => setFieldValue('sign', false)}
                        name="signFalse"
                      />
                    }
                    label={translation('advertising_social_networks.no')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Box mb={1} ml={3} display="flex" alignItems="baseline">
                    <FiberManualRecordIcon className={classes.iconDot} />
                    <Typography className={classes.textOption}>
                      {translation('advertising_social_networks.stickers')}
                    </Typography>
                  </Box>
                  <Box ml={8}>
                    <FormControlLabel
                      className={classes.marginCheck}
                      control={
                        <Checkbox
                          icon={
                            <img
                              className={classes.iconCheck}
                              src={Icons.checkBoxGreenBorder}
                              alt="icon"
                            />
                          }
                          checkedIcon={
                            <img
                              className={classes.iconCheck}
                              src={Icons.checkBoxGreen}
                              alt="icon"
                            />
                          }
                          checked={values.stickers}
                          onChange={() => setFieldValue('stickers', true)}
                          name="stickersTrue"
                        />
                      }
                      label={translation('advertising_social_networks.yes')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={
                            <img
                              className={classes.iconCheck}
                              src={Icons.checkBoxGreenBorder}
                              alt="icon"
                            />
                          }
                          checkedIcon={
                            <img
                              className={classes.iconCheck}
                              src={Icons.checkBoxGreen}
                              alt="icon"
                            />
                          }
                          checked={!values.stickers}
                          onChange={() => setFieldValue('stickers', false)}
                          name="stickersFalse"
                        />
                      }
                      label={translation('advertising_social_networks.no')}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography className={classes.textSubtitle}>
              {translation('advertising_social_networks.social_networks')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={Boolean(touched.url_facebook && errors.url_facebook)}
                  fullWidth
                  helperText={touched.url_facebook && errors.url_facebook}
                  label={translation('advertising_social_networks.facebook')}
                  margin="normal"
                  name="url_facebook"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.url_facebook}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon className={classes.socialNetworks} />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={Boolean(touched.url_instagram && errors.url_instagram)}
                  fullWidth
                  helperText={touched.url_instagram && errors.url_instagram}
                  label={translation('advertising_social_networks.instagram')}
                  margin="normal"
                  name="url_instagram"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.url_instagram}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon className={classes.socialNetworks} />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={Boolean(touched.url_other && errors.url_other)}
                  fullWidth
                  helperText={touched.url_other && errors.url_other}
                  label={translation('advertising_social_networks.other')}
                  margin="normal"
                  name="url_other"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.url_other}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                color="secondary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                {isRegister
                  ? translation('advertising_social_networks.register')
                  : translation('save')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    );
  }
);

export default AdvertisingSocialNetworksForm;
