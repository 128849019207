import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import axios from 'src/utils/axios';
import firebase from 'firebase/app';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import XpressModal from 'src/components/Modal';
import ImageSelector from 'src/components/ImageSelector';
import { editProduct, deleteImage } from '../../../../services/productsService';
import { editSharedProduct } from '../../../../services/sharedProductsService';
import 'firebase/storage';
import { COLORS } from '../../../../constants';
import YellowTextField from '../../../../components/Inputs/TextField';
import { GreenSwitch, RedSwitch } from '../../../../components/Inputs/Switch';
import ImageInputCrop from '../../../../components/Inputs/ImageInputCrop';
import { logProduct } from '../../../../services/logServices';
import { LOG_ACTIONS } from '../../../../dto/enum';
import { getObjectChanges } from '../../../../utils/object';
import env from '../../../../env';
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  header: {
    height: '80px',
    paddingLeft: '32px',
    paddingRight: '32px',
    flexWrap: 'nowrap'
  },
  formContainer: {
    padding: '30px'
  },
  formLeftSide: {
    flexDirection: 'column',
    paddingRight: '25px'
  },
  formDescriptionContainer: {
    height: '200px'
  },
  imageContainer: {
    width: '100%',
    position: 'relative'
  },
  imageButton: {
    position: 'absolute',
    top: '17px',
    left: '17px'
  },
  imageCard: {
    width: '100%',
    height: 'auto'
  },
  cookingCard: {
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  cookingCardBox: {
    height: '80px',
    borderBottom: `1px solid ${COLORS.disabledGray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  prepackedTitle: {
    flexDirection: 'column',
    alignItems: 'baseline',
    alignContent: 'center'
  },
  removeButton: {
    backgroundColor: COLORS.red,
    color: theme.palette.expressColors.white
  },
  button: {
    marginRight: 20
  }
}));

const CreateForm = props => {
  const { blockCashier, currentProduct, section, enableMenuCashier } = props;
  const ref = useRef();
  const classes = useStyles();
  const { restId, subId } = useParams();
  const [restaurant] = useRestaurantAndSubsidiaryforMenu(restId, subId);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState('');
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isTemplate = useIsTemplateMenu();
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageFull, setSelectedImageFull] = useState('');
  const [fileValid, setFileValid] = useState({ error: false });
  const [loading, setLoading] = useState(false);
  const [fullImage, setFullImage] = useState(null);
  const [galleryId, setGalleryId] = useState('');
  const [imageActions, setImageActions] = useState({
    isDeleted: false,
    isUpdated: false
  });

  // eslint-disable-next-line
  const saveImage = (url, img) => {
    setFile(url);
    setImage(img);
  };

  const uploadFile = async () => {
    const storageRef = firebase.storage().ref(`product/${currentProduct.id}`);
    await storageRef.put(image);
    return storageRef.getDownloadURL();
  };
  const disableProduct = async () => {
    if (isTemplate) {
      await editSharedProduct({ enable: false }, currentProduct.id);
      const changes = getObjectChanges(
        {
          ...currentProduct,
          photo: currentProduct.photo.original,
          image: undefined
        },
        null
      );
      const data = {
        product: {
          id: currentProduct.id,
          name: currentProduct.name
        },
        restaurant: {
          id: restaurant.id,
          name: restaurant.name
        }
      };
      await logProduct(changes, LOG_ACTIONS.DELETE, data);
    } else {
      await editProduct({ enable: false }, currentProduct.id);
    }

    window.history.back();
  };

  const downloadImage = url => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      const imageData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const hash = (Math.random() + 1).toString(36).substring(7);
      link.href = imageData;
      link.setAttribute('download', `${currentProduct.name} ${hash}.jpg`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const openFullImage = () => {
    setFullImage(
      <XpressModal>
        <ImageSelector
          restaurantId={restaurant.id}
          handleConfirm={async selected => {
            setGalleryId(selected.id);
            setSelectedImage(selected?.image?.original);
            setSelectedImageFull(selected?.image);
            setFullImage(null);
            setImageActions({
              isDeleted: false,
              isUpdated: true
            });
            ref.current.setFieldValue('image', true);
          }}
          handleClose={() => {
            setFullImage(null);
          }}
        />
      </XpressModal>
    );
  };

  return (
    <>
      {!currentProduct && (
        <Card>
          <CardHeader title={translation('edit.product_create.load')} />
          <Divider />
          <CardContent>
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      )}
      {fullImage && fullImage}
      {currentProduct && (
        <Formik
          innerRef={ref}
          enableReinitialize
          initialValues={{
            name: currentProduct.name,
            description: currentProduct.description,
            unit_price: parseFloat(
              parseFloat(
                currentProduct.unit_price === '' ? 0 : currentProduct.unit_price
              ).toFixed(2)
            ),
            cooking_time: currentProduct.cooking_time,
            pre_packed: currentProduct.pre_packed,
            availability: currentProduct.availability,
            image: ''
          }}
          validationSchema={Yup.object().shape({
            availability: Yup.boolean(),
            name: Yup.string().when('availability', {
              is: true,
              then: Yup.string().required(
                translation('forms.validation_message.required')
              ),
              otherwise: Yup.string()
            }),
            unit_price: Yup.number().when('availability', {
              is: true,
              then: Yup.number()
                .positive(translation('forms.validation_message.positive'))
                .nullable(true)
                .required(translation('forms.validation_message.required'))
                .typeError(translation('forms.validation_message.required'))
                .transform((_, val) => (val === Number(val) ? val : 0)),
              otherwise: Yup.number()
            })
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            if (
              values.availability &&
              (!file || currentProduct.photo?.original === '')
            )
              setFileValid({
                error: true,
                message: 'forms.validation_message.required'
              });
            if (!values.availability || !fileValid.error)
              try {
                const newValues = { ...values, gallery_id: galleryId };
                setLoading(true);
                let urlImage = '';
                if (imageActions.isUpdated) {
                  if (image) {
                    urlImage = await uploadFile();
                  }
                } else if (imageActions.isDeleted) {
                  await deleteImage(currentProduct.id);
                }
                if (selectedImageFull) {
                  newValues.photo = selectedImageFull || currentProduct?.photo;
                }

                if (!newValues.unit_price) {
                  newValues.unit_price = 0;
                }
                if (isTemplate) {
                  await editSharedProduct(newValues, currentProduct.id);
                  const changes = getObjectChanges(
                    {
                      ...currentProduct,
                      photo: currentProduct.photo.original,
                      image: undefined
                    },
                    { ...newValues, photo: urlImage, image: undefined }
                  );
                  const data = {
                    product: {
                      id: currentProduct.id,
                      name: currentProduct.name
                    },
                    restaurant: {
                      id: restaurant.id,
                      name: restaurant.name
                    }
                  };
                  await logProduct(changes, LOG_ACTIONS.UPDATE, data);
                } else await editProduct(newValues, currentProduct.id);

                resetForm();
                setFileValid({ error: false });
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(translation('notification.correctly_save'), {
                  variant: 'success'
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                if (error.status === 409) {
                  enqueueSnackbar(
                    translation('notification.something_go_wrong'),
                    {
                      variant: 'error'
                    }
                  );
                } else {
                  enqueueSnackbar(
                    `${translation('notification.something_go_wrong')} ${
                      env.REACT_APP_SUPPORT_MAIL
                    }`,
                    {
                      variant: 'error'
                    }
                  );
                }
              } finally {
                setLoading(false);
              }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            dirty
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Card>
                <Grid
                  item
                  container
                  display="row"
                  justify="space-between"
                  className={classes.header}
                >
                  <Grid item container alignContent="center">
                    <Typography variant="h4">
                      <Box fontWeight="bold">
                        {translation(
                          'edit.product_create.basic_information.head'
                        )}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid
                    md={3}
                    xs={3}
                    item
                    container
                    display="row"
                    alignItems="center"
                    justify="flex-end"
                    wrap="nowrap"
                  >
                    <Typography variant="body2" color="textSecondary">
                      {values.availability
                        ? translation('available')
                        : translation('not_available')}
                    </Typography>
                    {values.availability ? (
                      <GreenSwitch
                        disabled={blockCashier || !enableMenuCashier}
                        checked={values.availability}
                        onChange={e => {
                          setFieldValue('availability', e.target.checked);
                        }}
                        name="availability"
                        id="availability"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    ) : (
                      <RedSwitch
                        // disabled={
                        //   (!currentProduct.photo.original && !values.image) ||
                        //   !values.unit_price ||
                        //   !values.name ||
                        //   blockCashier
                        // }
                        disabled={!values.unit_price || !values.name}
                        checked={values.availability}
                        onChange={e => {
                          setFieldValue('availability', e.target.checked);
                        }}
                        name="availability"
                        id="availability"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <Grid item container className={classes.formContainer}>
                    <Grid
                      item
                      container
                      xs={9}
                      md={9}
                      className={classes.formLeftSide}
                    >
                      <Box mb={3}>
                        <YellowTextField
                          required={values.availability}
                          disabled={currentProduct.template && !isTemplate}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={`${translation(
                            'edit.product_list.table.head.name'
                          )}:`}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Box>
                      <Box mb={3}>
                        <YellowTextField
                          disabled={currentProduct.template && !isTemplate}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          fullWidth
                          multiline
                          helperText={touched.description && errors.description}
                          label={`${translation(
                            'calendar.event_form.description'
                          )}:`}
                          name="description"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: '165px'
                            }
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <Box mb={3}>
                        <YellowTextField
                          disabled={blockCashier || !enableMenuCashier}
                          required={values.availability}
                          error={Boolean(
                            touched.unit_price && errors.unit_price
                          )}
                          fullWidth
                          helperText={touched.unit_price && errors.unit_price}
                          label={`${translation(
                            'edit.product_create.basic_information.form.price'
                          )}:`}
                          name="unit_price"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.unit_price}
                          variant="outlined"
                          type="number"
                          inputProps={{
                            min: 0.0,
                            step: 0.01
                          }}
                        />
                      </Box>
                      <Box md={3} xs={12} className={classes.imageContainer}>
                        <Card className={classes.imageCard}>
                          <ImageInputCrop
                            enableMenuCashier={enableMenuCashier}
                            isTemplate={isTemplate}
                            fullImage={
                              selectedImage || currentProduct?.photo?.original
                            }
                            defaultImage={
                              selectedImage ||
                              selectedImageFull?.photo?.x400 ||
                              currentProduct?.photo?.x400
                            }
                            onDownloadImage={downloadImage}
                            section={section}
                            onOpen={async () => {
                              openFullImage();
                            }}
                            onDelete={() => {
                              setSelectedImage(null);
                              setSelectedImageFull(null);
                              setImageActions({
                                isDeleted: true,
                                isUpdated: false
                              });
                              setFieldValue('image', true);
                            }}
                          />
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card style={{ marginTop: '24px' }}>
                <CardContent className={classes.cookingCard}>
                  <Grid
                    item
                    container
                    xs={6}
                    md={6}
                    style={{
                      borderRight: `1px solid ${COLORS.disabledGray}`
                    }}
                  >
                    <Grid item container className={classes.cookingCardBox}>
                      <Typography variant="h4" color="textSecondary">
                        <Box fontWeight="bold">
                          {translation(
                            'edit.product_create.estimated_time.head'
                          )}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.cookingCardBox}>
                      {!values.pre_packed && (
                        <>
                          <IconButton
                            disabled={blockCashier}
                            style={{ color: COLORS.ligthYellow }}
                            onClick={() => {
                              const cookingTime =
                                values.cooking_time - 1 >= 0
                                  ? values.cooking_time - 1
                                  : 0;
                              setFieldValue('cooking_time', cookingTime);
                            }}
                          >
                            <Remove />
                          </IconButton>
                          <Typography
                            variant="h3"
                            style={{ color: COLORS.green }}
                          >
                            <Box fontWeight="bold">
                              {`${values.cooking_time} ${translation(
                                'edit.product_create.estimated_time.form.min'
                              )}`}
                            </Box>
                          </Typography>
                          <IconButton
                            disabled={blockCashier}
                            style={{ color: COLORS.ligthYellow }}
                            onClick={() => {
                              setFieldValue(
                                'cooking_time',
                                values.cooking_time + 1
                              );
                            }}
                          >
                            <Add />
                          </IconButton>
                        </>
                      )}
                      {values.pre_packed && (
                        <Typography
                          variant="h3"
                          style={{ color: COLORS.green }}
                        >
                          <Box fontWeight="bold">
                            {`${values.cooking_time} ${translation(
                              'edit.product_create.estimated_time.form.min'
                            )}`}
                          </Box>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container xs={6} md={6}>
                    <Grid
                      item
                      container
                      direction="column"
                      className={classes.cookingCardBox}
                    >
                      <Typography variant="h4" color="textSecondary">
                        <Box fontWeight="bold">
                          {translation(
                            'edit.product_create.estimated_time.form.pre_packaged'
                          )}
                        </Box>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {translation(
                          'edit.product_create.estimated_time.form.line1'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item container className={classes.cookingCardBox}>
                      <Box alignItems="center" display="flex">
                        <Checkbox
                          disabled={currentProduct.template && !isTemplate}
                          checked={values.pre_packed}
                          name="pre_packed"
                          onChange={(event, checked) => {
                            handleChange(event, checked);
                            if (checked) {
                              setFieldValue('cooking_time', 0);
                            } else {
                              setFieldValue('cooking_time', 10);
                            }
                          }}
                          style={{ color: COLORS.green }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {translation(
                            'edit.product_create.estimated_time.form.pre_packaged'
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Grid item container spacing={2} justify="flex-end">
                <Box mt={2}>
                  <Button
                    className={clsx(classes.button, {
                      [classes.removeButton]: isTemplate
                    })}
                    size="large"
                    type="button"
                    variant="contained"
                    disabled={!isTemplate}
                    onClick={e => {
                      e.preventDefault();
                      disableProduct();
                    }}
                  >
                    {translation(
                      'edit.product_create.basic_information.form.delete_product'
                    )}
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    disabled={!dirty || loading}
                    color="secondary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {translation('save_changes')}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CreateForm;
