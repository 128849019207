import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../components/Page';
import Results from './Results';
import Header from './Header';
import ModalCreateAddition from '../AdditionEditView/ModalCreateAddition';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
function AdditionListView() {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth={false}>
          <Header setShowModal={setShowModal} />
          <Box mt={3}>
            <Results />
          </Box>
        </Container>
      </Page>
      <ModalCreateAddition
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}

export default AdditionListView;
