/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import { PlusCircle } from 'react-feather';
import WithLoading from '../../../../components/WithLoading';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';
import { createAreaDb, saveAreaDB } from '../../../../services/areaServices';
import AreaPriceItemForm from './AreaPriceItemForm';
import MapBinder from '../../../../components/Map/MapBinder';
import env from '../../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  },
  mapWrapper: {
    height: 750
  }
}));

// eslint-disable-next-line no-unused-vars
function AreasEditForm({ className, area = {}, def = {}, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const cities = useCities();
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (area.price) {
      const result = Object.values(area.price).map(price =>
        parseInt(price.distance, 10)
      );
      setRadius(Math.max(...result));
    }
  }, [area.price]);

  const saveArea = async areaForm => {
    setLoading(true);
    const data = {
      ...areaForm,
      updated_at: moment().toDate()
    };
    if (area.id) {
      return saveAreaDB(area.id, data);
    }
    data.created_at = moment().toDate();
    return createAreaDb(data);
  };

  const calculateRadius = (index, price) => {
    const areaPrice = price.slice();
    if (index > -1) areaPrice.splice(index, 1);
    if (areaPrice.length > 0) {
      const result = Object.values(areaPrice).map(p =>
        parseInt(p.distance, 10)
      );
      setRadius(Math.max(...result));
    } else {
      setRadius(0);
    }
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          name: area.name || '',
          city_id: area.city_id || '',
          price: area.price || [
            {
              price: 0,
              distance: 0,
              subsidy: 0,
              bonus: 0
            }
          ],
          location: area.location || null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required(translation('admin_areas.title_field.required')),
          city_id: Yup.string()
            .max(255)
            .required(translation('admin_areas.city.required')),
          price: Yup.array()
            .of(
              Yup.object().shape({
                price: Yup.number()
                  .min(0)
                  .required('Precio requerido'),
                distance: Yup.number()
                  .min(0)
                  .required('Distancia requerida'),
                subsidy: Yup.number().min(0),
                bonus: Yup.number().min(0)
              })
            )
            .required(translation('admin_areas.price.required')),
          location: Yup.object()
            .shape({
              lat: Yup.number(),
              lng: Yup.number()
            })
            .typeError(translation('admin_areas.location.required'))
            .required(translation('admin_areas.location.required'))
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await saveArea(values);

            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(translation('admin_areas.correct_save'), {
              variant: 'success'
            });
            setLoading(false);
            history.push('/admin/areas');
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            enqueueSnackbar(
              `${translation('complete_restaurant.error')} ${
                env.REACT_APP_SUPPORT_MAIL
              }`,
              {
                variant: 'error'
              }
            );
            setErrors({ submit: e.message });
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
          const city = cities.find(c => c.id === values.city_id);
          const optionalMapProps = {};
          if (city && city.location) {
            optionalMapProps.center = {
              lat: city.location.latitude,
              lng: city.location.longitude
            };
          }
          return (
            <>
              <form
                noValidate
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Box mb={3}>
                          {cities.length && (
                            <TextField
                              error={Boolean(touched.city_id && errors.city_id)}
                              fullWidth
                              label={`${translation('admin_areas.city.name')}:`}
                              name="city_id"
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue('city_id', e.target.value);
                                setFieldValue('location', null);
                                setFieldValue('name', '');
                                setFieldValue('price', [
                                  {
                                    price: 0,
                                    distance: 0,
                                    subsidy: 0,
                                    bonus: 0
                                  }
                                ]);
                                setRadius(0);
                              }}
                              select
                              SelectProps={{ native: true }}
                              value={values.city_id}
                              variant="outlined"
                            >
                              <option value="" />
                              {cities.map(option => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          )}
                        </Box>
                        <Box mb={3}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={translation('admin_areas.title_field.name')}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            required
                            variant="outlined"
                          />
                        </Box>
                        <Box mb={3}>
                          <Grid container spacing={3}>
                            <Grid item md={3} xs={12}>
                              <Typography>
                                <strong>Distancia</strong>
                              </Typography>
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <Typography>
                                <strong>Precio</strong>
                              </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <Typography>
                                <strong>
                                  {translation(
                                    'admin_areas.item_price.subsidy'
                                  )}
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid item md={2} xs={12}>
                              <Typography>
                                <strong>
                                  {translation('admin_areas.item_price.bonus')}
                                </strong>
                              </Typography>
                            </Grid>
                          </Grid>
                          <FieldArray
                            name="price"
                            render={arrayHelpers => (
                              <Box>
                                {values.price &&
                                  values.price.length > 0 &&
                                  values.price.map((option, index) => (
                                    <AreaPriceItemForm
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={index}
                                      price={option}
                                      index={index}
                                      errors={
                                        errors.price &&
                                        typeof errors.price !== 'string'
                                          ? errors.price[index]
                                          : {}
                                      }
                                      // eslint-disable-next-line no-shadow
                                      onSave={(i, option) =>
                                        arrayHelpers.replace(i, option)
                                      }
                                      onDelete={i => arrayHelpers.remove(i)}
                                      initialyEditing={option.initialyEditing}
                                      // eslint-disable-next-line no-shadow
                                      onRadius={index =>
                                        calculateRadius(
                                          index,
                                          arrayHelpers.form.values.price
                                        )
                                      }
                                    />
                                  ))}
                                <Grid container justify="flex-end" spacing={3}>
                                  <Grid item md={2} xs={12}>
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <IconButton
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            distance: '',
                                            price: '',
                                            initialyEditing: true
                                          });
                                        }}
                                        className={classes.icon}
                                      >
                                        <PlusCircle />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          />
                          {errors.price && typeof errors.price === 'string' && (
                            <Box ml={3} mr={3} mb={3}>
                              <FormHelperText error>
                                {errors.price}
                              </FormHelperText>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <Box className={classes.mapWrapper}>
                          <MapBinder
                            {...optionalMapProps}
                            setPicker={coordinates =>
                              setFieldValue('location', coordinates)
                            }
                            marker={values.location}
                            radius={radius}
                          />
                          {errors.location && (
                            <Box ml={3} mr={3} mb={3}>
                              <FormHelperText error>
                                {errors.location}
                              </FormHelperText>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {translation('save')}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            </>
          );
        }}
      </Formik>
    </BoxLoading>
  );
}

AreasEditForm.propTypes = {
  className: PropTypes.string,
  area: PropTypes.object
};

export default AreasEditForm;
