import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { swap } from 'react-grid-dnd';
import { useSelector } from 'react-redux';
import AdditionPickerItem from './AdditionPickerItem';
import { saveAdditions } from '../../../../services/productsService';
import AdditionService from '../../../../services/additionsService';
import { LOG_ACTIONS } from '../../../../dto/enum';
import { logAddition } from '../../../../services/logServices';

const arrayToObject = array => {
  const newObject = {};
  array.forEach((_, index) => {
    newObject[index] = array[index].name;
  });
  return newObject;
};

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: theme.spacing(3)
  }
}));
const DragDropAdditions = ({
  additions,
  prodId,
  restIdFromState = null,
  blockCashier,
  setLoading,
  isTemplate
}) => {
  const classes = useStyles();
  const restaurant = useSelector(state => state.account.restaurant);

  const dragEnd = async result => {
    if (!result.destination) return;
    setLoading(true);
    const swaped = swap(
      additions,
      result.source.index,
      result.destination.index
    );
    const changes = {
      before: arrayToObject(additions),
      after: arrayToObject(swaped)
    };
    const data = {
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      }
    };
    const [edit] = swaped.filter(
      (section, index) => section !== additions[index]
    );
    if (edit && isTemplate) await logAddition(changes, LOG_ACTIONS.ORDER, data);
    await saveAdditions(swaped, prodId);
    await AdditionService.updatePositions(prodId);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {additions.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {prov => (
                  <div
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    className={classes.item}
                  >
                    <AdditionPickerItem
                      addition={item}
                      prodId={prodId}
                      restIdFromState={restIdFromState}
                      blockCashier={blockCashier}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropAdditions;
