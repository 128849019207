import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  IconButton,
  lighten,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import Toolbar from '@material-ui/core/Toolbar';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    color: theme.palette.common.black,
    flex: '1 1 100%'
  }
}));

const ExpressTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, onPayment } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 && (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${numSelected} selected`}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Pagar Orden">
          <IconButton aria-label="Pagar Orden" onClick={onPayment}>
            <PaymentIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

ExpressTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onPayment: PropTypes.func
};

export default ExpressTableToolbar;
