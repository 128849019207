import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import { useFirestoreConnect } from 'react-redux-firebase';
import Header from './Header';
import Results from './Results';
import { COLLECTIONS } from '../../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function UsersListView() {
  const classes = useStyles();
  const translation = useTranslation();

  useFirestoreConnect({
    collection: COLLECTIONS.USER
  });

  const users = useSelector(state => state.firestore.ordered.users);

  if (!users) {
    return null;
  }
  const normalizedUsers = users.map(user => ({
    ...user,
    orders_completed: user.orders_completed ? user.orders_completed : 0,
    total_purchases: user.total_purchases ? user.total_purchases : 0,
    status: user.status ? user.status : 'ACTIVE'
  }));
  return (
    <Page className={classes.root} title={translation('members.page_title')}>
      <Container maxWidth={false}>
        <Header />
        {users && (
          <Box mt={3}>
            <Results users={normalizedUsers} />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default UsersListView;
