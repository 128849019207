import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useTranslation } from 'react-multi-lang/lib';
import {
  MenuItem,
  Typography,
  Box,
  makeStyles,
  Button,
  Menu
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCurrentSubsidiary } from '../../../hooks/useRestaurant';
import { COLLECTIONS } from '../../../constants';
import { useCurrentSubsidiaryFS } from '../../../hooks/Subsidiary/useCurrentSubsidiary';

const useStyles = makeStyles(theme => ({
  typeVehicle: {
    marginRight: '4px',
    borderRadius: 10
  },
  menu: {
    textTransform: 'initial',
    width: 90,
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    fontWeight: 'normal'
  },
  icon: {
    color: theme.palette.tertiary.main
  }
}));
const TypeVehicleSelector = () => {
  const classes = useStyles();
  const translate = useTranslation();
  const firestore = useFirestore();
  const { id } = useCurrentSubsidiary();
  const { subsidiary_vehicle_type } = useCurrentSubsidiaryFS(id);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const typeVehicle = ['ALL', 'CAR', 'MOTO'];

  const getTypeVehicle = option => {
    let type = '';
    switch (option) {
      case 'MOTO':
        type = translate('menu.subsidiary_vehicle_type.type.moto');
        break;
      case 'CAR':
        type = translate('menu.subsidiary_vehicle_type.type.car');
        break;
      case 'ALL':
        type = translate('menu.subsidiary_vehicle_type.type.all');
        break;
      default:
        break;
    }
    return type;
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeSubsidiaryTypeVehicle = type => {
    firestore
      .collection(COLLECTIONS.SUBSIDIARY)
      .doc(id)
      .update({ subsidiary_vehicle_type: type });
    handleClose();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1.5}
    >
      <Typography variant="h6">
        {translate('menu.subsidiary_vehicle_type.transportation_preferences')}
      </Typography>
      <Box className={classes.typeVehicle} boxShadow={3}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.menu}
          onClick={handleClick}
          endIcon={<ExpandMoreIcon className={classes.icon} />}
          variant="text"
        >
          {getTypeVehicle(subsidiary_vehicle_type || 'ALL')}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {typeVehicle.map((type, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                changeSubsidiaryTypeVehicle(type);
              }}
              variant="h4"
              color="textPrimary"
            >
              {getTypeVehicle(type)}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default TypeVehicleSelector;
