/* global google */
import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import Typography from '@material-ui/core/Typography';
// eslint-disable-next-line import/extensions
import demoFancyMapStyles from './demoFancyMapStyles';
import { DRIVER_STATUS } from '../../dto/enum';
import env from '../../env';

const useStyles = makeStyles(theme => ({
  mapResponsiveContainer: {
    height: 1000,
    [theme.breakpoints.down('md')]: {
      height: 750
    }
  },
  descriptionBox: {
    backgroundColor: theme.palette.expressColors.white,
    opacity: 0.75,
    padding: theme.spacing(1)
  },
  description: {
    fontSize: theme.spacing(1.5)
  }
}));

function Map({ center, dealers, orders, ...props }) {
  const classes = useStyles();
  const [viewInfoBox, setViewInfoBox] = useState({});
  const [orderInfoBox, setOrderInfoBox] = useState({});
  const refMap = useRef(null);
  const [currentCenter, setCurrentCenter] = useState({});
  const [oldCenter, setOldCenter] = useState({});
  // const handleBoundsChanged = () => {
  //   const mapCenter = refMap.current.getCenter(); //get map center
  //   setLastCenter(mapCenter);
  // };

  useEffect(() => {
    const vi = {};
    if (dealers.length) {
      dealers.forEach((d, index) => {
        if (d.delivering_status === DRIVER_STATUS.WAITING_FOR_ORDERS) {
          vi[index] = true;
        }
      });
      setViewInfoBox(vi);
    }
    setTimeout(() => {
      setCurrentCenter({ ...center });
    }, 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOldCenter({ ...center });
    if (
      center &&
      center.lat !== oldCenter.lat &&
      center.lng !== oldCenter.lng
    ) {
      setCurrentCenter(center);
    }
    // eslint-disable-next-line
  }, [center]);

  const getdealerIcon = d => {
    let url = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    if (
      d.delivering_status === DRIVER_STATUS.GOING_TO_PICK ||
      d.delivering_status === DRIVER_STATUS.DELIVERING
    ) {
      url = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png';
    }
    if (d.delivering_status === DRIVER_STATUS.WAITING_FOR_ORDERS)
      url = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

    return {
      url,
      size: { width: 35, height: 35 },
      scaledSize: new google.maps.Size(35, 35)
    };
  };

  const getOrderIcon = o => {
    return {
      url: '/static/images/icons/locationPin.png',
      size: { width: 35, height: 35 },
      scaledSize: new google.maps.Size(35, 35)
    };
  };

  const optionalParameters = {};

  if (currentCenter.lat && currentCenter.lng) {
    optionalParameters.defaultCenter = currentCenter;
    optionalParameters.center = currentCenter;
  }

  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={14}
      defaultOptions={{ styles: demoFancyMapStyles }}
      // onBoundsChanged={useCallback(handleBoundsChanged)}
      {...optionalParameters}
    >
      {dealers.map((d, index) => (
        <Marker
          key={index}
          position={d.location}
          options={{
            icon: getdealerIcon(d)
          }}
          onClick={() => {
            const ib = { ...viewInfoBox };
            ib[index] = !ib[index];
            setViewInfoBox(ib);
          }}
        >
          {viewInfoBox[index] && (
            <InfoBox>
              <div className={classes.descriptionBox}>
                <Typography variant="body1" className={classes.description}>
                  {`${d.first_name} ${d.last_name.charAt(0).toUpperCase()}.`}
                </Typography>
              </div>
            </InfoBox>
          )}
        </Marker>
      ))}
      {orders.map((o, index) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <Marker
          key={index}
          position={o.location}
          options={{
            icon: getOrderIcon(o)
          }}
          onMouseOver={() => {
            const oIB = { ...orderInfoBox };
            oIB[index] = true;
            setOrderInfoBox(oIB);
          }}
          onMouseOut={() => {
            const oIB = { ...orderInfoBox };
            oIB[index] = false;
            setOrderInfoBox(oIB);
          }}
        >
          {orderInfoBox[index] && (
            <InfoBox>
              <div className={classes.descriptionBox}>
                <Typography variant="body1" className={classes.description}>
                  {o.order_code}
                </Typography>
              </div>
            </InfoBox>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export const MapWrapped = withScriptjs(withGoogleMap(Map));

const MapDealersOrders = ({ dealers = [], orders = [], center }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.mapResponsiveContainer)}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        center={center}
        dealers={dealers}
        orders={orders}
      />
    </div>
  );
};

MapDealersOrders.propTypes = {
  center: PropTypes.object
};

export default MapDealersOrders;
