import React from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { PlusCircle as PlusCircleIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ path, className, ...rest }) => {
  const classes = useStyles();

  const createCommerce = async () => {
    console.log('create commerce');
  };

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/commerce"
            component={RouterLink}
          >
            Comercios
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to={`/admin/commerce/${path}`}
            component={RouterLink}
          >
            {path}
          </Link>
        </Breadcrumbs>
      </Grid>
      {!path && (
        <Grid container item alignContent="center" justify="flex-end">
          <Grid>
            <Button
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={createCommerce}
            >
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <PlusCircleIcon />
              </SvgIcon>
              Añadir nuevo comercio
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
