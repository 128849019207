import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  TextField
} from '@material-ui/core';
import { useUserAsLegalRepresentant } from '../../../../../hooks/AdminHooks/users/useUsers';

const RepresentantCard = props => {
  const { reprsentantId } = props;
  const representant = useUserAsLegalRepresentant(reprsentantId);
  const translation = useTranslation();
  let representantInfo = {
    fullName: '',
    phone: '',
    ci: '',
    mail: ''
  };
  if (representant) {
    representantInfo = {
      fullName: `${representant.first_name} ${representant.last_name}`,
      phone: `${representant.phone_number}`,
      ci: `${representant.ci}`,
      mail: `${representant.email}`
    };
  }
  return (
    <Box width="100%">
      <Card>
        <CardHeader
          title={translation('admin_invoice.representant_card.title')}
        />
        <Divider />
        <CardContent>
          {!representant && (
            <Grid item container justify="center">
              <CircularProgress />
            </Grid>
          )}
          {representant && (
            <Box my={3}>
              <Grid item container>
                <Box mx={1.5}>
                  <TextField
                    disabled
                    type="text"
                    label={translation(
                      'admin_invoice.representant_card.labels.name'
                    )}
                    name="full_name"
                    value={representantInfo.fullName}
                    variant="outlined"
                  />
                </Box>
                <Box mx={1.5}>
                  <TextField
                    disabled
                    type="text"
                    label={translation(
                      'admin_invoice.representant_card.labels.phone'
                    )}
                    name="full_name"
                    value={representantInfo.phone}
                    variant="outlined"
                  />
                </Box>
                <Box mx={1.5}>
                  <TextField
                    disabled
                    type="text"
                    label={translation(
                      'admin_invoice.representant_card.labels.ci'
                    )}
                    name="full_name"
                    value={representantInfo.ci}
                    variant="outlined"
                  />
                </Box>
                <Box mx={1.5}>
                  <TextField
                    disabled
                    type="text"
                    label={translation(
                      'admin_invoice.representant_card.labels.mail'
                    )}
                    name="full_name"
                    value={representantInfo.mail}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RepresentantCard;
