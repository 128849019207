import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { useReadyToPickStyles } from './styles';
import { ORDER_TYPE, ORDER_STATUS } from '../../../../dto/enum';

const ReadyToPick = props => {
  const {
    onNotifyClient,
    onOrderDelivered,
    orderType,
    orderStatus,
    notificationUser
  } = props;
  const classes = useReadyToPickStyles();
  const translation = useTranslation();

  return (
    <Box>
      <Box className={classes.container}>
        <div className={classes.containerButton}>
          <Button
            className={clsx(classes.button, classes.responsiveButton)}
            variant="contained"
            color="secondary"
            onClick={onNotifyClient}
            disabled={orderStatus === ORDER_STATUS.READY_TO_CASHIER}
          >
            {translation('orders_view.cockpit.buttons.notify')}
          </Button>
          {notificationUser && (
            <Typography className={classes.btnTextClient} align="center">
              CLIENTE NOTIFICADO
            </Typography>
          )}
        </div>
        <div className={classes.containerButton}>
          <Button
            className={clsx(
              classes.button,
              classes.middleButton,
              classes.responsiveButton
            )}
            variant="contained"
            color="secondary"
            onClick={onOrderDelivered}
            disabled={orderType === ORDER_TYPE.DELIVERY}
          >
            {translation('orders_view.cockpit.buttons.order_delivered')}
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default ReadyToPick;
