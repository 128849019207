import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../../constants';

export const useAllOrdersPerRestaurant = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      storeAs: `orders_per_restaurant`
    }
  ]);

  const ordersPerRestaurant = useSelector(
    state => state.firestore.ordered.orders_per_restaurant
  );

  return ordersPerRestaurant;
};

export const useAllUsersPerRestaurant = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      storeAs: `users_per_restaurant`
    }
  ]);

  const usersPerRestaurant = useSelector(
    state => state.firestore.ordered.users_per_restaurant
  );

  return usersPerRestaurant;
};
