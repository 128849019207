import { getDate } from 'src/utils/date';
/* eslint camelcase: 0 */

class AdminNotification {
  constructor({ id, title, description, metadata, created_at, read }) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.metadata = metadata;
    this.created_at = getDate(created_at);
    this.read = read;
  }
}
export default AdminNotification;
