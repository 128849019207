/* eslint-disable no-nested-ternary,no-unneeded-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-multi-lang/lib';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { USER_ROLES } from '../../../dto/enum';
import useRestaurantAndSubsidiaries, {
  useSubsidiariesUser
} from '../../../hooks/useRestaurant';
import userService from '../../../services/usersService';
import WithLoading from '../../../components/WithLoading';
import { COLORS } from '../../../theme/colors';
import Icons from '../../../assets/icons';
import DropzoneModal from '../../../components/Dropzone/DropzoneModal';
import { generateRandomId } from '../../../utils/database';
import getUserRoles from '../../../utils/helpers/userHelper';
import env from '../../../env';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  gridBorder: {
    borderWidth: 2,
    padding: 20,
    borderStyle: 'solid',
    borderColor: COLORS.defaultPage,
    borderLeftWidth: 0
  }
}));

function CustomerEditForm({
  className,
  user = {},
  def = {},
  restId,
  isModal,
  modalCancel,
  modalSave,
  onClose,
  withPassword,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [restaurant] = useRestaurantAndSubsidiaries();
  const subsidiaries = useSubsidiariesUser(restId || '');
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const [image, setImage] = useState(false);
  const [open, setOpen] = useState(false);
  const roles = [
    {
      value: USER_ROLES.CASHIER,
      label: translation('customer_edit.cashier')
    },
    {
      value: USER_ROLES.SUPERVISOR,
      label: translation('customer_edit.supervisor')
    }
  ];

  const saveUser = async userForm => {
    setLoading(true);
    const userData = {
      ...userForm,
      roles: {
        [userForm.roles]: true
      },
      restaurant_id: restId || restaurant.id
    };
    if (user.id) {
      delete userData.email;
      return userService.editUser(user.id, userData);
    }
    userData.profile_photo = { original: image, x80: image, x40: image };
    return userService.saveUser(userData);
  };

  const defaultSubsidiary = def.subsidiary_id ? [def.subsidiary_id] : [];

  if (user.subsidiary_id && typeof user.subsidiary_id === 'string') {
    // eslint-disable-next-line no-param-reassign
    user.subsidiary_id = [user.subsidiary_id];
  }

  const updateImage = img => {
    setImage(img);
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          phone_number: user.phone_number || '',
          email: user.email || '',
          roles: getUserRoles(user)[0] || '',
          subsidiary_id:
            user.subsidiary_id && user.subsidiary_id.length
              ? user.subsidiary_id
              : defaultSubsidiary,
          temporal_password: user.temporal_password || ''
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string()
            .max(255)
            .required(
              translation('basic_information.personal_info.name.require')
            ),
          last_name: Yup.string()
            .max(255)
            .required(
              translation('basic_information.personal_info.lastname.require')
            ),
          phone_number: Yup.string().max(255),
          email: Yup.string()
            .email(translation('login.email.valid'))
            .max(255)
            .required(translation('login.email.require')),
          roles: Yup.mixed()
            .oneOf([USER_ROLES.CASHIER, USER_ROLES.SUPERVISOR])
            .required(translation('customer_edit.profile_require')),
          subsidiary_id: Yup.array().required(
            translation('customer_edit.require_subsidiary')
          ),
          temporal_password: Yup.string()
            .min(6)
            .max(255)
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await saveUser(values);
            setStatus({ success: true });
            setSubmitting(false);
            if (isModal) modalSave();
            enqueueSnackbar(translation('customer_edit.correct_save'), {
              variant: 'success',
              action: (
                <Button onClick={() => history.push('/app/members')}>
                  {translation('customer_edit.staff')}
                </Button>
              )
            });
            setLoading(false);
            if (!restId) {
              if (onClose) {
                onClose();
              } else {
                history.push('/app/members');
              }
            }
          } catch (e) {
            setStatus({ success: false });
            setSubmitting(false);
            if (e.status === 409) {
              enqueueSnackbar(translation('customer_edit.used_email'), {
                variant: 'error'
              });
              setErrors({ email: translation('customer_edit.email') });
            } else {
              enqueueSnackbar(
                `${translation('complete_restaurant.error')} ${
                  env.REACT_APP_SUPPORT_MAIL
                }`,
                {
                  variant: 'error'
                }
              );
              setErrors({ submit: e.message });
            }
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => {
          return (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              <div>
                <div>
                  <Grid container>
                    <Grid className={classes.gridBorder} item md={9} xs={12}>
                      <Grid container spacing={3}>
                        <Grid align="center" item md={4} xs={12}>
                          <Paper
                            onClick={() => setOpen(true)}
                            style={{ width: 136, height: 136 }}
                            elevation={3}
                          >
                            <img
                              style={{ width: 136, height: 136 }}
                              src={
                                image
                                  ? image
                                  : user.profile_photo &&
                                    user.profile_photo.original
                                  ? user.profile_photo.x80 ||
                                    user.profile_photo.original
                                  : Icons.usePicture
                              }
                              alt="avatar"
                            />
                          </Paper>
                        </Grid>
                        <Grid item md={8} xs={12}>
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.first_name && errors.first_name
                                )}
                                fullWidth
                                helperText={
                                  touched.first_name && errors.first_name
                                }
                                label={translation(
                                  'basic_information.personal_info.name.name'
                                )}
                                name="first_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                required
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.last_name && errors.last_name
                                )}
                                fullWidth
                                helperText={
                                  touched.last_name && errors.last_name
                                }
                                label={translation(
                                  'basic_information.personal_info.lastname.lastname'
                                )}
                                name="last_name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                required
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label={translation('login.email.label')}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required
                                value={values.email}
                                variant="outlined"
                                disabled={!!user.id}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.phone_number && errors.phone_number
                                )}
                                fullWidth
                                helperText={
                                  touched.phone_number && errors.phone_number
                                }
                                label={translation(
                                  'basic_information.personal_info.phone.phone'
                                )}
                                name="phone_number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone_number}
                                variant="outlined"
                              />
                            </Grid>
                            {withPassword && (
                              <Grid item md={12} xs={12}>
                                <TextField
                                  type="password"
                                  fullWidth
                                  label={translation(
                                    'basic_information.personal_info.password'
                                  )}
                                  name="temporal_password"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.temporal_password}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.gridBorder} item md={3} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            name="roles"
                            onChange={handleChange}
                            select
                            label={translation('account.profile')}
                            SelectProps={{ native: true }}
                            value={values.roles}
                            variant="outlined"
                            error={Boolean(touched.roles && errors.roles)}
                            helperText={touched.roles && errors.roles}
                          >
                            <option />
                            {roles.map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        {values.roles === USER_ROLES.SUPERVISOR ? (
                          <Grid item md={12} xs={12}>
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              options={subsidiaries}
                              getOptionLabel={s => s.name || s.address}
                              filterSelectedOptions
                              value={values.subsidiary_id.map(i =>
                                subsidiaries.find(s => i === s.id)
                              )}
                              onChange={(e, subs) => {
                                setFieldValue(
                                  'subsidiary_id',
                                  subs.map(s => s.id)
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={translation(
                                    'customer_edit.subsidiary'
                                  )}
                                  error={Boolean(
                                    touched.subsidiary_id &&
                                      errors.subsidiary_id
                                  )}
                                  helperText={
                                    touched.subsidiary_id &&
                                    errors.subsidiary_id
                                  }
                                  placeholder={translation(
                                    'customer_edit.subsidiary'
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        ) : (
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              name="subsidiary_id[0]"
                              select
                              label={translation('customer_edit.subsidiary')}
                              SelectProps={{ native: true }}
                              value={values.subsidiary_id[0]}
                              onChange={e => {
                                handleChange(e);
                                if (e.target.value === '') {
                                  setFieldValue('subsidiary_id', []);
                                }
                              }}
                              variant="outlined"
                              error={Boolean(
                                touched.subsidiary_id && errors.subsidiary_id
                              )}
                              helperText={
                                touched.subsidiary_id && errors.subsidiary_id
                              }
                            >
                              <option />
                              {subsidiaries.map(({ id, address, name }) => (
                                <option key={id} value={id}>
                                  {name || address}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid item container justify="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {translation('save')}
                      </Button>
                      {isModal && (
                        <Box ml={5}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            disabled={isSubmitting}
                            onClick={modalCancel}
                          >
                            {translation('cancel')}
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Box>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <DropzoneModal
        onClose={() => setOpen(false)}
        open={open}
        setImage={updateImage}
        onFinish={() => setOpen(false)}
        path={`profile/${user.id || generateRandomId()}`}
      />
    </BoxLoading>
  );
}

CustomerEditForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default CustomerEditForm;
