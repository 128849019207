import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useCurrentRestaurantSubsidiaries } from '../hooks/useGetUserSubsidiaries';
import { setSubsidiaries } from '../reducers/restaurantReducer';

export default function useUserSubsidiariesProvider() {
  const subsidiaries = useCurrentRestaurantSubsidiaries();
  const dispatch = useDispatch();
  useEffect(() => {
    if (subsidiaries) {
      dispatch(setSubsidiaries(subsidiaries));
    }
  }, [subsidiaries, dispatch]);
}
