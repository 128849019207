import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory, useParams } from 'react-router';
import Header from './Header';
import Results from './Results';
import {
  usePromoBanners,
  usePromos
} from '../../../../hooks/AdminHooks/promos/usePromos';
import ResultsBanners from './ResultsBanners';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function PromosListView() {
  const classes = useStyles();
  const translation = useTranslation();
  const [currentTab, setCurrentTab] = useState('promos');
  const history = useHistory();
  const { status } = useParams();
  const promos = usePromos();
  const promosBanner = usePromoBanners();

  useEffect(() => {
    let value = 'promos';
    if (history.location.pathname === '/admin/promos_banner') {
      value = 'promos_banner';
    }
    setCurrentTab(value);
    // eslint-disable-next-line
  }, [status]);

  const tabs = [
    {
      value: 'promos',
      label: 'PROMO CODES'
    },

    {
      value: 'promos_banner',
      label: 'PROMO BANNERS'
    }
  ];

  const handleTabsChange = (event, value) => {
    if (value === 'promos') {
      history.push(`/admin/promos`);
    } else {
      history.push(`/admin/promos_banner`);
    }
  };

  return (
    <Page className={classes.root} title={translation('admin_promos.title')}>
      <Container maxWidth={false}>
        <Header currentTab={currentTab} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          {currentTab === 'promos' && promos && <Results promos={promos} />}
          {currentTab === 'promos_banner' && promosBanner && (
            <ResultsBanners promoBanners={promosBanner} />
          )}
        </Box>
      </Container>
    </Page>
  );
}

export default PromosListView;
