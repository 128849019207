import React from 'react';
import { Card } from '@material-ui/core';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import _ from 'lodash';
import useUser from '../../../hooks/useUser';
import Orderstatus from './Orderstatus';

const useStyles = makeStyles(theme => ({
  root: {},
  right: {
    textAlign: 'right'
  },
  container: {
    width: '100%'
  },
  textField: {
    fontSize: 14
  }
}));

const TimeDetails = ({ order }) => {
  const classes = useStyles();
  const [user] = useUser(order.user_id);
  const translation = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <Card className={clsx(classes.root)}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h4" component="span">
            {translation('orders.details.title')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box width="100%">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={5} xl={5} className={classes.right}>
                <Typography variant="h6" color="textPrimary" display="inline">
                  {`${translation('orders.details.order_state')}: `}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7} xl={7}>
                <Orderstatus orderStatus={order.status} />
              </Grid>
              <Grid item xs={12} md={5} xl={5} className={classes.right}>
                <Typography display="inline" variant="h6" color="textPrimary">
                  {`${translation('orders.details.order_time')}: `}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7} xl={7}>
                <Typography
                  display="inline"
                  variant="body2"
                  color="textSecondary"
                >
                  {` ${moment(order.created_at.toDate()).format('LT')}`}
                </Typography>
              </Grid>
              {!_.isEmpty(order.time_log) && (
                <Box className={classes.container}>
                  {order.time_log?.canceled && (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={5} className={classes.right}>
                        <Typography className={classes.textField}>
                          {translation('orders.details.canceled')}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className={classes.textField}>
                          {moment(order.time_log.canceled.toDate()).format(
                            'LT'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {order.time_log?.delivery_pickup && (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={5} className={classes.right}>
                        <Typography className={classes.textField}>
                          {translation('orders.details.delivery_pickup')}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className={classes.textField}>
                          {moment(
                            order.time_log.delivery_pickup.toDate()
                          ).format('LT')}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {order.time_log?.completed && (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={5} className={classes.right}>
                        <Typography className={classes.textField}>
                          {translation('orders.details.completed')}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className={classes.textField}>
                          {moment(order.time_log.completed.toDate()).format(
                            'LT'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {order.time_log?.total_order_time && (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={5} className={classes.right}>
                        <Typography className={classes.textField}>
                          {translation('orders.details.total_order_time')}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className={classes.textField}>
                          {`${order.time_log.total_order_time.toFixed(2)} min.`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}

              <Grid item xs={12} md={5} xl={5} className={classes.right}>
                <Typography display="inline" variant="h6" color="textPrimary">
                  {`${translation('orders.details.order_date')}:`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7} xl={7}>
                <Typography
                  display="inline"
                  variant="body2"
                  color="textSecondary"
                >
                  {` ${moment(order.created_at.toDate()).format('LL')}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
};

export default TimeDetails;
