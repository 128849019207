import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

const COLLECTION = COLLECTIONS.RESTAURANT;

export const addSharedMenuSection = async (newSection, restaurantId) => {
  const batch = admin.firestore().batch();

  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(restaurantId)
    .get();
  let existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections.push({
    ...newSection,
    template_id: newSection.id,
    id: firebaseService.randomId('menu_section')
  });
  existingSections = existingSections.map(section => ({
    ...section,
    template: true
  }));

  batch.set(
    admin
      .firestore()
      .collection(COLLECTION)
      .doc(restaurantId),
    { menu_section: existingSections },
    { merge: true }
  );

  const subsidiaries = (
    await admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('restaurant_id', '==', restaurantId)
      .get()
  ).docs.map(doc => ({ ...doc.data(), id: doc.id }));

  subsidiaries.forEach(sub => {
    if (sub.menu_section) {
      const selfSections = sub.menu_section.filter(
        section => !section.template
      );
      batch.update(
        admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id),
        { menu_section: [...existingSections, ...selfSections] }
      );
    } else {
      batch.update(
        admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id),
        { menu_section: existingSections }
      );
    }
  });
  return batch.commit();
};

export const removeSharedMenuSection = async (id, index) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  let existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections.splice(index, 1);
  existingSections = existingSections.map(section => ({
    ...section,
    template: true
  }));

  const batch = admin.firestore().batch();

  batch.update(
    admin
      .firestore()
      .collection(COLLECTION)
      .doc(id),
    { menu_section: existingSections }
  );

  const subsidiaries = (
    await admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('restaurant_id', '==', id)
      .get()
  ).docs.map(doc => ({ ...doc.data(), id: doc.id }));

  subsidiaries.forEach(sub => {
    if (sub.menu_section) {
      const selfSections = sub.menu_section.filter(
        section => !section.template
      );
      batch.update(
        admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id),
        { menu_section: [...existingSections, ...selfSections] }
      );
    } else {
      batch.update(
        admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id),
        { menu_section: existingSections }
      );
    }
  });
  await batch.commit();
};

export const editSharedMenuSection = async (editedFields, id, index) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  let existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];
  existingSections[index] = editedFields;
  existingSections = existingSections.map(section => ({
    ...section,
    template: true
  }));

  // eslint-disable-next-line no-unused-vars
  const [result, subsidiaries] = await Promise.all([
    admin
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .set({ menu_section: existingSections }, { merge: true }),

    (async () => {
      const subsidiariesRest = (
        await admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .where('restaurant_id', '==', id)
          .get()
      ).docs.map(doc => ({ ...doc.data(), id: doc.id }));
      return subsidiariesRest;
    })()
  ]);

  return Promise.all(
    subsidiaries.map(async sub => {
      if (sub.menu_section) {
        const selfSections = sub.menu_section.filter(
          section => !section.template
        );
        await admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id)
          .update({ menu_section: [...existingSections, ...selfSections] });
      } else {
        await admin
          .firestore()
          .collection(COLLECTIONS.SUBSIDIARY)
          .doc(sub.id)
          .update({ menu_section: existingSections });
      }
    })
  );
};

export const swapSharedArrayPositions = async (
  section,
  index,
  direction,
  id
) => {
  const sections = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  let existingSections = sections.data().menu_section
    ? [...sections.data().menu_section]
    : [];

  if (direction === 'up') {
    const swapableSection = [...existingSections].splice(index - 1, 1)[0];
    existingSections[index] = swapableSection;
    existingSections[index - 1] = section;
  } else {
    const swapableSection = [...existingSections].splice(index + 1, 1)[0];
    existingSections[index] = swapableSection;
    existingSections[index + 1] = section;
  }
  existingSections = existingSections.map(item => ({
    ...item,
    template: true
  }));

  admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .set({ menu_section: existingSections }, { merge: true });

  const subsidiaries = (
    await admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('restaurant_id', '==', id)
      .get()
  ).docs.map(doc => ({ ...doc.data(), id: doc.id }));

  return subsidiaries.forEach(sub => {
    if (sub.menu_section) {
      const selfSections = sub.menu_section.filter(item => !item.template);
      admin
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(sub.id)
        .update({ menu_section: [...existingSections, ...selfSections] });
    } else {
      admin
        .firestore()
        .collection(COLLECTIONS.SUBSIDIARY)
        .doc(sub.id)
        .update({ menu_section: existingSections });
    }
  });
};
