import React from 'react';
import DropzoneModal from '../../../../components/Dropzone/DropzoneModal';

function UpdateImagenDealer({
  dealer,
  updateImage,
  open = { open: false, name: '' },
  setOpen = () => {}
}) {
  let namePhoto = '';
  let path = '';

  if (open.name !== 'photo' && open.name !== '') {
    const start = open.name.indexOf('%2Fdriver%2F');
    const end = open.name.indexOf('?alt=');
    namePhoto = open.name.substring(start + 12, end);
  }

  if (open.name === 'photo') {
    path = `profile/${dealer.id}`;
  } else {
    path = `profile/${dealer.id}/driver/${namePhoto}`;
  }

  return (
    <DropzoneModal
      onClose={() => setOpen({ open: false, name: '' })}
      open={open.open}
      setImage={img => {
        updateImage(img, open.type);
      }}
      onFinish={() => setOpen({ open: false, name: '' })}
      path={path}
      maxSize={800}
    />
  );
}

export default UpdateImagenDealer;
