import React, { useState } from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import Icons from '../../assets/icons';

const useStyles = makeStyles(() => ({
  text: {
    justifyContent: 'center'
  },
  content: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function Image({
  alt,
  onClick = () => {},
  style = {},
  src,
  filename = ''
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  return (
    <div className={classes.content}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt={alt}
        onClick={onClick}
        className={style}
        src={src}
        onLoad={() => {
          setLoading(false);
        }}
        onError={event => {
          // eslint-disable-next-line no-param-reassign
          event.target.src = Icons.uploadImage;
          // eslint-disable-next-line no-param-reassign
          event.onerror = null;
        }}
      />
      <Typography className={classes.text}>{filename}</Typography>
      {loading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
