import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Typography, Card, Avatar } from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import clsx from 'clsx';
import { LOG_ACTION, ORDER_STATUS } from '../../../../../dto/enum';
import { useHistoryStyles } from '../styles';
import { useOrderHistory } from '../../../../../hooks/History/useOrderHistory';

const History = ({ orderId }) => {
  const orderHistory = useOrderHistory(orderId);
  const translation = useTranslation();
  const classes = useHistoryStyles();

  const getHistoryAction = action => {
    switch (action) {
      case LOG_ACTION.STATUS_CHANGED:
        return translation('orders.history.action.status_changed');
      case LOG_ACTION.VERIFIED:
        return translation('orders.history.action.verified');
      case LOG_ACTION.DRIVER_CHANGED:
        return translation('orders.history.action.driver_canged');
      case LOG_ACTION.NOTIFY_CLIENT:
        return translation('orders.history.action.notify_client');
      case LOG_ACTION.READY_FOR_DRIVER_TO_PICK_UP:
        return translation('orders.history.action.ready_for_driver_to_pick_up');
      default:
        return `invalid action ${action}`;
    }
  };

  const getChange = history => {
    switch (history.action) {
      case LOG_ACTION.STATUS_CHANGED:
        return translation(
          `orders.history.change.${history.status.toLowerCase()}`
        );
      case LOG_ACTION.VERIFIED:
        return translation(
          `orders.history.change.${history?.verified ? 'true' : 'false'}`
        );
      case LOG_ACTION.DRIVER_CHANGED:
        return `${history?.driver?.first_name} ${history?.driver?.last_name}`;
      case LOG_ACTION.NOTIFY_CLIENT:
        return `${moment(history?.created_at?.toDate())?.format('LLLL')}`;
      case LOG_ACTION.READY_FOR_DRIVER_TO_PICK_UP:
        return `${moment(history?.created_at?.toDate())?.format('LLLL')}`;
      default:
        return `invalid change ${history.action}`;
    }
  };

  return (
    <Card>
      {orderHistory.map(h => (
        <Box className={classes.container}>
          <Avatar
            className={classes.avatar}
            src={h.user.profile_photo && h.user.profile_photo.x80}
          >
            {getInitials(`${h.user.first_name} ${h.user.last_name}`)}
          </Avatar>
          <Typography className={clsx(classes.text, classes.mainText)}>
            {`${h.user.first_name} ${h.user.last_name}`} &nbsp;
          </Typography>
          <Typography className={clsx(classes.text)}>
            {`${getHistoryAction(h.action)}`} &nbsp;
          </Typography>
          <Typography className={clsx(classes.text, classes.mainText)}>
            {`${getChange(h)}`} &nbsp;
          </Typography>
          {h.status === ORDER_STATUS.CANCELED && (
            <Typography className={clsx(classes.text)}>
              {translation('orders.history.change.due')} &nbsp;
              {h.canceled_reason || ''} &nbsp;
            </Typography>
          )}
          <Typography className={clsx(classes.text)}>
            {`${
              h.action !== LOG_ACTION.NOTIFY_CLIENT
                ? moment(h.created_at.toDate()).format('LLLL')
                : ''
            }`}{' '}
            &nbsp;
          </Typography>
        </Box>
      ))}
    </Card>
  );
};

export default History;
