import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Grid, Link } from '@material-ui/core';
import ExpressTable from '../../../../components/Table/ExpressTable';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';

const Results = ({ promos }) => {
  const translation = useTranslation();
  const cities = useCities();

  const tableColumns = [
    {
      id: 'name',
      label: translation('admin_areas.table.city'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'actions',
      label: translation('admin_areas.table.actions'),
      maxWidth: 100,
      align: 'center'
    }
  ];
  let tableContent = [];

  const linkToSub = item => {
    return (
      <Box>
        <Link
          style={{
            color: 'black',
            textDecorationLine: 'underline',
            marginRight: '8px'
          }}
          component={RouterLink}
          to={`/admin/city/${item.id}/poligon`}
        >
          Poligono
        </Link>
      </Box>
    );
  };

  if (cities) {
    tableContent = cities.map(item => ({
      id: item.id,
      name: item.name,
      actions: linkToSub(item)
    }));
  }

  return (
    <>
      <Grid>
        <ExpressTable
          columns={[...tableColumns]}
          rows={[...tableContent]}
          search
        />
      </Grid>
    </>
  );
};

export default Results;
