// eslint-disable-next-line import/named
import { MEDIA_TYPE } from '../../constants';

export default class Media {
  constructor({ type = MEDIA_TYPE.PHOTO, url_original = '' }) {
    this.type = type;
    this.url_original = url_original;
  }

  toPlainObject() {
    return { ...this };
  }
}
