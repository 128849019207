import React, { useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useFirestoreConnect } from 'react-redux-firebase';
import { COLLECTIONS } from 'src/constants';
import { useSelector } from 'react-redux';
import Header from './Header';
import Page from '../../../components/Page';
import CategoriesFoodList from './CategoriesFoodList';
import CreateOrEditModal from './Modal/CreateOrEditModal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const FoodCategories = () => {
  const classes = useStyles();
  const translation = useTranslation();

  const [modalMode, setModalMode] = useState('create');

  const [creationModalIsOpen, setCreationModalIsOpen] = useState(false);

  useFirestoreConnect({
    collection: COLLECTIONS.CATEGORIES
  });
  const categories = useSelector(
    state => state.firestore.ordered.categories || []
  );

  return (
    <Page className={classes.root} title={translation('category_food.title')}>
      {!!creationModalIsOpen && (
        <CreateOrEditModal
          onClose={() => setCreationModalIsOpen(false)}
          open={creationModalIsOpen}
          mode={modalMode}
        />
      )}
      <Container maxWidth={false}>
        <>
          <Header
            onAddClick={() => {
              setModalMode('create');
              setCreationModalIsOpen(true);
            }}
          />
          <CategoriesFoodList
            onEdit={category => {
              setModalMode('edit');
              setCreationModalIsOpen(category);
            }}
            categories={categories}
          />
        </>
      </Container>
    </Page>
  );
};

export default FoodCategories;
