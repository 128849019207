import { useState, useEffect } from 'react';
import { COLLECTIONS } from '../constants';
import firebase from '../config/firebaseConfig';
import MenuGallery from '../dto/menuGallery';

export const useMenuGalleryDocs = restId => {
  const [menuGallery, setMenuGallery] = useState([]);

  useEffect(() => {
    if (restId) {
      return firebase
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .collection(COLLECTIONS.MENU_GALLERY)
        .orderBy('created_at', 'asc')
        .onSnapshot(snap => {
          setMenuGallery(
            snap.docs.map(doc => new MenuGallery({ ...doc.data(), id: doc.id }))
          );
        });
    }
  }, [restId]);

  return menuGallery;
};
