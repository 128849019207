import React, { useState, useEffect } from 'react';
import {
  Container,
  makeStyles,
  Divider,
  CircularProgress,
  Box,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import RestaurantSettingsView from 'src/views/configurations/settingsView/RestaurantSettingsView';
import Page from '../../../components/Page';
import Header from './Header';
import { useRestaurant } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import SubsidiaryHeader from './SubsidiaryHeader';
import SubsidiariesList from './tabs/SubsidiariesList';
import Menu from './tabs/menu';
import RestaurantForm from './forms/RestaurantForm';
import LegalRepresentant from './forms/LegalRepresentantForm';
import Prices from './tabs/Prices';
import Workers from './tabs/Workers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const Restaurant = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { status, id } = match.params;
  const restaurant = useRestaurant(id);
  const [value, setValue] = useState(0);
  const translation = useTranslation();
  const allCities = useAllCities();
  let loading = false;
  const [banner, setBanner] = useState('');
  const [logo, setLogo] = useState('');

  if (!restaurant || restaurant.id !== id) {
    loading = true;
  }

  useEffect(() => {
    if (restaurant) {
      setBanner(`${restaurant?.banner_photo?.x400}#${moment()}`);
      setLogo(`${restaurant?.logo_photo?.x80}#${moment()}`);
    }
  }, [restaurant]);

  const updatePics = () => {
    setBanner(`${restaurant.banner_photo.original}#${moment()}`);
    setLogo(`${restaurant.logo_photo.x80}#${moment()}`);
  };

  const handleChange = (event, index) => {
    setValue(index);
  };

  const showRestaurantForms = () => (
    <Grid>
      <RestaurantForm
        shouldUpdate={updatePics}
        currentRestaurant={restaurant}
      />
      <LegalRepresentant ownerId={restaurant.owner_id} />
    </Grid>
  );

  const workerTab = () => {
    return <Workers restId={id} />;
  };

  const getTabContent = () => {
    switch (value) {
      case 0:
        return showRestaurantForms();
      case 1:
        return <SubsidiariesList restId={id} />;
      case 2:
        return <Menu restId={id} />;
      case 3:
        return (
          <RestaurantSettingsView
            currentRestaurant={restaurant}
            showButtonSave={false}
          />
        );
      case 4:
        return <Prices restId={id} />;
      case 5:
        return workerTab();
      default:
        console.log(' =( unhandle tab!');
        break;
    }
  };
  const getBreadCrumbLabel = () => {
    switch (status) {
      case 'active':
        return translation('admin_commerces.breadcrumbs.actives');
      case 'rejected':
        return translation('admin_commerces.breadcrumbs.rejected');
      case 'suspended':
        return translation('admin_commerces.breadcrumbs.suspended');
      case 'pending':
        return translation('admin_commerces.breadcrumbs.pending');
      case 'archived':
        return translation('admin_commerces.breadcrumbs.archived');
      default:
        break;
    }
  };

  return (
    <Page
      className={classes.root}
      title={translation('admin_commerces.title_page_commerce')}
    >
      <Container maxWidth={false}>
        {restaurant && (
          <Header
            path={[
              {
                link: `/admin/commerce/${status}`,
                label: getBreadCrumbLabel()
              },
              {
                link: `/admin/commerce/${status}/restaurant/${id}`,
                label: restaurant.name || id
              }
            ]}
          />
        )}
        <Divider style={{ marginTop: '10px' }} />
        {!restaurant && (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}
        {restaurant && !loading && (
          <>
            <SubsidiaryHeader
              bannerSrc={banner}
              logo={logo}
              name={restaurant.name}
              regStatus={restaurant.register_status}
            />
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={translation('admin_rest.tabs.gen_data')} />
                <Tab label={translation('admin_rest.tabs.res_sub_list')} />
                <Tab label={translation('admin_rest.tabs.menu')} />
                <Tab label={translation('admin_rest.tabs.settings')} />
                <Tab label={translation('admin_rest.tabs.prices')} />
                <Tab
                  label={translation('admin_subsidiary.tabs.employee_accounts')}
                />
              </Tabs>
            </AppBar>
            {allCities && (
              <Card>
                <CardContent>{getTabContent()}</CardContent>
              </Card>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default Restaurant;
