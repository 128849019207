import BankData from 'src/dto/bankData';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

export const createBankAccount = async fields => {
  const id = firebaseService.randomId(COLLECTIONS.BANK_DATA);

  const newAccount = new BankData({
    id,
    commerce_id: fields.commerce_id,
    ci_nit: fields.ciNit,
    bank: fields.bank,
    account_type: fields.accountType,
    account_number: Number(fields.accountNumber),
    owner_name: fields.ownerName
  });

  try {
    await admin
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .doc(fields.commerce_id)
      .collection(COLLECTIONS.BANK_DATA)
      .doc(id)
      .set({ ...newAccount });

    return newAccount;
  } catch (error) {
    console.log(error);
  }
};

export const editBankData = async (id, fields) => {
  try {
    const bankData = {
      commerce_id: fields.commerce_id,
      ci_nit: fields.ciNit,
      bank: fields.bank,
      account_type: fields.accountType,
      account_number: fields.accountNumber,
      owner_name: fields.ownerName,
      updated_at: admin.firestore.FieldValue.serverTimestamp()
    };
    await admin
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .doc(fields.commerce_id)
      .collection(COLLECTIONS.BANK_DATA)
      .doc(id)
      .update({ ...bankData });

    return fields;
  } catch (error) {
    console.log(error);
  }
};
