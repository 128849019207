import firebase from '../config/firebaseConfig';

class FirebaseService {
  isUserLoggedIn = () =>
    new Promise((resolve, reject) => {
      const stopListening = firebase.auth().onAuthStateChanged(user => {
        if (user) {
          const dbUser = this.getDbUser(user.uid);
          resolve(dbUser);
        } else {
          stopListening();
          reject(new Error('Unauthenticated'));
        }
      });
    });

  getDbUser = async uid => {
    const dbUserDoc = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get();

    return { ...dbUserDoc.data(), id: dbUserDoc.id };
  };

  loginWithEmailAndPassword = async (
    credentials = { email: '', password: '' }
  ) => {
    const UserCredentials = await firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password);

    const dbUser = await this.getDbUser(UserCredentials.user.uid);
    if (this.userIsAdmin(dbUser)) {
      return { ...dbUser, refreshToken: UserCredentials.user.refreshToken };
    }
    firebase.auth().signOut();
    throw new Error('Unauthorized');
  };

  userIsAdmin = (user = {}) =>
    !!Object.keys(user.roles).filter(role => user.roles[role]).length; // TODO: Check roles into future

  async getDocumentWithJoin(
    collection,
    docId,
    secondCollection,
    joinField,
    joinValue
  ) {
    const docRef = firebase
      .firestore()
      .collection(collection)
      .doc(docId);
    const doc = await docRef.get();
    const data = doc.data();
    const subCollectionSnapshot = await firebase
      .firestore()
      .collection(secondCollection)
      .where(joinField, '==', joinValue)
      .get();
    data[secondCollection] = this.mapDocsToData(subCollectionSnapshot);
    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  mapDocsToData(snapshot) {
    if (!snapshot) {
      return [];
    }
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  }

  async getCollection(collectionPath) {
    const collectionSnap = await firebase
      .firestore()
      .collection(collectionPath)
      .get();
    return this.mapDocsToData(collectionSnap);
  }

  // eslint-disable-next-line class-methods-use-this
  async getDoc(docPath) {
    const doc = await firebase
      .firestore()
      .doc(docPath)
      .get();
    return doc.data();
  }

  // eslint-disable-next-line class-methods-use-this
  async setDoc(docPath, data) {
    // eslint-disable-next-line no-return-await
    return await firebase
      .firestore()
      .doc(docPath)
      .set(data, { merge: true });
  }

  // eslint-disable-next-line class-methods-use-this
  async updateDoc(docPath, data) {
    // eslint-disable-next-line no-return-await
    return await firebase
      .firestore()
      .doc(docPath)
      .update(data);
  }

  // eslint-disable-next-line class-methods-use-this
  async signOut() {
    const userCurrentUid = firebase.auth().currentUser?.uid;
    if (userCurrentUid) {
      const dbUser = await this.getDbUser(userCurrentUid);
      let deviceId = dbUser.device_id;
      let token;
      try {
        token = await firebase.messaging().getToken();
      } catch (error) {
        token = false;
      }
      deviceId = deviceId?.filter(deviceid => deviceid !== token);
      await firebase
        .firestore()
        .collection('users')
        .doc(dbUser.id)
        .update({ device_id: deviceId || [] });
      firebase.auth().signOut();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  randomId(collection) {
    return firebase
      .firestore()
      .collection(collection || 'random')
      .doc().id;
  }

  passwordResetEmail = async emailAddress => {
    await firebase.auth().sendPasswordResetEmail(emailAddress);
  };

  loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const UserCredentials = await firebase.auth().signInWithRedirect(provider);
    const dbUser = await this.getDbUser(UserCredentials.user.uid);
    if (this.userIsAdmin(dbUser)) {
      return dbUser;
    }
    firebase.auth().signOut();
    throw new Error('Unauthorized');
  };

  loginWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    const UserCredentials = await firebase.auth().signInWithRedirect(provider);

    const dbUser = await this.getDbUser(UserCredentials.user.uid);
    if (this.userIsAdmin(dbUser)) {
      return dbUser;
    }
    firebase.auth().signOut();
    throw new Error('Unauthorized');
  };

  getDbRestaurant = async restId => {
    const dbRestDoc = await firebase
      .firestore()
      .collection('restaurants')
      .doc(restId)
      .get();

    return { ...dbRestDoc.data(), id: dbRestDoc.id };
  };
}
const firebaseService = new FirebaseService();

export default firebaseService;
