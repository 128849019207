/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useCurrentSubsidiaryFS } from 'src/hooks/Subsidiary/useCurrentSubsidiary';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from 'src/constants';
import { useCurrentSubsidiary } from 'src/hooks/useRestaurant';
import { useTranslation } from 'react-multi-lang';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { REGISTER_STATUS } from 'src/dto/enum';
import { useMasterSchedule } from '../../../hooks/AdminHooks/settings/useSettings';
import { setLogsRestaurant } from '../../../services/restaurantService';
import { TYPE_ACTION_LOGS, ACTION_LOGS } from '../../../dto/enum';
import { createMomentOnBolivianTime } from '../../../utils/date';
import { useSubsidiaryDeliveryDisabled } from '../../../hooks/useSubsidiaryDeliveryDisabled';

const useStyles = makeStyles(theme => ({
  label: {
    marginLeft: 0
  },
  labelTag: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.expressColors.cakeRed,
    width: '180px',
    marginTop: '15px',
    padding: 10
  },
  label_tag_delivery_closed: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.expressColors.gray,
    width: '180px',
    marginTop: '15px',
    padding: 10
  },
  labelTagMasterSchedule: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.expressColors.cakeRed,
    width: '250px',
    marginTop: '15px',
    padding: 10
  }
}));

function RecievingOrdersSwitch() {
  const classes = useStyles();
  const firestore = useFirestore();
  const { id } = useCurrentSubsidiary();
  const translate = useTranslation();
  const { receiving_orders } = useCurrentSubsidiaryFS(id);
  const currentSubsidiary = useCurrentSubsidiary();
  const registerStatus = useSelector(
    state => state.account.restaurant.register_status
  );
  const masterSchedule = useMasterSchedule();
  const { user } = useSelector(state => state.account);
  const { restaurant } = useSelector(state => state.account);
  const isDeliveryClosed = useSubsidiaryDeliveryDisabled(currentSubsidiary);

  const addLogsRestaurant = async receivingOrders => {
    const data = {
      created_at: new Date(),
      type_action: receivingOrders
        ? TYPE_ACTION_LOGS.OPEN
        : TYPE_ACTION_LOGS.CLOSE,
      from: 'MERCHANT',
      action: ACTION_LOGS.RECEIVING_ORDERS,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      },
      restaurant_id: restaurant.id,
      restaurant_name: restaurant.name,
      subsidiary_id: currentSubsidiary.id,
      subsidiary_name: currentSubsidiary.name
    };
    await setLogsRestaurant(data, restaurant.id);
  };

  function changeRecievingOrders() {
    firestore
      .collection(COLLECTIONS.SUBSIDIARY)
      .doc(id)
      .update({ receiving_orders: !receiving_orders });
    addLogsRestaurant(!receiving_orders);
  }

  const currentDay = createMomentOnBolivianTime()
    .locale('en')
    .format('dddd')
    .toUpperCase();

  const currentTime = createMomentOnBolivianTime().format('HH:mm');
  const isClosedForSchedule = () => {
    if (currentSubsidiary?.schedule.length > 0) {
      const daySchedule = currentSubsidiary.schedule.filter(
        item => item.journey === currentDay && item.open === true
      );
      if (daySchedule.length > 0) {
        let isClosed = true;
        const scheduleTime = daySchedule[0].schedule;
        for (let i = 0; i < scheduleTime.length; i++) {
          if (scheduleTime[i].start_time) {
            const startTime = createMomentOnBolivianTime(
              scheduleTime[i].start_time.seconds
                ? scheduleTime[i].start_time.toDate()
                : scheduleTime[i].start_time
            ).format('HH:mm');
            const endTime = createMomentOnBolivianTime(
              scheduleTime[i].end_time.seconds
                ? scheduleTime[i].end_time.toDate()
                : scheduleTime[i].end_time
            ).format('HH:mm');
            const isBetween = createMomentOnBolivianTime(
              currentTime,
              'HH:mm'
            ).isBetween(
              createMomentOnBolivianTime(startTime, 'HH:mm'),
              createMomentOnBolivianTime(endTime, 'HH:mm'),
              undefined,
              '[]'
            );
            if (isBetween) {
              isClosed = false;
              break;
            }
          }
        }
        return isClosed;
      }
      return true;
    }
    return true;
  };

  const isClosedForMasterSchedule = () => {
    if (masterSchedule && currentSubsidiary) {
      const citySchedule = masterSchedule.schedule_by_city.filter(
        c => c.city_id === currentSubsidiary.city.id
      );
      if (citySchedule.length > 0) {
        const daySchedule = citySchedule[0].schedule.filter(
          item => item.journey === currentDay && item.open === true
        );
        if (daySchedule.length > 0) {
          let isClosed = true;
          const scheduleTime = daySchedule[0].schedule;
          for (let i = 0; i < scheduleTime.length; i++) {
            if (scheduleTime[i].start_time) {
              const startTime = createMomentOnBolivianTime(
                scheduleTime[i].start_time.toDate()
              ).format('HH:mm');
              const endTime = createMomentOnBolivianTime(
                scheduleTime[i].end_time.toDate()
              ).format('HH:mm');
              const isBetween = createMomentOnBolivianTime(
                currentTime,
                'HH:mm'
              ).isBetween(
                createMomentOnBolivianTime(startTime, 'HH:mm'),
                createMomentOnBolivianTime(endTime, 'HH:mm'),
                undefined,
                '[]'
              );
              if (isBetween) {
                isClosed = false;
                break;
              }
            }
          }
          return isClosed;
        }
        return true;
      }
      return true;
    }
    return true;
  };

  return (
    <>
      {receiving_orders && isClosedForSchedule() && (
        <Box className={classes.labelTag}>
          <Typography style={{ fontWeight: 'bold' }}>
            {translate('subsidiary.label_tag')}
          </Typography>
        </Box>
      )}
      {isDeliveryClosed && (
        <Box className={classes.label_tag_delivery_closed}>
          <Typography style={{ fontWeight: 'bold' }}>
            {translate('subsidiary.label_tag_delivery_closed')}
          </Typography>
        </Box>
      )}
      {isClosedForMasterSchedule() && (
        <Box className={classes.labelTagMasterSchedule}>
          <Typography style={{ fontWeight: 'bold' }}>
            {translate('subsidiary.label_tag_master_schedule')}
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" mt={1}>
        <FormControlLabel
          className={classes.label}
          label={
            <Typography variant="h6">
              {translate('receiving_orders')}:
            </Typography>
          }
          labelPlacement="start"
          control={
            <Switch
              checked={!!receiving_orders}
              onChange={changeRecievingOrders}
            />
          }
          disabled={
            registerStatus === REGISTER_STATUS.SUSPENDED ||
            isClosedForMasterSchedule()
          }
        />
      </Box>
      {registerStatus === REGISTER_STATUS.SUSPENDED && (
        <Typography variant="body2" color="primary">
          {translate('admin_commerces.suspended_rest')}
        </Typography>
      )}
    </>
  );
}

export default RecievingOrdersSwitch;
