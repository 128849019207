import React from 'react';
import ModalTemplateCustomHeader from '../../Modal/ModalTemplateCustomHeader';
import ImagePicker from './ImagePicker';

const ImagePickerModal = ({ show, onClose, onPick, images, label }) => {
  return (
    <ModalTemplateCustomHeader show={show} onClose={onClose} label={label}>
      <ImagePicker onClose={onClose} onPick={onPick} images={images} />
    </ModalTemplateCustomHeader>
  );
};

export default ImagePickerModal;
