import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { useAcceptedStyles } from './styles';
import { ORDER_TYPE } from '../../../../dto/enum';

const Accepted = props => {
  const {
    orderType,
    orderReady,
    orderReadyForDelivery,
    showDriverNotification,
    timePassed,
    setAdditionalMinutesOpen,
    isDisabled,
    rejectOrderConfim,
    orderWithDestination
  } = props;
  const classes = useAcceptedStyles();
  const translation = useTranslation();

  return (
    <Box>
      <Box className={classes.buttonContainer}>
        <Box className={classes.buttonWrapper}>
          <Box>
            <Button
              variant="contained"
              className={clsx(classes.button, classes.rejectButton)}
              style={{ marginRight: '15px' }}
              onClick={rejectOrderConfim}
            >
              <Typography className={classes.btnText}>
                {translation('orders.details.new_order.cancel')}
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button
              className={clsx(classes.button, classes.delayProd)}
              onClick={() => setAdditionalMinutesOpen(true)}
              disabled={orderWithDestination}
            >
              {translation('orders.details.accepted.delay')}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={
                orderType === ORDER_TYPE.PICK_UP ||
                orderType === ORDER_TYPE.IN_COMMERCE
                  ? orderReady
                  : orderReadyForDelivery
              }
              className={classes.button}
              disabled={isDisabled}
            >
              {timePassed &&
              (orderType === ORDER_TYPE.PICK_UP ||
                orderType === ORDER_TYPE.IN_COMMERCE)
                ? translation('orders.details.accepted.ready')
                : translation('orders.details.accepted.ready')}
            </Button>
            {showDriverNotification && (
              <Typography className={classes.btnTextDriver} align="center">
                DRIVER NOTIFICADO
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Accepted;
