import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import useAdminNotifications from 'src/hooks/useAdminNotifications';
import Account from '../DashboardLayout/TopBar/Account';
import Notifications from '../DashboardLayout/TopBar/Notifications';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 100
  },
  toolbar: {
    height: 64
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium
  },
  logo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxHeight: 60
  }
}));

function TopBar({ onMobileNavOpen }) {
  const classes = useStyles();

  useAdminNotifications();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/admin">
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />

        <Box ml={2}>
          <Notifications />
        </Box>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
