/* global google */
import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Circle
} from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import demoFancyMapStyles from './demoFancyMapStyles';
import env from '../../env';

// eslint-disable-next-line no-unused-vars
function Map({ center, markers, radius, ...props }) {
  const markerIcon = {
    url: '/static/images/icons/locationPin.png',
    size: { width: 40, height: 40 },
    scaledSize: new google.maps.Size(40, 40)
  };

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={center}
      center={center}
      defaultOptions={{ styles: demoFancyMapStyles }}
    >
      {markers.map((m, index) => (
        <Marker
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          position={m}
          options={{
            icon: markerIcon
          }}
        />
      ))}
      {center && (
        <Circle
          center={center}
          radius={radius}
          options={{
            strokeColor: '#0000FF',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillOpacity: 0
          }}
        />
      )}
    </GoogleMap>
  );
}

export const MapWrapped = withScriptjs(withGoogleMap(Map));

const useStyles = makeStyles(theme => ({
  mapResponsiveContainer: {
    height: 750,
    [theme.breakpoints.down('md')]: {
      height: 400
    }
  }
}));

const MapViewer = ({ markers = [], center, radius }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.mapResponsiveContainer)}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        center={center}
        markers={markers}
        radius={radius}
      />
    </div>
  );
};

MapViewer.propTypes = {
  center: PropTypes.object,
  radius: PropTypes.number
};

export default MapViewer;
