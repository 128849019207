import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  backIcon: {
    marginRight: theme.spacing(1)
  },
  rightHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

function Header({ city, className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/areas"
            component={RouterLink}
          >
            {translation('areas.title')}
          </Link>
          <Link variant="body1" color="inherit" to="#" component={RouterLink}>
            Ciudad
          </Link>
        </Breadcrumbs>

        <Typography variant="h3" color="textPrimary" display="inline">
          {city.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default Header;
