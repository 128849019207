/* eslint-disable react/no-unused-prop-types */
/* global google */
import React from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Circle
} from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import demoFancyMapStyles from './demoFancyMapStyles';
import env from '../../env';

function Map({ center, marker, radius, ...props }) {
  const markerIcon = {
    url: '/static/images/icons/locationPin.png',
    size: { width: 40, height: 40 },
    scaledSize: new google.maps.Size(40, 40)
  };
  const mapClick = e => {
    props.setPicker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
  };

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={center}
      defaultOptions={{ styles: demoFancyMapStyles }}
      center={marker || center}
      onClick={mapClick}
    >
      {marker && marker.lat && (
        <>
          <Marker
            position={marker}
            options={{
              icon: markerIcon
            }}
          />
          <Circle
            center={marker}
            radius={radius}
            options={{
              strokeColor: '#0000FF',
              strokeOpacity: 0.5,
              strokeWeight: 1,
              fillOpacity: 0
            }}
            onClick={mapClick}
          />
        </>
      )}
    </GoogleMap>
  );
}

export const MapWrapped = withScriptjs(withGoogleMap(Map));

const useStyles = makeStyles(theme => ({
  mapResponsiveContainer: {
    height: 750,
    [theme.breakpoints.down('md')]: {
      height: 400
    }
  }
}));

const MapBinder = ({ setPicker = () => {}, marker, center, radius }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.mapResponsiveContainer)}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        center={center}
        marker={marker}
        setPicker={setPicker}
        radius={radius}
      />
    </div>
  );
};

MapBinder.propTypes = {
  open: PropTypes.bool,
  setShowMap: PropTypes.func,
  center: PropTypes.object,
  setPicker: PropTypes.func,
  radius: PropTypes.number
};

export default MapBinder;
