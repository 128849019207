import React from 'react';
import ReactExport from 'react-export-excel';
import { Button } from '@material-ui/core';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const ExportToExcel = props => {
  const { data, sheetName, buttonLabel, disabled } = props;

  return (
    <ExcelFile
      filename={sheetName}
      element={
        <Button disabled={disabled} color="secondary" variant="contained">
          {buttonLabel}
        </Button>
      }
    >
      <ExcelSheet dataSet={data()} name={sheetName} />
    </ExcelFile>
  );
};

export default ExportToExcel;
