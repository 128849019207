import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Typography, Link, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RecievingOrdersSwitchSubMerged from './RecievingOrdersSwitchSubMerged';
import Story from '../../TopBar/story';

const useStyles = makeStyles(theme => ({
  subsidiary: {
    marginRight: '4px',
    borderRadius: 10
  },
  avatar: {
    width: 64,
    height: 64,
    borderRadius: 10
  },
  restaurantName: {
    margin: theme.spacing(1)
  }
}));

const SubsidiaryMergueOptions = ({ subsidiaryId, restaurantId }) => {
  const classes = useStyles();
  const translate = useTranslation();
  const subsidiariesMerged = useSelector(
    state => state.account.subsidiariesMerged
  );
  const allRestaurantsMerged = useSelector(
    state => state.account.allRestaurantsMerged
  );
  const subsidiary = subsidiariesMerged.find(sub => sub.id === subsidiaryId);
  const restaurant = allRestaurantsMerged.find(
    rest => rest.id === restaurantId
  );

  if (!restaurant && !subsidiary) return null;

  return (
    <>
      {restaurant && subsidiary && (
        <>
          <Divider />
          <Box m={2}>
            <Box display="flex" alignItems="center">
              <RouterLink to={`/app/configuration/${restaurant.id}`}>
                <Avatar
                  alt="Restaurant"
                  variant="rounded"
                  className={classes.avatar}
                  src={
                    restaurant.logo_photo.x400
                      ? restaurant.logo_photo.x400
                      : restaurant.logo_photo.original
                  }
                />
              </RouterLink>
              <Link
                component={RouterLink}
                to={`/app/configuration/${restaurant.id}`}
                variant="h5"
                color="textPrimary"
                underline="none"
                className={classes.restaurantName}
              >
                {restaurant.name}
              </Link>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <Typography variant="h6">
                {translate('menu.restaurantOptions.subsidiary')}
              </Typography>
              <Box ml={1}>
                <Typography variant="body2" color="textPrimary">
                  {subsidiary.name ? subsidiary.name : subsidiary.address}
                </Typography>
              </Box>
            </Box>
            <RecievingOrdersSwitchSubMerged subsidiary={subsidiary} />
            <Story
              name={`${restaurant.name} - ${subsidiary?.name}` || ''}
              photo={restaurant?.logo_photo}
              city={subsidiary?.city?.name || ''}
              phoneNumber={
                subsidiary?.legal_representant?.phone ||
                subsidiary?.manager_phone ||
                subsidiary?.phone_number
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SubsidiaryMergueOptions;
