import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDrag, useDrop } from 'react-dnd';
import { SixDotsButton } from '../Inputs/Buttons';
import { COLORS } from '../../theme/colors';

const useClasses = makeStyles(() => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 10,
    padding: 8,
    '&:hover': {
      background: COLORS.primary,
      cursor: 'pointer'
    }
  },
  dataContainer: {
    width: '73vw'
  }
}));

export default function DndListItem({
  renderItem,
  dndType,
  index,
  onCancelDrop,
  moveItem,
  onDropItem,
  menu
}) {
  const classes = useClasses();

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: dndType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop(item) {
      onDropItem(item);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: dndType, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        onCancelDrop();
      }
    }
  });
  const opacity = isDragging ? 0 : 1;

  preview(drop(ref));

  return (
    <div ref={ref} className={classes.itemContainer} style={{ opacity }}>
      <div ref={drag}>
        <SixDotsButton style={{ cursor: 'grab' }} />
      </div>
      <div className={menu ? classes.dataContainer : null}>{renderItem}</div>
    </div>
  );
}
