import React, { useState } from 'react';
import { Card, Box, TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useCommentsStyles } from '../styles';
import { updateOrder } from '../../../../../services/orderServices';
import WithLoading from '../../../../../components/WithLoading';

const BoxLoading = WithLoading(Box);

const Comments = ({ orderId, orderComment }) => {
  const [comment, setComment] = useState(orderComment);
  const [loading, setLoading] = useState(false);
  const translation = useTranslation();
  const classes = useCommentsStyles();

  const saveComment = async () => {
    setLoading(true);
    await updateOrder(orderId, { admin_comment: comment });
    setLoading(false);
  };

  return (
    <Card className={classes.container}>
      <BoxLoading isLoading={loading} className={classes.content}>
        <TextField
          placeholder={translation('orders.comments')}
          value={comment}
          fullWidth
          multiline
          label={translation('orders.comments')}
          onChange={e => setComment(e.target.value)}
          variant="outlined"
        />
        <Button
          className={classes.saveButton}
          variant="contained"
          color="secondary"
          onClick={saveComment}
        >
          {translation(`${orderComment ? 'edit' : 'save'}`)}
        </Button>
      </BoxLoading>
    </Card>
  );
};

export default Comments;
