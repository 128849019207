import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import wait from 'src/utils/wait';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Notifications({ className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();

  const handleSubmit = async event => {
    event.preventDefault();
    // Make API request
    await wait(500);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title={translation('account.notification')} />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6" color="textPrimary">
                {translation('notification.sub_title')}
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                {translation('notification.description')}
              </Typography>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={translation('notification.checkbox.email')}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox />}
                  label={translation('notification.checkbox.push')}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={translation('notification.checkbox.message')}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={
                    <>
                      <Typography variant="body1" color="textPrimary">
                        {translation('notification.checkbox.phone')}
                      </Typography>
                      <Typography variant="caption">
                        {translation('notification.checkbox.message_phone')}
                      </Typography>
                    </>
                  }
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6" color="textPrimary">
                {translation('notification.chat.sub_title')}
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary">
                {translation('notification.chat.description')}
              </Typography>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={translation('login.email.label')}
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={translation('notification.checkbox.push')}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button color="secondary" type="submit" variant="contained">
            {translation('account.save_setting')}
          </Button>
        </Box>
      </Card>
    </form>
  );
}

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
