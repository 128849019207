import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Cards from './Cards';
// import { useAllSubsidiaries } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { useAllRestaurants } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import {
  useAllOrdersPerRestaurant,
  useAllUsersPerRestaurant
} from './CardsQuery';
import { ORDER_STATUS } from '../../../../dto/enum';

const DashboardCard = () => {
  const translation = useTranslation();
  // const subsidiaries = useAllSubsidiaries(); // TODO: To implement
  const restaurants = useAllRestaurants();
  const orders = useAllOrdersPerRestaurant();
  const users = useAllUsersPerRestaurant();
  let pendingRestaurantsAmount = 0;
  let activeRestaurantsAmount = 0;
  let totalOrdersAmount = 0;
  let currencyOrdersAmount = 0;
  let completedOrdersAmount = 0;
  let numberUsersAmount = 0;
  // let canceledOrdersAmount = 0; // TODO: To implement

  if (restaurants) {
    activeRestaurantsAmount = restaurants.filter(
      restaurant => restaurant.register_status === 'ACTIVE'
    ).length;

    pendingRestaurantsAmount = restaurants.filter(
      item =>
        item.pendings && (item.pendings.docs || item.pendings.subsidiaries)
    ).length;
  }

  /* if (subsidiaries) {
    subsidiaries.forEach(sub => {
      switch (sub.register_status) {
        case 'ACTIVE':
          activeRestaurants++;
          break;
        default:
          break;
      }
    });
  } */

  if (orders) {
    totalOrdersAmount = orders.length;
    orders.forEach(order => {
      switch (order.status) {
        case ORDER_STATUS.COMPLETED:
          completedOrdersAmount++;
          break;
        case ORDER_STATUS.ACCEPTED:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.IN_PROCESS:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.CHANGES_ACCEPTED:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.NEED_CHANGES:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.READY_TO_CASHIER:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.READY_TO_PICK:
          currencyOrdersAmount++;
          break;
        case ORDER_STATUS.CANCELED:
          // canceledOrdersAmount++;
          break;
        default:
          break;
      }
    });
  }

  if (users) {
    numberUsersAmount = users.length;
  }

  return (
    <Grid container>
      <Box m={4}>
        <Cards
          title={translation('dashboard_admin.cards.total')}
          quantity={totalOrdersAmount}
        />
      </Box>
      <Box m={4}>
        <Cards
          title={translation('dashboard_admin.cards.current')}
          quantity={currencyOrdersAmount}
        />
      </Box>
      <Box m={4}>
        <Cards
          title={translation('dashboard_admin.cards.completed')}
          quantity={completedOrdersAmount}
        />
      </Box>
      <Box m={4}>
        <Cards
          title={translation('admin_commerces.titles.active_r')}
          quantity={activeRestaurantsAmount}
        />
      </Box>
      <Box m={4}>
        <Cards
          title={translation('admin_commerces.titles.pending_r')}
          quantity={pendingRestaurantsAmount}
        />
      </Box>
      <Box m={4}>
        <Cards
          title={translation('dashboard_admin.cards.users')}
          quantity={numberUsersAmount}
        />
      </Box>
    </Grid>
  );
};

export default DashboardCard;
