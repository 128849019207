/* eslint-disable */
import React from 'react';
import {
  makeStyles,
  FormControlLabel,
  Select,
  MenuItem,
  FormLabel,
  IconButton
} from '@material-ui/core';
import { XCircle, PlusCircle, ChevronDown } from 'react-feather';
import { generateDayHours } from 'src/utils/date';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang/lib';
import { COLORS } from '../theme/colors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  control: {},
  select: {
    minWidth: 80,
    margin: '0 8px',
    '&:before': {
      borderColor: COLORS.success,
      borderWidth: 2
    },
    '&:after': {
      borderColor: COLORS.success,
      borderWidth: 2
    }
  },
  option: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    color: COLORS.successDark
  },
  iconDanger: {
    color: COLORS.dangerDark
  },
  add: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const dayHours = generateDayHours(15);

function ScheduleTimePicker({
  from,
  to,
  onChangeFrom = () => {},
  onChangeTo = () => {},
  onIconPress = () => {},
  onAddPress = () => {},
  disabled,
  read
}) {
  const classes = useStyles();
  const translation = useTranslation();

  if (!from && !to) {
    return (
      <div className={classes.container}>
        <div className={classes.add}>
          <FormLabel>{disabled?'----': translation('schedule.journey')}</FormLabel>
          {!read&& <IconButton disabled={disabled} onClick={onAddPress}>
            <PlusCircle className={classes.icon} />
          </IconButton>}
        </div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.control}
        control={
          <Select
            IconComponent={() => <ChevronDown/>}
            value={from.format('HH:mm')}
            classes={{ selectMenu: classes.option }}
            className={classes.select}
            color="primary"
            onChange={onChangeFrom}
            disabled={disabled}
          >
            {dayHours.map(hourDate => (
              <MenuItem
                key={hourDate.format('HH:mm')}
                value={hourDate.format('HH:mm')}
                className={classes.option}
              >
                {hourDate.format('HH:mm')}
              </MenuItem>
            ))}
          </Select>
        }
        label={translation('schedule.from')}
        labelPlacement="start"
      />
      <FormControlLabel
        className={classes.control}
        control={
          <Select
            IconComponent={() => <ChevronDown/>}
            onChange={onChangeTo}
            value={to.format('HH:mm')}
            classes={{ selectMenu: classes.option }}
            className={classes.select}
            color="primary"
            disabled={disabled}
          >
            {dayHours.map(
              hourDate =>
                !moment(hourDate).isBefore(moment(from).add(30, 'minutes')) && (
                  <MenuItem
                    key={hourDate.format('HH:mm')}
                    value={hourDate.format('HH:mm')}
                    className={classes.option}
                  >
                    {hourDate.format('HH:mm')}
                  </MenuItem>
                )
            )}
          </Select>
        }
        label={translation('schedule.to')}
        labelPlacement="start"
      />
      {!read&& <IconButton disabled={disabled} onClick={onIconPress}>
        <XCircle className={classes.iconDanger} />
      </IconButton>}
    </div>
  );
}

export default ScheduleTimePicker;
