import React from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ path, currentTab, className, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslation();
  const history = useHistory();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Typography variant="h3" color="textPrimary">
          {translate('admin_promos.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Box
          style={{ height: '100%' }}
          display="flex"
          flexWrap="wrap"
          alignContent="flex-end"
        >
          <Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                if (currentTab === 'promos') {
                  history.push('/admin/promos/new');
                } else {
                  history.push('/admin/promos_banner/new');
                }
              }}
            >
              {currentTab === 'promos'
                ? translate('admin_promos.new')
                : 'CREAR PROMO BANNER'}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
