import React, { useCallback, useEffect, useState } from 'react';
import { swap } from 'react-grid-dnd';
import DndListItem from './DndListItem';

export default function DndList({
  items = [],
  renderItem,
  keyGenerator = () => null,
  dndType,
  onDrop,
  menu
}) {
  const [itemList, setItemList] = useState(items);
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const nextState = swap([...itemList], dragIndex, hoverIndex);
      setItemList(nextState);
    },
    [itemList]
  );

  useEffect(() => {
    setItemList(items);
  }, [items]);

  const onCancelDrop = () => {
    setItemList(items);
  };
  return (
    <div>
      {itemList.map((item, index) => (
        <DndListItem
          key={keyGenerator(item)}
          index={index}
          moveItem={moveItem}
          renderItem={renderItem(item, index)}
          dndType={dndType}
          onDropItem={() => {
            onDrop(itemList);
          }}
          onCancelDrop={onCancelDrop}
          menu={menu}
        />
      ))}
    </div>
  );
}
