import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import firebaseService from '../../../services/authService';

const useStyles = makeStyles(() => ({
  root: {},
  helper: {
    color: 'green'
  }
}));

function ResetForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        send: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(translation('reset.email.valid'))
          .max(255)
          .required(translation('reset.email.require'))
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, setFieldValue }
      ) => {
        try {
          await firebaseService.passwordResetEmail(values.email);
          setFieldValue('send', true);
        } catch (error) {
          const message =
            error.code && error.message
              ? translation('reset.error_reset')
              : translation('reset.without_authorize');
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label={`${translation('reset.email.label')}:`}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {translation('reset.send')}
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            {values.send && (
              <Box mt={3}>
                <FormHelperText className={classes.helper}>
                  {translation('reset.sendEmail')}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

ResetForm.propTypes = {
  className: PropTypes.string
};

export default ResetForm;
