// eslint-disable-next-line max-classes-per-file
class SubscriptionChargesBase {
  constructor({ price = 0 } = {}) {
    this.price = price;
  }

  toPlainObject() {
    return { ...this };
  }
}

export class XpressCharges extends SubscriptionChargesBase {
  constructor({ PICK_UP = false, DELIVERY = false, ...data } = {}) {
    super(data);
    this.PICK_UP = PICK_UP;
    this.DELIVERY = DELIVERY;
  }
}

export class EntregasCharges extends SubscriptionChargesBase {
  constructor({ ENTREGAS = false, ...data } = {}) {
    super(data);
    this.ENTREGAS = ENTREGAS;
  }
}

export default class SubscriptionDetails {
  constructor({
    xpress = new XpressCharges(),
    entregas = new EntregasCharges(),
    months = 0
  } = {}) {
    this.xpress = new XpressCharges(xpress);
    this.entregas = new EntregasCharges(entregas);
    this.months = months;
  }

  toPlainObject() {
    return {
      ...this,
      xpress: this.xpress.toPlainObject(),
      entregas: this.entregas.toPlainObject()
    };
  }
}
