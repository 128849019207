/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_CHATS } from '../actions/chatActions';

const initialState = {
  chat: []
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS: {
      const { chats } = action.payload;
      return produce(state, draft => {
        draft.chat = [...chats] || state.chat;
      });
    }
    default:
      return state;
  }
};

export default chatReducer;
