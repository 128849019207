/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-multi-lang';
import clsx from 'clsx';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import * as Yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  basicInfo: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    width: '100%'
  },
  basicInfoForm: {
    maxWidth: '500px',
    width: '100%',
    backgroundColor: 'transparent',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent
    }
  },
  title: {
    paddingBottom: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  card: {
    maxWidth: '865px',
    width: '100%',
    maxHeight: '750px',
    height: '100%',
    position: 'relative',
    textAlign: '-webkit-center'
  },
  background: {
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
    background: '#ffffff',
    height: '100%',
    width: '100%'
  },
  formPosition: {
    position: 'absolute',
    padding: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrayIcon: {
    zIndex: 4,
    cursor: 'pointer',
    position: 'absolute',
    color: '#56B19E',
    top: 45,
    left: 45
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  title_email: {
    paddingBottom: 10,
    textAlign: 'center'
  },
  legalRepresentative: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  eye: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const NewPassword = React.memo(
  ({ className, email, handleNext, updateUser = () => {}, handleBack }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const translation = useTranslation();
    return (
      <div className={clsx(classes.root, className)}>
        <div className={clsx(classes.basicInfo)}>
          <Box className={classes.card}>
            <Box className={classes.background} />
            <ArrowBack onClick={handleBack} className={classes.arrayIcon} />
            <Box className={classes.formPosition}>
              <Box p={2} className={classes.basicInfoForm}>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={clsx(classes.title)}
                >
                  {translation('new_password.title')}
                </Typography>
                <Typography variant="h3" className={clsx(classes.title_email)}>
                  {email}
                </Typography>
                <Formik
                  initialValues={{
                    new_password: '',
                    confirm_password: '',
                    email
                  }}
                  validationSchema={Yup.object().shape({
                    new_password: Yup.string()
                      .required(
                        translation(
                          'new_password.error_messages.password_require'
                        )
                      )
                      .matches(
                        `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!\\"\\'<>\\[\\]{}%*?:;&.,#+\\/~_])[A-Za-z\\d$@$!\\"\\'<>\\[\\]{}%*?;:#&.,+\\/~_]{8,}$`,
                        translation(
                          'new_password.error_messages.password_error_validation'
                        )
                      ),
                    confirm_password: Yup.string()
                      .required()
                      .oneOf(
                        [Yup.ref('new_password'), null],
                        translation(
                          'new_password.error_messages.password_must_match'
                        )
                      ),
                    email: Yup.string()
                      .email(translation('login.email.valid'))
                      .max(255)
                      .required(translation('login.email.require'))
                  })}
                  onSubmit={async (
                    // eslint-disable-next-line no-shadow
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      updateUser({
                        password: values.new_password,
                        email: values.email
                      });
                      handleNext();
                    } catch (error) {
                      setStatus({ success: false });
                      setErrors({ submit: error.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    // eslint-disable-next-line no-shadow
                    values
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={translation(
                          'basic_information.personal_info.email'
                        )}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.email}
                        variant="outlined"
                      />
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: '8px' }}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          {translation('new_password.new_password')}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          error={Boolean(
                            touched.new_password && errors.new_password
                          )}
                          fullWidth
                          helperText={
                            touched.new_password && errors.new_password
                          }
                          label={translation('new_password.new_password')}
                          margin="normal"
                          name="new_password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment
                              onClick={() => setShowPassword(!showPassword)}
                              className={classes.eye}
                              position="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </InputAdornment>
                          }
                          type={showPassword ? 'text' : 'password'}
                          value={values.new_password}
                        />
                        {Boolean(
                          touched.new_password && errors.new_password
                        ) && (
                          <FormHelperText error>
                            {`${translation(
                              'new_password.error_messages.password_error_validation'
                            )}`}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">
                          {translation('new_password.new_password_confirm')}
                        </InputLabel>
                        <OutlinedInput
                          error={Boolean(
                            touched.confirm_password && errors.confirm_password
                          )}
                          fullWidth
                          helperText={
                            touched.confirm_password && errors.confirm_password
                          }
                          label={translation(
                            'new_password.new_password_confirm'
                          )}
                          margin="normal"
                          name="confirm_password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment
                              onClick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                              className={classes.eye}
                              position="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </InputAdornment>
                          }
                          type={showConfirmPassword ? 'text' : 'password'}
                          value={values.confirm_password}
                          variant="outlined"
                        />
                        {Boolean(
                          touched.confirm_password && errors.confirm_password
                        ) && (
                          <FormHelperText error>
                            {`${translation(
                              'new_password.error_messages.password_must_match'
                            )}`}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Box mt={2}>
                        <Button
                          color="secondary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {translation('next')}
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
);

export default NewPassword;
