import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDate } from '../utils/date';

const useProgressRealTime = (startDate, finishDate, setProgress, timeout) => {
  const clock = useSelector(store => store.deviceReducer.time);
  const progressCalcualtion = () => {
    const start = getDate(startDate).getTime();
    const end = getDate(finishDate).getTime();
    const middle = new Date().getTime();
    const total = end - start;
    const mark = middle - start;
    let pr = (mark * 100) / total;
    if (pr > 100) {
      pr = 100;
      if (timeout && typeof timeout === 'function') timeout();
    }
    if (pr <= 0) {
      pr = 0;
    }
    setProgress(pr);
  };
  const tick = () => {
    progressCalcualtion();
  };
  useEffect(() => {
    tick();
    // eslint-disable-next-line
  }, [startDate, finishDate, clock]);
};

export default useProgressRealTime;
