import _ from 'lodash';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import { INVOICE_STATUS } from '../dto/enum';

const filteredInvoices = async ({
  status,
  year,
  month,
  subsidiary,
  restaurant,
  city
}) => {
  let response = {
    error: true,
    message: 'invalid filter',
    payload: []
  };

  if (!city || !subsidiary || !restaurant || !year || !month) return response;

  const fromTimeStamp = new Date(`${year}-${month}`);
  const plusMonth = `${month * 1 + 1}`;
  const toTimestamp = new Date(`${year}-0${plusMonth}`);
  const statusArray = _.values(INVOICE_STATUS);

  if (subsidiary === 'all' && restaurant === 'all') {
    const invoices = await admin
      .firestore()
      .collection(COLLECTIONS.INVOICE)
      .orderBy('created_at')
      .startAt(fromTimeStamp)
      .endAt(toTimestamp)
      .where('subsidiary_city.id', '==', city)
      .where(
        'status',
        `${status !== 'all' ? '==' : 'in'}`,
        status !== 'all' ? status : statusArray
      )
      .get();

    response = {
      error: false,
      message: 'success',
      payload: invoices.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    };
  }

  if (subsidiary === 'all' && restaurant !== 'all') {
    const invoices = await admin
      .firestore()
      .collection(COLLECTIONS.INVOICE)
      .orderBy('created_at')
      .startAt(fromTimeStamp)
      .endAt(toTimestamp)
      .where('restaurant_id', '==', restaurant)
      .where(
        'status',
        `${status !== 'all' ? '==' : 'in'}`,
        status !== 'all' ? status : statusArray
      )
      .get();

    response = {
      error: false,
      message: 'success',
      payload: invoices.docs.map(doc => ({ ...doc.data() }))
    };
  }

  if (subsidiary !== 'all') {
    const invoices = await admin
      .firestore()
      .collection(COLLECTIONS.INVOICE)
      .orderBy('created_at')
      .startAt(fromTimeStamp)
      .endAt(toTimestamp)
      .where('subsidiary_id', '==', subsidiary)
      .where(
        'status',
        `${status !== 'all' ? '==' : 'in'}`,
        status !== 'all' ? status : statusArray
      )
      .get();

    response = {
      error: false,
      message: 'success',
      payload: invoices.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    };
  }

  return response;
};

export default filteredInvoices;
