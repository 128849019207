import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

const COLLECTION = COLLECTIONS.PROMOS_BANNER;
const { BANNER_ITEM } = COLLECTIONS;

class PromoBannerService {
  savePromoBannerDB = async (id, data) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({ ...data });

  createPromoBannerDb = async data => {
    const id = firebaseService.randomId(COLLECTION);
    await firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .set({ id, ...data });
    return id;
  };

  createPromoBannerItem = async (promoBannerItemid, data) => {
    const id = firebaseService.randomId(COLLECTION);
    await firebase
      .firestore()
      .collection(COLLECTION)
      .doc(promoBannerItemid)
      .collection(BANNER_ITEM)
      .doc(id)
      .set({ ...data, id });
    return id;
  };

  savePromoBannerItem = async (promoBannerItemid, id, data) => {
    await firebase
      .firestore()
      .collection(COLLECTION)
      .doc(promoBannerItemid)
      .collection(BANNER_ITEM)
      .doc(id)
      .update({ ...data, id });
    return id;
  };

  deletePromoBannerItem = async (promoBannerItemid, id) => {
    await firebase
      .firestore()
      .collection(COLLECTION)
      .doc(promoBannerItemid)
      .collection(BANNER_ITEM)
      .doc(id)
      .delete()
      // eslint-disable-next-line
      .then(() => {
        // eslint-disable-next-line
        console.log('Document successfully deleted!');
      })
      // eslint-disable-next-line
      .catch(error => {
        // eslint-disable-next-line
        console.error('Error removing document: ', error);
      });
  };

  getNewItem = () => {
    const id = firebaseService.randomId(BANNER_ITEM);
    return {
      id,
      newItem: true
    };
  };

  isUniquePromo = async (promo, id) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .get()
      .then(querySnapshot => {
        let repeated = false;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.promo_code === promo && data.id !== id) {
            repeated = true;
          }
        });
        return repeated;
      });

  saveFile = async (promoBannerId, itemId, type, file) => {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(
      `promo_banner/${promoBannerId}/${itemId}/${type}/${itemId}`
    );

    return new Promise((resolve, reject) => {
      const uploadTask = imageRef.put(file);
      uploadTask.on(
        'state_changed',
        () => {},
        // eslint-disable-next-line no-shadow
        error => {
          reject(error);
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });
  };

  getPromoBanner = async id =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .get()
      .then(doc => {
        return doc.data();
      });

  getPromoBannerItems = async id =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .collection(BANNER_ITEM)
      .get()
      .then(query => {
        const items = [];
        query.forEach(doc => items.push({ ...doc.data(), id: doc.id }));
        return items;
      });
}
const promoBannerService = new PromoBannerService();
export default promoBannerService;
