export function transformToCsv(rows) {
  let csvContent = '';
  rows.forEach(rowArray => {
    const row = rowArray.join(';').replace('#', '');
    csvContent += `${row}\r\n`;
  });
  return csvContent;
}

export default function exportToCsv(data, filename = 'report') {
  const csvString = transformToCsv(data);
  const header = 'data:text/csv;charset=utf-8,%EF%BB%BF';
  const encodedUri = encodeURI(csvString);
  const link = document.createElement('a');
  link.setAttribute('href', header + encodedUri);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
  document.body.removeChild(link);
}
