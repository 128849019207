import { useState, useEffect, useMemo } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../constants';
import admin from '../../../config/firebaseConfig';

export const useCurrentOwner = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      doc: id,
      storeAs: `current_owner`
    }
  ]);
  const currentOwner = useSelector(
    state => state.firestore.ordered.current_owner
  );
  if (currentOwner) return currentOwner[0];
  return null;
};

export const useUserAsLegalRepresentant = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      doc: id,
      storeAs: `legal_representant`
    }
  ]);
  const legalRepresentant = useSelector(
    state => state.firestore.data.legal_representant
  );

  if (!id) return null;
  return legalRepresentant;
};

export const useWorkersFormSubsidiary = subId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['subsidiary_id', 'array-contains', subId]],
      storeAs: `subsidiary_workers`
    }
  ]);
  const workers = useSelector(
    state => state.firestore.ordered.subsidiary_workers
  );
  return workers;
};

export const useWorkersFormRestaurant = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: ['restaurant_id', '==', restId],
      storeAs: `subsidiary_workers`
    }
  ]);
  const workers = useSelector(
    state => state.firestore.ordered.subsidiary_workers
  );
  return workers;
};

export const useSuperAdminUsers = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['roles.SUPER_ADMIN', '==', true]],
      storeAs: `super_admin_users`
    }
  ]);
  const adminUsers = useSelector(
    state => state.firestore.ordered.super_admin_users
  );
  if (!adminUsers) return [];
  return adminUsers;
};

export const useAccountantUsers = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['roles.ACCOUNTANT', '==', true]],
      storeAs: `accountant_users`
    }
  ]);
  const accountantUsers = useSelector(
    state => state.firestore.ordered.accountant_users
  );

  if (!accountantUsers) return [];
  return accountantUsers;
};

export const useCustomerServiceUsers = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['roles.CUSTOMER_SERVICE', '==', true]],
      storeAs: `customer_service`
    }
  ]);
  const customerService = useSelector(
    state => state.firestore.ordered.customer_service
  );

  if (!customerService) return [];
  return customerService;
};

export const useMarketingUsers = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.USER,
      where: [['roles.MARKETING', '==', true]],
      storeAs: `maketing_users`
    }
  ]);
  const customerService = useSelector(
    state => state.firestore.ordered.maketing_users
  );

  if (!customerService) return [];
  return customerService;
};

export const useAdminUsers = () => {
  const adminUsers = [
    ...useAccountantUsers(),
    ...useSuperAdminUsers(),
    ...useCustomerServiceUsers(),
    ...useMarketingUsers()
  ];
  return adminUsers.sort((a, b) => a.created_at - b.created_at);
};

export const useSuperAdminUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.SUPER_ADMIN', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};

export const useMarketingUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.MARKETING', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};
export const useAccountantUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.ACCOUNTANT', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};
export const useSalesUsersNative = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    return admin
      .firestore()
      .collection(COLLECTIONS.USER)
      .where('roles.SALES', '==', true)
      .onSnapshot(snap => {
        setResult(
          snap.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);
  return result;
};

export const useUserBusinessExecutive = () => {
  const salesServices = useSalesUsersNative();
  const marketingUsers = useMarketingUsersNative();
  const accountantUsers = useAccountantUsersNative();
  const superAdminUsers = useSuperAdminUsersNative();
  return useMemo(() => {
    if (marketingUsers && superAdminUsers && salesServices && accountantUsers) {
      return [
        ...marketingUsers,
        ...superAdminUsers,
        ...salesServices,
        ...accountantUsers
      ];
    }
  }, [marketingUsers, superAdminUsers, salesServices, accountantUsers]);
};
