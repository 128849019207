/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';
import { COLORS as colorsConstants } from './colors';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        defaultTransparent: 'rgba(255,255,255,0.9)',
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#E3BF12'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        defaultTransparent: 'rgba(40,44,52,0.9)',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#FFDF46'
      },
      secondary: {
        main: '#FFDF46'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.EXPRESS,
    typography: {
      fontFamily: 'Nunito sans'
    },
    breakpoints: {
      values: {
        xs: 375,
        sm: 1024,
        md: 1440,
        lg: 1920,
        xl: 1920
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#232323'
        // hover: 'rgba(255, 255, 255, 0.04)',
        // selected: 'rgba(255, 255, 255, 0.08)',
        // disabled: 'rgba(255, 255, 255, 0.26)',
        // disabledBackground: 'rgba(255, 255, 255, 0.12)',
        // focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#FFFFFF',
        defaultTransparent: 'rgba(255,255,255,0.9)',
        dark: '#F2F2F2',
        paper: '#FFFFFF',
        primary: '#FFDD39',
        secondary: '#FFEA85',
        bulk: '#A9A9A9',
        red: '#FFD5CC'
      },
      primary: {
        main: '#BF8D00'
      },
      secondary: {
        main: '#FFDF46',
        dark: '#BF8D00',
        light: '#BF8D00'
      },
      tertiary: {
        main: '#4E9788',
        background: '#B2E7D8',
        backgroundTransparent: 'rgba(178,231,216, 0.50)'
      },
      text: {
        primary: '#232323',
        secondary: '#232323',
        tertiary: '#707070'
      },
      expressColors: {
        green: '#56B19E',
        lightYellow: '#FFEA85',
        strongYellow: '#BF8D00',
        white: '#FFFFFF',
        black: '#000000',
        red: '#C23F25',
        lightRed: '#F75B3B',
        lighterRed: '#FFC8BD',
        gray: '#CCCCCC',
        primary: colorsConstants.primary,
        lightGreen: colorsConstants.lightGreen,
        cakeRed: colorsConstants.redCake,
        greenCake: colorsConstants.greenCake,
        yellowCake: colorsConstants.yellowCake,
        disabledGray: colorsConstants.disabledGray,
        strongGreen: colorsConstants.strongGreen,
        lightGray: colorsConstants.lightGray,
        ordersCockpitBackground: colorsConstants.ordersCockpitBackground,
        orderCockpitActiveCard: colorsConstants.orderCockpitActiveCard,
        orderCardGreenText: colorsConstants.orderCardGreenText,
        topbarButons: colorsConstants.topbarButons,
        lightBlue: colorsConstants.lightBlue,
        activeGreen: colorsConstants.activeGreen,
        hoverGreen: colorsConstants.hoverGreen,
        yellowLink: colorsConstants.yellowLink,
        lowLightGreen: colorsConstants.lowLightGreen,
        lowLightYellow: colorsConstants.lowLightYellow
      },
      modalBackground: {
        primary: '#1F6456',
        primaryDegradation: '#707070'
      },
      switch: {
        green: '#56B19E',
        secondary: '#FFEA85'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        defaultTransparent: 'rgba(42,45,61,0.9)',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#FFDF46'
      },
      secondary: {
        main: '#FFDF46'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
