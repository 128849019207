import React from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Select,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { createMomentOnBolivianTime } from 'src/utils/date';
import Page from '../../../components/Page';
import CardCustomers from './CardCustomers';
import CardCustomerImg from './CardCutomersImg';
import CardLevelBar from './CardLevelBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  verySlow: {
    width: '20%',
    backgroundColor: 'red'
  },
  slow: {
    width: '20%',
    backgroundColor: 'orange'
  },
  normal: {
    width: '20%',
    backgroundColor: 'yellow'
  },
  fast: {
    width: '20%',
    backgroundColor: 'lightgreen'
  },
  veryFast: {
    width: '20%',
    backgroundColor: 'green'
  }
}));

const AdvancedDashboard = ({
  handleMonth,
  month,
  handleYear,
  year,
  metrics,
  categories = [],
  enable_advanced_metrics
}) => {
  const classes = useStyles();
  const translation = useTranslation();

  const months = [
    { value: 'January', label: translation('months.january') },
    { value: 'February', label: translation('months.february') },
    { value: 'March', label: translation('months.march') },
    { value: 'April', label: translation('months.april') },
    { value: 'May', label: translation('months.may') },
    { value: 'June', label: translation('months.june') },
    { value: 'July', label: translation('months.july') },
    { value: 'August', label: translation('months.august') },
    { value: 'September', label: translation('months.september') },
    { value: 'October', label: translation('months.october') },
    { value: 'November', label: translation('months.november') },
    { value: 'December', label: translation('months.december') }
  ];

  const getYears = () => {
    const arr = [];
    const yearCurrent = createMomentOnBolivianTime().format('YYYY');
    for (let i = 2023; i <= yearCurrent * 1; i++) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };

  const years = getYears();

  const levelBar = type => {
    switch (type) {
      case 'VERY_SLOW':
        return classes.verySlow;
      case 'SLOW':
        return classes.slow;
      case 'NORMAL':
        return classes.normal;
      case 'FAST':
        return classes.fast;
      case 'VERY_FAST':
        return classes.veryFast;
      default:
        return classes.normal;
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      {enable_advanced_metrics && (
        <Container maxWidth={false} className={classes.container}>
          <Box display="flex" justifyContent="center">
            <Box width="386px" marginRight="20px">
              <Select
                variant="outlined"
                native
                style={{ backgroundColor: 'white' }}
                fullWidth
                value={month.value}
                onChange={e => handleMonth(e.target.value)}
              >
                {months.map(m => (
                  <option key={m.value} value={m.value}>
                    {m.label}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width="386px" marginLeft="20px">
              <Select
                variant="outlined"
                native
                style={{ backgroundColor: 'white' }}
                fullWidth
                value={year}
                onChange={e => handleYear(e.target.value)}
              >
                {years.map(y => (
                  <option key={y.value} value={y.value}>
                    {y.label}
                  </option>
                ))}
              </Select>
            </Box>
          </Box>
          {metrics && (
            <>
              <Box mt={2} key="orders">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h2" color="textPrimary">
                      {translation('dashboard.orders')}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardLevelBar
                      title={translation('customersMetric.preparation_time')}
                      data={{
                        value: metrics.cooked_average
                      }}
                      styleLevel={levelBar(metrics.cooked_average)}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.average_order_acceptance_time'
                      )}
                      data={{
                        value: `${Math.round(metrics.average_time_accepted) ||
                          0} mins`
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.average_distance_deliveries'
                      )}
                      data={{ value: `${metrics.delivery_distance || 0} m` }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.your_business_in_favorites'
                      )}
                      data={{ value: metrics.users_with_favorite || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.canceled_orders')}
                      data={{ value: metrics.canceled_orders_month || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.orders_accepted')}
                      data={{ value: metrics.completed_orders_month || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.orders_payment_type_card'
                      )}
                      data={{ value: metrics.orders_paid_with_card || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.orders_payment_type_qr'
                      )}
                      data={{ value: metrics.orders_paid_with_qr || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation(
                        'customersMetric.orders_payment_type_cash'
                      )}
                      data={{ value: metrics.orders_paid_with_cash || 0 }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} key="your_most_popular_dishes">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h2" color="textPrimary">
                      {translation('customersMetric.your_most_popular_dishes')}
                    </Typography>
                  </Grid>
                  {metrics.popular_products.length > 0 &&
                    metrics.popular_products.map(product => (
                      <Grid item lg={4} sm={4} xs={12}>
                        <CardCustomerImg
                          title="Nombre del producto:"
                          subTitle={product.name}
                          img={product.photo}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
              <Box mt={2} key="your clients">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h2" color="textPrimary">
                      {translation('customersMetric.customers')}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.customer_ratings')}
                      data={{ value: metrics.qualifications_clients || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.positive_ratings')}
                      data={{ value: metrics.positive_qualifications || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.negative_ratings')}
                      data={{ value: metrics.negative_qualifications || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.new')}
                      data={{ value: metrics.unique_clients || 0 }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <CardCustomers
                      title={translation('customersMetric.repeat')}
                      data={{ value: metrics.frequent_clients || 0 }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} key="your categories">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h2" color="textPrimary">
                      {translation('customersMetric.categories')}
                    </Typography>
                  </Grid>
                  {categories &&
                    categories.length > 0 &&
                    categories.map(
                      category =>
                        category.enable && (
                          <Grid item lg={4} sm={4} xs={12}>
                            <CardCustomerImg
                              subTitle={`Posición Número: ${metrics.categories[
                                category.id
                              ] || 'No cuenta ordenes completadas.'}`}
                              title={category.name}
                              img={
                                category?.img?.original ||
                                category?.img?.x40 ||
                                category?.img?.x400 ||
                                category?.img?.x80
                              }
                            />
                          </Grid>
                        )
                    )}
                </Grid>
              </Box>
            </>
          )}
          {!metrics && (
            <Box mt={2}>
              <Typography>No existen metricas para este mes.</Typography>
            </Box>
          )}
        </Container>
      )}
      {!enable_advanced_metrics && (
        <Container maxWidth={false} className={classes.container}>
          <Box display="flex" justifyContent="center" mt={3}>
            <Typography variant="h2">
              Esta sección es parte de un servicio adicional, el cual debe ser
              solicitado a la administración.
            </Typography>
          </Box>
        </Container>
      )}
    </Page>
  );
};

export default AdvancedDashboard;
