import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { COLLECTIONS } from '../../constants';

export const useAdditionByProductId = productId => {
  const [additions, setAdditions] = useState([]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(COLLECTIONS.PRODUCT)
      .doc(productId)
      .collection(COLLECTIONS.ADDITION)
      .where('enable', '==', true)
      .onSnapshot(data => {
        if (!data.empty) {
          setAdditions(
            data.docs
              .map(item => item.data())
              .filter(item => item.enable)
              .sort((a, b) => (a.order > b.order ? 1 : -1))
          );
        }
      });
    return () => {
      unsubscribe();
    };
  }, [productId]);
  return additions;
};
export const useAddition = productId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      doc: productId,
      subcollections: [{ collection: COLLECTIONS.ADDITION }],
      where: ['enable', '==', true],
      storeAs: 'all_additions'
    }
  ]);

  const allAdditions = useSelector(
    state => state.firestore.ordered.all_additions
  );
  const resultAllAddtions = useMemo(() => {
    if (allAdditions) {
      return allAdditions
        .filter(item => item.enable)
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    }
    return [];
  }, [allAdditions]);
  return resultAllAddtions;
};

export const usePrintAdditions = productId => {
  useFirestoreConnect(
    productId
      ? [
          {
            collection: COLLECTIONS.PRODUCT,
            doc: productId,
            subcollections: [{ collection: COLLECTIONS.ADDITION }],
            storeAs: `additions_${productId}`
          }
        ]
      : []
  );

  const all_additions = useSelector(
    state => state.firestore.ordered[`additions_${productId}`]
  );
  return all_additions;
};
