import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import { useTranslation } from 'react-multi-lang/lib';
import CustomSwitch from '../../../components/CustomSwitch';
import IconButton from '../../../components/Inputs/IconButton';
import Icons from '../../../assets/icons';
import additionService from '../../../services/additionsService';
import ModalTemplateCustomHeader from '../../../components/Modal/ModalTemplateCustomHeader';
import ModalCreateAddition from '../AdditionEditView/ModalCreateAddition';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  }
}));

const AdditionCard = ({ addition }) => {
  const classes = useStyles();
  const [avialability, setAvialability] = useState(false);
  const translation = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [showEditionModal, setShowEditionModal] = useState(false);

  useEffect(() => {
    setAvialability(addition.availability);
    // eslint-disable-next-line
  }, [addition]);

  const deleteAddition = async () => {
    await additionService.updateAddition(addition.id, {
      enable: false
    });
  };

  const availableChange = async availability => {
    await additionService.updateAddition(addition.id, {
      availability
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Box pl={1} className={classes.actions}>
            <Typography color="textSecondary" variant="h4">
              {addition.name}
            </Typography>
            <IconButton
              src={Icons.close}
              onClick={() => setShowDelete(true)}
              component={Icon}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Box p={2} className={classes.actions}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setShowEditionModal(true)}
            >
              {translation('additions.form.submit_edit')}
            </Button>
            <CustomSwitch
              avialability={avialability}
              setAvialability={setAvialability}
              onChange={availableChange}
            />
          </Box>
        </CardActions>
      </Card>
      <ModalTemplateCustomHeader
        show={showDelete}
        onClose={() => setShowDelete(false)}
        size="sm"
        label={translation('additions.delete.question')}
      >
        <Box display="flex" justifyContent="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={deleteAddition}
          >
            {translation('additions.delete.button')}
          </Button>
        </Box>
      </ModalTemplateCustomHeader>
      <ModalCreateAddition
        show={showEditionModal}
        onClose={() => setShowEditionModal(false)}
        addition={addition}
      />
    </>
  );
};

export default AdditionCard;
