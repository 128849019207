import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import { USER_STATUS } from '../../../../dto/enum';
import Label from '../../../../components/Label';

const Userstatus = ({ user }) => {
  const translation = useTranslation();
  let status = '';
  let statusColor = '';
  switch (user.status) {
    case USER_STATUS.ARCHIVED:
      status = translation('admin_users.status.archived');
      statusColor = 'error';
      break;
    case USER_STATUS.SUSPENDED:
      status = translation('admin_users.status.suspended');
      statusColor = 'warning';
      break;
    default:
      status = translation('admin_users.status.active');
      statusColor = 'success';
      break;
  }

  return (
    <Label color={statusColor}>
      <Typography variant="h4">
        {translation('admin_users.user')} {status}
      </Typography>
    </Label>
  );
};

export default Userstatus;
