import React, { useRef } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { exportComponentAsJPEG } from 'react-component-export-image';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    width: 480
  },
  avatar: {
    borderRadius: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 160
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center'
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  label: {
    background: '#79c2b0',
    color: theme.palette.common.white,
    fontWeight: '700',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  bold: {
    fontWeight: '700'
  },
  logo: {
    height: 70,
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 70
  },
  title: {
    fontSize: '1.5em',
    fontWeight: '900',
    letterSpacing: '0.1em',
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontWeight: '700',
    marginBottom: theme.spacing(2)
  }
}));

function ModalDealerCredential({ closeModal = () => {}, openModal, user }) {
  const componentRef = useRef();

  const classes = useStyles();

  const translation = useTranslation();

  const handleClose = () => {
    closeModal();
  };

  const image = user.profile_photo ? user.profile_photo.x400 : '';

  const firstName = user.first_name ? user.first_name : '';
  const lastName = user.last_name ? user.last_name : '';
  const credentialName = `${firstName
    .replace(' ', '_')
    .toLowerCase()}_${lastName.replace(' ', '_').toLowerCase()}.jpg`;

  // eslint-disable-next-line consistent-return
  const getLabel = status => {
    switch (status) {
      case 'APPLIED':
        return translation('admin_dealers.driver_aplication_status.applied');
      case 'ACCEPTED':
        return translation('admin_dealers.driver_credential_status.accepted');
      case 'REJECTED':
        return translation('admin_dealers.driver_aplication_status.rejected');
      case 'SUSPENDED':
        return translation('admin_dealers.driver_aplication_status.suspended');
      case 'EXPIRED_LICENSE':
        return translation(
          'admin_dealers.driver_aplication_status.expired_licence'
        );
      case 'EXPIRED_SOAT':
        return translation(
          'admin_dealers.driver_aplication_status.expired_soat'
        );
      default:
        break;
    }
  };

  const getType = option => {
    let type = '';
    switch (option) {
      case 'MOTO':
        type = translation('admin_dealers.type_transportation.motorcycle');
        break;
      case 'BICYCLE':
        type = translation('admin_dealers.type_transportation.bicycle');
        break;
      default:
        type = translation('admin_dealers.type_transportation.car');
        break;
    }
    return type;
  };

  return (
    <Dialog onClose={handleClose} open={openModal}>
      <Card className={classes.root}>
        <CardContent className={classes.content} ref={componentRef}>
          <Avatar
            className={classes.logo}
            src="/static/images/logoGreenBlack.png"
          />
          <Typography className={classes.title} variant="h2">
            XPRESS
          </Typography>
          <CardMedia
            alt="Avatar"
            className={classes.avatar}
            component="img"
            image={image}
            title="Avatar"
          />
          <Divider className={classes.divider} />
          <Typography className={classes.subtitle} variant="h2">
            {`${firstName} ${lastName}`}
          </Typography>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation(
                  'admin_dealers.driver_credential.vehicle_type'
                )}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? getType(user.driver_information.transportation_type)
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation(
                  'admin_dealers.driver_credential.identity_card_number'
                )}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? user.driver_information.ci_number
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation(
                  'admin_dealers.driver_credential.plate_number'
                )}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? user.driver_information.plate_number
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation('admin_dealers.driver_credential.brand')}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? user.driver_information.vehicle_manufacturer
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation('admin_dealers.driver_credential.model')}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? user.driver_information.vehicle_model
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="flex-end" container justify="center" spacing={1}>
            <Grid item>
              <Typography className={classes.bold} variant="h6">
                {`${translation('admin_dealers.driver_credential.year')}:`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {user.driver_information
                  ? user.driver_information.vehicle_year
                  : ''}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {user.driver_information && (
            <Typography className={classes.label} variant="h3">
              {getLabel(user.driver_information.status)}
            </Typography>
          )}
        </CardContent>
        {user.driver_information && (
          <CardActions>
            {user.driver_information.status === 'ACCEPTED' && (
              <Button
                fullWidth
                variant="text"
                onClick={() => {
                  return exportComponentAsJPEG(componentRef, credentialName);
                }}
              >
                {translation('admin_dealers.dealerEditView.export_credential')}
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Dialog>
  );
}

export default ModalDealerCredential;
