import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { swap } from 'react-grid-dnd';
import { makeStyles, Box, Grid, Typography, Checkbox } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import WithLoading from '../../../../components/WithLoading';
import additionService from '../../../../services/additionsService';
import { updateMenuAdditions } from '../../../../services/menuAdditionsService';

const BoxLoading = WithLoading(Box);

const useStyles = makeStyles(theme => ({
  option: {
    marginBottom: '0',
    borderBottom: `1px solid ${theme.palette.expressColors.lightGray}`,
    '&:last-child': {
      marginBottom: 0,
      borderBottom: `none`
    }
  },
  optionField: {
    textAlign: 'center',
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.expressColors.lightGray}`,
    '&:last-child': {
      borderRight: `none`
    }
  },
  optionFieldBox: {
    minWidth: '60px',
    display: 'inline-block',
    textAlign: 'left'
  },
  optionFieldBoxDragAddition: {
    minWidth: '60px',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center'
  },
  grabButtonDragAddition: {
    marginRight: theme.spacing(1)
  }
}));
const DragDropAdditionsItem = ({
  updateOption,
  restaurantAdditionOptions,
  addition,
  prodId,
  blockCashier,
  isTemplate,
  columnsSize,
  loading,
  setLoading
}) => {
  const classes = useStyles();

  const dragEnd = async result => {
    if (!result.destination) return;
    setLoading(true);
    console.log('drag');
    const swaped = swap(
      restaurantAdditionOptions,
      result.source.index,
      result.destination.index
    );
    if (isTemplate) {
      await additionService.updateAddition(addition.id, prodId, {
        options: swaped
      });
      await updateMenuAdditions(addition.menu_additions_id, {
        options: swaped
      });
    } else {
      await additionService.updateAddition(addition.id, prodId, {
        options: swaped
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppableItems">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {restaurantAdditionOptions.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {prov => (
                  <div
                    ref={prov.innerRef}
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                  >
                    <BoxLoading isLoading={loading}>
                      <Grid container key={item.id} className={classes.option}>
                        <Grid
                          item
                          xs={columnsSize}
                          className={classes.optionField}
                        >
                          <Box className={classes.optionFieldBoxDragAddition}>
                            <DragIndicatorIcon
                              fontSize="medium"
                              className={classes.grabButtonDragAddition}
                            />
                            <Typography>{item.name}</Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={columnsSize}
                          className={classes.optionField}
                        >
                          <Box className={classes.optionFieldBox}>
                            <Typography>
                              Bs.
                              {item.price}
                            </Typography>
                          </Box>
                        </Grid>
                        {!isTemplate && (
                          <Grid
                            item
                            xs={columnsSize}
                            className={classes.optionField}
                          >
                            <Box className={classes.optionFieldBox}>
                              <Checkbox
                                disabled={blockCashier}
                                checked={item.availability}
                                onChange={event => {
                                  updateOption(item.id, event.target.checked);
                                }}
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </BoxLoading>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropAdditionsItem;
