/**
 *
 * @type {EnvironmentConfig}
 */
const DevEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
    authDomain: 'xpress-dev.firebaseapp.com',
    databaseURL: 'https://xpress-dev.firebaseio.com',
    projectId: 'xpress-dev',
    storageBucket: 'xpress-dev.appspot.com',
    messagingSenderId: '895482157850',
    appId: '1:895482157850:web:d29538968761197d19eaa3',
    measurementId: 'G-21E38XT7JD'
  },
  REACT_APP_GOOGLE_MAP_API: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
  REACT_APP_CLOUD_FUNCTIONS_API:
    'https://us-central1-xpress-dev.cloudfunctions.net',
  REACT_APP_SUPPORT_MAIL: 'info@xpressbolivia.com',
  REACT_APP_FCM_KEY:
    'BDIAYCbYVNXEBRH4ThOvpSdQajp6V4O8b_oT3aMPxaqxldUq3_sD3RfDxPZJYR71o7Xoh2oOcObmSZfrTcbdjNA',
  REACT_APP_COMPANY_NAME: 'Xpress Technologies',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyB967xGVmp1f76qlWtMb2ZSS5LPZtwgq_Q',
  REACT_APP_DYNAMIC_LINK_DOMAIN_URI: 'https://xpressbelieve.page.link',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_MOTOS_URL: 'https://xpress-motos-dev.web.app/login',
  REACT_APP_XPRESS_SUPPORT: '+59162093356',
  REACT_APP_GA_MEASUREMENT_ID: '',
  REACT_APP_XPRESS_BOLIVIA_COOKIES:
    'https://xpress-dev.web.app/cookies-declaration',
  BACKEND_URL: 'https://xpress-api-2enzqqbxva-uc.a.run.app/'
};

export default DevEnv;
