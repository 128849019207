// eslint-disable-next-line import/prefer-default-export
export const COLORS = {
  white: '#FFFFFF',
  danger: '#C23F25',
  dangerDark: '#F75B3B',
  successDark: '#56B19E',
  success: '#4E9788',
  successLight: '#B2E7D8',
  defaultPage: '#F2F2F2',
  primary: '#FFDD39',
  primaryDark: '#EDC400',
  primaryLight: '#FFEA85',
  disabledGray: '#F2F2F2',
  lightGray: '#797979',
  red: '#C23F25',
  lightRed: '#F75B3B',
  lightYellow: '#FFEA85',
  green: '#56B19E',
  greenLight: '#56B19E12',
  lightGreen: '#EBF9F5',
  greenDark: '#637A75',
  strongGreen: '#1F6456',
  greenCake: '#EAF8F4',
  redCake: '#FFEEEB',
  yellowCake: '#FFF3BB',
  ordersCockpitBackground: '#C3E6DC',
  orderCockpitActiveCard: '#7BCCBB',
  orderCardGreenText: '#55B19B',
  topbarButons: '#FFE255',
  lightBlue: '#1DA1F2',
  grayLight: '#C2C2C2',
  activeGreen: '#232323',
  hoverGreen: '#C7F3E7',
  yellowLink: '#E3BF12',
  lowLightGreen: '#B2E7D833',
  black: '#000000',
  rateQualifGray: '#71807d',
  rateQualifiGrayDark: '#f0f6f6',
  rateQualifGreen: '#b1e7d9',
  rateQualifGreenDark: '#6ca999',
  rateQualifRed: '#f57150',
  rateQualifRedDark: '#cd4629',
  rateLikedUncheck: '#F0F6F5',
  rateDislikedCheck: '#F87052',
  rateBorderGray: '#E6E3E8',
  rateTextLikedCheck: '#4E9788',
  rateTextDislikedCheck: '#A8331C',
  dividerGray: '#999999',
  darkGolden: '#BF8D20'
};
