const pendingColumns = [
  {
    id: 'counter',
    labelToTranslate: 'admin_subsidiary.common_table.id',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'name',
    labelToTranslate: [
      'admin_subsidiary.common_table.commerce_name',
      'admin_subsidiary.common_table.commerce_subsidiary_name'
    ],
    minWidth: 170,
    align: 'center',
    search: true,
    link: true
  },
  {
    id: 'email',
    labelToTranslate: 'admin_subsidiary.common_table.email',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'created_at',
    filterName: 'createdValue',
    labelToTranslate: 'admin_subsidiary.common_table.inscription_date',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'contact_phone',
    labelToTranslate: 'admin_subsidiary.common_table.contact_phone',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'subsidiaries_amount',
    labelToTranslate: 'admin_subsidiary.pendingTable.amount_subsidiaries',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'pending',
    labelToTranslate: 'admin_subsidiary.pendingTable.pending',
    minWidth: 170,
    align: 'center',
    filterBy: true
  }
];

const activeColumns = [
  {
    id: 'counter',
    labelToTranslate: 'admin_subsidiary.common_table.id',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'name',
    labelToTranslate: [
      'admin_subsidiary.common_table.commerce_name',
      'admin_subsidiary.common_table.commerce_subsidiary_name'
    ],
    minWidth: 170,
    align: 'center',
    search: true
  },
  {
    id: 'email',
    labelToTranslate: 'admin_subsidiary.common_table.email',
    minWidth: 170,
    align: 'center',
    search: true
  },
  {
    id: 'created_at',
    filterName: 'createdValue',
    labelToTranslate: 'admin_subsidiary.common_table.inscription_date',
    minWidth: 170,
    align: 'center',
    search: true,
    filterBy: true
  },
  {
    id: 'contact_phone',
    labelToTranslate: 'admin_subsidiary.common_table.contact_phone',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'subsidiaries_amount',
    labelToTranslate: 'admin_subsidiary.pendingTable.amount_subsidiaries',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'actions',
    labelToTranslate: 'admin_subsidiary.common_table.acctions',
    minWidth: 170,
    align: 'center'
  }
];

const rejectedColumns = [
  {
    id: 'counter',
    labelToTranslate: 'admin_subsidiary.common_table.id',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'name',
    labelToTranslate: [
      'admin_subsidiary.common_table.commerce_name',
      'admin_subsidiary.common_table.commerce_subsidiary_name'
    ],
    minWidth: 170,
    align: 'center',
    search: true
  },
  {
    id: 'email',
    labelToTranslate: 'admin_subsidiary.common_table.email',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'created_at',
    filterName: 'createdValue',
    labelToTranslate: 'admin_subsidiary.common_table.inscription_date',
    minWidth: 170,
    align: 'center',
    search: true,
    filterBy: true
  },
  {
    id: 'contact_phone',
    labelToTranslate: 'admin_subsidiary.common_table.contact_phone',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'actions',
    labelToTranslate: 'admin_subsidiary.common_table.acctions',
    minWidth: 170,
    align: 'center'
  }
];

const suspendedColumns = [
  {
    id: 'counter',
    labelToTranslate: 'admin_subsidiary.common_table.id',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'name',
    labelToTranslate: [
      'admin_subsidiary.common_table.commerce_name',
      'admin_subsidiary.common_table.commerce_subsidiary_name'
    ],
    minWidth: 170,
    align: 'center',
    search: true
  },
  {
    id: 'email',
    labelToTranslate: 'admin_subsidiary.common_table.email',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'suspention_reason',
    labelToTranslate: 'admin_subsidiary.common_table.suspention_reason',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'created_at',
    filterName: 'createdValue',
    labelToTranslate: 'admin_subsidiary.common_table.inscription_date',
    minWidth: 170,
    align: 'center',
    search: true,
    filterBy: true
  },
  {
    id: 'contact_phone',
    labelToTranslate: 'admin_subsidiary.common_table.contact_phone',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'subsidiaries_amount',
    labelToTranslate: 'admin_subsidiary.pendingTable.amount_subsidiaries',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'actions',
    labelToTranslate: 'admin_subsidiary.common_table.acctions',
    minWidth: 170,
    align: 'center'
  }
];

const archivedColumns = [
  {
    id: 'counter',
    labelToTranslate: 'admin_subsidiary.common_table.id',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'name',
    labelToTranslate: [
      'admin_subsidiary.common_table.commerce_name',
      'admin_subsidiary.common_table.commerce_subsidiary_name'
    ],
    minWidth: 170,
    align: 'center',
    search: true
  },
  {
    id: 'email',
    labelToTranslate: 'admin_subsidiary.common_table.email',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'suspention_reason',
    labelToTranslate: 'admin_subsidiary.common_table.suspention_reason',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'created_at',
    filterName: 'createdValue',
    labelToTranslate: 'admin_subsidiary.common_table.inscription_date',
    minWidth: 170,
    align: 'center',
    search: true,
    filterBy: true
  },
  {
    id: 'contact_phone',
    labelToTranslate: 'admin_subsidiary.common_table.contact_phone',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'subsidiaries_amount',
    labelToTranslate: 'admin_subsidiary.pendingTable.amount_subsidiaries',
    minWidth: 170,
    search: true,
    align: 'center'
  },
  {
    id: 'actions',
    labelToTranslate: 'admin_subsidiary.common_table.acctions',
    minWidth: 170,
    align: 'center'
  }
];

export const columns = {
  pendingColumns,
  activeColumns,
  rejectedColumns,
  suspendedColumns,
  archivedColumns
};
