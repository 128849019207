// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Card, makeStyles, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tabHeaderCard: {
    paddingLeft: 24,
    marginBottom: 24,
    boxShadow: 'unset'
  },
  tabTitle: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  tabSubTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}));
const TabsHeader = ({
  onChange = () => {},
  value,
  tabs = [],
  tabValueName = 'value',
  tabLabelName = 'label',
  tabSubLabelName = 'subLabel',
  isSubLabel = false
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.tabHeaderCard}>
      <Tabs
        style={{ height: 80 }}
        onChange={onChange}
        scrollButtons="auto"
        textColor="secondary"
        value={value}
        variant="scrollable"
      >
        {tabs
          .filter(item => !item.hidden)
          .map(tab => (
            <Tab
              style={{ textTransform: 'unset', height: 80 }}
              key={tab[tabValueName]}
              value={tab[tabValueName]}
              label={
                <div>
                  <span className={classes.tabTitle}>{tab[tabLabelName]}</span>
                  {isSubLabel && (
                    <div>
                      <span className={classes.tabSubTitle}>
                        {tab[tabSubLabelName]}
                      </span>
                    </div>
                  )}
                </div>
              }
            />
          ))}
      </Tabs>
    </Card>
  );
};
export default TabsHeader;
