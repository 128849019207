export default class Log {
  constructor({
    id,
    created_at,
    target,
    action,
    platform,
    data,
    modifier_user,
    reviewer_user,
    changes
  } = {}) {
    this.id = id;
    this.created_at = created_at;
    this.target = target;
    this.action = action;
    this.platform = platform;
    this.data = data;
    this.modifier_user = modifier_user;
    if (reviewer_user) this.reviewer_user = reviewer_user;
    this.changes = changes;
  }
}
