import { useState, useEffect } from 'react';
import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from 'src/constants';
import Document from 'src/dto/document';

export const useDocumentsRestaurantNative = restId => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .collection(COLLECTIONS.DOCUMENT)
        .where('enable', '==', true)
        .orderBy('created_at', 'asc')
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => new Document({ ...doc.data() }));
          setDocuments(data);
        });
    }
    // eslint-disable-next-line
  }, []);

  return documents;
};

export const useGetBanner = restId => {
  const [logoBanner, setLogoBanner] = useState({});

  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.RESTAURANT)
        .doc(restId)
        .onSnapshot(snap => {
          const banner = snap.data().banner_photo;
          const logo = snap.data().logo_photo;
          setLogoBanner({ logo, banner });
        });
    }
    // eslint-disable-next-line
  }, []);

  return logoBanner;
};
