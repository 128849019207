import React, { useState } from 'react';
import {
  Container,
  makeStyles,
  Divider,
  Box,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import Page from '../../components/Page';
import Header from './Header';
import AddSecction from './AddSeccion';
import { COLLECTIONS } from '../../constants';
import useIsTemplateMenu from '../../hooks/AdminHooks/restaurant/useIsTemplateMenu';
import useCanEditablePage from '../../hooks/navigation/useCanEditablePage';
import { blockCashierEdit } from '../../utils/helpers/userHelper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  },
  loadingWrapper: {
    background: theme.palette.background.defaultTransparent,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10
  },
  wrapper: {
    position: 'relative'
  }
}));

const Edit = () => {
  const classes = useStyles();
  const translation = useTranslation();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant] = useRestaurantAndSubsidiaryforMenu(restId, subId);
  const isEditable = useCanEditablePage();
  const isTemplateMenu = useIsTemplateMenu();
  const currentItemId = isTemplateMenu ? restId : subId;
  const collection = isTemplateMenu
    ? COLLECTIONS.RESTAURANT
    : COLLECTIONS.SUBSIDIARY;
  const [newSectionId, setNewSectionId] = useState();
  const [isLoading, setLoading] = useState(false);
  const { user } = useSelector(state => state.account);
  const blockCashier = blockCashierEdit(user, restaurant);
  const setNewSection = id => {
    setNewSectionId(id);
  };

  const setNoLongerNew = () => {
    setNewSectionId('');
  };

  const location = useLocation();
  let titlePage = '';
  if (
    location.pathname ===
    `/app/editor/menu-sections/restId/${restId}/subId/${subId}`
  )
    titlePage = translation('menu.title');
  if (location.pathname === `/app/management/shared_menu/restId/${restId}`) {
    titlePage = translation('menu.title_shared_menu');
  }
  return (
    <Box className={classes.wrapper}>
      {isLoading && (
        <Box className={classes.loadingWrapper}>
          <CircularProgress />
        </Box>
      )}
      <Page className={classes.root} title={titlePage}>
        <Container maxWidth={false}>
          <Header
            setNewSection={setNewSection}
            isEditable={isEditable}
            blockCashier={blockCashier}
          />
          <Divider style={{ marginTop: '10px' }} />
          <AddSecction
            newSectionId={newSectionId}
            setNoLongerNew={setNoLongerNew}
            id={currentItemId}
            collection={collection}
            isEditable={isEditable}
            blockCashier={blockCashier}
            setLoading={setLoading}
            restaurant={restaurant}
          />
        </Container>
      </Page>
    </Box>
  );
};

export default Edit;
