import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-multi-lang/lib';
import _ from 'lodash';
import NewOrder from './NewOrder';
import { ORDER_STATUS } from '../../../dto/enum';
import AcceptedOrder from './AcceptedOrder';
import OrderView from './OrderView';
import OrderPrinting from '../OrderPrinting/OrderPrinting';
import { usePromotion } from '../../../hooks/Promotions/usePromotions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1)
    }
  }
}));

const OrderDetailsView = props => {
  const { order } = props;
  const classes = useStyles();
  const promotion = usePromotion(order?.promotionId);
  const translation = useTranslation();
  let Order = NewOrder;
  switch (order.status) {
    case ORDER_STATUS.IN_PROCESS:
      Order = NewOrder;
      break;
    case ORDER_STATUS.ACCEPTED:
      Order = AcceptedOrder;
      break;
    case ORDER_STATUS.READY_TO_PICK:
      Order = AcceptedOrder;
      break;
    case ORDER_STATUS.READY_TO_CASHIER:
      Order = AcceptedOrder;
      break;
    default:
      Order = OrderView;
      break;
  }

  return (
    <Page className={classes.root} title={translation('orders.details.title')}>
      <Container maxWidth={false}>
        {!_.isEmpty(order) && (
          <>
            <OrderPrinting order={{ ...order, promotion }} />
            <Order order={{ ...order, promotion }} />
          </>
        )}
      </Container>
    </Page>
  );
};

export default OrderDetailsView;
