import React, { useMemo } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import moment from 'moment';
import ExpressTableGenerate from '../../../../components/Table/ExpressTableGenerate';
import { useRestaurantPaymentWithoutStatusNative } from '../../../../hooks/useInvoice';
import 'moment/locale/es';

const TableRefundHistory = ({ idRes }) => {
  const translation = useTranslation();
  const COLUMNS = [
    {
      title: translation('central_payments.details.table_refund_history.name'),
      key: 'name_owner'
    },
    {
      title: translation(
        'central_payments.details.table_refund_history.ci_nit'
      ),
      key: 'ci_nit'
    },
    {
      title: translation(
        'central_payments.details.table_refund_history.bank_account'
      ),
      key: 'bank_account'
    },
    {
      title: translation('central_payments.details.table_refund_history.bank'),
      key: 'bank_name'
    },
    {
      title: translation(
        'central_payments.details.table_refund_history.refund_amount'
      ),
      key: 'amount_paid'
    },
    {
      title: translation(
        'central_payments.details.table_refund_history.reference'
      ),
      key: 'observations'
    },
    {
      title: translation('central_payments.details.table_refund_history.date'),
      key: 'date_payment'
    }
  ];
  const paymentHistory = useRestaurantPaymentWithoutStatusNative(idRes);
  const payment = useMemo(() => {
    return paymentHistory.map(pay => {
      return {
        ...pay,
        date_payment: moment(pay.date_payment.toDate()).format('dddd LL'),
        amount_paid: `Bs. ${pay.amount_paid}`
      };
    });
  }, [paymentHistory]);
  return (
    <>
      <Typography
        style={{
          fontSize: 20,
          marginTop: 20,
          marginBottom: 10,
          marginLeft: 12,
          fontWeight: 'bold'
        }}
      >
        {translation('central_payments.details.refund_history')}
      </Typography>
      <Divider />
      {payment.length === 0 ? (
        <Typography style={{ textAlign: 'center' }}>
          {translation('central_payments.details.no_refund_payment')}
        </Typography>
      ) : (
        <ExpressTableGenerate
          listHeader={COLUMNS}
          needTranslation={false}
          data={payment}
        />
      )}
    </>
  );
};
export default TableRefundHistory;
