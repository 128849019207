import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTranslation } from 'react-multi-lang/lib';
import WithLoading from '../../../components/WithLoading';
import admin from '../../../config/firebaseConfig';
import { updateUser } from '../../../services/usersService';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {}
}));

function Security({ className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  const { user } = useSelector(state => state.account);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const changePassword = async values => {
    await admin
      .auth()
      .signInWithEmailAndPassword(user.email, values.currentPassword);
    await admin.auth().currentUser.updatePassword(values.password);
    await updateUser(user.id, { temporal_password: values.password });
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        initialValues={{
          currentPassword: '',
          password: '',
          passwordConfirm: ''
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            translation('edit.product_create.additions.form.required')
          ),
          password: Yup.string()
            .min(7, translation('security.character'))
            .max(255)
            .required(
              translation('edit.product_create.additions.form.required')
            ),
          passwordConfirm: Yup.string()
            .oneOf(
              [Yup.ref('password'), null],
              translation('security.password')
            )
            .required(
              translation('edit.product_create.additions.form.required')
            )
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setLoading(true);
            // Make API request
            await changePassword(values);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(translation('security.update'), {
              variant: 'success'
            });
            setLoading(false);
          } catch (error) {
            let { message } = error;
            if (
              error.message ===
              'The password is invalid or the user does not have a password.'
            ) {
              message = 'Contraseña actual incorrecta';
              setErrors({ currentPassword: message });
            } else {
              setErrors({ submit: message });
            }
            setStatus({ success: false });

            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={clsx(classes.root, className)} {...rest}>
              <CardHeader title={translation('security.change')} />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>
                        {translation('security_password.current_password')}
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(
                          touched.currentPassword && errors.currentPassword
                        )}
                        label={translation(
                          'security_password.current_password'
                        )}
                        name="currentPassword"
                        type={showCurrentPassword ? 'text' : 'password'}
                        value={values.currentPassword}
                        onBlur={handleBlur}
                        onChange={handleChange('currentPassword')}
                        endAdornment={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showCurrentPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(
                        touched.currentPassword && errors.currentPassword
                      ) && (
                        <FormHelperText error>
                          {touched.currentPassword && errors.currentPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>
                        {translation('security_password.new_password')}
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(touched.password && errors.password)}
                        label={translation('security_password.new_password')}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange('password')}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        endAdornment={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(touched.password && errors.password) && (
                        <FormHelperText error>
                          {touched.password && errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>
                        {translation('security.confirmation')}
                      </InputLabel>
                      <OutlinedInput
                        error={Boolean(
                          touched.passwordConfirm && errors.passwordConfirm
                        )}
                        label={translation('security.confirmation')}
                        name="passwordConfirm"
                        onBlur={handleBlur}
                        onChange={handleChange('passwordConfirm')}
                        type={showPasswordConfirm ? 'text' : 'password'}
                        value={values.passwordConfirm}
                        endAdornment={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordConfirm ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(
                        touched.passwordConfirm && errors.passwordConfirm
                      ) && (
                        <FormHelperText error>
                          {touched.passwordConfirm && errors.passwordConfirm}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </CardContent>
              <Divider />
              <Box p={2} display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {translation('security.change')}
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </BoxLoading>
  );
}

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
