import React, { useState, useEffect, useMemo } from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import TabsHeader from '../../../components/Tab/Tabs';
import ReportContent from './ReportContent';
import { useOrdersCompletedCanceled } from '../../../hooks/Order/useOrders';
import { useSubsidiariesMergedAndCurrentOrdered } from '../../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function PerformanceReport() {
  const classes = useStyles();
  const translation = useTranslation();
  const currentRestaurant = useSelector(state => state.account.restaurant);
  const currentSubsidiary = useSelector(
    state => state.account.currentSubsidiary
  );
  const [valueTab, setValueTab] = useState(0);
  const [subsidiaryId, setSubsidiaryId] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const completedCanceledOrders = useOrdersCompletedCanceled(subsidiaryId);
  const subsidiaries = useSubsidiariesMergedAndCurrentOrdered();
  useEffect(() => {
    setValueTab(0);
  }, [currentSubsidiary]);

  useEffect(() => {
    if (valueTab === 0) {
      setSubsidiaryId(currentSubsidiary.id);
      setRestaurantId(currentRestaurant.id);
    } else if (
      valueTab <= currentSubsidiary.subsidiariesWithRestaurantsMerged.length
    ) {
      const subsRest =
        currentSubsidiary.subsidiariesWithRestaurantsMerged[valueTab - 1];
      setSubsidiaryId(subsRest.subsidiaryId);
      setRestaurantId(subsRest.restaurantId);
    }
    // eslint-disable-next-line
  }, [currentSubsidiary, valueTab]);

  const handleChangeTab = (event, index) => {
    setValueTab(index);
  };

  const tabs = useMemo(() => {
    const tabsValues = [];
    if (subsidiaries) {
      subsidiaries.forEach((subRest, index) => {
        tabsValues.push({
          value: index,
          label: subRest.restaurant_name,
          subLabel: subRest.subsidiary_name
        });
      });
    }
    return tabsValues;
  }, [subsidiaries]);
  if (!currentSubsidiary && !subsidiaryId && !restaurantId) return null;
  return (
    <Page
      className={classes.root}
      title={translation('earning.header.performance')}
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={1.5}>
          <TabsHeader
            value={valueTab}
            onChange={handleChangeTab}
            tabs={tabs}
            isSubLabel
          />
          <ReportContent
            subsidiaryId={subsidiaryId}
            restaurantId={restaurantId}
            completedCanceledOrders={completedCanceledOrders}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default PerformanceReport;
