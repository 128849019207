/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useIsTemplateMenu from '../../../../hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { useMenuProductsNativeAsync } from '../../../../hooks/useMenu';
import { saveMenuProductsOrder } from '../../../../services/menuSectionService';
import DndList from '../../../../components/DragAndDrop/DndList';
import ProductListItem from '../../../../components/Products/ProductListItem';
import { logProduct } from '../../../../services/logServices';
import { LOG_ACTIONS } from '../../../../dto/enum';

const arrayToObject = array => {
  const newObject = {};
  array.forEach((_, index) => {
    newObject[index] = array[index].name;
  });
  return newObject;
};

const Results = props => {
  const {
    sectionId,
    isEditable,
    blockCashier = false,
    setLoading,
    section,
    restaurant
  } = props;
  const { subId } = useParams();
  const isTemplate = useIsTemplateMenu();
  const products = useMenuProductsNativeAsync(subId, sectionId, isTemplate);
  const { SUPER_ADMIN, CASHIER, OWNER, SUPERVISOR } = useSelector(
    state => state.account.user.roles
  );

  const enableCashier = useMemo(() => {
    return OWNER || SUPERVISOR || (CASHIER && restaurant?.enable_menu_cashier);
  }, [CASHIER, restaurant, OWNER, SUPERVISOR]);

  const onDrop = async nextState => {
    setLoading(true);
    const changes = {
      before: arrayToObject(products),
      after: arrayToObject(nextState)
    };
    const data = {
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      },
      menu_section: {
        id: section.id,
        name: section.name
      }
    };
    await saveMenuProductsOrder(nextState);
    if (isTemplate) await logProduct(changes, LOG_ACTIONS.ORDER, data);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <DndList
      items={products}
      renderItem={item => (
        <ProductListItem
          dontOpenEdit={props.dontOpenEdit}
          product={item}
          isEditable={isEditable}
          blockCashier={blockCashier}
          section={section}
          restaurant={restaurant}
          enableMenuCashier={enableCashier}
          SUPER_ADMIN={SUPER_ADMIN}
        />
      )}
      dndType="PRODUCT_ITEM"
      keyGenerator={item => item.id}
      onDrop={onDrop}
    />
  );
};

Results.defaultProps = {
  products: []
};

export default Results;
