import React, { useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useProgressRealTime from '../../../hooks/progressRealtime';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: 7
  }
}));
const ProgressLinearTimer = ({ startDate, finishDate, timeout }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useProgressRealTime(startDate, finishDate, setProgress, timeout);

  return (
    <LinearProgress
      variant="determinate"
      color="secondary"
      value={progress}
      className={classes.root}
    />
  );
};

export default ProgressLinearTimer;
