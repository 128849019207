import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, MenuItem, Menu } from '@material-ui/core';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { useSubsidiaries } from '../../../hooks/useRestaurant';
import { COLORS } from '../../../theme/colors';

const Header = ({ currentSubsidiary, setSubsidiary }) => {
  const [sub, setSub] = useState(currentSubsidiary);
  const [openMenu, setOpenMenu] = useState(false);
  const subsidiaries = useSubsidiaries();
  const anchorRef = useRef(null);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleOptionToggle = subsidiary => {
    setSub({ name: subsidiary.name, id: subsidiary.id });
    handleMenuClose();
  };

  useEffect(() => {
    setSubsidiary(sub);
    // eslint-disable-next-line
  }, [sub]);

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Typography variant="h1" style={{ fontWeight: 'bold' }}>
        Precios de delivery
      </Typography>
      <Box display="flex">
        <Typography variant="h4" style={{ marginRight: 10 }}>
          Sucursal
        </Typography>
        <Typography
          variant="h4"
          style={{
            cursor: 'pointer',
            alignItems: 'flex-end',
            position: 'relative'
          }}
          onClick={handleMenuOpen}
          ref={anchorRef}
          display="flex"
        >
          {sub.name}
          <ChevronDownIcon
            style={{
              width: 20,
              backgroundColor: COLORS.lightYellow,
              borderRadius: 10,
              height: 20,
              marginLeft: 10,
              marginTop: 1,
              position: 'absolute'
            }}
          />
        </Typography>
        <Menu
          anchorEl={anchorRef.current}
          elevation={1}
          onClose={handleMenuClose}
          open={openMenu}
          PaperProps={{ style: { width: 250 } }}
        >
          {subsidiaries.map(subsidiary => (
            <MenuItem
              key={subsidiary.id}
              onClick={() => handleOptionToggle(subsidiary)}
            >
              <Typography>{subsidiary.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;
