import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-multi-lang/lib';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Account from './Account';
import Cockpit from './Cockpit';
import { useRestaurantStatus } from '../../../hooks/useRestaurant';
import { REGISTER_STATUS } from '../../../dto/enum';
import CheckDeliveryPrices from '../../../components/CheckDeliveryPrices';
import { isOwner, isSupervisor } from '../../../utils/helpers/userHelper';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.EXPRESS
      ? {
          backgroundColor: theme.palette.background.primary
        }
      : {})
  },
  toolbar: {
    minHeight: 64,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(8)
    }
  },
  title: {
    margin: '0 16px'
  },
  logo: {
    maxHeight: 62,
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(5),
      paddingLeft: theme.spacing(1)
    }
  },
  divider: {
    margin: '0 20px'
  },
  menuButton: {
    color: theme.palette.expressColors.black
  },
  menuButtonContainer: {
    backgroundColor: theme.palette.expressColors.white,
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      maxHeight: theme.spacing(8),
      backgroundColor: theme.palette.background.primary
    }
  },
  verificationMessage: {
    padding: theme.spacing(1),
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold'
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  restaurantId,
  chatNewMessagesAmount,
  ...rest
}) {
  const history = useHistory();
  const classes = useStyles();
  const translation = useTranslation();
  const [showOption, setShowOption] = useState([]);
  const subsidiaries = useSelector(store => store.restaurant.subsidiaries);
  const restaurant = useSelector(
    state => state.firestore.ordered.userRestaurant || false
  );
  const currentRestaurant = useSelector(
    state => state.account.restaurant || ''
  );
  const subsidiary = useSelector(
    state => state.account.currentSubsidiary || false
  );
  const restaurantActiveHook = useRestaurantStatus(restaurant, subsidiary);
  const { user } = useSelector(state => state.account);
  const [registerStatus, setregisterStatus] = useState();
  const [isEnableChat, setIsEnableChat] = useState(false);

  if (subsidiary === false) {
    history.push('/user-suspended');
  }
  if (registerStatus === REGISTER_STATUS.SUSPENDED) {
    history.push('/user-suspended');
  }
  if (registerStatus === REGISTER_STATUS.SUBSCRIPTION_EXPIRED) {
    history.push('/user-suspended');
  }

  if (registerStatus && currentRestaurant.register_status !== registerStatus) {
    window.location.reload();
  }

  let userSubsidiaries = subsidiaries;
  if (isSupervisor(user)) {
    userSubsidiaries = userSubsidiaries.filter(s =>
      user.subsidiary_id.includes(s.id)
    );
  }
  if (isOwner(user)) {
    userSubsidiaries = subsidiaries;
  }

  useEffect(() => {
    if (restaurantActiveHook) {
      setShowOption(restaurantActiveHook);
    }
    if (restaurant) {
      setregisterStatus(restaurant[0].register_status);
      if (restaurant[0]?.enable_chat) setIsEnableChat(true);
    }
    // eslint-disable-next-line
  }, [restaurant]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.menuButtonContainer}>
          {showOption && (
            <IconButton
              className={classes.menuButton}
              color="primary"
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="large">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Box>
        <RouterLink to="/app/orders">
          <Logo className={classes.logo} />
        </RouterLink>
        {!showOption && (
          <Box ml={2} className={classes.verificationMessage}>
            <Typography className={classes.text}>
              {!subsidiary && registerStatus !== 'PENDING'
                ? 'Todas las Sucursales estan suspendidas'
                : translation('top_bar.verificationMessage')}
            </Typography>
          </Box>
        )}
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          {showOption && (
            <Cockpit
              chatNewMessagesAmount={chatNewMessagesAmount}
              isEnableChat={isEnableChat}
            />
          )}
        </Box>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
      {(isOwner(user) || isSupervisor(user)) && (
        <CheckDeliveryPrices
          userSubsidiaries={userSubsidiaries}
          currentRestaurant={restaurant}
        />
      )}
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
