import React, { useMemo } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useRestaurantInvoiceWithoutStatusNative } from 'src/hooks/useInvoice';
import { useChargesHistoryByRestaurant } from 'src/hooks/chargesHistory/useChargesHistory';
import PaymentSummary from './paymentSummary';
import HistorialTable from './historialTable';

const useStyles = makeStyles(() => ({
  row: {
    width: '100%'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  card: {
    borderRadius: '12px',
    border: '1px solid #BF8D20',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    padding: '35px 55px'
  },
  title: {
    fontSize: '23px',
    fontWeight: 'bold'
  }
}));

const OnlinePayment = ({ restaurant }) => {
  const classes = useStyles();
  const invoices = useRestaurantInvoiceWithoutStatusNative(restaurant.id || '');
  const [currentChargesHistory] = useChargesHistoryByRestaurant(restaurant.id);
  const chargesHist = useMemo(() => {
    if (currentChargesHistory) {
      return currentChargesHistory.charges;
    }
    if (restaurant.charge) {
      return {
        pick_up: restaurant.charge.pick_up || 0,
        delivery: restaurant.charge.delivery,
        xpress_motos: restaurant.charge.xpress_motos || 0
      };
    }
    return [];
  }, [restaurant, currentChargesHistory]);
  const onlineInvoices = useMemo(() => {
    if (invoices) {
      return invoices.filter(invoice => invoice.summary?.online?.total_sales);
    }
    return [];
  }, [invoices]);
  return (
    <Box p={3}>
      <Grid container direction="column" justify="center" spacing={3}>
        <Grid item className={classes.row}>
          <Box className={classes.cardContainer}>
            <PaymentSummary
              restaurant={restaurant}
              charges={chargesHist}
              onlineCharges={restaurant.charges_online}
            />
          </Box>
        </Grid>
        <Grid item className={classes.row}>
          <HistorialTable invoices={onlineInvoices} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnlinePayment;
