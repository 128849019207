/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { getEstimatedTime } from '../utils/estimatedTime';

export function useIntervalOrder(order) {
  const formatedOrder = useMemo(() => {
    return {
      ...order,
      delivery_distance: order.delivery_distance,
      created_at: order.created_at
        ? createMomentOnBolivianTime(order.created_at.toDate()).startOf(
            'minutes'
          )
        : null
    };
  }, [order]);
  const clock = useSelector(store => store.deviceReducer.time);
  const [intervalOrder, setIntervalOrder] = useState(null);

  async function run() {
    const currentDate = createMomentOnBolivianTime();
    if (currentDate >= intervalOrder?.high) {
      setIntervalOrder(getEstimatedTime(formatedOrder, clock));
    }
  }

  useEffect(() => {
    setIntervalOrder(getEstimatedTime(formatedOrder, clock));
  }, [formatedOrder]);

  useEffect(() => {
    run();
  }, [clock.getSeconds()]);

  return intervalOrder;
}
