import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Grid, Link, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpressTable from '../../../../components/Table/ExpressTable';
import { useCities } from '../../../../hooks/AdminHooks/cities/useCities';
import { useAreas } from '../../../../hooks/AdminHooks/areas/useAreas';
import { updateAreaDB } from '../../../../services/areaServices';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import NewPrice from '../modal/NewPrice';
import XpressModal from '../../../../components/Modal';

const useStyles = makeStyles(() => ({
  addPriceModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`
  }
}));

const Results = ({ promos }) => {
  const translation = useTranslation();
  const classes = useStyles();
  const cities = useCities();
  const [deletedArea, setDeletedArea] = useState({});
  const areas = useAreas();
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const tableColumns = [
    {
      id: 'name',
      label: translation('admin_areas.table.name'),
      minWidth: 100,
      align: 'center',
      search: true
    },
    {
      id: 'city',
      label: translation('admin_areas.table.city'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'actions',
      label: translation('admin_areas.table.actions'),
      maxWidth: 100,
      align: 'center'
    }
  ];
  let tableContent = [];

  const handleClose = () => {
    setShowModal(false);
  };

  const createArea = area => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid className={classes.addPriceModal}>
          <NewPrice onCancel={handleClose} area={area} />
        </Grid>
      </XpressModal>
    );
  };

  const linkToSub = item => {
    return (
      <Box>
        <Button
          style={{ color: 'black', textDecorationLine: 'underline' }}
          onClick={() => createArea(item)}
        >
          {translation('admin_areas.table.link').toUpperCase()}
        </Button>
        <Link
          style={{ color: 'black', textDecorationLine: 'underline' }}
          component={RouterLink}
          to={`/admin/areas/${item.id}/assign`}
        >
          {translation('admin_areas.table.assign_link').toUpperCase()}
        </Link>

        <Button
          style={{ color: 'black', textDecorationLine: 'underline' }}
          onClick={() => setDeletedArea(item)}
        >
          {translation('admin_areas.table.delete_link').toUpperCase()}
        </Button>
      </Box>
    );
  };

  if (areas) {
    tableContent = areas.map(item => ({
      id: item.id,
      name: item.name,
      city: cities.find(c => item.city_id === c.id).name,
      actions: linkToSub(item)
    }));
  }

  const deleteArea = async () => {
    await updateAreaDB(deletedArea.id);
    setDeletedArea({});
  };

  return (
    <>
      <Grid>
        <ExpressTable
          columns={[...tableColumns]}
          rows={[...tableContent]}
          search
        />
      </Grid>
      <AlertDialog
        open={!!deletedArea.id}
        question={translation('admin_areas.table.delete')}
        maxWidth="sm"
        handleCancel={() => {
          setDeletedArea({});
        }}
        handleAccept={() => deleteArea()}
      >
        <Box m={1}>
          <Typography variant="h4">{`${deletedArea.name}`}</Typography>
        </Box>
      </AlertDialog>
      {showModal && modal}
    </>
  );
};

export default Results;
