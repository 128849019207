import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Link, Grid } from '@material-ui/core';
import ExpressTable from '../../../../components/Table/ExpressTable';
// import XpressModal from '../../../../components/Modal';
import { useAllRestaurantSubsidiaries } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
// import { YellowButton } from '../../../../components/Inputs/Buttons';
// import NewSubsidiaryForm from '../forms/NewSubsidiaryForm';
// import { useAllCities } from '../../../../hooks/AdminHooks/cities/useCities';

/* const useStyles = makeStyles(theme => ({
  modal: {
    marginTop: '20px',
    justifyContent: "center",
  }
})); */

const SubsidiariesList = ({ restId, isMerchant = false }) => {
  // const classes = useStyles();
  const translation = useTranslation();
  const allSubsidiaries = useAllRestaurantSubsidiaries(restId);
  // const [modal, setModal] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  // const allCities = useAllCities();

  const tableColumns = [
    {
      id: 'counter',
      label: translation('admin_rest.table.headers.id'),
      minWidth: 50,
      align: 'center'
    },
    {
      id: 'name',
      label: translation('admin_rest.table.headers.suc_name'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'city',
      label: translation('admin_rest.table.headers.city'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'address',
      label: translation('admin_rest.table.headers.address'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'phone',
      label: translation('admin_rest.table.headers.phone'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'status',
      label: translation('admin_rest.table.headers.status'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'acciones',
      label: translation('admin_rest.table.headers.acciones'),
      minWidth: 170,
      align: 'center'
    }
  ];

  // eslint-disable-next-line consistent-return
  const getBreadCrumbLabel = status => {
    switch (status) {
      case 'ACTIVE':
        return translation('admin_rest.register_status.active');
      case 'REJECTED':
        return translation('admin_rest.register_status.rejected');
      case 'SUSPENDED':
        return translation('admin_rest.register_status.suspended');
      case 'PENDING':
        return translation('admin_rest.register_status.pending');
      case 'ARCHIVED':
        return translation('admin_rest.register_status.archived');
      default:
        break;
    }
  };

  const linkToSub = (status, id) => {
    return (
      <Box>
        <Link
          style={{ color: 'black', textDecorationLine: 'underline' }}
          component={RouterLink}
          to={
            isMerchant
              ? `/app/configuration/subsidiary/${id}`
              : `/admin/commerce/${status.toLowerCase()}/subsidiary/${id}`
          }
        >
          {translation(
            'admin_subsidiary.common_table.see_subsidiary'
          ).toUpperCase()}
        </Link>
      </Box>
    );
  };

  const tableContent = useMemo(() => {
    if (allSubsidiaries) {
      return allSubsidiaries.map((item, index) => ({
        id: item.id,
        counter: index + 1,
        name: item.name,
        city: item.city.name,
        address: item.address,
        phone: item.legal_representant ? item.legal_representant.phone : '-',
        status: getBreadCrumbLabel(item.register_status),
        acciones: linkToSub(item.register_status, item.id)
      }));
    }
    return [];
    // eslint-disable-next-line
  }, [allSubsidiaries, restId]);

  /*   const handleClose = () => {
    setShowModal(false);
  }

  const addSubsidiary = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid item container className={classes.modal}>
          <NewSubsidiaryForm
            restId={allSubsidiaries[0].restaurant_id}
            allCities={allCities}
            handleClose={handleClose}
            onModal
          />
        </Grid>
      </XpressModal>
    );
  } */

  return (
    <Grid>
      <ExpressTable
        columns={[...tableColumns]}
        rows={[...tableContent]}
        search
        /* additional={
            <YellowButton
              onClick={addSubsidiary}
            >
              {translation('admin_subsidiary.table.buttons.add_sub')}
            </YellowButton>
          } */
      />
      {/* {showModal && modal} */}
    </Grid>
  );
};

export default SubsidiariesList;
