/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang/lib';
import { Grid, Select, Box, makeStyles, Button } from '@material-ui/core';
import { setFilter } from '../../../../actions/filterActions';
import { useAllCities } from '../../../../hooks/AdminHooks/cities/useCities';
import { useAllRestaurantsToSelect } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllSubsidiaries } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { usePromos } from '../../../../hooks/AdminHooks/promos/usePromos';
import useFilter from '../../../../hooks/filter/useFilter';
import { INVOICE_STATUS, ORDER_STATUS } from '../../../../dto/enum';
import { START_YEAR } from '../../../../constants';

const startYear = START_YEAR;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  }
}));

const Header = props => {
  const { tab, setRestaurant, setSubsidiary } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const cities = useAllCities();
  const filter = useFilter();
  const allRestaurants = useAllRestaurantsToSelect();
  const allSubsidiaries = useAllSubsidiaries();
  const allPromos = usePromos();
  const [defaultCity, setDefaultCity] = useState(null);

  if (filter && !filter.city && cities && !defaultCity) {
    const [defCity] = cities.filter(city => city.is_default);
    setDefaultCity(defCity ? defCity.id : cities[0].id);
    dispatch(setFilter({ city: defCity ? defCity.id : cities[0].id }));
  }
  if (filter && !filter.year) {
    dispatch(setFilter({ year: moment().format('YYYY') }));
  }
  if (filter && !filter.month) {
    dispatch(setFilter({ month: moment().format('MM') }));
  }
  if (filter && !filter.restaurant) {
    dispatch(setFilter({ restaurant: 'all' }));
  }
  if (filter && filter.restaurant === 'all') {
    dispatch(setFilter({ subsidiary: 'all' }));
  }
  if (filter && !filter.subsidiary) {
    dispatch(setFilter({ subsidiary: 'all' }));
  }
  if (filter && !filter.status) {
    dispatch(setFilter({ status: 'all' }));
  }
  if (filter && !filter.promo) {
    dispatch(setFilter({ promo: 'all' }));
  }
  if (filter && !filter.statusOrder) {
    dispatch(setFilter({ statusOrder: ORDER_STATUS.COMPLETED }));
  }

  const months = [
    { value: '01', label: translation('months.january') },
    { value: '02', label: translation('months.february') },
    { value: '03', label: translation('months.march') },
    { value: '04', label: translation('months.april') },
    { value: '05', label: translation('months.may') },
    { value: '06', label: translation('months.june') },
    { value: '07', label: translation('months.july') },
    { value: '08', label: translation('months.august') },
    { value: '09', label: translation('months.september') },
    { value: '10', label: translation('months.october') },
    { value: '11', label: translation('months.november') },
    { value: '12', label: translation('months.december') }
  ];

  const statusOrder = [
    {
      value: ORDER_STATUS.COMPLETED,
      label: translation('orders.details.order_status.completed')
    },
    {
      value: ORDER_STATUS.CANCELED,
      label: translation('orders.details.order_status.canceled')
    }
  ];

  const getYears = () => {
    const arr = [];
    const year = moment().format('YYYY');
    for (let i = startYear; i <= year * 1; i++) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };

  const years = getYears();

  const getRestaurants = () => {
    if (!allSubsidiaries.length || !allRestaurants.length) return [];
    const subsidiaries = allSubsidiaries.filter(
      s => s.city?.id === filter.city
    );
    const restaurants = allRestaurants
      .filter(r => {
        let isInList = false;
        for (let i = 0; i < subsidiaries.length; i++) {
          if (r.value === subsidiaries[i].restaurant_id) {
            isInList = true;
            i = subsidiaries.length;
          }
        }
        return isInList;
      })
      .map(r => (
        <option key={r.value} value={r.value} name={r.label}>
          {r.label}
        </option>
      ));
    restaurants.unshift(
      <option key="all" value="all">
        {translation('accounting.all_restaurants')}
      </option>
    );
    return restaurants;
  };

  const getSubsidiaries = () => {
    if (!filter.restaurant) return [];
    const subsidiaries = allSubsidiaries
      .filter(
        s => s.city?.id === filter.city && s.restaurant_id === filter.restaurant
      )
      .map(s => (
        <option key={s.id} value={s.id} name={s.name}>
          {s.name}
        </option>
      ));
    subsidiaries.unshift(
      <option key="all" value="all">
        {translation('accounting.all_subsidiaries')}
      </option>
    );
    return subsidiaries;
  };

  const getPromos = () => {
    if (!allSubsidiaries.length || !allRestaurants.length) return [];
    const promos = allPromos.map(p => (
      <option key={p.id} value={p.id}>
        {p.title}
      </option>
    ));
    promos.unshift(
      <option key="all" value="all">
        {translation('accounting.select_promos')}
      </option>
    );
    return promos;
  };

  const getStatus = () => {
    const toArray = _.values(INVOICE_STATUS);
    const values = toArray.map(item => (
      <option key={item} value={item}>
        {translation(`accounting.status_dropdown.${item.toLocaleLowerCase()}`)}
      </option>
    ));
    values.unshift(
      <option key="all" value="all">
        {translation('accounting.status_dropdown.all')}
      </option>
    );
    return values;
  };

  const onReset = () => {
    dispatch(
      setFilter({
        city: defaultCity,
        restaurant: 'all',
        subsidiary: 'all',
        promo: 'all',
        month: moment().format('MM'),
        year: moment().format('YYYY')
      })
    );
  };

  return (
    <Box className={classes.container} width="100%" my={3}>
      <Grid item container justify="flex-end" wrap="nowrap" xs={12} md={12}>
        <Button variant="contained" onClick={onReset}>
          {translation('accounting.reset_button')}
        </Button>
        {cities && (
          <Box mx={1.5}>
            <Select
              native
              value={filter.city}
              onChange={e =>
                dispatch(
                  setFilter({
                    city: e.target.value,
                    restaurant: 'all',
                    subsidiary: 'all',
                    promo: 'all',
                    statusOrder: ORDER_STATUS.COMPLETED
                  })
                )
              }
            >
              {cities.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </Box>
        )}

        <Box mx={1.5}>
          {allSubsidiaries && (
            <Select
              native
              value={filter.restaurant}
              onChange={e => {
                dispatch(
                  setFilter({ restaurant: e.target.value, subsidiary: 'all' })
                );
                const [r] = allRestaurants.filter(
                  rest => rest.value === e.target.value
                );
                setRestaurant(r);
              }}
            >
              {getRestaurants()}
            </Select>
          )}
        </Box>

        <Box mx={1.5}>
          {filter.restaurant && (
            <Select
              native
              value={filter.subsidiary}
              onChange={e => {
                dispatch(setFilter({ subsidiary: e.target.value }));
                const [s] = allSubsidiaries.filter(
                  sub => sub.id === e.target.value
                );
                setSubsidiary(s);
              }}
            >
              {getSubsidiaries()}
            </Select>
          )}
        </Box>

        {tab !== 0 && (
          <>
            <Box mx={1.5}>
              <Select
                native
                onChange={e => dispatch(setFilter({ status: e.target.value }))}
              >
                {getStatus()}
              </Select>
            </Box>
          </>
        )}

        {tab !== 1 && (
          <>
            <Box mx={1.5}>
              {allRestaurants && (
                <Select
                  native
                  value={filter.promo}
                  onChange={e => dispatch(setFilter({ promo: e.target.value }))}
                >
                  {getPromos()}
                </Select>
              )}
            </Box>
          </>
        )}

        {tab !== 1 && (
          <>
            <Box mx={1.5}>
              {allRestaurants && (
                <Select
                  native
                  value={filter.statusOrder}
                  onChange={e =>
                    dispatch(setFilter({ statusOrder: e.target.value }))
                  }
                >
                  {statusOrder.map(s => (
                    <option key={s.value} value={s.value}>
                      {s.label}
                    </option>
                  ))}
                </Select>
              )}
            </Box>
          </>
        )}

        <Box mx={1.5}>
          <Select
            native
            value={filter.year}
            onChange={e => dispatch(setFilter({ year: e.target.value }))}
          >
            {years.map(y => (
              <option key={y.value} value={y.value}>
                {y.label}
              </option>
            ))}
          </Select>
        </Box>

        <Box mx={1.5}>
          <Select
            native
            value={filter.month}
            onChange={e => dispatch(setFilter({ month: e.target.value }))}
          >
            {months.map(m => (
              <option key={m.value} value={m.value}>
                {m.label}
              </option>
            ))}
          </Select>
        </Box>
      </Grid>
    </Box>
  );
};

export default Header;
