import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Box,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useWebView } from 'src/hooks/window/windowHooks';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '15%',
    outline: 'none'
  },
  contentSmall: {
    padding: '16px 32px 24px',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5%',
    outline: 'none'
  },
  header: {
    textAlign: 'center'
  },
  required: {
    color: 'red'
  },
  image: {
    minHeight: 100,
    maxHeight: 200,
    width: '100%',
    cursor: 'pointer'
  },
  input: {
    marginTop: 15
  }
}));

const AddDocument = ({ handleClose, handleConfirm }) => {
  const webView = useWebView();
  const classes = useStyles();
  const translation = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [docTitle, setDocTitle] = useState('');
  const [docDescription, setDocDescription] = useState('');
  const [required, setRequired] = useState(false);

  const addDocument = () => {
    setUploading(true);
    handleConfirm({ title: docTitle, description: docDescription });
  };

  const requiredForm = () => {
    if (docTitle) {
      addDocument();
    } else if (!docTitle) {
      setRequired(true);
    }
  };

  return (
    <Grid>
      <Card className={webView ? classes.content : classes.contentSmall}>
        <CardHeader
          className={classes.header}
          title={translation('modals.addDocument.title')}
        />
        <Divider />
        <CardContent>
          <Grid container direction="row" spacing={2} justify="center">
            <Grid item xs={12} sm={9}>
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_title')}
                name="title"
                onChange={e => setDocTitle(e.target.value)}
                value={docTitle}
                variant="outlined"
              />
              {required && !docTitle && (
                <Typography className={classes.required}>
                  {translation('document_uploader.title.required')}
                </Typography>
              )}
              <TextField
                className={classes.input}
                fullWidth
                label={translation('modals.uploadFile.doc_description')}
                name="description"
                onChange={e => setDocDescription(e.target.value)}
                value={docDescription}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {uploading && (
            <Box width="100%" my={3}>
              <LinearProgress />
            </Box>
          )}
          {!uploading && (
            <Box mt={3} width="100%">
              <Grid item container justify="center" spacing={4}>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={requiredForm}
                  >
                    {translation('modals.uploadFile.btn_save')}
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                  >
                    {translation('cancel')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddDocument;
