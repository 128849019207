import React, { useEffect, useState } from 'react';
import 'firebase/storage';
import {
  Box,
  Card,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Button,
  Switch as Switch2,
  FormHelperText
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import Link from '@material-ui/core/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CardHeader from '../../../components/Card/CardHeader';
import Switch from '../../../components/Inputs/Switch';
import { RESTAURANT_DEFAULT_SETTINGS } from '../../../constants';
import { COLORS } from '../../../theme/colors';
import {
  editRestaurant,
  updateMinimumPurchase
} from '../../../services/restaurantService';
import createDynamicLink from '../../../services/dynamicLinkService';
import IconButton from '../../../components/Inputs/IconButton';
import Icons from '../../../assets/icons';
import WithLoading from '../../../components/WithLoading';
import AlertDialog from '../../../components/Dialog/AlertDialog';

const useStyles = makeStyles(() => ({
  boxContainer: {
    padding: 20,
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    width: 650
  },
  feedback: {
    fontSize: 12,
    marginLeft: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 16
  }
}));

const MAX_TITLE = 50;
const MAX_DESCRIPTION = 155;

const BoxLoading = WithLoading(Box);

const RestaurantSettingsView = ({
  currentRestaurant,
  showButtonSave = true
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSetting] = useState(RESTAURANT_DEFAULT_SETTINGS);
  const [title, setTitle] = useState(
    `${currentRestaurant.name} esta en XPRESS`
  );
  const [description, setDescription] = useState(
    '¡XPRESS es una plataforma tecnológica diseñada para hacer tu vida más fácil! Puedes usar el servicio único Pa’Recoger para buscar entre muchos comercios disponibles e infinidad de productos muy fácilmente desde tu teléfono móvil.'
  );
  const [loading, setLoading] = useState(false);
  const [isMinimumPurchase, setIsMinimumPurchase] = useState(
    currentRestaurant?.minimum_purchase > 0
  );
  const [minimumPurchase, setMinimumPurchase] = useState('');
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    if (currentRestaurant.settings) setSetting(currentRestaurant.settings);
    if (currentRestaurant.minimum_purchase) {
      setMinimumPurchase(currentRestaurant.minimum_purchase);
    }
  }, [currentRestaurant, isMinimumPurchase]);

  const updateSettings = (value, field) => {
    setSetting({
      ...settings,
      [field]: value
    });
  };
  const saveConfiguration = (newSettings = settings) => {
    editRestaurant(currentRestaurant.id, { settings: newSettings }).then(() => {
      enqueueSnackbar(translation('notification.correctly_save'), {
        variant: 'success'
      });
    });
  };
  const generateLink = () => {
    setLoading(true);
    createDynamicLink(title, description, currentRestaurant.id)
      .then(response => {
        saveConfiguration({
          ...settings,
          link: response.data.shortLink
        });
        updateSettings(response.data.shortLink, 'link');
      })
      .finally(() => setLoading(false));
  };
  const deleteLink = () => {
    saveConfiguration({
      ...settings,
      link: ''
    });
    updateSettings('', 'link');
  };
  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const saveMinimumPurchase = async () => {
    try {
      let response = {};
      setLoading(true);
      setConfirmation(false);
      if (isMinimumPurchase) {
        response = await updateMinimumPurchase(
          currentRestaurant.id,
          minimumPurchase
        );
      } else {
        response = await updateMinimumPurchase(currentRestaurant.id, 0);
      }
      if (response.data.success) {
        enqueueSnackbar(translation('notification.correctly_save'), {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(translation('notification.error_on_save'), {
          variant: 'error'
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar(translation('notification.error_on_save'), {
        variant: 'error'
      });
      setLoading(false);
    }
  };
  const getTitleLengthAvailable = () => MAX_TITLE - title.length;
  const getDescriptionLengthAvailable = () =>
    MAX_DESCRIPTION - description.length;

  return (
    <Card container spacing={1}>
      <BoxLoading isLoading={loading} mt={2}>
        <CardHeader title={translation('admin_rest.restaurant.settings')} />
        <Divider />
        <Box
          className={classes.boxContainer}
          style={{ paddingBottom: 0 }}
          mb={1}
        >
          <Typography variant="h3" color="textPrimary">
            {translation('restaurant.settings.minimum_purchase.title')}
          </Typography>
        </Box>
        <Box className={classes.boxContainer} mb={1}>
          <Typography variant="h5" color="textPrimary">
            {translation(
              'restaurant.settings.minimum_purchase.is_minimum_purchase'
            )}
          </Typography>
          <Switch2
            checked={isMinimumPurchase}
            onChange={() => {
              setIsMinimumPurchase(!isMinimumPurchase);
            }}
          />
        </Box>
        <Box style={{ paddingLeft: 20 }} mb={1}>
          {isMinimumPurchase && (
            <Box mb={3}>
              <TextField
                required
                type="number"
                label={translation(
                  'restaurant.settings.minimum_purchase.amount'
                )}
                name="amount"
                variant="outlined"
                value={minimumPurchase}
                onChange={e => setMinimumPurchase(e.target.value)}
                inputProps={{ min: 0 }}
              />
              <FormHelperText error>
                {minimumPurchase === ''
                  ? translation('restaurant.settings.minimum_purchase.required')
                  : ''}
              </FormHelperText>
            </Box>
          )}
          <Box style={{ paddingLeft: 10 }} mb={3}>
            <Button
              // disabled={disabledSave}
              color="secondary"
              type="submit"
              variant="contained"
              onClick={() => setConfirmation(true)}
              disabled={
                (minimumPurchase === '' && isMinimumPurchase) ||
                (currentRestaurant.minimum_purchase ===
                  parseInt(minimumPurchase, 10) &&
                  isMinimumPurchase) ||
                (currentRestaurant.minimum_purchase === 0 &&
                  !isMinimumPurchase) ||
                (!currentRestaurant.minimum_purchase && !isMinimumPurchase)
              }
            >
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.buttonText}
              >
                {translation(
                  'restaurant.settings.minimum_purchase.save'
                ).toUpperCase()}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box hidden>
          <Divider />
          {false && (
            <Box className={classes.boxContainer} mb={1}>
              <Typography variant="h4" color="textPrimary">
                {translation('admin_rest.restaurant.ask_before_delivery')}
              </Typography>
              <Switch
                checked={settings.askBeforeDelivery}
                onChange={() => {
                  updateSettings(
                    !settings.askBeforeDelivery,
                    'askBeforeDelivery'
                  );
                }}
              />
            </Box>
          )}

          <Box
            className={classes.boxContainer}
            style={{ paddingBottom: 0 }}
            mb={1}
          >
            <Typography variant="h3" color="textPrimary">
              {translation('admin_rest.restaurant.social_link')}
            </Typography>
          </Box>
          {settings.link ? (
            <Box
              className={classes.boxContainer}
              style={{ paddingTop: 0 }}
              mb={1}
            >
              <Link style={{ marginRight: 20 }}>{settings.link}</Link>
              <IconButton
                size={14}
                icon={<FileCopyIcon style={{ color: 'white' }} />}
                aria-label="close"
                className={classes.closeButton}
                onClick={() => copyToClipboard(settings.link)}
              />
              <IconButton
                size={24}
                src={Icons.close}
                aria-label="close"
                className={classes.closeButton}
                onClick={() => deleteLink()}
              />
            </Box>
          ) : (
            <Box className={classes.boxContainer} mb={1}>
              <Box mb={3}>
                <Box mb={3}>
                  <TextField
                    required
                    className={classes.textField}
                    label={translation('admin_rest.restaurant.title')}
                    name="title"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    inputProps={{ maxLength: MAX_TITLE }}
                  />
                  <Typography className={classes.feedback}>
                    {`${getTitleLengthAvailable()} ${translation(
                      'marketing.chars_available'
                    )}`}
                  </Typography>
                </Box>
                <Box mb={4}>
                  <TextField
                    required
                    className={classes.textField}
                    rows={4}
                    multiline
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={translation('admin_rest.restaurant.description')}
                    name="decription"
                    variant="outlined"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    inputProps={{ maxLength: MAX_DESCRIPTION }}
                  />
                  <Typography className={classes.feedback}>
                    {`${getDescriptionLengthAvailable()} ${translation(
                      'marketing.chars_available'
                    )}`}
                  </Typography>
                </Box>
                <Box
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  mb={3}
                >
                  {!loading && (
                    <Button
                      disabled={!(title && description)}
                      color="secondary"
                      type="submit"
                      variant="contained"
                      onClick={() => generateLink()}
                    >
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.buttonText}
                      >
                        {translation(
                          'admin_rest.restaurant.generate_link'
                        ).toUpperCase()}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {showButtonSave && (
            <Grid container style={{ margin: 32 }} justify="center">
              <Button
                disabled={!(title && description)}
                style={{
                  backgroundColor: COLORS.primary,
                  width: 193,
                  height: 48,
                  borderRadius: 7
                }}
                size="small"
                type="submit"
                variant="contained"
                onClick={() => saveConfiguration()}
              >
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontWeight: 'bold', fontSize: 16 }}
                >
                  {translation('forms.save_changes').toUpperCase()}
                </Typography>
              </Button>
            </Grid>
          )}
        </Box>
        <AlertDialog
          open={confirmation}
          question={
            isMinimumPurchase
              ? translation(`restaurant.settings.minimum_purchase.question_1`)
              : translation(`restaurant.settings.minimum_purchase.question_2`)
          }
          handleCancel={() => {
            setMinimumPurchase(currentRestaurant.minimum_purchase);
            if (currentRestaurant.minimum_purchase === 0) {
              setIsMinimumPurchase(false);
            } else {
              setIsMinimumPurchase(true);
            }
            if (!currentRestaurant.minimum_purchase) setMinimumPurchase('');
            setConfirmation(false);
          }}
          handleAccept={() => saveMinimumPurchase()}
          acceptLabel={translation('accept')}
          cancelLabel={translation('cancel')}
        >
          <Typography as="body">{currentRestaurant.name}</Typography>
        </AlertDialog>
      </BoxLoading>
    </Card>
  );
};

export default RestaurantSettingsView;
