/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary,no-unneeded-ternary,prefer-destructuring */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { CheckCircle, Edit2, XCircle } from 'react-feather';
import { allowOnlyNumbersAndDecimals } from '../../../../utils/helpers/fieldFormat';
import { roundTwoDecimals } from '../../../../services/orderServices';

const useStyles = makeStyles(theme => ({
  root: {},
  field: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    wordBreak: 'break-word'
  },
  icon: {
    color: theme.palette.expressColors.green
  }
}));

function AreaPriceItemForm({
  index,
  price,
  onDelete,
  onSave,
  initialyEditing = false,
  errors,
  onRadius
}) {
  const classes = useStyles();
  const translation = useTranslation();
  const [editingItem, setEditingItem] = useState(false);

  useEffect(() => {
    if (initialyEditing) {
      setEditingItem(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item md={3} xs={3}>
        {editingItem ? (
          <TextField
            error={errors && !!errors.distance}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: allowOnlyNumbersAndDecimals
            }}
            helperText={
              errors && errors.distance
                ? translation('admin_areas.item_price.distance.required')
                : ''
            }
            label={translation('admin_areas.item_price.distance.name')}
            name="distance"
            onChange={e => {
              onSave(index, { ...price, distance: e.currentTarget.value * 1 });
            }}
            value={price.distance}
            required
            variant="outlined"
          />
        ) : (
          <Typography className={classes.field} variant="body1">
            {price.distance}
          </Typography>
        )}
      </Grid>
      <Grid item md={2} xs={2}>
        {editingItem ? (
          <TextField
            error={errors && !!errors.price}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: allowOnlyNumbersAndDecimals
            }}
            helperText={
              errors && errors.price
                ? translation('admin_areas.item_price.price.required')
                : ''
            }
            label={translation('admin_areas.item_price.price.name')}
            name="price"
            onChange={e => {
              onSave(index, {
                ...price,
                price:
                  e.currentTarget.value === ''
                    ? e.currentTarget.value * 1
                    : roundTwoDecimals(+e.currentTarget.value) * 1
              });
            }}
            value={price.price}
            required
            variant="outlined"
          />
        ) : (
          <Box className={classes.field}>
            <Typography variant="body1">{price.price}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item md={3} xs={3}>
        {editingItem ? (
          <TextField
            error={errors && !!errors.subsidy}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: allowOnlyNumbersAndDecimals
            }}
            label={translation('admin_areas.item_price.subsidy')}
            name="subsidy"
            onChange={e => {
              onSave(index, { ...price, subsidy: e.currentTarget.value * 1 });
            }}
            value={price.subsidy}
            variant="outlined"
          />
        ) : (
          <Typography className={classes.field} variant="body1">
            {price.subsidy}
          </Typography>
        )}
      </Grid>
      <Grid item md={2} xs={2}>
        {editingItem ? (
          <TextField
            error={errors && !!errors.bonus}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: allowOnlyNumbersAndDecimals
            }}
            label={translation('admin_areas.item_price.bonus')}
            name="bonus"
            onChange={e => {
              onSave(index, { ...price, bonus: e.currentTarget.value * 1 });
            }}
            value={price.bonus}
            variant="outlined"
          />
        ) : (
          <Typography className={classes.field} variant="body1">
            {price.bonus}
          </Typography>
        )}
      </Grid>
      <Grid item md={2} xs={2}>
        <Box display="flex" justifyContent="flex-end">
          {editingItem ? (
            <IconButton
              className={classes.icon}
              type="button"
              onClick={() => {
                if (price.distance) {
                  setEditingItem(false);
                  let priceValue = price.price;
                  if (price.price === '') {
                    priceValue = 0;
                  }
                  onSave(index, {
                    ...price,
                    price: parseFloat(priceValue).toFixed(2) * 1
                  });
                  onRadius();
                }
              }}
            >
              <CheckCircle />
            </IconButton>
          ) : (
            <>
              <IconButton
                className={classes.icon}
                type="button"
                onClick={() => setEditingItem(true)}
              >
                <Edit2 />
              </IconButton>
              <IconButton
                className={classes.icon}
                type="button"
                onClick={() => {
                  onDelete(index);
                  onRadius(index);
                }}
              >
                <XCircle />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AreaPriceItemForm;
