import firebase from 'firebase';
import { COLLECTIONS } from '../constants';
import firebaseService from './firebaseService';

class StoryService {
  saveStory = async story => {
    const id = firebaseService.randomId(COLLECTIONS.STORIES);
    return firebase
      .firestore()
      .collection(COLLECTIONS.STORIES)
      .doc(id)
      .set({ ...story.toPlainObject(), id });
  };

  updateStory = async (id, data) => {
    firebase
      .firestore()
      .collection(COLLECTIONS.STORIES)
      .doc(id)
      .update({ ...data });
  };
}

const storyService = new StoryService();
export default storyService;
