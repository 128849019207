import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  FormHelperText,
  FormControl
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import WithLoading from '../../../../components/WithLoading';
import { useSubsidiariesPerCity } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { editSubsidiary } from '../../../../services/subsidiaryService';
import { useCity } from '../../../../hooks/AdminHooks/cities/useCities';
import MapViewer from '../../../../components/Map/MapViewer';
import { sortSubsidiaryDistance } from '../../../../services/areaServices';
import { useAreasPerCity } from '../../../../hooks/AdminHooks/areas/useAreas';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(() => ({
  root: {},
  formControl: {
    width: '100%'
  },
  mapWrapper: {
    height: 750
  },
  checkbox: {}
}));

// eslint-disable-next-line no-unused-vars
function AreasAssignForm({ className, area, def = {}, ...rest }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const city = useCity(area.city_id);
  const subsidiaries = useSubsidiariesPerCity(area.city_id);
  const areas = useAreasPerCity(area.city_id).filter(a => a.id !== area.id);
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (area.price) {
      const result = Object.values(area.price).map(price =>
        parseInt(price.distance, 10)
      );
      setRadius(Math.max(...result));
    }
  }, [area.price]);

  const addSubsidiary = async sub => {
    setLoading(true);
    await editSubsidiary(sub.id, {
      price_area_id: area.id
    });
    setLoading(false);
  };

  const deleteSubsidiary = async sub => {
    await editSubsidiary(sub.id, {
      price_area_id: ''
    });
  };

  const markers = subsidiaries
    .filter(s => s.price_area_id === area.id && s.location)
    .map(s => ({
      lat: s.location.latitude,
      lng: s.location.longitude
    }));
  const subsidiariesSorted = sortSubsidiaryDistance(area, subsidiaries);

  return (
    <BoxLoading isLoading={loading}>
      <Card>
        <CardContent>
          <Box mb={3}>
            <Typography variant="h4">{area?.name}</Typography>
            <Typography variant="h5">{city?.name}</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              {subsidiariesSorted.map(s => {
                const currentArea = areas.find(a => a.id === s.price_area_id);
                return (
                  <Box mb={3} key={s.id}>
                    <FormControl error={!!currentArea} component="fieldset">
                      <FormControlLabel
                        className={classes.checkbox}
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Checkbox
                            checked={s.price_area_id === area.id}
                            onChange={event => {
                              if (event.target.checked) {
                                addSubsidiary(s);
                              } else {
                                deleteSubsidiary(s);
                              }
                            }}
                          />
                        }
                        label={`${s.name}${
                          s.restaurant_name ? ` - ${s.restaurant_name}` : ''
                        }`}
                      />
                      {!!currentArea && (
                        <FormHelperText>
                          Pertence al area
                          {currentArea.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                );
              })}
            </Grid>
            <Grid item md={8} xs={12}>
              <Box className={classes.mapWrapper}>
                <MapViewer
                  center={area.location}
                  markers={markers}
                  radius={radius}
                />
              </Box>
            </Grid>
            <Grid item />
          </Grid>
        </CardContent>
      </Card>
    </BoxLoading>
  );
}

AreasAssignForm.propTypes = {
  className: PropTypes.string,
  area: PropTypes.object
};

export default AreasAssignForm;
