/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Card,
  Avatar,
  Checkbox,
  Divider,
  Button,
  Typography,
  Box,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-multi-lang/lib';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { useDriverQualificationStyles } from './styles';
import { useRateQualificationOptions } from '../../../hooks/useRateQualificationOptions';
import ButtonCheckRate from './ButtonCheckRate';
import QualificationOptions from '../../../dto/qualificationOptions';
import DriverQualification from '../../../dto/driverQualification';
import { updateOrder } from '../../../services/orderServices';

const RateQualificationDriver = ({ order }) => {
  const classes = useDriverQualificationStyles();
  const translation = useTranslation();
  const history = useHistory();
  const [rateType, setRateType] = useState('');
  const [options, setOptions] = useState([]);
  const { liked, disliked } = useRateQualificationOptions();
  const [comment, setComment] = useState('');

  const updateCategories = index => {
    const tmpOptions = [...options];
    tmpOptions[index] = {
      ...tmpOptions[index],
      check: !tmpOptions[index].check
    };
    setOptions(tmpOptions);
  };

  const sendRateDriver = async () => {
    const optionsQualification = [];
    options.forEach(option => {
      const qualificationOptions = new QualificationOptions(
        option.id,
        option.label,
        option.labelTranslated
      );
      if (option.check) optionsQualification.push({ ...qualificationOptions });
    });
    try {
      const driverQualification = new DriverQualification(
        rateType === 'like',
        optionsQualification,
        comment
      );
      await updateOrder(order.id, {
        driver_qualification: {
          ...order.driver_qualification,
          merchant: { ...driverQualification }
        }
      });
      history.push(`/app/orders/${order.id}`);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Card className={clsx(classes.root)}>
      <Box className={classes.header}>
        <Typography className={clsx(classes.text, classes.noMarging)}>
          <strong>
            {translation('orders.details.driver_qualification.title')}
          </strong>
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box display="flex" justifyContent="center">
          <Typography>
            <strong>
              {order.driver.first_name} {order.driver.last_name}
            </strong>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-evenly">
          <Checkbox
            name="checkedLiked"
            icon={
              <Avatar className={classes.avatar}>
                <FaRegThumbsUp
                  fontSize="inherit"
                  style={{ fontSize: '25px' }}
                />
              </Avatar>
            }
            checkedIcon={
              <Avatar className={classes.avatarLiked}>
                <FaRegThumbsUp
                  fontSize="inherit"
                  style={{ fontSize: '25px' }}
                />
              </Avatar>
            }
            checked={rateType === 'like'}
            onChange={() => {
              setRateType('like');
              setOptions(liked);
            }}
          />
          <Checkbox
            name="checkedDisliked"
            icon={
              <Avatar className={classes.avatar}>
                <FaRegThumbsDown
                  fontSize="inherit"
                  style={{ fontSize: '25px' }}
                />
              </Avatar>
            }
            checkedIcon={
              <Avatar className={classes.avatarDisliked}>
                <FaRegThumbsDown
                  fontSize="inherit"
                  style={{ fontSize: '25px' }}
                />
              </Avatar>
            }
            checked={rateType === 'dislike'}
            onChange={() => {
              setRateType('dislike');
              setOptions(disliked);
            }}
          />
        </Box>
        {rateType !== '' && (
          <>
            <Divider />
            <Box
              style={{
                padding: 24,
                flexWrap: 'wrap',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {options.map((option, index) => (
                <ButtonCheckRate
                  danger={rateType === 'dislike'}
                  check={option.check}
                  onClick={() => updateCategories(index)}
                  title={option.label}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                />
              ))}
            </Box>
            <Box>
              <Box mb={1}>
                <Typography variant="body1" color="inherit">
                  {translation(
                    'orders.details.driver_qualification.additional_comments'
                  )}
                </Typography>
              </Box>
              <Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={e => setComment(e.target.value)}
                  value={comment}
                />
              </Box>
            </Box>
            <Divider />
            <Box mt={2} display="flex" justifyContent="center">
              <Button color="primary" onClick={() => setRateType('')}>
                <Typography className={classes.textbutton}>
                  {translation(
                    'orders.details.driver_qualification.rate_later'
                  )}
                </Typography>
              </Button>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => sendRateDriver()}
              >
                {translation('orders.details.driver_qualification.rate_now')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};

export default RateQualificationDriver;
