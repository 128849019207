import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import XpressModal from '../../../../components/Modal';
import {
  updateOrder,
  sentInvitesToDrivers
} from '../../../../services/orderServices';
import {
  ORDER_STATUS,
  ORDER_TYPE,
  DELIVERY_STATUS,
  LOG_ACTION
} from '../../../../dto/enum';
import DriverAssignmentModal from '../../../orders/OrderDetailsView/modal/DriverAsignmentModal';
import WithLoading from '../../../../components/WithLoading';
import { useCurrentUser } from '../../../../hooks/useUser';

const BoxLoading = WithLoading(Box);
const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex'
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonText: {
    fontWeight: 'bold'
  },
  dangerButton: {
    backgroundColor: theme.palette.expressColors.lightRed,
    color: theme.palette.expressColors.white
  },
  verifiedButton: {
    backgroundColor: theme.palette.expressColors.lightBlue,
    color: theme.palette.expressColors.white
  }
}));

const buttonsIds = {
  cancelAndReorder: 0,
  changeOrderType: 1,
  verified: 2,
  reassigne: 3,
  cancel: 4,
  accept: 5
};

const OrderCockpit = ({ order }) => {
  const translation = useTranslation();
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.paramsOrder);

  const setVerifiedOrder = async () => {
    try {
      setLoading(true);
      await updateOrder(order.id, { verified: true });
      await sentInvitesToDrivers(order.id);
      history.push(id);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.log(error);
      await updateOrder(order.id, { verified: false });
      setLoading(false);
    }
  };

  const setCancelOrder = () => {
    updateOrder(
      order.id,
      {
        status: ORDER_STATUS.CANCELED,
        canceled_reason: translation('orders.admin_cockpit.canceled_reason'),
        canceled_by: { id: currentUser.id, name: currentUser.first_name },
        canceled_from: 'MERCHANT'
      },
      LOG_ACTION.STATUS_CHANGED
    );
  };

  const setAcceptOrder = () => {
    updateOrder(order.id, {
      status: ORDER_STATUS.ACCEPTED,
      accepted_time: new Date()
    });
  };

  const setOrderType = () => {
    const type =
      order.order_type === ORDER_TYPE.PICK_UP
        ? ORDER_TYPE.DELIVERY
        : ORDER_TYPE.PICK_UP;

    let updateFields = { order_type: type };
    if (type === ORDER_TYPE.PICK_UP) {
      updateFields = {
        ...updateFields,
        delivery_status: DELIVERY_STATUS.UNASSIGNED,
        driver: {},
        driver_id: ''
      };
    }
    updateOrder(order.id, updateFields);
  };

  const setDriver = () => {
    setShow(true);
  };

  const defineAction = actionId => {
    switch (actionId) {
      case buttonsIds.cancelAndReorder:
        return true;
      case buttonsIds.changeOrderType:
        return setOrderType();
      case buttonsIds.verified:
        return setVerifiedOrder();
      case buttonsIds.reassigne:
        return setDriver();
      case buttonsIds.cancel:
        return setCancelOrder();
      case buttonsIds.accept:
        return setAcceptOrder();
      default:
        console.log('unhadled actionId', actionId);
        break;
    }
  };

  const confirmAction = actionId => {
    if (actionId === buttonsIds.reassigne) defineAction(actionId);
    else {
      setShowModal(true);
      setModal(
        <XpressModal
          type="comfirmation"
          message={{
            header: translation('orders.admin_cockpit.modal_message'),
            btnLeft: translation('notification.yes'),
            btnRight: translation('notification.no')
          }}
          handleConfirm={() => {
            setShowModal(false);
            defineAction(actionId);
          }}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      );
    }
  };

  return (
    <Box>
      <DriverAssignmentModal
        order={order}
        show={show}
        onClose={() => setShow(false)}
      />
      <BoxLoading isLoading={loading} mt={2}>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled
            onClick={() => confirmAction(buttonsIds.cancelAndReorder)}
          >
            <Typography className={classes.buttonText}>
              {translation('orders.admin_cockpit.cancel_reorder')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => confirmAction(buttonsIds.changeOrderType)}
            disabled
          >
            <Typography className={classes.buttonText}>
              {order.order_type === ORDER_TYPE.DELIVERY
                ? translation('orders.admin_cockpit.change_to_pick')
                : translation('orders.admin_cockpit.change_to_driver')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.verifiedButton)}
            onClick={() => confirmAction(buttonsIds.verified)}
          >
            <Typography className={classes.buttonText}>
              {translation('orders.admin_cockpit.order_verified')}
            </Typography>
          </Button>
        </Box>
        <Box mt={2} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.dangerButton)}
            onClick={() => confirmAction(buttonsIds.reassigne)}
          >
            <Typography className={classes.buttonText}>
              {translation('orders.admin_cockpit.re_assigne')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.dangerButton)}
            onClick={() => confirmAction(buttonsIds.cancel)}
          >
            <Typography className={classes.buttonText}>
              {translation('orders.admin_cockpit.cancel')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.dangerButton)}
            onClick={() => confirmAction(buttonsIds.accept)}
          >
            <Typography className={classes.buttonText}>
              {translation('orders.admin_cockpit.accept')}
            </Typography>
          </Button>
        </Box>
      </BoxLoading>
      {showModal && modal}
    </Box>
  );
};

export default OrderCockpit;
