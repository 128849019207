import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  Grid,
  Card,
  makeStyles,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { useCurrentSubsidiary } from '../../../hooks/useRestaurant';
import { DELIVERY_STATUS } from '../../../dto/enum';
import {
  updateOrder,
  getNearByDriversByRoute,
  removeOrderInvites
} from '../../../services/orderServices';
import WithLoading from '../../../components/WithLoading';
import { COLORS } from '../../../constants';
import XpressModal from '../../../components/Modal';
import env from '../../../env';

const TableCell = withStyles({
  root: {
    border: `1px solid ${COLORS.disabledGray}`,
    paddingLeft: 37,
    paddingRight: 58
  }
})(MuiTableCell);

const useStyles = makeStyles(theme => ({
  cardsContainer: {
    display: 'flex'
  },
  clientContainer: {
    marginRight: theme.spacing(1)
  },
  driverContainer: {
    marginLeft: theme.spacing(1)
  },
  header: {
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`
  },
  cardContainer: {
    padding: '16px 21px'
  },
  text: {
    fontSize: 20,
    paddingBottom: 21
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  button: {
    backgroundColor: theme.palette.expressColors.green,
    color: theme.palette.expressColors.white,
    fontWeight: 'bold',
    fontSize: 16,
    padding: '13px 17px',
    borderRadius: 7
  },
  cancelButton: {
    fontWeight: 'bold'
  },
  tableProximity: {
    padding: 0
  },
  tableName: {
    padding: 0
  },
  cardContent: {
    height: 246
  },
  nameCell: {
    padding: 0,
    paddingLeft: theme.spacing(2)
  },
  nameTextCell: {
    fontSize: 16
  },
  proxCellButton: {
    height: 35,
    width: 230
  },
  proxiCell: {
    padding: theme.spacing(1)
  },
  tableScroll: {
    overflowY: 'scroll'
  },
  headerCell: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  driverAssigned: {
    backgroundColor: theme.palette.expressColors.greenCake
  }
}));

const BoxLoading = WithLoading(Box);

const DriverSelector = ({ order, onClose }) => {
  const translation = useTranslation();
  const classes = useStyles();
  const sub = useCurrentSubsidiary();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);

  useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      const currentDrivers = await getNearByDriversByRoute(
        sub.id || order.subsidiary_id
      );
      setDrivers(currentDrivers.data);
      setLoading(false);
    };
    fetchDrivers();
  }, [sub, order]);

  const assignDriver = async driver => {
    // TODO: MOVE THIS TO BACKEND
    const deliveryStatus =
      order.delivery_status === DELIVERY_STATUS.ON_PLACE
        ? order.delivery_status
        : DELIVERY_STATUS.GOING_TO_PICK;
    const data = {
      driver_id: driver.id,
      delivery_status: deliveryStatus,
      driver: {
        ...driver
      }
    };
    setLoading(true);
    try {
      await removeOrderInvites(order.id);
      await updateOrder(order.id, data);
      setLoading(false);
      onClose();
      enqueueSnackbar(translation('dealers.success'), {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar(
        `${translation('complete_restaurant.error')} ${
          env.REACT_APP_SUPPORT_MAIL
        }`,
        {
          variant: 'error'
        }
      );
      onClose();
      setLoading(false);
    }
  };

  const confirmModal = driver => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="comfirmation"
        message={{
          header: translation('orders.admin_cockpit.modal_message'),
          btnLeft: translation('notification.yes'),
          btnRight: translation('notification.no')
        }}
        handleConfirm={() => {
          setShowModal(false);
          assignDriver(driver);
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  return (
    <BoxLoading isLoading={loading}>
      <Box className={clsx(classes.cardsContainer)}>
        <Grid item xs={6} md={6} xl={6}>
          <Box className={clsx(classes.clientContainer)}>
            <Card>
              <Box className={clsx(classes.header)}>
                <Typography variant="h4">
                  <strong>
                    {translation('orders_view.driver_modal.client_card.title')}
                  </strong>
                </Typography>
              </Box>
              <Box className={clsx(classes.cardContent)} pl={2} pt={2}>
                <Typography className={clsx(classes.text)}>
                  <strong>
                    {translation('orders_view.driver_modal.client_card.name')}:
                  </strong>
                  <span>&nbsp;&nbsp;</span>
                  {order.user_full_name}
                </Typography>
                <Typography className={clsx(classes.text)}>
                  <strong>
                    {translation('orders_view.driver_modal.client_card.phone')}:
                  </strong>
                  <span>&nbsp;&nbsp;</span>
                  {order.user_phone.substr(4)}
                </Typography>
                <Typography className={clsx(classes.text)}>
                  <strong>
                    {translation(
                      'orders_view.driver_modal.client_card.address'
                    )}
                    :
                  </strong>
                  <span>&nbsp;&nbsp;</span>
                  {order.delivery_location.address}
                </Typography>
                <Typography className={clsx(classes.text)}>
                  <strong>
                    {translation(
                      'orders_view.driver_modal.client_card.delivery_charge'
                    )}
                    :
                  </strong>
                  <span>&nbsp;&nbsp;</span>Bs. {order.delivery_fee}
                </Typography>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} xl={6}>
          <Box className={clsx(classes.driverContainer)}>
            <Card>
              <Box className={clsx(classes.header)}>
                <Typography variant="h4">
                  <strong>
                    {translation('orders_view.driver_modal.driver_card.title')}
                  </strong>
                </Typography>
              </Box>
              <Box
                className={clsx(classes.cardContent, {
                  [classes.tableScroll]: drivers.length > 4
                })}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={clsx(classes.headerCell)}>
                        <Typography variant="h5">
                          <strong>
                            {translation(
                              'orders_view.driver_modal.driver_card.name'
                            )}
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell className={clsx(classes.headerCell)}>
                        <Typography variant="h5">
                          <strong>
                            {translation(
                              'orders_view.driver_modal.driver_card.proximity'
                            )}
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {drivers.map((driver, index) => {
                      const label =
                        driver.orders_in_process &&
                        driver.orders_in_process.length
                          ? `${driver.distance.text} con ordenes`
                          : `${driver.distance.text} assignar`;
                      const orders =
                        driver.orders_in_process &&
                        driver.orders_in_process.length
                          ? driver.orders_in_process
                              .map(o => o.order_code)
                              .join(', ')
                          : '-';
                      return (
                        <TableRow
                          className={clsx({
                            [classes.driverAssigned]:
                              order.driver_id === driver.id
                          })}
                          key={driver.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={clsx(classes.nameCell)}
                          >
                            <Typography className={clsx(classes.nameTextCel)}>
                              <strong>{index + 1}</strong>
                              <span>&nbsp;&nbsp;</span>
                              {`${driver.first_name} ${driver.last_name}`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={clsx(classes.proxiCell)}
                          >
                            <Tooltip title={orders} placement="right">
                              <Button
                                className={clsx(
                                  classes.button,
                                  classes.proxCellButton
                                )}
                                variant="contained"
                                onClick={() => confirmModal(driver)}
                              >
                                {label}
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Box>
      <Grid item md={12} xs={12} className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={clsx(classes.cancelButton)}
          onClick={onClose}
        >
          {translation('orders_view.driver_modal.buttons.cancel')}
        </Button>
      </Grid>
      {showModal && modal}
    </BoxLoading>
  );
};

export default DriverSelector;
