import geohash from 'ngeohash';
import { getDistance } from 'geolib';
import admin from '../config/firebaseConfig';
import firebaseService from './firebaseService';
import { COLLECTIONS } from '../constants';
import { MAX_LENGTH } from './dealerService';

const COLLECTION = COLLECTIONS.AREAS;

// eslint-disable-next-line import/prefer-default-export
export const saveAreaDB = async (id, data) => {
  const latitude = -16.489689;
  const longitude = -68.119293;
  const value = {
    ...data,
    price: data.price.map(p => ({
      price: p.price * 1,
      distance: p.distance,
      subsidy: p.subsidy || 0,
      bonus: p.bonus || 0
    })),
    enable: true,
    geohash: geohash.encode(latitude, longitude),
    location: new admin.firestore.GeoPoint(latitude, longitude)
  };

  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .update(value);
};
export const updateAreaDB = async id => {
  return admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .delete();
};

export const getArea = async id => {
  const area = await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .get();
  return area.data();
};

export const createAreaDb = async data => {
  const latitude = -16.489689;
  const longitude = -68.119293;
  const id = firebaseService.randomId(COLLECTION);
  const value = {
    ...data,
    enable: true,
    price: data.price.map(p => ({
      price: p.price * 1,
      distance: p.distance,
      subsidy: p.subsidy || 0,
      bonus: p.bonus || 0
    })),
    geohash: geohash.encode(latitude, longitude),
    location: new admin.firestore.GeoPoint(latitude, longitude)
  };
  await admin
    .firestore()
    .collection(COLLECTION)
    .doc(id)
    .set({ id, ...value });
  const newArea = await getArea(id);
  return newArea;
};
export const sortSubsidiaryDistance = (area, subsidiaries) => {
  if (!area.location) return subsidiaries;
  const subsidiariesDistance = subsidiaries.map(d => {
    let distanceToSubsidiary = MAX_LENGTH;
    if (d.location) {
      distanceToSubsidiary = getDistance(
        {
          latitude: area.location.lat,
          longitude: area.location.lng
        },
        {
          latitude: d.location.latitude,
          longitude: d.location.longitude
        }
      );
    }
    return {
      ...d,
      distanceToSubsidiary
    };
  });

  return subsidiariesDistance.sort((a, b) => {
    // return b.distanceToSubsidiary - a.distanceToSubsidiary;
    return a.distanceToSubsidiary - b.distanceToSubsidiary;
  });
};
