import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { AiOutlineFileImage } from 'react-icons/ai';
import Dropzone from 'react-dropzone';
import nanoMetadata from 'nano-metadata';
import firebase from 'firebase/app';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import XpressModal from '../../../components/Modal';
import { COLORS } from '../../../theme/colors';
import 'firebase/storage';
import { useCurrentUser } from '../../../hooks/useUser';
import storyService from '../../../services/storyService';
import Media from '../../../dto/stories/media';
import StoryModel from '../../../dto/stories/stories';
import { MEDIA_TYPE } from '../../../constants';
import WithLoading from '../../../components/WithLoading';
import ModalInfo from '../../../components/Modal/ModalInfo';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.yellowLink,
    width: '54%',
    marginLeft: 0,
    marginRight: 0
  },
  iconInfo: {
    marginLeft: 10,
    color: COLORS.yellowLink,
    cursor: 'pointer',
    width: 26,
    height: 26
  },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '35%',
    marginRight: '35%',
    marginTop: '10%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  styleIcon: {
    color: COLORS.green,
    width: '100%',
    height: '100%'
  },
  styleText: {
    fontSize: '10px',
    textDecoration: 'underline',
    textAlign: 'center'
  },
  styleTextError: {
    fontSize: '10px',
    color: COLORS.danger,
    textAlign: 'center'
  },
  smallBox: {
    height: 34,
    marginTop: 20
  },
  box: {
    height: 60,
    marginBottom: '8px'
  },
  dropzone: {
    height: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px dashed',
    borderColor: COLORS.green,
    paddingTop: 20,
    paddingBottom: 20
  },
  buttonStyle: {
    marginTop: 20
  },
  boxStyle: {
    marginTop: 12
  },
  imagePreview: {
    justifyContent: 'center'
  },
  imgStyle: {
    objectFit: 'contain',
    width: '300px',
    height: '300px'
  }
}));

const BoxLoading = WithLoading(Box);

const Story = ({ name, photo, city, phoneNumber }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [fileToSave, setFileToSave] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorFile, setErrorFile] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const saveStory = async story => {
    await storyService.saveStory(story);
  };
  const createDataStory = async (file, type) => {
    const storageRef = firebase
      .storage()
      .ref(`story/${currentUser.id}-${new Date().getTime()}.mp4`);
    const res = await storageRef.put(file);
    const url_file = await res?.ref?.getDownloadURL();
    const media = new Media({ type, url_original: url_file });
    const story = new StoryModel({
      user_id: currentUser.id,
      media,
      phone_number: phoneNumber || '',
      full_name: name,
      profile_photo: photo,
      city_name: city,
      enable: true
    });
    return saveStory(story);
  };
  const handleDropGallery = async files => {
    try {
      const file = files[0];
      const [currentType] = file.type.split('/');
      if (file && (currentType === 'image' || currentType === 'video')) {
        if (currentType === 'video') {
          const duration = await nanoMetadata.video.duration(file);
          if (duration <= 15) {
            await setFileToSave({ file, type: MEDIA_TYPE.VIDEO });
          } else {
            setErrorFile('El video no puede durar mas de 15 seg.');
            enqueueSnackbar('El video no puede durar mas de 15 seg.', {
              variant: 'error'
            });
          }
        } else {
          await setFileToSave({ file, type: MEDIA_TYPE.PHOTO });
        }
      } else {
        setErrorFile(
          'No se puede subir este formato de archivo, formatos permitidos .png, .jpeg, .mp4'
        );
        enqueueSnackbar(
          'No se puede subir este formato de archivo, formatos permitidos .png, .jpeg, .mp4',
          {
            variant: 'error'
          }
        );
      }
    } catch (e) {
      setErrorFile('No se pudo cargar su archivo');
      enqueueSnackbar('No se pudo cargar su archivo', {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    let fileReader;
    let isCancel = false;
    if (fileToSave?.file) {
      fileReader = new FileReader();
      fileReader.onload = e => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileToSave?.file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToSave?.file]);

  const clearModal = () => {
    setShowModal(false);
    setFileToSave(null);
    setFileDataURL(null);
    setErrorFile('');
  };

  const saveMedia = async () => {
    setLoading(true);
    try {
      await createDataStory(fileToSave.file, fileToSave.type);
      enqueueSnackbar('Se subio la Historia correctamente', {
        variant: 'success'
      });
      clearModal();
    } catch (e) {
      enqueueSnackbar('No se pudo guardar su estado', {
        variant: 'error'
      });
    }
    setLoading(false);
  };
  const points = [
    {
      text: '¿Cómo funcionan las historias XPRESS?',
      bold: true
    },
    {
      text: translation('info.body.points_1'),
      bold: false
    },
    {
      text: translation('info.body.points_2'),
      bold: false
    },
    {
      text: translation('info.body.points_3'),
      bold: false
    },
    {
      text: translation('info.body.points_4'),
      bold: false
    },
    {
      text: translation('info.body.points_5'),
      bold: false
    },
    {
      text: translation('info.body.points_6'),
      bold: false
    },
    {
      text: translation('info.body.points_7'),
      bold: false
    },
    {
      text: 'Políticas de uso',
      bold: true
    },
    {
      text: translation('info.body.points_8'),
      bold: false
    },
    {
      text: translation('info.body.points_9'),
      bold: false
    },
    {
      text: translation('info.body.points_10'),
      bold: false
    }
  ];
  return (
    <div>
      <ModalInfo
        title="Añade una Historia"
        isVisible={showModalInfo}
        description=""
        confirm={() => setShowModalInfo(false)}
        onClose={() => setShowModalInfo(false)}
        component={
          <Box className={classes.boxStyle}>
            {points.map((point, index) => (
              <Typography
                key={index}
                style={{
                  fontWeight: point.bold ? 'bold' : 'normal',
                  fontSize: 14
                }}
              >
                {point.text}
              </Typography>
            ))}
          </Box>
        }
      />
      <Box display="flex">
        <Button className={classes.button} onClick={() => setShowModal(true)}>
          <Typography style={{ fontWeight: 'bold', fontSize: 10 }}>
            Añade una Historia
          </Typography>
        </Button>
        <InfoOutlinedIcon
          className={classes.iconInfo}
          onClick={() => setShowModalInfo(true)}
        />
      </Box>

      {showModal && (
        <XpressModal handleConfirm={e => handleDropGallery(e)}>
          <Grid>
            <Card className={classes.content}>
              <CardHeader
                className={classes.Header}
                title={
                  <Typography style={{ fontWeight: 'bold' }}>
                    Añada una Historia
                  </Typography>
                }
              />
              <Divider />
              <BoxLoading isLoading={loading}>
                <CardContent>
                  {!fileDataURL ? (
                    <Dropzone onDrop={handleDropGallery}>
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps({ className: classes.dropzone })}>
                          <input
                            {...getInputProps()}
                            accept=".png, .jpeg, .mp4"
                          />
                          <Box mb={1.6} className={classes.smallBox}>
                            <AiOutlineFileImage className={classes.styleIcon} />
                          </Box>
                          <Box>
                            <Typography style={{ textAlign: 'center' }}>
                              Arrastra la Imagen o Video
                            </Typography>
                            <Typography style={{ textAlign: 'center' }}>
                              {translation('central_payments.documents.here')}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.styleText}>
                              {translation(
                                'central_payments.documents.upload_from_device'
                              )}
                            </Typography>
                          </Box>
                          {errorFile && (
                            <Typography className={classes.styleTextError}>
                              {errorFile}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  ) : (
                    <Box align="center" className={classes.imagePreview}>
                      {fileToSave.type === MEDIA_TYPE.PHOTO ? (
                        <img
                          src={fileDataURL}
                          alt="preview"
                          className={classes.imgStyle}
                        />
                      ) : (
                        <video
                          controls
                          autoPlay
                          src={fileDataURL}
                          className={classes.imgStyle}
                        >
                          <track default kind="captions" />
                        </video>
                      )}
                      <IconButton
                        onClick={() => {
                          setFileToSave(null);
                          setFileDataURL(null);
                          setErrorFile('');
                        }}
                      >
                        <DeleteForever style={{ color: COLORS.danger }} />
                      </IconButton>
                    </Box>
                  )}
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.buttonStyle}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={saveMedia}
                      disabled={!fileToSave}
                    >
                      Guardar Estado
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={clearModal}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </CardContent>
              </BoxLoading>
            </Card>
          </Grid>
        </XpressModal>
      )}
    </div>
  );
};

export default Story;
