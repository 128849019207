import React, { useState, useEffect } from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import Page from '../../components/Page';
import SelectedDelivery from './SelectedDelivery';
import SubsidyDelivery from './subsidyDelivery';
import DeliveryFree from './deliveryFree';
import { useRestaurant, useCurrentSubsidiary } from '../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const Delivery = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentSubsidiary = useCurrentSubsidiary();
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [subsidyDelivery, setSubsidyDelivery] = useState(true);
  const currentRestaurant = useRestaurant();
  const [subsidiary, setSubsidiary] = useState({
    name: currentSubsidiary.name,
    id: currentSubsidiary.id
  });

  useEffect(() => {
    if (!currentRestaurant.hasSubsidiarySubsidy) {
      history.push('/app/orders');
    }
    // eslint-disable-next-line
  }, [currentRestaurant.hasSubsidiarySubsidy]);

  useEffect(() => {
    if (currentRestaurant.deliveryFree.chargeByXpress) {
      setSubsidyDelivery(true);
      setFreeDelivery(false);
    }
  }, [currentRestaurant.deliveryFree.chargeByXpress]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Box mb={2} mt={2}>
          {!currentRestaurant.deliveryFree.chargeByXpress && (
            <SelectedDelivery
              setFreeDelivery={setFreeDelivery}
              freeDelivery={freeDelivery}
              subsidyDelivery={subsidyDelivery}
              setSubsidyDelivery={setSubsidyDelivery}
            />
          )}
        </Box>
        {subsidyDelivery && (
          <SubsidyDelivery
            restaurantId={currentRestaurant.id}
            setSubsidiary={setSubsidiary}
            subsidiary={subsidiary}
          />
        )}
        {freeDelivery && (
          <DeliveryFree
            restaurantName={currentRestaurant.name}
            subsidiary={subsidiary}
            deliveryFree={currentRestaurant.deliveryFree}
            restaurantId={currentRestaurant.id}
          />
        )}
      </Container>
    </Page>
  );
};

export default Delivery;
