import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useAdditionsPerRestaurant } from '../../../hooks/useAdditions';
import { useRestaurant } from '../../../hooks/useRestaurant';
import AdditionCard from './AdditionCard';

const AdditionsResults = props => {
  const restaurant = useRestaurant();
  const additions = useAdditionsPerRestaurant(restaurant.id);
  if (!additions.length) return null;
  return (
    <Box>
      <Grid container spacing={3}>
        {additions.map(a => (
          <Grid item sm={6} md={4} xl={3} xs={12} key={a.id}>
            <AdditionCard addition={a} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdditionsResults;
