import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import Product from '../dto/product';
import firebaseService from './firebaseService';

export const addSharedProduct = async (
  id,
  sectionId,
  restaurantId,
  sizeProducts
) => {
  const newProduct = new Product();
  newProduct.id = id;
  newProduct.restaurant_id = restaurantId || '';
  newProduct.menu_section_id = sectionId;
  newProduct.template = true;
  newProduct.menu_order = sizeProducts || 0;
  await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .set({ ...newProduct });

  const subsidiariesSnap = await admin
    .firestore()
    .collection(COLLECTIONS.SUBSIDIARY)
    .where('restaurant_id', '==', restaurantId)
    .get();

  subsidiariesSnap.docs.forEach(async doc => {
    const newId = firebaseService.randomId();
    await admin
      .firestore()
      .collection(COLLECTIONS.PRODUCT)
      .doc(newId)
      .set({
        ...newProduct,
        subsidiary_id: doc.id,
        id: newId,
        product_template_id: id
      });
  });

  return newProduct;
};

export const editSharedComplementaryProducts = async (id, addition) => {
  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .update(addition);

  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .where('product_template_id', '==', id)
    .where('enable', '==', true)
    .get()
    .then(productToUpdateDoc => {
      productToUpdateDoc.docs.forEach(doc => {
        admin
          .firestore()
          .collection(COLLECTIONS.PRODUCT)
          .doc(doc.id)
          .update(addition);
      });
    });
};

export const editSharedProduct = async (fields, id) => {
  await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .update({ ...fields });

  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .where('product_template_id', '==', id)
    .where('enable', '==', true)
    .get()
    .then(productToUpdateDoc => {
      productToUpdateDoc.docs.forEach(doc => {
        admin
          .firestore()
          .collection(COLLECTIONS.PRODUCT)
          .doc(doc.id)
          .update({ ...fields });
      });
    });
};
