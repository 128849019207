import React from 'react';
import { makeStyles } from '@material-ui/core';
import EditMenuSection from './EditMenuSection';
import ModalTemplateCustomHeader from '../../../components/Modal/ModalTemplateCustomHeader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function ModalEditMenuSection({
  show,
  onClose,
  closeModalSave,
  isEditable,
  section,
  currentMenuSectionSchedule,
  menuList,
  setName,
  restId,
  saveName
}) {
  const classes = useStyles();

  return (
    <ModalTemplateCustomHeader
      show={show}
      onClose={onClose}
      className={classes.root}
      size="md"
    >
      <EditMenuSection
        onClose={onClose}
        closeModalSave={closeModalSave}
        isEditable={isEditable}
        section={section}
        menuList={menuList}
        currentMenuSectionSchedule={currentMenuSectionSchedule}
        setName={setName}
        saveName={saveName}
        restId={restId}
      />
    </ModalTemplateCustomHeader>
  );
}

export default ModalEditMenuSection;
