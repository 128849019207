import { getDistance, isPointWithinRadius } from 'geolib';
import { TYPE_DEMAND, COOKED_AVERAGE } from '../../dto/enum';

export const getDeliveryDisabledByCity = (
  subsidiary,
  deliveriesDisabledPerCity
) => {
  let isDisabledByCity = false;
  let deliveryDisabledIds = [];
  deliveriesDisabledPerCity.forEach(deliveryDisabledPerCity => {
    const locationDeliveryDisabled = {
      latitude: deliveryDisabledPerCity.location.latitude,
      longitude: deliveryDisabledPerCity.location.longitude
    };
    const locationSubsidiary = {
      latitude: subsidiary.location.latitude,
      longitude: subsidiary.location.longitude
    };
    const radius = deliveryDisabledPerCity.radius * 1000;
    const isLocationInside = isPointWithinRadius(
      locationSubsidiary,
      locationDeliveryDisabled,
      radius
    );
    const distance = getDistance(locationSubsidiary, locationDeliveryDisabled);
    if (isLocationInside && distance <= radius) {
      deliveryDisabledIds = [
        ...deliveryDisabledIds,
        deliveryDisabledPerCity.id
      ];
    }
  });
  if (deliveryDisabledIds.length > 0) isDisabledByCity = true;
  return isDisabledByCity;
};

const isSubsidiaryModerateDemand = subsidiary => {
  if (
    subsidiary.average_time_accepted >= 7 ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.SLOW ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.VERY_SLOW ||
    subsidiary.owner_comments_for_subsidiary
  ) {
    return true;
  }
  return false;
};

const isSubsidiaryHighDemand = subsidiary => {
  if (
    subsidiary.average_time_accepted >= 7 ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.SLOW ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.VERY_SLOW ||
    subsidiary.owner_comments_for_subsidiary ||
    subsidiary.completed_orders_month <= 5
  ) {
    return true;
  }
  return false;
};

const isSubsidiaryExcessiveDemand = subsidiary => {
  if (
    subsidiary.average_time_accepted >= 7 ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.SLOW ||
    subsidiary.cooked_average.type === COOKED_AVERAGE.VERY_SLOW ||
    subsidiary.owner_comments_for_subsidiary ||
    subsidiary.completed_orders_month <= 5 ||
    subsidiary.amount_months_debt >= 7
  ) {
    return true;
  }
  return false;
};
export const getDeliveryDisabledByZone = (subsidiary, demand) => {
  let isDisabledByDemand = false;
  if (demand === TYPE_DEMAND.MODERATE)
    isDisabledByDemand = isSubsidiaryModerateDemand(subsidiary);
  if (demand === TYPE_DEMAND.HIGH)
    isDisabledByDemand = isSubsidiaryHighDemand(subsidiary);
  if (demand === TYPE_DEMAND.EXCESSIVE)
    isDisabledByDemand = isSubsidiaryExcessiveDemand(subsidiary);
  return isDisabledByDemand;
};
