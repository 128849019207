import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Page from '../../../../components/Page';
import Header from './Header';
import Results from '../../../orders/OrderListView/Results';
import { setParams } from '../../../../actions/paramsActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

// eslint-disable-next-line no-unused-vars
function Orders({ orders, params, type, status }) {
  const classes = useStyles();
  const translation = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('all');

  const tabs = [
    {
      value: 'all',
      label: translation('customer_edit.all')
    },

    {
      value: 'new',
      label: translation('menu.new_order.label')
    },
    {
      value: 'process',
      label: translation('menu.process_order.label')
    },
    {
      value: 'ready',
      label: translation('menu.prepared_order')
    },
    {
      value: 'delivering',
      label: translation('menu.delivering')
    },
    {
      value: 'complete',
      label: translation('menu.completed_order')
    },
    {
      value: 'canceled',
      label: translation('menu.canceled_order')
    }
  ];
  useEffect(() => {
    const value = status || 'all';
    setCurrentTab(value);
    // eslint-disable-next-line
  }, [status]);

  const handleTabsChange = (event, value) => {
    if (value !== 'all') {
      history.push(`/admin/orders/${value}`);
      dispatch(setParams({ id: `/admin/orders/${value}` }));
    } else {
      history.push(`/admin/orders`);
      dispatch(setParams({ id: `/admin/orders` }));
    }
  };
  let columns = {};
  switch (status) {
    case 'new': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true,
        showTimer: true,
        showSupport: true
      };
      break;
    }
    case 'process': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true,
        showTimer: true,
        showSupport: true
      };
      break;
    }
    case 'ready': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true,
        showTimer: true,
        showSupport: true
      };
      break;
    }
    case 'delivering': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true,
        showTimer: true,
        showSupport: true
      };
      break;
    }
    case 'complete': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true
      };
      break;
    }
    case 'canceled': {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true
      };
      break;
    }
    default: {
      columns = {
        showRestaurant: true,
        showType: true,
        showCreatedHour: true,
        showStatus: true,
        showUserPhone: true,
        showDriver: true,
        showVerified: true,
        showTimer: true,
        showSupport: true
      };
      break;
    }
  }

  return (
    <Page className={classes.root} title={translation('orders.page_title')}>
      <Container maxWidth={false}>
        <Header type={type} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          <Results
            orders={orders}
            search
            {...columns}
            params={{ status }}
            // eslint-disable-next-line react/jsx-curly-brace-presence
            link={'/admin/order'}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default Orders;
