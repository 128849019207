/* eslint-disable no-param-reassign,consistent-return,no-unused-vars */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import MapPicker from '../../../../components/Register/MapPicker';
import { createSubsidiary } from '../../../../services/subsidiaryService';
import admin from '../../../../config/firebaseConfig';
import useCategories from '../../../../hooks/useCategories';
import YellowTextField from '../../../../components/Inputs/TextField';
import { GreenCrossButton } from '../../../../components/Inputs/Buttons';
import { useRestaurant } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    padding: '0 32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    flexWrap: 'nowrap'
  },
  container: {
    padding: '16px'
  },
  leftBlock: {
    paddingRight: '12px',
    alignContent: 'baseline'
  },
  middleBlock: {
    paddingRight: '12px',
    paddingLeft: '12px',
    alignContent: 'baseline'
  },
  rigthBlock: {
    paddingLeft: '12px'
  }
}));

const NewSubsidiaryForm = ({ allCities, restId, handleClose, onModal }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const [location, setLocation] = useState();
  const [showMap, setShowMap] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const categories = useCategories();
  const currentRestaurant = useRestaurant(restId);

  return (
    <Formik
      initialValues={{
        name: '',
        manager: '',
        manager_phone: '',
        address: '',
        nit: '',
        address_2: '',
        city: allCities[0].id,
        categories: []
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(translation('subsidiary.required')),
        manager: Yup.string().required(translation('subsidiary.required')),
        manager_phone: Yup.string().required(
          translation('subsidiary.required')
        ),
        address: Yup.string().required(translation('subsidiary.required')),
        nit: Yup.number()
          .positive(translation('subsidiary.number'))
          .required(translation('subsidiary.required')),
        city: Yup.string().required(translation('subsidiary.required')),
        categories: Yup.array()
          .max(10)
          .required(translation(translation('subsidiary.required')))
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          setSubmitting(true);
          if (location) {
            values.location = new admin.firestore.GeoPoint(
              location.lat,
              location.lng
            );
          } else {
            enqueueSnackbar(translation('notification.error_map'), {
              variant: 'error'
            });
            setSubmitting(false);
            return;
          }

          const choosenCity = allCities.find(item => item.id === values.city);
          values.city = { ...choosenCity };
          values.legal_representant = {
            name: values.manager,
            phone: values.manager_phone
          };
          values.restaurant_name = currentRestaurant.name;
          values.banner_photo = { ...currentRestaurant.banner_photo };
          values.logo_photo = { ...currentRestaurant.logo_photo };
          await createSubsidiary(values, restId);
          setStatus({ success: true });
          setSubmitting(false);
          if (handleClose) handleClose();
          enqueueSnackbar(translation('notification.correctly_save'), {
            variant: 'success'
          });
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          resetForm();
          setSubmitting(false);
          enqueueSnackbar(
            error.message
              ? error.message
              : translation('notification.something_go_wrong'),
            {
              variant: 'error'
            }
          );
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => {
        const city = allCities.find(c => c.id === values.city);
        const optionalMapProps = {};
        if (city && city.location) {
          optionalMapProps.center = {
            lat: city.location.latitude,
            lng: city.location.longitude
          };
        }
        return (
          <>
            {isSubmitting && (
              <Box display="flex" justifyContent="center" my={5}>
                <CircularProgress />
              </Box>
            )}
            {!isSubmitting && (
              <Card style={{ width: `${onModal ? '1060px' : '100%'}` }}>
                <Grid item container className={classes.header}>
                  <Grid item container alignContent="center">
                    <Typography variant="h4">
                      {translation('subsidiary.basic_information.head')}
                    </Typography>
                  </Grid>
                  <Grid>
                    <GreenCrossButton size="small" onClick={handleClose} />
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid item container className={classes.container}>
                      <Grid
                        item
                        container
                        xs={4}
                        md={4}
                        className={classes.leftBlock}
                      >
                        <Grid item container>
                          <Box pr={3} marginBottom="24px">
                            <YellowTextField
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              label={translation(
                                'subsidiary.basic_information.name'
                              )}
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.name}
                              variant="outlined"
                            />
                          </Box>
                          <Box>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel>
                                {translation(
                                  'subsidiary.basic_information.city'
                                )}
                              </InputLabel>
                              <Select
                                variant="outlined"
                                label="Ciudad"
                                name="city"
                                id="city"
                                value={values.city}
                                onChange={event => {
                                  setFieldValue('city', event.target.value);
                                }}
                                inputProps={{
                                  style: {
                                    height: '165px'
                                  }
                                }}
                              >
                                {allCities.map(item => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item container>
                          <Box mt={3} width="100%">
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              options={categories}
                              getOptionLabel={s => {
                                if (!s) return;
                                return s.name;
                              }}
                              filterSelectedOptions
                              value={values.categories.map(v =>
                                categories.find(s => v === s.id)
                              )}
                              onChange={(e, cat) => {
                                setFieldValue(
                                  'categories',
                                  cat.map(s => s.id)
                                );
                              }}
                              renderInput={params => (
                                <YellowTextField
                                  {...params}
                                  variant="outlined"
                                  label={translation(
                                    'subsidiary.basic_information.category'
                                  )}
                                  error={Boolean(
                                    touched.categories && errors.categories
                                  )}
                                  helperText={
                                    touched.categories && errors.categories
                                  }
                                  placeholder={translation(
                                    'subsidiary.basic_information.category'
                                  )}
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={4}
                        md={4}
                        className={classes.middleBlock}
                      >
                        <Box width="100%" mb={3}>
                          <YellowTextField
                            fullWidth
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                            label={translation(
                              'subsidiary.basic_information.address'
                            )}
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowMap(true)}>
                                    <LocationOn />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Box>
                        <Box width="100%" mb={3}>
                          <YellowTextField
                            fullWidth
                            error={Boolean(
                              touched.address_2 && errors.address_2
                            )}
                            helperText={touched.address_2 && errors.address_2}
                            label={translation(
                              'subsidiary.basic_information.local_address'
                            )}
                            name="address_2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address_2}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={4}
                        md={4}
                        className={classes.rigthBlock}
                      >
                        <Box width="100%" mb={3}>
                          <YellowTextField
                            fullWidth
                            error={Boolean(touched.manager && errors.manager)}
                            helperText={touched.manager && errors.manager}
                            label={translation(
                              'subsidiary.basic_information.name_manager'
                            )}
                            name="manager"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.manager}
                            variant="outlined"
                          />
                        </Box>

                        <Box width="100%" mb={3}>
                          <YellowTextField
                            fullWidth
                            error={Boolean(
                              touched.manager_phone && errors.manager_phone
                            )}
                            helperText={
                              touched.manager_phone && errors.manager_phone
                            }
                            label={translation(
                              'subsidiary.basic_information.phone_manager'
                            )}
                            name="manager_phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.manager_phone}
                            variant="outlined"
                          />
                        </Box>

                        <Box width="100%" mb={3}>
                          <YellowTextField
                            fullWidth
                            type="number"
                            error={Boolean(touched.nit && errors.nit)}
                            helperText={touched.nit && errors.nit}
                            label={translation(
                              'subsidiary.basic_information.nit'
                            )}
                            name="nit"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nit}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container justify="center">
                      <Box my={1.5} mx={1.5}>
                        <Button
                          color="secondary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {translation('subsidiary.save')}
                        </Button>
                      </Box>
                    </Grid>
                    <MapPicker
                      open={showMap}
                      setShowMap={setShowMap}
                      setPicker={setLocation}
                      {...optionalMapProps}
                    />
                  </form>
                </CardContent>
              </Card>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default NewSubsidiaryForm;
