import React from 'react';
import { makeStyles } from '@material-ui/core';
import PromoBannerItemForm from './PromoBannerItemForm';
import ModalTemplateCustomHeader from '../../../../components/Modal/ModalTemplateCustomHeader';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  customHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  }
}));

function ModalCreatePromoBannerItem({ show, onClose, item = {}, onSuccess }) {
  const classes = useStyles();

  return (
    <ModalTemplateCustomHeader
      show={show}
      onClose={onClose}
      className={classes.root}
      size="md"
      label={!item.newItem ? 'Editar item' : 'Crear item'}
    >
      <PromoBannerItemForm
        item={item}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </ModalTemplateCustomHeader>
  );
}

export default ModalCreatePromoBannerItem;
