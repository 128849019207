import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/storage';
import env from '../env';

firebase.initializeApp(env.firebaseConfig);
const db = firebase.firestore();
db.settings({
  cacheSizeBytes: 2e7
});
db.enablePersistence({ synchronizeTabs: true }).then(r =>
  console.info('Persistence Enabled')
);
const admin = firebase;

export const fbMessaging = firebase.messaging.isSupported();

if (fbMessaging) {
  firebase.messaging().usePublicVapidKey(env.REACT_APP_FCM_KEY);
}

export default admin;
