import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Collapse,
  Chip,
  Typography,
  Divider,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import { ArrowLeft } from 'react-feather';
import Page from '../../components/Page';
import { ORDER_STATUS } from '../../dto/enum';
import ListOrderCard from './ListOrderCard';
import OrderDetailsView from './OrderDetailsView';
import { COCKPIT_OPTIONS, SIDEICONS } from './constants';
import getWindowDimensions from '../../hooks/useWindowDimension';
import ImageIcon from '../../components/ImageIcon';
import { useOrdersCockpit } from './styles';
import {
  useNewOrders,
  useOrdersInProcess,
  useOrdersBeenDelivery,
  useOrdersCompleted
} from '../../hooks/Order/useOrders';
import Logger from '../../modules/logs';

const topbarSize = 64;

const OrdersCockpit = () => {
  const classes = useOrdersCockpit();
  const history = useHistory();
  const { orderId } = useParams();
  const translation = useTranslation();
  const windowDimentions = getWindowDimensions();
  const [options, setOptions] = useState(COCKPIT_OPTIONS);
  const limitDay = 0;
  const allOrder = useSelector(state => state.firestore.ordered.order);
  const [newOrders, setNewOrders] = useState(false);
  const [ordersBeenDelivery, setBeenDelivery] = useState(false);
  const [ordersCompleted, setOrdersCompleted] = useState(false);
  const callNewOrders = useNewOrders(1);
  const ordersInProcessRaw = useOrdersInProcess(limitDay);
  const callBeenDelivery = useOrdersBeenDelivery(limitDay);
  const callOrdersCompleted = useOrdersCompleted(limitDay, true);
  const [ordersInProcess, setOrdersInProcess] = useState([]);
  const [orderToContent, setOrderToContent] = useState(null);
  const [isOrderDisplay, setIsOrderDisplay] = useState(false);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    if (!isXS) setIsOrderDisplay(false);
  }, [isXS]);

  const getStatus = status => {
    switch (status) {
      case ORDER_STATUS.READY_TO_CASHIER:
        return 0;
      case ORDER_STATUS.READY_TO_PICK:
        return 1;
      case ORDER_STATUS.ACCEPTED:
        return 2;
      default:
        return null;
    }
  };

  window.onerror = (event, source, lineno, colno, error) => {
    const stack = {};
    Error.captureStackTrace(stack);
    Logger.error({ error, data: { loggerLine: stack.stack } });
  };

  const setOption = useCallback(
    id => {
      const [order] = ordersInProcess
        .concat(ordersCompleted)
        .concat(ordersBeenDelivery)
        .filter(o => o.id === id);
      setOrderToContent(order);
      if (isXS) setIsOrderDisplay(true);
      history.push(`/app/orders/${id}`);
    },
    [history, ordersInProcess, ordersCompleted, ordersBeenDelivery, isXS]
  );
  useEffect(() => {
    let sortedArray = [];
    if (ordersInProcessRaw)
      sortedArray = ordersInProcessRaw
        .map(item => ({ ...item, orderToSort: getStatus(item.status) }))
        .sort((a, b) => (a.orderToSort > b.orderToSort ? 1 : -1));
    if (callNewOrders && newOrders !== callNewOrders)
      setNewOrders(callNewOrders);
    if (ordersInProcessRaw && ordersInProcessRaw !== ordersInProcess)
      setOrdersInProcess(sortedArray);
    if (callBeenDelivery && callBeenDelivery !== ordersBeenDelivery)
      setBeenDelivery(callBeenDelivery);
    if (callOrdersCompleted && ordersCompleted !== callOrdersCompleted)
      setOrdersCompleted(callOrdersCompleted);
    // eslint-disable-next-line
  }, [allOrder]);

  useEffect(() => {
    if (ordersInProcess && ordersCompleted && ordersBeenDelivery) {
      if (!orderId && ordersInProcess.length) {
        const [order] = ordersInProcess;
        setOrderToContent(order);
        history.push(`/app/orders/${order.id}`);
      }
      if (orderId && allOrder) {
        const findOrder = allOrder.find(
          value => value.id === orderId && value.status !== 'CANCELED'
        );
        if (findOrder) setOrderToContent(findOrder);
        else history.push(`/app/orders`);
      }
    }
    // eslint-disable-next-line
  }, [
    orderId, ordersInProcess, ordersCompleted, ordersBeenDelivery
  ]);



  const onClickList = index => {
    const newOptions = [...options];
    newOptions[index].open = !newOptions[index].open;
    setOptions(newOptions);
  };

  const configureCards = array => {
    return array.map(order => {
      const driverName = _.isEmpty(order.driver)
        ? null
        : `${order.driver.first_name} ${order.driver.last_name}`;

      return (
        <Box key={order.id} my={1.5}>
          <ListOrderCard
            orderId={order.id}
            activeOrder={orderId}
            orderStatus={order.status}
            orderCode={order.order_code}
            orderType={order.order_type}
            orderCookingTime={order.cooking_time}
            acceptedTime={order.accepted_time}
            userFullName={order.user_full_name}
            createdAt={order.created_at}
            onClickedCard={setOption}
            driverId={order.driver_id}
            driverName={driverName}
            deliveryStatus={order.delivery_status}
            amountOfProducts={order.products_available_number}
            total={order.total}
            deliveryTime={order.delivery_time}
            finish_times={order.finish_times}
            order={order}
          />
        </Box>
      );
    });
  };

  const displayCards = value => {
    switch (value) {
      case ORDER_STATUS.IN_PROCESS:
        return configureCards(newOrders);
      case ORDER_STATUS.ACCEPTED:
        return configureCards(ordersInProcess);
      case ORDER_STATUS.DELIVERING:
        return configureCards(ordersBeenDelivery);
      case ORDER_STATUS.COMPLETED:
        return configureCards(ordersCompleted);
      case ORDER_STATUS.SCHEDULE:
        return [];
      default:
        console.log('unhandled order status', value);
        break;
    }
  };

  const constructListItem = (label, size, icon = SIDEICONS.inProcess) => {
    return (
      <Grid item container>
        <ImageIcon styles={clsx(classes.sectionIcon)} src={icon} />
        <Typography className={clsx(classes.sectionTitle)}>
          {translation(label)}
        </Typography>
        {size > 0 && (
          <Chip
            size="small"
            label={size}
            className={clsx(classes.activeChip)}
          />
        )}
      </Grid>
    );
  };

  const getListItem = (value, label) => {
    switch (value) {
      case ORDER_STATUS.IN_PROCESS:
        return constructListItem(label, newOrders.length);
      case ORDER_STATUS.ACCEPTED:
        return constructListItem(
          label,
          ordersInProcess.length,
          SIDEICONS.inProcess
        );
      case ORDER_STATUS.DELIVERING:
        return constructListItem(
          label,
          ordersBeenDelivery.length,
          SIDEICONS.delivering
        );
      case ORDER_STATUS.COMPLETED:
        return constructListItem(
          label,
          ordersCompleted.length,
          SIDEICONS.completed
        );
      case ORDER_STATUS.SCHEDULE:
        return constructListItem(label, newOrders.length, SIDEICONS.scheduled);
      default:
        console.log('unhandled order status', value);
        break;
    }
  };
  return (
    <Page className={classes.root}>
      {!newOrders &&
        !ordersInProcess &&
        !ordersCompleted &&
        !ordersBeenDelivery && (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}
      {newOrders && ordersInProcess && ordersCompleted && ordersBeenDelivery && (
        <Grid item container className={classes.container}>
          {!isOrderDisplay && (
            <Grid
              item
              container
              xs={12}
              sm={4}
              md={3}
              lg={3}
              className={classes.cockpitContainer}
              style={{ height: windowDimentions.height - topbarSize }}
            >
              <Box width="100%">
                <List>
                  {options.map((option, index) => (
                    <Box width="100%" key={option.label}>
                      <ListItem
                        className={classes.listItem}
                        onClick={() => onClickList(index)}
                      >
                        {getListItem(option.value, option.label)}
                      </ListItem>
                      <Collapse
                        className={classes.collapsable}
                        xs={12}
                        in={option.open}
                        style={{ width: '100%' }}
                      >
                        {displayCards(option.value)}
                      </Collapse>
                      <Divider className={classes.divider} />
                    </Box>
                  ))}
                </List>
              </Box>
            </Grid>
          )}
          <Grid item container xs={12} lg={9} sm={8} md={9}>
            {!isXS && orderToContent && (
              <OrderDetailsView order={orderToContent} />
            )}
            {isXS && orderToContent && isOrderDisplay && (
              <>
                <Box className={clsx(classes.linkContainer)}>
                  <ArrowLeft className={clsx(classes.icon)} />
                  <Typography
                    className={clsx(classes.link)}
                    onClick={() => setIsOrderDisplay(false)}
                  >
                    {translation('orders_view.card.back_to_orders')}
                  </Typography>
                </Box>
                <OrderDetailsView order={orderToContent} />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default OrdersCockpit;
