import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Box from '@material-ui/core/Box';
import AdditionEditForm from './AdditionEditForm';
import ModalTemplateCustomHeader from '../../../components/Modal/ModalTemplateCustomHeader';
import CustomSwitch from '../../../components/CustomSwitch';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  customHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  }
}));

function ModalCreateAddition({
  show,
  onClose,
  addition = { availability: true },
  onSuccess,
  prodId,
  restaurantId
}) {
  const classes = useStyles();
  const { restId } = useParams();
  const translation = useTranslation();
  const [avialability, setAvialability] = useState(true);

  useEffect(() => {
    setAvialability(addition.availability);
    // eslint-disable-next-line
  }, [addition.availability]);

  return (
    <ModalTemplateCustomHeader
      show={show}
      onClose={onClose}
      className={classes.root}
      size="sm"
      label={
        addition?.id
          ? translation('additions.form.edit_title')
          : translation('additions.form.title')
      }
      CustomHeader={() => (
        <Box className={classes.customHeader}>
          <CustomSwitch
            avialability={avialability}
            setAvialability={setAvialability}
          />
        </Box>
      )}
    >
      <AdditionEditForm
        addition={addition}
        onClose={onClose}
        availability={avialability}
        onSuccess={onSuccess}
        prodId={prodId}
        restaurantId={restId}
      />
    </ModalTemplateCustomHeader>
  );
}

export default ModalCreateAddition;
