import React, { useState } from 'react';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DropzoneModal from '../../Dropzone/DropzoneModal';
import campaingsImageService from '../../../services/campaingsImageService';

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 200,
    transition: 'all 0.25s',
    '&:hover': {
      boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)',
      cursor: 'pointer'
    }
  }
}));

const ImagePicker = ({ images = [], onPick, onClose }) => {
  const classes = useStyles();
  const [imageId, setImageId] = useState();

  return (
    <Grid container spacing={3}>
      {images.map(img => (
        <Grid item md={3} xs={12}>
          <CardMedia
            className={classes.media}
            image={img.image.original}
            onClick={() => {
              onPick(img.image.original);
              onClose();
            }}
          />
        </Grid>
      ))}
      <Grid item md={12} xs={12}>
        <Typography>ó puedes</Typography>{' '}
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const id = campaingsImageService.getNewCampaingsImage();
              setImageId(id);
            }}
          >
            {' '}
            Subir una imagen
          </Button>
        </Box>
      </Grid>

      <DropzoneModal
        onClose={() => setImageId('')}
        open={!!imageId}
        setImage={image => {
          campaingsImageService.createCampaingImageDB(imageId, {
            id: imageId,
            image: {
              original: image
            }
          });
          onPick(image);
        }}
        onFinish={img => {
          setImageId('');
          onClose();
        }}
        path={`campaings_images/${imageId}`}
        maxSize={300}
      />
    </Grid>
  );
};

export default ImagePicker;
