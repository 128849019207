import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Information from './Information';
import PriceTable from './PriceTable';
import EnableDeliveryFree from './EnableDeliveryFree';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  main: {
    alignContent: 'center'
  },
  form: {
    display: 'flex',
    backgroundColor: 'white',
    marginLeft: '5%',
    marginRight: '5%'
  },
  textTitle: {
    fontSize: '33px',
    fontWeight: 'bold',
    marginTop: '20px'
  }
}));

const FreeDelivery = ({
  restaurantId,
  restaurantName,
  subsidiary,
  deliveryFree
}) => {
  const classes = useStyles();
  const [minimumAmount, setMinimumAmount] = useState(0);

  return (
    <Box maxWidth="lg" style={{ marginTop: 20 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.form}
      >
        <Typography className={classes.textTitle}>{restaurantName}</Typography>
        <Box display="flex" style={{ marginTop: '-40px' }}>
          <Box style={{ width: '100%' }} mt={8}>
            <Information />
          </Box>
          <Box
            style={{ width: '56%' }}
            mt={8}
            mr={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <EnableDeliveryFree
              minimumAmount={minimumAmount}
              setMinimumAmount={setMinimumAmount}
              freeDelivery={deliveryFree}
              restaurantId={restaurantId}
            />
            <Box style={{ width: '100%' }}>
              <PriceTable subsidiary={subsidiary} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FreeDelivery;
