const ACTIONS = {
  SET_TIME: 'DEVICE/SET_TIME'
};

/**
 *
 * @param {Date} time
 */
export const setTime = (time = new Date()) => {
  return { type: ACTIONS.SET_TIME, payload: time };
};

const initialState = {
  time: new Date()
};

export default function DeviceReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_TIME:
      return { ...state, time: action.payload };
    default:
      return { ...state };
  }
}
