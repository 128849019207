import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { bannerColors } from '../../../../../constants';
import { INVOICE_STATUS } from '../../../../../dto/enum';

const InfoCard = props => {
  const { invoice } = props;
  const translation = useTranslation();

  return (
    <Box width="100%">
      <Card>
        <CardHeader title={translation('admin_invoice.info_card.title')} />
        <Divider />
        <CardContent>
          <Grid item container justify="center">
            <Typography variant="subtitle1" color="textPrimary">
              {translation('admin_invoice.info_card.amount')}
            </Typography>
            <Box ml={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {translation('admin_currency')} {invoice.amount}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            justify="center"
            style={{
              backgroundColor:
                invoice.status === INVOICE_STATUS.PAST
                  ? bannerColors.archColor
                  : ''
            }}
          >
            <Typography variant="subtitle1" color="textPrimary">
              {translation('admin_invoice.info_card.status')}
            </Typography>
            <Box ml={2}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold'
                }}
              >
                {invoice.status}
              </Typography>
            </Box>
          </Grid>
          <Grid item container justify="center">
            <Typography variant="subtitle1" color="textPrimary">
              {translation('admin_invoice.info_card.limit_date')}
            </Typography>
            <Box ml={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {moment(invoice.limit_date.toDate()).format('LL')}
              </Typography>
            </Box>
          </Grid>
          <Grid item container justify="center">
            <Typography variant="subtitle1" color="textPrimary">
              {translation('admin_invoice.info_card.period')}
            </Typography>
            <Box ml={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {moment(invoice.created_at.toDate()).format('LL')}
              </Typography>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default InfoCard;
