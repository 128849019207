import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../../constants';

export const useAllOrders = () => {
  // TODO: restrict per subsidiary
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      storeAs: 'orders'
    }
  ]);

  const allOrders = useSelector(state => state.firestore.ordered.orders);
  return allOrders;
};

export const getOrderNumber = order => {
  if (order.order_code) return order.order_code;
  if (order.id) return order.id.substring(0, 5);
  return '';
};
