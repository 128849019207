/* eslint-disable import/prefer-default-export */

import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const useAdditionsPerRestaurant = restaurantId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.MENU_ADDITIONS,
      where: [
        ['restaurant_id', '==', restaurantId],
        ['enable', '==', true]
      ],
      orderBy: ['created_at', 'desc']
    }
  ]);
  const additions = useSelector(
    state => state.firestore.ordered.menu_additions
  );

  if (additions) {
    return additions;
  }
  return [];
};
