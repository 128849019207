/* eslint-disable no-nested-ternary,no-unneeded-ternary,prefer-destructuring */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { CheckCircle, Edit2, XCircle } from 'react-feather';
import { allowOnlyNumbersAndDecimals } from '../../../utils/helpers/fieldFormat';
import {
  roundTwoDecimals
  // roundWithOutDecimals
} from '../../../services/orderServices';

const useStyles = makeStyles(theme => ({
  root: {},
  field: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    wordBreak: 'break-word'
  },
  icon: {
    color: theme.palette.expressColors.green
  }
}));

function AdditionItemForm({
  className,
  index,
  addition,
  onDelete,
  onSave,
  onEdit,
  initialyEditing = false,
  errors = { name: '', price: 0 },
  showMax = false,
  max = 0,
  ...rest
}) {
  const classes = useStyles();
  const translation = useTranslation();
  const [editingItem, setEditingItem] = useState(false);
  useEffect(() => {
    if (initialyEditing) {
      setEditingItem(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        {editingItem ? (
          <TextField
            error={!!errors.name}
            fullWidth
            helperText={
              errors.name
                ? translation('additions.form.options_list.name.required')
                : ''
            }
            label={translation('additions.form.options_list.name.name')}
            name="name"
            onChange={e => {
              onSave(index, { ...addition, name: e.currentTarget.value });
            }}
            value={addition.name}
            required
            variant="outlined"
          />
        ) : (
          <Typography className={classes.field} variant="body1">
            {addition.name}
          </Typography>
        )}
      </Grid>
      <Grid item md={showMax ? 2 : 5} xs={12}>
        {editingItem ? (
          <TextField
            error={errors.price}
            fullWidth
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: allowOnlyNumbersAndDecimals
            }}
            helperText={
              errors.price
                ? translation('additions.form.options_list.price.required')
                : ''
            }
            label={translation('additions.form.options_list.price.name')}
            name="price"
            onChange={e => {
              onSave(index, {
                ...addition,
                price:
                  e.currentTarget.value === ''
                    ? e.currentTarget.value
                    : roundTwoDecimals(+e.currentTarget.value)
              });
            }}
            value={addition.price}
            required
            variant="outlined"
          />
        ) : (
          <Box className={classes.field}>
            <Typography variant="body1">{addition.price}</Typography>
          </Box>
        )}
      </Grid>
      {/* {showMax && (
        <Grid item md={showMax ? 3 : 5} xs={12}>
          {editingItem ? (
            <TextField
              error={errors.max_value}
              fullWidth
              type="number"
              inputProps={{
                min: 0,
                step: 1,
                onKeyDown: allowOnlyNumbersAndDecimals
              }}
              helperText={
                errors.max_value
                  ? translation('additions.form.options_list.max.required')
                  : ''
              }
              label={translation('additions.form.options_list.max.name')}
              name="Max"
              onChange={e => {
                onSave(index, {
                  ...addition,
                  max_value:
                    e.currentTarget.value === ''
                      ? e.currentTarget.value
                      : roundWithOutDecimals(+e.currentTarget.value)
                });
              }}
              onBlur={e => {
                if (Boolean(max) && max < e.currentTarget.value) {
                  onSave(index, {
                    ...addition,
                    max_value: max
                  });
                }
              }}
              value={addition.max_value}
              required
              variant="outlined"
            />
          ) : (
            <Box className={classes.field}>
              {Boolean(addition.max_value) && (
                <Typography variant="body1">
                  {`Max: ${addition.max_value}`}
                </Typography>
              )}
            </Box>
          )}
        </Grid>
      )} */}
      <Grid item md={3} xs={12}>
        <Box display="flex" justifyContent="flex-end">
          {editingItem ? (
            <IconButton
              className={classes.icon}
              type="button"
              onClick={() => {
                if (addition.name) {
                  setEditingItem(false);
                  let price = addition.price;
                  if (addition.price === '') {
                    price = 0;
                  }
                  onSave(index, {
                    ...addition,
                    price: parseFloat(price).toFixed(2)
                  });
                }
              }}
            >
              <CheckCircle />
            </IconButton>
          ) : (
            <>
              <IconButton
                className={classes.icon}
                type="button"
                onClick={() => setEditingItem(true)}
              >
                <Edit2 />
              </IconButton>
              <IconButton
                className={classes.icon}
                type="button"
                onClick={() => {
                  onDelete(index);
                }}
              >
                <XCircle />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AdditionItemForm;
