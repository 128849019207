import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import Product from '../dto/product';

export const addProduct = async (
  id,
  sectionId,
  subsidiaryId,
  restaurantId,
  sizeProducts
) => {
  const newProduct = new Product();
  newProduct.id = id;
  newProduct.subsidiary_id = subsidiaryId || '';
  newProduct.restaurant_id = restaurantId || '';
  newProduct.menu_section_id = sectionId;
  newProduct.menu_order = sizeProducts || 0;
  await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .set({ ...newProduct });
  return id;
};

export const editComplementaryProducts = async (id, addition) =>
  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .update(addition);

export const editProduct = async (fields, id) =>
  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .update({ ...fields });

export const deleteImage = async id =>
  admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(id)
    .update({
      photo: {
        original: '',
        x160: '',
        x200: '',
        x40: '',
        x400: '',
        x80: '',
        x800: ''
      }
    });

export const saveAdditions = async (additionList, prodId) => {
  const batch = admin.firestore().batch();
  const prodRef = await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(prodId);
  const addRef = prodRef.collection(COLLECTIONS.ADDITION);
  additionList.forEach((addition, index) => {
    const document = addRef.doc(addition.id);
    batch.set(document, { order: index }, { merge: true });
  });
  return batch.commit();
};

export const updateAdditions = async (prodId, additionId, data) => {
  const prodRef = admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .doc(prodId);
  const addRef = prodRef.collection(COLLECTIONS.ADDITION).doc(additionId);
  addRef.update({
    ...data
  });
};

export const getProductsPerRestaurant = async restaurantId => {
  const products = await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .where('restaurant_id', '==', restaurantId)
    .where('enable', '==', true)
    .where('subsidiary_id', '==', '')
    .where('template', '==', true)
    .get()
    .then(querySnapshot => {
      const arrayOfProducts = [];
      querySnapshot.forEach(doc => {
        arrayOfProducts.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return arrayOfProducts;
    });
  return products;
};

export const getProductsByGallery = async ({ galleryId }) => {
  const products = await admin
    .firestore()
    .collection(COLLECTIONS.PRODUCT)
    .where('gallery_id', '==', galleryId)
    .get()
    .then(querySnapshot => {
      const arrayOfProducts = [];
      querySnapshot.forEach(doc => {
        arrayOfProducts.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return arrayOfProducts;
    });
  return products;
};
