import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AdvertisingSocialNetworksForm from '../../../../components/AdvertisingSocialNetworksForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  basicInfo: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  basicInfoForm: {
    maxWidth: '865px',
    width: '100%',
    backgroundColor: 'transparent',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent
    }
  },
  transparentBox: {
    backgroundColor: '#FFFFFF80',
    borderRadius: 10,
    padding: 41
  },
  iconBack: {
    cursor: 'pointer',
    color: theme.palette.expressColors.green
  }
}));

const AdvertisingSocialNetworks = React.memo(
  ({
    isRegister = false,
    handleNext = () => {},
    handleBack = () => {},
    saveRestaurantData = () => {}
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <div className={classes.basicInfo}>
          <Box p={2} className={classes.basicInfoForm}>
            <Box className={classes.transparentBox}>
              <ArrowBackIcon
                className={classes.iconBack}
                onClick={handleBack}
              />
              <AdvertisingSocialNetworksForm
                isRegister={isRegister}
                handleNext={handleNext}
                saveRestaurantData={saveRestaurantData}
              />
            </Box>
          </Box>
        </div>
      </div>
    );
  }
);

export default AdvertisingSocialNetworks;
