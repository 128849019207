import React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ModalTemplateCustomHeader from '../../components/Modal/ModalTemplateCustomHeader';
import { COLORS } from '../../theme/colors';
import { editRestaurant } from '../../services/restaurantService';

const useStyles = makeStyles(() => ({
  button: {
    color: '#FFFFFF',
    background: COLORS.green,
    width: '100px',
    '&:hover': {
      color: 'black'
    }
  },
  divider: {
    marginTop: '-8px'
  },
  padding: {
    paddingTop: 24
  }
}));

const ModalBillingData = ({
  show,
  onClose,
  billingData,
  setBillingData,
  id
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  return (
    <>
      <ModalTemplateCustomHeader
        show={show}
        onClose={onClose}
        size="xs"
        label={translation('central_payments.details.information.invoice_data')}
      >
        <Divider className={classes.divider} />
        <Formik
          initialValues={{
            business_name: billingData ? billingData.business_name : '',
            nit: billingData ? billingData.nit : '',
            contact_name: billingData ? billingData.contact_name : '',
            phone_number: billingData ? billingData.phone_number : '',
            email: billingData ? billingData.email : '',
            additional_comments: billingData
              ? billingData.additional_comments
              : ''
          }}
          validationSchema={Yup.object().shape({
            business_name: Yup.string().required(
              translation(
                'central_payments.details.billing_data.business_name_require'
              )
            ),
            nit: Yup.string()
              .max(255)
              .required(
                translation('central_payments.details.billing_data.nit_require')
              ),
            contact_name: Yup.string().required(
              translation(
                'central_payments.details.billing_data.contact_name_require'
              )
            ),
            phone_number: Yup.string()
              .max(255)
              .required(
                translation(
                  'central_payments.details.billing_data.phone_number_require'
                )
              ),
            email: Yup.string()
              .email(translation('login.email.valid'))
              .max(255)
              .required(translation('login.email.require'))
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setBillingData(values);
              editRestaurant(id, {
                invoice_name: values.business_name,
                nit: values.nit,
                invoice_contact_name: values.contact_name,
                invoice_email: values.email,
                invoice_comments: values.additional_comments
              });
              onClose();
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.business_name'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.business_name && errors.business_name
                          )}
                          helperText={
                            touched.business_name && errors.business_name
                          }
                          margin="normal"
                          name="business_name"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.business_name}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.nit'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          error={Boolean(touched.nit && errors.nit)}
                          helperText={touched.nit && errors.nit}
                          margin="normal"
                          name="nit"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.nit}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.contact_name'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.contact_name && errors.contact_name
                          )}
                          helperText={
                            touched.contact_name && errors.contact_name
                          }
                          margin="normal"
                          name="contact_name"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact_name}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.phone_number'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          error={Boolean(
                            touched.phone_number && errors.phone_number
                          )}
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          margin="normal"
                          name="phone_number"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone_number}
                          disabled
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.email'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          margin="normal"
                          name="email"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.padding}
                      >
                        {translation(
                          'central_payments.details.billing_data.additional_comments'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          error={Boolean(
                            touched.additional_comments &&
                              errors.additional_comments
                          )}
                          helperText={
                            touched.additional_comments &&
                            errors.additional_comments
                          }
                          margin="normal"
                          name="additional_comments"
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.additional_comments}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        className={classes.button}
                        size="medium"
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {translation(
                          'central_payments.details.billing_data.accept'
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </ModalTemplateCustomHeader>
    </>
  );
};
export default ModalBillingData;
