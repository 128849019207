import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Typography, makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import {
  useDocumentsRestaurantNative,
  useGetBanner
} from '../../../hooks/AdminHooks/document/useDocument';
import Document from './Document';
import { COLORS } from '../../../theme/colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.background.default
  },
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  transparentBox: {
    backgroundColor: '#FFFFFF80',
    borderRadius: 10,
    padding: 41,
    width: '57%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  textTitle: {
    fontFamily: 'Nunito Sans',
    fontSize: '30px'
  },
  textDescription: {
    fontFamily: 'Nunito Sans',
    fontSize: '22px'
  },
  containerDoc: {
    border: `1px solid ${COLORS.darkGolden}`,
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    margin: '0% 0px 0px 5%'
  },
  cardDescription: {
    margin: '5% 5% 0px 5%',
    fontSize: '12px'
  },
  padding: {
    padding: '0px 10%'
  },
  styleIconOption: {
    width: '24px',
    height: '24px',
    color: `${COLORS.green}`,
    cursor: 'pointer',
    marginRight: '5%'
  },
  styleIconDelete: {
    width: '24px',
    height: '24px',
    color: `${COLORS.green}`,
    cursor: 'pointer'
  }
}));

const Documents = ({ handleNext }) => {
  const classes = useStyles();
  const { id } = useParams();
  const { logo, banner } = useGetBanner(id);
  const documentsRestaurant = useDocumentsRestaurantNative(id);
  const [documents, setDocuments] = useState([]);
  const translation = useTranslation();

  const initialArray = [
    {
      title: 'Imagen de LOGO',
      description:
        'Sube una imagen del logo corporativo en buena calidad, legible y con máximo 10Mb.',
      urlDocument: '',
      nameDocument: 'Imagen de LOGO:',
      idDocument: '1',
      status: 'logo'
    },
    {
      title: 'Banner de restaurant',
      description:
        'Sube una imagen de tu plato favorito en buena calidad y máximo 10Mb.',
      urlDocument: 'banner.original',
      nameDocument: 'Banner de restaurant (producto):',
      idDocument: '2',
      status: 'banner'
    }
  ];

  useEffect(() => {
    initialArray[0].urlDocument = logo?.original || '';
    initialArray[0].idDocument = id;
    initialArray[1].urlDocument = banner?.original || '';
    initialArray[1].idDocument = id;
    if (documentsRestaurant.length > 0) {
      const arrayDoc = [...initialArray];
      documentsRestaurant.forEach(doc => {
        arrayDoc.push({
          title: doc.title,
          description: doc.description,
          urlDocument: doc.url,
          nameDocument: doc.name,
          idDocument: doc.id,
          status: doc.status
        });
      });
      setDocuments(arrayDoc);
    } else {
      const arrayDoc = [...initialArray];
      setDocuments(arrayDoc);
    }
    // eslint-disable-next-line
  }, [documentsRestaurant, logo, banner]);

  return (
    <div className={classes.root}>
      <div className={classes.basicInfo}>
        <Box className={classes.transparentBox}>
          <Box textAlign="center" mb={3}>
            <Typography className={classes.textTitle}>
              {translation('restaurant.additional_documents.title')}
            </Typography>
          </Box>
          <Box textAlign="center" mb={5}>
            <Typography className={classes.textDescription}>
              {translation('restaurant.additional_documents.description')}
            </Typography>
          </Box>
          <Grid container direction="row" spacing={6}>
            {documents.length > 0 &&
              documents.map((doc, index) => (
                <Grid sm={6} item key={index} xs={12}>
                  <Document
                    title={doc.title}
                    description={doc.description}
                    urlDocument={doc.urlDocument}
                    index={index >= 0}
                    nameDocument={doc.nameDocument}
                    idDocument={doc.idDocument}
                    status={doc.status}
                  />
                </Grid>
              ))}
            <Grid sm={6} item key={0} xs={12}>
              <Document />
            </Grid>
          </Grid>
          <Box mt={5}>
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              mt={2}
              ml={-1}
            >
              <Button
                color="secondary"
                size="large"
                variant="contained"
                onClick={handleNext}
              >
                {translation('next')}
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Documents;
