import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import {
  Button,
  Card,
  makeStyles,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import NotificationImagePicker from './NotificationImagePicker';

const useStyles = makeStyles(() => ({
  card: {
    marginTop: 20,
    padding: 20,
    width: '100%'
  },
  input: {
    marginTop: 20
  },
  button: {
    marginTop: 20
  },
  feedback: {
    fontSize: 12,
    marginLeft: 10
  }
}));

const USER_TOPIC_TYPE = {
  CLIENT: 'CLIENT',
  VISITOR: 'VISITOR'
};

const MAX_TITLE = 65;
const MAX_BODY = 130;

function SendNotificationForm({ options, onSubmit }) {
  const translate = useTranslation();
  const [restaurantSelectorOpened, setRestaurantSelectorOpened] = useState(
    false
  );
  const [destinationValue, setDestinationValue] = useState('ALL');
  const [userType, setUserType] = useState(USER_TOPIC_TYPE.CLIENT);
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState('');

  const handleRestaurantSelectorOpenedClose = () => {
    setRestaurantSelectorOpened(false);
  };

  const getOptions = () => {
    if (options) {
      return options;
    }
    return [];
  };
  const getUserType = () => {
    return Object.values(USER_TOPIC_TYPE);
  };

  const getTitleLengthAvailable = () => {
    return MAX_TITLE - title.length;
  };
  const getBodyLengthAvailable = () => {
    return MAX_BODY - body.length;
  };
  const submit = async () => {
    setButtonDisabled(true);
    await onSubmit({
      title,
      body,
      destination: destinationValue,
      userType,
      image
    });
    setButtonDisabled(false);
  };
  return (
    <Grid container>
      <Grid item container />
      <Grid item container>
        <Menu
          id="simple-menu"
          anchorEl={10}
          keepMounted
          open={restaurantSelectorOpened}
          onClose={handleRestaurantSelectorOpenedClose}
        />
      </Grid>

      <Card className={classes.card} p={3}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={4}>
            <Typography variant="body1" color="primary">
              {translate('marketing.receiver')}
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              label={translate('marketing.form.restaurant')}
              name="filter"
              id="filter"
              value={destinationValue}
              onChange={event => {
                setDestinationValue(event.target.value);
              }}
            >
              <MenuItem value="ALL" id={null}>
                {translate('marketing.option.all')}
              </MenuItem>
              {getOptions().map(res => (
                <MenuItem key={res} value={res.id}>
                  {res.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography variant="body1" color="primary">
              {translate('marketing.user_type')}
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              label={translate('marketing.form.type')}
              name="type"
              id="type"
              value={userType}
              onChange={event => setUserType(event.target.value)}
            >
              {getUserType().map(option => (
                <MenuItem key={option} value={option}>
                  {translate(`user_type.${option}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={8} xs={4}>
            <TextField
              value={title}
              className={classes.input}
              fullWidth
              name="notification_title"
              onChange={event => {
                setTitle(event.target.value);
              }}
              label={translate('marketing.form.notification_title')}
              variant="outlined"
              inputProps={{ maxLength: MAX_TITLE }}
            />
            <Typography className={classes.feedback}>
              {`${getTitleLengthAvailable()} ${translate(
                'marketing.chars_available'
              )}`}
            </Typography>
            <TextField
              multiline
              rows={3}
              value={body}
              className={classes.input}
              fullWidth
              name="notification_body"
              onChange={event => {
                setBody(event.target.value);
              }}
              label={translate('marketing.form.notification_body')}
              variant="outlined"
              inputProps={{ maxLength: MAX_BODY }}
            />
            <Typography className={classes.feedback}>
              {`${getBodyLengthAvailable()} ${translate(
                'marketing.chars_available'
              )}`}
            </Typography>
            {/* <TextField */}
            {/*  value={image} */}
            {/*  className={classes.input} */}
            {/*  fullWidth */}
            {/*  name="notification_image" */}
            {/*  onChange={event => { */}
            {/*    setImage(event.target.value); */}
            {/*  }} */}
            {/*  label={translate('marketing.form.notification_image')} */}
            {/*  variant="outlined" */}
            {/* /> */}
          </Grid>
          <Grid item md={8} xs={4}>
            <NotificationImagePicker image={image} setImage={setImage} />
          </Grid>
        </Grid>
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={submit}
          disabled={buttonDisabled || !(title && body)}
        >
          <Typography>
            {translate('marketing.form.send').toUpperCase()}
          </Typography>
        </Button>
      </Card>
    </Grid>
  );

  /* const translate = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState();

  const handleChange = event => {
    setValue(event.target.value);
    event.preventDefault();
    list();
  };

  const list = () => {
    if (value === 'users') {
      return <UsersPhone />;
    }
    if (value === 'business') {
      return <BusinessEmail />;
    }
  };

  return (
    <Card p={3}>
      <CardHeader title={`${translate('marketing.group_message')}:`} />
      <RadioGroup row onChange={handleChange}>
        <FormControlLabel
          label={translate('marketing.user_phone')}
          value="users"
          control={<Radio />}
  0        labelPlacement="start"
        />
        <FormControlLabel
          label={translate('marketing.business_email')}
          value="business"
          control={<Radio />}
          labelPlacement="start"
        />
      </RadioGroup>
      <Formik
        initialValues={{
          message: String
        }}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            resetForm();
            setStatus({ success: true });
            enqueueSnackbar(translate('notification.correctly_send'), {
              variant: 'success'
            });
            setSubmitting(false);
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            if (error.status === 409) {
              enqueueSnackbar(translate('notification.something_go_wrong'), {
                variant: 'error'
              });
            } else {
              enqueueSnackbar(
                `${translate('notification.something_go_wrong')} ${
                  process.env.REACT_APP_SUPORT_MAIL
                }`,
                {
                  variant: 'error'
                }
              );
            }
          }
        }}
      >
        {(handleSubmit, isSubmitting) => (
          <form onSubmit={handleSubmit}>
            <Box m={2}>
              <Typography variant="body1" color="textPrimary" mb={2}>
                {`${translate('marketing.message')}:`}
              </Typography>
              <TextField
                name="message"
                id="filled-multiline-flexible"
                multiline
                rows={5}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box m={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
              >
                {translate('marketing.send_message')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {list()}
    </Card>
  ); */
}

export default SendNotificationForm;
