import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { UNVERIFIED_REASONS } from '../../../../dto/enum';
import { MESSAGES } from './constants';

const useStyles = makeStyles(theme => ({
  container: {
    height: '50px',
    background: theme.palette.expressColors.lightYellow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerText: {
    fontSize: 30,
    fontWeight: 'bold'
  }
}));

const Banner = ({ unverifiedReason }) => {
  const classes = useStyles();

  const getBannerMessage = () => {
    switch (unverifiedReason) {
      case UNVERIFIED_REASONS.HIGH_COST:
        return MESSAGES.HIGH_COST;
      case UNVERIFIED_REASONS.DRIVER_ORDER:
        return MESSAGES.DRIVER_ORDER;
      case UNVERIFIED_REASONS.FIRST_ORDER:
        return MESSAGES.FIRST_ORDER;
      case UNVERIFIED_REASONS.OTHER:
        return MESSAGES.OTHER;
      default:
        console.log('unhandled reason', unverifiedReason);
        break;
    }
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.bannerText}>
        {getBannerMessage()}
      </Typography>
    </Box>
  );
};

export default Banner;
