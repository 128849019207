import React, { useMemo } from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  informationContainer: {
    borderRadius: '5px',
    border: '1px solid #BF8D20',
    padding: '28px 27px 20px 18px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '20px'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  textContainer: {
    maxWidth: '265px',
    width: '100%'
  },
  text: {
    fontWeight: 'light',
    fontSize: '16px'
  },
  editButton: {
    cursor: 'pointer',
    color: '#4E9788'
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px'
  },
  button: {
    padding: 0,
    fontSize: '14px',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const PaymentSummary = ({ charges, onlineCharges }) => {
  const classes = useStyles();
  const total = useMemo(() => {
    // charges.delivery + onlineCharges.qr + charges.pick_up + charges.iva
    let sum = 0;
    if (charges && onlineCharges) {
      if (charges.delivery) {
        sum += charges.delivery;
      }
      if (onlineCharges.qr || onlineCharges.car) {
        sum += onlineCharges.qr;
      }
      if (charges.pick_up) {
        sum += charges.pick_up;
      }
      if (charges.iva) {
        sum += charges.iva;
      }
    }
    return sum;
  }, [charges, onlineCharges]);
  return (
    <Box className={classes.informationContainer}>
      <Typography className={classes.text}>
        <strong>Comisión Delivery:</strong> {charges.delivery || 0}%
      </Typography>

      <Typography className={classes.text}>
        <strong>Comisión Pagos en linea: </strong> {onlineCharges.qr || 0}%
      </Typography>
      <Typography className={classes.text}>
        <strong>Comisión PickUp: </strong>
        {charges.pick_up || 0}%
      </Typography>
      <Typography className={classes.text}>
        <strong>Comisión Iva: </strong>
        {charges.iva || 0}%
      </Typography>
      <Divider sx={{}} color="#56B19E" />

      <Typography className={classes.text}>
        <strong>Sumatoria Total: </strong>
        {total}%
      </Typography>
    </Box>
  );
};

export default PaymentSummary;
