import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  CardHeader,
  NativeSelect,
  FormHelperText,
  Button,
  TextField,
  OutlinedInput
} from '@material-ui/core';
import { useChatLayoutStyles } from '../styles';
import WithLoading from '../../../../components/WithLoading';
import { SUBJECT_OPTIONS } from '../../constants';
import { setChats } from '../../../../actions/chatActions';
import { createChat } from '../../../../services/chatService';
import { useOrderShortInfo } from '../../../../hooks/Order/useOrders';
import { getOwner } from '../../../../services/restaurantService';

const BoxLoading = WithLoading(Box);

const MAX_OTHER_TEX = 12;

const CreateNewChat = ({ handleClose }) => {
  const classes = useChatLayoutStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const orders = useOrderShortInfo();
  const subsidiary = useSelector(state => state.account.currentSubsidiary);
  const restaurant = useSelector(state => state.account.restaurant);
  const user = useSelector(state => state.account.user);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(SUBJECT_OPTIONS[0].value);
  const [otherText, setOtherText] = useState('');
  const [order, setOrder] = useState('');
  const [valid, setValid] = useState(false);
  const [owner, setOwner] = useState({});

  useEffect(() => {
    const fetchOwner = async () => {
      const restOwner = await getOwner(restaurant.id);
      setOwner(restOwner);
    };
    fetchOwner();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    switch (subject) {
      case 'INVALID':
        setValid(false);
        break;
      case 'ORDER':
        if (order) setValid(true);
        else setValid(false);
        break;
      case 'OTHER':
        if (otherText) setValid(true);
        else setValid(false);
        break;
      default:
        setValid(true);
        break;
    }
  }, [subject, order, otherText]);

  const getOtherTextLengthAvailable = () => MAX_OTHER_TEX - otherText.length;

  const otherTextBox = () => {
    return (
      <Box>
        <TextField
          fullWidth
          onChange={e => setOtherText(e.target.value)}
          value={otherText}
          variant="outlined"
          size="small"
          autoFocus
          inputProps={{ maxLength: MAX_OTHER_TEX }}
        />
        <Typography className={classes.feedback}>
          {`${getOtherTextLengthAvailable()} ${translation(
            'chat.chat_layout.subjects.chars_available'
          )}`}
        </Typography>
      </Box>
    );
  };

  const ordersSelect = () => {
    return (
      <Box>
        <NativeSelect
          className={classes.nativeSelect}
          variant="Outlined"
          value={order}
          onChange={e => setOrder(e.target.value)}
          input={<OutlinedInput />}
        >
          <option key="" value="" />
          {orders.map(so => (
            <option key={so.id} value={so.id}>
              {so.order_code}
            </option>
          ))}
        </NativeSelect>
        <FormHelperText>
          {translation('chat.chat_layout.subjects.select_order')}
        </FormHelperText>
      </Box>
    );
  };

  const createNewChat = async () => {
    setLoading(true);
    dispatch(setChats([]));
    const [filteredOrder] = orders.filter(o => o.id === order);
    const chat = {
      subject,
      order_summary: { ...filteredOrder },
      other_text: otherText,
      restaurant_id: restaurant.id,
      restaurant_name: restaurant.name,
      subsidiary_id: subsidiary.id,
      subsidiary_name: subsidiary.name
    };
    const newChat = await createChat({
      chat,
      user,
      owner,
      restaurantPhoto: restaurant.logo_photo
    });
    setSubject(SUBJECT_OPTIONS[0].value);
    setOtherText('');
    setOrder('');
    dispatch(setChats([newChat]));
    handleClose();
    setLoading(false);
  };

  const CardSubheader = () => {
    return (
      <Box>
        <Box className={classes.cockpit}>
          <Box>
            <NativeSelect
              className={classes.nativeSelect}
              variant="Outlined"
              value={subject}
              onChange={e => {
                setSubject(e.target.value);
                setOtherText('');
                setOrder('');
              }}
              input={<OutlinedInput />}
            >
              {SUBJECT_OPTIONS.map(o => (
                <option key={o.value} value={o.value}>
                  {translation(o.label)}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>
              {translation('chat.chat_layout.subjects.order_helper')}
            </FormHelperText>
          </Box>
          <Box>
            <Button
              className={classes.button}
              onClick={createNewChat}
              disabled={!valid}
              variant="contained"
            >
              {translation('chat.chat_layout.new_chat')}
            </Button>
          </Box>
        </Box>
        <Box>{subject === 'ORDER' && ordersSelect()}</Box>
        <Box>{subject === 'OTHER' && otherTextBox()}</Box>
      </Box>
    );
  };

  return (
    <BoxLoading isLoading={loading}>
      <CardHeader
        className={classes.cardHeader}
        subheader={<CardSubheader />}
      />
    </BoxLoading>
  );
};

export default CreateNewChat;
