import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';

import GreenEditButton, {
  GreenCrossButton
} from '../../../../components/Inputs/Buttons';
import SixDots from '../../../../assets/icons/SixDots.png';
import XpressModal from '../../../../components/Modal';
import additionService from '../../../../services/additionsService';
import { useRestaurant } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import ModalCreateAddition from '../../../aditions/AdditionEditView/ModalCreateAddition';
import { logAddition } from '../../../../services/logServices';
import { LOG_ACTIONS } from '../../../../dto/enum';
import { getObjectChanges } from '../../../../utils/object';
import DragDropAdditionsItem from './DragDropAdditionItem';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(1),
      display: 'inline-block'
    }
  },
  optionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    marginBottom: '0',
    borderBottom: `1px solid ${theme.palette.expressColors.lightGray}`,
    '&:last-child': {
      marginBottom: 0,
      borderBottom: `none`
    }
  },
  optionField: {
    textAlign: 'center',
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.expressColors.lightGray}`,
    '&:last-child': {
      borderRight: `none`
    }
  },
  arrowIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: theme.spacing(1)
  },
  grabButton: {
    marginLeft: theme.spacing(1)
  },
  caption: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.65rem',
    display: 'inline-block'
  },
  optionFieldBox: {
    minWidth: '60px',
    display: 'inline-block',
    textAlign: 'left'
  }
}));

const AdditionPickerItem = ({
  addition,
  prodId,
  restIdFromState = null,
  blockCashier
}) => {
  const classes = useStyles();
  const translate = useTranslation();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [sharedWith, setSharedWith] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const restaurant = useRestaurant(restIdFromState);
  const restaurantAddition = addition;
  const isTemplate = useIsTemplateMenu();
  const deleteAddition = () => {
    setShowModal(true);
    const changes = getObjectChanges(addition, null);
    const data = {
      addition: {
        id: addition.id,
        name: addition.name
      },
      restaurant: {
        id: restaurant.id,
        name: restaurant.name
      },
      product: {
        id: prodId
      }
    };
    setModal(
      <XpressModal
        type="deleteConfirmation"
        handleConfirm={async () => {
          additionService.deleteAdditionFromProduct(prodId, addition.id);
          await logAddition(changes, LOG_ACTIONS.DELETE, data);
          setShowModal(false);
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    );
  };

  const updateOption = async (id, status) => {
    setLoading(true);
    const newOptions = [];
    addition.options.forEach(a => {
      if (a.id === id) newOptions.push({ ...a, availability: status });
      else newOptions.push({ ...a });
    });
    await additionService.updateAddition(addition.id, prodId, {
      options: newOptions
    });
    setLoading(false);
  };

  const getSharedWith = async () => {
    const sharedWithNumber = await additionService.sharedWithOtherProducts(
      addition,
      prodId,
      restaurant.id
    );
    setSharedWith(sharedWithNumber);
  };

  useEffect(() => {
    getSharedWith();
    // eslint-disable-next-line
  }, []);
  const columnsSize = isTemplate ? 6 : 4;

  return (
    <>
      <ExpansionPanel className={classes.root} expanded={expanded}>
        <Box p={2} className={classes.header}>
          <Box>
            <Typography>
              <strong>{restaurantAddition.name}</strong>{' '}
            </Typography>
            {sharedWith > 0 && (
              <Typography
                variant="caption"
                component="p"
                className={classes.caption}
              >
                {`Compartido con ${sharedWith} ${
                  sharedWith === 1 ? 'producto' : 'productos'
                }`}
              </Typography>
            )}
          </Box>
          {expanded ? (
            <ArrowDropUp
              className={classes.arrowIcon}
              onClick={() => setExpanded(false)}
            />
          ) : (
            <ArrowDropDown
              className={classes.arrowIcon}
              onClick={() => setExpanded(true)}
            />
          )}
          {isTemplate && (
            <>
              <GreenEditButton size="small" onClick={() => setShowEdit(true)} />
              <GreenCrossButton size="small" onClick={deleteAddition} />
            </>
          )}
          <img src={SixDots} alt="grab" className={classes.grabButton} />
        </Box>
        <Divider />
        <ExpansionPanelDetails className={classes.optionDetails}>
          <Box mt={1}>
            <Grid container className={classes.option}>
              <Grid item xs={columnsSize} className={classes.optionField}>
                <Box className={classes.optionFieldBox}>
                  <Typography>
                    <strong>{translate('additions.picker.table.name')}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={columnsSize} className={classes.optionField}>
                <Box className={classes.optionFieldBox}>
                  <Typography>
                    <strong>{translate('additions.picker.table.price')}</strong>
                  </Typography>
                </Box>
              </Grid>
              {!isTemplate && (
                <Grid item xs={columnsSize} className={classes.optionField}>
                  <Box className={classes.optionFieldBox}>
                    <Typography>
                      <strong>
                        {translate('additions.picker.table.available')}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <DragDropAdditionsItem
              updateOption={updateOption}
              restaurantAdditionOptions={restaurantAddition.options}
              addition={restaurantAddition}
              prodId={prodId}
              blockCashier={blockCashier}
              isTemplate={isTemplate}
              columnsSize={columnsSize}
              loading={loading}
              setLoading={setLoading}
            />
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {showModal && modal}
      {restaurantAddition && (
        <ModalCreateAddition
          show={showEdit}
          onClose={() => setShowEdit(false)}
          addition={restaurantAddition}
          prodId={prodId}
          restaurantId={restIdFromState}
        />
      )}
    </>
  );
};

export default AdditionPickerItem;
