import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Breadcrumbs, Button, Grid, Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ setShowModal, className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Box mt={3}>
          <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
            <Link
              variant="h3"
              color="inherit"
              component={RouterLink}
              to="/app/management/shared_menu"
            >
              {translation('menu.menu_section.label')}
            </Link>
            <Link
              variant="h3"
              color="inherit"
              to="/app/management/additions"
              component={RouterLink}
            >
              {translation('additions.title')}
            </Link>
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid item>
        <Box
          style={{ height: '100%' }}
          display="flex"
          flexWrap="wrap"
          alignContent="flex-end"
        >
          <Box>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setShowModal(true)}
            >
              {translation('additions.add')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
