/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import React from 'react';
import ReactDOM from 'react-dom';

import './print.css';
import { useRestaurant } from 'src/hooks/useRestaurant';
import moment from 'moment';
import useProductsPerOrder from 'src/hooks/Order/useProductsPerOrder';
import { Box, Divider } from '@material-ui/core';
import {
  getOrderTotal,
  getProductSimplePrice
} from 'src/services/orderServices';
import { useTranslation } from 'react-multi-lang/lib';
import PrintItem from './PrintItem';
import { useSubsidiaryId } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { PAYMENT_METHOD } from '../../../dto/enum';
import { getOrderType } from '../../../utils/helpers/orderHelper';

const InfoFields = ({ orderTypeText }) => {
  return [
    { field: 'name', object: 'restaurant', label: 'RESTAURANTE' },
    { label: 'FECHA', value: moment().format('DD/MM/YYYY HH:mm') },
    { label: 'ENTREGA', value: 'Entrega Inmediata' },
    { label: 'TIPO DE ENTREGA', value: orderTypeText },
    { break: true },
    { label: 'PEDIDO', object: 'order', field: 'order_code' },
    { label: 'CLIENTE', object: 'order', field: 'invoice_name' },
    { label: 'NÚMERO DE NIT', object: 'order', field: 'invoice_nit' }
  ];
};

function getPaymentMethod(type) {
  if (type === PAYMENT_METHOD.ONLINE_CARD) {
    return 'PAGO ONLINE(CARD)';
  }
  if (type === PAYMENT_METHOD.ONLINE_QR) {
    return 'PAGO ONLINE(QR)';
  }
  return type;
}

function OrderPrinting({ order, printing, finishOrder = () => {} }) {
  const descriptionAditional = order.additional_description || '';
  const orderProducts = useProductsPerOrder(order.id);
  const translation = useTranslation();
  const { promotion } = order;
  const restaurantStore = useRestaurant();
  const subsidiary = useSubsidiaryId(order.subsidiary_id);
  const restaurant = {
    ...restaurantStore,
    name: `${restaurantStore.name} ${
      subsidiary && subsidiary.name ? `- ${subsidiary.name}` : ''
    }`
  };
  const orderType = getOrderType(order.order_type);
  const texts = { orderTypeText: translation(`${orderType}`) };
  const infFields = InfoFields(texts);
  const getPhone = phone => {
    if (phone[0] === '+' && phone[1] === '5') return phone.substr(4);
    if (phone[0] === '+' && phone[1] === '1') return phone.substr(2);
    return phone;
  };
  return ReactDOM.createPortal(
    <div
      id="printer"
      style={{
        position: 'absolute',
        zIndex: 999999999,
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        background: 'black'
      }}
    >
      <br />
      <br />
      <label style={{ width: '100%', textAlign: 'center' }}>XPRESS</label>
      {(order?.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
        order?.payment_method === PAYMENT_METHOD.ONLINE_QR) && (
        <Box className="container-print-paid">
          <Box className="print-paid">
            <label style={{ fontSize: '20px' }}>PAGADO</label>
          </Box>
        </Box>
      )}
      <br />
      <br />
      <div className="print-divider" />
      <br />
      {infFields.map((printField, i) => {
        if (printField.break) {
          return <br key={i} />;
        }
        const PrintObject =
          printField.object === 'restaurant' ? restaurant : order;
        return (
          <PrintItem
            key={i}
            isOrder={printField.object === 'order'}
            label={printField.label}
            value={printField.value || PrintObject[printField.field] || '-'}
          />
        );
      })}
      {restaurantStore.show_user_phone && (
        <PrintItem
          key={10}
          label="TELÉFONO DEL CLIENTE"
          value={getPhone(order.user_phone)}
        />
      )}
      <br />
      <div className="print-divider" />
      <br />
      <label>DETALLES</label>
      <br />
      <br />
      {orderProducts
        ?.filter(prod => !prod.canceled_reason)
        .map((prod, i) => {
          const additional = prod.additional_requirement || false;
          const refText =
            additional && additional !== ''
              ? `- Referencia: ${additional}`
              : '';
          return (
            <section key={i}>
              <PrintItem
                label={`x${prod.quantity}`}
                separator=" "
                value={prod.name || prod.product_name}
                floatingLabel={
                  `${(
                    prod.simple_price * (prod.quantity ? +prod.quantity : 1)
                  ).toFixed(2)} Bs.` || `${getProductSimplePrice(prod)} Bs`
                }
              />
              <PrintItem
                key={i + prod.id}
                label={refText}
                separator=""
                value=""
              />
              <div style={{ marginLeft: 12 }}>
                {prod.selected_options?.map((option, index) => {
                  return (
                    <PrintItem
                      key={index}
                      label={`- x${
                        option.quantity ? option.quantity : prod.quantity
                      } ${option.addition_item_name}`}
                      separator=""
                      value=""
                      floatingLabel={`${parseFloat(
                        option.quantity
                          ? option.price *
                              (option.quantity ? +option.quantity : 1)
                          : option.price * (prod.quantity ? +prod.quantity : 1)
                      ).toFixed(2)} Bs`}
                    />
                  );
                })}
              </div>
            </section>
          );
        })}
      {descriptionAditional !== '' && (
        <PrintItem
          key={descriptionAditional}
          label={`Referencias generales: ${descriptionAditional}`}
          separator=""
          value=""
        />
      )}
      {promotion && (
        <section key="discount">
          <PrintItem
            label="-"
            separator=" "
            value={`Promo Code: ${promotion.promo_code}`}
            floatingLabel={`-${(order.discount * 1).toFixed(2)} .Bs`}
          />
        </section>
      )}
      <br />
      <div className="print-divider" />
      <br />
      {(order?.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
        order?.payment_method === PAYMENT_METHOD.ONLINE_QR) && (
        <>
          <PrintItem
            label="SUB-TOTAL"
            value={
              order.total
                ? `${getOrderTotal(
                    orderProducts,
                    order.simple_calculation || false
                  ).toFixed(2)} Bs`
                : `${(order.total * 1).toFixed(2)} Bs`
            }
            floatingLabel=""
          />
          {promotion && (
            <section key="discount">
              <PrintItem
                label="-"
                separator=" "
                value={`Promo Code: ${promotion.promo_code}`}
                floatingLabel={`-${(order.discount * 1).toFixed(2)} .Bs`}
              />
            </section>
          )}
          <PrintItem
            label={getPaymentMethod(order.payment_method)}
            value={`-${order.total.toFixed(2)} Bs`}
            floatingLabel=""
          />
          <PrintItem label="TOTAL" value="0.00 Bs" floatingLabel="" />
        </>
      )}
      {(order?.payment_method === PAYMENT_METHOD.CASH ||
        order?.payment_method === PAYMENT_METHOD.QR) && (
        <>
          <PrintItem
            label="TOTAL"
            value={`${(order.total * 1).toFixed(2)} Bs`}
            floatingLabel=""
          />
          <PrintItem
            label="FORMA DE PAGO"
            value={getPaymentMethod(order.payment_method)}
          />
        </>
      )}
      <Divider />
      {(order?.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
        order?.payment_method === PAYMENT_METHOD.ONLINE_QR) && (
        <Box className="container-print-paid">
          <Box className="print-paid-xpress">
            <label style={{ fontSize: '20px' }}>PAGO RECIBIDO POR XPRESS</label>
          </Box>
        </Box>
      )}
    </div>,
    document.getElementById('root')
  );
}

export default OrderPrinting;
