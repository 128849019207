// eslint-disable-next-line import/prefer-default-export
export const validateImageSizes = (
  blobFile,
  { maxWidth, minWidth, maxHeight, minHeight },
  setCallback,
  file
) => {
  const img = new Image();
  let isValid = {
    maxWidth: true,
    minWidth: true,
    maxHeight: true,
    minHeight: true,
    errorMessage: false,
    message: '',
    file
  };
  img.onload = () => {
    const { width } = img;
    const { height } = img;
    if (minWidth && width < minWidth)
      isValid = {
        ...isValid,
        minWidth: false,
        errorMessage: true,
        message: 'validations.images.min_w'
      };
    if (maxWidth && width > maxWidth)
      isValid = {
        ...isValid,
        maxWidth: false,
        errorMessage: true,
        message: 'validations.images.max_w'
      };
    if (minHeight && height < minHeight)
      isValid = {
        ...isValid,
        minHeight: false,
        errorMessage: true,
        message: 'validations.images.min_h'
      };
    if (maxHeight && height > maxHeight)
      isValid = {
        ...isValid,
        maxHeight: false,
        errorMessage: true,
        message: 'validations.images.max_h'
      };
    setCallback(isValid);
  };
  img.src = blobFile;
};
