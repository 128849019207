/* eslint-disable import/prefer-default-export */
export const generatePDFHeaders = headers => {
  const result = [];
  for (let i = 0; i < headers.length; i += 1) {
    result.push({
      id: headers[i],
      name: headers[i],
      prompt: headers[i],
      width: 150,
      align: 'center',
      padding: 0
    });
  }
  return result;
};
