import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang/lib';
import { CardContent, Box, Button, makeStyles } from '@material-ui/core';
import { jsPDF } from 'jspdf';
import Page from '../../../components/Page';
import HeaderTop from './HeaderTop';
import Header from './Header';
import Sales from './Sales';
import { setDefaultFields, setFilter } from '../../../actions/filterActions';
import ExportToExcel from '../../../components/ExportToExcel';
import useFilter from '../../../hooks/filter/useFilter';
import xpressLogo from '../../../assets/Images/xpress.logo.png';
import { generatePDFHeaders } from '../../../utils/pdfHelper';
import { getBankInfo } from '../../../services/settingsService';
import { createMomentOnBolivianTime } from '../../../utils/date';
import { TYPE_CHARGE } from '../../../dto/enum';
import TabsHeader from '../../../components/Tab/Tabs';
import { useAllActiveRestaurantSubsidiaries } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import {
  useRestaurantInvoices,
  useRestaurantMergedAndCurrentOrdered
} from '../../../hooks/useRestaurant';

const useStyle = makeStyles(theme => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

const SalesReport = () => {
  const moment = require('moment');
  require('moment/locale/es');
  const translation = useTranslation();
  const currentRestaurant = useSelector(state => state.account.restaurant);
  const [orders, setOrders] = useState(null);
  const dispatch = useDispatch();
  const filter = useFilter();
  const [restaurant, setRestaurant] = useState(currentRestaurant);
  const [subsidiary, setSubsidiary] = useState(null);
  const classes = useStyle();
  const [chargesHistory, setChargesHistory] = useState({});
  const [creditOrders, setCreditOrders] = useState([]);
  const [ordersTotals, setOrdersTotals] = useState({});
  const [valueTab, setValueTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const allRestaurantsMerged = useSelector(
    state => state.account.allRestaurantsMerged
  );
  const allSubsidiariesRest = useAllActiveRestaurantSubsidiaries(restaurant.id);
  const restaurants = useRestaurantMergedAndCurrentOrdered();

  const invoicesPerRestaurant = useRestaurantInvoices(
    filter.restaurant,
    3,
    createMomentOnBolivianTime(filter.toDate)
      .add(1, 'day')
      .format('YYYY-MM-DD')
  );
  useEffect(() => {
    return () => dispatch(setDefaultFields());
  }, [dispatch]);

  useEffect(() => {
    if (valueTab === 0) {
      if (currentRestaurant) setRestaurant(currentRestaurant);
    } else {
      const restSelected = currentRestaurant.restaurantsMerged[valueTab - 1];
      const restaurantMerged = allRestaurantsMerged.find(
        rest => rest.id === restSelected.restaurantId
      );
      setRestaurant(restaurantMerged);
    }
    // eslint-disable-next-line
  }, [currentRestaurant, valueTab]);

  useEffect(() => {
    dispatch(setFilter({ restaurant: restaurant.id }));
    // eslint-disable-next-line
  }, [restaurant]);

  const handleChangeTab = (event, index) => {
    setValueTab(index);
  };

  useEffect(() => {
    if (restaurants.length > 0) {
      const newTabs = [];
      restaurants.forEach((subRest, index) => {
        newTabs.push({
          value: index,
          label: subRest.restaurant_name
        });
      });
      setTabs(newTabs);
    }
  }, [restaurants]);

  const contentOrders = tableContent => {
    setOrders(tableContent);
  };

  const contentChargesHistory = chargesHistoryRest => {
    setChargesHistory(chargesHistoryRest);
  };

  const getTotals = totals => {
    setOrdersTotals(totals);
  };
  const getCreditOrders = ordersCredit => {
    setCreditOrders(ordersCredit);
  };
  const getSalesReport = type => {
    if (!orders) return [];
    const data = orders.map(order => {
      if (type === 'pdf') {
        return {
          [translation('accounting.table.counter')]: `${order.counter}`,
          [translation('accounting.table.order_number')]: order.orderNumber,
          [translation('accounting.table.rest')]: order.restaurant,
          [translation('accounting.table.date')]: order.date,
          [translation('accounting.table.status')]: order.statusValue,
          [translation('accounting.table.city')]: order.city,
          [translation('accounting.table.service')]: order.service,
          [translation(
            'accounting.table.subsidi_by_restaurant'
          )]: order.subsidiByRestaurant,
          [translation('accounting.table.sale')]: `${order.saleValue}`,
          [translation(
            'accounting.table.commission_percentage'
          )]: order.commissionXpressPercentage,
          [translation(
            'accounting.table.commission_entregas'
          )]: `${order.commissionEntregas}`,
          [translation(
            'accounting.table.commission_online_percentage'
          )]: order.commissionOnlinePercentage,
          [translation(
            'accounting.table.commission'
          )]: `${order.commissionValue}`,
          [translation('accounting.table.promo')]: order.promotionTitle,
          [translation('accounting.table.discount')]: `${order.discountValue}`,
          [translation('accounting.table.refund')]: `${order.refundValue}`,
          [translation(
            'accounting.table.restaurant_reward'
          )]: `${order.restRewardPaidRestaurantValue}`,
          [translation(
            'accounting.table.delivery_free_restaurant'
          )]: `${order.deliveryFreeRestaurant}`,
          [translation('accounting.table.sum_charge')]: `${order.sumCharge}`
        };
      }
      return [
        order.counter,
        order.orderNumber,
        order.restaurant,
        order.date,
        order.statusValue,
        order.city,
        order.saleValue,
        order.subsidiByRestaurant,
        order.service,
        order.commissionXpressPercentage,
        order.commissionEntregas,
        order.commissionOnlinePercentage,
        order.commissionValue,
        order.promotionTitle,
        order.discountValue,
        order.refundValue,
        order.restRewardPaidRestaurantValue,
        order.deliveryFreeRestaurant,
        order.sumCharge
      ];
    });
    const {
      tSale,
      tCommission,
      tEntregas,
      tSumOfCommissions,
      tDiscount,
      tRefund,
      tRestRewardPaidRestaurant,
      tDeliveryFreeRestaurant,
      totalCharge
    } = ordersTotals;

    if (type === 'pdf') {
      data.push({
        [translation('accounting.table.counter')]: '',
        [translation('accounting.table.order_number')]: '',
        [translation('accounting.table.rest')]: translation(
          'accounting.table.totals'
        ),
        [translation('accounting.table.date')]: '',
        [translation('accounting.table.status')]: '',
        [translation('accounting.table.city')]: '',
        [translation('accounting.table.sale')]: `${tSale}`,
        [translation('accounting.table.subsidi_by_restaurant')]: `-`,
        [translation('accounting.table.service')]: '',
        [translation('accounting.table.commission_percentage')]: `-`,
        [translation('accounting.table.commission_entregas')]: `-`,
        [translation('accounting.table.commission_online_percentage')]: `-`,
        [translation('accounting.table.commission')]: `${tCommission}`,
        [translation('accounting.table.promo')]: '',
        [translation('accounting.table.discount')]: `${tDiscount}`,
        [translation('accounting.table.refund')]: `${tRefund}`,
        [translation(
          'accounting.table.restaurant_reward'
        )]: `${tRestRewardPaidRestaurant}`,
        [translation('accounting.table.delivery_free_restaurant')]: `-`,
        [translation('accounting.table.sum_charge')]: `${totalCharge}`
      });
    } else {
      data.push([
        translation('accounting.table.totals'),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        tSale,
        '-',
        tEntregas,
        '-',
        tSumOfCommissions,
        '-',
        tDiscount,
        tRefund,
        tRestRewardPaidRestaurant,
        tDeliveryFreeRestaurant,
        totalCharge
      ]);
    }

    const columns = [
      translation('accounting.table.counter'),
      translation('accounting.table.order_number'),
      translation('accounting.table.rest'),
      translation('accounting.table.date'),
      translation('accounting.table.status'),
      translation('accounting.table.city'),
      translation('accounting.table.service'),
      translation('accounting.table.subsidi_by_restaurant'),
      translation('accounting.table.sale'),
      translation('accounting.table.commission_percentage'),
      translation('accounting.table.commission_entregas'),
      translation('accounting.table.commission_online_percentage'),
      translation('accounting.table.commission'),
      translation('accounting.table.promo'),
      translation('accounting.table.discount'),
      translation('accounting.table.refund'),
      translation('accounting.table.restaurant_reward'),
      translation('accounting.table.delivery_free_restaurant'),
      translation('accounting.table.sum_charge')
    ];

    if (type === 'pdf') {
      const pdfColumns = generatePDFHeaders(columns);
      return {
        pdfColumns,
        pdfData: data
      };
    }

    return {
      xSteps: 1,
      columns,
      data
    };
  };
  const getTextCredit = order => {
    if (order.textCredit === 'Reembolso') {
      return translation('accounting.summary.refund');
    }
    return translation('accounting.summary.promo');
  };
  const getCreditDetailsReport = type => {
    if (!creditOrders) return [];
    const columns = [
      translation('accounting.table.counter'),
      translation('accounting.table.city'),
      translation('accounting.table.order_number'),
      translation('accounting.table.rest'),
      translation('accounting.table.date'),
      translation('accounting.table.credit'),
      translation('accounting.table.detail'),
      translation('accounting.table.total')
    ];
    const data = creditOrders.map(o => {
      if (type === 'pdf') {
        return {
          [translation('accounting.table.counter')]: `${o.counter}`,
          [translation('accounting.table.city')]: o.city,
          [translation('accounting.table.order_number')]: o.order_code,
          [translation('accounting.table.rest')]: o.restaurantFullName,
          [translation('accounting.table.date')]: o.date,
          [translation('accounting.table.credit')]: `${getTextCredit(o)}`,
          [translation('accounting.table.detail')]: `${o.textDetail}`,
          [translation('accounting.table.total')]: `${o.total}`
        };
      }

      return [
        o.counter,
        o.city,
        o.order_code,
        o.restaurantFullName,
        o.date,
        getTextCredit(o),
        o.textDetail,
        o.total
      ];
    });

    const tCredit = ordersTotals.totalCredit;

    if (type === 'pdf') {
      const credit = ordersTotals.totalCredit;

      data.push({
        [translation('accounting.table.counter')]: ``,
        [translation('accounting.table.city')]: '',
        [translation('accounting.table.order_number')]: '',
        [translation('accounting.table.rest')]: translation(
          'accounting.table.totals'
        ),
        [translation('accounting.table.date')]: '',
        [translation('accounting.table.credit')]: '',
        [translation('accounting.table.detail')]: '',
        [translation('accounting.table.total')]: `${credit}`
      });
    } else {
      data.push([
        translation('accounting.table.totals'),
        '',
        '',
        '',
        '',
        '',
        '',
        tCredit
      ]);
    }

    if (type === 'pdf') {
      const pdfColumns = generatePDFHeaders(columns);
      return {
        pdfColumns,
        pdfData: data
      };
    }

    return {
      xSteps: 1,
      columns,
      data
    };
  };

  const getTotalsTableReport = type => {
    if (!orders) return [];
    const {
      totalCredit,
      tSumOfCommissions,
      tIvaCommission,
      subtotalCharge,
      totalCharge
    } = ordersTotals;
    const columns = [
      translation('accounting.table.totals'),
      translation('accounting.table.amount')
    ];
    const data = [
      [translation('accounting.table.total_sales'), tSumOfCommissions],
      [translation('accounting.table.total_iva'), tIvaCommission],
      [translation('accounting.table.sub_total'), subtotalCharge],
      [translation('accounting.table.total_credit'), totalCredit],
      [translation('accounting.table.total_to_charge'), totalCharge]
    ];
    if (type === 'pdf') {
      const pdfColumns = generatePDFHeaders(columns);
      const pdfData = [
        {
          [translation('accounting.table.totals')]: translation(
            'accounting.table.total_sales'
          ),
          [translation('accounting.table.amount')]: `${tSumOfCommissions}`
        },
        {
          [translation('accounting.table.totals')]: translation(
            'accounting.table.total_iva'
          ),
          [translation('accounting.table.amount')]: `${tIvaCommission}`
        },
        {
          [translation('accounting.table.totals')]: translation(
            'accounting.table.sub_total'
          ),
          [translation('accounting.table.amount')]: `${subtotalCharge}`
        },
        {
          [translation('accounting.table.totals')]: translation(
            'accounting.table.total_credit'
          ),
          [translation('accounting.table.amount')]: `${totalCredit}`
        },
        {
          [translation('accounting.table.totals')]: translation(
            'accounting.table.total_to_charge'
          ),
          [translation('accounting.table.amount')]: `${totalCharge}`
        }
      ];
      return {
        pdfColumns,
        pdfData
      };
    }

    return {
      xSteps: 1,
      columns,
      data
    };
  };

  const getSalesReportHeader = () => {
    return {
      xSteps: 7,
      ySteps: 3,
      columns: [translation('accounting.table.sales_report')],
      data: []
    };
  };

  const getCreditDetailsHeader = () => {
    return {
      xSteps: 4,
      ySteps: 3,
      columns: [translation('accounting.table.credit_details')],
      data: []
    };
  };

  const getTotalsTableHeader = () => {
    return {
      xSteps: 1,
      ySteps: 3,
      columns: [translation('accounting.table.total_header')],
      data: []
    };
  };

  const setMainHeader = type => {
    const month = createMomentOnBolivianTime().format('MMMM - YYYY');
    const parsedMonth = moment(month, 'MMMM - YYYY')
      .locale('es')
      .format('MMMM - YYYY');
    const rest = `${restaurant ? restaurant.name : ''} ${
      subsidiary ? `- ${subsidiary.name}` : ''
    }`;
    if (type === 'pdf') {
      return {
        date: parsedMonth,
        rest
      };
    }
    return {
      ySteps: 2,
      xSteps: 7,
      columns: ['Xpress'],
      data: [[parsedMonth], [rest]]
    };
  };

  const setAdditionalComments = type => {
    const additionalComments = restaurant?.invoice_comments
      ? restaurant.invoice_comments
      : '-';
    const columns = [translation('accounting.table.additional_comments')];
    if (type === 'pdf') {
      const pdfColumns = generatePDFHeaders(columns);
      const pdfData = [
        {
          [translation(
            'accounting.table.additional_comments'
          )]: additionalComments
        }
      ];
      return {
        pdfColumns,
        pdfData
      };
    }
    return {
      ySteps: 2,
      xSteps: 7,
      columns,
      data: [[additionalComments]]
    };
  };

  const setFeesTable = type => {
    const result = [];
    const columnsCommission = [];
    const columnsSubscripcion = [];
    const chargeDelivery = ['delivery'];
    const chargePickup = ['pickup'];
    const chargeMotos = ['motos xpress'];
    const chargeIva = ['IVA'];
    let isTypeCommission = false;
    let isTypeSubscription = false;

    const chargeXpressSubscription = ['Xpress (Pickup, Delivery)'];
    const chargeEntregasSubscription = ['Entregas'];

    if (restaurant) {
      columnsSubscripcion.push(
        translation('accounting.table.susbcription_type')
      );
      columnsCommission.push(
        `${translation('accounting.table.commission_type')} %`
      );
      const chargesHistoryRestaurant = chargesHistory[restaurant.id] || [];
      chargesHistoryRestaurant.forEach(chargesHistoryRest => {
        const startsOn = createMomentOnBolivianTime(
          new Date(chargesHistoryRest.startsOn)
        ).format('DD/MM/YYYY');
        if (chargesHistoryRest?.chargeType === TYPE_CHARGE.SUBSCRIPTION) {
          const { subscriptionDetails } = chargesHistoryRest;
          columnsSubscripcion.push(startsOn);
          chargeXpressSubscription.push(subscriptionDetails.xpress.price);
          chargeEntregasSubscription.push(subscriptionDetails.entregas.price);
          isTypeSubscription = true;
        }
        if (chargesHistoryRest?.chargeType === TYPE_CHARGE.COMMISSION) {
          const { charges } = chargesHistoryRest;
          columnsCommission.push(startsOn);
          chargeDelivery.push(charges.delivery);
          chargePickup.push(charges.pick_up);
          chargeMotos.push(charges.xpress_motos);
          chargeIva.push(charges.iva);
          isTypeCommission = true;
        }
      });
    } else {
      isTypeSubscription = true;
      isTypeCommission = true;
      columnsCommission.push(translation('accounting.table.commission_type'));
      columnsCommission.push(translation('accounting.table.commission_amount'));
      chargeDelivery.push('-');
      chargePickup.push('-');
      chargeMotos.push('-');
      chargeIva.push('-');

      columnsSubscripcion.push(
        translation('accounting.table.susbcription_type')
      );
      columnsSubscripcion.push('-');
      chargeXpressSubscription.push('-');
      chargeEntregasSubscription.push('-');
    }
    const dataCommission = [
      chargeDelivery,
      chargePickup,
      chargeMotos,
      chargeIva
    ];
    const dataSubscription = [
      chargeXpressSubscription,
      chargeEntregasSubscription
    ];

    if (type === 'pdf') {
      if (restaurant) {
        const resultPdf = [];
        const pdfColumnsCommission = generatePDFHeaders(columnsCommission);
        const pdfColumnsSubscription = generatePDFHeaders(columnsSubscripcion);
        let pdfDataCommission = [];
        let pdfDataSubscription = [];
        const chargesHistoryRestaurant = chargesHistory[restaurant.id];
        const nameColumnsCommission = chargesHistoryRestaurant
          ?.filter(
            chargeHist => chargeHist?.chargeType === TYPE_CHARGE.COMMISSION
          )
          .map(chargeHist =>
            createMomentOnBolivianTime(new Date(chargeHist.startsOn)).format(
              'DD/MM/YYYY'
            )
          );
        const nameColumnsSubcription = chargesHistoryRestaurant
          ?.filter(
            chargeHist => chargeHist?.chargeType === TYPE_CHARGE.SUBSCRIPTION
          )
          .map(chargeHist =>
            createMomentOnBolivianTime(new Date(chargeHist.startsOn)).format(
              'DD/MM/YYYY'
            )
          );
        const nameTypeCharge = translation('accounting.table.commission_type');
        const nameTypeChargeSubs = translation(
          'accounting.table.susbcription_type'
        );
        const objetDelivery = { [`${nameTypeCharge} %`]: 'delivery' };
        const objectPickUp = { [`${nameTypeCharge} %`]: 'pickup' };
        const objectrowMotos = { [`${nameTypeCharge} %`]: 'motos xpress' };
        const objectrowIva = { [`${nameTypeCharge} %`]: 'IVA' };

        const objectXpressSubs = {
          [`${nameTypeChargeSubs}`]: 'Xpress (Pickup, Delivery)'
        };
        const objectEntregasSubs = { [`${nameTypeChargeSubs}`]: 'Entregas' };

        if (nameColumnsCommission && chargesHistoryRestaurant) {
          nameColumnsCommission.forEach(name => {
            objetDelivery[`${name}`] = '';
            objectPickUp[`${name}`] = '';
            objectrowMotos[`${name}`] = '';
            objectrowIva[`${name}`] = '';
          });

          nameColumnsSubcription.forEach(name => {
            objectXpressSubs[`${name}`] = '';
            objectEntregasSubs[`${name}`] = '';
          });

          chargesHistoryRestaurant.forEach(chargesHistoryRest => {
            const { charges } = chargesHistoryRest;
            const nameDate = createMomentOnBolivianTime(
              new Date(chargesHistoryRest.startsOn)
            ).format('DD/MM/YYYY');
            if (chargesHistoryRest?.chargeType === TYPE_CHARGE.COMMISSION) {
              objetDelivery[`${nameDate}`] = `${charges.delivery}`;
              objectPickUp[`${nameDate}`] = `${charges.pick_up}`;
              objectrowMotos[`${nameDate}`] = `${charges.xpress_motos}`;
              objectrowIva[`${nameDate}`] = `${charges.iva}`;
            }
            if (chargesHistoryRest?.chargeType === TYPE_CHARGE.SUBSCRIPTION) {
              const { subscriptionDetails } = chargesHistoryRest;
              objectXpressSubs[
                `${nameDate}`
              ] = `${subscriptionDetails.xpress.price}`;
              objectEntregasSubs[
                `${nameDate}`
              ] = `${subscriptionDetails.entregas.price}`;
            }
          });
        }

        pdfDataCommission = [
          objetDelivery,
          objectPickUp,
          objectrowMotos,
          objectrowIva
        ];

        pdfDataSubscription = [objectXpressSubs, objectEntregasSubs];
        if (isTypeCommission)
          resultPdf.push({
            pdfColumns: pdfColumnsCommission,
            pdfData: pdfDataCommission
          });
        if (isTypeSubscription)
          resultPdf.push({
            pdfColumns: pdfColumnsSubscription,
            pdfData: pdfDataSubscription
          });
        return resultPdf;
      }
      if (!restaurant) {
        const resultPdf = [];
        const pdfColumnsCommission = generatePDFHeaders(columnsCommission);
        const pdfColumnsSubscription = generatePDFHeaders(columnsSubscripcion);
        const pdfDataCommission = [
          {
            [translation('accounting.table.commission_type')]: 'delivery',
            [translation(
              'accounting.table.commission_amount'
            )]: chargeDelivery[1]
          },
          {
            [translation('accounting.table.commission_type')]: 'pickup',
            [translation('accounting.table.commission_amount')]: chargePickup[1]
          },
          {
            [translation('accounting.table.commission_type')]: 'motos xpress',
            [translation('accounting.table.commission_amount')]: chargeMotos[1]
          },
          {
            [translation('accounting.table.commission_type')]: 'IVA',
            [translation('accounting.table.commission_amount')]: chargeIva[1]
          }
        ];
        const pdfDataSubscription = [
          {
            [translation(
              'accounting.table.susbcription_type'
            )]: 'Xpress (Pickup, Delivery)',
            '-': chargeXpressSubscription[1]
          },
          {
            [translation('accounting.table.susbcription_type')]: 'Entregas',
            '-': chargeEntregasSubscription[1]
          }
        ];
        resultPdf.push({
          pdfColumns: pdfColumnsCommission,
          pdfData: pdfDataCommission
        });
        resultPdf.push({
          pdfColumns: pdfColumnsSubscription,
          pdfData: pdfDataSubscription
        });
        return resultPdf;
      }
    }
    if (isTypeCommission)
      result.push({
        ySteps: 3,
        xSteps: 7,
        columns: columnsCommission,
        data: dataCommission
      });
    if (isTypeSubscription) {
      result.push({
        ySteps: isTypeCommission ? 1 : 3,
        xSteps: 7,
        columns: columnsSubscripcion,
        data: dataSubscription
      });
    }
    return result;
  };

  const getInvoicesRestaurants = () => {
    const main = {
      xSteps: 1,
      ySteps: 3,
      columns: ['CORTES SEMANALES Y/O MENSUALES'],
      data: [[]]
    };
    const invoiceData =
      invoicesPerRestaurant?.map(invoice => {
        if (invoice) {
          const periodInvoice = `${createMomentOnBolivianTime(
            invoice?.period?.from.toDate()
          ).format('DD/MM/YYYY')} - ${createMomentOnBolivianTime(
            invoice?.period?.to.toDate()
          ).format('DD/MM/YYYY')}`;
          const cashCommissionInvoice = Number(
            parseFloat(invoice?.summary?.cash?.total_comission || 0)
          ).toFixed(2);
          const onlineCommissionInvoice = Number(
            parseFloat(invoice?.summary?.online?.total_comission || 0)
          ).toFixed(2);
          const totalCommissionInvoice = Number(
            parseFloat(
              invoice?.summary?.online?.total_comission +
                invoice?.summary?.cash?.total_comission || 0
            )
          ).toFixed(2);
          const refundInvoice = Number(
            parseFloat(invoice?.amount_comission_refund || 0)
          ).toFixed(2);
          return [
            periodInvoice,
            cashCommissionInvoice,
            onlineCommissionInvoice,
            totalCommissionInvoice,
            refundInvoice,
            invoice?.getCharges()
          ];
        }
        return [];
      }) || [];
    const columns = {
      ySteps: 0,
      xSteps: 1,
      columns: [
        'RANGO FECHA',
        'COMISION EFECTIVO',
        'COMISION EN LINEA',
        'TOTAL COMISIONES',
        'REEMBOLSO',
        'TOTAL DEUDA'
      ],
      data: [...invoiceData]
    };
    return [main, columns];
  };

  const getDataSet = () => {
    const salesReport = getSalesReport();
    const creditDetails = getCreditDetailsReport();
    const totals = getTotalsTableReport();
    const salesHeader = getSalesReportHeader();
    const creditHeader = getCreditDetailsHeader();
    const totalHeader = getTotalsTableHeader();
    const mainHeader = setMainHeader();
    const feesTable = setFeesTable();
    const additionalComments = setAdditionalComments();
    const invoiceData =
      filter.restaurant && filter.restaurant !== 'all'
        ? getInvoicesRestaurants()
        : [];

    const dataSet = [
      { ...mainHeader },
      { ...additionalComments },
      ...feesTable,
      { ...salesHeader },
      { ...salesReport },
      { ...creditHeader },
      { ...creditDetails },
      { ...totalHeader },
      { ...totals },
      ...invoiceData
    ];
    return dataSet;
  };

  const getPaymentData = async () => {
    const bankInfo = await getBankInfo();
    const columns = [
      translation('accounting.table.field'),
      translation('accounting.table.value')
    ];
    const pdfData = [
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.invoice_name'
        ),
        [translation('accounting.table.value')]: bankInfo.invoice_name
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.nit'
        ),
        [translation('accounting.table.value')]: bankInfo.nit
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.bank'
        ),
        [translation('accounting.table.value')]: bankInfo.bank
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.account_number'
        ),
        [translation('accounting.table.value')]: bankInfo.account_number
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.account_type'
        ),
        [translation('accounting.table.value')]: bankInfo.account_type
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.coin'
        ),
        [translation('accounting.table.value')]: bankInfo.coin
      }
    ];
    const pdfColumns = generatePDFHeaders(columns);
    return {
      pdfColumns,
      pdfData
    };
  };

  const getInvoiceReport = () => {
    const columns = [
      translation('accounting.table.field'),
      translation('accounting.table.value')
    ];
    const pdfData = [
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.invoice_name'
        ),
        [translation('accounting.table.value')]: restaurant?.invoice_name || '-'
      },
      {
        [translation('accounting.table.field')]: translation(
          'accounting.table.nit'
        ),
        [translation('accounting.table.value')]: restaurant?.nit || '-'
      }
    ];
    const pdfColumns = generatePDFHeaders(columns);
    return {
      pdfColumns,
      pdfData
    };
  };

  const exportPDF = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: 'landscape',
      format: [430, 210]
    });
    const { width } = pdf.internal.pageSize;
    const title = setMainHeader('pdf');
    const additionalComments = setAdditionalComments('pdf');
    const feeInfo = setFeesTable('pdf');
    const salesReport = getSalesReport('pdf');
    const creditDetailsReport = getCreditDetailsReport('pdf');
    const totalReport = getTotalsTableReport('pdf');
    const paymentData = await getPaymentData('pdf');
    const invoiceReport = getInvoiceReport('pdf');
    let positionY = 5;
    pdf.addImage(xpressLogo, width / 2 - 20, positionY, 40, 40);
    pdf.setFontSize(20).setTextColor('#66C88E');
    positionY += 50;
    pdf.text(title.date, width / 2, positionY, null, null, 'center');
    positionY += 7;
    pdf.text(title.rest, width / 2, positionY, null, null, 'center');
    pdf.setTextColor('#000000');
    positionY += 8;
    pdf.table(
      width / 2 - 20,
      positionY,
      additionalComments.pdfData,
      additionalComments.pdfColumns,
      {
        autoSize: true,
        fontSize: 7
      }
    );
    positionY += 28;
    feeInfo.forEach((fee, index) => {
      if (feeInfo.length === 2 && index === 1) positionY += 55;
      pdf.table(width / 2 - 20, positionY, fee.pdfData, fee.pdfColumns, {
        autoSize: true,
        fontSize: 7
      });
    });
    if (
      feeInfo.length === 1 &&
      feeInfo[0].pdfColumns[0].id ===
        `${translation('accounting.table.commission_type')} %`
    ) {
      positionY += 57;
    } else {
      positionY += 40;
    }
    if (feeInfo.length === 2) {
      pdf.addPage();
      positionY = 10;
    }

    pdf.setFontSize(20).setTextColor('#66C88E');
    pdf.text(
      translation('accounting.table.sales_report'),
      width / 2,
      positionY,
      null,
      null,
      'center'
    );
    pdf.setTextColor('#000000');
    positionY += 5;
    pdf.table(0, positionY, salesReport.pdfData, salesReport.pdfColumns, {
      autoSize: true,
      fontSize: 6
    });
    pdf.addPage();
    pdf.setFontSize(20).setTextColor('#66C88E');
    pdf.text(
      translation('accounting.table.credit_details'),
      width / 2,
      10,
      null,
      null,
      'center'
    );
    pdf.setTextColor('#000000');
    pdf.table(
      4,
      15,
      creditDetailsReport.pdfData,
      creditDetailsReport.pdfColumns,
      {
        autoSize: true,
        fontSize: 10
      }
    );

    pdf.addPage();
    pdf.setFontSize(20).setTextColor('#66C88E');
    pdf.text(
      translation('accounting.table.total_header'),
      width / 2,
      10,
      null,
      null,
      'center'
    );
    pdf.setTextColor('#000000');
    pdf.table(width / 2 - 40, 15, totalReport.pdfData, totalReport.pdfColumns, {
      autoSize: true,
      fontSize: 10
    });
    pdf.setFontSize(20).setTextColor('#66C88E');
    pdf.addPage();
    pdf.text(
      translation('accounting.table.payment_data'),
      width / 2,
      65,
      null,
      null,
      'center'
    );
    pdf.setTextColor('#000000');
    pdf.table(width / 2 - 40, 70, paymentData.pdfData, paymentData.pdfColumns, {
      autoSize: true,
      fontSize: 10
    });
    pdf.setFontSize(20).setTextColor('#66C88E');
    pdf.text(
      translation('accounting.table.invoice_data'),
      width / 2,
      150,
      null,
      null,
      'center'
    );
    pdf.setTextColor('#000000');
    pdf.table(
      width / 2 - 40,
      160,
      invoiceReport.pdfData,
      invoiceReport.pdfColumns,
      {
        autoSize: true,
        fontSize: 10
      }
    );
    pdf.save(
      `sales_report_${createMomentOnBolivianTime().format('YYYY_MM_DD_HH_mm')}`
    );
  };

  if (!restaurant && !allSubsidiariesRest) return null;
  return (
    <Page
      style={{ marginRight: '25px' }}
      title={translation('accounting.title_page')}
    >
      <Box ml={2} mt={3}>
        <HeaderTop />
        <TabsHeader value={valueTab} onChange={handleChangeTab} tabs={tabs} />
      </Box>
      <Header
        restaurant={restaurant}
        setSubsidiary={setSubsidiary}
        allSubsidiariesRest={allSubsidiariesRest}
      />
      {allSubsidiariesRest && (
        <>
          <CardContent>
            <Box className={classes.buttonsContainer}>
              <ExportToExcel
                data={getDataSet}
                sheetName={`sales_report_${createMomentOnBolivianTime().format(
                  'YYYY_MM_DD_HH_mm'
                )}`}
                buttonLabel={translation('earning.export')}
                disabled={!orders}
              />
              <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                onClick={exportPDF}
                disabled={!orders}
              >
                pdf
              </Button>
            </Box>
          </CardContent>
          <CardContent>
            <Sales
              handleOrders={contentOrders}
              getTotals={getTotals}
              contentChargesHistory={contentChargesHistory}
              getCreditOrders={getCreditOrders}
            />
          </CardContent>
        </>
      )}
    </Page>
  );
};

export default SalesReport;
