import { getLanguage } from 'react-multi-lang/lib';

export default function getDeviceLanguage(simplify = false) {
  const language = getLanguage();
  if (simplify) {
    const data = language.split('_');
    return data[0];
  }
  return language;
}
