import admin from '../../config/firebaseConfig';
import Base from '../base';

export default class OrderProduct extends Base {
  constructor(
    id = '',
    price,
    cooking_time = 10,
    available = true,
    updated_at = admin.firestore.FieldValue.serverTimestamp()
  ) {
    super(id, updated_at);
    this.quantity = 1;
    this.unit_price = parseFloat(price);
    this.selected_options = [];
    this.additional_requirement = '';
    this.available = available;
    this.product_name = 'Paquete';
    this.cooking_time = parseInt(cooking_time, 10);
    this.simple_price = parseFloat(price);
  }
}
