import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  makeStyles,
  SvgIcon,
  Typography,
  Button
} from '@material-ui/core';
import { ArrowLeft as ArrowLeftIcon, Clock as CLockIcon } from 'react-feather';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang/lib';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { ORDER_STATUS, USER_STATUS } from '../../../../dto/enum';
import Orderstatus from '../../../orders/OrderDetailsView/Orderstatus';
import { getDate } from '../../../../utils/date';
import { getOrderNumber } from '../../../../hooks/Order/useOrders';
import Userstatus from '../../users/UserDetailsView/Userstatus';
import useUser from '../../../../hooks/useUser';
import { updateOrder } from '../../../../services/orderServices';

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  backIcon: {
    marginRight: theme.spacing(1)
  },
  rightHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

function Header({ order, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(state => state.account);
  const translation = useTranslation();
  const [userClient] = useUser(order.user_id);
  const [nameAssigned, setNameAssigned] = useState(false);

  const dataOrder = { ...order };

  if (dataOrder.delivery_time) {
    dataOrder.delivery_time = moment(getDate(dataOrder.delivery_time));
  }

  const updateInfoATC = async () => {
    setNameAssigned(`${user.first_name} ${user.last_name}`);
    await updateOrder(order.id, {
      assigned: user,
      assigned_id: user.id
    });
  };

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(order, 'assigned')) {
      setNameAssigned(
        `${order.assigned.first_name} ${order.assigned.last_name}`
      );
    }
    // eslint-disable-next-line
  }, [order]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin"
            component={RouterLink}
          >
            {translation('admin_navBar.principal_dashboard')}
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/admin/orders"
            component={RouterLink}
          >
            {translation('admin_orders.orders')}
          </Link>
        </Breadcrumbs>

        <Typography variant="h3" color="textPrimary" display="inline">
          <IconButton
            className={classes.backIcon}
            onClick={() => history.goBack()}
          >
            <SvgIcon fontSize="small">
              <ArrowLeftIcon />
            </SvgIcon>
          </IconButton>
          {`${translation('orders.details.order')} ${getOrderNumber(
            dataOrder
          )}`}
        </Typography>
        <Box display="flex">
          <Typography variant="h4">
            {translation('orders.details.assigned')}
            {': '}
          </Typography>
          {nameAssigned ? (
            <Typography variant="h4" style={{ marginLeft: 10 }}>
              {nameAssigned}
            </Typography>
          ) : (
            order.status !== ORDER_STATUS.COMPLETED &&
            order.status !== ORDER_STATUS.CANCELED && (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 10, marginBottom: 10 }}
                size="small"
                onClick={updateInfoATC}
              >
                {translation('orders.details.assign_me')}
              </Button>
            )
          )}
        </Box>
      </Grid>

      <Grid item className={classes.rightHeader}>
        {userClient &&
          (userClient.status === USER_STATUS.SUSPENDED ||
            userClient.status === USER_STATUS.ARCHIVED) && (
            <Box mb={1}>
              <Userstatus user={userClient} />
            </Box>
          )}
        <Orderstatus orderStatus={order.status} />
        {dataOrder.delivery_time &&
          (order.status === ORDER_STATUS.ACCEPTED ||
            order.status === ORDER_STATUS.READY_TO_PICK) && (
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ paddingLeft: 8 }}
            >
              {`${translation('orders.details.header.time_delay')} `}
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <CLockIcon />
              </SvgIcon>
              {dataOrder.delivery_time.format('h:mm a')}
            </Typography>
          )}
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default Header;
