import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Breadcrumbs, Grid, Link, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Header({ className, area, ...rest }) {
  const classes = useStyles();
  const translate = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/areas"
            component={RouterLink}
          >
            {translate('areas.title')}
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/admin/areas"
            component={RouterLink}
          >
            {area.name ? area.name : translate('areas.new_area')}
          </Link>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
