import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { LocationOn } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import 'firebase/firestore';
import { useTranslation } from 'react-multi-lang/lib';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import MapPicker from './MapPicker';
import ModalSchedule from '../Modal/modalSchedule';
import useCategories from '../../hooks/useCategories';
import { DEFAULT_SCHEDULE_VALUE } from '../../constants';

const SubsidiaryRegister = ({
  formRef,
  subsidiary,
  id,
  setSubsidiary,
  cities
}) => {
  const [showMap, setShowMap] = useState(false);
  const [location, setLocation] = useState();
  const translation = useTranslation();
  const categories = useCategories();
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [dialCode, setDialCode] = useState(591);
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          name: subsidiary.name ? subsidiary.name : '',
          representant_name: subsidiary.legal_representant.name || '',
          phone_number: subsidiary.legal_representant.phone || '',
          address: subsidiary.address,
          address_2: subsidiary.address_2,
          city: subsidiary.city,
          categories: [],
          schedule: DEFAULT_SCHEDULE_VALUE
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            translation('register_restaurant.subsidiary.required')
          ),
          representant_name: Yup.string().required(
            translation(
              'register_restaurant.subsidiary.representant_name.required'
            )
          ),
          phone_number: Yup.string()
            .max(255)
            .required(
              translation(
                'register_restaurant.subsidiary.representant_phone.required'
              )
            ),
          address: Yup.string()
            .max(255)
            .required(translation('restaurant.subsidiary.address.require'))
            .test(
              'map',
              translation('restaurant.subsidiary.address.map'),
              () => !!location
            ),
          city: Yup.string()
            .max(255)
            .required(translation('restaurant.subsidiary.city.require')),
          categories: Yup.array().required(
            translation('restaurant.subsidiary.categories.require')
          )
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const sub = { ...values };
          sub.legal_representant = {
            name: values.representant_name,
            phone: `+${values.phone_number}`
          };
          sub.manager = values.representant_name;
          sub.manager_phone = `+${values.phone_number}`;
          delete sub.representant_name;
          delete sub.phone_number;
          try {
            if (!phoneNumber && !dialCode) {
              setErrors({
                phone_number: translation(
                  'basic_information.personal_info.phone.require'
                )
              });
            } else {
              setSubsidiary(id, {
                ...subsidiary,
                ...sub,
                location
              });
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values
        }) => {
          const city = cities.find(c => c.id === values.city);
          const optionalMapProps = {};
          if (city && city.location) {
            optionalMapProps.center = {
              lat: city.location.latitude,
              lng: city.location.longitude
            };
          }

          return (
            <>
              <Box
                p={3}
                style={{
                  background: 'rgba(255, 255, 255, 0.56)',
                  border: '0.4px solid #999999',
                  borderRadius: '8px'
                }}
              >
                <Typography
                  variant="h2"
                  color="textPrimary"
                  style={{ fontWeight: 'bold', textAlign: 'start' }}
                >
                  {`${translation('restaurant.subsidiary.title')} ${id}:`}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={translation(
                          'register_restaurant.subsidiary.name'
                        )}
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={Boolean(
                          touched.representant_name && errors.representant_name
                        )}
                        fullWidth
                        helperText={
                          touched.representant_name && errors.representant_name
                        }
                        label={translation(
                          'register_restaurant.subsidiary.representant_name.name'
                        )}
                        margin="normal"
                        name="representant_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.representant_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <Box mt={2} style={{ width: '100%' }}>
                        <PhoneInput
                          inputStyle={{
                            background: '#f2f2f2'
                          }}
                          placeholder="Celular"
                          specialLabel="Celular"
                          regions={['america', 'europe']}
                          country="bo"
                          value={values.phone_number}
                          onChange={(phone, data) => {
                            setDialCode(data.dialCode);
                            setPhoneNumber(phone);
                            setFieldValue('phone_number', phone);
                          }}
                          variant="outlined"
                        />
                        {errors.phone_number && (
                          <Box>
                            <FormHelperText error>
                              {errors.phone_number}
                            </FormHelperText>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <TextField
                        error={Boolean(touched.address_2 && errors.address_2)}
                        fullWidth
                        helperText={touched.address_2 && errors.address_2}
                        label={translation(
                          'restaurant.subsidiary.address.local_address'
                        )}
                        margin="normal"
                        name="address_2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <TextField
                        style={{ margin: 0 }}
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        label={translation('restaurant.subsidiary.city.name')}
                        margin="normal"
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.city}
                        variant="outlined"
                      >
                        <option value="" />
                        {cities.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={categories}
                        getOptionLabel={s => {
                          if (!s) return;
                          // eslint-disable-next-line consistent-return
                          return s.name;
                        }}
                        filterSelectedOptions
                        value={values?.categories?.map(v =>
                          categories.find(s => v === s.id)
                        )}
                        name="categories"
                        onChange={(event, value) => {
                          setFieldValue(
                            'categories',
                            value.map(s => s.id)
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={translation(
                              'subsidiary.basic_information.category'
                            )}
                            error={Boolean(
                              touched.categories && errors.categories
                            )}
                            helperText={touched.categories && errors.categories}
                            placeholder={translation(
                              'subsidiary.basic_information.category'
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                      <TextField
                        error={Boolean(touched.address && errors.address)}
                        fullWidth
                        helperText={touched.address && errors.address}
                        label={translation(
                          'restaurant.subsidiary.address.address'
                        )}
                        margin="normal"
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowMap(true)}>
                                <LocationOn />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div style={{ padding: 10 }}>
                        <ModalSchedule
                          value={values.schedule}
                          onChange={schedule => {
                            setFieldValue('schedule', schedule);
                          }}
                          name="schedule"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Box>
              <MapPicker
                open={showMap}
                setShowMap={setShowMap}
                setPicker={setLocation}
                {...optionalMapProps}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

SubsidiaryRegister.propTypes = {
  formRef: PropTypes.object,
  subsidiary: PropTypes.object,
  id: PropTypes.number,
  setSubsidiary: PropTypes.func
};

export default SubsidiaryRegister;
