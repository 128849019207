const BANKS = [
  { name: 'Banco BISA S.A.' },
  { name: 'Banco de Crédito de Bolivia S.A.' },
  { name: 'Banco de la Nación Argentina S. A.' },
  { name: 'Banco Económico S.A.' },
  { name: 'Banco Fassil S.A.' },
  { name: 'Banco Fie S.A.' },
  { name: 'Banco Fortaleza S.A.' },
  { name: 'Banco Ganadero S.A.' },
  { name: 'Banco Mercantil Santa Cruz S.A.' },
  { name: 'Banco Nacional de Bolivia S.A.' },
  { name: 'Banco para el Fomento a Iniciativas Económicas S.A.' },
  { name: 'Banco Prodem S.A.' },
  { name: 'Banco Solidario S.A.' },
  { name: 'Banco Unión S.A.' },
  { name: 'Banco PYME de la Comunidad S.A.' },
  { name: 'Banco PYME Ecofuturo S.A.' }
];

export default BANKS;
