import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormLabel,
  Button,
  DialogActions,
  makeStyles,
  LinearProgress,
  Box,
  IconButton
} from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import firebaseService from 'src/services/firebaseService';
import { COLLECTIONS } from 'src/constants';
import { useDropzone } from 'react-dropzone';
import admin from 'src/config/firebaseConfig';
import { Clear } from '@material-ui/icons';
import Modal from '../../../../components/Modal';

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& >*': {
      marginBottom: 12
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  input: {
    display: 'block',
    marginBottom: 10,
    width: '100%'
  },
  image: {
    height: 300,
    width: 300,
    objectFit: 'contain',
    padding: 10,
    border: 'none'
  },
  noImage: {
    height: 300,
    width: 300,
    objectFit: 'contain',
    padding: 100,
    border: 'none'
  }
});

const CreateOrEditModal = ({ open, onClose, mode }) => {
  const classes = useStyles();
  const [confirming, setConfirming] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const isEditing = mode === 'edit';
  const [category, setCategory] = useState(isEditing ? open : {});

  const firestore = useFirestore();

  function uploadImage(file, id) {
    return new Promise((resolve, reject) => {
      const storageRef = admin.storage().ref();
      const imageRef = storageRef.child(`${COLLECTIONS.CATEGORIES}/${id}`);
      const uploadTask = imageRef.put(file);
      setUploading(true);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setImage({ ...image, progress });
        },
        // eslint-disable-next-line no-shadow
        error => {
          // eslint-disable-next-line default-case
          switch (error.code) {
            case 'storage/unauthorized':
              reject('No tiene permiso autorizado');
              break;
            case 'storage/canceled':
              reject('Se cancelo');
              break;
            case 'storage/unknown':
              reject('Tuvimos un problema al subir');
              break;
          }
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();

          setUploading(false);
          resolve(url);
        }
      );
    });
  }

  async function createCategory() {
    const today = new Date();
    if (isEditing) {
      let imageUrl = category.img.original
        ? category.img.original
        : category.img;
      if (image) {
        imageUrl = await uploadImage(image.file, category.id);
      }
      firestore.update(`${COLLECTIONS.CATEGORIES}/${category.id}`, {
        name: category.name,
        img: {
          original: imageUrl
        },
        updated_at: today
      });
      onClose();
    } else {
      const newId = firebaseService.randomId();
      let imageUrl = category.img;
      if (image) {
        imageUrl = await uploadImage(image.file, newId);
      }
      firestore.set(`${COLLECTIONS.CATEGORIES}/${newId}`, {
        id: newId,
        name: category.name,
        img: {
          original: imageUrl
        },
        enable: true,
        value: category.name.toUpperCase(),
        created_at: today,
        updated_at: today
      });
      onClose();
    }
  }

  const changeCategoryField = field => ev => {
    setCategory({ ...category, [field]: ev.target.value });
    setHasChanged(true);
  };
  const handleDrop = useCallback(
    async acceptedFiles => {
      const fileStructure = acceptedFiles.map(file => ({
        file,
        progress: 0,
        complete: false
      }));
      const file = fileStructure[0];
      const fileUrl = await toBase64(file.file);
      setImage(file);
      setCategory({
        ...category,
        img: {
          original: fileUrl
        }
      });
      setHasChanged(true);
    },
    // eslint-disable-next-line
    [category]
  );
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: handleDrop
  });
  const img = category.img?.x400 || category.img?.x80 || category.img?.original;

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (hasChanged) {
          setConfirming(true);
        } else {
          onClose();
        }
      }}
    >
      {confirming && (
        <Modal
          type="comfirmation"
          message={{
            btnLeft: 'Si',
            btnRight: 'No',
            header: 'Estas seguro que deseas salir sin guardar tus cambios?'
          }}
          handleConfirm={() => {
            setConfirming(false);
            onClose();
          }}
          handleClose={() => setConfirming(false)}
        />
      )}
      <DialogTitle>
        <IconButton
          style={{ position: 'absolute', right: 8, top: 8 }}
          onClick={() => {
            if (hasChanged) {
              setConfirming(true);
            } else {
              onClose();
            }
          }}
        >
          <Clear />
        </IconButton>
        {isEditing ? 'Editar Categoría' : 'Crear Categoría'}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div style={{ width: '100%' }}>
          <FormLabel className={classes.input}>
            Nombre de la Categoría
          </FormLabel>
          <TextField
            className={classes.input}
            variant="outlined"
            onChange={changeCategoryField('name')}
            value={category.name}
            disabled={uploading}
            InputProps={{ style: { width: '100%' } }}
          />
        </div>
        <div
          {...getRootProps()}
          style={{
            position: 'relative',
            width: '100%',
            border: '1px solid gray'
          }}
        >
          <img
            alt="category"
            src={img || '/static/images/undraw_add_file2_gvbb.svg'}
            className={img ? classes.image : classes.noImage}
          />
          <input
            {...getInputProps({
              disabled: uploading,
              multiple: false
            })}
          />
          <Button
            disabled={uploading}
            color="secondary"
            variant="contained"
            style={{ position: 'absolute', bottom: 22, right: 20 }}
          >
            Cambiar Imagen
          </Button>
        </div>
        {!!image && (
          <Box width="100%" mb={1}>
            <LinearProgress variant="determinate" value={image.progress} />
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          disabled={uploading || (!category.image && !image) || !category.name}
          onClick={createCategory}
          color="primary"
          variant="contained"
        >
          Guardar Cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrEditModal;
