import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import { useTranslation } from 'react-multi-lang/lib';
import { useUserBusinessExecutive } from '../../../hooks/AdminHooks/users/useUsers';
// import { useAllCitiesNative } from '../../../hooks/AdminHooks/cities/useCities';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexDirection: 'column'
  },
  basicInfo: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    width: '100%'
  },
  basicInfoForm: {
    maxWidth: '500px',
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    gap: '60px',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '75px'
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  card: {
    maxWidth: '865px',
    width: '100%',
    maxHeight: '630px',
    height: '100%',
    position: 'relative',
    textAlign: '-webkit-center'
  },
  background: {
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
    background: '#ffffff',
    height: '100%',
    width: '100%'
  },
  formPosition: {
    position: 'absolute',
    padding: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrayIcon: {
    position: 'absolute',
    color: '#56B19E',
    top: 10,
    left: 10
  },
  loaderBox: {
    width: '100%',
    alignContent: 'center'
  },
  errorText: {
    fontSize: '8px',
    color: 'red'
  }
}));

const ExecutiveRepresentative = ({
  className,
  handleNext,
  updateRestaurant,
  handleBack
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const businessExecutive = useUserBusinessExecutive();
  // const cities = useAllCitiesNative();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.basicInfo)}>
        <Box className={classes.card}>
          <Box className={classes.background} />
          <Box className={classes.formPosition}>
            <Box p={2} className={classes.basicInfoForm}>
              <Typography
                variant="h1"
                color="textPrimary"
                className={clsx(classes.title)}
              >
                {translation('basic_information.executiveBusiness.title')}
              </Typography>
              <Formik
                initialValues={{
                  city: null,
                  businessExecutive: null
                }}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  if (!values.businessExecutive) {
                    setErrors({
                      businessExecutive: 'El ejecutivo comercial es requerido'
                    });
                  }
                  if (values.businessExecutive) {
                    try {
                      updateRestaurant({
                        business_executive: {
                          email: values.businessExecutive.email,
                          first_name: values.businessExecutive.first_name,
                          last_name: values.businessExecutive.last_name,
                          id: values.businessExecutive.id,
                          phone_number: values.businessExecutive.phone_number
                        }
                      });
                      handleNext();
                    } catch (error) {
                      setStatus({ success: false });
                      setErrors({ submit: error.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    {!businessExecutive && (
                      <Box className={classes.loaderBox}>
                        <CircularProgress color="secondary" />
                      </Box>
                    )}
                    {businessExecutive && (
                      <Box>
                        <Autocomplete
                          id="business-executive"
                          freeSolo
                          style={{ maxWidth: '500px', width: '100%' }}
                          options={businessExecutive}
                          getOptionLabel={
                            option => `${option.first_name} ${option.last_name}`
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          onChange={(e, value) => {
                            setFieldValue('businessExecutive', value);
                          }}
                          value={values.busimessExecutive}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Seleccione un representante ejecutivo"
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                        />
                        {Boolean(
                          touched.businessExecutive && errors.businessExecutive
                        ) && (
                          <Typography className={classes.errorText}>
                            {touched.businessExecutive &&
                              errors.businessExecutive}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {/* {!cities && <CircularProgress color="secondary" />} */}
                    {/* {cities && (
                      <Box>
                        <Autocomplete
                          id="cities"
                          freeSolo
                          style={{ maxWidth: '500px', width: '100%' }}
                          options={cities}
                          getOptionLabel={option => option.name}
                          onChange={(e, value) => {
                            setFieldValue('city', value);
                          }}
                          value={values.city}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Ciudad"
                              margin="normal"
                              variant="outlined"
                            />
                          )}
                        />
                        {Boolean(touched.city && errors.city) && (
                          <Typography className={classes.errorText}>
                            {touched.city && errors.city}
                          </Typography>
                        )}
                      </Box>
                    )} */}
                    <Box mt={2}>
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {translation('next')}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ExecutiveRepresentative;
