import React from 'react';
import { Box } from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';
import { SaveAlt } from '@material-ui/icons';
import { GrSelect } from 'react-icons/gr';

const HoverButtons = ({
  classes,
  onOpen,
  section,
  onDelete,
  onDownloadImage,
  isTemplate
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      className={classes.containerIcons}
    >
      {!section && (
        <>
          {isTemplate && (
            <Box
              className={classes.iconOption}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={onOpen}
            >
              <style>
                {`
                .gricon path {
                  stroke: white;
                }
               
                `}
              </style>
              <GrSelect
                style={{
                  width: '24px',
                  height: '24px',
                  color: 'white',
                  paddingTop: 2
                }}
                className="gricon"
              />
            </Box>
          )}

          <Box
            className={classes.iconOption}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onDownloadImage}
          >
            <SaveAlt className={classes.styleIconOption} />
          </Box>
        </>
      )}

      <Box
        className={classes.iconOption}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={onDelete}
      >
        <IoMdClose className={classes.styleIconOption} />
      </Box>
    </Box>
  );
};

export default HoverButtons;
