import React, { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Button, Grid } from '@material-ui/core';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { useSnackbar } from 'notistack';
import ExpressTable from '../../../../components/Table/ExpressTable';
import { useSubsidiaryProducts } from '../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { useRestaurantProducts } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';

import { editProduct } from '../../../../services/productsService';
import { editSharedProduct } from '../../../../services/sharedProductsService';
import XpressModal from '../../../../components/Modal';

const Menu = props => {
  const { restId, subId } = props;
  const translation = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const isTemplate = useIsTemplateMenu();
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const products = useSubsidiaryProducts(subId, restId);
  const restaurantProducts = useRestaurantProducts(restId);

  const tableColumns = [
    {
      id: 'id',
      label: translation('admin_subsidiary.common_table.no'),
      minWidth: 50,
      align: 'center'
    },
    {
      id: 'name',
      label: translation('admin_subsidiary.common_table.name'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'section',
      label: translation('admin_subsidiary.common_table.section'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'availability',
      label: translation('admin_subsidiary.common_table.availability'),
      minWidth: 170,
      align: 'center'
    }
  ];
  let tableContent = [];

  if (products && subId) {
    tableContent = products.map((item, index) => ({
      id: index + 1,
      name: item.name,
      section: item.menu_section ? item.menu_section.name : '-',
      availability: item.availability
        ? translation('admin_common.available')
        : translation('admin_common.not_available'),
      dbId: item.id
    }));
  }

  if (restaurantProducts && restId) {
    tableContent = restaurantProducts.map((item, index) => ({
      id: index + 1,
      name: item.name,
      section: item.menu_section ? item.menu_section.name : '-',
      availability: item.availability
        ? translation('admin_common.available')
        : translation('admin_common.not_available'),
      dbId: item.id
    }));
  }

  const deleteProduct = row => {
    setShowModal(true);
    setModal(
      <XpressModal
        type="comfirmation"
        message={{
          header: translation('notification.sure_to_delete'),
          btnLeft: translation('notification.yes'),
          btnRight: translation('notification.cancel')
        }}
        handleConfirm={async () => {
          setShowModal(false);
          if (isTemplate) {
            editSharedProduct({ enable: false }, row.dbId);
          } else {
            editProduct({ enable: false }, row.dbId);
          }
          enqueueSnackbar(translation('notification.correctly_save'), {
            variant: 'success'
          });
        }}
        handleClose={async () => {
          setShowModal(false);
        }}
      />
    );
  };

  return (
    <>
      <Grid item container justify="flex-end">
        <Button
          style={{ cursor: 'pointer' }}
          color="primary"
          component={RouterLink}
          to="/admin/editor/menu-sections"
        >
          {translation('admin_subsidiary.buttons.go_menu')}
        </Button>
      </Grid>
      <ExpressTable
        columns={[...tableColumns]}
        rows={[...tableContent]}
        actions={{
          edit: row =>
            history.push({
              pathname: `/admin/editor/${
                restId ? 'shared_menu' : 'menu-sections'
              }/${row.section}/${row.dbId}`,
              state: { url: pathname, tab: 2, restaurantId: restId }
            }),
          delete: row => deleteProduct(row)
        }}
      />
      {showModal && modal}
    </>
  );
};

export default Menu;
