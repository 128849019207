export const registerStatuses = {
  pending: 'pending',
  rejected: 'rejected',
  accepted: 'accepted'
};

export const ORDER_STATUS = {
  ON_CART: 'ON_CART',
  IN_PROCESS: 'IN_PROCESS',
  ACCEPTED: 'ACCEPTED',
  DELIVERING: 'DELIVERING',
  READY_TO_PICK: 'READY_TO_PICK',
  READY_TO_CASHIER: 'READY_TO_CASHIER',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',

  NEED_CHANGES: 'NEED_CHANGES',
  CHANGES_ACCEPTED: 'CHANGES_ACCEPTED',
  SCHEDULE: 'SCHEDULE'
};

export const ORDER_TYPE = {
  PICK_UP: 'PICK_UP',
  DELIVERY: 'DELIVERY',
  IN_COMMERCE: 'IN_COMMERCE'
};

export const DELIVERY_STATUS = {
  UNASSIGNED: 'UNASSIGNED',
  REQUESTED: 'REQUESTED',
  GOING_TO_PICK: 'GOING_TO_PICK',
  ON_PLACE: 'ON_PLACE',
  DELIVERING: 'DELIVERING',
  COMPLETED: 'COMPLETED'
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED'
};

export const TICKET_STATUS = {
  WAITING: 'WAITING',
  ON_GOING: 'ON_GOING',
  PASSED: 'PASSED'
};

export const USER_ROLES = {
  OWNER: 'OWNER',
  CASHIER: 'CASHIER',
  SUPERVISOR: 'SUPERVISOR',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ACCOUNTANT: 'ACCOUNTANT',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  MARKETING: 'MARKETING'
};

export const categoryOptions = [
  { value: 'MEAT', label: 'Carne' },
  { value: 'SOUPS', label: 'Sopas' },
  { value: 'SANDWICH', label: 'Sandwiches' },
  { value: 'PIZZA', label: 'Pizza' },
  { value: 'SUSHI', label: 'Sushi' },
  { value: 'PASTA', label: 'Pasta' },
  { value: 'WRAPS', label: 'Wraps' },
  { value: 'SEAFOOD', label: 'Comida de Mar' },
  { value: 'CHICKEN', label: 'Pollo' },
  { value: 'SALAD', label: 'Ensalada' },
  { value: 'VEGETARIAN_FOOD', label: 'Comida Vegetariana' },
  { value: 'DESSERTS_AND_SWEETS', label: 'Postres y Dulces' }
];

export const docStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const INVOICE_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  REJECTED: 'REJECTED',
  INVALID: 'INVALID',
  PAST: 'PAST'
};

export const TYPE_OF_DISCOUNT = {
  PERCENTAGE: 'PERCENTAGE',
  CASH: 'CASH'
};

export const DRIVER_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  WAITING_FOR_ORDERS: 'WAITING_FOR_ORDERS',
  DELIVERING: 'DELIVERING',
  GOING_TO_PICK: 'GOING_TO_PICK'
};

export const PAYMENT_METHOD = {
  CASH: 'CASH',
  BNB: 'BNB',
  ONLINE_CARD: 'ONLINE_CARD',
  TIGO_MONEY: 'TIGO_MONEY',
  LIBELULA: 'LIBELULA',
  QR: 'QR',
  ONLINE_QR: 'ONLINE_QR'
};

export const REGISTER_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
  SUBSCRIPTION_EXPIRED: 'SUBSCRIPTION_EXPIRED'
};

export const DRIVER_APPLICATION_STATUS = {
  APPLIED: 'APPLIED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  EXPIRED_LICENSE: 'EXPIRED_LICENSE',
  EXPIRED_SOAT: 'EXPIRED_SOAT'
};

export const PROMO_BANNER_TYPE = {
  RESTAURANT: 'RESTAURANT',
  CATEGORY: 'CATEGORY',
  PRODUCT: 'PRODUCT',
  INFO: 'INFO'
};

export const UNVERIFIED_REASONS = {
  HIGH_COST: 'HIGH_COST',
  DRIVER_ORDER: 'DRIVER_ORDER',
  FIRST_ORDER: 'FIRST_ORDER',
  OTHER: 'OTHER'
};

export const ORDER_SERVICES = {
  PICKUP: 'PICK_UP',
  DELIVERY: 'DELIVERY'
};

export const LOG_SOURCE = {
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  USER: 'USER',
  MERCHANT: 'MERCHANT',
  BOT: 'BOT'
};

export const LOG_ACTION = {
  STATUS_CHANGED: 'STATUS_CHANGED',
  DRIVER_CHANGED: 'DRIVER_CHANGED',
  VERIFIED: 'VERIFIED',
  NOTIFY_CLIENT: 'NOTIFY_CLIENT',
  NONE: 'NONE',
  READY_FOR_DRIVER_TO_PICK_UP: 'READY_FOR_DRIVER_TO_PICK_UP'
};

export const GOAL_TYPE = {
  ORDER: 'ORDER',
  AMOUNT: 'AMOUNT'
};

export const REWARD_TYPE = {
  RESTAURANT: 'RESTAURANT'
};
export const TYPE_ACTION_LOGS = {
  CLOSE: 'CLOSE',
  OPEN: 'OPEN',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ADD: 'ADD'
};

export const ACTION_LOGS = {
  ALL_SUBSIDIARIES: 'ALL_SUBSIDIARIES',
  SCHEDULE_SUBSIDIARY: 'SCHEDULE_SUBSIDIARY',
  SHARED_MENU: 'SHARED_MENU',
  RECEIVING_ORDERS: 'RECEIVING_ORDERS'
};

export const TARGET_LOGS = {
  MENU_SECTION: 'MENU_SECTION',
  PRODUCT_SECTION: 'PRODUCT_SECTION',
  PRODUCT: 'PRODUCT',
  ADDITION: 'ADDITION'
};

export const LOG_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ORDER: 'ORDER'
};

export const LOG_PLATFORM = {
  MERCHANT_WEB: 'MERCHANT_WEB'
};

export const TYPE_CHARGE = {
  COMMISSION: 'COMMISSION',
  SUBSCRIPTION: 'SUBSCRIPTION'
};

export const XPRESS_ORDER_TYPE = {
  NORMAL: 'NORMAL',
  FOOD_COURT_PARENT: 'FOOD_COURT_PARENT',
  FOOD_COURT_CHILD: 'FOOD_COURT_CHILD'
};

export const SUBSCRIPTION_INITIAL_VALUES = {
  months: 1,
  xpress: {
    PICK_UP: false,
    DELIVERY: false,
    price: 0
  },
  entregas: {
    ENTREGAS: false,
    price: 0
  }
};

export const TYPE_DEMAND = {
  NORMAL: 'NORMAL',
  MODERATE: 'MODERATE',
  HIGH: 'HIGH',
  EXCESSIVE: 'EXCESSIVE'
};

export const COOKED_AVERAGE = {
  VERY_FAST: 'VERY_FAST',
  FAST: 'FAST',
  NORMAL: 'NORMAL',
  SLOW: 'SLOW',
  VERY_SLOW: 'VERY_SLOW'
};
