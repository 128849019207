import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useConversationStyles } from './chatStyles';
import ConversationMessages from './ConversationMessages';
import { useChatMesseges, useChat } from '../../../hooks/Chat/useChat';
import { lastMessageViewrs } from '../../../services/chatService';
import { setChats } from '../../../actions/chatActions';

const ContentChat = ({ chatId, isCollapsed, setIsCollapsed }) => {
  const classes = useConversationStyles();
  const messages = useChatMesseges(chatId);
  const user = useSelector(state => state.account.user);
  const messageBox = useRef(null);
  const [chat] = useChat(chatId || '');
  const dispatch = useDispatch();

  const scrollDown = () => {
    messageBox.current.scrollIntoView({ behavior: 'smooth' });
  };

  const lastMessageViewers = async () => {
    if (!chat.last_message_viewers.includes(user.id)) {
      const lasMessageViewrs = [...chat.last_message_viewers];
      lasMessageViewrs.push(user.id);
      await lastMessageViewrs(chat.id, {
        last_message_viewers: [...lasMessageViewrs]
      });
    }
  };

  useEffect(() => {
    if (chat && messages && !isCollapsed) scrollDown();
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (chat) {
      if (chat.is_active === false) dispatch(setChats([]));
      else dispatch(setChats([chat]));
    }
    if (!chat) setIsCollapsed(false);
    if (chat && !isCollapsed) lastMessageViewers();
    // eslint-disable-next-line
  }, [chat, isCollapsed]);

  const renderMessages = () => {
    if (!messages.length) return <></>;
    if (messages[0]?.chat_id !== chatId)
      return (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      );
    const dates = [];
    return messages.map((m, i) => {
      let isShowDate = false;
      let dateNew = '';
      if (i === 0) {
        dateNew = m.created_at ? m.created_at.toDate() : new Date();
        dates.push(dateNew);
        isShowDate = true;
      }
      if (i > 0) {
        const dateOld = dates[i - 1];
        dateNew = moment(
          m.created_at ? m.created_at.toDate() : new Date(),
          'YYYY-MM-DD'
        );
        if (!moment(dateNew).isSame(dateOld, 'day')) isShowDate = true;
        dates.push(dateNew);
      }
      return (
        <>
          <Box className={classes.messageData}>
            {isShowDate && (
              <Typography>{moment(dateNew).format('DD/MM/YYYY')}</Typography>
            )}
          </Box>
          <ConversationMessages m={m} user={user} chat={chat || {}} />
        </>
      );
    });
  };

  if (!chat)
    return (
      <Box m="auto" pt={9} className={classes.conversationContainer}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box className={classes.messagesContainer}>
      {messages && (
        <Box>
          {renderMessages()}
          <div ref={messageBox} />
        </Box>
      )}
    </Box>
  );
};

export default ContentChat;
