import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang/lib';
import { AppBar, Tabs, Tab, CardContent } from '@material-ui/core';
import Page from '../../../../components/Page';
import Header from './Header';
import Sales from './tabs/Sales';
import History from './tabs/History';
import { setDefaultFields } from '../../../../actions/filterActions';
import ExportToExcel from '../../../../components/ExportToExcel';
import useFilter from '../../../../hooks/filter/useFilter';
import { createMomentOnBolivianTime } from '../../../../utils/date';
import { useRestaurantInvoices } from '../../../../hooks/useRestaurant';

const ExpressAccounting = () => {
  const [value, setValue] = useState(0);
  const translation = useTranslation();
  const [orders, setOrders] = useState(null);
  const dispatch = useDispatch();
  const [totalSales, setTotalSales] = useState(0);
  const [totalCommission, setTotaCommission] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalToCharge, setTotalToCharge] = useState(0);
  const [totalRefund, setTotalRefund] = useState(0);
  const filter = useFilter();
  const [restaurant, setRestaurant] = useState(null);
  const [subsidiary, setSubsidiary] = useState(null);

  useEffect(() => {
    return () => dispatch(setDefaultFields());
  }, [dispatch]);

  const invoicesPerRestaurant = useRestaurantInvoices(
    filter.restaurant,
    3,
    createMomentOnBolivianTime(filter.toDate)
      .add(1, 'day')
      .format('YYYY-MM-DD')
  );

  const handleChange = (event, index) => {
    setValue(index);
  };

  const contentOrders = tableContent => {
    setOrders(tableContent);
  };

  const getTotals = (
    currentTotalSales,
    currentTotalCommission,
    currentTotalDiscount,
    currentTotalRefund,
    currentTotalToCharge
  ) => {
    setTotalSales(currentTotalSales);
    setTotaCommission(currentTotalCommission);
    setTotalDiscount(currentTotalDiscount);
    setTotalToCharge(currentTotalToCharge);
    setTotalRefund(currentTotalRefund);
  };

  const getTabContent = () => {
    switch (value) {
      case 0:
        return <Sales handleOrders={contentOrders} getTotals={getTotals} />;
      case 1:
        return <History />;
      default:
        console.log(' =( unhandle tab!');
        break;
    }
  };

  const getSalesReport = () => {
    if (!orders) return [];
    const data = orders.map(order => {
      const sale = order.saleValue.toString().replace(/\./g, ',');
      return [
        order.counter,
        order.orderNumber,
        order.restaurant,
        order.date,
        order.statusValue,
        order.city,
        sale,
        order.service,
        order.commissionPercentage,
        order.commission,
        order.promotionTitle,
        order.discount,
        order.refund
      ];
    });
    const tSale = orders
      .reduce((total, currentValue) => total + currentValue.sale * 1, 0)
      .toFixed(2);
    const tCP = orders
      .reduce(
        (total, currentValue) => total + currentValue.commissionPercentage * 1,
        0
      )
      .toFixed(2);
    const tC = orders
      .reduce((total, currentValue) => total + currentValue.commission * 1, 0)
      .toFixed(2);
    const tDisc = orders
      .reduce((total, currentValue) => total + currentValue.discount * 1, 0)
      .toFixed(2);
    const tRef = orders
      .reduce((total, currentValue) => total + currentValue.refund * 1, 0)
      .toFixed(2);
    data.push([
      translation('accounting.table.totals'),
      '',
      '',
      '',
      '',
      '',
      tSale,
      '',
      tCP,
      tC,
      '',
      tDisc,
      tRef
    ]);

    return {
      xSteps: 1,
      columns: [
        translation('accounting.table.counter'),
        translation('accounting.table.order_number'),
        translation('accounting.table.rest'),
        translation('accounting.table.date'),
        translation('accounting.table.status'),
        translation('accounting.table.city'),
        translation('accounting.table.sale'),
        translation('accounting.table.service'),
        translation('accounting.table.commission_percentage'),
        translation('accounting.table.commission'),
        translation('accounting.table.promo'),
        translation('accounting.table.discount'),
        translation('accounting.table.refund')
      ],
      data
    };
  };

  const getCreditDetailsReport = () => {
    if (!orders) return [];
    const data = orders
      .filter(o => o.discount * 1 > 0 || o.refund * 1 > 0)
      .map(o => [
        o.counter,
        o.city,
        o.orderNumber,
        o.restaurant,
        o.date,
        o.refund > 0
          ? translation('accounting.summary.refund')
          : translation('accounting.summary.promo'),
        o.refund > 0 ? o.refund_description : o.promotionTitle,
        o.refund > 0 ? o.refund : o.discount
      ]);

    const tCredit = data
      .reduce((total, currentValue) => total + currentValue[7] * 1, 0)
      .toFixed(2);
    data.push([
      translation('accounting.table.totals'),
      '',
      '',
      '',
      '',
      '',
      '',
      tCredit
    ]);

    return {
      xSteps: 1,
      columns: [
        translation('accounting.table.counter'),
        translation('accounting.table.city'),
        translation('accounting.table.order_number'),
        translation('accounting.table.rest'),
        translation('accounting.table.date'),
        translation('accounting.table.credit'),
        translation('accounting.table.detail'),
        translation('accounting.table.total')
      ],
      data
    };
  };

  const getTotalsTableReport = () => {
    if (!orders) return [];
    const data = [
      [translation('accounting.table.total_sales'), totalCommission * 1],
      [
        translation('accounting.table.total_credit'),
        totalDiscount * 1 + totalRefund * 1
      ],
      [translation('accounting.table.total_to_charge'), totalToCharge]
    ];
    return {
      xSteps: 1,
      columns: [
        translation('accounting.table.totals'),
        translation('accounting.table.amount')
      ],
      data
    };
  };

  const getSalesReportHeader = () => {
    return {
      xSteps: 7,
      ySteps: 3,
      columns: [translation('accounting.table.sales_report')],
      data: []
    };
  };

  const getCreditDetailsHeader = () => {
    return {
      xSteps: 4,
      ySteps: 3,
      columns: [translation('accounting.table.credit_details')],
      data: []
    };
  };

  const getTotalsTableHeader = () => {
    return {
      xSteps: 1,
      ySteps: 3,
      columns: [translation('accounting.table.total_header')],
      data: []
    };
  };

  const setMainHeader = () => {
    const month = moment(`${filter.year}-${filter.month}`).format(
      'MMMM - YYYY'
    );
    const rest = `${restaurant ? restaurant.label : ''} ${
      subsidiary ? `- ${subsidiary.name}` : ''
    }`;
    return {
      ySteps: 2,
      xSteps: 7,
      columns: ['Xpress'],
      data: [[month], [rest]]
    };
  };

  const setFeesTable = () => {
    const chargeDelivery = [
      'delivery',
      `${restaurant ? restaurant.charge.delivery : '-'}`
    ];
    const chargePickup = [
      'pickup',
      `${restaurant ? restaurant.charge.pick_up : '-'}`
    ];
    return {
      ySteps: 2,
      xSteps: 7,
      columns: [
        translation('accounting.table.commission_type'),
        translation('accounting.table.commission_amount')
      ],
      data: [chargeDelivery, chargePickup]
    };
  };
  const getInvoicesRestaurants = () => {
    const main = {
      xSteps: 1,
      ySteps: 3,
      columns: ['CORTES SEMANALES Y/O MENSUALES'],
      data: [[]]
    };
    const invoiceData =
      invoicesPerRestaurant?.map(invoice => {
        if (invoice) {
          const periodInvoice = `${createMomentOnBolivianTime(
            invoice?.period?.from.toDate()
          ).format('DD/MM/YYYY')} - ${createMomentOnBolivianTime(
            invoice?.period?.to.toDate()
          ).format('DD/MM/YYYY')}`;
          const cashCommissionInvoice = Number(
            parseFloat(invoice?.summary?.cash?.total_comission || 0)
          ).toFixed(2);
          const onlineCommissionInvoice = Number(
            parseFloat(invoice?.summary?.online?.total_comission || 0)
          ).toFixed(2);
          const totalCommissionInvoice = Number(
            parseFloat(
              invoice?.summary?.online?.total_comission +
                invoice?.summary?.cash?.total_comission || 0
            )
          ).toFixed(2);
          const refundInvoice = Number(
            parseFloat(invoice?.amount_comission_refund || 0)
          ).toFixed(2);
          return [
            periodInvoice,
            cashCommissionInvoice,
            onlineCommissionInvoice,
            totalCommissionInvoice,
            refundInvoice,
            invoice?.getCharges()
          ];
        }
        return [];
      }) || [];
    const columns = {
      ySteps: 0,
      xSteps: 1,
      columns: [
        'RANGO FECHA',
        'COMISION EFECTIVO',
        'COMISION EN LINEA',
        'TOTAL COMISIONES',
        'REEMBOLSO',
        'TOTAL DEUDA'
      ],
      data: [...invoiceData]
    };
    return [main, columns];
  };

  const getDataSet = () => {
    const salesReport = getSalesReport();
    const creditDetails = getCreditDetailsReport();
    const totals = getTotalsTableReport();
    const salesHeader = getSalesReportHeader();
    const creditHeader = getCreditDetailsHeader();
    const totalHeader = getTotalsTableHeader();
    const mainHeader = setMainHeader();
    const feesTable = setFeesTable();
    const invoiceData =
      filter.restaurant && filter.restaurant !== 'all'
        ? getInvoicesRestaurants()
        : [];

    const dataSet = [
      { ...mainHeader },
      { ...feesTable },
      { ...salesHeader },
      { ...salesReport },
      { ...creditHeader },
      { ...creditDetails },
      { ...totalHeader },
      { ...totals },
      ...invoiceData
    ];
    return dataSet;
  };

  return (
    <Page
      style={{ marginRight: '25px' }}
      title={translation('accounting.title_page')}
    >
      <Header
        totalSales={totalSales}
        tab={value}
        setRestaurant={setRestaurant}
        setSubsidiary={setSubsidiary}
      />
      <CardContent>
        {value === 0 && (
          <ExportToExcel
            data={getDataSet}
            sheetName={`sales_report_${moment().format('YYYY_MM_DD_HH_mm')}`}
            buttonLabel={translation('earning.export')}
            disabled={!orders}
          />
        )}
      </CardContent>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={translation('accounting.sales')} />
          <Tab label={translation('accounting.payment')} />
        </Tabs>
      </AppBar>
      <CardContent>{getTabContent()}</CardContent>
    </Page>
  );
};

export default ExpressAccounting;
