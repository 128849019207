import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { COLLECTIONS } from '../../../constants';
import { ORDER_STATUS, ORDER_TYPE } from '../../../dto/enum';

export const useCompletedOrders = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['subsidiary_id', '==', id],
        ['status', '==', 'COMPLETED']
      ],
      storeAs: `orders_completed`
    }
  ]);
  const ordersCompleted = useSelector(state => {
    const sortOrdener = state.firestore.ordered.orders_completed;
    if (sortOrdener && sortOrdener.length > 1)
      sortOrdener.slice().sort((a, b) => b.updated_at - a.updated_at);
    return sortOrdener;
  });

  return ordersCompleted;
};

export const useOrdersPerUser = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      where: [['user_id', '==', id]],
      storeAs: `orders_${id}`
    }
  ]);
  const orders = useSelector(state => state.firestore.ordered[`orders_${id}`]);

  return orders;
};

export const useOrderPerId = id => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      doc: id
    }
  ]);
  const order = useSelector(state => {
    if (
      state.firestore.data[COLLECTIONS.ORDER] &&
      state.firestore.data[COLLECTIONS.ORDER][id]
    ) {
      return state.firestore.data[COLLECTIONS.ORDER][id];
    }
  });

  return order;
};

export const useAdminNewOrders = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      where: [['status', '==', ORDER_STATUS.IN_PROCESS]],
      orderBy: ['created_at', 'desc'],
      storeAs: 'new_orders'
    }
  ]);
  const newOrders = useSelector(state => state.firestore.ordered.new_orders);
  if (newOrders) return newOrders[0];
  return {};
};

export const useTodayOrders = () => {
  let yesterday = moment();
  yesterday = yesterday.subtract(1, 'days');
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      where: [
        ['created_at', '>', yesterday.toDate()],
        ['order_type', '==', ORDER_TYPE.DELIVERY]
      ],
      orderBy: ['created_at', 'desc'],
      storeAs: 'today_orders'
    }
  ]);
  const newOrders = useSelector(state => state.firestore.ordered.today_orders);
  if (newOrders) return newOrders;
  return [];
};
