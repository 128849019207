import React, { useState, useEffect } from 'react';
import {
  Container,
  makeStyles,
  Divider,
  CircularProgress,
  Box,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent
} from '@material-ui/core';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { registerStatus } from 'src/constants';
import Page from '../../../components/Page';
import Header from './Header';
// import { useSubsidiary } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries'; //
import SubsidiaryHeader from './SubsidiaryHeader';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import SubsidiaryForm from './forms/SubsidiaryForm';
import { useSubsidiaryId } from '../../../hooks/Subsidiary/useCurrentSubsidiary';
// import DocumentsTable from './DocumentsTable';
// import Settings from './tabs/Settings';
// import Menu from './tabs/menu';
// import Results from '../../management/ScheduleManagementView/Results';
// import Sales from './tabs/Sales';
// import Qualifications from './tabs/Qualifications';
// import Payment from './tabs/Pay';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const Subsidiary = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const { /* status = '', */ id } = match.params;
  const tab = location.state ? location.state?.tab : 0;
  // const subsidiary = useSubsidiary(id);
  const subsidiary = useSubsidiaryId(id);
  const [value, setValue] = useState(0);
  const translation = useTranslation();
  const allCities = useAllCities();

  let loading = false;
  const isMerchant = match.url.startsWith('/app');

  if (!subsidiary || subsidiary.id !== id) {
    loading = true;
  }

  useEffect(() => {
    if (tab) setValue(tab);
  }, [tab]);

  const handleChange = (event, index) => {
    setValue(index);
  };

  const subsidiaryForm = () => {
    return <SubsidiaryForm id={subsidiary.id} allCities={allCities} />;
  };
  /*  const subsiadiarySettings = () => {
      return <Settings currentSubsiadiary={subsidiary} />;
    }; */

  /*   const documentsForm = () => {
    return <DocumentsTable id={subsidiary.id} type="subsidiary" />;
  };

  const subsidiaryMenu = () => {
    return <Menu subId={subsidiary.id} />;
  };

  const shceduleTab = () => {
    return <Results />;
  };

  const salesTab = () => {
    return <Sales subId={subsidiary.id} />;
  };

  const qualificationTab = () => {
    return <Qualifications subId={subsidiary.id} />;
  };

  const paymentTab = () => {
    return <Payment subId={subsidiary.id} />;
  }; */

  const getTabContent = () => {
    switch (value) {
      case 0:
        return subsidiaryForm();
      // case 1: return subsiadiarySettings();
      /*  case 2:
        return documentsForm();
      case 3:
        return subsidiaryMenu();
      case 4:
        return shceduleTab();
      case 5:
        return salesTab();
      case 6:
        return qualificationTab();
      case 7:
        return paymentTab(); */
      default:
        console.log(' =( unhandle tab!');
        break;
    }
  };

  const getDate = () => {
    if (
      subsidiary.archived_date &&
      subsidiary.register_status === registerStatus.ARCHIVED
    )
      return subsidiary.archived_date;
    if (
      subsidiary.suspention_date &&
      subsidiary.register_status === registerStatus.SUSPENDED
    )
      return subsidiary.suspention_date;
  };

  /* const getBreadCrumbLabel = () => {
    switch (status) {
      case 'active':
        return translation('admin_commerces.breadcrumbs.actives');
      case 'rejected':
        return translation('admin_commerces.breadcrumbs.rejected');
      case 'suspended':
        return translation('admin_commerces.breadcrumbs.suspended');
      case 'pending':
        return translation('admin_commerces.breadcrumbs.pending');
      case 'archived':
        return translation('admin_commerces.breadcrumbs.archived');
      default:
        break;
    }
  }; */

  return (
    <Page
      className={classes.root}
      title={translation('admin_commerces.title_page_subsidiary')}
    >
      <Container maxWidth={false}>
        {subsidiary && (
          <Header
            isMerchant={isMerchant}
            path={
              // isMerchant ?
              [
                {
                  link: `/app/configuration`,
                  label: translation('menu.sections.configuration')
                },
                {
                  link: `/app/configuration`,
                  label: subsidiary.restaurant_name || subsidiary.restaurant_id
                },
                {
                  link: `/app/configuration/subsidiary/${id}`,
                  label: subsidiary.name || id
                }
              ]
              /* : [
                {
                  link: `/admin/commerce/${status}`,
                  label: getBreadCrumbLabel()
                },
                {
                  link: `/admin/commerce/${status}/restaurant/${subsidiary.restaurant_id}`,
                  label:
                    subsidiary.restaurant_name || subsidiary.restaurant_id
                },
                {
                  link: `/admin/commerce/${status}/subsidiary/${id}`,
                  label: subsidiary.name || id
                }
              ] */
            }
          />
        )}
        <Divider style={{ marginTop: '10px' }} />
        {!subsidiary && (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}
        {subsidiary && !loading && (
          <>
            <SubsidiaryHeader
              bannerSrc={
                subsidiary.banner_photo ? subsidiary.banner_photo.x400 : ''
              }
              logo={subsidiary.logo_photo ? subsidiary.logo_photo.x80 : ''}
              name={subsidiary.name}
              restName={subsidiary.restaurant_name}
              regStatus={subsidiary.register_status}
              date={getDate()}
            />
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label={translation('admin_subsidiary.tabs.sub_doc')} />
                {/* <Tab label={translation('admin_subsidiary.tabs.sub_settings')} /> */}
                {/* {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.doc')} />
                )}
                {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.menu')} />
                )}
                {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.schedule')} />
                )}
                {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.sales')} />
                )}
                {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.scores')} />
                )}
                {!isMerchant && (
                  <Tab label={translation('admin_subsidiary.tabs.payments')} />
                )} */}
              </Tabs>
            </AppBar>
            {allCities && (
              <Card>
                <CardContent>{getTabContent()}</CardContent>
              </Card>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default Subsidiary;
