import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import { useAllActiveRestaurants } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import {
  sendNotificationToTopic,
  sendNotificationToTopics
} from '../../../hooks/AdminHooks/notifications';
import SendNotificationForm from '../../../components/Marketing/SendNotificationForm';

const USER_TOPIC_TYPE = {
  CLIENT: 'CLIENT',
  VISITOR: 'VISITOR'
};

function SendByRestaurants() {
  const translate = useTranslation();
  const activeRestaurants = useAllActiveRestaurants();
  const { enqueueSnackbar } = useSnackbar();

  const getOptions = () => {
    if (activeRestaurants) {
      return activeRestaurants;
    }
    return [];
  };
  const getTopicToSend = (destination, userType) => {
    switch (userType) {
      case USER_TOPIC_TYPE.VISITOR:
        return `${destination}_visitor`;
      case USER_TOPIC_TYPE.CLIENT:
        return `${destination}_client`;
      default:
        return 'unknown';
    }
  };
  const sendNotification = async data => {
    const { title, body, destination, userType, image } = data;
    try {
      if (title && body) {
        const message = {
          title,
          body,
          image
        };
        if (destination === 'ALL') {
          const topics = [
            ...activeRestaurants.map(item => getTopicToSend(item.id, userType))
          ];
          await sendNotificationToTopics(topics, message);
        } else {
          const topic = getTopicToSend(destination, userType);
          await sendNotificationToTopic(topic, message);
        }
        enqueueSnackbar(translate('marketing.notification_sended'), {
          variant: 'success'
        });
      }
    } catch (e) {
      enqueueSnackbar(translate('marketing.notification_failed'), {
        variant: 'error'
      });
    }
  };

  return (
    <SendNotificationForm options={getOptions()} onSubmit={sendNotification} />
  );
}

export default SendByRestaurants;
