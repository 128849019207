import moment from 'moment';
import { EstimatedTime } from '../dto/estimatedTime';
import { ORDER_TYPE_SUBSIDIARY } from '../constants/index';
import { ORDER_TYPE } from '../dto/enum';

function extraDistanceTime(deliveryDistance) {
  if (deliveryDistance >= 5000) {
    return (Math.trunc(deliveryDistance / 1000) - 4) * 5;
  }
  return 0;
}

export function getEstimatedTime(
  { subsidiary, cooking_time, delivery_distance, created_at, order_type },
  current_date
) {
  let extraTime;
  const currentMoment = moment(current_date);
  const { type } = subsidiary.cooked_average;
  const [params] = ORDER_TYPE_SUBSIDIARY.filter(tp => tp.type === type);
  const { min, max } = params;
  if (
    order_type !== ORDER_TYPE.PICK_UP ||
    order_type !== ORDER_TYPE.IN_COMMERCE
  ) {
    extraTime = cooking_time + extraDistanceTime(delivery_distance) + 15;
  } else {
    extraTime = cooking_time + 10;
  }
  const estimatedTime = new moment(created_at.toDate()).add(
    extraTime,
    'minutes'
  );
  const low = moment(estimatedTime).add(min, 'minutes');
  const high = moment(estimatedTime).add(max, 'minutes');

  const lastEstimatedTime = new EstimatedTime({
    low,
    high
  });

  const difference =
    (currentMoment.toDate().getTime() -
      lastEstimatedTime.high.toDate().getTime()) /
    1000 /
    60;

  const addMinutes = Math.ceil(difference / 10);

  if (addMinutes > 0) {
    lastEstimatedTime.low = lastEstimatedTime.low.add(
      10 * addMinutes,
      'minutes'
    );
    lastEstimatedTime.high = lastEstimatedTime.high.add(
      10 * addMinutes,
      'minutes'
    );
  }
  return lastEstimatedTime;
}
