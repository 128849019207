import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { COLORS } from 'src/theme/colors';
import { useTranslation } from 'react-multi-lang';
import { ORDER_SERVICES } from 'src/dto/enum';

const useStyles = makeStyles(() => ({
  cardContainer: {
    borderRadius: '5px',
    border: `1px solid ${COLORS.yellowLink}`,
    padding: '28px 10% 20px 10%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  containerOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px',
    marginTop: 15
  },
  input: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
const CardsSection = ({ restaurant, currentChargesHistory, title, charge }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const usePickup =
    restaurant.order_services.findIndex(e => e === ORDER_SERVICES.PICKUP) > -1;
  const useDelivery =
    restaurant.order_services.findIndex(e => e === ORDER_SERVICES.DELIVERY) >
    -1;
  const iva =
    currentChargesHistory && currentChargesHistory?.charges
      ? parseInt(currentChargesHistory?.charges?.iva, 10) > 0
      : false;
  return (
    <Grid container direction="row" spacing={4} justify="center">
      <Grid item xs={12} sm={4}>
        <Box className={classes.cardContainer}>
          <Typography className={classes.title}>
            <strong>{title}</strong>
          </Typography>
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Grid container item>
              <Grid item xs={6}>
                <FormControlLabel
                  disabled
                  checked={usePickup}
                  control={<Checkbox value={usePickup} color="primary" />}
                  label={translation('forms.commerce.pickup_charge')}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.input}>
                  <TextField
                    type="number"
                    label={translation('forms.commerce.pickup_charge')}
                    name="pickup"
                    value={charge?.pick_up * 1}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      step: 1.0
                    }}
                    disabled
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={6}>
                <FormControlLabel
                  disabled
                  checked={useDelivery}
                  control={<Checkbox color="primary" />}
                  label={translation('forms.commerce.delivery_charge')}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.input}>
                  <TextField
                    type="number"
                    label={translation('forms.commerce.pickup_charge')}
                    name="pickup"
                    value={charge?.delivery * 1}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      step: 1.0
                    }}
                    disabled
                  />
                </Box>
              </Grid>
            </Grid>
            {restaurant && restaurant?.xpress_motos && (
              <Grid container item>
                <Grid item xs={6}>
                  <FormControlLabel
                    checked={restaurant?.xpress_motos}
                    control={<Checkbox color="primary" />}
                    label={translation('forms.commerce.motos_charge')}
                    labelPlacement="end"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.input}>
                    <TextField
                      type="number"
                      label={translation('forms.commerce.motos_charge')}
                      name="xpress_motos"
                      value={charge?.xpress_motos || 0}
                      variant="outlined"
                      inputProps={{
                        min: 0,
                        step: 1.0
                      }}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {restaurant && !restaurant?.denied_merch && (
              <Grid container item>
                <Grid item xs={6}>
                  <FormControlLabel
                    checked={iva}
                    control={<Checkbox color="primary" />}
                    label={translation('forms.commerce.iva_charge')}
                    labelPlacement="end"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.input}>
                    <TextField
                      type="number"
                      label={translation('forms.commerce.iva_charge')}
                      name="delivery"
                      value={charge?.iva || 0}
                      variant="outlined"
                      inputProps={{
                        min: 0,
                        step: 1.0
                      }}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CardsSection;
