import React, { useState, Fragment, useMemo } from 'react';
import {
  Checkbox,
  Table,
  TableBody,
  TableHead,
  withStyles,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Plus as PlusIcon } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import useProductsPerOrder from '../../../../hooks/Order/useProductsPerOrder';
import BulkOperations from '../../OrderListView/BulkOperations';
import {
  getOrderTotal,
  getProductSimplePrice,
  roundTwoDecimals
} from '../../../../services/orderServices';
import { COLORS } from '../../../../constants';
import { useTableStyles } from '../styles';
import { PAYMENT_METHOD } from '../../../../dto/enum';

const TableCell = withStyles({
  root: {
    border: `1px solid ${COLORS.disabledGray}`
  }
})(MuiTableCell);

function ProductTable({
  order,
  canEdit = false,
  rejectProducts = () => {},
  rejectOrder = () => {}
}) {
  const classes = useTableStyles();
  const translations = useTranslation();
  const products = useProductsPerOrder(order.id);
  const { promotion } = order;
  const [selectedOrders, setSelectedOrders] = useState([]);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const isOnlinePayment = useMemo(
    () =>
      order.payment_method === PAYMENT_METHOD.ONLINE_CARD ||
      order.payment_method === PAYMENT_METHOD.ONLINE_QR,
    [order]
  );

  const selectAllProducts = event => {
    if (event.target.checked) {
      setSelectedOrders(products.filter(p => p.available).map(p => p.id));
    } else {
      setSelectedOrders([]);
    }
  };

  const handleSelectOneOrder = (event, orderId) => {
    if (!selectedOrders.includes(orderId)) {
      setSelectedOrders(prevSelected => [...prevSelected, orderId]);
    } else {
      setSelectedOrders(prevSelected =>
        prevSelected.filter(id => id !== orderId)
      );
    }
  };

  const getDiscountRow = () => {
    return (
      <TableRow key="discount_row" className={classes.discountRow}>
        <TableCell
          className={clsx(classes.responsivePadding)}
          colSpan={3}
          align="center"
        >
          <Typography
            className={classes.tableContentText}
            style={{ fontWeight: 'bold' }}
          >
            {`${translations('orders.details.order_table.discount')}:
            ${promotion.promo_code}`}
          </Typography>
        </TableCell>
        <TableCell className={clsx(classes.responsivePadding)} align="center">
          <Typography
            className={classes.tableContentText}
            style={{ fontWeight: 'bold' }}
          >
            {`Bs. -${roundTwoDecimals(order.discount).toFixed(2)}`}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const getTotalRows = () => {
    const subTotal = order.total
      ? getOrderTotal(products, order.simple_calculation || false).toFixed(2)
      : (order.total * 1).toFixed(2);
    const deliveryFree = order.subsidies.restaurantDeliveryFree;
    let total = getOrderTotal(
      products,
      order.simple_calculation || false
    ).toFixed(2);
    total -= order.discount || 0;
    total = total.toFixed(2);
    const paidByXpress = order?.wallet_restaurant_is_paid_by_xpress
      ? order.wallet_restaurant_is_paid_by_xpress
      : false;
    let usedTicketRestaurant = 0;
    if (!paidByXpress) {
      usedTicketRestaurant = order.ticket_wallet_restaurant_amount_use || 0;
      total -= usedTicketRestaurant;
    }
    const subsidiarySubsidy = order?.subsidies?.subsidiarySubsidies || 0;
    total -= subsidiarySubsidy;
    total -= deliveryFree;

    return (
      <Fragment key="summary">
        <TableRow>
          <TableCell className={clsx(classes.responsivePadding)} colSpan={2} />
          <TableCell className={clsx(classes.responsivePadding)} align="center">
            <Typography className={clsx(classes.tertiaryText)}>
              {translations('orders.details.order_table.sub_total')}
            </Typography>
          </TableCell>
          <TableCell className={clsx(classes.responsivePadding)} align="center">
            <Typography className={clsx(classes.tertiaryText)}>
              Bs. {subTotal}
            </Typography>
          </TableCell>
        </TableRow>
        {usedTicketRestaurant !== 0 && (
          <TableRow key="discount_row_rest" className={classes.discountRow}>
            <TableCell
              className={clsx(classes.responsivePadding)}
              colSpan={3}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                {`${translations('orders.details.order_table.ticket_amount')}:
                  ${order.restaurant_name}`}
                {order.subsidiary_name ? `(${order.subsidiary_name})` : ''}
              </Typography>
            </TableCell>

            <TableCell
              className={clsx(classes.responsivePadding)}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                {`Bs. -${(usedTicketRestaurant || 0).toFixed(2)}`}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {promotion && getDiscountRow()}
        {subsidiarySubsidy !== 0 && (
          <TableRow key="discount_row_subsidy" className={classes.discountRow}>
            <TableCell
              className={clsx(classes.responsivePadding)}
              colSpan={3}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                {translations('orders.details.order_table.subsidiary_subsidy')}
              </Typography>
            </TableCell>

            <TableCell
              className={clsx(classes.responsivePadding)}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                {`Bs. -${(subsidiarySubsidy || 0).toFixed(2)}`}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {deliveryFree > 0 && (
          <TableRow
            key="discount_delivery_free"
            className={classes.discountRow}
          >
            <TableCell
              className={clsx(classes.responsivePadding)}
              colSpan={3}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                Descuento de envio gratis
              </Typography>
            </TableCell>
            <TableCell
              className={clsx(classes.responsivePadding)}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                Bs. -{roundTwoDecimals(deliveryFree).toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {isOnlinePayment && (
          <TableRow key="online-payment" className={classes.discountRow}>
            <TableCell
              className={clsx(classes.responsivePadding)}
              colSpan={3}
              align="center"
            >
              <Typography
                className={clsx(classes.tableContentText)}
                style={{ fontWeight: 'bold' }}
              >
                {`Pago Online(${
                  order.payment_method === PAYMENT_METHOD.ONLINE_QR
                    ? 'QR'
                    : 'CARD'
                })`}
              </Typography>
            </TableCell>
            <TableCell
              className={clsx(classes.responsivePadding)}
              align="center"
            >
              <Typography
                className={classes.tableContentText}
                style={{ fontWeight: 'bold' }}
              >
                {` Bs. -${(total || 0).toFixed(2)}`}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell className={clsx(classes.responsivePadding)} colSpan={2} />
          <TableCell className={clsx(classes.responsivePadding)} align="center">
            <Typography className={clsx(classes.tertiaryText)}>
              {translations('orders.details.order_table.total')}
            </Typography>
          </TableCell>
          <TableCell className={clsx(classes.responsivePadding)} align="center">
            <Typography className={clsx(classes.tertiaryText)}>
              Bs.{' '}
              {roundTwoDecimals(isOnlinePayment ? 0 : total || 0).toFixed(2)}
            </Typography>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const getProducts = () => {
    const rows = [];
    if (products && products.length) {
      products
        .filter(p => p.available)
        .forEach(p => {
          const simplePrice = p.simple_price
            ? +p.simple_price
            : getProductSimplePrice(p);
          const price = simplePrice * (p.quantity ? +p.quantity : 1);
          const isOrderSelected = selectedOrders.includes(p.id);

          let extraRows = [];
          if (p && p.selected_options && p.selected_options.length) {
            extraRows = [...p.selected_options];
          }
          if (p.additional_requirement) {
            extraRows.push({
              is_additional: true,
              additional_requirement: p.additional_requirement
            });
          }

          const row = (
            <TableRow key={p.id}>
              <TableCell
                className={clsx(classes.responsivePadding)}
                rowSpan={extraRows.length + 1}
                align="center"
                style={{ verticalAlign: 'top' }}
              >
                <Typography className={classes.quantityText}>
                  {p.quantity}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.responsivePadding)}
                align="left"
              >
                <Typography className={classes.tableContentText}>
                  {p.product_name}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.responsivePadding)}
                align="center"
              >
                <Typography
                  className={clsx(classes.grayText, classes.numericText)}
                >
                  Bs. {roundTwoDecimals(simplePrice).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.responsivePadding)}
                align="center"
              >
                <Typography className={classes.numericText}>
                  <strong>
                    Bs.
                    {roundTwoDecimals(price).toFixed(2)}
                  </strong>
                </Typography>
              </TableCell>
              {canEdit && (
                <TableCell>
                  <Checkbox
                    checked={isOrderSelected}
                    onChange={event => handleSelectOneOrder(event, p.id)}
                    value={isOrderSelected}
                  />
                </TableCell>
              )}
            </TableRow>
          );

          rows.push(row);

          if (extraRows.length) {
            extraRows.forEach((er, i) => {
              let additionPrice;
              const simpleCalculation = order.simple_calculation;
              if (!er.is_additional) {
                additionPrice =
                  (+er.price || 0) * (simpleCalculation ? 1 : +p.quantity || 1);
              }
              rows.push(
                <TableRow key={Date.now() + p.id + i}>
                  <TableCell
                    className={clsx(classes.responsivePadding)}
                    colSpan={er.is_additional ? 3 : 1}
                  >
                    {er.is_additional ? (
                      <Typography className={classes.tableContentText}>
                        <strong>
                          {translations(
                            'orders.details.additional_reference.title'
                          )}
                        </strong>
                        <span>&nbsp;&nbsp;</span>
                        {er.additional_requirement}{' '}
                      </Typography>
                    ) : (
                      <Grid item container alignItems="center">
                        <PlusIcon
                          size={12}
                          strokeWidth={4}
                          className={classes.plusIcon}
                        />
                        <Typography className={classes.additionsText}>
                          {`${er.quantity ? `(${er.quantity})` : ''}`}
                          &nbsp;
                          {er.addition_item_name}
                        </Typography>
                      </Grid>
                    )}
                  </TableCell>
                  {!er.is_additional && (
                    <>
                      <TableCell
                        className={clsx(classes.responsivePadding)}
                        align="center"
                      >
                        {!er.is_additional && (
                          <Typography
                            className={clsx(
                              classes.grayText,
                              classes.numericText
                            )}
                          >
                            Bs. {roundTwoDecimals(er.price).toFixed(2)}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.responsivePadding)}
                        align="center"
                      >
                        {!er.is_additional && (
                          <Typography
                            className={classes.numericText}
                            style={{ fontWeight: 'bold' }}
                          >
                            Bs.{' '}
                            {(
                              roundTwoDecimals(additionPrice) *
                              (er.quantity || 1)
                            ).toFixed(2)}
                          </Typography>
                        )}
                      </TableCell>
                    </>
                  )}
                  {canEdit && !er.is_additional ? (
                    <TableCell
                      className={clsx(classes.responsivePadding)}
                      align="right"
                    >
                      <Checkbox
                        checked={isOrderSelected}
                        onChange={event => handleSelectOneOrder(event, p.id)}
                        value={isOrderSelected}
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            });
          }
        });
    }
    rows.push(getTotalRows());
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              className={clsx(classes.responsivePadding, classes.quantityWidth)}
            >
              <Typography className={classes.textHeader} align="center">
                {isXS
                  ? '#'
                  : translations('orders.details.order_table.header.amount')}
              </Typography>
            </TableCell>
            <TableCell className={clsx(classes.responsivePadding)}>
              <Typography className={classes.textHeader}>
                {translations('orders.details.order_table.header.product')}
              </Typography>
            </TableCell>
            <TableCell
              className={clsx(classes.responsivePadding, classes.unitWidth)}
              align="center"
            >
              <Typography className={classes.textHeader}>
                {isXS
                  ? translations(
                      'orders.details.order_table.header.xs_unit_price'
                    )
                  : translations(
                      'orders.details.order_table.header.unit_price'
                    )}
              </Typography>
            </TableCell>
            <TableCell
              className={clsx(classes.responsivePadding, classes.totalWidth)}
              align="center"
            >
              <Typography className={classes.textHeader}>
                {translations('orders.details.order_table.header.total_price')}
              </Typography>
            </TableCell>
            {canEdit && (
              <TableCell width={50}>
                <Checkbox onChange={event => selectAllProducts(event)} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };
  const enableBulkOperations = selectedOrders.length > 0;

  return (
    <>
      {getProducts()}
      {canEdit && (
        <BulkOperations
          onDelete={items => {
            if (items.length === products.length) {
              rejectOrder();
            } else {
              rejectProducts(items.map(i => products.find(p => i === p.id)));
            }
          }}
          open={enableBulkOperations}
          selected={selectedOrders}
        />
      )}
    </>
  );
}

export default ProductTable;
