import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { useSnackbar } from 'notistack';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAllActiveRestaurants } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { sendNotificationToTopics } from '../../../hooks/AdminHooks/notifications';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import { COLLECTIONS } from '../../../constants';
import { ArraySet } from '../../../utils/array';
import SendNotificationForm from '../../../components/Marketing/SendNotificationForm';

const USER_TOPIC_TYPE = {
  CLIENT: 'CLIENT',
  VISITOR: 'VISITOR'
};

function SendByCity() {
  const translate = useTranslation();
  const cities = useAllCities();
  const activeRestaurants = useAllActiveRestaurants();
  const { enqueueSnackbar } = useSnackbar();

  const getOptions = () => {
    if (cities) {
      return cities;
    }
    return [];
  };

  const getTopicToSend = (destination, userType) => {
    switch (userType) {
      case USER_TOPIC_TYPE.VISITOR:
        return `${destination}_visitor`;
      case USER_TOPIC_TYPE.CLIENT:
        return `${destination}_client`;
      default:
        return 'unknown';
    }
  };

  const getRestaurantsOnCity = async destination => {
    const subsidiariesRef = await firebase
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('city.id', '==', destination)
      .get();
    if (subsidiariesRef.empty) {
      return [];
    }
    const restaurantsIds = subsidiariesRef.docs.map(
      item => item.data().restaurant_id
    );
    const uniqueRestaurantsIds = ArraySet(restaurantsIds);
    return activeRestaurants.filter(restaurant =>
      uniqueRestaurantsIds.includes(restaurant.id)
    );
  };
  const sendNotification = async data => {
    const { title, body, destination, userType, image } = data;
    try {
      if (title && body) {
        const message = {
          title,
          body,
          image
        };
        if (destination === 'ALL') {
          const topics = [
            ...activeRestaurants.map(item => getTopicToSend(item.id, userType))
          ];
          await sendNotificationToTopics(topics, message);
        } else {
          const restaurantsOnCity = await getRestaurantsOnCity(destination);
          const topics = [
            ...restaurantsOnCity.map(item => getTopicToSend(item.id, userType))
          ];
          await sendNotificationToTopics(topics, message);
        }
        enqueueSnackbar(translate('marketing.notification_sended'), {
          variant: 'success'
        });
      }
    } catch (e) {
      enqueueSnackbar(translate('marketing.notification_failed'), {
        variant: 'error'
      });
    }
  };

  return (
    <SendNotificationForm options={getOptions()} onSubmit={sendNotification} />
  );
}

export default SendByCity;
