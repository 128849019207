import Base from './base';

export default class MonthlyMetric extends Base {
  constructor({
    id = '',
    subsidiary_id = '',
    restaurant_id = '',
    cooked_average = '',
    canceled_orders_month = 0,
    completed_orders_month = 0,
    average_time_accepted = 0,
    delivery_distance = 0,
    users_with_favorite = 0,
    orders_paid_with_card = 0,
    orders_paid_with_qr = 0,
    orders_paid_with_cash = 0,
    popular_products = [],
    negative_qualifications = 0,
    positive_qualifications = 0,
    qualifications_clients = 0,
    unique_clients = 0,
    frequent_clients = 0,
    month = '',
    year = 0,
    date = `${month} - ${year}`,
    categories = {}
  }) {
    super();
    this.id = id;
    this.cooked_average = cooked_average;
    this.subsidiary_id = subsidiary_id;
    this.restaurant_id = restaurant_id;
    this.canceled_orders_month = canceled_orders_month;
    this.completed_orders_month = completed_orders_month;
    this.average_time_accepted = average_time_accepted;
    this.delivery_distance = delivery_distance;
    this.users_with_favorite = users_with_favorite;
    this.orders_paid_with_card = orders_paid_with_card;
    this.orders_paid_with_qr = orders_paid_with_qr;
    this.orders_paid_with_cash = orders_paid_with_cash;
    this.popular_products = popular_products;
    this.negative_qualifications = negative_qualifications;
    this.positive_qualifications = positive_qualifications;
    this.qualifications_clients = qualifications_clients;
    this.unique_clients = unique_clients;
    this.frequent_clients = frequent_clients;
    this.month = month;
    this.year = year;
    this.date = date;
    this.categories = categories;
  }

  toPlainObject() {
    return { ...this };
  }
}
