import { useFirestoreConnect } from 'react-redux-firebase';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import Invoice from '../dto/invoice';
import PaymentHistory from '../dto/paymentHistory';

export const useRestaurantInvoice = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.INVOICE,
      where: [['restaurant_id', '==', restId]],
      storeAs: `current_restaurant_invoices_${restId}`,
      orderBy: ['created_at', 'asc']
    }
  ]);
  const currentRestaurantInvoices = useSelector(
    state => state.firestore.ordered[`current_restaurant_invoices_${restId}`]
  );

  return currentRestaurantInvoices;
};

export const usePaymentHistory = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PAYMENT_HISTORY,
      where: [['restaurant_id', '==', restId]],
      storeAs: `current_payment_history_${restId}`,
      limit: 10,
      orderBy: ['created_at', 'desc']
    }
  ]);
  const currentRestaurantInvoices = useSelector(
    state => state.firestore.ordered[`current_payment_history_${restId}`]
  );
  const currentRestaurantInvoicesOrderBy = _.orderBy(
    currentRestaurantInvoices,
    ['period.from', 'invoice_number'],
    ['desc', 'desc']
  );
  return currentRestaurantInvoicesOrderBy || [];
};

export const usePaymentHistoryNative = restId => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.PAYMENT_HISTORY)
        .where('restaurant_id', '==', restId)
        .where('enable', '==', true)
        .limit(10)
        .orderBy('created_at', 'desc')
        .onSnapshot(snap => {
          const data = snap.docs.map(doc =>
            new PaymentHistory({ ...doc.data(), id: doc.id }).toPlainObject()
          );
          setResult(data);
        });
    }
  }, [restId]);
  return result;
};

export const useRestaurantInvoiceWithoutStatusNative = restId => {
  const [invoices, setInvoices] = useState(null);
  useEffect(() => {
    if (restId) {
      return admin
        .firestore()
        .collection(COLLECTIONS.INVOICE)
        .where('restaurant_id', '==', restId)
        .orderBy('created_at', 'desc')
        .onSnapshot(snap => {
          if (snap.docs.length > 0) {
            const data = snap.docs.map(
              doc => new Invoice({ ...doc.data(), id: doc.id })
            );
            setInvoices(data);
          } else {
            setInvoices([]);
          }
        });
    }
  }, [restId]);
  return invoices;
};

export const useRestaurantPaymentWithoutStatusNative = restId => {
  const [payments, setPayments] = useState([]);
  let unsubscribe = () => {};
  useEffect(() => {
    if (restId) {
      // eslint-disable-next-line
      unsubscribe = admin
        .firestore()
        .collection(COLLECTIONS.PAYMENT_HISTORY)
        .where('enable', '==', true)
        .where('restaurant_id', '==', restId)
        .where('isRefund', '==', true)
        .orderBy('created_at', 'desc')
        .onSnapshot(snap => {
          if (snap.docs.length > 0) {
            const data = snap.docs.map(
              doc => new PaymentHistory({ ...doc.data(), id: doc.id })
            );
            setPayments(data);
          } else {
            setPayments([]);
          }
        });
    }
    return () => {
      unsubscribe();
    };
  }, [restId]);
  return payments;
};
