import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Avatar,
  Box,
  Card,
  Grid,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import env from '../../../env';

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    padding: 15,
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.expressColors.disabledGray}`,
    height: 80,
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    marginLeft: 20
  },
  avatar: {
    height: 42,
    width: 42
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  infoRow: {
    width: '100%',
    padding: '10px 24px',
    height: 50,
    display: 'flex',
    alignItems: 'center'
  },
  alertInfoRow: {
    backgroundColor: theme.palette.expressColors.cakeRed
  },

  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  driverInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px'
  },
  driverInfoText: {
    display: 'flex'
  },
  addressInfoContainer: {
    display: 'flex',
    margin: '10px',
    justifyContent: 'center'
  },
  cockpit: {
    display: 'flex',
    margin: '10px',
    justifyContent: 'center'
  },
  withWhatsApp: {
    justifyContent: 'space-between'
  }
}));

const DriverAssignmentCard = props => {
  const { avatarSrc, driverName, address, deliveryCharge, driverPhone } = props;
  const classes = useStyles();
  const translation = useTranslation();

  const sendWhatsApp = () => {
    window.open(
      `${env.REACT_APP_WHATSAPP}${driverPhone}?text=Gracias por utilizar XPRESS`,
      '_blank'
    );
  };

  return (
    <Box width="100%">
      <Card>
        <Grid className={classes.header}>
          <Avatar
            className={classes.avatar}
            alt="driver_avatar"
            src={avatarSrc || '/static/merchant/orders/defaultAvatar.png'}
          />
          <Typography variant="h4" className={classes.title}>
            {translation('orders_view.driver_card.title')}
          </Typography>
        </Grid>

        <Grid className={clsx(classes.infoContainer)}>
          <Box
            className={clsx(classes.infoRow, {
              [classes.alertInfoRow]: driverName === ' '
            })}
          >
            <Typography variant="h4">
              <strong>
                {translation('orders_view.driver_card.driver_name')}:
              </strong>
              <span>&nbsp;&nbsp;</span>
              {driverName !== ' '
                ? driverName
                : translation('orders_view.driver_card.default_name')}
            </Typography>
          </Box>
          <Box className={clsx(classes.infoRow)}>
            <Typography variant="h4">
              <strong>{translation('orders_view.driver_card.charge')}:</strong>
              <span>&nbsp;&nbsp;</span>
              {deliveryCharge || '-'}
            </Typography>
          </Box>
          <Box
            className={clsx(classes.infoRow, {
              [classes.withWhatsApp]: driverPhone
            })}
          >
            <Typography variant="h4">
              <strong>{translation('orders_view.driver_card.address')}:</strong>
              <span>&nbsp;&nbsp;</span>
              {address !== ' ' ? address : '-'}
            </Typography>
            {driverPhone && (
              <Button
                onClick={() => sendWhatsApp()}
                variant="contained"
                color="secondary"
                type="submit"
              >
                <WhatsAppIcon />
              </Button>
            )}
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};

export default DriverAssignmentCard;
