/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { COLORS } from '../../../constants';
import {
  useCurrentSubsidiary,
  useSubsidiariesMergedAndCurrentOrdered
} from '../../../hooks/useRestaurant';

const useStyles = makeStyles(theme => ({
  textTitle: {
    fontWeight: 'bold'
  },
  barSelected: {
    height: '2px',
    width: '100%'
  },
  containerList: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  containerBox: {
    width: '100%',
    height: '100%'
  },
  containerListItem: {
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    width: '250px'
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const SubsidiaryMerged = ({ onChange }) => {
  const classes = useStyles();
  const subsidiary = useCurrentSubsidiary();
  const dataSubsidiaries = useSubsidiariesMergedAndCurrentOrdered();
  const [selectedById, setSelectedById] = useState('');

  const handleSelect = option => {
    setSelectedById(option.subsidiaryId);
    onChange(option.subsidiaryId);
  };
  useEffect(() => {
    onChange(subsidiary.id);
    setSelectedById(subsidiary.id);
    // eslint-disable-next-line
  }, [subsidiary]);

  return (
    <Box className={classes.containerBox}>
      <List disablePadding className={classes.containerList}>
        {dataSubsidiaries.map(subsidiary => (
          <ListItem
            className={classes.containerListItem}
            style={
              selectedById === subsidiary.subsidiaryId
                ? { color: COLORS.ligthYellow }
                : null
            }
            key={subsidiary.subsidiaryId}
            onClick={() => handleSelect(subsidiary)}
          >
            <ListItemText className={classes.listItemText}>
              <Typography variant="h4" className={classes.textTitle}>
                {subsidiary.restaurant_name}
              </Typography>
              <Typography style={{ fontStyle: 'italic' }}>
                {subsidiary.subsidiary_name}
              </Typography>
            </ListItemText>
            <Divider
              className={classes.barSelected}
              style={
                selectedById === subsidiary.subsidiaryId
                  ? { backgroundColor: COLORS.ligthYellow }
                  : { backgroundColor: COLORS.white }
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SubsidiaryMerged;
