import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { useConversationStyles } from './chatStyles';
import Header from './Header';
import FooterChat from './FooterChat';
import ContentChat from './ContentChat';

const Conversation = ({ chat, isCollapsed, setIsCollapsed }) => {
  const classes = useConversationStyles();
  return (
    <Box className={clsx(classes.conversationContainer)}>
      <Header
        chat={chat}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      {!isCollapsed && (
        <Box className={clsx(classes.container)}>
          <ContentChat
            chatId={chat.id}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <FooterChat chatId={chat.id} />
        </Box>
      )}
    </Box>
  );
};

export default Conversation;
