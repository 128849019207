import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  Link,
  makeStyles,
  Button
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-multi-lang/lib';
import { useCurrentUser } from '../../hooks/useUser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 84,
    backgroundColor: theme.palette.secondary.main
  },
  logo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '130px'
    }
  },
  link: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    '& + &': {
      marginLeft: theme.spacing(2)
    },
    background:
      'transparent linear-gradient(90deg, #FECB13 0%, #FEAF10 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.5)',
    borderRadius: 28,
    opacity: 1,
    padding: '10px 24px',
    fontSize: 16,
    textTransform: 'none'
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonContainer: {
    marginLeft: 30
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();
  const translation = useTranslation();
  const currentUser = useCurrentUser();
  const history = useHistory();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/admin">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box flexGrow={1} />
        <Box className={classes.buttonContainer}>
          <Link
            className={classes.link}
            color="primary"
            component={Button}
            onClick={() => history.push('/app')}
            to="/app"
            underline="none"
            variant="contained"
          >
            {!currentUser && <>{translation('dashboard.login')}</>}
            {currentUser && <>{translation('dashboard.go_to')}</>}
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
