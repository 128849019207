import React, { useState } from 'react';
import { Button, ButtonBase } from '@material-ui/core';
import ModalTemplate from './ModalTemplate';
import ScheduleTemplate from '../../views/management/ScheduleManagementView/ScheduleTemplate';

const ModalSchedule = ({ value = [], onChange = () => {} }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div>
      <Button color="primary" size="large" onClick={() => setOpenModal(true)}>
        Horarios
      </Button>
      <ModalTemplate
        label="Seleccione un horario para la sucursal"
        show={openModal}
        onClose={() => setOpenModal(false)}
        component={ButtonBase}
      >
        <div>
          <div style={{ minWidth: 600 }}>
            <ScheduleTemplate value={value} onChange={onChange} />
          </div>
          <div
            style={{ padding: 16, width: '100%', justifyContent: 'flex-end' }}
          />
        </div>
      </ModalTemplate>
    </div>
  );
};

export default ModalSchedule;
