import moment from 'moment-timezone';
import * as firebase from 'firebase/app';

export function createMomentOnBolivianTime(date, format) {
  return moment.tz(date, format, 'America/La_Paz');
}

export function generateDayHours(step = 30) {
  let hours = [];
  const hoursInADay =
    Math.abs(
      moment()
        .startOf('day')
        .diff(moment().endOf('day'), 'hours')
    ) + 1;
  const realStep = 60 / step;
  for (let hour = 0; hour < hoursInADay; hour++) {
    const element = hour.toString();
    hours.push(`${element}:00`);
    for (let minuts = 1; minuts < realStep; minuts++) {
      hours.push(`${element}:${minuts * step}`);
    }
  }
  hours.push('23:59');

  hours = hours.map(hour =>
    createMomentOnBolivianTime(`2000 01 ${hour}`, 'YYYY MM H:mm')
  );
  return hours;
}
export function getDate(date = new Date()) {
  if (date.toDate) {
    return date.toDate();
  }
  return date;
}

export const getServerTime = async () => {
  // eslint-disable-next-line no-return-await
  return await firebase
    .firestore()
    .collection('servertime')
    .doc('time')
    .get()
    .then(snap => getDate(snap.data().timestamp));
};

export const getServerTimeMoment = async () => {
  const serverTime = await getServerTime();
  return moment(serverTime);
};

export function generateMonths(year = moment().year()) {
  const startingMoment = moment()
    .year(year)
    .startOf('year');

  const monthsInAYear = 12;
  const months = [];
  for (let monthIndex = 0; monthIndex < monthsInAYear; monthIndex++) {
    const month = moment(startingMoment)
      .add(monthIndex, 'month')
      .toDate();
    months.push(month);
  }

  return months;
}

export function generateYears({ min, max }) {
  const result = [];
  for (let actual = min; actual <= max; actual++) {
    result.push(actual);
  }
  return result;
}

// this method convert date to string date format(E.G. 23/10/2020 )
export const getFormatDate = date => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit'
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year }
  ] = dateTimeFormat.formatToParts(date);

  return `${day} / ${month} / ${year}`;
};
