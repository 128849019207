import firebase from '../config/firebaseConfig';

class FileService {
  // saveFile = async (promoBannerId, itemId, type, file) => {
  //   const storageRef = firebase.storage().ref();
  //   const imageRef = storageRef.child(
  //     `promo_banner/${promoBannerId}/${itemId}/${type}/${itemId}`
  //   );
  //
  //   return new Promise((resolve, reject) => {
  //     const uploadTask = imageRef.put(file);
  //     uploadTask.on(
  //       'state_changed',
  //       () => {},
  //       // eslint-disable-next-line no-shadow
  //       error => {
  //         reject(error);
  //       },
  //       async () => {
  //         const url = await uploadTask.snapshot.ref.getDownloadURL();
  //         resolve(url);
  //       }
  //     );
  //   });
  // };

  deleteFolderContents(path) {
    const ref = firebase.storage().ref(path);
    ref
      .listAll()
      .then(dir => {
        dir.items.forEach(fileRef => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach(folderRef => {
          this.deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log(error);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  deleteFile(pathToFile, fileName) {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  }
}
const fileService = new FileService();
export default fileService;
