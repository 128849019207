import React from 'react';
import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ path, className, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslation();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/admin/commerce"
            component={RouterLink}
          >
            {translate('marketing.commerce')}
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to={`/admin/commerce/${path}`}
            component={RouterLink}
          >
            {path}
          </Link>
        </Breadcrumbs>
      </Grid>
      <Typography variant="h3" color="textPrimary">
        {translate('marketing.title')}
      </Typography>
    </Grid>
  );
};

export default Header;
