import React from 'react';
import {
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import CustomerEditForm from '../../../../members/MemberEditView/CustomerEditForm';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  }
}));

const NewUser = ({
  handleConfirm,
  handleClose,
  restId,
  user,
  withPassword
}) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Grid>
      <Card className={classes.content}>
        <CardHeader
          className={classes.Header}
          title={translation('admin_workers.newUser')}
        />
        <Divider />
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Box my={3} width="100%">
              <Grid item container justify="center">
                <CustomerEditForm
                  user={user}
                  isModal
                  modalCancel={handleClose}
                  modalSave={handleConfirm}
                  restId={restId}
                  withPassword={withPassword}
                />
              </Grid>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NewUser;
