/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import {
  useCurrentSubsidiary,
  useRestaurant,
  useRestaurantAndSubsidiaryforMenu
} from 'src/hooks/useRestaurant';
import { useFirestore } from 'react-redux-firebase';
import { addMenuSection } from '../../services/menuSectionService';
import { addSharedMenuSection } from '../../services/sharedMenuSectionService';
import firebaseService from '../../services/firebaseService';
import menuSection from '../../dto/menuSection';
import { orderByAsc } from '../../utils/array';
import exportToCsv from '../../utils/csv';
import { TYPE_ACTION_LOGS, ACTION_LOGS, LOG_ACTIONS } from '../../dto/enum';
import { setLogsRestaurant } from '../../services/restaurantService';
import { logMenuSection } from '../../services/logServices';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(3)
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({
  section,
  className,
  sectionId,
  setNewSection,
  isEditable = true,
  blockCashier,
  ...rest
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  const { restId } = useParams();
  const { subId } = useParams();
  const [restaurant, subsidiary] = useRestaurantAndSubsidiaryforMenu(
    restId,
    subId
  );
  const isTemplate = useIsTemplateMenu();
  const currentSubsidiary = useSelector(
    state => state.account.currentSubsidiary || ''
  );
  const currentRestaurant = useSelector(state => state.account.restaurant);
  const firestore = useFirestore();
  const { SUPER_ADMIN } = useSelector(state => state.account.user.roles);
  const history = useHistory();
  const { user } = useSelector(state => state.account);

  const addLogsRestaurant = async () => {
    const data = {
      created_at: new Date(),
      type_action: TYPE_ACTION_LOGS.ADD,
      from: 'MERCHANT',
      action: ACTION_LOGS.SHARED_MENU,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      },
      restaurant_id: restaurant.id,
      restaurant_name: restaurant.name,
      subsidiary_id: '',
      subsidiary_name: ''
    };
    await setLogsRestaurant(data, restaurant.id);
  };

  const createSecction = async () => {
    const sectionId = firebaseService.randomId('menu_section');
    const newSection = new menuSection();
    newSection.id = sectionId;
    if (isTemplate) {
      await addSharedMenuSection({ ...newSection }, restaurant.id);
      const logData = {
        restaurant: {
          id: restaurant.id,
          name: restaurant.name
        }
      };
      logMenuSection(
        { before: null, after: { ...newSection }},
        LOG_ACTIONS.CREATE,
        logData
      );
    } else {
      await addMenuSection({ ...newSection }, subId);
    }
    setNewSection(sectionId);
    addLogsRestaurant();
  };

  const exportMenu = async () => {
    const menuSections = orderByAsc(restaurant.menu_section.filter(menu => menu.template), menu => menu.name);
    const productsRef = firestore.collection("product");
    const productsResult = await productsRef.where("restaurant_id", "==", restaurant.id).where("subsidiary_id", "==", "").where("enable", "==", true).get();
    const products = !productsResult.empty ? productsResult.docs.map(item => item.data()) : [];
    let dataToExport = [];
    menuSections.forEach(menuSection => {
      const menuSectionProducts = products.filter(product => product.menu_section_id === menuSection.id);
      menuSectionProducts.forEach(product => {
        dataToExport.push([menuSection.name, product.name, product.description.replace(/\n/g, " "), parseFloat(product.unit_price).toFixed(2), product.cooking_time, product.photo.x400, ""]);
      })
    })
    const nameCsv = isTemplate ? `${restaurant.name}` : `${restaurant.name}-${subsidiary.name}`
    const headers = [
      "Sección",
      "Producto",
      "Descripción",
      "Precio",
      "Tiempo de preparación",
      "Link de imagen",
      "Comentarios"
    ];
    exportToCsv(
      [headers, ...dataToExport],
      `${nameCsv}`
    );
  };
  const link = isTemplate
    ? `/app/management/shared_menu/restId/${restId}`
    : `/app/editor/menu-sections/restId/${restId}/subId/${subId}`;

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid container item justify="space-between" alignItems='center'>
        <Grid item>
          {!SUPER_ADMIN && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                variant="body1"
                color="inherit"
                to={link}
                component={RouterLink}
              >
                {translation('menu.menu_section.label')}
              </Link>
            </Breadcrumbs>
          )}
          {SUPER_ADMIN && (
            <Grid item container justify="flex-end">
              <Button
                style={{ cursor: 'pointer' }}
                component={RouterLink}
                onClick={() => history.goBack()}
              >
                {translation('back')}
              </Button>
            </Grid>
          )}
          <Grid>
            <Typography variant="h3" color="textPrimary">
              {translation('menu.menu_section.label')}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {translation('edit.header.advice')}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          {isTemplate ? (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''}`}
            </Typography>
          ) : (
            <Typography variant="h3" color="textPrimary">
              {`${restaurant.name || ''} - ${subsidiary.name || ''}`}
            </Typography>
          )}
        </Grid>
        <Grid>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            onClick={exportMenu}
            disabled={!isEditable}
            style={{ cursor: 'pointer' }}
          >
            {translation('edit.header.export_menu')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            onClick={createSecction}
            disabled={!isEditable}
            style={{ cursor: 'pointer' }}
          >
            {translation('edit.header.add_section')}
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {blockCashier && (
          <Alert severity="warning">
            {translation('information.block_Cashier')}
          </Alert>
        )
        }
      </Grid>
    </Grid>
  );
};

export default Header;
