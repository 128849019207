import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  Divider,
  Link,
  makeStyles,
  Popover,
  Typography
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-multi-lang/lib';
import LoginForm from './LoginForm';
import { useSettingsAppInfo } from '../../../hooks/useSettings';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80
  },
  backButton: {
    marginLeft: theme.spacing(2)
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4)
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  linkReset: {
    left: theme.spacing(3)
    // color: 'blue'
  },
  textDownload: {
    textTransform: 'none'
  }
}));

function LoginView() {
  const classes = useStyles();
  const translation = useTranslation();
  const settingsAppInfo = useSettingsAppInfo();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSubmitSuccess = () => {};

  const handleClickPopoverDownload = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopoverDownload = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Box mb={8} display="flex" alignItems="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Button component={RouterLink} to="/" className={classes.backButton}>
            {translation('back')}
          </Button>
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              {translation('login.title')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {translation('login.description')}
            </Typography>

            <Box mt={3}>
              <LoginForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={3}>
              <Link
                className={classes.linkReset}
                component={RouterLink}
                to="/reset-password"
                variant="body2"
                color="textSecondary"
              >
                {translation('login.reset_password')}
              </Link>
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Link
                component={RouterLink}
                to="/home"
                variant="body2"
                color="textSecondary"
              >
                {translation('login.sing_up')}
              </Link>
              {settingsAppInfo && settingsAppInfo?.merchant_desktop && (
                <>
                  <Button
                    className={classes.textDownload}
                    aria-describedby="popover-download"
                    size="small"
                    onClick={handleClickPopoverDownload}
                  >
                    {translation('login.download_merchant_desktop')}
                  </Button>
                  <Popover
                    id="popover-download"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopoverDownload}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  >
                    <Box p={3}>
                      <Box pb={2}>
                        <Link
                          href={
                            settingsAppInfo.merchant_desktop.download_url_x32
                          }
                          underline="hover"
                          color="inherit"
                          variant="body2"
                          rel="noreferrer"
                        >
                          Xpress Merchants 32 bits
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          href={
                            settingsAppInfo.merchant_desktop.download_url_x64
                          }
                          underline="hover"
                          color="inherit"
                          variant="body2"
                          rel="noreferrer"
                        >
                          Xpress Merchants 64 bits
                        </Link>
                      </Box>
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
          </CardContent>
          <CardMedia
            className={classes.media}
            image="/static/images/auth.jpg"
            title="Cover"
          />
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
