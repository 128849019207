import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { COLORS } from 'src/theme/colors';
import GroupBox from './groupBox';

const useStyles = makeStyles(() => ({
  chargeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    borderRadius: '5px',
    border: `1px solid ${COLORS.yellowLink}`,
    padding: '28px 27px 20px 18px'
  },
  selectWithLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '15px'
  },
  groupBox: {
    border: `2px solid ${COLORS.grayLight}`,
    boxSizing: 'border-box',
    borderRadius: '18px',
    padding: '40px',
    position: 'relative',
    display: 'flex',
    maxWidth: '420px',
    width: '100%',
    gap: '15px',
    flexWrap: 'wrap'
  },
  groupBoxLabel: {
    top: -15,
    left: 15,
    backgroundColor: 'white',
    position: 'absolute'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}));

const CardSectionSubscription = ({
  restaurant,
  title,
  subscriptionDetails
}) => {
  const classes = useStyles();
  const translation = useTranslation();
  return restaurant && !restaurant?.denied_merch ? (
    <Grid container direction="row" spacing={4} justify="center">
      <Grid item xs={12} sm={8}>
        <Box className={classes.chargeContainer}>
          <Typography className={classes.title}>
            <strong>{title}</strong>
          </Typography>
          <Box className={classes.selectWithLabelContainer}>
            <Typography>
              {translation('central_payments.details.cash_payment.period')}
            </Typography>
            <TextField
              id="period"
              disabled
              name="period"
              type="number"
              value={subscriptionDetails?.months}
              InputProps={{ inputProps: { min: 1 } }}
              label={translation(
                'central_payments.details.cash_payment.months'
              )}
              variant="outlined"
            />
          </Box>
          <Box className={classes.selectWithLabelContainer}>
            <GroupBox
              classes={classes}
              label={translation(
                'central_payments.details.cash_payment.xpressService'
              )}
            >
              <Box className={classes.selectWithLabelContainer}>
                <FormControlLabel
                  disabled
                  checked={subscriptionDetails?.xpress?.PICK_UP}
                  control={
                    <Checkbox
                      checked={subscriptionDetails?.xpress?.PICK_UP}
                      name="pickup"
                    />
                  }
                  label={translation(
                    'central_payments.details.cash_payment.pickUp'
                  )}
                />
                <FormControlLabel
                  disabled
                  checked={subscriptionDetails?.xpress?.DELIVERY}
                  control={
                    <Checkbox
                      checked={subscriptionDetails?.xpress?.DELIVERY}
                      name="delivery"
                    />
                  }
                  label={translation(
                    'central_payments.details.cash_payment.delivery'
                  )}
                />
              </Box>
              <Box className={classes.selectWithLabelContainer}>
                <Typography>
                  {translation('central_payments.details.cash_payment.price')}
                </Typography>
                <TextField
                  id="period"
                  disabled
                  name="period"
                  type="number"
                  label={translation(
                    'central_payments.details.cash_payment.currency'
                  )}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={subscriptionDetails?.xpress?.price * 1}
                />
              </Box>
            </GroupBox>
            <GroupBox
              classes={classes}
              label={translation(
                'central_payments.details.cash_payment.entregasService'
              )}
            >
              <FormControlLabel
                disabled
                checked={subscriptionDetails?.entregas?.ENTREGAS}
                control={
                  <Checkbox
                    checked={subscriptionDetails?.entregas?.ENTREGAS}
                    name="entregas"
                  />
                }
                label={translation(
                  'central_payments.details.cash_payment.entregas'
                )}
              />
              <Box className={classes.selectWithLabelContainer}>
                <Typography>
                  {translation('central_payments.details.cash_payment.price')}
                </Typography>
                <TextField
                  id="period"
                  disabled
                  name="period"
                  type="number"
                  label={translation(
                    'central_payments.details.cash_payment.currency'
                  )}
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  value={subscriptionDetails?.entregas?.price * 1}
                />
              </Box>
            </GroupBox>
          </Box>
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

export default CardSectionSubscription;
