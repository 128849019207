import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-multi-lang/lib';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  isCashier,
  isOwner,
  isSupervisor
} from '../../../utils/helpers/userHelper';
import {
  useCurrentSubsidiary,
  useSubsidiaries
} from '../../../hooks/useRestaurant';
import { UPDATE_SUBSIDIARY } from '../../../actions/accountActions';
import {
  getSubsidiariesIds,
  getSubsidiariesMerged
} from '../../../services/subsidiariesRestaurantsMergedService';

const useStyles = makeStyles(theme => ({
  subsidiary: {
    marginRight: '4px',
    borderRadius: 10
  },
  menu: {
    textTransform: 'initial',
    width: 170,
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    fontWeight: 'normal'
  },
  icon: {
    color: theme.palette.tertiary.main
  }
}));
const SubsidiarySelector = props => {
  const classes = useStyles();
  const translate = useTranslation();
  const currentUser = useSelector(state => state.account.user);
  const dispatch = useDispatch();
  const subsidiaries = useSubsidiaries();
  const currentSubsidiary = useCurrentSubsidiary();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubsidiaryChange = async newSubsidiary => {
    localStorage.setItem('defaultSubsidiary', newSubsidiary.id);
    const payload = { subsidiary: newSubsidiary.id };
    const subsWithRestsMerged = newSubsidiary.subsidiariesWithRestaurantsMerged;
    const subsidiariesIds = getSubsidiariesIds(subsWithRestsMerged);
    const subsidiariesMerged = await getSubsidiariesMerged(subsidiariesIds);
    payload.subsidiariesMerged = subsidiariesMerged;
    dispatch({
      type: UPDATE_SUBSIDIARY,
      payload
    });
    handleClose();
  };

  let userSubsidiaries = subsidiaries;
  if (isCashier(currentUser)) {
    userSubsidiaries = [currentSubsidiary];
  }
  if (isSupervisor(currentUser)) {
    userSubsidiaries = userSubsidiaries.filter(s =>
      currentUser.subsidiary_id.includes(s.id)
    );
  }
  if (isOwner(currentUser)) {
    userSubsidiaries = subsidiaries;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
    >
      <Typography variant="h6">
        {translate('menu.restaurantOptions.subsidiary')}
      </Typography>
      {userSubsidiaries.length === 1 ? (
        <Box className={classes.subsidiary}>
          <Typography variant="body2" color="textPrimary">
            {userSubsidiaries[0].name
              ? userSubsidiaries[0].name
              : userSubsidiaries[0].address}
          </Typography>
        </Box>
      ) : (
        <Box className={classes.subsidiary} boxShadow={3}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.menu}
            onClick={handleClick}
            endIcon={<ExpandMoreIcon className={classes.icon} />}
            variant="text"
          >
            {currentSubsidiary.name || currentSubsidiary.address}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userSubsidiaries.map(subsidiary => (
              <MenuItem
                key={subsidiary.id}
                onClick={() => handleSubsidiaryChange(subsidiary)}
                variant="h4"
                color="textPrimary"
              >
                {subsidiary.name ? subsidiary.name : subsidiary.address}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default SubsidiarySelector;
