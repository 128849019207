import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';
import DriverSelector from '../DriverSelector';
import ModalTemplateCustomHeader from '../../../../components/Modal/ModalTemplateCustomHeader';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 80
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    borderRadius: theme.spacing(1)
  },
  codeBox: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.expressColors.greenCake
  },
  hourBox: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.expressColors.yellowCake
  },
  codeText: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  hourText: {
    fontSize: 20,
    fontWeight: 'bold'
  }
}));

const DriverAssignmentModal = ({ show, onClose, order }) => {
  const translate = useTranslation();
  const classes = useStyles();
  const hour = moment(order.delivery_time.toDate());

  return (
    <ModalTemplateCustomHeader
      show={show}
      onClose={onClose}
      hideCloseIcon
      CustomHeader={() => (
        <Box className={classes.container}>
          <Grid item xs={6} md={6} xl={6}>
            <Box className={clsx(classes.box, classes.codeBox)}>
              <Typography variant="h4" className={classes.codeText}>
                {`${translate('orders_view.driver_modal.title')} ${
                  order.order_code
                }`}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} md={6} xl={6}>
            <Box className={clsx(classes.box, classes.hourBox)}>
              <Typography variant="h4" className={classes.hourText}>
                {`${translate('orders_view.driver_modal.hour')}: ${hour.format(
                  'HH:mm'
                )}`}
              </Typography>
            </Box>
          </Grid>
        </Box>
      )}
    >
      <DriverSelector onClose={onClose} order={order} />
    </ModalTemplateCustomHeader>
  );
};

export default DriverAssignmentModal;
