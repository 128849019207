import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS, registerStatus } from '../../../constants';
import { orderByAsc } from '../../../utils/array';

export const useAllRestaurants = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      storeAs: `all_restaurants`
    }
  ]);
  const allRestaurants = useSelector(
    state => state.firestore.ordered.all_restaurants
  );

  return allRestaurants;
};

export const useRestaurant = id => {
  let query = [];
  if (id) {
    query = [
      {
        collection: COLLECTIONS.RESTAURANT,
        doc: id,
        storeAs: id ? `current_restaurant` : `all_restaurants`
      }
    ];
  }
  useFirestoreConnect(query);
  const currentRestaurant = useSelector(
    state => state.firestore.data.current_restaurant
  );
  return currentRestaurant;
};

export const useRestaurantProducts = restId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.PRODUCT,
      where: [
        ['restaurant_id', '==', restId || ''],
        ['enable', '==', true]
      ],
      storeAs: 'restaurant_products'
    }
  ]);
  const restaurantProducts = useSelector(
    state => state.firestore.ordered.restaurant_products
  );
  if (!restaurantProducts) return [];
  return restaurantProducts.filter(item => !item.product_template_id);
};

export const useAllRestaurantsToSelect = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.RESTAURANT,
      orderBy: 'name',
      storeAs: `select_restaurants`
    }
  ]);
  const restaurants = useSelector(
    state => state.firestore.ordered.select_restaurants
  );
  if (!restaurants) return [];
  return restaurants.map(r => ({
    value: r.id,
    label: r.name,
    charge: r.charge
  }));
};

export function useAllActiveRestaurants() {
  const restaurants = useAllRestaurants();
  if (restaurants) {
    const result = restaurants.filter(
      item => item.register_status === registerStatus.ACTIVE
    );
    return orderByAsc(result, item => item.name);
  }
  return [];
}
