import { useLocation } from 'react-router';

const editableRoutes = ['app/management/shared_menu'];

export default function useCanEditablePage() {
  const location = useLocation();
  const currentPath = location.pathname;
  if (editableRoutes.length === 0) {
    return true;
  }
  const result = editableRoutes.map(route => currentPath.includes(route));
  return result.reduce((prevVal, currentVal) => prevVal || currentVal);
}
