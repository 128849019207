import { makeStyles } from '@material-ui/core';

export const useReportsStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  lastContainer: {
    marginLeft: theme.spacing(2)
  },
  totalText: {
    fontSize: 16
  },
  amountText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  },
  titleText: {
    fontSize: 18,
    fontWeight: 'bold'
  }
}));
