import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { Grid, Box, Button } from '@material-ui/core';
import XpressModal from '../../../../../components/Modal';
import { editSubsidiary } from '../../../../../services/subsidiaryService';
import { registerStatus, DOCUMENT_TYPE } from '../../../../../constants';
import InfoCard from './Info';
import RepresentantCard from './Representant';
import DocumentsTable from '../../DocumentsTable';
import Suspend from '../modals/SuspendModal';
import Archived from '../modals/ArchivedModal';

const InvoiceCard = props => {
  const { invoice } = props;
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const translation = useTranslation();

  const handleConfirm = (reason, status) => {
    setShowModal(false);
    if (status === registerStatus.SUSPENDED) {
      editSubsidiary(invoice.subsidiary_id, {
        suspention_reason: reason,
        register_status: status
      });
    } else
      editSubsidiary(invoice.subsidiary_id, {
        register_status: status
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const modalAction = status => {
    setShowModal(true);
    if (status === registerStatus.SUSPENDED) {
      setModal(
        <XpressModal>
          <Suspend handleConfirm={handleConfirm} handleClose={handleClose} />
        </XpressModal>
      );
    } else
      setModal(
        <XpressModal>
          <Archived
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            name={invoice.subsidiary_name}
          />
        </XpressModal>
      );
  };

  return (
    <>
      <Grid item container xs={12} md={12}>
        <Grid item container justify="flex-end">
          <Box mx={2}>
            <Button color="primary">
              {translation('admin_invoice.buttons.communicate')}
            </Button>
          </Box>
          <Box mx={2}>
            <Button
              color="primary"
              onClick={() => modalAction(registerStatus.SUSPENDED)}
            >
              {translation('admin_invoice.buttons.suspend')}
            </Button>
          </Box>
          <Box mx={2}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => modalAction(registerStatus.ARCHIVED)}
            >
              {translation('admin_invoice.buttons.archive')}
            </Button>
          </Box>
        </Grid>
        {showModal && modal}

        <Box width="100%" my={3}>
          <InfoCard invoice={invoice} />
        </Box>
        <Box width="100%" mb={3}>
          <RepresentantCard reprsentantId={invoice.representant_id} />
        </Box>
        <Box width="100%" mb={3}>
          <DocumentsTable id={invoice.id} type={DOCUMENT_TYPE.INVOICE} />
        </Box>
      </Grid>
    </>
  );
};

export default InvoiceCard;
