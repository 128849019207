import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, makeStyles, Grid, Box } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import _ from 'lodash';
import Page from '../../../../components/Page';
import Header from './Header';
import CreateForm from './CreateEditForm';
import AdditionsPicker from './additionsPicker';
import { useProductNative } from '../../../../hooks/Products/useProducts';
import { useRestaurant } from '../../../../hooks/AdminHooks/restaurant/useRestaurant';
import { blockCashierEdit } from '../../../../utils/helpers/userHelper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductCreateView = () => {
  const { prodId, sectionId } = useParams();
  const { restId } = useParams();
  const query = useQuery();
  const classes = useStyles();
  const translation = useTranslation();
  const currentProduct = useProductNative(prodId);
  const restaurant = useRestaurant(restId);
  const section = query.get('section');
  let menuSection = false;
  const { user } = useSelector(state => state.account);
  const storeCurrent = useSelector(state => state.account.restaurant);
  const { CASHIER, OWNER, SUPERVISOR } = useSelector(
    state => state.account.user.roles
  );

  const enableCashier = useMemo(() => {
    return OWNER || SUPERVISOR || (CASHIER && restaurant?.enable_menu_cashier);
  }, [CASHIER, restaurant, OWNER, SUPERVISOR]);

  if (!restaurant) {
    const findMenuSection = storeCurrent.menu_section.find(
      item => item.id === sectionId
    );
    menuSection = findMenuSection;
  } else {
    const findMenuSection = restaurant.menu_section.find(
      item => item.id === sectionId
    );
    menuSection = findMenuSection;
  }
  const blockCashier = blockCashierEdit(user, restaurant || storeCurrent);
  return (
    <Page
      className={classes.root}
      title={translation('edit.product_create.create_edit.product')}
    >
      <Container maxWidth="lg">
        {menuSection !== null && (
          <>
            <Header
              prodId={prodId}
              sectionName={menuSection?.name}
              sectionId={menuSection?.id}
              blockCashier={blockCashier}
            />
            {currentProduct && (
              <Grid container direction="row" justify="space-between">
                <Grid item xs={8} md={8}>
                  <Box mt={3} mr={3}>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <CreateForm
                          currentProduct={currentProduct}
                          blockCashier={blockCashier}
                          section={section}
                          enableMenuCashier={enableCashier}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Box mt={3}>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        {!_.isEmpty(restaurant) && (
                          <AdditionsPicker
                            product={currentProduct}
                            section={menuSection}
                            restIdFromState={restaurant.id}
                            blockCashier={blockCashier}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default ProductCreateView;
