import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTime } from 'src/reducers/deviceReducer';

export function useTimerProvider() {
  const dispatch = useDispatch();
  useEffect(() => {
    setInterval(() => {
      dispatch(setTime(new Date()));
    }, 1000);
    // eslint-disable-next-line
  }, []);
}
