import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { updateProfile } from 'src/actions/accountActions';
import { useTranslation } from 'react-multi-lang/lib';
import { useDispatch } from 'react-redux';
import WithLoading from '../../../../components/WithLoading';
import userService from '../../../../services/usersService';

const BoxLoading = WithLoading(Box);

const useStyles = makeStyles(() => ({
  root: {}
}));

function GeneralSettings({ user, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const translation = useTranslation();
  const [loading, setLoading] = useState(false);

  const saveUserValues = async userValues => {
    const data = { ...userValues };
    delete data.email;
    setLoading(true);
    await userService.saveUserDB(user.id, data);
    const userUpdated = await userService.getUserDB(user.id);
    await dispatch(updateProfile(userUpdated));
    setLoading(false);
  };

  return (
    <BoxLoading isLoading={loading}>
      <Formik
        enableReinitialize
        initialValues={{
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(translation('login.email.valid'))
            .max(255)
            .required(translation('login.email.require')),
          first_name: Yup.string()
            .max(255)
            .required(translation('profile.first_name.require')),
          last_name: Yup.string()
            .max(255)
            .required(
              translation('basic_information.personal_info.lastname.require')
            )
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await saveUserValues(values);
            setStatus({ success: true });
            enqueueSnackbar(translation('profile.update'), {
              variant: 'success'
            });
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={clsx(classes.root, className)} {...rest}>
              <CardHeader title={translation('account.profile')} />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      label={translation('profile.first_name.name')}
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      // type="first_name"
                      value={values.first_name}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      label={translation(
                        'basic_information.personal_info.lastname.lastname'
                      )}
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      // type="last_name"
                      value={values.last_name}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={
                        touched.email && errors.email
                          ? errors.email
                          : translation('profile.contact.email')
                      }
                      label={translation('profile.email_address')}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      type="email"
                      value={values.email}
                      variant="outlined"
                      // disabled
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.phone_number && errors.phone_number
                      )}
                      fullWidth
                      helperText={touched.phone_number && errors.phone_number}
                      label={translation(
                        'basic_information.personal_info.phone.phone'
                      )}
                      name="phone_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone_number}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </CardContent>
              {/* <Divider />
              <Box p={2} display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {translation('save_changes')}
                </Button>
              </Box> */}
            </Card>
          </form>
        )}
      </Formik>
    </BoxLoading>
  );
}

GeneralSettings.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default GeneralSettings;
