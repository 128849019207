import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';
import Restaurant from '../dto/restaurant';
import Subsidiary from '../dto/subsidiary';

export const getSubsidiariesIds = subsidiariesWithRestaurantsMerged => {
  const subIds = subsidiariesWithRestaurantsMerged.map(
    subRestMerged => subRestMerged.subsidiaryId
  );
  return subIds;
};

export const getRestaurantsIds = subsidiariesWithRestaurantsMerged => {
  const restIds = subsidiariesWithRestaurantsMerged.map(
    subRestMerged => subRestMerged.restaurantId
  );
  return restIds;
};

export const getRestaurantsMerged = async restIdsMerged => {
  if (restIdsMerged.length > 0) {
    const restaurantsMerged = await admin
      .firestore()
      .collection(COLLECTIONS.RESTAURANT)
      .where('id', 'in', restIdsMerged)
      .get();

    if (restaurantsMerged.empty) {
      return [];
    }
    const restaurants = restaurantsMerged.docs.map(doc => {
      const data = new Restaurant({ ...doc.data(), id: doc.id });
      return data;
    });
    return restaurants || [];
  }
  return [];
};

export const getSubsidiariesMerged = async subIdsMerged => {
  if (subIdsMerged.length > 0) {
    const subsidiariesMerged = await admin
      .firestore()
      .collection(COLLECTIONS.SUBSIDIARY)
      .where('id', 'in', subIdsMerged)
      .get();

    if (subsidiariesMerged.empty) {
      return [];
    }
    const subsidiaries = subsidiariesMerged.docs.map(doc => {
      const data = new Subsidiary({ ...doc.data(), id: doc.id });
      return data;
    });
    return subsidiaries || [];
  }
  return [];
};
