import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-multi-lang/lib';
import * as firebase from 'firebase/app';
import { ArrowBack } from '@material-ui/icons';
import { useMainCategories } from 'src/hooks/useCategories';
import { Autocomplete } from '@material-ui/lab';
import SubsidiaryRegister from '../../../components/Register/SubsidiaryRegister';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    width: '100%'
  },
  containerImage: {
    border: '1px solid red',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  containerTitle: {
    backgroundColor: 'transparent'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '30px'
  },
  card: {
    maxWidth: '865px',
    width: '100%',
    height: '1066px',
    position: 'relative',
    textAlign: '-webkit-center',
    display: 'flex',
    flexDirection: 'column'
  },
  background: {
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
    background: '#ffffff',
    height: '100%',
    width: '100%'
  },
  formPosition: {
    position: 'absolute',
    padding: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px'
  },
  containerSubsidiaries: {
    border: '1px solid red',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  subsidiariesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: 660
  },
  subsidiariesScroll: {
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      // maxHeight: '100vh',
      overflow: 'auto'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  basicInfoForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '500px',
    width: '100%',
    backgroundColor: 'transparent',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent
    }
  },
  arrayIcon: {
    zIndex: 4,
    cursor: 'pointer',
    position: 'absolute',
    color: '#56B19E',
    top: 45,
    left: 45
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

const RestaurantForm = React.memo(
  ({
    subsidiaryNumber,
    setSubsidiaryNumber,
    subsidiaries,
    setSubsidiary,
    setRestaurantData,
    handleNext,
    handleBack
  }) => {
    const classes = useStyles();
    const formRef = useRef();
    const mainCategories = useMainCategories();
    const translation = useTranslation();
    const [cities, setCities] = useState([]);

    const heightCard = useMemo(() => {
      if (subsidiaries && subsidiaries.length > 0) {
        return (
          520 + subsidiaries.length * (subsidiaries.length > 1 ? 525 : 505)
        );
      }
      return 1023;
    }, [subsidiaries]);

    const merchantCategories = useMemo(() => {
      if (mainCategories) {
        return mainCategories.filter(
          cat => !cat.is_food_court && !cat.is_system_action
        );
      }
      return [];
    }, [mainCategories]);

    useEffect(() => {
      const getCities = async () => {
        const citiesFirestore = await firebase
          .firestore()
          .collection('city')
          .get()
          .then(querySnapshot => {
            const citiesArray = [];
            querySnapshot.forEach(doc => {
              citiesArray.push({
                ...doc.data(),
                id: doc.id
              });
            });
            return citiesArray;
          });
        setCities(citiesFirestore);
      };
      getCities();
    }, []);

    const submitRestaurant = async () => {
      if (formRef.current) {
        await formRef.current.submitForm();
        const restaurantValid = formRef.current.isValid;
        let subsidiariesValid = true;
        for (let i = 0; i < subsidiaries.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await subsidiaries[i].formRef.current.submitForm();
          const valid = subsidiaries[i].formRef.current.isValid;
          if (!valid) {
            subsidiariesValid = false;
          }
        }
        if (restaurantValid && subsidiariesValid) {
          handleNext();
        }
      }
    };
    return (
      <div className={clsx(classes.root)}>
        <div className={clsx(classes.basicInfo)}>
          <Box
            className={classes.card}
            style={{
              height: heightCard
            }}
          >
            <Box className={classes.background} />
            <ArrowBack onClick={handleBack} className={classes.arrayIcon} />
            <Box className={classes.formPosition}>
              <Box className={clsx(classes.basicInfoForm)}>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={clsx(classes.title)}
                >
                  {translation('restaurant.box.title')}
                </Typography>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    name: '',
                    nit: '',
                    merchant_main_category_id: ''
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .max(255)
                      .required(
                        translation(
                          'basic_information.personal_info.name.require'
                        )
                      ),
                    nit: Yup.string(),
                    merchant_main_category_id: Yup.string()
                      .max(255)
                      .required('Categoria principal es requerida')
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setRestaurantData(values);
                    } catch (error) {
                      setStatus({ success: false });
                      setErrors({ submit: error.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={translation('register_restaurant.name')}
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="name"
                        value={values.name}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.nit && errors.nit)}
                        fullWidth
                        helperText={touched.nit && errors.nit}
                        label="NIT"
                        margin="normal"
                        name="nit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="nit"
                        value={values.nit}
                        variant="outlined"
                      />
                      <Autocomplete
                        id="combo-box-mainCategories"
                        options={merchantCategories}
                        getOptionLabel={cat => {
                          if (!cat) return;
                          return cat.name;
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.merchant_main_category_id &&
                                errors.merchant_main_category_id
                            )}
                            helperText={
                              touched.merchant_main_category_id &&
                              errors.merchant_main_category_id
                            }
                            fullWidth
                            variant="outlined"
                            label="Categoria Principal"
                            placeholder="Categoria Principal"
                          />
                        )}
                        value={merchantCategories.find(
                          s => values.merchant_main_category_id === s.id
                        )}
                        onChange={(event, value) => {
                          setFieldValue('merchant_main_category_id', value.id);
                        }}
                      />
                    </form>
                  )}
                </Formik>
                <TextField
                  label={translation('restaurant.box.subsidiary')}
                  fullWidth
                  margin="normal"
                  name="subsidiaryNumber"
                  onChange={e => {
                    setSubsidiaryNumber(+e.target.value);
                  }}
                  select
                  SelectProps={{ native: true }}
                  value={subsidiaryNumber}
                  variant="outlined"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box className={clsx(classes.subsidiariesList)}>
                {subsidiaries.map(s => (
                  <SubsidiaryRegister
                    key={s.id}
                    id={s.id}
                    formRef={s.formRef}
                    subsidiary={s.subsidiary}
                    setSubsidiary={setSubsidiary}
                    cities={cities}
                  />
                ))}
              </Box>
              <Box>
                <Box
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  ml={-1}
                >
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={submitRestaurant}
                  >
                    {translation('next')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
);

RestaurantForm.propTypes = {
  subsidiaryNumber: PropTypes.number,
  setSubsidiaryNumber: PropTypes.func,
  subsidiaries: PropTypes.array,
  handleNext: PropTypes.func,
  setRestaurantData: PropTypes.func,
  setSubsidiary: PropTypes.func
};

export default RestaurantForm;
