import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router';
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Card,
  Modal
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { GreenCrossButton } from './Inputs/Buttons';

const useStyle = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  subContainer: {
    width: 560,
    maxWidth: 560,
    alignSelf: 'center',
    borderRadius: 25,
    padding: 24
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginTop: '-24px'
  },
  textRestaurant: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  textSubsidiary: {
    fontSize: 16
  },
  textDescription: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '25px'
  },
  buttonWhite: {
    borderRadius: 0,
    border: '1px black solid',
    width: '205px',
    textTransform: 'Capitalize'
  },
  buttonYellow: {
    borderRadius: 0,
    width: '205px',
    textTransform: 'Capitalize'
  }
}));

const CheckDeliveryPrices = ({ userSubsidiaries, currentRestaurant }) => {
  const classes = useStyle();
  const translation = useTranslation();
  const history = useHistory();
  const { user } = useSelector(state => state.account);
  const subsidiariesResume = useMemo(() => {
    if (userSubsidiaries) {
      return userSubsidiaries.map(subsidiary => ({
        name: subsidiary.name,
        id: subsidiary.id,
        hasPriceChanges: subsidiary?.price_area?.hasPriceChanges,
        restaurant_name: subsidiary.restaurant_name,
        restaurantSubsidy: currentRestaurant[0]?.hasSubsidiarySubsidy,
        restaurantEnableDeliveryPrices:
          currentRestaurant[0]?.enable_delivery_prices
      }));
    }
    return [];
  }, [userSubsidiaries, currentRestaurant]);
  const [showModalDeliveryPrices, setShowModalDeliveryPrices] = useState(false);
  const [
    changesDeliverySubsidiaries,
    setChangesDeliverySubsidiaries
  ] = useState([]);
  useEffect(() => {
    if (subsidiariesResume.length > 0) {
      if (user.roles.OWNER || user.roles.SUPERVISOR) {
        const hasChanges = [];
        subsidiariesResume.forEach(sub => {
          if (sub.hasPriceChanges) {
            hasChanges.push(sub);
          }
        });
        if (
          hasChanges.length > 0 &&
          hasChanges[0].restaurantEnableDeliveryPrices &&
          hasChanges[0].restaurantSubsidy
        ) {
          setChangesDeliverySubsidiaries(hasChanges);
          setShowModalDeliveryPrices(true);
        } else {
          setShowModalDeliveryPrices(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(subsidiariesResume), user]);

  const onClose = () => {
    setShowModalDeliveryPrices(false);
  };

  const modifyDeliveryPrices = async () => {
    onClose();
    history.push('/app/subsidyDelivery');
  };

  return (
    <Modal open={showModalDeliveryPrices} disableBackdropClick>
      <Box className={classes.mainContainer}>
        <Card className={classes.subContainer}>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
            <GreenCrossButton size="small" onClick={onClose} />
          </Box>
          <Box className={classes.header}>
            <Typography className={classes.textRestaurant}>
              {translation('subsidy_delivery.check_delivery_prices.title')}
            </Typography>
            <Box mt={1}>
              {changesDeliverySubsidiaries.map(sub => (
                <Typography className={classes.textSubsidiary}>
                  {`${sub.restaurant_name} - ${sub.name}`}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box pl={4} pr={5} pb={2}>
            <Box my={5}>
              <Typography className={classes.textDescription}>
                {translation(
                  'subsidy_delivery.check_delivery_prices.description'
                )}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                className={classes.buttonWhite}
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className={classes.buttonYellow}
                onClick={() => modifyDeliveryPrices()}
              >
                Revisar Precios de Delivery
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default CheckDeliveryPrices;
