import moment from 'moment';

export class EstimatedTime {
  constructor({ low, high }) {
    this.low = moment(low).startOf('minutes');
    this.high = moment(high).startOf('minutes');
  }

  toString() {
    return `${this.low.format('HH:mm')} - ${this.high.format('HH:mm')}`;
  }
}
