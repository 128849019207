import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { ORDER_STATUS } from '../../dto/enum';
import Sounds from '../../assets/sounds/sounds';
import { useOrdersStatus } from '../../hooks/Order/useOrders';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const filterLastInProcesess = Order => {
  let lastOrder = Order?.filter(
    value => value.status === ORDER_STATUS.IN_PROCESS
  );
  lastOrder = lastOrder.sort((a, b) => b.created_at - a.created_at);
  return lastOrder[0] || [];
};
const alert = new Audio(Sounds.alert);
alert.loop = false;
function DocsLayout({ children }) {
  // call one listener for orderns
  const limit = 1;
  const getOrderDay = useOrdersStatus(limit);
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const snackbar = useSnackbar();
  let newOrder = [];
  if (getOrderDay) newOrder = filterLastInProcesess(getOrderDay);

  // end one call to firebase connect

  const [lastNewOrder, setLastNewOrder] = useState({ id: null });
  const allNewOrders = newOrder;
  useEffect(() => {
    const interval = setInterval(() => {
      if (allNewOrders && allNewOrders.length) {
        alert.play();
      }
    }, 120000);

    if (!_.isEmpty(newOrder) && newOrder.id !== lastNewOrder.id) {
      alert.play();
      setLastNewOrder(newOrder);
      snackbar.enqueueSnackbar(
        `Nuevo pedido para ${newOrder.subsidiary_name}.`,
        {
          variant: 'Success'
        }
      );
    }
    return () => clearInterval(interval);
  }, [newOrder, snackbar, lastNewOrder, allNewOrders]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DocsLayout.propTypes = {
  children: PropTypes.any
};

export default DocsLayout;
