/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-multi-lang/lib';
import { useSelector } from 'react-redux';
import NavItem from './NavItem';
import {
  isCustomerService,
  isMarketingUser
} from '../../../utils/helpers/userHelper';
import {
  customer_service,
  navItemsGeneral,
  maketingNavBar
} from './roleNavbar';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        open={Boolean(open)}
        href={item.href}
        key={item.href}
        title={item.title}
        caption={item.caption}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={item.href}
        title={item.title}
        icon={item.icon}
        info={item.info}
        caption={item.caption}
        id={item.id}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const translate = useTranslation();
  const { user } = useSelector(state => state.account);
  let navItems = [];

  const putTranslation = array => {
    const navbar = array.map(item => ({
      ...item,
      title: translate(item.title)
    }));
    navbar.forEach(currentItem => {
      const item = currentItem;
      if (!item.items) return;
      item.items = item.items.map(subItem => ({
        ...subItem,
        title: translate(subItem.title)
      }));
    });
    return navbar;
  };

  if (isCustomerService(user)) {
    navItems = putTranslation(customer_service);
  } else {
    navItems = putTranslation(navItemsGeneral);
  }
  if (isMarketingUser(user)) navItems = putTranslation(maketingNavBar);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>
        {renderNavItems({ items: navItems, pathname: location.pathname })}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
