import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Grid, Typography, TextField, SvgIcon, Box } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import useIsTemplateMenu from 'src/hooks/AdminHooks/restaurant/useIsTemplateMenu';
import { Save as SaveIcon } from 'react-feather';
import ListItem from '@material-ui/core/ListItem';
import { useRestaurantAndSubsidiaryforMenu } from 'src/hooks/useRestaurant';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { useMenuSectionSchedule } from '../../hooks/useMenuSectionSchedule';
import {
  removeMenuSection,
  editMenuSection,
  disableMenuProducts
} from '../../services/menuSectionService';
import {
  removeSharedMenuSection,
  editSharedMenuSection
} from '../../services/sharedMenuSectionService';
import XpressModal from '../../components/Modal';
import GreenEditButton, {
  GreenCrossButton
} from '../../components/Inputs/Buttons';
import XpressSwitch from '../../components/XpressSwitch';
import ModalEditMenuSection from './EditMenuSection/ModalEditMenuSection';
import { setLogsRestaurant } from '../../services/restaurantService';
import { TYPE_ACTION_LOGS, ACTION_LOGS, LOG_ACTIONS } from '../../dto/enum';
import { logMenuSection } from '../../services/logServices';
import { COLORS } from '../../theme/colors';
import TagSimpleTextOval from '../../components/TagSimpleTextOval';
import { WEEK_DAYS_SPANISH } from '../../constants';

const SectionCard = props => {
  const {
    position,
    section,
    isNew,
    setNoLongerNew,
    setShouldReload,
    menuList,
    isEditable,
    blockCashier,
    enableMenuCashier
  } = props;
  const history = useHistory();
  const { restId, subId } = useParams();
  const [restaurant, subsidiary] = useRestaurantAndSubsidiaryforMenu(
    restId,
    subId
  );
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isTemplate = useIsTemplateMenu();
  const menuSectionSchedule = useMenuSectionSchedule(
    restId,
    subId || '',
    isEditable
  );
  const prefix = isTemplate
    ? `/app/management/shared_menu/restId/${restId}`
    : `/app/editor/menu-sections/restId/${restId}/subId/${subId}`;

  const translation = useTranslation();
  const [name, setName] = useState(section.name);
  const [editing, setEditing] = useState(isNew);
  const [readOnly, setReadOnly] = useState(false);
  const [showModalEditing, setShowModalEditing] = useState(null);
  const [currentMenuSectionSchedule, setCurrentMenuSectionSchedule] = useState(
    null
  );
  const { user } = useSelector(state => state.account);

  const logData = {
    restaurant: {
      id: restaurant.id,
      name: restaurant.name
    },
    menu_section: {
      name,
      id: section.id
    }
  };

  const editSection = () => {
    if (section.template && !isTemplate) return;
    setEditing(!editing);
  };
  useEffect(() => {
    if (menuSectionSchedule) {
      const parseMenuSection = menuSectionSchedule.menu_section.map(
        menuSection => {
          const schedule = menuSection.schedule.map(daySchedule => {
            // eslint-disable-next-line no-shadow
            const schedule = daySchedule.schedule.map(itemSchedule => {
              if (itemSchedule.start_time && itemSchedule.end_time) {
                return {
                  start_time: itemSchedule.start_time.toDate(),
                  end_time: itemSchedule.end_time.toDate()
                };
              }
              return {};
            });
            return { ...daySchedule, schedule };
          });
          return {
            ...menuSection,
            start_schedule: menuSection.start_schedule.toDate(),
            end_schedule: menuSection.end_schedule.toDate(),
            schedule
          };
        }
      );
      setCurrentMenuSectionSchedule({
        ...menuSectionSchedule,
        menu_section: parseMenuSection
      });
    }
  }, [menuSectionSchedule]);

  const enableSection = isActive => {
    section.enable = isActive;
    if (isTemplate) {
      logMenuSection(
        { before: { enable: !isActive }, after: { enable: isActive } },
        LOG_ACTIONS.UPDATE,
        logData
      );
      editSharedMenuSection(
        { ...section, enable: isActive },
        restaurant.id,
        position
      );
    } else {
      editMenuSection(
        { ...section, enable: isActive },
        subsidiary.id,
        position
      );
    }
    setShouldReload(true);
  };

  const isProgrammedSection = useMemo(() => {
    if (section?.id) {
      const sectionCurrent = menuSectionSchedule?.menu_section.find(
        secMenu => secMenu.id === section.id
      );
      return sectionCurrent;
    }
    return null;
    // eslint-disable-next-line
  }, [menuSectionSchedule]);

  const dayProgrammedSection = useMemo(() => {
    let daysProgrammedSection = [];
    if (isProgrammedSection?.all_days === false) {
      if (isProgrammedSection?.schedule?.length > 0) {
        // eslint-disable-next-line
        const day = isProgrammedSection?.schedule.forEach(schedule => {
          if (schedule.open) {
            daysProgrammedSection.push(
              translation(
                `schedule.result.days.${schedule.journey.toLowerCase()}`
              )
            );
          }
        });
        if (daysProgrammedSection.length > 0) {
          daysProgrammedSection = daysProgrammedSection.sort((a, b) => {
            return WEEK_DAYS_SPANISH.indexOf(a) - WEEK_DAYS_SPANISH.indexOf(b);
          });
        }
      }
    }
    return daysProgrammedSection;
    // eslint-disable-next-line
  }, [isProgrammedSection?.all_days, section.enable, menuSectionSchedule]);

  const enableView = useMemo(() => {
    const currentDay = createMomentOnBolivianTime()
      .locale('en')
      .format('dddd');
    if (!section.enable) {
      return false;
    }
    if (isProgrammedSection?.all_days === false) {
      if (isProgrammedSection?.schedule.length > 0) {
        const currentSchedule = isProgrammedSection.schedule.find(
          value => value?.journey === currentDay?.toUpperCase()
        );
        if (currentSchedule && currentSchedule?.open) {
          const arrayFlag = [];
          currentSchedule.schedule.forEach(journey => {
            if (journey.start_time && journey.end_time) {
              const currentJourney = createMomentOnBolivianTime().format(
                'HH:mm'
              );
              if (
                currentJourney >=
                  createMomentOnBolivianTime(
                    journey.start_time.toDate()
                  ).format('HH:mm') &&
                currentJourney <=
                  createMomentOnBolivianTime(journey.end_time.toDate()).format(
                    'HH:mm'
                  )
              ) {
                arrayFlag.push(true);
              } else {
                arrayFlag.push(false);
              }
            }
          });
          const flag = arrayFlag.find(Boolean);
          if (flag) {
            setReadOnly(false);
            return true;
          }
          setReadOnly(true);
          return false;
        }
        setReadOnly(true);
        return false;
      }
      setReadOnly(true);
      return false;
    }
    setReadOnly(false);
    return true;
    // eslint-disable-next-line
  }, [isProgrammedSection?.all_days, section.enable, menuSectionSchedule]);

  const addLogsRestaurant = async typeAction => {
    const data = {
      created_at: new Date(),
      type_action: typeAction,
      from: 'MERCHANT',
      action: ACTION_LOGS.SHARED_MENU,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      },
      restaurant_id: restaurant.id,
      restaurant_name: restaurant.name,
      subsidiary_id: '',
      subsidiary_name: ''
    };
    await setLogsRestaurant(data, restaurant.id);
  };

  const saveToFS = async (enable = null) => {
    if (isTemplate) {
      await editSharedMenuSection(
        {
          ...section,
          name,
          enable: enable || enableView
        },
        restaurant.id,
        position
      );
      logMenuSection(
        {
          before: { name: section.name },
          after: { name }
        },
        LOG_ACTIONS.UPDATE,
        logData
      );
    } else {
      await editMenuSection(
        {
          ...section,
          name,
          enable: enable || enableView
        },
        subsidiary.id,
        position
      );
    }
    addLogsRestaurant(TYPE_ACTION_LOGS.EDIT);
    setEditing(false);
    setNoLongerNew();
    setShouldReload(true);
  };

  const saveName = () => {
    if (!enableView) {
      setShowModal(true);
      setModal(
        <XpressModal
          type="comfirmation"
          message={{
            header: translation('menu.sections.available_menu'),
            btnLeft: translation('notification.yes'),
            btnRight: 'No'
          }}
          handleConfirm={() => {
            saveToFS(true);
            setShowModal(false);
          }}
          handleClose={() => {
            saveToFS();
            setShowModal(false);
          }}
        />
      );
    } else saveToFS();
  };

  const handleKeyUp = event => {
    if (event.keyCode === 13) {
      saveName();
    }
  };

  const handleConfirm = () => {
    setShowModal(false);
    if (isTemplate) {
      removeSharedMenuSection(restaurant.id, position);
      const data = {
        restaurant: {
          id: restaurant.id,
          name: restaurant.name
        }
      };
      logMenuSection(
        { before: { ...logData.menu_section }, after: null },
        LOG_ACTIONS.DELETE,
        data
      );
      disableMenuProducts(section);
    } else {
      removeMenuSection(subsidiary.id, position);
      disableMenuProducts(section);
    }
    addLogsRestaurant(TYPE_ACTION_LOGS.DELETE);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const removeSection = () => {
    if (section.template && !isTemplate) {
      return;
    }
    setShowModal(true);
    setModal(
      <XpressModal
        type="deleteConfirmation"
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <ListItem
        style={{ paddingTop: 0, paddingBottom: 0 }}
        key={section.id}
        onClick={() => {
          if (!editing) {
            history.push({
              pathname: `${prefix}/${section.id}`,
              state: { sectionId: section.id }
            });
          }
        }}
      >
        {!editing && (
          <div>
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontSize: 10 }}>Sección</Typography>
              {isProgrammedSection?.all_days === false && (
                <TagSimpleTextOval
                  title="Sección Programada"
                  color={COLORS.yellowLink}
                  stylesText={{ fontSize: 10, marginRight: 2, marginLeft: 2 }}
                />
              )}
              {isProgrammedSection?.all_days === false &&
                dayProgrammedSection.length > 0 && (
                  <Box
                    style={{
                      backgroundColor: COLORS.green,
                      marginLeft: 6,
                      borderRadius: 12,
                      padding: '0px 5px',
                      display: 'flex'
                    }}
                  >
                    {dayProgrammedSection.map((day, index) => (
                      <Typography
                        style={{
                          fontSize: 10,
                          marginRight: 2,
                          marginLeft: 2,
                          color: 'white'
                        }}
                      >
                        {`${index !== 0 ? '-' : ''} ${day}`}
                      </Typography>
                    ))}
                  </Box>
                )}
            </div>
            <Typography varian="h4">
              {`${position + 1}. ${name || 'Nueva Sección'}`}
            </Typography>
          </div>
        )}
        {editing && (
          <Grid>
            <TextField
              style={{ marginRight: 20 }}
              id={`secction_${section.name}`}
              value={name}
              onChange={e => setName(e.target.value)}
              onKeyUp={handleKeyUp}
            />
          </Grid>
        )}
      </ListItem>
      {isEditable && (
        <Grid item container xs={3} md={3} justify="flex-end" wrap="nowrap">
          {!editing && (
            <GreenEditButton
              size="small"
              disabled={section.template && !isTemplate}
              onClick={() => editSection()}
            />
          )}
          {editing && (
            <SvgIcon
              style={{
                margin: '8px',
                cursor: 'pointer'
              }}
              fontSize="small"
              onClick={() => {
                saveName();
              }}
            >
              <SaveIcon />
            </SvgIcon>
          )}
          <GreenCrossButton
            size="small"
            disabled={section.template && !isTemplate}
            onClick={editing ? () => setEditing(false) : removeSection}
            style={{ marginRight: '32px' }}
          />
        </Grid>
      )}
      <div>
        <Typography
          style={{
            fontSize: 10,
            textAlign: 'center',
            position: 'relative',
            top: 6
          }}
        >
          {enableView
            ? translation(
                `edit.section_item.${blockCashier ? 'blockEnable' : 'disable'}`
              )
            : translation(
                `edit.section_item.${blockCashier ? 'blockDisable' : 'enable'}`
              )}
        </Typography>
        <XpressSwitch
          disabled={blockCashier || readOnly || !enableMenuCashier}
          value={enableView}
          onClick={e => enableSection(e.target.checked)}
        />
      </div>
      {showModal && modal}
      <ModalEditMenuSection
        show={showModalEditing}
        onClose={() => {
          setName(section.name);
          setShowModalEditing(false);
        }}
        closeModalSave={() => {
          setShowModalEditing(false);
        }}
        menuList={menuList}
        isEditable={isEditable}
        section={section}
        currentMenuSectionSchedule={currentMenuSectionSchedule}
        setName={setName}
        saveName={saveName}
        restId={restaurant.id}
      />
    </div>
  );
};

export default SectionCard;
