import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import env from '../../../env';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    backgroundImage: "url('/static/images/register/home.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom'
  },
  containerImage: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '35px 15px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  title: {
    backgroundImage: "url('/static/color-logo.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: 550,
    height: 175,
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  containerRegister: {
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  actionIcon: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    background: '#56B19E 0% 0% no-repeat padding-box',
    display: 'inline-block',
    marginRight: 16
  },
  containerTitle: {
    marginRight: '10%',
    marginTop: '5%'
  }
}));

const Register = React.memo(
  ({ className, terms, setTerms, openTerms, handleNext, ...rest }) => {
    const classes = useStyles();
    const translation = useTranslation();

    const openWhatsApp = () => {
      window.open(
        `${env.REACT_APP_WHATSAPP}${
          env.REACT_APP_XPRESS_SUPPORT
        }?text=${encodeURI(translation('home.whatsapp_message'))}`,
        '_blank'
      );
    };

    return (
      <div className={clsx(classes.root, className)} {...rest}>
        <div className={clsx(classes.containerImage)}>
          <Box p={2} className={clsx(classes.containerTitle)}>
            <Box className={clsx(classes.title)} />
            <Typography variant="h2" style={{ fontWeight: 'bold' }}>
              {translation('home.first_line_logo')}
            </Typography>
            <Typography variant="h2">
              {translation('home.second_line_logo')}
            </Typography>

            <div className={clsx(classes.containerRegister)}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box m={2}>
                  <Box alignItems="center" display="flex" mt={4} ml={3}>
                    <Button
                      startIcon={<WhatsAppIcon />}
                      color="secondary"
                      size="large"
                      variant="contained"
                      onClick={openWhatsApp}
                    >
                      {translation('home.whatsapp')}
                    </Button>
                    {/*
                    <Typography style={{ margin: '15px' }} variant="h2">
                      {translation('home.or')}
                    </Typography>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      onClick={handleNext}
                    >
                      {translation('home.form')}
                    </Button>
                    */}
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </div>
    );
  }
);

Register.propTypes = {
  className: PropTypes.string,
  openTerms: PropTypes.func,
  terms: PropTypes.bool,
  setTerms: PropTypes.func,
  handleNext: PropTypes.func
};

export default Register;
