import admin from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

export const updateMenuAdditions = async (menuAdditionId, data) => {
  // eslint-disable-next-line
  const prodRef = admin
    .firestore()
    .collection(COLLECTIONS.MENU_ADDITIONS)
    .doc(menuAdditionId)
    .update({ ...data });
};
