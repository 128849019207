import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  makeStyles,
  SvgIcon,
  Typography,
  Box
} from '@material-ui/core';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useAllRestaurants } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllSubsidiaries } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { registerStatus } from '../../../constants';

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  linkButton: {
    cursor: 'pointer'
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const Header = ({ path, isMerchant, className, headerTitle, ...rest }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { status } = match.params;
  const translation = useTranslation();
  const restaurants = useAllRestaurants();
  const subsidiaries = useAllSubsidiaries();
  let pendingAmount = 0;
  let activeAmount = 0;
  let rejectedAmount = 0;
  let suspendedAmount = 0;
  let archivedAmount = 0;

  if (subsidiaries) {
    subsidiaries.forEach(sub => {
      switch (sub.register_status) {
        case 'ACTIVE':
          activeAmount++;
          break;
        case 'SUSPENDED':
          suspendedAmount++;
          break;
        case 'ARCHIVED':
          archivedAmount++;
          break;
        case 'REJECTED':
          rejectedAmount++;
          break;
        default:
          break;
      }
    });
  }

  if (restaurants) {
    pendingAmount = restaurants.filter(
      item =>
        item.register_status === registerStatus.PENDING &&
        item.pendings &&
        (item.pendings.docs || item.pendings.subsidiaries)
    ).length;
  }

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      direction="column"
      justify="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {!isMerchant && (
            <Link
              variant="body1"
              color="inherit"
              to="/admin/commerce"
              component={RouterLink}
            >
              {translation('admin_commerces.breadcrumbs.commerces')}
            </Link>
          )}

          {path ? (
            path.map(item => (
              <Link
                key={Math.random()}
                variant="body1"
                color="inherit"
                to={item.link}
                component={RouterLink}
              >
                {item.label}
              </Link>
            ))
          ) : (
            <div />
          )}
        </Breadcrumbs>
      </Grid>
      {(headerTitle || !path) && (
        <Box mt={1}>
          <Grid item container style={{ flexWrap: 'nowrap' }}>
            {headerTitle && (
              <Typography variant="h3" color="textPrimary">
                {headerTitle}
              </Typography>
            )}
            {!path && (
              <Grid container item alignContent="center" justify="flex-end">
                <Grid>
                  <Button
                    component={RouterLink}
                    color="secondary"
                    variant="contained"
                    className={classes.action}
                    to="/admin/add_commerce"
                  >
                    <SvgIcon fontSize="small" className={classes.actionIcon}>
                      <PlusCircleIcon />
                    </SvgIcon>
                    {translation('admin_commerces.add_commerce')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {!isMerchant && path && (
        <Box width="100%" mt={2} mb={2}>
          <Grid item container style={{ flexWrap: 'nowrap' }}>
            <Grid item container justify="center">
              <Button
                className={classes.linkButton}
                component={RouterLink}
                variant={status === 'pending' ? 'contained' : 'outlined'}
                to="/admin/commerce/pending"
              >
                {translation('admin_commerces.pending')} ({pendingAmount})
              </Button>
            </Grid>
            <Grid item container justify="center">
              <Button
                className={classes.linkButton}
                component={RouterLink}
                variant={status === 'active' ? 'contained' : 'outlined'}
                to="/admin/commerce/active"
              >
                {translation('admin_commerces.active')} ({activeAmount})
              </Button>
            </Grid>
            <Grid item container justify="center">
              <Button
                className={classes.linkButton}
                component={RouterLink}
                variant={status === 'rejected' ? 'contained' : 'outlined'}
                to="/admin/commerce/rejected"
              >
                {translation('admin_commerces.rejected')} ({rejectedAmount})
              </Button>
            </Grid>
            <Grid item container justify="center">
              <Button
                className={classes.linkButton}
                component={RouterLink}
                variant={status === 'suspended' ? 'contained' : 'outlined'}
                to="/admin/commerce/suspended"
              >
                {translation('admin_commerces.suspended')} ({suspendedAmount})
              </Button>
            </Grid>
            <Grid item container justify="center">
              <Button
                className={classes.linkButton}
                component={RouterLink}
                variant={status === 'archived' ? 'contained' : 'outlined'}
                to="/admin/commerce/archived"
              >
                {translation('admin_commerces.archived')} ({archivedAmount})
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default Header;
