/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TableHead,
  TablePagination,
  TableFooter,
  Card,
  Box,
  Divider,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useRestaurant } from '../../../hooks/useRestaurant';
import { useSubsidiary } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { COLORS } from '../../../theme/colors';
import XpressModal from '../../../components/Modal';
import RowTable from './rowTable';
import getUserRoles from '../../../utils/helpers/userHelper';
import { USER_ROLES } from '../../../dto/enum';
import Switch from '../../../components/Inputs/Switch';
import { editSubsidiary } from '../../../services/subsidiaryService';

const useStyles = makeStyles(theme => ({
  TableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid',
    textAlign: 'left'
  },
  content: {
    padding: '16px 32px 24px',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '15%',
    outline: 'none'
  },
  Header: {
    textAlign: 'center'
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));

// let buttonDisabled = false;

const PriceForm = ({
  subsidiaryId,
  subsidiaryName,
  listSubsidiaries,
  setListSubsidiaries
}) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  const translation = useTranslation();
  const currentSubsidiary = useSubsidiary(subsidiaryId);
  const [isEnabledDelivery, setIsEnabledDelivery] = useState(false);
  const [isEnablePriceDelivery, setIsEnablePriceDelivery] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [arrayFlag, setArrayFlag] = useState([]);
  const { user } = useSelector(state => state.account);
  const current_restaurant = useRestaurant();
  const { enqueueSnackbar } = useSnackbar();
  const [rol] = getUserRoles(user);
  const [priceData, setPriceData] = useState([]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = async () => {
    try {
      await editSubsidiary(subsidiaryId, {
        price_area: {
          ...currentSubsidiary.price_area,
          price: priceData
        },
        enable_delivery_prices: isEnablePriceDelivery
      });
      handleClose();
      setButtonDisabled(true);
      enqueueSnackbar(translation('notification.correctly_save'), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(translation('account.unable_logout'), {
        variant: 'error'
      });
    }
  };

  const handleSaveEnablePrice = async valueChecked => {
    setIsEnablePriceDelivery(valueChecked);
    setButtonDisabled(!true);
  };

  useEffect(() => {
    setIsEnabledDelivery(current_restaurant.enable_delivery_prices);
  }, [current_restaurant]);

  useEffect(() => {
    if (currentSubsidiary) {
      if (currentSubsidiary?.price_area?.price) {
        setPriceData(currentSubsidiary.price_area.price);
        const newFlag = [];
        currentSubsidiary.price_area.price.map(() => newFlag.push(true));
        setArrayFlag(newFlag);
      } else {
        setIsEnabledDelivery(false);
      }
      if (currentSubsidiary?.enable_delivery_prices !== undefined) {
        setIsEnablePriceDelivery(currentSubsidiary?.enable_delivery_prices);
      }
    }
  }, [currentSubsidiary]);

  useEffect(() => {
    setButtonDisabled(true);
  }, []);

  const priceArray = ({ result, index, flagButton }) => {
    const newPriceData = [...priceData];
    newPriceData[index] = { ...priceData[index], subsidiarySubsidy: result };
    setPriceData(newPriceData);
    arrayFlag[index] = !flagButton;
    const isTrueArray = currentValue => currentValue;
    setButtonDisabled(!arrayFlag.every(isTrueArray));
  };

  const handleCorrectData = async () => {
    setListSubsidiaries(
      listSubsidiaries.filter(sub => sub.id !== subsidiaryId)
    );
    const data = {
      price_area: {
        ...currentSubsidiary.price_area,
        hasPriceChanges: false
      }
    };
    await editSubsidiary(subsidiaryId, data);
  };

  const handleSaveChanges = async () => {
    setShowModal(true);
    setModal(
      <XpressModal>
        <Grid>
          <Card className={classes.content}>
            <CardHeader
              className={classes.Header}
              title={
                <Typography style={{ fontWeight: 'bold' }}>
                  {`¿GUARDAR CAMBIOS PARA LA SUCURSAL ${subsidiaryName}?`}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleConfirm}
                >
                  Guardar
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </XpressModal>
    );
  };

  return (
    <Grid style={{ margin: '0px 20px 20px 80px' }}>
      <Card style={{ margin: '22px 65px 0px 20px' }}>
        <Box className={classes.containerBox}>
          <Typography variant="h5" color="textPrimary">
            {isEnablePriceDelivery ? 'Habilitado' : 'Deshabilitado'}
          </Typography>
          <Switch
            checked={isEnablePriceDelivery}
            onChange={() => handleSaveEnablePrice(!isEnablePriceDelivery)}
            disabled={!isEnabledDelivery}
          />
        </Box>
        <TableContainer>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell className={classes.TableCell}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translation('subsidy_delivery.subsidy_form.distance')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translation('subsidy_delivery.subsidy_form.price')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translation(
                      'subsidy_delivery.subsidy_form.subsidySubsidiary'
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((price, index) => {
                  // eslint-disable-next-line no-param-reassign
                  index += rowsPerPage * page;
                  return (
                    <RowTable
                      key={`${Math.random() * (10000 - 0) + 0}${index}${
                        price.price
                      }`}
                      price={price}
                      classes={classes}
                      index={index}
                      id={subsidiaryId}
                      priceArray={priceArray}
                      rol={rol}
                    />
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[6]}
                  colSpan={3}
                  count={currentSubsidiary?.price_area?.price.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Grid>
          {currentSubsidiary?.price_area?.hasPriceChanges && (
            <Button
              mt={2}
              variant="contained"
              style={{
                margin: '10px 65px 0px 0px',
                backgroundColor: '#fbdd39'
              }}
              onClick={handleCorrectData}
            >
              Los datos son correctos
            </Button>
          )}
          {rol === USER_ROLES.OWNER && (
            <Button
              mt={2}
              variant="contained"
              style={
                !buttonDisabled
                  ? {
                      margin: '10px 65px 0px 0px',
                      backgroundColor: '#fbdd39'
                    }
                  : {
                      margin: '10px 65px 0px 0px',
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                      color: 'rgba(0, 0, 0, 0.26)'
                    }
              }
              onClick={handleSaveChanges}
              disabled={buttonDisabled}
            >
              {translation('forms.save_changes')}
            </Button>
          )}
        </Grid>
      </Box>
      {showModal && modal}
    </Grid>
  );
};

export default PriceForm;
