/* global google */
import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-multi-lang/lib';
import env from '../../env';

function Map({ center, location, ...props }) {
  const markerIcon = {
    url: '/static/images/icons/locationPin.png',
    size: { width: 40, height: 40 },
    scaledSize: new google.maps.Size(40, 40)
  };
  const [marker, setMarker] = useState(null);
  const mapClick = e => {
    props.setPicker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });

    setMarker(e.latLng);
  };

  useEffect(() => {
    if (location && location.lat && location.lng) {
      setMarker({ lat: location.lat, lng: location.lng });
    }
    if (location === null) setMarker(null);
  }, [location]);

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={center}
      center={marker || center}
      onClick={mapClick}
      styles={[
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.medical',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]}
    >
      {marker && (
        <Marker
          position={marker}
          options={{
            icon: markerIcon
          }}
        />
      )}
    </GoogleMap>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

const useStyles = makeStyles(theme => ({
  mapResponsiveContainer: {
    height: 'calc(100vh - 200px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 150px)'
    }
  }
}));

const MapPicker = ({
  open = false,
  setShowMap = () => {},
  setPicker = () => {},
  center = {
    lat: -17.413977,
    lng: -68.119293
  },
  location
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const translation = useTranslation();

  return (
    <Dialog
      open={open}
      keepMounted
      fullScreen={fullScreen}
      fullWidth
      onClose={() => setShowMap(false)}
      maxWidth="xl"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {translation('restaurant.subsidiary.address.map')}
      </DialogTitle>
      <DialogContent>
        <div className={clsx(classes.mapResponsiveContainer)}>
          <MapWrapped
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            styles={[
              {
                featureType: 'poi',
                elementType: 'labels.text',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              },
              {
                featureType: 'poi.business',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              },
              {
                featureType: 'poi.medical',
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              },
              {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              },
              {
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off'
                  }
                ]
              }
            ]}
            center={center}
            setPicker={setPicker}
            location={location}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowMap(false)} color="primary">
          {translation('restaurant.subsidiary.address.save_address')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MapPicker.propTypes = {
  open: PropTypes.bool,
  setShowMap: PropTypes.func,
  center: PropTypes.object,
  setPicker: PropTypes.func
};

export default MapPicker;
