import React from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import {
  Box,
  Divider,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography
} from '@material-ui/core';
import { COLORS } from 'src/constants';
import { createMomentOnBolivianTime } from 'src/utils/date';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxHeight: 440,
    '&::-webkit-scrollbar': {
      width: 7
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: COLORS.success
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLORS.successLight,
      borderRadius: '10px'
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  divider: {
    height: '2px',
    backgroundColor: '#56B19E',
    width: '100%'
  },
  title: {
    fontSize: '23px',
    fontWeight: 'bold'
  },
  tableCell: {
    borderColor: '#56B19E',
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingBlock: '30px'
  },
  tableCellFirst: {
    backgroundColor: 'white',
    paddingBlock: '30px'
  },
  tableCellHeader: {
    borderBlockStart: 'none',
    borderBlockEnd: '1px solid #56B19E'
  },
  fontWeight: {
    fontWeight: 'bold'
  }
}));

const COLUMNS = [
  'Mes',
  '# de ordenes con pagos en linea',
  'Ventas con pagos en linea',
  'Comisión',
  'Comisión de pagos en linea',
  'Comisión IVA',
  'Total descuento',
  'Comisiones por servicios con pagos en linea'
];
const parseFloat = number => {
  return number === 0 ? 0 : Number.parseFloat(number).toFixed(2);
};

const HistorialTable = ({ invoices }) => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Historial</Typography>
      <Divider className={classes.divider} />
      <TableContainer className={classes.root}>
        <Table stickyHeader size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              {COLUMNS.map(col => (
                <TableCell
                  align="center"
                  className={clsx(
                    classes.tableCell,
                    classes.tableCellHeader,
                    classes.fontWeight
                  )}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {invoices?.length > 0 ? (
            <TableBody>
              {invoices.map((invoice, index) => {
                const { online } = invoice?.summary;
                return (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`${translation(
                        `month.${invoice?.month_created}`
                      )}/${createMomentOnBolivianTime(
                        invoice?.period?.from.toDate()
                      ).format('YYYY')}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {online?.total_order || 0}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(online?.total_sales || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(
                        online.commission_pickup +
                          online.commission_delivery +
                          online.commission_entregas
                      )}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(online.commission_online || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(online?.commission_iva || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(online?.total_discount || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.tableCellFirst : classes.tableCell
                      }
                    >
                      {`Bs. ${parseFloat(online?.total_comission || 0)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} />
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HistorialTable;
