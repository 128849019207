/**
 *
 * @type {EnvironmentConfig}
 */
const StagingEnv = {
  firebaseConfig: {
    apiKey: 'AIzaSyCrAhqcN-ESoIe2KFew3eX2m0Wt6xFa0zo',
    authDomain: 'xpress-stagging.firebaseapp.com',
    projectId: 'xpress-stagging',
    storageBucket: 'xpress-stagging.appspot.com',
    messagingSenderId: '119143959265',
    appId: '1:119143959265:web:2d06b3e0d62ef7aafec5a0',
    measurementId: 'G-F9NZQZZFG8'
  },
  REACT_APP_GOOGLE_MAP_API: 'AIzaSyBex46zHORVn63VFPsYrn4elJPhL3sxCWA',
  REACT_APP_CLOUD_FUNCTIONS_API:
    'https://us-central1-xpress-stagging.cloudfunctions.net',
  REACT_APP_SUPPORT_MAIL: 'info@xpressbolivia.com',
  REACT_APP_FCM_KEY:
    'BE3Pm1OWeG2z9Bp_xwGNVcWLS3OBJY2kJmKyjl-wEZK4R5E-cmA9jRgzW4tt9cCwJO7Ckl1ino7eZyMVZ4KymSc',
  REACT_APP_COMPANY_NAME: 'Xpress Technologies',
  REACT_APP_FIREBASE_API_KEY: 'AIzaSyCrAhqcN-ESoIe2KFew3eX2m0Wt6xFa0zo',
  REACT_APP_DYNAMIC_LINK_DOMAIN_URI: 'https://xpresssocial.page.link',
  REACT_APP_WHATSAPP: 'https://wa.me/',
  REACT_APP_XPRESS_MOTOS_URL: 'entregas-xpress-staging.web.app',
  REACT_APP_XPRESS_SUPPORT: '+59162093356',
  REACT_APP_GA_MEASUREMENT_ID: '',
  REACT_APP_XPRESS_BOLIVIA_COOKIES:
    'https://xpress-dev.web.app/cookies-declaration'
};

export default StagingEnv;
