/* eslint-disable import/prefer-default-export */
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../constants';

export const useMenuSectionSchedule = (restId, subId, isEditable) => {
  let query = [];
  if (isEditable) {
    query = [
      ['restaurant_id', '==', restId],
      ['subsidiary_id', '==', '']
    ];
  } else query = [['subsidiary_id', '==', subId]];

  useFirestoreConnect([
    {
      collection: COLLECTIONS.MENU_SECTION_SCHEDULE,
      where: query,
      storeAs: 'menu_section_schedule'
    }
  ]);

  const menuSectionSchedule = useSelector(
    state => state.firestore.ordered.menu_section_schedule
  );
  return menuSectionSchedule ? menuSectionSchedule[0] : null;
};
