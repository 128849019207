import Sw from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from '../../theme/colors';

const Switch = withStyles({
  switchBase: {
    color: COLORS.dangerDark,
    '&$checked': {
      color: COLORS.success
    },
    '&$checked + $track': {
      backgroundColor: COLORS.successLight
    }
  },
  checked: {},
  track: {
    backgroundColor: COLORS.danger
  }
})(Sw);

export const GreenSwitch = withStyles({
  switchBase: {
    color: COLORS.green,
    '&$checked': {
      color: COLORS.green
    },
    '&$checked + $track': {
      backgroundColor: COLORS.green
    }
  },
  checked: {},
  track: {}
})(Sw);

export const RedSwitch = withStyles({
  switchBase: {
    color: COLORS.red,
    '&$checked': {
      color: COLORS.red
    },
    '&$checked + $track': {
      backgroundColor: COLORS.red
    },
    '&:not($checked) + $track': {
      backgroundColor: COLORS.red
    }
  },
  checked: {},
  track: {}
})(Sw);

export default Switch;
