import SubscriptionDetails from './subscriptionDetails';
import admin from '../../config/firebaseConfig';
import TransitionOrderServices from './transitionOrderServices';
import CommissionCharge from './commissionCharges';
import { TYPE_CHARGE } from '../enum';

export default class ChargesHistory {
  constructor({
    id = '',
    createdAt = admin.firestore.FieldValue.serverTimestamp(),
    nextChargeId = '',
    prevChargeId = '',
    startsOn = admin.firestore.FieldValue.serverTimestamp(),
    restaurantId = '',
    charges = new CommissionCharge({}),
    chargeType = TYPE_CHARGE.COMMISSION,
    subscriptionDetails = new SubscriptionDetails({}),
    transitionOrderServices = null
  }) {
    this.id = id;
    this.createdAt = createdAt;
    this.nextChargeId = nextChargeId;
    this.prevChargeId = prevChargeId;
    this.startsOn = startsOn;
    this.restaurantId = restaurantId;
    this.charges = new CommissionCharge(charges);
    this.chargeType = chargeType;
    this.subscriptionDetails = new SubscriptionDetails(subscriptionDetails);
    this.transitionOrderServices = transitionOrderServices
      ? new TransitionOrderServices(transitionOrderServices)
      : null;
  }

  toPlainObject() {
    return {
      ...this,
      charges: this.charges.toPlainObject(),
      subscriptionDetails: this.subscriptionDetails
        ? this.subscriptionDetails.toPlainObject()
        : null,
      transitionOrderServices: this.transitionOrderServices
        ? this.transitionOrderServices.toPlainObject()
        : null
    };
  }

  toJson() {
    return {
      ...this,
      createdAt: this.createdAt.toDate(),
      startsOn: this.startsOn.toDate(),
      charges: this.charges.toPlainObject()
    };
  }

  cleanByChargeType() {
    if (this.chargeType === TYPE_CHARGE.SUBSCRIPTION) {
      this.charges = new CommissionCharge({});
    }
    if (this.chargeType === TYPE_CHARGE.COMMISSION) {
      this.subscriptionDetails = new SubscriptionDetails({});
    }
  }
}
