import React from 'react';
import { useParams } from 'react-router';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../../../components/Page';
import { COLLECTIONS } from '../../../../constants';
import Header from './Header';
import UserView from '../AdminUserEditView/UserView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));
// eslint-disable-next-line no-unused-vars
const UserDetailView = props => {
  const classes = useStyles();
  const params = useParams();
  useFirestoreConnect({
    collection: COLLECTIONS.USER,
    doc: params.userId
  });

  // eslint-disable-next-line consistent-return
  const userStore = useSelector(state => {
    if (
      state.firestore.data.users &&
      state.firestore.data.users[params.userId]
    ) {
      return state.firestore.data.users[params.userId];
    }
  });
  let user = {};
  if (params.userId) {
    if (!userStore) return null;
    user = {
      id: params.userId,
      ...userStore
    };
  }

  return (
    <Page className={classes.root} title="User Detail View">
      <Container maxWidth="lg">
        <Header user={user} />
        <Box mt={3}>
          <UserView user={user} />
        </Box>
      </Container>
    </Page>
  );
};

export default UserDetailView;
