/* eslint-disable import/prefer-default-export */
import admin from 'src/config/firebaseConfig';
import { useState, useEffect } from 'react';
import { COLLECTIONS } from '../constants';
import getDeviceLanguage from '../utils/languaje';

export const useRateQualificationOptions = () => {
  const [rateQualifOptions, setRateQualifOptions] = useState({
    liked: [],
    disliked: []
  });
  useEffect(() => {
    admin
      .firestore()
      .collection(COLLECTIONS.RATE_QUALIFICATON_OPTIONS)
      .where('platform', '==', 'MERCHANT')
      .where('target', '==', 'DRIVER')
      .where('enable', '==', true)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const language = getDeviceLanguage(true);
          const listRateQualOp = querySnapshot.docs
            .map(item => item.data())
            .map(rqo => {
              return {
                ...rqo,
                label:
                  rqo.labelTranslated && rqo.labelTranslated[language]
                    ? rqo.labelTranslated[language]
                    : rqo.label
              };
            });
          const data = {
            liked: listRateQualOp.filter(rqo => rqo.liked),
            disliked: listRateQualOp.filter(rqo => !rqo.liked)
          };
          setRateQualifOptions(data);
        }
      });
  }, []);
  return rateQualifOptions;
};
