import { useEffect, useState } from 'react';
import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from 'src/constants';
import AlertsServices from 'src/services/alertService';

export const useGetAlertsNative = user => {
  const [alertInformation, setAlertInformation] = useState([]);
  useEffect(() => {
    admin
      .firestore()
      .collection(COLLECTIONS.ALERTS)
      .doc('platform_alerts')
      .collection('merchant')
      .where('enable', '==', true)
      .onSnapshot(async snap => {
        if (!snap.empty) {
          const data = snap.docs.map(item => item.data());
          const alert = await AlertsServices.getAlertMerchant(data, user);
          setAlertInformation(alert);
        }
      });
  }, [user]);
  return alertInformation;
};
