import React from 'react';
import {
  Box,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { COLORS } from '../../../../theme/colors';
import { styles } from '../styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  divider: {
    height: '1px',
    backgroundColor: COLORS.dividerGray,
    width: '100%'
  },
  title: {
    fontSize: '23px',
    fontWeight: 'bold'
  },
  tableCell: {
    borderColor: COLORS.green,
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingBlock: '30px'
  },
  firstTableCell: {
    orderBlockStart: 'none',
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    paddingBlock: '30px'
  },
  tableCellHeader: {
    borderBlockStart: 'none',
    borderBlockEnd: `1px solid ${COLORS.successDark}`
  },
  fontWeight: {
    fontWeight: 'bold'
  }
}));

const COLUMNS = [
  'Mes',
  '# de ordenes con pagos en efectivo',
  'Ventas con pagos en efectivo',
  'Comisión',
  'Comisión IVA',
  'Total descuento',
  'Comisiones por servicios con pagos en efectivo'
];

const parseFloat = number => {
  return number === 0 ? 0 : Number.parseFloat(number).toFixed(2);
};

const HistorySection = ({ invoices }) => {
  const classes = useStyles();
  const mainStyle = styles();
  const translation = useTranslation();
  return (
    <Box className={classes.container}>
      <Divider className={classes.divider} />
      <TableContainer className={mainStyle.root}>
        <Table stickyHeader size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              {COLUMNS.map(col => (
                <TableCell
                  align="center"
                  className={clsx(
                    classes.tableCell,
                    classes.tableCellHeader,
                    classes.fontWeight
                  )}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {invoices?.length > 0 ? (
            <TableBody>
              {invoices.map((invoice, index) => {
                const { cash } = invoice?.summary;
                return (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`${translation(
                        `month.${invoice?.month_created}`
                      )}/${createMomentOnBolivianTime(
                        invoice?.period?.from.toDate()
                      ).format('YYYY')}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(cash?.total_order || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(cash?.total_sales || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(
                        cash.commission_pickup +
                          cash.commission_delivery +
                          cash.commission_entregas || 0
                      )}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(cash?.commission_iva || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(cash?.total_discount || 0)}`}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        index === 0 ? classes.firstTableCell : classes.tableCell
                      }
                    >
                      {`Bs.${parseFloat(cash?.total_comission || 0)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} />
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HistorySection;
