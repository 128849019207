/* eslint-disable no-restricted-globals */
/* eslint-disable operator-assignment */
import React, { useMemo, useState } from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import moment from 'moment';
import { getDate } from 'src/utils/date';
import { ORDER_STATUS } from 'src/dto/enum';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Header from './Header';
import OrdersRealTime from './OrdersRealTime';
import { useAppSummary } from '../../../hooks/useRestaurant';
import NetSales from './NetSales';
import AverageOrder from './AverageOrder';
import TotalCustomers from './TotalCustomers';
import NewCustomers from './NewCustomers';
import RepeatCustomers from './RepeatCustomers';
import { isOwner } from '../../../utils/helpers/userHelper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  }
}));

const timeRanges = [
  {
    value: 'today',
    text: 'Today'
  },
  {
    value: 'yesterday',
    text: 'Yesterday'
  },
  {
    value: 'last_7_days',
    text: 'Last 7 days'
  },
  {
    value: 'last_30_days',
    text: 'Last 30 days'
  },
  {
    value: 'last_year',
    text: 'Last year'
  }
];

function get30DayLabels() {
  const labels = [];
  const last30 = moment().subtract(30, 'days');
  for (let i = 0; i < 30; i++) {
    const date = last30.add(1, 'days').format('DD/MM/YY');
    if (i % 5 === 0) {
      labels.push(date);
    } else {
      labels.push(date);
    }
  }
  return labels;
}

function get7DayLabels() {
  const labels = [];
  const last7 = moment().subtract(7, 'days');
  for (let i = 0; i < 7; i++) {
    const date = last7.add(1, 'days').format('DD/MM/YY');
    if (i % 5 === 0) {
      labels.push(date);
    } else {
      labels.push(date);
    }
  }
  return labels;
}

function getDayLabels() {
  const labels = [];
  for (let i = 0; i < 24; i++) {
    labels.push(`${i}:00`);
  }
  return labels;
}

function getYearLabels() {
  const labels = [];
  for (let i = 0; i < 12; i++) {
    labels.push(
      moment()
        .month(i)
        .format('MMMM')
    );
  }
  return labels;
}

function StandardDashboard({ subsidiary }) {
  const classes = useStyles();
  const translation = useTranslation();
  const [timeRange, setTimeRange] = useState(timeRanges[2].text);
  const lastOrders = useSelector(state => state.firestore.ordered.order || []);
  const restaurant = useSelector(state => state.account.restaurant || '');
  const otherOrders = useSelector(
    state => state.firestore.ordered.summary || []
  );
  useAppSummary(restaurant.id, subsidiary.id || '');

  const orders = useMemo(() => {
    let ordersAux = lastOrders;
    if (otherOrders.length > 0) {
      otherOrders.forEach(element => {
        const limitDay = moment()
          .startOf('day')
          .subtract(1, 'days')
          .valueOf();
        if (
          element.end_day < limitDay &&
          element.summary[subsidiary.id] &&
          element.summary[subsidiary.id].orderComplete
        )
          ordersAux = ordersAux.concat(
            element.summary[subsidiary.id].orderComplete
          );
      });
    }
    return ordersAux;
  }, [lastOrders, otherOrders, subsidiary]);

  const { user } = useSelector(state => state.account);
  const history = useHistory();
  if (!isOwner(user)) {
    history.push('/app/orders');
  }

  function getDateRanges() {
    const today = moment();
    switch (timeRange) {
      case 'Last 30 days':
        return {
          start: moment(today)
            .subtract(30, 'days')
            .toDate(),
          compare: moment(today)
            .subtract(60, 'days')
            .toDate(),
          end: today.toDate()
        };
      case 'Last 7 days':
        return {
          start: moment(today)
            .subtract(7, 'days')
            .toDate(),
          compare: moment(today)
            .subtract(14, 'days')
            .toDate(),
          end: today.toDate()
        };
      case 'Today':
        return {
          start: moment(today)
            .subtract(1, 'days')
            .endOf('day')
            .toDate(),
          compare: moment(today)
            .subtract(2, 'days')
            .toDate(),
          end: today.toDate()
        };
      case 'Yesterday':
        return {
          start: moment(today)
            .subtract(2, 'days')
            .endOf('day')
            .toDate(),
          compare: moment(today)
            .subtract(3, 'days')
            .toDate(),
          end: moment(today)
            .subtract(1, 'days')
            .endOf('day')
            .toDate()
        };
      case 'Last year':
        return {
          start: moment(today)
            .subtract(1, 'year')
            .toDate(),
          compare: moment(today)
            .subtract(2, 'years')
            .toDate(),
          end: moment(today).toDate()
        };
      default:
        return {
          start: moment(today)
            .subtract(7, 'days')
            .toDate(),
          compare: moment(today)
            .subtract(14, 'days')
            .toDate(),
          end: today.toDate()
        };
    }
  }

  // eslint-disable-next-line consistent-return
  function getLabels() {
    switch (timeRange) {
      case 'Last 30 days':
        return get30DayLabels();
      case 'Last 7 days':
        return get7DayLabels();
      case 'Today':
        return getDayLabels();
      case 'Yesterday':
        return getDayLabels();
      case 'Last year':
        return getYearLabels();
      default:
        break;
    }
  }

  const filteredOrders = useMemo(() => {
    const dateRanges = getDateRanges();
    let currentOrders = [];
    currentOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(
        dateRanges.start,
        dateRanges.end
      )
    );
    const dataLabels = {};
    let values = [];
    switch (timeRange) {
      case 'Last 30 days':
        currentOrders.forEach(order => {
          const date = moment(getDate(order.created_at)).format('DD/MM/YY');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] = dataLabels[date] + 1;
          }
        });
        values = getLabels().map(value =>
          dataLabels[value] ? dataLabels[value] : 0
        );
        return values;
      case 'Last 7 days':
        currentOrders.forEach(order => {
          const date = moment(getDate(order.created_at)).format('DD/MM/YY');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] = dataLabels[date] + 1;
          }
        });
        values = getLabels().map(value =>
          dataLabels[value] ? dataLabels[value] : 0
        );
        return values;
      case 'Today':
        currentOrders.forEach(order => {
          const orderHour = moment(getDate(order.created_at)).hour();
          if (!dataLabels[orderHour]) {
            dataLabels[orderHour] = 1;
          } else {
            dataLabels[orderHour] = dataLabels[orderHour] + 1;
          }
        });
        return Object.values(dataLabels);
      case 'Yesterday':
        currentOrders.forEach(order => {
          const orderHour = moment(getDate(order.created_at)).hour();
          if (!dataLabels[orderHour]) {
            dataLabels[orderHour] = 1;
          } else {
            dataLabels[orderHour] = dataLabels[orderHour] + 1;
          }
        });
        return Object.values(dataLabels);
      case 'Last year':
        currentOrders.forEach(order => {
          const date = moment(getDate(order.created_at)).format('MMMM');
          if (!dataLabels[date]) {
            dataLabels[date] = 1;
          } else {
            dataLabels[date] = dataLabels[date] + 1;
          }
        });
        values = getLabels().map(value =>
          dataLabels[value] ? dataLabels[value] : 0
        );
        return values;
      default:
        return values;
    }
    // eslint-disable-next-line
  }, [timeRange, orders, subsidiary]);

  function getChange(newAmount, oldAmount) {
    const change = newAmount - oldAmount;
    if (oldAmount === 0 || isNaN(oldAmount)) {
      if (newAmount === 0 || isNaN(newAmount)) {
        return 0;
      }
      return 100;
    }
    if (isNaN(newAmount)) {
      return 0;
    }
    const percentageChange = change / (oldAmount * 100);
    return percentageChange;
  }

  const netSalesProps = useMemo(() => {
    const date = getDateRanges();
    const currentOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.start, date.end)
    );
    const latestOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.compare, date.start)
    );
    const value = currentOrders
      .filter(order => order.status === ORDER_STATUS.COMPLETED)
      .reduce(
        (map, next) =>
          next.total && !isNaN(next.total) ? map + next.total : map,
        0
      );
    const oldValue = latestOrders
      .filter(order => order.status === ORDER_STATUS.COMPLETED)
      .reduce(
        (map, next) =>
          next.total && !isNaN(next.total) ? map + next.total : map,
        0
      );
    const difference = getChange(value, oldValue);
    return { value, difference, currency: 'Bs' };
    // eslint-disable-next-line
  }, [orders, timeRange, subsidiary]);

  const averageOrderProps = useMemo(() => {
    const date = getDateRanges();
    const currentOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.start, date.end)
    );
    const latestOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.compare, date.start)
    );
    let value = (
      currentOrders
        .filter(order => order.status === ORDER_STATUS.COMPLETED)
        .reduce(
          (map, next) =>
            next.total && !isNaN(next.total) ? map + next.total : map,
          0
        ) / currentOrders.length
    ).toFixed(2);
    const oldValue =
      latestOrders
        .filter(order => order.status === ORDER_STATUS.COMPLETED)
        .reduce(
          (map, next) =>
            next.total && !isNaN(next.total) ? map + next.total : map,
          0
        ) / latestOrders.length;
    value = isNaN(value) ? 0 : value;
    const difference = getChange(value, isNaN(oldValue) ? 0 : oldValue);
    return { value, difference, currency: 'Bs' };
    // eslint-disable-next-line
  }, [orders, timeRange, subsidiary]);

  const totalCustomers = useMemo(() => {
    let currentOrders = [];
    currentOrders = orders?.filter(order => moment(getDate(order.created_at)));
    const currentCustomers = {};
    currentOrders.forEach(order => {
      if (currentCustomers[order.user_id]) {
        currentCustomers[order.user_id] = currentCustomers[order.user_id] + 1;
      } else {
        currentCustomers[order.user_id] = 1;
      }
    });
    const currentCustomersValue = Object.keys(currentCustomers).length;

    const difference = getChange(currentCustomersValue, 0);
    return { value: currentCustomersValue, difference };
    // eslint-disable-next-line
  }, [orders, timeRange, subsidiary]);

  const totalNewCustomers = useMemo(() => {
    const date = getDateRanges();
    let currentOrders = [];
    let latestOrders = '';
    currentOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.start, date.end)
    );
    latestOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.compare, date.start)
    );
    const currentCustomers = {};
    currentOrders.forEach(order => {
      if (currentCustomers[order.user_id]) {
        currentCustomers[order.user_id] = currentCustomers[order.user_id] + 1;
      } else {
        currentCustomers[order.user_id] = 1;
      }
    });
    const latestCustomers = {};
    latestOrders.forEach(order => {
      if (latestCustomers[order.user_id]) {
        latestCustomers[order.user_id] = latestCustomers[order.user_id] + 1;
      } else {
        latestCustomers[order.user_id] = 1;
      }
    });

    let newCustomers = 0;
    Object.keys(currentCustomers).forEach(customerId => {
      if (!latestCustomers[customerId]) {
        newCustomers++;
      }
    });
    const value = newCustomers;
    const oldValue = Object.values(latestCustomers).filter(item => item === 1)
      .length;
    const difference = getChange(value, oldValue);
    return { value, difference };
    // eslint-disable-next-line
  }, [orders, timeRange, subsidiary]);

  const totalRepeatCustomers = useMemo(() => {
    const date = getDateRanges();
    let currentOrders = [];
    let latestOrders = '';
    currentOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.start, date.end)
    );
    latestOrders = orders?.filter(order =>
      moment(getDate(order.created_at)).isBetween(date.compare, date.start)
    );
    const currentCustomers = {};
    currentOrders.forEach(order => {
      if (currentCustomers[order.user_id]) {
        currentCustomers[order.user_id] = currentCustomers[order.user_id] + 1;
      } else {
        currentCustomers[order.user_id] = 1;
      }
    });
    const latestCustomers = {};
    latestOrders.forEach(order => {
      if (latestCustomers[order.user_id]) {
        latestCustomers[order.user_id] = latestCustomers[order.user_id] + 1;
      } else {
        latestCustomers[order.user_id] = 1;
      }
    });
    let repeatCustomers = 0;
    const customers = currentCustomers
      ? Object.keys(currentCustomers).map(key => ({
          id: key,
          amount: currentCustomers[key]
        }))
      : [];
    customers.forEach(customer => {
      if (customer.amount > 1) repeatCustomers++;
    });
    const value = repeatCustomers;
    const difference =
      customers.length > 0 ? (repeatCustomers * 100) / customers.length : 0;
    return { value, difference };
    // eslint-disable-next-line
  }, [orders, timeRange, subsidiary]);

  function getLabelsTitle() {
    let label = '';
    switch (timeRange) {
      case 'Today':
        label = translation('dashboard.Today');
        break;
      case 'Yesterday':
        label = translation('dashboard.Yesterday');
        break;
      case 'Last 7 days':
        label = translation('dashboard.Last 7 days');
        break;
      case 'Last 30 days':
        label = translation('dashboard.Last 30 days');
        break;
      case 'Last year':
        label = translation('dashboard.Last year');
        break;
      default:
        break;
    }
    return label;
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header timerange={timeRange} setTimeRange={setTimeRange} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" color="textPrimary">
              {translation('dashboard.overview')}
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <OrdersRealTime
              {...getDateRanges()}
              labels={getLabels()}
              data={filteredOrders}
              orders={orders}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="textPrimary">
              {translation('dashboard.sales')}
              {' - '}
              {getLabelsTitle()}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <NetSales {...netSalesProps} />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <AverageOrder {...averageOrderProps} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="textPrimary">
              {translation('dashboard.customers')}
              {' - '}
              {getLabelsTitle()}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TotalCustomers {...totalCustomers} />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <NewCustomers {...totalNewCustomers} />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <RepeatCustomers {...totalRepeatCustomers} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default StandardDashboard;
