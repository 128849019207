import React from 'react';
import { Container, makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import Page from '../../../components/Page';
import Header from './Header';
import Commerces from './Commerces';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const Commerce = () => {
  const classes = useStyles();
  const translation = useTranslation();

  return (
    <Page className={classes.root} title={translation('admin_commerces.title')}>
      <Container maxWidth={false}>
        <Header headerTitle={translation('admin_commerces.title')} />
        <Divider style={{ marginTop: '10px' }} />
        <Commerces />
      </Container>
    </Page>
  );
};

export default Commerce;
