/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  makeStyles,
  Typography,
  FormControlLabel,
  CircularProgress,
  Box
} from '@material-ui/core';
import ScheduleTimePicker from 'src/components/ScheduleTimePicker';
import { updateSchedule, createPeriod } from 'src/actions/scheduleActions';
import { createMomentOnBolivianTime } from 'src/utils/date';
import Schedule from 'src/dto/schedule';
import { DEFAULT_END_HOUR_DATE, DEFAULT_START_HOUR_DATE } from 'src/constants';
import { useTranslation } from 'react-multi-lang/lib';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '../../../components/Inputs/Switch';
import { COLORS } from '../../../theme/colors';
import { setLogsRestaurant } from '../../../services/restaurantService';
import { TYPE_ACTION_LOGS, ACTION_LOGS } from '../../../dto/enum';
import admin from '../../../config/firebaseConfig';
import { useSubsidiaryByIdAsyncNative } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '100%'
  },
  root: {},
  queryField: {
    width: 500
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  list: { width: '100%', padding: 0 },
  item: { display: 'flex', paddingLeft: 16 },
  week: {
    maxWidth: 120,
    minWidth: 80,
    paddingLeft: 10,
    fontWeight: 900,
    fontSize: 20
  },
  flex: { flex: 1 },
  center: { display: 'flex', justifyContent: 'center' },
  tableCell: {
    borderColor: COLORS.defaultPage,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  switch: {
    width: '100%',
    justifyContent: 'center'
  }
}));
const DEFAULT_NEW_DAY = journey =>
  new Schedule({
    schedule: [
      {
        start_time: admin.firestore.Timestamp.fromDate(
          DEFAULT_START_HOUR_DATE.toDate()
        ),
        end_time: admin.firestore.Timestamp.fromDate(
          DEFAULT_START_HOUR_DATE.toDate()
        )
      },
      {}
    ],
    open: true,
    journey: journey.toUpperCase(),
    enable: true
  });

function Results({ subsidiaryId }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const subsidiary = useSubsidiaryByIdAsyncNative(subsidiaryId);

  const [schedule, setSchedule] = useState(null);

  useEffect(() => {
    if (subsidiary?.schedule) {
      setSchedule(subsidiary.schedule || []);
    } else {
      setSchedule(null);
    }
    // eslint-disable-next-line
  }, [subsidiary?.schedule]);

  const addLogsRestaurant = async typeAction => {
    const data = {
      created_at: new Date(),
      type_action: typeAction,
      from: 'MERCHANT',
      action: ACTION_LOGS.SCHEDULE_SUBSIDIARY,
      user: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      },
      restaurant_id: subsidiary.restaurant_id,
      restaurant_name: subsidiary.restaurant_name,
      subsidiary_id: subsidiary.id,
      subsidiary_name: subsidiary.name
    };
    await setLogsRestaurant(data, subsidiary.restaurant_id);
  };

  const onChangeSchedule = (day, index, field) => ev => {
    const { value } = ev.target;

    const dayScheduleIndex = schedule.findIndex(
      item => item.journey === day.toUpperCase()
    );

    const newSchedule = [...schedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      ...newSchedule[dayScheduleIndex].schedule[index],
      [`${field}_time`]: admin.firestore.Timestamp.fromDate(
        createMomentOnBolivianTime(`2000 01 ${value}`, 'YYYY MM H:mm').toDate()
      )
    };
    setSchedule(newSchedule);
    updateSchedule(subsidiary.id, {
      schedule: newSchedule
    });
    addLogsRestaurant(TYPE_ACTION_LOGS.EDIT);
  };

  const handleOpenChange = (day, isOpen) => () => {
    const dayExists = !!schedule.find(
      item => item.journey === day.value.toUpperCase()
    );
    if (dayExists) {
      const readableSubsidiary = { ...subsidiary };

      const dayScheduleIndex = schedule.findIndex(
        item => item.journey === day.value.toUpperCase()
      );

      const newSchedule = [...schedule];
      newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
      newSchedule[dayScheduleIndex].open = !newSchedule[dayScheduleIndex].open;
      setSchedule(newSchedule);
      updateSchedule(readableSubsidiary.id, {
        schedule: newSchedule
      });
    } else {
      setSchedule([...schedule, { ...DEFAULT_NEW_DAY(day.value) }]);
      createPeriod(subsidiary.id, {
        ...subsidiary,
        schedule: [...schedule, { ...DEFAULT_NEW_DAY(day.value) }]
      });
    }
    addLogsRestaurant(isOpen ? TYPE_ACTION_LOGS.OPEN : TYPE_ACTION_LOGS.CLOSE);
  };

  function onTimeFrameAdd(day, index) {
    const dayScheduleIndex = schedule.findIndex(item => item.journey === day);

    const newSchedule = [...schedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {
      start_time: admin.firestore.Timestamp.fromDate(
        DEFAULT_START_HOUR_DATE.toDate()
      ),
      end_time: admin.firestore.Timestamp.fromDate(
        DEFAULT_END_HOUR_DATE.toDate()
      )
    };
    setSchedule(newSchedule);
    updateSchedule(subsidiary.id, {
      schedule: newSchedule
    });
    addLogsRestaurant(TYPE_ACTION_LOGS.ADD);
  }

  function onTimeFrameRemove(day, index) {
    const dayScheduleIndex = schedule.findIndex(item => item.journey === day);

    const newSchedule = [...schedule];
    newSchedule[dayScheduleIndex] = { ...newSchedule[dayScheduleIndex] };
    newSchedule[dayScheduleIndex].schedule = [
      ...newSchedule[dayScheduleIndex].schedule
    ];
    newSchedule[dayScheduleIndex].schedule[index] = {};
    setSchedule(newSchedule);
    updateSchedule(subsidiary.id, {
      schedule: newSchedule
    });
    addLogsRestaurant(TYPE_ACTION_LOGS.DELETE);
  }

  const translation = useTranslation();

  const WEEK_DAYS = [
    { value: 'monday', day: translation('schedule.result.days.monday') },
    { value: 'tuesday', day: translation('schedule.result.days.tuesday') },
    { value: 'wednesday', day: translation('schedule.result.days.wednesday') },
    { value: 'thursday', day: translation('schedule.result.days.thursday') },
    { value: 'friday', day: translation('schedule.result.days.friday') },
    { value: 'saturday', day: translation('schedule.result.days.saturday') },
    { value: 'sunday', day: translation('schedule.result.days.sunday') }
  ];

  const DISABLED_REGISTER_STATUS = ['ARCHIVED', 'SUSPENDED', 'REJECTED'];

  if (!schedule)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div className={clsx(classes.root)}>
      <PerfectScrollbar options={{ suppressScrollY: true }}>
        <div style={{ borderRadius: 16, padding: 0, backgroundColor: 'white' }}>
          <Table className={classes.table}>
            <TableBody>
              {WEEK_DAYS.map((day, i) => {
                const daySchedule =
                  schedule.find(
                    item => item.journey === day.value.toUpperCase()
                  ) || '';
                return (
                  <TableRow key={i}>
                    <TableCell className={classes.tableCell}>
                      <Typography
                        className={classes.week}
                        variant="h5"
                        color="textPrimary"
                      >
                        {`${day.day}:`}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Switch
                            checked={daySchedule.open}
                            onChange={handleOpenChange(day, !!daySchedule.open)}
                            color="primary"
                            disabled={DISABLED_REGISTER_STATUS.includes(
                              subsidiary.register_status
                            )}
                          />
                        }
                        label={
                          daySchedule.open
                            ? translation('schedule.result.open')
                            : translation('schedule.result.close')
                        }
                        labelPlacement="start"
                      />
                    </TableCell>
                    {daySchedule.schedule ? (
                      daySchedule.schedule.map((time, index) => (
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          key={index}
                        >
                          <div className={clsx(classes.flex, classes.center)}>
                            {daySchedule && (
                              <ScheduleTimePicker
                                from={
                                  time.start_time
                                    ? createMomentOnBolivianTime(
                                        time.start_time.toDate()
                                      )
                                    : ''
                                }
                                to={
                                  time.end_time
                                    ? createMomentOnBolivianTime(
                                        time.end_time.toDate()
                                      )
                                    : ''
                                }
                                onChangeFrom={onChangeSchedule(
                                  day.value.toUpperCase(),
                                  index,
                                  'start'
                                )}
                                onChangeTo={onChangeSchedule(
                                  day.value.toUpperCase(),
                                  index,
                                  'end'
                                )}
                                onIconPress={() => {
                                  onTimeFrameRemove(
                                    day.value.toUpperCase(),
                                    index
                                  );
                                }}
                                onAddPress={() => {
                                  onTimeFrameAdd(
                                    day.value.toUpperCase(),
                                    index
                                  );
                                }}
                                disabled={DISABLED_REGISTER_STATUS.includes(
                                  subsidiary.register_status
                                )}
                              />
                            )}
                          </div>
                        </TableCell>
                      ))
                    ) : (
                      <>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell} />
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

Results.propTypes = {};

Results.defaultProps = {
  schedule: []
};

export default Results;
