import React, { useMemo } from 'react';
import { useTranslation } from 'react-multi-lang';
import clsx from 'clsx';
import moment from 'moment';
import {
  Box,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createMomentOnBolivianTime } from 'src/utils/date';
import { COLORS } from '../../../../theme/colors';
import { styles } from '../styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  accordion: {
    flexDirection: 'row-reverse',
    gap: '50px'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '18px'
  },
  expandIcon: {
    color: COLORS.success
  },
  tableCell: {
    borderColor: COLORS.green,
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    paddingBlock: '30px'
  },
  firstTableCell: {
    orderBlockStart: 'none',
    borderInline: 'none',
    borderBottom: 'none',
    backgroundColor: 'white',
    paddingBlock: '30px'
  },
  tableCellHeader: {
    borderBlockStart: 'none',
    borderBlockEnd: `1px solid ${COLORS.green}`
  },
  fontWeight: {
    fontWeight: 'bold'
  },
  deleteIcon: {
    color: COLORS.red,
    cursor: 'pointer'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '10px'
  },
  button: {
    cursor: 'pointer',
    color: COLORS.success
  }
}));

const COLUMNS = [
  'N°',
  'Mes a cobrar',
  '# factura emitida',
  'Fecha de pago recibido',
  'Pagos',
  'Observaciones'
];

const parseFloat = number => {
  return number === 0 ? 0 : Number.parseFloat(number).toFixed(2);
};

const PaymentHistory = ({ lastPaymentHistory = [], restaurant }) => {
  const classes = useStyles();
  const mainStyle = styles();
  const translation = useTranslation();

  const payments = useMemo(() => {
    return lastPaymentHistory?.filter(pay => pay.isRefund === false);
  }, [lastPaymentHistory]);

  return (
    <Box className={classes.container}>
      <Accordion>
        <AccordionSummary
          className={classes.accordion}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Historial de pagos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer className={mainStyle.root}>
            <Table stickyHeader size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {COLUMNS.map(col => (
                    <TableCell
                      align="center"
                      className={clsx(
                        classes.tableCell,
                        classes.tableCellHeader,
                        classes.fontWeight
                      )}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {payments?.length > 0 ? (
                <TableBody>
                  {payments.map((payment, index) => {
                    const recivedPay = moment(
                      payment.date_payment.toDate()
                    ).format('DD/MM/YYYY');
                    return (
                      <TableRow key={index}>
                        <TableCell align="center" className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {`${translation(
                            `month.${payment?.month_charge}`
                          )}/${createMomentOnBolivianTime(
                            payment?.period?.from.toDate()
                          ).format('YYYY')}`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {payment.invoice_number}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {recivedPay}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {`Bs. ${parseFloat(payment.amount_paid)}`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {payment?.observations || '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} />
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PaymentHistory;
