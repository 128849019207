import React from 'react';
import { Box, Typography } from '@material-ui/core';

const GroupBox = ({ classes, children = <div />, label }) => {
  return (
    <Box className={classes.groupBox}>
      <Typography className={classes.groupBoxLabel}>{label}</Typography>
      {children}
    </Box>
  );
};

export default GroupBox;
