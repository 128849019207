import firebase from 'firebase/app';
import 'firebase/functions';

// eslint-disable-next-line import/prefer-default-export
export const sendNotificationToTopic = async (topic, notification) => {
  const { functions } = firebase;
  const sendNotificationToTopicFunction = functions().httpsCallable(
    'sendNotificationToTopic'
  );
  const data = {
    notification: {
      ...notification,
      image: notification.image !== '' ? notification.image : null,
      type: topic
    },
    topic
  };
  return sendNotificationToTopicFunction({ ...data });
};

export const sendNotificationToTopics = async (topics, notification) => {
  const { functions } = firebase;
  const sendNotificationToTopicFunction = functions().httpsCallable(
    'sendNotificationToTopics'
  );
  const data = {
    notification: {
      ...notification,
      image: notification.image !== '' ? notification.image : null,
      type: 'ALL'
    },
    topics
  };
  return sendNotificationToTopicFunction({ ...data });
};
