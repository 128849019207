import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../../constants';

export const useAreas = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.AREAS,
      where: ['enable', '==', true],
      storeAs: `areas`
    }
  ]);
  const areas = useSelector(state => state.firestore.ordered.areas);

  if (areas) {
    return areas;
  }

  return [];
};

export const useAreasPerCity = cityId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.AREAS,
      where: [['city_id', '==', cityId]],
      storeAs: `areasCity`
    }
  ]);
  const areas = useSelector(state => state.firestore.ordered.areasCity);

  if (areas) {
    return areas;
  }

  return [];
};
