import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TableBody,
  Box,
  TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import useToggle from 'src/hooks/useToggle';
import { useTranslation } from 'react-multi-lang/lib';

function applyPagination(customers, page, limit) {
  return customers.slice(page * limit, page * limit + limit);
}

function ReportCard({ date, orders = [] }) {
  const [isExpanded, expandOrContract] = useToggle(
    moment().isSame(date, 'month')
  );
  const translation = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const paginatedOrders = applyPagination(orders, page, limit);

  return (
    <Card style={{ margin: 16 }}>
      <CardHeader
        style={{ cursor: 'pointer' }}
        onClick={expandOrContract}
        title={`${translation('earning.card.report')} ${moment(date).format(
          'MMMM'
        )}`}
      />
      {isExpanded && (
        <>
          <CardContent>
            <PerfectScrollbar>
              <Box minWidth={700}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {translation('edit.product_list.table.head.number')}
                      </TableCell>
                      <TableCell>{translation('earning.table.user')}</TableCell>
                      <TableCell>
                        {translation('earning.table.notes')}
                      </TableCell>
                      <TableCell>
                        {translation('earning.table.comments')}
                      </TableCell>
                      <TableCell>
                        {translation('earning.table.rating')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedOrders
                      .filter(order => !!order.qualification)
                      .map((order, index) => {
                        const { qualification } = order;

                        return (
                          <TableRow key={order.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{order.invoice_name}</TableCell>
                            <TableCell>
                              {qualification.options?.join(', ')}
                            </TableCell>
                            <TableCell>
                              {qualification.comment ||
                                translation('notification.no_comment')}
                            </TableCell>
                            <TableCell>
                              {qualification.liked
                                ? translation('notification.yes')
                                : translation(
                                    'admin_subsidiary.table.header.no'
                                  )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        {translation('earning.report_table.total_positives')}
                      </TableCell>
                      <TableCell>
                        {orders
                          .filter(order => !!order.qualification)
                          .reduce(
                            (map, order) =>
                              order.qualification.liked ? map + 1 : map,
                            0
                          )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={orders.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default ReportCard;
