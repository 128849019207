import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import admin from 'src/config/firebaseConfig';
import { COLLECTIONS } from '../constants';

const useCategories = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.CATEGORIES,
      where: ['enable', '==', true]
    }
  ]);
  const categories = useSelector(state => state.firestore.ordered.categories);

  if (categories) {
    return categories;
  }
  return [];
};

export const useMainCategories = () => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const categories = await admin
        .firestore()
        .collection(COLLECTIONS.MERCHANT_MAIN_CATEGORIES)
        .where('enable', '==', true)
        .where('available', '==', true)
        .get()
        .then(querySnapshot => {
          const res = [];
          querySnapshot.forEach(doc => {
            res.push({
              ...doc.data(),
              id: doc.id
            });
          });
          return res;
        });
      setResult(categories);
    };
    fetchData();
  }, []);
  return result;
};
export default useCategories;
