import React, { useEffect, useMemo, useState } from 'react';
import { Box, makeStyles, Card } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import TabsHeader from '../../components/Tab/Tabs';
import {
  useRestaurant,
  useRestaurantMergedAndCurrentOrdered
} from '../../hooks/useRestaurant';
import { useAllActiveRestaurantSubsidiaries } from '../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import Header from './header';
import { getOrderDeliveryMap } from '../../services/restaurantService';
import CircularProgress from '../reports/DashboardAlternativeView/MostProfitableProducts/CircularProgress';
import Map, { MARKER_TYPE, MarkerData } from '../../components/Map/Map';
import env from '../../env';

const useStyle = makeStyles(() => ({
  containerMap: {
    width: '100%',
    height: '67vh',
    position: 'relative',
    padding: 20
  }
}));

function getOrdersMarkers(orders) {
  return orders.map((orderModel, index) => {
    const { location } = orderModel;
    if (location) {
      return new MarkerData({
        id: `order_${index}`,
        location: {
          lat: location?.latitude,
          lng: location?.longitude
        },
        type: MARKER_TYPE.ORDER
      });
    }
    return [];
  });
}

const DeliveryMap = () => {
  const classes = useStyle();
  const history = useHistory();
  const translation = useTranslation();
  const restaurants = useRestaurantMergedAndCurrentOrdered();
  const currentRestaurant = useRestaurant();
  const [restaurant, setRestaurant] = useState(currentRestaurant);
  const allSubsidiariesRest = useAllActiveRestaurantSubsidiaries(restaurant.id);
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [loader, setLoader] = useState(false);
  const allRestaurantsMerged = useSelector(
    state => state.account.allRestaurantsMerged
  );
  const [filter, setFilter] = useState({
    subsidiary_id: '',
    date_range: 'last_day'
  });

  const [center] = useMemo(() => {
    let location = null;
    if (allSubsidiariesRest?.length > 0) {
      location = {
        lat: allSubsidiariesRest[0].location.latitude,
        lng: allSubsidiariesRest[0].location.longitude
      };
    }

    return [location];
    // eslint-disable-next-line
  }, [allSubsidiariesRest]);

  const handleFilter = select => {
    setFilter(values => ({ ...values, [select.id]: select.value }));
  };

  // eslint-disable-next-line
  const handleChangeTab = (event, index) => {
    setValueTab(index);
    setFilter({
      subsidiary_id: '',
      date_range: 'last_day'
    });
    setOrdersFiltered([]);
  };

  useEffect(() => {
    if (valueTab === 0) {
      if (currentRestaurant) setRestaurant(currentRestaurant);
    } else {
      const restSelected = currentRestaurant.restaurantsMerged[valueTab - 1];
      const restaurantMerged = allRestaurantsMerged.find(
        rest => rest.id === restSelected.restaurantId
      );
      setRestaurant(restaurantMerged);
    }
    // eslint-disable-next-line
  }, [currentRestaurant, valueTab]);

  useEffect(() => {
    if (restaurants.length > 0) {
      const newTabs = [];
      restaurants.forEach((subRest, index) => {
        newTabs.push({
          value: index,
          label: subRest.restaurant_name
        });
      });
      setTabs(newTabs);
    }
  }, [restaurants]);

  const handleSearch = async () => {
    setLoader(true);
    const dataFilter = {
      ...filter,
      restaurant_id: restaurant.id
    };
    const orders = await getOrderDeliveryMap(dataFilter);
    setOrdersFiltered(orders.payload);
    setLoader(false);
  };

  const coordinates = useMemo(() => {
    if (filter.subsidiary_id) {
      const subsidiarySelect = allSubsidiariesRest.find(
        sub => sub.id === filter.subsidiary_id
      );
      const coordinatesDB = subsidiarySelect.city.coverage_polygon.map(p => ({
        lat: p.latitude,
        lng: p.longitude
      }));
      return coordinatesDB;
    }
    // eslint-disable-next-line
  }, [filter]);

  const ordersMarkers = useMemo(() => {
    if (ordersFiltered.length > 0) {
      return getOrdersMarkers(ordersFiltered);
    }
    return [];
  }, [ordersFiltered]);

  useEffect(() => {
    if (currentRestaurant) {
      if (!currentRestaurant.show_past_orders) {
        history.push('/app/404');
      }
    }
    // eslint-disable-next-line
  }, [currentRestaurant]);

  return (
    <Page
      style={{ marginRight: '25px' }}
      title={translation('accounting.title_page')}
    >
      <Box ml={2} mt={3}>
        <TabsHeader value={valueTab} onChange={handleChangeTab} tabs={tabs} />
      </Box>
      {allSubsidiariesRest && (
        <Box ml={2}>
          <Card>
            <Header
              allSubsidiariesRest={allSubsidiariesRest}
              handleFilter={handleFilter}
              filter={filter}
              handleSearch={handleSearch}
            />
            {loader && (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="67vh"
              >
                <CircularProgress />
              </Box>
            )}
            {!loader && (
              <Box className={classes.containerMap}>
                <Map
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${env.REACT_APP_GOOGLE_MAP_API}`}
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  center={center}
                  ordersMarkers={ordersMarkers}
                  coordinates={coordinates}
                />
              </Box>
            )}
          </Card>
        </Box>
      )}
    </Page>
  );
};

export default DeliveryMap;
