import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from '../../constants';

export const useOrderHistory = orderId => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.ORDER,
      doc: orderId,
      subcollections: [{ collection: COLLECTIONS.HISTORY }],
      orderBy: ['created_at', 'desc'],
      storeAs: 'order_history'
    }
  ]);
  const orderHistory = useSelector(
    state => state.firestore.ordered.order_history
  );
  return orderHistory || [];
};
