/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_SUBSIDIARY,
  UPDATE_SCHEDULE
} from 'src/actions/accountActions';

const initialState = {
  user: null,
  currentSubsidiary: {},
  subsidiaries: [],
  subsidiariesMerged: [],
  allRestaurantsMerged: []
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const {
        user,
        restaurant,
        subsidiariesMerged,
        allRestaurantsMerged
      } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        if (restaurant) {
          draft.subsidiaries = restaurant.subsidiary || [];
          let sub = restaurant.subsidiary[0];
          if (user.subsidiary_id) {
            let defaultSubsidiary = localStorage.getItem('defaultSubdisiary');
            if (!defaultSubsidiary) {
              [defaultSubsidiary] = user.subsidiary_id;
            }

            const foundSub = restaurant.subsidiary.find(
              s => s.id === defaultSubsidiary
            );
            if (foundSub) sub = foundSub;
          }
          // eslint-disable-next-line prefer-destructuring
          draft.currentSubsidiary = sub;
          delete restaurant.subsidiary;
          draft.restaurant = restaurant;
          draft.subsidiariesMerged = subsidiariesMerged;
          draft.allRestaurantsMerged = allRestaurantsMerged;
        }
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const {
        user,
        restaurant,
        subsidiariesMerged,
        allRestaurantsMerged
      } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        if (restaurant) {
          draft.subsidiaries = restaurant.subsidiary || [];
          // eslint-disable-next-line prefer-destructuring
          let sub = restaurant.subsidiary[0];
          if (user.subsidiary_id) {
            let defaultSubsidiary = localStorage.getItem('defaultSubsidiary');
            if (
              !defaultSubsidiary ||
              (defaultSubsidiary &&
                !user.subsidiary_id.includes(defaultSubsidiary))
            ) {
              [defaultSubsidiary] = user.subsidiary_id;
            }
            const foundSub = restaurant.subsidiary.find(
              s => s.id === defaultSubsidiary
            );
            // console.log('fond sub', foundSub);
            if (foundSub) sub = foundSub;
          }
          draft.currentSubsidiary = sub;
          delete restaurant.subsidiary;
          draft.restaurant = restaurant;
          draft.subsidiariesMerged = subsidiariesMerged;
          draft.allRestaurantsMerged = allRestaurantsMerged;
        }
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, draft => {
        draft.user = user;
      });
    }

    case UPDATE_SUBSIDIARY: {
      const { subsidiary, subsidiariesMerged } = action.payload;
      return produce(state, draft => {
        draft.currentSubsidiary = state.subsidiaries.find(
          item => item.id === subsidiary
        );
        draft.subsidiariesMerged = subsidiariesMerged;
      });
    }
    case UPDATE_SCHEDULE: {
      // eslint-disable-next-line camelcase
      const { subsidiary_id, subsidiary } = action.payload;

      return produce(state, draft => {
        const index = state.subsidiaries.findIndex(
          // eslint-disable-next-line camelcase
          item => item.id === subsidiary_id
        );
        draft.currentSubsidiary = subsidiary;
        draft.subsidiaries[index] = subsidiary;
      });
    }
    default: {
      return state;
    }
  }
};

export default accountReducer;
