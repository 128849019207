import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  // Button,
  // Checkbox,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useHistory } from 'react-router';
// import { Done } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    backgroundImage: "url('/static/home/thanks.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containerImage: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '50px 15px'
  },
  title: {
    paddingBottom: 30,
    fontWeight: 'bold',
    fontSize: 64
  },
  image: {
    maxWidth: '800px',
    width: '100%',
    marginTop: '20px',
    marginBottom: '30px'
  },
  text: {
    maxWidth: '800px',
    width: '100%',
    textAlign: 'center',
    zIndex: 3
  },
  overlay: {
    background:
      'transparent linear-gradient(212deg, #F9F9F9ED 0%, #F9F9F9E8 21%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
    height: '100vh',
    width: '100%',
    position: 'absolute',
    zIndex: 2
  },
  button: {
    zIndex: 4,
    fontWeight: 'bold'
  }
}));

const RegisterThanks = React.memo(({ className, handleNext }) => {
  const classes = useStyles();
  const translation = useTranslation();

  const history = useHistory();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.overlay} />
      <div className={clsx(classes.containerImage)}>
        <Box className={clsx(classes.text)}>
          <Typography
            variant="h1"
            color="textPrimary"
            className={clsx(classes.title)}
          >
            ¡{translation('congratulation.title')}!
          </Typography>
          <Typography variant="h3" color="textPrimary">
            {translation('congratulation.content')}
          </Typography>
        </Box>
        <Box>
          <Button
            className={classes.button}
            onClick={() => history.push('/home')}
            color="secondary"
            variant="contained"
          >
            {translation('home.go_back')}
          </Button>
        </Box>
      </div>
    </div>
  );
});

RegisterThanks.propTypes = {
  className: PropTypes.string,
  handleNext: PropTypes.func
};

export default RegisterThanks;
