/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Divider,
  CircularProgress,
  Box,
  Grid,
  Link,
  Switch,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import ExpressTable from '../../../components/Table/ExpressTable';
import Page from '../../../components/Page';
import Header from './Header';
import { useAllRestaurants } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllSubsidiaries } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { columns } from './columns';
import { registerStatus } from '../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  }
}));

const CommerceTable = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { status } = match.params;
  const restaurants = useAllRestaurants();
  const subsidiaries = useAllSubsidiaries();
  const translation = useTranslation();
  const [tableColumns, setTableColumns] = useState([]);
  const [filter, setFilter] = useState(true);

  let tableContent = [];
  let title = null;

  const getLabel = item => {
    if (filter) return translation(item.labelToTranslate[0]);
    return translation(item.labelToTranslate[1]);
  };

  useEffect(() => {
    let col = [];
    switch (status) {
      case 'pending':
        col = columns.pendingColumns.map(item => ({
          ...item,
          label:
            item.id === 'name'
              ? getLabel(item)
              : translation(item.labelToTranslate)
        }));
        return setTableColumns(col);
      case 'active':
        col = columns.activeColumns.map(item => ({
          ...item,
          label:
            item.id === 'name'
              ? getLabel(item)
              : translation(item.labelToTranslate)
        }));
        return setTableColumns(col);
      case 'rejected':
        col = columns.rejectedColumns.map(item => ({
          ...item,
          label:
            item.id === 'name'
              ? getLabel(item)
              : translation(item.labelToTranslate)
        }));
        return setTableColumns(col);
      case 'suspended':
        col = columns.suspendedColumns.map(item => ({
          ...item,
          label:
            item.id === 'name'
              ? getLabel(item)
              : translation(item.labelToTranslate)
        }));
        return setTableColumns(col);
      case 'archived':
        col = columns.archivedColumns.map(item => ({
          ...item,
          label:
            item.id === 'name'
              ? getLabel(item)
              : translation(item.labelToTranslate)
        }));
        return setTableColumns(col);
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, filter]);

  const actionsContent = (restId, subId) => {
    return (
      <Grid item container direction="column">
        <Box>
          <Link
            component={RouterLink}
            to={`/admin/commerce/${status}/restaurant/${restId}`}
          >
            {translation('admin_subsidiary.common_table.see_restaurant')}
          </Link>
        </Box>
        {!filter && (
          <Box>
            <Link
              component={RouterLink}
              to={`/admin/commerce/${status}/subsidiary/${subId}`}
            >
              {translation('admin_subsidiary.common_table.see_subsidiary')}
            </Link>
          </Box>
        )}
      </Grid>
    );
  };

  const getPendingLabel = restaurant => {
    let word = '';
    if (restaurant.pendings.docs && restaurant.pendings.subsidiaries)
      word = translation('admin_commerces.pendings.docs_subs');
    if (restaurant.pendings.docs && !restaurant.pendings.subsidiaries)
      word = translation('admin_commerces.pendings.docs');
    if (!restaurant.pendings.docs && restaurant.pendings.subsidiaries)
      word = translation('admin_commerces.pendings.subs');
    return word;
  };

  const getPhone = item => {
    if (filter) return item.owner_phone ? item.owner_phone : '-';
    return item.legal_representant ? item.legal_representant.phone : '-';
  };

  const getEmail = item => {
    if (filter) return item.owner_email ? item.owner_email : '-';
    return item.legal_representant && item.legal_representant.email
      ? item.legal_representant.email
      : '-';
  };

  if (restaurants && status === 'pending') {
    tableContent = [];
    let res;
    let subs;
    if (filter) {
      res = restaurants.filter(
        item =>
          item.register_status !== registerStatus.REJECTED &&
          ((item.pendings && item.register_status === registerStatus.PENDING) ||
            (item.pendings &&
              (item.pendings.docs || item.pendings.subsidiaries)))
      );
    }
    if (!filter)
      subs = subsidiaries.filter(
        item => item.register_status === registerStatus.PENDING
      );

    const content = filter ? res : subs;
    tableContent = content.map((item, index) => {
      return {
        counter: index + 1,
        id: item.id,
        linkTo: `/admin/commerce/${status}/${
          filter ? item.id : item.restaurant_id
        }`,
        name: `${item.restaurant_name ? `${item.restaurant_name} /` : ''} ${
          item.name
        }`,
        email: getEmail(item),
        subsidiaries_amount: item.subsidiary_amount
          ? item.subsidiary_amount
          : '-',
        created_at: moment(item.created_at.toDate()).format('LLL'),
        createdValue: item.created_at,
        contact_phone: getPhone(item),
        pending: filter ? getPendingLabel(item) : '-'
      };
    });

    title = translation(
      `admin_commerces.titles.${filter ? 'pending_r' : 'pending'}`
    );
  }

  if (restaurants && subsidiaries && status === 'active') {
    tableContent = [];
    let res;
    let subs;
    if (filter)
      res = restaurants.filter(
        item => item.register_status === registerStatus.ACTIVE
      );
    if (!filter)
      subs = subsidiaries.filter(
        item => item.register_status === registerStatus.ACTIVE
      );

    const content = filter ? res : subs;

    tableContent = content.map((item, index) => ({
      counter: index + 1,
      id: item.id,
      name: `${item.restaurant_name ? `${item.restaurant_name} /` : ''} ${
        item.name
      }`,
      email: getEmail(item),
      subsidiaries_amount: item.subsidiary_amount
        ? item.subsidiary_amount
        : '-',
      created_at: moment(item.created_at.toDate()).format('LLL'),
      createdValue: item.created_at,
      raw_date: moment(item.created_at.toDate()),
      contact_phone: getPhone(item),
      actions: actionsContent(filter ? item.id : item.restaurant_id, item.id)
    }));
    title = translation(
      `admin_commerces.titles.${filter ? 'active_r' : 'active'}`
    );
  }

  if (restaurants && status === 'rejected') {
    tableContent = [];
    let res;
    let subs;
    if (filter)
      res = restaurants.filter(
        item => item.register_status === registerStatus.REJECTED
      );
    if (!filter)
      subs = subsidiaries.filter(
        item => item.register_status === registerStatus.REJECTED
      );
    const content = filter ? res : subs;

    tableContent = content.map((item, index) => ({
      counter: index + 1,
      id: item.id,
      name: `${item.restaurant_name ? `${item.restaurant_name} /` : ''} ${
        item.name
      }`,
      email: getEmail(item),
      created_at: moment(item.created_at.toDate()).format('LLL'),
      createdValue: item.created_at,
      raw_date: moment(item.created_at.toDate()),
      contact_phone: getPhone(item),
      actions: actionsContent(filter ? item.id : item.restaurant_id, item.id)
    }));
    title = translation(
      `admin_commerces.titles.${filter ? 'rejected_r' : 'rejected'}`
    );
  }

  if (subsidiaries && status === 'suspended') {
    tableContent = [];
    let res;
    let subs;
    if (filter)
      res = restaurants.filter(
        item => item.register_status === registerStatus.SUSPENDED
      );
    if (!filter)
      subs = subsidiaries.filter(
        item => item.register_status === registerStatus.SUSPENDED
      );
    const content = filter ? res : subs;

    tableContent = content.map((item, index) => ({
      counter: index + 1,
      id: item.id,
      name: `${item.restaurant_name ? `${item.restaurant_name} /` : ''} ${
        item.name
      }`,
      email: getEmail(item),
      suspention_reason: item.suspention_reason ? item.suspention_reason : '-',
      created_at: moment(item.created_at.toDate()).format('LLL'),
      createdValue: item.created_at,
      raw_date: moment(item.created_at.toDate()),
      contact_phone: getPhone(item),
      subsidiaries_amount: item.subsidiary_amount
        ? item.subsidiary_amount
        : '-',
      actions: actionsContent(filter ? item.id : item.restaurant_id, item.id)
    }));
    title = translation(
      `admin_commerces.titles.${filter ? 'suspended_r' : 'suspended'}`
    );
  }

  if (subsidiaries && status === 'archived') {
    tableContent = [];
    let res;
    let subs;
    if (filter)
      res = restaurants.filter(
        item => item.register_status === registerStatus.ARCHIVED
      );
    if (!filter)
      subs = subsidiaries.filter(
        item => item.register_status === registerStatus.ARCHIVED
      );
    const content = filter ? res : subs;

    tableContent = content.map((item, index) => ({
      counter: index + 1,
      id: item.id,
      name: `${item.restaurant_name ? `${item.restaurant_name} /` : ''} ${
        item.name
      }`,
      email: getEmail(item),
      suspention_reason: item.suspention_reason ? item.suspention_reason : '-',
      created_at: moment(item.created_at.toDate()).format('LLL'),
      createdValue: item.created_at,
      contact_phone: getPhone(item),
      raw_date: moment(item.created_at.toDate()),
      subsidiaries_amount: item.subsidiary_amount
        ? item.subsidiary_amount
        : '-',
      actions: actionsContent(filter ? item.id : item.restaurant_id, item.id)
    }));
    title = translation(
      `admin_commerces.titles.${filter ? 'archived_r' : 'archived'}`
    );
  }

  const filterOptions = [
    {
      value: translation('admin_commerces.pendings.docs_subs'),
      label: translation('admin_commerces.pendings.docs_subs')
    },
    {
      value: translation('admin_commerces.pendings.docs'),
      label: translation('admin_commerces.pendings.docs')
    },
    {
      value: translation('admin_commerces.pendings.subs'),
      label: translation('admin_commerces.pendings.subs')
    },
    { value: 'all', label: translation('admin_commerces.filter.all') }
  ];

  const filterByDate = [
    { value: '30', label: translation('admin_commerces.filter.last30_d') },
    { value: '60', label: translation('admin_commerces.filter.last60_d') },
    { value: '365', label: translation('admin_commerces.filter.this_year') },
    { value: 'all', label: translation('admin_commerces.filter.all') }
  ];

  const getFilter = () => {
    if (status === 'pending') return filterOptions;
    return filterByDate;
  };

  const getFilterText = () => {
    if (status === 'pending') return translation('admin_commerces.pending');
    return translation('admin_commerces.filter_dates');
  };

  const getBreadCrumbLabel = () => {
    switch (status) {
      case 'active':
        return translation('admin_commerces.breadcrumbs.actives');
      case 'rejected':
        return translation('admin_commerces.breadcrumbs.rejected');
      case 'suspended':
        return translation('admin_commerces.breadcrumbs.suspended');
      case 'pending':
        return translation('admin_commerces.breadcrumbs.pending');
      case 'archived':
        return translation('admin_commerces.breadcrumbs.archived');
      default:
        break;
    }
  };

  return (
    <Page
      className={classes.root}
      title={translation('admin_commerces.title_page_table')}
    >
      <Container maxWidth={false}>
        <Header
          headerTitle={title}
          path={[
            { link: `/admin/commerce/${status}`, label: getBreadCrumbLabel() }
          ]}
        />
        <Divider />
        {(!restaurants || !subsidiaries) && (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}
        <Box m={2} width="100%">
          <Grid item container alignItems="center" justify="flex-end">
            <Box mr={3}>
              <Typography variant="subtitle2" color="textPrimary">
                {filter
                  ? translation('admin_commerces.show_rest')
                  : translation('admin_commerces.show_subsi')}
              </Typography>
            </Box>
            <Box mr={2}>
              <Switch
                checked={filter}
                onChange={e => setFilter(e.target.checked)}
                name="filter"
                id="filter"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </Grid>
        </Box>
        {restaurants && subsidiaries && (
          <Grid>
            {status === 'pending' && (
              <ExpressTable
                columns={tableColumns}
                rows={tableContent}
                search
                searchPH={translation('admin_commerces.searh_ph')}
                filterOptions={getFilter()}
                filterText={getFilterText()}
                nameToExportCsv={title}
              />
            )}
            {status !== 'pending' && (
              <ExpressTable
                columns={tableColumns}
                rows={tableContent}
                search
                searchPH={translation('admin_commerces.searh_ph')}
                filterOptions={getFilter()}
                filterText={getFilterText()}
                filterSettings={{
                  property: 'raw_date',
                  filterType: 'dates'
                }}
                nameToExportCsv={title}
              />
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default CommerceTable;
