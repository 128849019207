/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
  CardActions
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import { useDispatch, useSelector } from 'react-redux';
import DealersEditForm from './DealersEditForm';
import ModalDealerImg from './ModalDealerImg';
import ModalDealerCredential from './ModalDealerCredential';
import { DRIVER_APPLICATION_STATUS } from '../../../../dto/enum';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import dealerService from '../../../../services/dealerService';
import UpdateImagenDealer from './UpdateImagenDealer';
import ProfileDetails from '../../../pages/AccountView/General/ProfileDetails';
import { updateProfile } from '../../../../actions/accountActions';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {},
  actionButtons: {
    textAlign: 'end',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  name: {
    marginTop: theme.spacing(1)
  },
  card: {
    maxWidth: 300
  },
  media: {
    padding: '2%',
    height: 300
  }
}));

const DealersView = ({ dealer }) => {
  const classes = useStyles();
  const translation = useTranslation();
  const currentUser = useSelector(state => state.account.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState({ open: false, name: '', type: '' });
  const [user, setUser] = useState('');
  const [license, setLicense] = useState('');
  const [soat, setSoat] = useState('');
  const [ruat, setRuat] = useState('');
  const [ciFront, setCIfront] = useState('');
  const [ciBack, setCIback] = useState('');
  const [permit, setPermit] = useState('');
  const [typeTransportation, setTypeTransportation] = useState('');
  const [showImg, setShowImg] = useState({ show: false, img: '' });
  const [status, setStatus] = useState(
    dealer.driver_information.status
      ? dealer.driver_information.status
      : DRIVER_APPLICATION_STATUS.APPLIED
  );
  const [statusToChange, setStatusToChange] = useState('');
  const [openCredentialModal, setOpenCredentialModal] = useState(false);

  useEffect(() => {
    setLicense(
      dealer.driver_information.license_photo
        ? dealer.driver_information.license_photo.original
        : ''
    );
    setSoat(
      dealer.driver_information.soat_photo
        ? dealer.driver_information.soat_photo.original
        : ''
    );
    setRuat(
      dealer.driver_information.ruat_photo
        ? dealer.driver_information.ruat_photo.original
        : ''
    );
    setCIfront(
      dealer.driver_information.ci_front_photo
        ? dealer.driver_information.ci_front_photo.original
        : ''
    );
    setCIback(
      dealer.driver_information.ci_back_photo
        ? dealer.driver_information.ci_back_photo.original
        : ''
    );
    setPermit(
      dealer.driver_information.license_movement_photo
        ? dealer.driver_information.license_movement_photo.original
        : ''
    );
    setTypeTransportation(
      dealer.driver_information.transportation_type
        ? dealer.driver_information.transportation_type
        : ''
    );
    setUser({
      ...dealer,
      profile_photo: {
        ...dealer.profile_photo,
        x400: dealer.profile_photo.original
      }
    });
  }, [dealer]);

  const updateImage = async (img, type) => {
    if (type === 'license') setLicense(img);
    if (type === 'soat') setSoat(img);
    if (type === 'ruat') setRuat(img);
    if (type === 'permit') setPermit(img);
    if (type === 'ciBack') setCIback(img);
    if (type === 'ciFront') setCIfront(img);
  };

  const setStatusDB = async () => {
    await dealerService.saveDealerDB(dealer.id, {
      driver_information: {
        ...dealer.driver_information,
        status: statusToChange
      }
    });
    setStatus(statusToChange);
    setStatusToChange('');
  };

  const updateImageProfile = async img => {
    setUser({
      ...dealer,
      profile_photo: { ...dealer.profile_photo, x400: img }
    });
    if (dealer.id === currentUser.id) {
      const userUpdated = {
        ...dealer,
        profile_photo: { ...dealer.profile_photo, x80: img, x40: img }
      };
      await dispatch(updateProfile(userUpdated));
    }
  };

  return (
    <>
      <Grid className={clsx(classes.root)} container spacing={3}>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <ProfileDetails
            user={user}
            updateImage={updateImageProfile}
            handleCredentialModal={() => setOpenCredentialModal(true)}
          />
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <DealersEditForm dealer={dealer} />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {(typeTransportation === 'MOTO' ||
                  typeTransportation === 'CAR') && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            component="img"
                            alt="Licencia"
                            image={license}
                            title="Licencia"
                            onClick={() =>
                              setShowImg({ show: true, img: license })
                            }
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {translation('admin_dealers.document.license')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="text"
                            onClick={() =>
                              setOpen({
                                open: true,
                                name: license,
                                type: 'license'
                              })
                            }
                          >
                            {translation('restaurant.button_title')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            component="img"
                            alt="Soat"
                            image={soat}
                            title="Soat"
                            onClick={() =>
                              setShowImg({ show: true, img: soat })
                            }
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              SOAT
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="text"
                            onClick={() =>
                              setOpen({ open: true, name: soat, type: 'soat' })
                            }
                          >
                            {translation('restaurant.button_title')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            component="img"
                            alt="Ruat"
                            image={ruat}
                            title="Ruat"
                            onClick={() =>
                              setShowImg({ show: true, img: ruat })
                            }
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              RUAT
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="text"
                            onClick={() =>
                              setOpen({ open: true, name: ruat, type: 'ruat' })
                            }
                          >
                            {translation('restaurant.button_title')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                )}
                {typeTransportation === 'BICYCLE' && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            component="img"
                            alt="CI Back"
                            image={ciBack}
                            title="CI Back"
                            onClick={() =>
                              setShowImg({ show: true, img: ciBack })
                            }
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {translation('admin_dealers.document.ci_back')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="text"
                            onClick={() =>
                              setOpen({
                                open: true,
                                name: ciBack,
                                type: 'ciBack'
                              })
                            }
                          >
                            {translation('restaurant.button_title')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            component="img"
                            alt="CI Front"
                            image={ciFront}
                            title="CI Front"
                            onClick={() =>
                              setShowImg({ show: true, img: ciFront })
                            }
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {translation('admin_dealers.document.ci_front')}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="text"
                            onClick={() =>
                              setOpen({
                                open: true,
                                name: ciFront,
                                type: 'ciFront'
                              })
                            }
                          >
                            {translation('restaurant.button_title')}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                )}
                <Grid item md={6} xs={12}>
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        component="img"
                        alt={permit ? 'Permit' : ''}
                        image={permit || ''}
                        title="Permit"
                        onClick={() => setShowImg({ show: true, img: permit })}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {translation('admin_dealers.document.permit')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button
                        variant="text"
                        onClick={() =>
                          setOpen({
                            open: true,
                            name: permit,
                            type: 'permit'
                          })
                        }
                      >
                        {permit
                          ? translation('restaurant.button_title')
                          : translation('admin_dealers.add')}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <ModalDealerImg showImg={showImg} setShowImg={setShowImg} />
          <ModalDealerCredential
            closeModal={() => setOpenCredentialModal(false)}
            openModal={openCredentialModal}
            user={user}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={clsx(classes.actionButtons)}>
            {(status === DRIVER_APPLICATION_STATUS.APPLIED ||
              status === DRIVER_APPLICATION_STATUS.REJECTED ||
              status === DRIVER_APPLICATION_STATUS.SUSPENDED) && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.action}
                onClick={() => {
                  setStatusToChange(DRIVER_APPLICATION_STATUS.ACCEPTED);
                }}
              >
                {translation('admin_dealers.dealerEditView.accept')}
              </Button>
            )}
            {status === DRIVER_APPLICATION_STATUS.APPLIED && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.action}
                onClick={() => {
                  setStatusToChange(DRIVER_APPLICATION_STATUS.REJECTED);
                }}
              >
                {translation('admin_dealers.dealerEditView.reject')}
              </Button>
            )}
            {status === DRIVER_APPLICATION_STATUS.ACCEPTED && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.action}
                onClick={() => {
                  setStatusToChange(DRIVER_APPLICATION_STATUS.SUSPENDED);
                }}
              >
                {translation('admin_dealers.dealerEditView.suspend')}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <AlertDialog
        open={!!statusToChange}
        question={translation(
          `admin_dealers.dealerEditView.question.${statusToChange}`
        )}
        handleCancel={() => {
          setStatusToChange('');
        }}
        handleAccept={() => setStatusDB()}
        acceptLabel={translation('admin_dealers.dealerEditView.continue')}
        cancelLabel={translation('close')}
      >
        <Typography as="body">
          {dealer.first_name}
          {dealer.last_name}
        </Typography>
      </AlertDialog>
      <UpdateImagenDealer
        dealer={dealer}
        updateImage={updateImage}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default DealersView;
