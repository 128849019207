import React, { forwardRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTheme } from '@material-ui/core';
import Delete from './Delete';
import Comfirmation from './Comfirmation';
import UploadFile from './UploadFile';
import EditDocument from './EditDocment';
import UploadFileRestaurant from './UploadFileRestaurant';
import EditFileRestaurant from './EditFileRestaurant';
import UpdateLogo from './UpdateLogo';
import AddDocument from './AddDocument';
import AddMenu from './AddMenu';
import EditDescriptionTitle from './EditDescriptionTitle';
import UploadFileRest from './ModalDocuments/UploadFileRest';
import AddNewImage from './AddNewImage';

const DefaultModals = forwardRef(
  ({
    handleConfirm,
    handleClose,
    type,
    message,
    path,
    status,
    name,
    ref,
    title,
    description,
    urlImage,
    id,
    typeImage,
    values,
    acceptFormat
  }) => {
    switch (type) {
      case 'deleteConfirmation':
        return (
          <Delete
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
          />
        );
      case 'comfirmation':
        return (
          <Comfirmation
            ref={ref}
            message={message}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
          />
        );
      case 'uploadFile':
        return (
          <UploadFile
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            path={path}
          />
        );
      case 'uploadFileRest':
        return (
          <UploadFileRest
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            path={path}
          />
        );
      case 'uploadFileRestaurant':
        return (
          <UploadFileRestaurant
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            path={path}
          />
        );
      case 'editDocument':
        return (
          <EditDocument
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            status={status}
            name={name}
            urlImage={urlImage}
            path={path}
          />
        );
      case 'editFileRestaurant':
        return (
          <EditFileRestaurant
            ref={ref}
            handleConfirm={handleConfirm}
            handleClose={handleClose}
            status={status}
            name={name}
            title={title}
            description={description}
            urlImage={urlImage}
            path={path}
            acceptFormat={acceptFormat}
          />
        );
      case 'updateLogo':
        return (
          <UpdateLogo
            typeImage={typeImage}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            id={id}
            acceptFormat={acceptFormat}
          />
        );
      case 'addDocument':
        return (
          <AddDocument
            handleClose={handleClose}
            handleConfirm={handleConfirm}
          />
        );
      case 'editDescriptionTitle':
        return (
          <EditDescriptionTitle
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            values={values}
          />
        );
      case 'addMenu':
        return (
          <AddMenu handleClose={handleClose} handleConfirm={handleConfirm} />
        );
      case 'addNewImage':
        return (
          <AddNewImage
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            id={id}
          />
        );
      default:
        return null;
    }
  }
);

const XpressModal = ({
  handleConfirm,
  handleClose,
  type,
  message,
  path,
  status,
  name,
  children,
  urlImage,
  title,
  description,
  id,
  typeImage,
  values,
  acceptFormat
}) => {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{
          style: {
            background: `transparent linear-gradient(180deg, ${theme.palette.modalBackground.primary} 100%, ${theme.palette.modalBackground.primaryDegradation} 100%) 0% 0% no-repeat padding-box`,
            opacity: 0.44
          }
        }}
      >
        <>
          {type && (
            <DefaultModals
              message={message}
              handleConfirm={handleConfirm}
              handleClose={handleClose}
              type={type}
              path={path}
              status={status}
              name={name}
              urlImage={urlImage}
              title={title}
              description={description}
              id={id}
              typeImage={typeImage}
              values={values}
              acceptFormat={acceptFormat}
            />
          )}
          {!type && { ...children }}
        </>
      </Modal>
    </div>
  );
};

export default XpressModal;
