import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang/lib';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ExpressTable from '../../../../components/Table/ExpressTable';
import { TYPE_OF_DISCOUNT } from '../../../../dto/enum';
import AlertDialog from '../../../../components/Dialog/AlertDialog';
import promoService from '../../../../services/promoService';

// const useStyles = makeStyles(theme => ({
//   modal: {
//     marginTop: theme.spacing(2),
//     justifyContent: 'center'
//   }
// }));

const Results = ({ promos }) => {
  // const classes = useStyles();
  const translation = useTranslation();
  const [deletedPromo, setDeletedPromo] = useState({});

  const tableColumns = [
    {
      id: 'title',
      label: translation('admin_promos.table.title'),
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'promo_code',
      label: translation('admin_promos.table.code'),
      minWidth: 100,
      align: 'center',
      search: true
    },
    {
      id: 'discount',
      label: translation('admin_promos.table.discount'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'expiration_date',
      label: translation('admin_promos.table.expiration_date'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'available',
      label: translation('admin_promos.table.available'),
      minWidth: 50,
      align: 'center',
      search: true
    },
    {
      id: 'actions',
      label: translation('admin_promos.table.actions'),
      minWidth: 50,
      align: 'center',
      search: true
    }
  ];
  let tableContent = [];

  const linkToSub = item => {
    return (
      <Box>
        <Link
          style={{ color: 'black', textDecorationLine: 'underline' }}
          component={RouterLink}
          to={`/admin/promos/${item.id}`}
        >
          {translation('admin_promos.table.link').toUpperCase()}
        </Link>

        <Button
          style={{ color: 'black', textDecorationLine: 'underline' }}
          onClick={() => setDeletedPromo(item)}
        >
          {translation('admin_promos.table.delete_link').toUpperCase()}
        </Button>
      </Box>
    );
  };

  if (promos) {
    tableContent = promos.map(item => ({
      id: item.id,
      title: item.title,
      promo_code: item.promo_code,
      discount: `${
        item.type_of_discount === TYPE_OF_DISCOUNT.PERCENTAGE ? '% ' : 'Bs. '
      }${item.amount}`,
      expiration_date: moment(item.expire_date.toDate()).format('DD/MM/YYYY'),
      available: item.available
        ? translation('admin_promos.table.yes')
        : translation('admin_promos.table.no'),
      actions: linkToSub(item)
    }));
  }

  const deletePromo = async () => {
    await promoService.savePromoDB(deletedPromo.id, {
      enable: false,
      available: false,
      updated_at: moment().toDate()
    });
    setDeletedPromo({});
  };

  return (
    <>
      <Grid>
        <ExpressTable
          columns={[...tableColumns]}
          rows={[...tableContent]}
          search
        />
      </Grid>
      <AlertDialog
        open={!!deletedPromo.id}
        question={translation('admin_promos.table.delete')}
        maxWidth="sm"
        handleCancel={() => {
          setDeletedPromo({});
        }}
        handleAccept={() => deletePromo()}
      >
        <Box m={1}>
          <Typography variant="h4">{`${deletedPromo.title}`}</Typography>
        </Box>
      </AlertDialog>
    </>
  );
};

export default Results;
