import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-multi-lang/lib';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    backgroundImage: "url('/static/home/marble.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  basicInfo: {
    height: '100vh',
    // width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 15px',
    width: '100%'
  },
  basicInfoChecks: {
    backgroundColor: 'transparent',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent,
      margin: 8
    }
  },
  basicInfoForm: {
    maxWidth: '500px',
    width: '100%',
    backgroundColor: 'transparent',
    '& li': {
      backgroundColor: theme.palette.background.defaultTransparent
    }
  },
  title: {
    paddingBottom: 30,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  card: {
    maxWidth: '865px',
    width: '100%',
    maxHeight: '750px',
    height: '100%',
    position: 'relative',
    textAlign: '-webkit-center'
  },
  background: {
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
    background: '#ffffff',
    height: '100%',
    width: '100%'
  },
  formPosition: {
    position: 'absolute',
    padding: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  labelTitle: {
    marginLeft: 48
  },
  arrayIcon: {
    zIndex: 4,
    cursor: 'pointer',
    position: 'absolute',
    color: '#56B19E',
    top: 45,
    left: 45
  },
  textField: {
    margin: 0
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  legalRepresentative: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const BasicInfo = React.memo(
  ({ className, handleNext, updateUser, setRestaurantData, handleBack }) => {
    const classes = useStyles();
    const translation = useTranslation();
    const [phoneNumber, setPhoneNumber] = useState(false);
    const [dialCode, setDialCode] = useState(591);

    return (
      <div className={clsx(classes.root, className)}>
        <div className={clsx(classes.basicInfo)}>
          <Box className={classes.card}>
            <Box className={classes.background} />
            <ArrowBack onClick={handleBack} className={classes.arrayIcon} />
            <Box className={classes.formPosition}>
              <Box p={2} className={classes.basicInfoForm}>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  className={clsx(classes.title)}
                >
                  {translation('basic_information.personal_info.title')}
                </Typography>
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    ci: '',
                    phone_number: '',
                    invoice_email: ''
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string()
                      .max(255)
                      .required(
                        translation(
                          'basic_information.personal_info.name.require'
                        )
                      ),
                    last_name: Yup.string()
                      .max(255)
                      .required(
                        translation(
                          'basic_information.personal_info.lastname.require'
                        )
                      ),
                    ci: Yup.string()
                      .max(255)
                      .required(
                        translation(
                          'basic_information.personal_info.ci.require'
                        )
                      ),
                    phone_number: Yup.string()
                      .max(255)
                      .required(
                        translation(
                          'basic_information.personal_info.phone.require'
                        )
                      ),
                    invoice_email: Yup.string()
                      .email(translation('login.invoice_email.valid'))
                      .max(255)
                      .required(translation('login.invoice_email.require'))
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      if (!phoneNumber && !dialCode) {
                        setErrors({
                          phone_number: translation(
                            'basic_information.personal_info.phone.require'
                          )
                        });
                      } else {
                        updateUser({
                          ...values,
                          phone_number: `+${phoneNumber}`
                        });
                      }
                      setRestaurantData({
                        invoice_email: values.invoice_email
                      });
                      handleNext();
                    } catch (error) {
                      setStatus({ success: false });
                      setErrors({ submit: error.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <TextField
                        className={classes.textField}
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        label={translation(
                          'basic_information.personal_info.name.name'
                        )}
                        margin="normal"
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.first_name}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        label={translation(
                          'basic_information.personal_info.lastname.lastname'
                        )}
                        margin="normal"
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.last_name}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={Boolean(touched.ci && errors.ci)}
                        fullWidth
                        helperText={touched.ci && errors.ci}
                        label={translation(
                          'basic_information.personal_info.ci.ci'
                        )}
                        margin="normal"
                        name="ci"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="ci"
                        value={values.ci}
                        variant="outlined"
                      />
                      <Box mt={2}>
                        <PhoneInput
                          inputStyle={{
                            background: '#f2f2f2'
                          }}
                          placeholder={translation(
                            'basic_information.personal_info.phone.phone'
                          )}
                          specialLabel={translation(
                            'basic_information.personal_info.phone.phone'
                          )}
                          regions={['america', 'europe']}
                          country="bo"
                          value={values.phone_number}
                          onChange={(phone, data) => {
                            setDialCode(data.dialCode);
                            setPhoneNumber(phone);
                            setFieldValue('phone_number', phone);
                          }}
                          variant="outlined"
                        />
                        {errors.phone_number && (
                          <Box>
                            <FormHelperText error>
                              {errors.phone_number}
                            </FormHelperText>
                          </Box>
                        )}
                      </Box>
                      <TextField
                        className={classes.textField}
                        error={Boolean(
                          touched.invoice_email && errors.invoice_email
                        )}
                        fullWidth
                        helperText={
                          touched.invoice_email && errors.invoice_email
                        }
                        label={translation(
                          'basic_information.personal_info.invoice_email'
                        )}
                        margin="normal"
                        name="invoice_email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.invoice_email}
                        variant="outlined"
                      />
                      <Box mt={2}>
                        <Button
                          color="secondary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {translation('next')}
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
);

BasicInfo.propTypes = {
  className: PropTypes.string,
  handleNext: PropTypes.func,
  updateUser: PropTypes.func
};

export default BasicInfo;
