import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  Typography,
  Card
} from '@material-ui/core';
import clsx from 'clsx';
import useFilter from '../../../hooks/filter/useFilter';
import ExpressTable from '../../../components/Table/ExpressTable';
import { getSalesReportsByFilters } from '../../../services/orderServices';
import OrderDetailsView from '../../admin/order/OrderDetailsView';
import orderStatusTranslate from '../../../utils/translateUtil';
import Orderstatus from '../../orders/OrderDetailsView/Orderstatus';
import { useSalesStyles } from './styles';
import { createMomentOnBolivianTime } from '../../../utils/date';

const Sales = props => {
  const {
    handleOrders,
    getTotals,
    contentChargesHistory,
    getCreditOrders
  } = props;
  const filter = useFilter();
  const translation = useTranslation();
  const [tableContent, setTableContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const classes = useSalesStyles();
  const [ordersTotals, setOrdersTotals] = useState({
    subtotalCharge: 0,
    tCommission: 0,
    tCommissionEntregas: 0,
    tCommissionOnline: 0,
    tDiscWallet: 0,
    tDiscount: 0,
    tIvaCommission: 0,
    tRefund: 0,
    tRestRewardPaidRestaurant: 0,
    tRestRewardPaidXpress: 0,
    tSale: 0,
    totalCharge: 0,
    tDeliveryFreeRestaurant: 0,
    totalCredit: 0
  });
  const tableColumns = [
    {
      id: 'counter',
      label: translation('accounting.table.counter'),
      align: 'center'
    },
    {
      id: 'orderNumber',
      label: translation('accounting.table.order_number'),
      align: 'center',
      search: true
    },
    {
      id: 'restaurant',
      label: translation('accounting.table.rest'),
      align: 'center',
      search: true
    },
    {
      id: 'date',
      filterName: 'dateValue',
      label: translation('accounting.table.date'),
      align: 'center',
      search: true
    },
    {
      id: 'status',
      filterName: 'statusValue',
      label: translation('accounting.table.status'),
      align: 'center',
      search: true
    },
    {
      id: 'city',
      label: translation('accounting.table.city'),
      align: 'center',
      search: true
    },
    {
      id: 'service',
      label: translation('accounting.table.service'),
      align: 'center',
      search: true
    },
    {
      id: 'userName',
      filterName: 'userName',
      label: translation('accounting.table.client_name'),
      align: 'center',
      search: true
    },
    {
      id: 'nit',
      filterName: 'nit',
      label: translation('accounting.table.nit'),
      align: 'center',
      search: true
    },
    {
      id: 'sale',
      filterName: 'saleValue',
      label: translation('accounting.table.sale'),
      align: 'center',
      search: true
    },
    {
      id: 'subsidiByRestaurant',
      label: translation('accounting.table.subsidi_by_restaurant'),
      align: 'center',
      search: true
    },
    {
      id: 'commissionXpressPercentage',
      label: translation('accounting.table.commission_percentage'),
      align: 'center',
      search: true
    },
    {
      id: 'commissionEntregas',
      label: translation('accounting.table.commission_entregas'),
      align: 'center',
      search: true
    },
    {
      id: 'commissionOnlinePercentage',
      label: translation('accounting.table.commission_online_percentage'),
      align: 'center',
      search: true
    },
    {
      id: 'commission',
      label: translation('accounting.table.commission'),
      align: 'center',
      search: true
    },
    {
      id: 'promotionTitle',
      label: translation('accounting.table.promo'),
      align: 'center',
      search: true
    },
    {
      id: 'discount',
      label: translation('accounting.table.discount'),
      align: 'center',
      search: true
    },
    {
      id: 'refund',
      label: translation('accounting.table.refund'),
      align: 'center',
      search: true
    },
    {
      id: 'restRewardPaidRestaurant',
      label: translation('accounting.table.restaurant_reward'),
      align: 'center',
      search: true
    },
    {
      id: 'deliveryFreeRestaurant',
      label: translation('accounting.table.delivery_free_restaurant'),
      align: 'center',
      search: true
    },
    {
      id: 'sumCharge',
      label: translation('accounting.table.sum_charge'),
      align: 'center',
      search: true
    }
  ];

  useEffect(() => {
    if (!filter.city) {
      console.log('no call');
      return;
    }
    setLoading(true);
    getSalesReportsByFilters(filter).then(result => {
      if (!result) {
        return;
      }
      const tc = result.orders.map(item => ({
        counter: item.counter,
        orderNumber: item.order_code,
        restaurant: item.restaurantFullName,
        date: createMomentOnBolivianTime(item.created_at).format('LLL'),
        nit: item.invoice_nit || '',
        userName: item.user_full_name || '',
        dateValue: item.created_at,
        status: <Orderstatus orderStatus={item.status} />,
        statusValue: orderStatusTranslate(item.status, translation).status,
        statusFilter: item.status,
        city: item.city,
        sale: item.total.toFixed(2),
        saleValue: item.total,
        service: item.service,
        commissionXpressPercentage: item.commissionPercentage,
        commissionEntregas: item.commissionEntregas,
        commissionOnlinePercentage: item.commissionOnlinePercentage,
        commission: item.sumOfCommissions.toFixed(2),
        commissionValue: item.sumOfCommissions,
        ivaComission: (item.ivaComission || 0).toFixed(2),
        id: item.id,
        promotionTitle: item.promotionTitle || '-',
        deliveryFreeRestaurant: item.deliveryFreeRestaurant.toFixed(2),
        discount: item.discount.toFixed(2),
        discountValue: item.discount,
        discountWallet: item.discountWallet.toFixed(2),
        discountWalletValue: item.discountWallet,
        refund: item.refund.toFixed(2),
        refundValue: item.refund,
        refund_description: item.refund_description,
        sumCharge: item.sumCharge.toFixed(2),
        restRewardPaidRestaurant: item.restRewardPaidRestaurant.toFixed(2),
        restRewardPaidRestaurantValue: item.restRewardPaidRestaurant,
        payment_method: item.payment_method,
        subsidiByRestaurant:
          item?.subsidies?.subsidiarySubsidies.toFixed(2) || 0.0
      }));

      setTableContent(tc);
      handleOrders(tc);
      contentChargesHistory(result.chargesHistory);
      setOrdersTotals(result.ordersTotals);
      getTotals(result.ordersTotals);
      getCreditOrders(result.creditOrders);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSetOrder = row => {
    setOrderId(row.id);
  };

  return (
    <Grid>
      {loading && (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !orderId && (
        <Box>
          <ExpressTable
            columns={tableColumns}
            rows={tableContent}
            clickRow={onSetOrder}
            search
          />
          <Card className={classes.card}>
            <Box className={classes.container}>
              <Box className={classes.container}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('accounting.summary.total_sale')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tSale.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.total_commission')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tCommission.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.total_commission_entregas')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tCommissionEntregas.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.total_commission_online')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tCommissionOnline.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.iva_commission')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tIvaCommission.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.promo')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tDiscount.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.refund')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tRefund.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container)}>
                <Typography
                  className={clsx(classes.totalText, classes.lastContainer)}
                >
                  {translation('accounting.summary.total_rest_reward')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tRestRewardPaidRestaurant.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container, classes.lastContainer)}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('accounting.summary.total_delivery_free')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.tDeliveryFreeRestaurant.toFixed(2)}
                </Typography>
              </Box>
              <Box className={clsx(classes.container, classes.lastContainer)}>
                <Typography className={clsx(classes.totalText)}>
                  {translation('accounting.summary.toal_charge')}
                </Typography>
                <Typography className={clsx(classes.amountText)}>
                  Bs. {ordersTotals.totalCharge.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
      {orderId && (
        <>
          <Button onClick={() => setOrderId(null)}>
            {`< ${translation('admin_subsidiary.common_table.back')}`}
          </Button>
          <OrderDetailsView orderIdFromProps={orderId} />
        </>
      )}
    </Grid>
  );
};

export default Sales;
