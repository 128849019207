import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang/lib';
import { Button, Grid, SvgIcon, Box } from '@material-ui/core';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import moment from 'moment';
import { useWorkersFormRestaurant } from '../../../../hooks/AdminHooks/users/useUsers';
import { USER_ROLES, USER_STATUS } from '../../../../dto/enum';
import ExpressTable from '../../../../components/Table/ExpressTable';
import XpressModal from '../../../../components/Modal';
import NewUser from './modals/NewUserModal';
import DeleteUser from './modals/DeleteUserModal';
import { updateUser } from '../../../../services/usersService';

const Workers = props => {
  const { restId } = props;
  const restaurantWorkers = useWorkersFormRestaurant(restId);
  const translation = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(null);
  let tableContent = [];

  const tableColumns = [
    {
      id: 'counter',
      label: translation('admin_subsidiary.common_table.no'),
      minWidth: 50,
      align: 'center',
      search: true
    },
    {
      id: 'name',
      label: translation('admin_subsidiary.common_table.name'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'user',
      label: translation('admin_subsidiary.common_table.user'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'mail',
      label: translation('admin_subsidiary.common_table.email'),
      minWidth: 170,
      align: 'center',
      search: true
    },
    {
      id: 'created_at',
      label: translation('admin_subsidiary.common_table.inscription_date'),
      minWidth: 170,
      align: 'center'
    },
    {
      id: 'position',
      label: translation('admin_subsidiary.common_table.position'),
      minWidth: 170,
      align: 'center'
    }
  ];

  const getPosition = item => {
    if (item[USER_ROLES.CASHIER]) return translation('admin_workers.cashier');
    if (item[USER_ROLES.SUPERVISOR])
      return translation('admin_workers.supervisor');
    if (item[USER_ROLES.OWNER]) return translation('admin_workers.owner');
    if (item[USER_ROLES.SUPER_ADMIN])
      return translation('admin_workers.super_admin');
    if (item[USER_ROLES.MARKETING])
      return translation('admin_workers.marketing');
    if (item[USER_ROLES.CUSTOMER_SERVICE])
      return translation('admin_workers.atc');
    console.log('unhandled role!', item.user_role);
  };

  if (restaurantWorkers) {
    tableContent = restaurantWorkers
      .filter(item => item.status !== USER_STATUS.ARCHIVED)
      .map((user, index) => ({
        id: user.id,
        counter: index + 1,
        name: `${user.first_name} ${user.last_name}`,
        user: `${user.email.split('@')[0]}`,
        mail: user.email,
        created_at: moment(user.created_at.toDate()).format('LL') || '-',
        position: getPosition(user.roles),
        userObject: { ...user }
      }));
  }
  const handleClose = () => {
    setShowModal(false);
  };
  const handleConfirm = id => {
    if (id) updateUser(id, { status: USER_STATUS.ARCHIVED });
    setShowModal(false);
  };

  const editUser = row => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <NewUser
          user={row.userObject}
          restId={restId}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
          withPassword
        />
      </XpressModal>
    );
  };

  const disableUser = row => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <DeleteUser
          name={row.name}
          user={row.mail}
          handleConfirm={() => handleConfirm(row.id)}
          handleClose={handleClose}
        />
      </XpressModal>
    );
  };

  const addUser = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <NewUser
          restId={restId}
          handleConfirm={handleConfirm}
          handleClose={handleClose}
        />
      </XpressModal>
    );
  };

  return (
    <>
      <Grid container item alignContent="center" justify="flex-end">
        <Box mb={2}>
          <Button color="secondary" variant="contained" onClick={addUser}>
            <SvgIcon fontSize="small">
              <PlusCircleIcon />
            </SvgIcon>
            {translation('admin_workers.newUser')}
          </Button>
        </Box>
      </Grid>
      <ExpressTable
        columns={[...tableColumns]}
        rows={[...tableContent]}
        actions={{
          edit: row => editUser(row),
          delete: row => disableUser(row)
        }}
      />
      {showModal && modal}
    </>
  );
};

export default Workers;
