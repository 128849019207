import React from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Card,
  CardContent,
  Box,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { editProduct } from '../../services/productsService';

const useStyles = makeStyles(theme => ({
  root: {},
  main: {
    backgroundColor: 'white'
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textTittle: {
    fontWeight: 'bold',
    marginTop: 10
  }
}));

const DeleteProduct = ({ productId, productName, handleClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleDisableProduct = async () => {
    await editProduct({ enable: false }, productId);
    enqueueSnackbar('El producto se elimino correctamente.', {
      variant: 'Success'
    });
    handleClose(false);
  };

  return (
    <Container className={classes.content}>
      <Card>
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Grid item container justify="center">
              <Box mx={2} mb={3}>
                <Typography className={classes.textTittle}>
                  {`¿Estas seguro que deseas eliminar el producto ${productName}?`}
                </Typography>
              </Box>
              <Box width="100%">
                <Grid item container justify="center">
                  <Box mr={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleDisableProduct}
                    >
                      Si
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => handleClose(false)}
                    >
                      No
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default DeleteProduct;
