import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import clsx from 'clsx';
import { useAddPriceStyles } from '../styles';
import { useAreas } from '../../../../../hooks/AdminHooks/areas/useAreas';
import ExpressTable from '../../../../../components/Table/ExpressTable';
import { editSubsidiary } from '../../../../../services/subsidiaryService';
import { useSubsidiary } from '../../../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { GreenCrossButton } from '../../../../../components/Inputs/Buttons';
import XpressModal from '../../../../../components/Modal';
import NewPrice from '../../../areas/modal/NewPrice';

const AddPrice = ({ subId, onClose, onAreaEdited }) => {
  const areas = useAreas();
  const [area, setArea] = useState({ id: null });
  const [areaPrice, setAreaPice] = useState([]);
  const [priceId, setPriceId] = useState(null);
  const classes = useAddPriceStyles();
  const translation = useTranslation();
  const subsidiary = useSubsidiary(subId);
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (subsidiary) {
      setPriceId(subsidiary.price_area_id);
    }
  }, [subsidiary]);

  const tableHeaders = [
    {
      id: 'distance',
      label: translation('admin_rest.prices.distance')
    },
    {
      id: 'price',
      label: translation('admin_rest.prices.price')
    },
    {
      id: 'subsidy',
      label: translation('admin_rest.prices.subsidy')
    },
    {
      id: 'bonus',
      label: translation('admin_rest.prices.bonus')
    }
  ];

  const assingPrice = (areaId, areaName) => {
    editSubsidiary(subId, {
      price_area_id: areaId,
      area_price_name: areaName
    });
    onAreaEdited(areaId, areaName);
  };

  const removePrice = (areaId, areaName) => {
    editSubsidiary(subId, {
      price_area_id: '',
      area_price_name: ''
    });
    onAreaEdited(areaId, areaName);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const newArea = () => {
    setArea(area);
    setAreaPice(area.price);
    assingPrice(area.id, area.name);
    setShowModal(false);
    onClose();
  };

  const createArea = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid className={classes.newPriceModal}>
          <NewPrice onCancel={handleClose} returnNewArea={newArea} />
        </Grid>
      </XpressModal>
    );
  };

  return (
    <Card>
      <Grid item container justify="flex-end" pl={1}>
        <GreenCrossButton onClick={onClose} />
      </Grid>
      <Grid item container justify="center">
        <Box my={1}>
          <Typography className={clsx(classes.title)}>
            {translation('admin_rest.prices.add_modal_title')}
          </Typography>
        </Box>
      </Grid>
      <Grid container className={classes.container}>
        <Grid
          item
          container
          xs={12}
          md={7}
          className={clsx(classes.areasContainer)}
        >
          <Box>
            <Typography className={clsx(classes.tableHelper)}>
              {translation('admin_rest.prices.modal_area_help_text')}
            </Typography>
          </Box>
          {areas &&
            areas.map(a => (
              <Box
                key={a.id}
                className={clsx(classes.areaRowContainer, {
                  [classes.areaActiveRowContainer]: area.id === a.id,
                  [classes.setAreaRowContainer]: a.id === priceId
                })}
              >
                <FormControlLabel
                  checked={area.id === a.id}
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={classes.label}>{a.name}</Typography>
                  }
                  labelPlacement="end"
                  onChange={e => {
                    if (e.target.checked) {
                      setArea(a);
                      setAreaPice(a.price);
                    }
                  }}
                />
                <Button
                  className={clsx(classes.button, {
                    [classes.setAreaButton]: a.id === priceId
                  })}
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={
                    a.id === priceId
                      ? () => {
                          setArea(a);
                          setAreaPice(a.price);
                          removePrice(a.id, '');
                        }
                      : () => {
                          setArea(a);
                          setAreaPice(a.price);
                          assingPrice(a.id, a.name);
                        }
                  }
                >
                  {a.id === priceId
                    ? translation('admin_rest.prices.remove')
                    : translation('admin_rest.prices.assigne')}
                </Button>
              </Box>
            ))}
        </Grid>
        <Grid item container xs={12} md={5} alignContent="flex-start">
          <Box className={clsx(classes.tableTitleContainer)}>
            <Typography className={clsx(classes.tableHelper)}>
              {area.name}
            </Typography>
            <Button
              className={clsx(classes.newButton)}
              variant="contained"
              onClick={createArea}
            >
              {translation('admin_rest.prices.add_prices')}
            </Button>
          </Box>
          <ExpressTable columns={[...tableHeaders]} rows={[...areaPrice]} />
        </Grid>
      </Grid>
      {showModal && modal}
    </Card>
  );
};

export default AddPrice;
