import axios from 'src/utils/axios';
import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

const COLLECTION = COLLECTIONS.USER;

class UserService {
  saveUser = (newUser, localAdmin = false) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/users${localAdmin ? '/admin' : ''}`, newUser)
        .then(({ status, data }) => {
          if (status === 201) {
            resolve({
              message: data.message,
              status
            });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              message: data.message,
              status
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            message: error.message,
            status: error.response && error.response.status
          });
        });
    });

  editUser = (id, editedUser, localAdmin = false) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/users${localAdmin ? '/admin' : ''}/${id}`, editedUser)
        .then(({ status, data }) => {
          if (status === 202) {
            resolve({
              message: data.message,
              status
            });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              message: data.message,
              status
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            message: error.message,
            status: error.response.status
          });
        });
    });

  removeFromRestaurant = id =>
    new Promise((resolve, reject) => {
      axios
        .patch(`users/removeFromRestaurant/${id}`)
        .then(({ status, data }) => {
          if (status === 202) {
            resolve({
              message: data.message,
              status
            });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              message: data.message,
              status
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            message: error.message,
            status: error.response.status
          });
        });
    });

  removeFromAdmin = id =>
    new Promise((resolve, reject) => {
      axios
        .patch(`users/admin/remove/${id}`)
        .then(({ status, data }) => {
          if (status === 202) {
            resolve({
              message: data.message,
              status
            });
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              message: data.message,
              status
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            message: error.message,
            status: error.response.status
          });
        });
    });

  saveUserDB = async (id, data) =>
    firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .update({ ...data });

  getUserDB = async id => {
    const dbUserDoc = await firebase
      .firestore()
      .collection(COLLECTION)
      .doc(id)
      .get();

    return { ...dbUserDoc.data(), id: dbUserDoc.id };
  };
}

export const updateUser = (id, fields) => {
  firebase
    .firestore()
    .collection(COLLECTIONS.USER)
    .doc(id)
    .set({ ...fields }, { merge: true });
};

const userService = new UserService();
export default userService;
