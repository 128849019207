import Base from './base';

export default class BankData extends Base {
  constructor({
    id,
    enable = true,
    created_at,
    updated_at,
    commerce_id = '',
    ci_nit = '',
    bank = '',
    account_type = '',
    account_number = 0,
    owner_name = ''
  } = {}) {
    super(id, enable, created_at, updated_at);
    this.commerce_id = commerce_id;
    this.ci_nit = ci_nit;
    this.bank = bank;
    this.account_type = account_type;
    this.account_number = Number(account_number);
    this.owner_name = owner_name;
  }
}
