import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import 'moment/locale/es';
import moment from 'moment';
import { useTranslation } from 'react-multi-lang';
import { COLORS } from 'src/theme/colors';
import { useCitiesNames } from 'src/hooks/AdminHooks/cities/useCities';
import ModalBillingData from '../../ModalBillingData';

const useStyles = makeStyles(() => ({
  informationContainer: {
    borderRadius: '5px',
    border: `1px solid ${COLORS.yellowLink}`,
    padding: '28px 27px 20px 18px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  total: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  text: {
    fontWeight: 'light',
    fontSize: '16px'
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px'
  },
  button: {
    color: COLORS.yellowLink,
    padding: 0,
    fontSize: '14px',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const RestaurantInfo = ({ restaurant, billingData, setBillingData }) => {
  const classes = useStyles();
  const cities = useCitiesNames(restaurant?.cities_id);
  const translation = useTranslation();
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const handleModal = () => {
    setOpenBillingModal(!openBillingModal);
  };
  const handleFormatDate = date => {
    const dateFormat = moment(date).format('dddd D [de] MMMM [de] YYYY');
    const dateArray = dateFormat.split(' ');
    dateArray[0] = dateArray[0].charAt(0).toUpperCase() + dateArray[0].slice(1);
    dateArray[3] = dateArray[3].charAt(0).toUpperCase() + dateArray[3].slice(1);
    return dateArray.join(' ');
  };
  return (
    <Box className={classes.informationContainer}>
      <ModalBillingData
        show={openBillingModal}
        onClose={() => setOpenBillingModal(false)}
        billingData={billingData}
        setBillingData={setBillingData}
        id={restaurant.id}
      />
      <Box className={classes.header}>
        <Typography className={classes.title}>
          {translation('centralPayment.global_summary.information')}
        </Typography>
        <Button className={classes.button} onClick={handleModal}>
          {translation('centralPayment.global_summary.modal_title')}
        </Button>
      </Box>
      <Divider />
      <Box className={classes.header}>
        <Typography className={classes.text}>
          <strong>{restaurant?.name || '-'}</strong>
        </Typography>
        <Typography className={classes.text}>
          {restaurant?.restaurant_code || '-'}
        </Typography>
      </Box>
      <Typography className={classes.text}>
        <strong>{translation('centralPayment.global_summary.city')}</strong>{' '}
        {cities.join(', ') || '-'}
      </Typography>
      <Typography className={classes.text}>
        <strong>
          {translation('centralPayment.global_summary.subsidiaries')}
        </strong>{' '}
        n°
        {restaurant?.subsidiary_amount || '-'}
      </Typography>
      <Box className={classes.inline}>
        <Typography className={classes.text}>
          <strong>
            {translation('centralPayment.global_summary.executive')}
          </strong>{' '}
          {restaurant?.business_executive
            ? `${restaurant?.business_executive?.first_name || ''} ${restaurant
                ?.business_executive?.last_name || ''}`
            : '-'}
        </Typography>
      </Box>
      <Typography className={classes.text}>
        <strong>Activación de pagos en línea: </strong>
        {restaurant?.online_payment_activation_date
          ? handleFormatDate(
              restaurant?.online_payment_activation_date.toDate()
            )
          : '-'}
      </Typography>
    </Box>
  );
};

export default RestaurantInfo;
