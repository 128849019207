/* eslint-disable import/prefer-default-export */
import firebase from '../config/firebaseConfig';
import { COLLECTIONS } from '../constants';

export const getBankInfo = async () => {
  const settingsSnap = await firebase
    .firestore()
    .collection(COLLECTIONS.SETTINGS)
    .doc('bank_info')
    .get();
  const bankInfo = settingsSnap.data();
  return bankInfo;
};

export const getDocuments = async () => {
  const settingsSnap = await firebase
    .firestore()
    .collection(COLLECTIONS.SETTINGS)
    .doc('documents')
    .get();
  return settingsSnap.data();
};
