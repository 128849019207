/* global google */
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Polyline
} from 'react-google-maps';
import React, { useMemo } from 'react';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import demoFancyMapStyles from './demoFancyMapStyles.json';

export const MARKER_TYPE = {
  ORDER: 'ORDER'
};

export const MARKER_IMAGE = {
  ORDER: '/static/markers/orderUser.png'
};

export class MarkerData {
  constructor({
    id,
    location,
    type = MARKER_TYPE.ORDER,
    data,
    customOptions = { scale: 50 },
    draggable = false
  }) {
    this.id = id;
    this.location = location;
    this.type = type;
    this.data = data;
    this.customOptions = customOptions;
    this.draggable = draggable;
  }
}

const CustomMarker = ({
  id,
  location,
  icon,
  // data = {},
  type,
  draggable = false
}) => {
  // const [open, setOpen] = useState(false);
  let markerLabel = '';
  if (type === MARKER_TYPE.ORDER_MULTIPLE_DESTINATIONS) {
    const textLabel = id.split('@')[1];
    markerLabel = {
      text: textLabel,
      color: '#000',
      backgroundColor: '#fff',
      fontSize: '24px',
      fontWeight: 'bold'
    };
  }
  return (
    <Marker
      key={id}
      position={location}
      options={{
        icon
      }}
      onClick={() => {}}
      draggable={draggable}
      label={markerLabel}
    />
  );
};

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  strokeWeight: 1,
  scale: 5,
  strokeColor: '#0000FF'
};

const Map = ({
  createPin = false,
  center,
  ordersMarkers = [],
  coordinates = []
}) => {
  function getMarker({
    id,
    location,
    type = MARKER_TYPE.ORDER,
    data,
    customOptions,
    draggable = false
  }) {
    const url = MARKER_IMAGE[type];
    const icon = {
      url,
      scaledSize: new google.maps.Size(customOptions.scale, customOptions.scale)
    };
    return (
      <CustomMarker
        key={id}
        id={id}
        location={location}
        type={type}
        data={data}
        icon={icon}
        draggable={draggable}
      />
    );
  }
  const orders = useMemo(() => {
    return (
      <MarkerClusterer
        key="orders_cluster"
        averageCenter
        enableRetinaIcons
        gridZone={10}
        maxZoom={14}
      >
        {ordersMarkers.map(markerData => getMarker(markerData))}
      </MarkerClusterer>
    );
  }, [ordersMarkers]);
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={center}
      defaultOptions={{ styles: demoFancyMapStyles }}
      center={center}
    >
      {orders}
      {coordinates.length && (
        <Polyline
          path={coordinates}
          key={1}
          options={{
            strokeOpacity: 0,
            fillOpacity: 0,
            zIndex: 10,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
              }
            ]
          }}
        />
      )}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
