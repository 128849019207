export default class Photo {
  constructor({ original = '', x400 = '', x80 = '', x40 = '' } = {}) {
    this.original = original;
    this.x400 = x400;
    this.x80 = x80;
    this.x40 = x40;
  }

  toPlainObject() {
    return { ...this };
  }
}
