import React from 'react';
import { Box, Dialog, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-multi-lang/lib';
import FilesDropzone from '../FilesDropzone';

const DropzoneModal = ({
  onClose = () => {},
  open = false,
  type = 'image',
  setImage = () => {},
  setDoc = () => {},
  onFinish = () => {},
  path,
  isDocument = false,
  setDocumentUrl,
  documentId,
  ...rest
}) => {
  const translation = useTranslation();

  const imageDropzoneLabels = {
    selectFiles: translation(
      'edit.product_create.basic_information.form.choose_image'
    ),
    instructions: translation('dropzone.image.instructions'),
    removeAll: translation('remove'),
    uploadAll: translation('save')
  };

  const fileDropzoneLabels = {
    selectFiles: translation('dropzone.file.select'),
    instructions: translation('dropzone.file.instructions'),
    removeAll: translation('remove'),
    uploadAll: translation('save')
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {type === 'image'
          ? translation('dropzone.upload.images')
          : translation('dropzone.upload.files')}{' '}
      </DialogTitle>
      <Box m={2}>
        <FilesDropzone
          labels={type === 'image' ? imageDropzoneLabels : fileDropzoneLabels}
          maxImages={1}
          setImage={setImage}
          setDoc={setDoc}
          type={type}
          onFinish={onFinish}
          path={path}
          isDocument={isDocument}
          setDocumentUrl={setDocumentUrl}
          documentId={documentId}
          {...rest}
        />
      </Box>
    </Dialog>
  );
};
DropzoneModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string,
  setImage: PropTypes.func,
  onFinish: PropTypes.func,
  path: PropTypes.string.isRequired
};

export default DropzoneModal;
