import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { TYPE_CHARGE } from 'src/dto/enum';
import GlobalSummary from './globalSummary';
import CashPayment from './cashPayment';
// import OnlinePayment from './onlinePayment';
import Documents from './documents';
import BankData from './bankData';
import { useRestaurantPerIdNative } from '../../../hooks/useRestaurant';
import OnlinePayment from './onlinePayment';
// import PayBills from './payBills';

const a11yProps = index => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

// const tabsValues = {
//   globalSummary: 0,
//   cashPayment: 1,
//   // onlinePayment: 2,
//   documents: 3,
//   bankData: 4,
//   // payBills: 5
// };

const TabsContainer = () => {
  const { tabId } = useParams();
  const history = useHistory();
  const [online, setOnline] = useState(0);
  const currentRestaurant = useSelector(state => state.account.restaurant);
  const restaurant = useRestaurantPerIdNative(currentRestaurant.id || '');

  const { tabs, tabsValues } = useMemo(() => {
    if (restaurant) {
      if (restaurant.charge_type === TYPE_CHARGE.COMMISSION) {
        setOnline(1);
        return {
          tabs: [
            'Resumen global',
            'Resumen pagos en efectivo',
            'Resumen pagos en linea',
            'Documentos legales de comercio',
            'Datos bancarios'
          ],
          tabsValues: {
            globalSummary: 0,
            cashPayment: 1,
            onlinePayment: 2,
            documents: 3,
            bankData: 4
          }
        };
      }
      return {
        tabs: [
          'Resumen global',
          'Resumen pagos en efectivo',
          'Documentos legales de comercio',
          'Datos bancarios'
        ],
        tabsValues: {
          globalSummary: 0,
          cashPayment: 1,
          documents: 2,
          bankData: 3
        }
      };
    }
    return { tabs: {}, tabsValues: {} };
  }, [restaurant]);
  const tabPosition = useMemo(() => {
    return tabsValues[tabId] || 0;
  }, [tabId, tabsValues]);

  const handleChange = (event, position) => {
    event.preventDefault();
    const keys = Object.keys(tabsValues);
    const route = keys.find(r => tabsValues[r] === position);
    history.push(`/app/centralPayments/${route}`);
  };
  if (!restaurant) {
    return null;
  }
  return (
    <Paper>
      <Tabs
        value={tabPosition}
        onChange={handleChange}
        aria-label="tabs-payment-center"
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <Tab label={tab} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
      <TabPanel value={tabPosition} index={0}>
        <GlobalSummary restaurant={restaurant} />
      </TabPanel>
      <TabPanel value={tabPosition} index={1}>
        <CashPayment restaurant={restaurant} />
      </TabPanel>

      {restaurant.charge_type === TYPE_CHARGE.COMMISSION && (
        <TabPanel value={tabPosition} index={2}>
          <OnlinePayment restaurant={restaurant} />
        </TabPanel>
      )}

      <TabPanel value={tabPosition} index={2 + online}>
        <Documents restaurant={restaurant} />
      </TabPanel>
      <TabPanel value={tabPosition} index={3 + online}>
        <BankData restaurant={restaurant} />
      </TabPanel>
      {/* <TabPanel value={tabPosition} index={5}>
        <PayBills />
      </TabPanel> */}
    </Paper>
  );
};

export default TabsContainer;
