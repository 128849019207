import { registerStatus } from 'src/constants';
import Base from './base';
import Photo from './photo';
import DeliveryDisabledReasons from './deliveryDisabledReasons';
import { COOKED_AVERAGE } from './enum';

/* eslint camelcase: 0 */
export default class Subsidiary extends Base {
  constructor({
    id,
    enabled,
    created_at,
    updated_at,
    restaurant_id,
    city,
    address = '',
    address_2 = '',
    location,
    geohash,
    phone_number = '',
    delivery_time,
    main_category,
    menu_section,
    subsidiary_id,
    schedule = [],
    categories = [],
    name = '',
    logo_photo = new Photo(),
    banner_photo = new Photo(),
    legal_representant = {},
    register_status = registerStatus.PENDING,
    archived_date = '',
    suspention_date = '',
    enable_delivery_prices = false,
    receiving_orders = true,
    restaurant_name = '',
    last_schedule,
    price_area = {},
    subsidiariesMerged = [],
    subsidiariesWithRestaurantsMerged = [],
    manager = '',
    manager_phone = '',
    delivery_disabled_reasons = new DeliveryDisabledReasons({}),
    owner_comments_for_subsidiary = '',
    amount_months_debt = 0,
    zone = {},
    average_time_accepted = 0,
    cooked_average = {
      average: 25,
      type: COOKED_AVERAGE.NORMAL
    },
    completed_orders_month = 0
  } = {}) {
    super(id, enabled, created_at, updated_at);
    if (restaurant_id) this.restaurant_id = restaurant_id;
    if (city) this.city = city;
    this.address = address;
    this.address_2 = address_2;
    if (location) this.location = location;
    if (geohash) this.geohash = geohash;
    this.phone_number = phone_number;
    if (delivery_time) this.delivery_time = delivery_time;
    if (main_category) this.main_category = main_category;
    if (menu_section) this.menu_section = menu_section;
    if (last_schedule) this.last_schedule = last_schedule;
    if (subsidiary_id) this.subsidiary_id = subsidiary_id;
    this.schedule = schedule || [];
    this.name = name;
    this.logo_photo = new Photo(logo_photo);
    this.banner_photo = new Photo(banner_photo);
    this.legal_representant = legal_representant;
    this.register_status = register_status;
    this.archived_date = archived_date;
    this.suspention_date = suspention_date;
    this.receiving_orders = receiving_orders;
    this.restaurant_name = restaurant_name;
    this.enable_delivery_prices = enable_delivery_prices;
    this.price_area = price_area;
    this.subsidiariesMerged = subsidiariesMerged;
    this.subsidiariesWithRestaurantsMerged = subsidiariesWithRestaurantsMerged;
    this.manager = manager;
    this.manager_phone = manager_phone;
    this.delivery_disabled_reasons = delivery_disabled_reasons;
    this.categories = categories;
    this.owner_comments_for_subsidiary = owner_comments_for_subsidiary;
    this.amount_months_debt = amount_months_debt;
    this.zone = zone;
    this.average_time_accepted = average_time_accepted;
    this.cooked_average = cooked_average;
    this.completed_orders_month = completed_orders_month;
  }

  toPlainObject() {
    return {
      ...this,
      logo_photo: this.logo_photo.toPlainObject(),
      banner_photo: this.banner_photo.toPlainObject()
    };
  }
}
