import React from 'react';
import { Grid, Box, makeStyles, Typography, Card } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang/lib';
import moment from 'moment';
import { registerStatus } from '../../../constants';

const bannerColors = {
  suspColor: '#fefbd8',
  archColor: '#eca1a6'
};

const useStyles = makeStyles(() => ({
  logo: {
    borderRadius: '50%',
    border: '1px solid white',
    marginRight: '20px'
  },
  archived: {
    zIndex: 1,
    height: '50px',
    width: '100%',
    backgroundColor: bannerColors.archColor
  },
  suspended: {
    zIndex: 1,
    height: '50px',
    width: '100%',
    backgroundColor: bannerColors.suspColor
  }
}));

const SubsidiaryHeader = ({
  bannerSrc,
  logo,
  name,
  restName,
  regStatus,
  date
}) => {
  const translation = useTranslation();
  const classes = useStyles();
  const dateToShow = date
    ? moment(date.toDate()).format('lll')
    : 'not available';
  let hasBanner = false;

  const getBanner = () => {
    switch (regStatus) {
      case registerStatus.SUSPENDED:
        hasBanner = true;
        return (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.suspended}
          >
            <Typography>
              {translation(
                `${restName ? 'admin_subsidiary' : ''}.banner.suspended`
              )}
            </Typography>
            <Typography>
              {translation(
                `${restName ? 'admin_subsidiary' : ''}.banner.since`
              )}{' '}
              {dateToShow}
            </Typography>
          </Grid>
        );
      case registerStatus.ARCHIVED:
        hasBanner = true;
        return (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.archived}
          >
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {translation(
                `${
                  restName ? 'admin_subsidiary' : 'admin_rest'
                }.banner.archived`
              )}
            </Typography>
            <Typography variant="subtitle2">
              {translation(
                `${
                  restName ? 'admin_subsidiary' : 'admin_rest'
                }.banner.arch_since`
              )}{' '}
              {dateToShow}
            </Typography>
          </Grid>
        );
      default:
        hasBanner = false;
        break;
    }
  };

  return (
    <Grid item container>
      <Box width="100%">
        <Grid item container>
          <Box width="100%" height="200px">
            <Grid
              item
              container
              justify="center"
              style={{
                height: '100%',
                backgroundColor: '#cccccc'
              }}
            >
              {getBanner()}
              <img
                style={{
                  marginTop: `${hasBanner ? '-50px' : ''}`,
                  height: '100%'
                }}
                src={bannerSrc}
                alt="banner"
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box mx={3} mt={-10} width="100%">
        <Grid item container justify="space-between" alignItems="flex-end">
          <Grid item container xs={6} md={6}>
            <img className={classes.logo} src={logo} alt="logo" />
            <Card style={{ height: '100%' }}>
              <Box p={1}>
                <Grid item container alignItems="baseline" direction="column">
                  <Typography variant="h5" color="textPrimary">
                    {restName}
                  </Typography>
                  <Typography
                    variant={restName ? 'body2' : 'h5'}
                    color="textPrimary"
                  >
                    {name}
                  </Typography>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SubsidiaryHeader;
