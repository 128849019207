import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Divider,
  CircularProgress,
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  SvgIcon
} from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useTranslation } from 'react-multi-lang/lib';
import Page from '../../../components/Page';
import Header from './Header';
import RestaurantForm from './forms/RestaurantForm';
import Subsidiaries from './Subsidiaries';
import { useRestaurant } from '../../../hooks/AdminHooks/restaurant/useRestaurant';
import { useAllCities } from '../../../hooks/AdminHooks/cities/useCities';
import { useAllRestaurantSubsidiaries } from '../../../hooks/AdminHooks/subsidiaries/useSubsidiaries';
import { COLLECTIONS, registerStatus } from '../../../constants';
import LegalRepresentant from './forms/LegalRepresentantForm';
import NewSubsidiaryForm from './forms/NewSubsidiaryForm';
import XpressModal from '../../../components/Modal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(5)
  },
  modal: {
    marginTop: '20px',
    justifyContent: 'center'
  }
}));

const CommerceData = () => {
  const classes = useStyles();
  const translation = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const firestore = useFirestore();
  const { status, id } = match.params;
  const currentRestaurant = useRestaurant(id);
  const allCities = useAllCities();
  const restaurantSubsidiaries = useAllRestaurantSubsidiaries(id);
  const [showSubsidiaries, setShowSubsidiaries] = useState(false);
  const [clearView, setClearView] = useState(false);
  const [modal, setModal] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const approveRest = async () => {
    await firestore
      .collection(COLLECTIONS.RESTAURANT)
      .doc(currentRestaurant.id)
      .update({ register_status: registerStatus.ACTIVE });
    history.push('/app');
  };

  const pendingRest = async () => {
    await firestore
      .collection(COLLECTIONS.RESTAURANT)
      .doc(currentRestaurant.id)
      .update({ register_status: registerStatus.PENDING });
  };

  const rejectRest = async () => {
    await firestore
      .collection(COLLECTIONS.RESTAURANT)
      .doc(currentRestaurant.id)
      .update({ register_status: registerStatus.REJECTED });
    history.push('/app');
  };

  const approvedDocs = async () => {
    await firestore
      .collection(COLLECTIONS.RESTAURANT)
      .doc(currentRestaurant.id)
      .set(
        { pendings: { docs: !currentRestaurant.pendings.docs } },
        { merge: true }
      );
  };

  if (!currentRestaurant) {
    return <></>;
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const addSubsidiary = () => {
    setShowModal(true);
    setModal(
      <XpressModal handleClose={handleClose}>
        <Grid item container className={classes.modal}>
          <NewSubsidiaryForm
            restId={currentRestaurant.id}
            allCities={allCities}
            handleClose={handleClose}
            onModal
          />
        </Grid>
      </XpressModal>
    );
  };

  return (
    <Page
      className={classes.root}
      title={translation('admin_commerces.title_page_commerce')}
    >
      <Container maxWidth={false}>
        {currentRestaurant && (
          <Header
            path={[
              {
                link: `/admin/commerce/${status}`,
                label: translation('admin_commerces.breadcrumbs.pending')
              },
              {
                link: `/admin/commerce/${status}/${id}`,
                label: currentRestaurant.name || id
              }
            ]}
          />
        )}
        <Divider style={{ marginTop: '10px' }} />
        {!currentRestaurant && (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}
        {currentRestaurant && allCities && currentRestaurant.id === id && (
          <>
            <Box m={3}>
              <Grid item container>
                <Grid item container xs={6} md={6}>
                  <Grid item container xs={12} md={12} direction="row">
                    <Typography variant="h1" color="textPrimary">
                      {currentRestaurant.name}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} md={12} direction="row">
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      style={{
                        marginRight: '100px',
                        cursor: 'pointer',
                        color: showSubsidiaries ? '' : 'yellow'
                      }}
                      onClick={() => setShowSubsidiaries(false)}
                    >
                      Datos
                    </Typography>
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      style={{
                        cursor: 'pointer',
                        color: showSubsidiaries ? 'yellow' : ''
                      }}
                      onClick={() => {
                        setShowSubsidiaries(true);
                        setClearView(true);
                      }}
                    >
                      Sucursales
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container xs={6} md={6} alignItems="baseline">
                  {!showSubsidiaries && (
                    <>
                      <Grid item container xs={6} md={6}>
                        <Grid>
                          <Button
                            onClick={approveRest}
                            disabled={
                              currentRestaurant.pendings
                                ? currentRestaurant.pendings.subsidiaries ||
                                  currentRestaurant.pendings.docs
                                : true
                            }
                            variant="contained"
                          >
                            {translation('admin_commerces.accept_rest')}
                          </Button>
                        </Grid>
                        <Grid>
                          <Typography variant="body2" color="textPrimary">
                            {translation('admin_commerces.accepted_info')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={6} md={6} justify="flex-end">
                        <Grid>
                          <Button
                            onClick={rejectRest}
                            color="secondary"
                            variant="contained"
                            disabled={
                              currentRestaurant.register_status ===
                              registerStatus.REJECTED
                            }
                          >
                            {translation('admin_commerces.reject_rest')}
                          </Button>
                        </Grid>
                        {currentRestaurant.register_status ===
                          registerStatus.REJECTED && (
                          <Grid>
                            <Typography variant="body2" color="textPrimary">
                              {translation('admin_commerces.rejected_info')}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>

            {!showSubsidiaries && (
              <Box m={3}>
                <Card>
                  <Grid item container display="row" justify="space-between">
                    <CardHeader title="Datos generales del Comercio" />
                    <Grid
                      md={6}
                      xs={6}
                      item
                      container
                      display="row"
                      alignItems="center"
                      justify="flex-end"
                    >
                      {currentRestaurant.register_status !==
                        registerStatus.PENDING && (
                        <Box mr={3}>
                          <Button
                            onClick={pendingRest}
                            variant={
                              currentRestaurant.register_status ===
                              registerStatus.PENDING
                                ? 'contained'
                                : 'outlined'
                            }
                          >
                            Pendiente
                          </Button>
                        </Box>
                      )}
                      <Box mr={3}>
                        <Button
                          variant={
                            currentRestaurant.pendings &&
                            !currentRestaurant.pendings.docs
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={approvedDocs}
                        >
                          Aprobado
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <RestaurantForm currentRestaurant={currentRestaurant} />
                    <LegalRepresentant ownerId={currentRestaurant.owner_id} />
                  </CardContent>
                </Card>
              </Box>
            )}
            {showSubsidiaries && restaurantSubsidiaries && (
              <Grid>
                <Box ml={3} mb={2}>
                  <Button
                    onClick={addSubsidiary}
                    color="secondary"
                    variant="contained"
                    className={classes.action}
                  >
                    <SvgIcon fontSize="small">
                      <PlusCircleIcon />
                    </SvgIcon>
                    {translation('admin_commerces.add_sub')}
                  </Button>
                </Box>
                <Subsidiaries
                  restaurantSubsidiaries={restaurantSubsidiaries}
                  allCities={allCities}
                  clearView={clearView}
                  setClearView={setClearView}
                />
              </Grid>
            )}
          </>
        )}
        {showModal && modal}
      </Container>
    </Page>
  );
};

export default CommerceData;
