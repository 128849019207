import React, { useState } from 'react';
import { Box, Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-multi-lang/lib';
import Header from './Header';
import TimeDetails from '../../../orders/OrderDetailsView/TimeDetails';
import Facturation from '../../../orders/OrderDetailsView/Facturation';
import DriverAssignmentCard from '../../../orders/OrderDetailsView/DriverAssignmentCard';
import OrderProductsTable from '../../../orders/OrderDetailsView/OrderProductsTable';
import AdditionalReferences from '../../../orders/OrderDetailsView/AdditionalReferences';
import { ORDER_STATUS, ORDER_TYPE } from '../../../../dto/enum';
import Cockpit from './OrderCockpit';
import Banner from './Banner';
import Comments from './tabs/Comments';
import History from './tabs/History';
import { useOrderViewStyles } from './styles';
import RefundCard from '../../../orders/OrderDetailsView/RefundCard';
import { useCurrentUser } from '../../../../hooks/useUser';

const OrderViewAdmin = ({ order }) => {
  const classes = useOrderViewStyles();
  const translation = useTranslation();
  const [value, setValue] = useState(0);
  const user = useCurrentUser();

  const handleChange = (event, index) => {
    setValue(index);
  };

  const getTabContent = () => {
    switch (value) {
      case 0:
        return (
          <Comments
            orderId={order.id}
            orderComment={order.admin_comment || ''}
          />
        );
      case 1:
        return <History orderId={order.id} />;
      default:
        console.log(' =( unhandle tab!');
        break;
    }
  };

  return (
    <>
      <Header order={order} />
      {!order.verified && <Banner unverifiedReason={order.unverified_reason} />}
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={8} xl={8} xs={12}>
            <OrderProductsTable adminView order={order} showExtraHeaderInfo />
            {order.additional_description && (
              <Box mt={2}>
                <AdditionalReferences order={order} />
              </Box>
            )}
            <Box className={classes.commentBox}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={translation('orders.comments')} />
                  <Tab label={translation('orders.history.history')} />
                </Tabs>
              </AppBar>
              {getTabContent()}
            </Box>
            {order.status !== ORDER_STATUS.COMPLETED &&
              order.status !== ORDER_STATUS.CANCELED && (
                <Box mt={2}>
                  <Cockpit order={order} />
                </Box>
              )}
          </Grid>

          <Grid item md={4} xl={4} xs={12}>
            {user.roles.SUPER_ADMIN && (
              <RefundCard
                orderId={order.id}
                orderRefund={order.refund || 0}
                orderDescription={order.refund_description || ''}
              />
            )}
            <TimeDetails order={order} />
            <Box mt={2}>
              <Facturation order={order} showWhatapp />
            </Box>
            {order.order_type === ORDER_TYPE.DELIVERY && (
              <Box mt={2}>
                <DriverAssignmentCard
                  avatarSrc={
                    order.driver.profile_photo?.x80 ||
                    order.driver.profile_photo?.original
                  }
                  driverName={
                    !_.isEmpty(order.driver)
                      ? `${order.driver.first_name} ${order.driver.last_name}`
                      : ''
                  }
                  address={
                    order.delivery_location
                      ? `${
                          order?.delivery_location?.address
                            ? order?.delivery_location?.address
                            : ''
                        } ${
                          order?.delivery_location?.address_number
                            ? order?.delivery_location?.address_number
                            : ''
                        }`
                      : ''
                  }
                  deliveryCharge={order.delivery_fee}
                  driverPhone={order.driver.phone_number}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderViewAdmin;
